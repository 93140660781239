import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const SimilarAssetCardStyled = styled.div<{
  marginRight?: string;
}>`
  /* width: 100%; */
  max-width: 400px;
  min-width: 400px;
  min-height: 265px;
  border-radius: 8px;
  border: solid 1px ${({ theme }): string => theme.borderColors.DARK_BLUE};
  background-color: #fff;
  margin-right: ${({ marginRight }): any => marginRight};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .body {
    padding: 18px 18px 0 18px;

    .related-obg-title {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      .ant-btn {
        /* max-width: 360px; */
        max-width: 70%;
      }

      .wrapper {
        width: 100%;
      }

      .info-title-sec {
        display: flex;
        gap: 5px 10px;
        flex-wrap: wrap;
        padding-right: 10px;

        .info-title {
          width: unset !important;
        }

        .info-status {
          align-items: center;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .info {
        margin: 0px 16px 0px 13px;
        .info-subtitle {
          color: ${({ theme }): string => theme.textColors.DARK_GREY};
          font-size: 12px;
          opacity: 0.8;
          line-height: 1;
        }
      }
    }
    .link-to-url {
      display: grid;
      gap: 5.6px;
      grid-template-columns: 74px minmax(75%, 80%);
      margin-bottom: 19px;

      .url-label {
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      }

      .url-value {
        line-height: 14px;
      }
    }
    .description {
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      margin-bottom: 10px;
      ${ellipsis}
    }
    .additional-info {
      margin-bottom: 23px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;

      .extra-additional-info {
        color: ${(props): string => props.theme.textColors.BLACK_COLOR};
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_24};

    .more-details {
      font-family: OpenSansSemiBold;
    }

    button {
      height: 25px;
    }

    svg {
      circle {
        fill: transparent;
      }
    }
  }
`;

export const ProfilingInfoContainerStyled = styled.div`
  display: flex;
  justify-content: space-between; /* Ensures equal spacing */
  align-items: start;
  width: 100%; /* Ensures it takes the full width */
  gap: 10px; /* Reduced spacing */
  flex-wrap: nowrap; /* Prevents wrapping to the next row */
  margin-bottom: 10px;

  .gvNKbm {
    width: 100% !important; /* Ensure it takes full parent width */
    height: 11px;
    display: flex; /* Makes sure bars align properly */
  }

  .bar-item {
    height: 11px;
    float: left;
    font-size: 12px;
    flex-grow: 1; /* Ensures it distributes width based on available space */
    max-width: 100%; /* Prevents exceeding parent width */
  }
`;

export const ProfilingInfoItemStyled = styled.div`
  flex: 1; /* Ensures equal distribution */
  min-width: 30%; /* Prevents shrinking too much */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevents content from overflowing */
  text-overflow: ellipsis;
  white-space: nowrap;

  .title {
    color: ${({ theme }): string => theme.textColors.GREY_25};
    opacity: 0.8;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .value {
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    width: 100%;
  }

  .profiling-progress-bar {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
`;

export const AdditionalInfoStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    color: ${({ theme }): string => theme.textColors.DARK_GREY_120};
    font-size: 13px;
    cursor: default;
  }
  .value {
    color: ${({ theme }): string => theme.textColors.GREY_40};
    cursor: default;
  }
`;
