// <--- Import Statements Start --->
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
// <--- Import Statements End --->

// <--- Components Start --->
import SectionWithBody from "../../../../components/sectionwithbody";
import EditableInformationSection from "../editableinformationsection";

import ReadOnlyInformation from "../readonlyinformation";
import Flex from "../../../../components/flex";
// <--- Components End --->

// <--- Constants Start --->
import {
  is_policy_linked,
  tagsetLabels,
} from "../../tagsetsdetailpage.constants";
// <--- Constants End --->

// <--- Renderers Start --->
import {
  ApplicableToRenderer,
  CheckboxRenderer,
} from "../readonlyinformation/readonlyinformation.components";
// <--- Renderers End --->

// <--- Schemas Start --->
import { informationSectionSchema } from "../../../../utils/schemas/tagsetsschemas";
// <--- Schemas End --->

// <--- Styles Start --->
import { LinkToPolicyReadOnlyRendrerStyled } from "./informationsection.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { editPencilIcon, linkEmbedIcon } from "../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import {
  InformationSectionProps,
  InformationSectionFormType,
} from "./informationsection.types";

import { ReadOnlyConfig } from "../readonlyinformation/readonlyinformation.types";
import { useGetRefByType } from "../../../../api/refservice";

import { FilterSelectionPanelProps } from "../../../../components/filterselectionpanel";
import { CoreNodesCircleIcon } from "../../../../components";

import { NodeType } from "../../../../app.types";
import { jsonParse, sortListOnSpecificKeyValue } from "../../../../utils";
// <--- Types End --->

const InformationSection = (props: InformationSectionProps): JSX.Element => {
  const {
    state,
    actionsState,
    setState,
    setActionsState,
    applicableToCount,
    isLoading,
    isSetState,
    nodeTypes = [],
  } = props;

  const [nodeTypesSelectOption, setNodeTypesSelectOption] = useState<
    FilterSelectionPanelProps["options"]
  >([]);

  const isClassification = state?.information?.type === "CLS";

  // Form
  const formProvider = useForm<InformationSectionFormType>({
    defaultValues: {
      name: "",
      description: "",
      type: "MNT",
      allow_suggestions: false,
      single_value: false,
      rules: false,
      is_note_required: false,
      is_policy_linked: false,
      tagset_policy_link: "",
    },
    resolver: yupResolver(informationSectionSchema),
    mode: "onChange",
  });
  const {
    getValues,
    formState: { isValid },
    reset,
    setValue,
  } = formProvider;

  // Callbacks
  // Edit information section
  const handleEdit = useCallback(() => {
    setValue("name", state?.information?.name);
    setValue("description", state?.information?.description);
    setValue("type", state?.information?.type);
    setValue("allow_suggestions", state?.information?.allow_suggestions);
    setValue("single_value", state?.information?.single_value);

    setValue("is_note_required", state?.information?.is_note_required);
    setValue(
      is_policy_linked,
      isClassification ? state?.information?.is_policy_linked : false
    );
    setValue(
      "tagset_policy_link",
      isClassification ? state?.information?.tagset_policy_link : ""
    );

    setActionsState((s) => ({
      ...s,
      information: { ...s?.information, isEdit: true },
    }));

    setNodeTypesSelectOption(
      sortListOnSpecificKeyValue({
        list: nodeTypes
          ?.filter((opt) => jsonParse(opt?.adhocInfo, true)?.is_tags_applicable)
          ?.map((opt) => ({
            id: opt?.id,
            label: opt?.name,
            selected: !!state?.information?.applicable_to?.[opt.id],
            icon: (
              <CoreNodesCircleIcon
                mode="tertiary"
                nodeType={opt?.id as NodeType}
                fontSize="9px"
                width="20px"
                height="20px"
              />
            ),
          })),
        key: "label",
      })
    );
  }, [state, formProvider, nodeTypes]);

  // Save changes
  const handleDone = useCallback(() => {
    const {
      name,
      description,
      type,
      allow_suggestions,
      single_value,
      is_note_required,
      is_policy_linked,
      tagset_policy_link,
    } = getValues();

    setState((s) => ({
      ...s,
      tagset_name: name,
      information: {
        ...s?.information,
        name,
        description,
        type,
        allow_suggestions,
        single_value,
        is_note_required,
        is_policy_linked,
        tagset_policy_link,
        applicable_to: nodeTypesSelectOption
          ?.filter((opt) => opt?.selected)
          ?.reduce(
            (prev, next) => ({
              ...prev,
              [next?.id]: {
                name: next?.label,
                count: applicableToCount?.[next?.id]?.count || 0,
              },
            }),
            {}
          ),
      },
    }));

    setActionsState((s) => ({
      ...s,
      information: { isEdit: false, isChanged: true },
    }));
  }, [state, nodeTypesSelectOption, applicableToCount]);

  // Discard changes
  const handleCancel = useCallback(() => {
    reset();

    setActionsState((s) => ({
      ...s,
      information: { ...s?.information, isEdit: false },
    }));
  }, []);

  // Memoized Variables
  const readOnlySectionConfig: ReadOnlyConfig[] = useMemo(() => {
    const {
      name,
      description,
      type,
      allow_suggestions,
      single_value,
      applicable_to,
      require_note,
      tagset_policy_link,
    } = tagsetLabels.tagsetdetail.information;

    const isTypeClassification = state?.information?.type === "CLS";

    return [
      { title: name, value: state?.information?.name },
      { title: description, value: state?.information?.description },
      ...((isTypeClassification && [
        { title: type, value: "Classification" },
      ]) ||
        []),
      {
        title: applicable_to,
        customJSX: (
          <ApplicableToRenderer
            applicableTo={state?.information?.applicable_to}
          />
        ),
      },
      {
        title: single_value,
        customJSX: (
          <CheckboxRenderer checked={state?.information?.single_value} />
        ),
      },
      ...((!isTypeClassification && [
        {
          title: allow_suggestions,
          customJSX: (
            <CheckboxRenderer checked={state?.information?.allow_suggestions} />
          ),
        },
        {
          title: require_note,
          customJSX: (
            <CheckboxRenderer checked={state?.information?.is_note_required} />
          ),
        },
      ]) ||
        []),
      ...((isTypeClassification && [
        {
          title: tagset_policy_link,
          customJSX: (
            <LinkToPolicyReadOnlyRendrerStyled>
              <Flex columnGap={13.6} alignItems="flex-start">
                <CheckboxRenderer
                  checked={state?.information?.is_policy_linked}
                />
                {state?.information?.is_policy_linked &&
                  state?.information?.tagset_policy_link && (
                    <Flex columnGap={6.4} alignItems="flex-start">
                      <div>{linkEmbedIcon("11.2px", "11.2px")}</div>
                      <div className="break-link">
                        {state?.information?.tagset_policy_link}
                      </div>
                    </Flex>
                  )}
              </Flex>
            </LinkToPolicyReadOnlyRendrerStyled>
          ),
        },
      ]) ||
        []),
    ];
  }, [state]);

  useEffect(() => {
    if (isSetState) handleEdit();

    return (): void => {};
  }, [isSetState]);

  const onChangeNodeOptionsSelection = useCallback((updOptions) => {
    setNodeTypesSelectOption(updOptions);
  }, []);
  return (
    <SectionWithBody
      headingProps={{
        title: tagsetLabels.tagsetdetail.information.title,
        isShowIcon: actionsState?.isEdit || false,
        icon: editPencilIcon("15", "15"),
        className: "section-with-body",
        isEditable: actionsState?.information?.isEdit || false,
        onEdit: handleEdit,
        onSave: handleDone,
        onCancel: handleCancel,
        isDisabledSaveButton: !isValid,
        isDisabledEditButton: isLoading,
      }}
      customClass="information-section"
    >
      {!actionsState?.information?.isEdit ? (
        <ReadOnlyInformation config={readOnlySectionConfig} />
      ) : (
        <FormProvider {...formProvider}>
          <EditableInformationSection
            options={nodeTypesSelectOption}
            onChange={onChangeNodeOptionsSelection}
          />
        </FormProvider>
      )}
    </SectionWithBody>
  );
};

export default InformationSection;
