import { AssetConfigType } from "../parsers/asssetoverviewparser";

export const TABLE_ASSET_CONFIG: AssetConfigType = {
  description: {
    sectionName: "Definition",
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    refPageSequence: 1,
    detailPageSequence: 1,
    rowId: 1,
    isFullSpan: false,
    sectionId: "description",
    fields: [
      {
        title: "Business Name",
        id: "title",
        path: "title.value",
        isRowVisibleInNormalView: false,
        isEditable: true,
        type: "input",
        isShowInfoMsg: true,
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Description",
        id: "description",
        path: "description.description.value",
        type: "textarea",
        isEditable: true,
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Domain",
        id: "data_domain",
        path: "classifications.functional_area.value.functional_area_name",
        idPath: "classifications.functional_area.value.functional_area_id",
        type: "select",
        isEditable: true,
        sequence: 3,
        isRowEditableInQuickEdit: false,
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Relationships",
        id: "relationships",
        path: "",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 4,
      },
    ],
  },
  managed_by: {
    sectionName: "Managed By",
    rowId: 1,
    refPageSequence: 2,
    detailPageSequence: 2,
    sectionId: "managed_by",
    fields: [
      {
        title: "Data Owner",
        id: "data_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "System Owner",
        id: "system_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Steward",
        id: "data_stewards",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isDataStewardField: true,
        sequence: 3,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Audit Trail",
        id: "audit_trail",
        sequence: 4,
      },
    ],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },

  custom_definition: {
    refPageSequence: 3,
    rowId: 1,
    detailPageSequence: 3,
    sectionId: "custom_definition",
    sectionName: "Asset-Specific Details",
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: true,
    fields: [
      {
        title: "File Path",
        id: "file_path",
        path: "table_path",
        type: "textarea",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 1,
      },

      {
        title: "Record Count",
        id: "record_count",
        isEditable: false,
        path: "",
        sequence: 2,
        isProfilingField: true,
      },
      {
        title: "Last Scanned On",
        id: "last_scanned_on",
        isEditable: false,
        path: "",
        sequence: 3,
        isProfilingField: true,
      },
      {
        title: "Last Updated On",
        id: "last_updated_on",
        isEditable: false,
        path: "",
        idPath: "",
        sequence: 4,
        isProfilingField: true,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  classification: {
    refPageSequence: 4,
    detailPageSequence: 4,
    sectionId: "classification",
    rowId: 2,
    sectionName: "Tags",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isAlwaysOnRightSide: true,
  },
  lineage: {
    refPageSequence: 5,
    detailPageSequence: 5,
    sectionId: "lineage",
    sectionName: "Lineage",
    isApplicableOnDetailPage: true,
    rowId: 2,
    fields: [
      {
        title: "Table & Sources",
        id: "table_and_sources",
        isEditable: true,
        apiKey: "get_node_parental_info_by_node_type",
        urlParam: ["TBL", "TBL"],
        path: "provenance_scan.provenance.value",
        isLineageField: true,
        sequence: 1,
      },
      {
        title: "Scan Frequence",
        id: "scan_frequency",
        isEditable: false,
        path: "provenance_scan.scan_frequency",
        sequence: 2,
      },
    ],
    isEditable: false,
    isEdited: false,
  },

  data_quality: {
    refPageSequence: 6,
    detailPageSequence: 7,
    sectionId: "data_quality",
    rowId: 3,
    sectionName: "Data Quality",
    isApplicableOnDetailPage: true,
    isEditable: false,
    isEdited: false,
    fields: [
      {
        title: "Overall Score",
        id: "overall_score",
        isEditable: false,
        path: "data_quality",
        sequence: 1,
      },
      {
        title: "Last Updated",
        id: "last_refreshed_on",
        isEditable: false,
        path: "last_refreshed_on",
        sequence: 2,
      },
    ],
  },

  relationships: {
    detailPageSequence: 8,
    sectionId: "relationships",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Relationships",
    fields: [],
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: false,
    isEdited: false,
    isEditable: false,
  },

  additional_info: {
    refPageSequence: 8,
    detailPageSequence: 9,
    sectionId: "additional_info",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Additional Info",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },

  represents: {
    refPageSequence: 7,
    detailPageSequence: 9,
    sectionId: "represents",
    sectionName: "Represents (Linked Tables)",
    isApplicableOnDetailPage: false,
    isEditable: false,
    isEdited: false,
    sectionNameInCompareChangesView: "Lineage",
    sectionTitleInCompareChangesView: "Linked Tables",
  },

  metadata_info: {
    sectionName: "Metadata Info",
    refPageSequence: 8,
    detailPageSequence: 8,
    nodeIdKey: "node_id",
    nodeNameKey: "node_name",
    parentIdKey: "parent_id",
    parentNameKey: "parent_name",
    isEditable: false,
    isEdited: false,
    nodeType: "TBL",
    isApplicableOnDetailPage: false,
    descKey: "node_desc",
  },
};

export const DATASET_ASSET_CONFIG: AssetConfigType = {
  metadata_info: {
    sectionName: "Metadata Info",
    nodeIdKey: "node_id",
    nodeNameKey: "node_name",
    parentIdKey: "parent_id",
    parentNameKey: "parent_name",
    isEditable: false,
    isEdited: false,
    nodeType: "DSR",
    refPageSequence: 6,
    detailPageSequence: 6,
    isApplicableOnDetailPage: false,
    isApplicableOnRefPage: false,
    descKey: "node_desc",
  },

  description: {
    sectionName: "Definition",
    isApplicableOnDetailPage: true,
    refPageSequence: 1,
    detailPageSequence: 1,
    rowId: 1,
    sectionId: "description",

    fields: [
      {
        title: "Description",
        id: "description",
        path: "description.description.value",
        isEditable: true,
        type: "textarea",
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Business Name",
        id: "title",
        path: "title.value",
        isRowVisibleInNormalView: false,
        isEditable: true,
        type: "input",
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Data Domain",
        id: "data_domain",
        path: "classifications.functional_area.value.functional_area_name",
        idPath: "classifications.functional_area.value.functional_area_id",
        type: "select",
        isEditable: true,
        sequence: 3,
        isRowEditableInQuickEdit: false,
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Relationships",
        id: "relationships",
        path: "",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 4,
      },
    ],
    isEdited: false,
    isEditable: false,
  },
  managed_by: {
    rowId: 1,
    refPageSequence: 2,
    detailPageSequence: 2,
    sectionId: "managed_by",
    sectionName: "Managed By",

    fields: [
      {
        title: "Author",
        id: "data_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "System Administrator",
        id: "system_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Steward",
        id: "data_stewards",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isDataStewardField: true,
        sequence: 3,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Audit Trail",
        id: "audit_trail",
        sequence: 4,
      },
    ],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },

  custom_definition: {
    refPageSequence: 3,
    rowId: 1,
    detailPageSequence: 3,
    sectionId: "custom_definition",
    sectionName: "Asset-Specific Details",
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: true,
    fields: [
      {
        title: "URL",
        id: "node_url",
        path: "node_url",
        isRowVisibleInRefViewOnly: true,
        isRowVisibleInNormalView: false,
        isEditable: false,
        sequence: 1,
      },
      {
        title: "Last Refreshed On",
        id: "last_refreshed_on",
        path: "last_refreshed_on",
        isRowVisibleInEditView: false,
        isTimeField: true,
        sequence: 2,
      },
      {
        title: "Preview",
        id: "image_preview",
        path: "node_image_url",
        isRowVisibleInEditView: false,
        sequence: 3,
      },
      {
        title: "Last Scanned On",
        id: "last_scanned_on",
        isEditable: false,
        path: "",
        sequence: 4,
        isProfilingField: true,
      },
      {
        title: "Last Updated On",
        id: "last_updated_on",
        isEditable: false,
        path: "",
        idPath: "",
        sequence: 5,
        isProfilingField: true,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  classification: {
    refPageSequence: 4,
    detailPageSequence: 4,
    rowId: 2,
    sectionId: "classification",
    sectionName: "Tags",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isAlwaysOnRightSide: true,
  },

  lineage: {
    refPageSequence: 5,
    detailPageSequence: 5,
    rowId: 2,
    sectionId: "lineage",
    sectionName: "Lineage",
    isApplicableOnDetailPage: true,
    isSectionHasEditAction: false,

    fields: [
      {
        title: "Table & Sources",
        id: "table_and_sources",
        isEditable: false,
        isLineageField: true,
        sequence: 1,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  relationships: {
    detailPageSequence: 6,
    refPageSequence: 5,
    sectionId: "relationships",
    isFullSpan: true,
    rowId: 3,
    sectionName: "Relationships",
    fields: [],
    isApplicableOnRefPage: false,
    isEdited: false,
    isEditable: false,
  },

  additional_info: {
    refPageSequence: 6,
    detailPageSequence: 6,
    rowId: 3,
    sectionId: "additional_info",
    sectionName: "Additional Info",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isFullSpan: true,
  },
};

export const REPORT_ASSET_CONFIG: AssetConfigType = {
  metadata_info: {
    sectionName: "Metadata Info",
    refPageSequence: 8,
    detailPageSequence: 8,
    nodeIdKey: "node_id",
    nodeNameKey: "node_name",
    parentIdKey: "parent_id",
    parentNameKey: "parent_name",
    isEditable: false,
    isEdited: false,
    isApplicableOnDetailPage: false,
    isApplicableOnRefPage: false,
    descKey: "node_desc",
    nodeType: "DSR",
    nodeSubType: "RPT",
  },
  relationships: {
    detailPageSequence: 6,
    refPageSequence: 6,
    sectionId: "relationships",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Relationships",
    fields: [],
    isApplicableOnRefPage: false,
    isEdited: false,
    isEditable: false,
  },

  description: {
    refPageSequence: 1,
    detailPageSequence: 1,
    rowId: 1,
    sectionId: "description",
    sectionName: "Definition",
    isApplicableOnDetailPage: true,

    fields: [
      {
        title: "Description",
        id: "description",
        path: "description.description.value",
        isEditable: true,
        type: "textarea",
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "URL",
        id: "node_url",
        path: "node_url",
        isRowVisibleInRefViewOnly: false,
        isRowVisibleInNormalView: false,
        isEditable: false,
        sequence: 2,
      },
      {
        title: "Business Name",
        id: "title",
        path: "title.value",
        isRowVisibleInNormalView: false,
        isEditable: true,
        type: "input",
        sequence: 3,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Last Refreshed On",
        id: "last_refreshed_on",
        path: "last_refreshed_on",
        isRowVisibleInEditView: false,
        isTimeField: true,
        sequence: 4,
      },
      {
        title: "Data Domain",
        id: "data_domain",
        path: "classifications.functional_area.value.functional_area_name",
        idPath: "classifications.functional_area.value.functional_area_id",
        type: "select",
        isEditable: true,
        sequence: 5,
        isRowEditableInQuickEdit: false,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Preview",
        id: "image_preview",
        path: "node_image_url",
        isRowVisibleInEditView: false,
        sequence: 6,
      },
      {
        title: "Relationships",
        id: "relationships",
        path: "",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 7,
      },
    ],
    isEdited: false,
    isEditable: false,
  },
  managed_by: {
    refPageSequence: 2,
    detailPageSequence: 2,
    rowId: 1,
    sectionId: "managed_by",
    sectionName: "Managed By",

    fields: [
      {
        title: "Author",
        id: "data_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Admin",
        id: "system_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Steward",
        id: "data_stewards",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isDataStewardField: true,
        sequence: 3,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Audit Trail",
        id: "audit_trail",
        sequence: 4,
      },
    ],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },
  lineage: {
    refPageSequence: 3,
    detailPageSequence: 3,
    rowId: 2,
    sectionId: "lineage",
    sectionName: "Lineage",
    isApplicableOnDetailPage: true,
    fields: [],
    isEdited: false,
    isEditable: false,
    isSectionHasEditAction: false,
  },
  classification: {
    refPageSequence: 4,
    detailPageSequence: 4,
    rowId: 2,
    sectionId: "classification",
    sectionName: "Tags",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isAlwaysOnRightSide: true,
  },

  additional_info: {
    refPageSequence: 5,
    detailPageSequence: 6,
    isFullSpan: true,
    rowId: 4,
    sectionId: "additional_info",
    sectionName: "Additional Info",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },
};

export const TERM_ASSET_CONFIG: AssetConfigType = {
  metadata_info: {
    sectionName: "Metadata Info",
    detailPageSequence: 8,
    nodeNameKey: "term_name",
    parentNameKey: "parent_name",
    rootNameKey: "domain_name",
    isEditable: false,
    isEdited: false,
    isApplicableOnDetailPage: false,
    isApplicableOnRefPage: false,
    refPageSequence: 0,
    descKey: "node_desc",
    nodeType: "TRM",
  },

  description: {
    refPageSequence: 1,
    rowId: 1,
    detailPageSequence: 1,
    sectionId: "description",
    sectionName: "Definition",
    isApplicableOnDetailPage: true,

    fields: [
      {
        title: "Sub-Domain",
        id: "sub_domain",
        path: "description.term_category.value.category_name",
        idPath: "description.term_category.value.category_id",
        isEditable: true,
        isRowEditableInQuickEdit: false,
        type: "select",
        sequence: 2,
        isRequired: true,
      },
      {
        title: "Short Name",
        id: "short_name",
        path: "description.term_name.value",
        alternatePath: "node_name",
        isRowVisibleInNormalView: false,
        isEditable: true,
        type: "input",
        isRequired: true,
        hasMaxLimit: true,
        sequence: 3,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Long Name",
        id: "title",
        path: "title.value",
        isRowVisibleInNormalView: false,
        isEditable: true,
        type: "input",
        sequence: 4,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Description",
        id: "description",
        isEditable: true,
        type: "textarea",
        path: "description.definition.value",
        isRequired: true,
        isRowVisibleInCompareChangesView: true,
        sequence: 5,
      },

      {
        title: "Relationships",
        id: "relationships",
        path: "",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 6,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  managed_by: {
    refPageSequence: 2,
    detailPageSequence: 2,
    rowId: 1,
    sectionId: "managed_by",
    sectionName: "Managed By",
    fields: [
      {
        title: "Data Steward",
        id: "data_stewards",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["true", "USR"],
        sequence: 1,
        isDataStewardField: true,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Owner",
        id: "data_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Audit Trail",
        id: "audit_trail",
        sequence: 3,
      },
    ],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },

  custom_definition: {
    refPageSequence: 3,
    rowId: 1,
    detailPageSequence: 3,
    sectionId: "custom_definition",
    sectionName: "Asset-Specific Details",
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: true,
    fields: [
      {
        title: "Term Type",
        id: "term_type",
        path: "description.term_type.value.term_type_name",
        idPath: "description.term_type.value.term_type_id",
        isEditable: true,
        isRowEditableInQuickEdit: false,
        type: "select",
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Formula",
        id: "formula",
        path: "description.term_formula.value",
        idPath: "description.term_type.value.term_type_id",
        isRowVisibleInEditView: true,
        isRowEditableInQuickEdit: false,
        isEditable: true,
        type: "input",
        sequence: 2,
      },
      {
        title: "Previously known as",
        id: "previously_known_as",
        path: "description.previously_known_as.value",
        isEditable: true,
        isRowEditableInQuickEdit: false,
        isRowVisibleInNormalView: true,
        type: "input",
        sequence: 3,
      },
      {
        title: "Replaces",
        id: "replaces",
        path: "description.replaces.value",
        isEditable: true,
        isRowEditableInQuickEdit: false,
        type: "autocomplete",
        sequence: 4,
      },
      {
        title: "All Replaces Term Deprecated",
        id: "replaced_terms_deprecated",
        path: "description.is_replace_terms_dep.value",
        isRowVisibleInEditView: false,
        isRowVisibleInNormalView: false,
        isRowEditableInQuickEdit: false,
        isEditable: true,
        sequence: 5,
      },

      {
        title: "Replaced By",
        id: "replaced_by",
        path: "description.replaced_by.value",
        sequence: 6,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  classification: {
    refPageSequence: 4,
    detailPageSequence: 4,
    rowId: 3,
    sectionId: "classification",
    sectionName: "Tags",
    fields: [],
    isEdited: false,
    isEditable: false,
    isAlwaysOnRightSide: true,
  },
  business_rules: {
    refPageSequence: 5,
    detailPageSequence: 4,
    rowId: 2,
    sectionId: "business_rules",
    isFullSpan: true,
    sectionName: "Business Rules",
    fields: [],
    isPartOfUsabilityScore: true,
    isEditable: false,
    isEdited: false,
  },

  relationships: {
    detailPageSequence: 6,
    refPageSequence: 5,
    sectionId: "relationships",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Relationships",
    fields: [],
    isApplicableOnRefPage: false,
    isEdited: false,
    isEditable: false,
  },

  lineage: {
    detailPageSequence: 3,
    refPageSequence: 4,
    sectionId: "lineage",
    sectionName: "Lineage",
    isApplicableOnDetailPage: false,
    isApplicableOnRefPage: false,
    rowId: 2,
    fields: [
      {
        title: "",
        id: "table_and_sources",
        isEditable: true,
        apiKey: "get_node_parental_info_by_node_type",
        urlParam: ["TBL", "TBL"],
        path: "linked_columns.linked_columns.value",
        isLineageField: true,
        sequence: 1,
        isColLevel: true,
      },
    ],
    isEditable: false,
    isEdited: false,
  },
  additional_info: {
    refPageSequence: 6,
    detailPageSequence: 7,
    rowId: 4,
    sectionId: "additional_info",
    sectionName: "Additional Info",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isFullSpan: true,
    isPartOfUsabilityScore: true,
  },
};

export const COLUMN_ASSET_CONFIG: AssetConfigType = {
  metadata_info: {
    sectionName: "Metadata Info",
    detailPageSequence: 8,
    refPageSequence: 8,
    nodeIdKey: "node_id",
    nodeNameKey: "node_name",
    parentIdKey: "parent_id",
    parentNameKey: "parent_name",
    rootIdKey: "source_id",
    rootNameKey: "source_name",
    isEditable: false,
    isEdited: false,
    isApplicableOnDetailPage: false,
    isApplicableOnRefPage: false,
    descKey: "node_desc",
    nodeType: "COL",
  },

  description: {
    refPageSequence: 1,
    detailPageSequence: 1,
    rowId: 1,
    sectionId: "description",
    sectionName: "Definition",
    isApplicableOnDetailPage: true,
    fields: [
      {
        title: "Business Name",
        id: "title",
        path: "title.value",
        isRowVisibleInNormalView: false,
        isEditable: true,
        type: "input",
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Description",
        id: "description",
        path: "description.description.value",
        isEditable: true,
        type: "textarea",
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
        isInheritedPath: "description.description.is_inherited",
      },

      {
        title: "Data Domain",
        id: "data_domain",
        path: "classifications.functional_area.value.functional_area_name",
        idPath: "classifications.functional_area.value.functional_area_id",
        type: "select",
        isEditable: true,
        sequence: 3,
        isRowEditableInQuickEdit: false,
        isRowVisibleInCompareChangesView: true,
        isInheritedPath: "classifications.functional_area.value.is_inherited",
        isInheritedByPath:
          "classifications.functional_area.value.inherited_from",
      },
      {
        title: "Relationships",
        id: "relationships",
        path: "",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 4,
      },
    ],
    isEdited: false,
    isEditable: false,
  },
  managed_by: {
    refPageSequence: 2,
    detailPageSequence: 2,
    rowId: 1,
    sectionId: "managed_by",
    sectionName: "Managed By",
    fields: [
      {
        title: "Data Owner",
        id: "data_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "System Owner",
        id: "system_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Steward",
        id: "data_stewards",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isDataStewardField: true,
        sequence: 3,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Audit Trail",
        id: "audit_trail",
        isEditable: false,
        sequence: 4,
      },
    ],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },

  custom_definition: {
    refPageSequence: 3,
    rowId: 1,
    detailPageSequence: 3,
    sectionId: "custom_definition",
    sectionName: "Asset-Specific Details",
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: true,
    fields: [
      {
        title: "Data Type",
        id: "data_type",
        path: "description.data_type",
        idPath: "description.data_type_id",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 1,
      },
      {
        title: "Column Type",
        id: "column_type",
        path: "classifications.col_type_json.value.col_type_name",
        idPath: "classifications.col_type_json.value.col_type_id",
        isEditable: true,
        isRowVisibleInEditView: true,
        isRowEditableInQuickEdit: false,
        type: "select",
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Example",
        id: "example",
        path: "description.example",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 3,
      },

      {
        title: "Column Sub-type",
        id: "column_sub_type",
        path: "classifications.col_subtype",
        isEditable: false,
        isRowVisibleInEditView: false,
        isRowVisibleInRefViewOnly: true,
        sequence: 4,
      },
      {
        title: "Is CDE",
        id: "is_cde",
        path: "classifications.is_col_cde.value",
        isEditable: true,
        isRowEditableInQuickEdit: false,
        type: "checkbox",
        sequence: 5,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Business Rules",
        id: "business_rules",
        path: "description.col_biz_rules.value",
        isEditable: true,
        isRowEditableInQuickEdit: false,
        type: "textarea",
        sequence: 6,
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Profiling",
        id: "count",
        isEditable: false,
        path: "profiling_info.count",
        isProfilingField: true,
        sequence: 7,
      },
      {
        title: "Min-Max",
        id: "min_max",
        isEditable: false,
        path: "profiling_info.min_max",
        isProfilingField: true,
        sequence: 8,
      },
      {
        title: "Visual Distribution",
        id: "visual_distribution",
        isEditable: false,
        path: "profiling_info.distribution",
        idPath: "description.data_type_id",
        sequence: 9,
        isRowVisibleInRefViewOnly: true,
        isProfilingField: true,
      },

      {
        title: "Pattern Distribution",
        id: "pattern_distribution",
        isEditable: false,
        path: "profiling_info.distribution",
        idPath: "description.data_type_id",
        sequence: 10,
        isRowVisibleInNormalView: false,
        isProfilingField: true,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  classification: {
    refPageSequence: 4,
    detailPageSequence: 4,
    rowId: 2,
    sectionId: "classification",
    sectionName: "Tags",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isAlwaysOnRightSide: true,
  },

  lineage: {
    refPageSequence: 5,
    detailPageSequence: 5,
    rowId: 3,
    sectionId: "lineage",
    sectionName: "Lineage",
    isApplicableOnDetailPage: true,
    fields: [
      {
        title: "Sources, Tables & Columns",
        id: "table_and_sources",
        isEditable: true,
        apiKey: "get_node_parental_info_by_node_type",
        urlParam: ["COL", "COL"],
        placeHolder: "Select one or more columns",
        placeHolderForSearch: "Search for columns",
        path: "provenance_scan.provenance.value",
        isColLevel: true,
        isLineageField: true,
        sequence: 1,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  represents: {
    refPageSequence: 6,
    detailPageSequence: 9,
    sectionId: "represents",
    sectionName: "Represents (Linked Tables)",
    isApplicableOnDetailPage: false,
    isApplicableOnRefPage: false,
    isEditable: false,
    isEdited: false,
    sectionNameInCompareChangesView: "Lineage",
    sectionTitleInCompareChangesView: "Sources, Tables & Columns",
  },

  data_quality: {
    refPageSequence: 7,
    detailPageSequence: 7,
    sectionId: "data_quality",
    rowId: 3,
    sectionName: "Data Quality",
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: true,
    isEditable: false,
    isEdited: false,
    fields: [
      {
        title: "Overall Score",
        id: "overall_score",
        isEditable: false,
        path: "quality",
        idPath: "quality",
        sequence: 1,
      },
      {
        title: "Last Updated",
        id: "last_refreshed_on",
        isEditable: false,
        path: "last_refreshed_on",
        sequence: 2,
      },
    ],
  },

  relationships: {
    detailPageSequence: 8,
    sectionId: "relationships",
    isFullSpan: true,
    rowId: 3,
    sectionName: "Relationships",
    fields: [],
    isApplicableOnRefPage: false,
    isEdited: false,
    isEditable: false,
  },

  additional_info: {
    refPageSequence: 8,
    detailPageSequence: 9,
    rowId: 3,
    sectionId: "additional_info",
    sectionName: "Additional Info",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isFullSpan: true,
  },
};

export const RULE_ASSET_CONFIG: AssetConfigType = {
  description: {
    sectionName: "Definition",
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    detailPageSequence: 1,
    refPageSequence: 1,
    rowId: 1,
    isFullSpan: false,
    sectionId: "description",
    fields: [
      {
        title: "Rule Name",
        id: "title",
        path: "node_name",
        isRowVisibleInNormalView: false,
        isRowVisibleInEditView: false,
        sequence: 1,
      },
      {
        title: "Description",
        id: "description",
        path: "overview.description.value",
        sequence: 2,
        isRowVisibleInNormalView: true,
        isEditable: true,
        type: "textarea",
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Data Domain",
        id: "data_domain",
        path: "classifications.functional_area.value.functional_area_name",
        idPath: "classifications.functional_area.value.functional_area_id",
        sequence: 3,
        isRowEditableInQuickEdit: false,
        isRowVisibleInCompareChangesView: true,
        isEditable: true,
        type: "select",
      },

      {
        title: "Relationships",
        id: "relationships",
        path: "",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 4,
      },
    ],
  },
  managed_by: {
    refPageSequence: 2,
    detailPageSequence: 2,
    sectionName: "Managed By",
    rowId: 1,
    sectionId: "managed_by",
    fields: [
      {
        title: "Data Owner",
        id: "data_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Steward",
        id: "data_stewards",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isDataStewardField: true,
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Audit Trail",
        id: "audit_trail",
        sequence: 3,
      },
    ],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },

  custom_definition: {
    refPageSequence: 3,
    rowId: 1,
    detailPageSequence: 3,
    sectionId: "custom_definition",
    sectionName: "Asset-Specific Details",
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: true,
    fields: [
      {
        title: "Rule Type",
        id: "rule_type",
        path: "rule_type",
        sequence: 1,
        isEditable: false,
      },

      {
        title: "Field Name",
        id: "field_name",
        path: "cols_info",
        sequence: 4,
        isEditable: false,
      },

      {
        title: "Filter",
        id: "filter",
        path: "filter_info.filter_text",
        sequence: 5,
        isEditable: false,
      },
      {
        title: "Run Result",
        id: "run_result",
        isEditable: false,
        path: "rule_status_id",
        idPath: "rule_exec_info.rule_exec_result",
        sequence: 6,
      },
      {
        title: "Last Refreshed",
        id: "last_refreshed_on",
        path: "rule_exec_info.rule_exec_on",
        sequence: 7,
        isEditable: false,
      },
      {
        title: "DQ Score",
        id: "dq_score",
        path: "rule_exec_info.rule_score",
        sequence: 9,
        isEditable: false,
      },
      {
        title: "Workflow Status",
        id: "workflow_status",
        sequence: 10,
        path: "wf_info",
        isEditable: false,
      },
      {
        title: "Activity",
        id: "activity",
        sequence: 11,
        path: "rule_exec_info.rule_remarks",
        isEditable: false,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  classification: {
    detailPageSequence: 4,
    refPageSequence: 4,
    sectionId: "classification",
    rowId: 2,
    sectionName: "Tags",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isFullSpan: false,
    isAlwaysOnRightSide: true,
  },

  relationships: {
    detailPageSequence: 5,
    sectionId: "relationships",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Relationships",
    fields: [],
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: false,
    isEdited: false,
    isEditable: false,
  },

  additional_info: {
    refPageSequence: 6,
    detailPageSequence: 6,
    sectionId: "additional_info",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Additional Info",
    fields: [],
    isEdited: false,
    isEditable: false,
  },
  metadata_info: {
    sectionName: "Metadata Info",
    refPageSequence: 8,
    detailPageSequence: 8,
    nodeIdKey: "node_id",
    nodeNameKey: "node_name",
    parentIdKey: "parent_id",
    parentNameKey: "parent_name",
    isEditable: false,
    isEdited: false,
    nodeType: "RLS",
    isApplicableOnDetailPage: false,
    isApplicableOnRefPage: false,
    descKey: "overview.description.value",
    descIdKey: "overview.system_description",
  },
};

export const REFERENCE_DICT_ASSET_CONFIG: AssetConfigType = {
  description: {
    sectionName: "Definition",
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    detailPageSequence: 1,
    refPageSequence: 1,
    rowId: 1,
    isFullSpan: false,
    sectionId: "description",
    fields: [
      {
        sequence: 0,
        title: "Title",
        id: "title",
        path: "node_name",
        isEditable: true,
        type: "input",
        isRowVisibleInNormalView: false,
        isRowVisibleInCompareChangesView: true,
      },
      {
        sequence: 1,
        title: "Description",
        id: "description",
        path: "description.description.value",
        isEditable: true,
        type: "textarea",
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Data Domain",
        id: "data_domain",
        path: "classifications.functional_area.value.functional_area_name",
        idPath: "classifications.functional_area.value.functional_area_id",
        sequence: 2,
        isEditable: true,
        type: "select",
        isRowEditableInQuickEdit: false,
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Relationships",
        id: "relationships",
        path: "",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 3,
      },
    ],
  },
  managed_by: {
    refPageSequence: 3,
    detailPageSequence: 2,
    sectionName: "Managed By",
    rowId: 1,
    sectionId: "managed_by",
    fields: [
      {
        title: "Data Owner",
        id: "data_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Steward",
        id: "data_stewards",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isDataStewardField: true,
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Audit Trail",
        id: "audit_trail",
        sequence: 2,
      },
    ],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },

  custom_definition: {
    refPageSequence: 3,
    rowId: 1,
    detailPageSequence: 3,
    sectionId: "custom_definition",
    sectionName: "Asset-Specific Details",
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: true,
    fields: [
      {
        sequence: 1,
        title: "Is Key/Value Pair",
        id: "is_key_value_pair",
        path: "description.is_key_value_pair.value",
        isEditable: false,
      },
      {
        sequence: 2,
        title: "Is Hierarchial",
        id: "is_hierarchial",
        path: "description.is_hierarchical.value",
        isEditable: false,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  classification: {
    detailPageSequence: 4,
    refPageSequence: 4,
    sectionId: "classification",
    rowId: 2,
    sectionName: "Tags",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isFullSpan: false,
    isAlwaysOnRightSide: true,
  },

  relationships: {
    detailPageSequence: 5,
    sectionId: "relationships",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Relationships",
    fields: [],
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: false,
    isEdited: false,
    isEditable: false,
  },
  additional_info: {
    refPageSequence: 6,
    detailPageSequence: 6,
    sectionId: "additional_info",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Additional Info",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },
  metadata_info: {
    sectionName: "Metadata Info",
    refPageSequence: 8,
    detailPageSequence: 8,
    nodeIdKey: "node_id",
    nodeNameKey: "node_name",
    parentIdKey: "parent_id",
    parentNameKey: "parent_name",
    isEditable: false,
    isEdited: false,
    nodeType: "RFD",
    isApplicableOnDetailPage: false,
    isApplicableOnRefPage: false,
  },
};

export const DSF_ASSET_CONFIG: AssetConfigType = {
  description: {
    sectionName: "Definition",
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    detailPageSequence: 1,
    refPageSequence: 1,
    rowId: 1,
    isFullSpan: false,
    sectionId: "description",
    fields: [
      {
        sequence: 1,
        title: "Description",
        id: "description",
        path: "description.description.value",
        isEditable: true,
        type: "textarea",
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Data Type",
        id: "data_type",
        path: "description.data_type",
        idPath: "description.data_type_id",
        sequence: 2,
        isEditable: false,
        isRowVisibleInEditView: false,
      },

      {
        sequence: 3,
        title: "Title",
        id: "title",
        path: "node_name",
        isRowVisibleInNormalView: false,
        isRowVisibleInCompareChangesView: true,
        isEditable: true,
        type: "input",
      },

      {
        title: "Data Domain",
        id: "data_domain",
        path: "classifications.functional_area.value.functional_area_name",
        idPath: "classifications.functional_area.value.functional_area_id",
        type: "select",
        isEditable: true,
        sequence: 4,
        isRowEditableInQuickEdit: false,
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Relationships",
        id: "relationships",
        path: "",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 5,
      },
    ],
  },
  managed_by: {
    rowId: 1,
    refPageSequence: 2,
    detailPageSequence: 2,
    sectionId: "managed_by",
    sectionName: "Managed By",
    fields: [
      {
        title: "Author",
        id: "data_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "System Administrator",
        id: "system_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Steward",
        id: "data_stewards",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isDataStewardField: true,
        sequence: 3,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Audit Trail",
        id: "audit_trail",
        sequence: 4,
      },
    ],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },

  lineage: {
    refPageSequence: 3,
    detailPageSequence: 3,
    sectionId: "lineage",
    sectionName: "Lineage",
    isApplicableOnDetailPage: true,
    isSectionHasEditAction: false,
    rowId: 2,
    fields: [
      {
        title: "Table & Sources",
        id: "table_and_sources",
        isEditable: false,
        isLineageField: true,
        sequence: 1,
      },
    ],
    isEditable: false,
    isEdited: false,
  },

  classification: {
    refPageSequence: 4,
    detailPageSequence: 4,
    rowId: 2,
    sectionId: "classification",
    sectionName: "Tags",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isAlwaysOnRightSide: true,
  },
  relationships: {
    detailPageSequence: 5,
    sectionId: "relationships",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Relationships",
    fields: [],
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: false,
    isEdited: false,
    isEditable: false,
  },

  additional_info: {
    refPageSequence: 5,
    detailPageSequence: 6,
    rowId: 3,
    sectionId: "additional_info",
    sectionName: "Additional Info",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isFullSpan: true,
  },

  metadata_info: {
    sectionName: "Metadata Info",
    refPageSequence: 8,
    detailPageSequence: 8,
    nodeIdKey: "node_id",
    nodeNameKey: "node_name",
    parentIdKey: "parent_id",
    parentNameKey: "parent_name",
    rootIdKey: "source_id",
    rootNameKey: "source_name",
    isEditable: false,
    isEdited: false,
    nodeType: "DSF",
    isApplicableOnDetailPage: false,
  },
};

// Etl Program Config
export const PLO_ASSET_CONFIG: AssetConfigType = {
  description: {
    sectionName: "Definition",
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    detailPageSequence: 1,
    refPageSequence: 1,
    rowId: 1,
    isFullSpan: false,
    sectionId: "description",
    fields: [
      {
        title: "Description",
        id: "description",
        path: "description.description.value",
        sequence: 1,
        isEditable: true,
        type: "textarea",
      },

      {
        title: "Title",
        id: "title",
        path: "node_name",
        sequence: 2,
        isRowVisibleInNormalView: false,
      },

      {
        title: "Data Domain",
        id: "data_domain",
        path: "classifications.functional_area.value.functional_area_name",
        idPath: "classifications.functional_area.value.functional_area_id",
        type: "select",
        isEditable: true,
        sequence: 3,
        isRowEditableInQuickEdit: false,
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Relationships",
        id: "relationships",
        path: "",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 4,
      },
    ],
  },

  managed_by: {
    rowId: 1,
    refPageSequence: 2,
    detailPageSequence: 2,
    sectionId: "managed_by",
    sectionName: "Managed By",
    fields: [
      {
        title: "Data Owner",
        id: "data_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "System Owner",
        id: "system_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Steward",
        id: "data_stewards",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isDataStewardField: true,
        sequence: 3,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Audit Trail",
        id: "audit_trail",
        sequence: 4,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  custom_definition: {
    refPageSequence: 3,
    rowId: 1,
    detailPageSequence: 3,
    sectionId: "custom_definition",
    sectionName: "Asset-Specific Details",
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: true,
    fields: [
      {
        sequence: 1,
        title: "Parent Node",
        id: "parent_name",
        path: "parent_plo_name",
      },

      {
        sequence: 2,
        title: "Job Name",
        id: "job_name",
        path: "pipeline_details",
      },
      {
        sequence: 3,
        title: "Job Type",
        id: "job_type",
        path: "pipeline_details",
      },

      {
        sequence: 4,
        title: "Source",
        id: "source",
        path: "plo_sources",
      },

      {
        sequence: 5,
        title: "Target",
        id: "target",
        path: "plo_targets",
      },

      {
        title: "Last Run",
        id: "last_run",
        sequence: 6,
        path: "pipeline_details",
      },
      {
        title: "Duration",
        id: "duration",
        path: "pipeline_details",
        sequence: 7,
      },
      {
        title: "Run Status",
        id: "run_status",
        path: "pipeline_details",
        sequence: 8,
      },
    ],
    isEdited: false,
    isEditable: false,
  },

  asset_code: {
    refPageSequence: 5,
    detailPageSequence: 3,
    rowId: 2,
    sectionId: "asset_code",
    sectionName: "Source Code",
    fields: [],
    isApplicableOnDetailPage: false,
    isEdited: false,
    isEditable: false,
  },

  classification: {
    refPageSequence: 3,
    detailPageSequence: 4,
    rowId: 2,
    sectionId: "classification",
    sectionName: "Tags",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isAlwaysOnRightSide: true,
  },

  lineage: {
    refPageSequence: 5,
    detailPageSequence: 5,
    sectionId: "lineage",
    sectionName: "Lineage",
    isApplicableOnDetailPage: true,
    rowId: 2,
    fields: [],
    isEditable: false,
    isEdited: false,
  },

  relationships: {
    detailPageSequence: 6,
    sectionId: "relationships",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Relationships",
    fields: [],
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: false,
    isEdited: false,
    isEditable: false,
  },

  additional_info: {
    refPageSequence: 5,
    detailPageSequence: 7,
    rowId: 3,
    sectionId: "additional_info",
    sectionName: "Additional Info",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isFullSpan: true,
  },

  metadata_info: {
    sectionName: "Metadata Info",
    refPageSequence: 8,
    detailPageSequence: 8,
    nodeIdKey: "node_id",
    nodeNameKey: "",
    parentIdKey: "parent_id",
    parentNameKey: "parent_name",
    rootIdKey: "source_id",
    rootNameKey: "source_name",
    isEditable: false,
    isEdited: false,
    nodeType: "DSF",
    isApplicableOnDetailPage: false,
  },
};

export const DEFAULT_ASSET_CONFIG: AssetConfigType = {
  description: {
    sectionName: "Definition",
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    refPageSequence: 1,
    detailPageSequence: 1,
    rowId: 1,
    isFullSpan: false,
    sectionId: "description",
    fields: [
      {
        title: "Business Name",
        id: "title",
        path: "title.value",
        isRowVisibleInNormalView: false,
        isEditable: true,
        type: "input",
        isShowInfoMsg: true,
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Description",
        id: "description",
        path: "description.description.value",
        type: "textarea",
        isEditable: true,
        sequence: 3,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Data Domain",
        id: "data_domain",
        path: "classifications.functional_area.value.functional_area_name",
        idPath: "classifications.functional_area.value.functional_area_id",
        type: "select",
        isEditable: true,
        sequence: 4,
        isRowEditableInQuickEdit: false,
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Relationships",
        id: "relationships",
        path: "",
        isEditable: false,
        isRowVisibleInEditView: false,
        sequence: 5,
      },
    ],
  },

  managed_by: {
    refPageSequence: 2,
    detailPageSequence: 2,
    rowId: 1,
    sectionId: "managed_by",
    sectionName: "Managed By",
    fields: [
      {
        title: "Data Owner",
        id: "data_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 1,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "System Owner",
        id: "system_owners",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        sequence: 2,
        isRowVisibleInCompareChangesView: true,
      },

      {
        title: "Data Steward",
        id: "data_stewards",
        type: "multiselect",
        isEditable: true,
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isDataStewardField: true,
        sequence: 3,
        isRowVisibleInCompareChangesView: true,
      },
      {
        title: "Audit Trail",
        id: "audit_trail",
        isEditable: true,
        sequence: 4,
      },
    ],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },

  classification: {
    refPageSequence: 3,
    detailPageSequence: 3,
    sectionId: "classification",
    rowId: 2,
    sectionName: "Tags",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
    isSingleItemInRow: true,
    isAlwaysOnRightSide: true,
  },

  relationships: {
    detailPageSequence: 4,
    sectionId: "relationships",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Relationships",
    fields: [],
    isApplicableOnDetailPage: true,
    isApplicableOnRefPage: false,
    isEdited: false,
    isEditable: false,
  },

  additional_info: {
    refPageSequence: 4,
    detailPageSequence: 5,
    sectionId: "additional_info",
    isFullSpan: true,
    rowId: 4,
    sectionName: "Additional Info",
    fields: [],
    isApplicableOnDetailPage: true,
    isEdited: false,
    isEditable: false,
  },

  metadata_info: {
    sectionName: "Metadata Info",
    refPageSequence: 8,
    detailPageSequence: 8,
    nodeIdKey: "node_id",
    nodeNameKey: "node_name",
    parentIdKey: "parent_id",
    parentNameKey: "parent_name",
    isEditable: false,
    isEdited: false,
    isApplicableOnDetailPage: false,
    descKey: "node_desc",
  },
};
