import { useEffect, useState } from "react";

export const description =
  "Great, your email is verified. Enter below information to create your shiny DvSum Data Insights Account.";

export const creatingAccountText = (
  <>
    Creating your new shiny Data Insights Account.
    <br />
    It will be just a few moments
  </>
);

export const RedirectText = (): JSX.Element => {
  const [time, setTime] = useState<number>(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0)); // <-- Change this line!
    }, 1000);
    return (): void => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      Your account is ready. We will now re-direct you to login page in
      {` ${time} `}
      seconds.
    </div>
  );
};

export const invalidLinkText =
  "Sorry, the registration link is no longer valid. Go ahead and register again.";

export const alreadyHaveAccount = `Already have a DvSum account?`;

export const agreeToTermsAndConditions = `You agree for us to send you information about your account and 
other information about DvSum product functionality from time to 
time. By checking you will agree to our `;
