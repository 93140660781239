// <--- Library Imports Start --->
import { Column } from "ag-grid-community";

import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// <--- Library Imports End --->

// <--- Components Start --->
// <--- Components End --->

// <--- Constants Start --->
import { CENTER_ALIGN_HEADER_CELL_CLS_NAME } from "./aggridtable.constants";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import {
  useWindowSize,
  useGetAppState,
  useContainerWidth,
} from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Renderer Start --->
import { DefaultRendrerForEllipsis } from "./aggrid.rendrer";
// <--- Renderer End --->

// <--- Styles Start --->
import { AgGridStyled } from "./aggridtable.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { gridSortAscIconStr, gridSortDescIconStr } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { ResizedColumnsType, TableProps } from "./aggridtable.types";
import { AgGridLocalPaginationWithSelect } from "../aggridpaginationwithselect";
// <--- Types End --->

function AgGridTable<T>({
  gridId = "",
  onGridReady,
  theme = "ag-theme-alpine",
  debounce = 0,
  onPaginationChanged,
  onPageSizeChange,
  pageSizes = ["5", "10", "20", "50"],
  tableColumns,
  cellRenderers,
  isTableWidthfitTowindowSize = true,
  pagination = true, // to apply pagination in grid data
  pageSize = 10,
  rowData,
  gridRef = undefined,
  isComputeCol = true,
  isShowPaginationComponent = true, // to show pagination component on top
  showOnlyCountPagination = false,
  selectionState,
  onClear,
  showExpandCollapse = false,
  ...props
}: TableProps<T>): JSX.Element {
  const tableRef = useRef<any>(null);

  const [resizedCols, setResizedCols] = useState<ResizedColumnsType>();

  const [windowWidth] = useWindowSize(debounce);
  const { width: containerWidth, ref } = useContainerWidth(debounce);
  const { sideBarPosition } = useGetAppState();

  useEffect(() => {
    if (tableRef?.current) {
      isTableWidthfitTowindowSize && tableRef?.current?.sizeColumnsToFit();
    }
  }, [windowWidth, containerWidth, tableRef, sideBarPosition]);

  const onInnerPaginationChanged = useCallback(
    (event): void => {
      onPaginationChanged && onPaginationChanged(event);
    },
    [tableRef]
  );

  const onChangeGridReady = useCallback(
    (params) => {
      tableRef.current = params?.api;
      isTableWidthfitTowindowSize && params?.api?.sizeColumnsToFit();
      onGridReady && onGridReady(params);
    },
    [tableRef]
  );

  const isEmpty = tableRef?.current?.getDisplayedRowCount() === 0;

  useEffect(() => {
    if (tableRef?.current) {
      if (tableRef?.current?.getDisplayedRowCount() === 0) {
        tableRef?.current?.showNoRowsOverlay();
      } else {
        tableRef?.current?.hideOverlay();
      }
    }
  }, [isEmpty]);

  const columns = useMemo(() => {
    const finalCols = isComputeCol
      ? tableColumns?.map((column) => {
          const currentColField = String(column?.field) || "";

          const isCurrentColResized = resizedCols?.[currentColField];

          return {
            ...(column?.field === "action" && {
              pinned: "right",
              minWidth: 50,
              maxWidth: 80,
            }),

            ...column,
            sortable: column?.sortable ?? true,
            cellRenderer:
              cellRenderers && cellRenderers?.[column?.field]
                ? cellRenderers[column.field]?.render
                : column?.cellRenderer || "DefaultRendrerForEllipsis",
            cellRendererParams:
              cellRenderers &&
              cellRenderers?.[column?.field] &&
              cellRenderers[column.field]?.params,
            headerClass: column?.isTextCenterAlign
              ? column?.headerClass || CENTER_ALIGN_HEADER_CELL_CLS_NAME
              : column?.headerClass || "",
            ...(isCurrentColResized && {
              minWidth: isCurrentColResized?.minWidth,
              width: isCurrentColResized?.currentWidth,
              flex: 0,
            }),
          };
        })
      : tableColumns;

    return finalCols;
  }, [tableColumns, cellRenderers, isComputeCol, resizedCols]);

  const onColumnResizedCallback = useCallback(
    (params): void => {
      props?.onColumnResized?.(params);

      const columnResized = params?.column?.getColDef();
      const currentColField = columnResized?.field || "";
      const newWid = params?.column?.getActualWidth() || 0;
      const allCols = params?.columnApi?.getAllColumns() || [];

      const source = params?.source;

      if (source === "uiColumnDragged") {
        if (currentColField) {
          setResizedCols((prevCols) => {
            return {
              ...prevCols,
              [currentColField]: {
                field: currentColField,
                minWidth: 50,
                currentWidth: newWid,
              },
            };
          });
        }
      } else if (source !== "autosizeColumns") {
        const updatedResizedCols = allCols?.reduce(
          (acc: ResizedColumnsType, col: Column) => {
            const currentColDef = col?.getColDef();
            const fieldId = currentColDef?.field || "";

            if (fieldId) {
              acc[fieldId] = {
                field: fieldId,
                minWidth: 50,
                currentWidth: col?.getActualWidth() || 0,
              };
            }
            return acc;
          },
          {}
        );
        setResizedCols(updatedResizedCols);
      }
    },
    [props]
  );

  const onFirstDataRendered = useCallback(
    (params) => {
      const autoSizeFields = tableColumns
        ?.filter((col) => col?.isAutoSize)
        ?.map((col) => col?.colId || col?.field);

      if (autoSizeFields?.length > 0) {
        params?.columnApi?.autoSizeColumns(autoSizeFields);
        // params.api.sizeColumnsToFit();
      }

      props?.onFirstDataRendered?.(params);
    },
    [tableColumns, props?.onFirstDataRendered]
  );

  return (
    <AgGridStyled
      className={theme}
      ref={ref}
      maxHeight={props?.maxHeight}
      isEmpty={tableRef?.current?.paginationGetRowCount() <= 0}
    >
      {isShowPaginationComponent && (
        <AgGridLocalPaginationWithSelect
          gridId={gridId}
          selectionState={selectionState}
          onClear={onClear}
          gridApi={tableRef?.current}
          showExpandCollapse={showExpandCollapse}
          showOnlyCountPagination={showOnlyCountPagination}
        />
      )}

      <AgGridReact
        animateRows
        localeText={{
          noRowsToShow: "No Records to View",
        }}
        {...props}
        frameworkComponents={{
          ...props?.frameworkComponents,
          DefaultRendrerForEllipsis,
        }}
        ref={gridRef}
        onGridReady={onChangeGridReady}
        rowData={rowData}
        // onFirstDataRendered={
        //   props?.onFirstDataRendered || defaultOnFirstDataRendered
        // } // Use provided or default handler
        onColumnResized={onColumnResizedCallback}
        paginationPageSize={pageSize}
        columnDefs={columns as any}
        onPaginationChanged={onInnerPaginationChanged}
        onFirstDataRendered={onFirstDataRendered}
        pagination={pagination}
        domLayout="autoHeight"
        sortingOrder={["asc", "desc", null]}
        defaultColDef={{
          ...props?.defaultColDef,
          resizable: true,
        }}
        suppressCellSelection={props?.suppressCellSelection || true}
        icons={{
          sortAscending: gridSortAscIconStr,
          sortDescending: gridSortDescIconStr,
        }}
        suppressPaginationPanel
      />
    </AgGridStyled>
  );
}
export default AgGridTable;
