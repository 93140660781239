import styled from "styled-components";

export const ColumnPageStyled = styled.div`
  /* padding: 20px; */
  .dropdown-icon {
    margin-left: 3px;
  }

  .dvsum-loading {
    position: relative;
    top: 350px;
  }
`;

export const HeaderStyled = styled.div`
  padding: 20px;

  .ant-btn-link.inactive-tab {
    color: black;
  }
`;

export const PageContentLayoutStyled = styled.div``;
