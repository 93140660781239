import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button } from "../../../../components";
import { SelectField } from "../../../../components/formfields";
import { HandleSubmitType } from "../../../../app.types";

import { useCancelModal, useGetAppState } from "../../../../customhooks";

import { DataClassififcationStyled } from "./dataclassification.styles";
import {
  DataClassificationContentProps,
  DataClassificationFormType,
  DataClassificationModalProps,
} from "./dataclassification.types";
import { FormStyled } from "../../../../components/form";

import { useRequestWithMethod } from "../../../../api";
import StateHandler from "../../../../components/statehandler/statehandler";

import { useGetMassDataClassificationFields } from "../../../../api/listingsservice";
import FormItemLabel from "../../../../components/form/formitemlabel";

import {
  getPageNode,
  jsonParse,
  openNotification,
  selectFilterOption,
  sortObjectsArrayByKey,
} from "../../../../utils";
import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import { REQUIRED_MESSAGE } from "../../../../constants/formconstants";

const DataClassificationContent = (
  props: DataClassificationContentProps
): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    nodeType,
    isSelectAll,
    selectedNodesIds,
    filterModel,
    countFilter,
  } = modalProps as DataClassificationModalProps;

  const handleCancel = useCancelModal();

  const { alreadySelectedTag, onExecuteRequestMassUpdate, parsedData } = props;

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<DataClassificationFormType>({
    defaultValues: {
      tags: alreadySelectedTag?.[0]?.id || "",
    },
    resolver: yupResolver(
      yup.object().shape({
        tags: yup.string().required(REQUIRED_MESSAGE),
      })
    ),
    mode: "onChange",
  });

  const onSubmit = useCallback(
    (values: DataClassificationFormType) => {
      onExecuteRequestMassUpdate([
        {
          tags: [values?.tags],
          tags_action: "ADD",
          isSelectAll: isSelectAll || false,
          [nodeType === "TRM"
            ? "trm_ids"
            : nodeType === "TBL"
            ? "tbl_ids"
            : nodeType === "COL"
            ? "col_ids"
            : nodeType === "DSR"
            ? "bo_ids"
            : "node_ids"]: [...(selectedNodesIds || [])],
          filterModel: filterModel || {},
          countFilter: countFilter || "all",
        },
      ]);
    },
    [isSelectAll, selectedNodesIds, filterModel, countFilter, nodeType]
  );

  // Memoized Variables

  const tagsFieldSelectOptions = useMemo(() => {
    const sortedTags = sortObjectsArrayByKey(
      parsedData?.field_data,
      "value_name"
    );

    const sortedTagSelctOptions = sortedTags?.map((item) => ({
      value: item?.id,
      label: item?.value_name,
      labelText: item?.value_name,
      labelDesc: item?.desc,
    }));

    return sortedTagSelctOptions || [];
  }, [parsedData]);

  return (
    <DataClassififcationStyled>
      <FormStyled
        onFinish={handleSubmit(onSubmit) as HandleSubmitType}
        isItemColumnLayout
      >
        <div className="main-content">
          <FormItemLabel label={parsedData?.field_display_name}>
            <SelectField
              control={control}
              setValue={setValue}
              isAllowClear={false}
              name="tags"
              placeholder="Select"
              options={tagsFieldSelectOptions}
              shouldAddDescInLabel
              filterOption={selectFilterOption}
            />
          </FormItemLabel>
        </div>
        <div className="modal-actions-sec">
          <Button id="cancel" width="78px" onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            id="primary"
            width="78px"
            onClick={(): void => {}}
            disabled={!isValid}
            htmlType="submit"
          >
            Add
          </Button>
        </div>
      </FormStyled>
    </DataClassififcationStyled>
  );
};

const DataClassification = (): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    nodeType,
    tags: selectedNodeTagIds = [],
    searchId,
    selectedRowsCount,
    populateData,
  } = modalProps as DataClassificationModalProps;

  const handleCancel = useCancelModal();
  const { parsedData, isFetching, error } = useGetMassDataClassificationFields(
    nodeType,
    true
  );

  const { isTablePage, isColumnPage, isTermPage, isDataSetPage } = getPageNode(
    nodeType
  );

  const onSuccessMassUpdate = useCallback((response) => {
    const parsedRes = isTablePage
      ? jsonParse(response?.data?.FN_UPDATE_GOV_TBLS_DATA)
      : jsonParse(response?.data?.FN_UPDATE_GOV_COLS_DATA);

    const totalCount = parsedRes?.total_cnt || 0;

    populateData({});

    handleCancel();

    openNotification(
      <SuccessNotificationMessage
        message={`${totalCount} ${
          isTermPage
            ? "term"
            : isTablePage
            ? "table"
            : isColumnPage
            ? "column"
            : isDataSetPage
            ? "dataset"
            : "asset"
        }${totalCount > 1 ? "s have" : " has"} been updated.`}
      />
    );
  }, []);

  const {
    onExecuteRequest: onExecuteRequestMassUpdate,
    isLoading: isLoadingMassUpdate,
    error: errorMassUpdate,
  } = useRequestWithMethod(
    "update_field_data",
    [nodeType, searchId],
    false,
    onSuccessMassUpdate
  );

  const tagsField = useMemo(() => {
    return (
      parsedData?.fields?.filter(
        (field) => field?.field_name === "tags"
      )?.[0] || []
    );
  }, [parsedData]);

  const selectedTagId = useMemo(() => {
    return (
      tagsField?.field_data?.filter((tag) =>
        selectedNodeTagIds?.includes(tag?.id)
      ) || []
    );
  }, [selectedNodeTagIds, tagsField]);

  return (
    <StateHandler
      isFetching={isFetching || isLoadingMassUpdate}
      error={error || errorMassUpdate}
      isModal
    >
      <DataClassificationContent
        key={isFetching ? "" : "update-data-classification-tag"}
        alreadySelectedTag={selectedTagId}
        onExecuteRequestMassUpdate={onExecuteRequestMassUpdate}
        parsedData={tagsField}
      />
    </StateHandler>
  );
};

export default DataClassification;
