import { rulePageViewValues } from "../../pages/ruledetailpage/ruledetailpage.constants";
import { ruleDetailPageTabsValues } from "../../pages/ruledetailpage/ruledetailpage.views/ruledetailpagecontent/ruledetailpagecontent.types";
import { RuleDetailPageType } from "./ruledetailpagecontext.types";

export const RULE_DOCUMENT_INITIAL_STATE: RuleDetailPageType = {
  data: {
    breadcrums: [],
    definition: [],
    status: [],
    isWorkFlowEnabled: false,
    sourceId: 0,
    sourceName: "",
    tableId: 0,
    tableName: "",
    userAddedDesc: "",
    systemGeneratedDesc: "",
    ruleName: "",
    rulePriority: "NRM",
    ruleId: 0,
    ruleAppliedOnColumns: [],
    isEditable: false,
    isRuleAlertStatusExists: false,
    exceptionsCount: 0,
    sourceTypeId: "SNF",
    nodeId: 0,
    colSeqType: "",
    ruleAlertStatusId: "VLD",
    jobSchedulers: [],
    ruleColumnIds: [],
    ruleAlertStatus: undefined,
    compareSchemaRef: [],
    workflow: [
      {
        stepDescription: "",
        stepName: "",
        stepNum: 1,
        stepStatus: "",
        stepType: "",
      },
    ],
    ruleResolvedComment: "",
    ruleResolvedReason: "",
    isRuleResolved: false,
    ruleRunStatusId: "VLD",
    executionHistory: [],
    instructions: "",
    isTblSeqSet: false,
    ruleAssignedTo: {
      name: "",
      id: 0,
    },
    thresholdConfig: {
      lowerBound: undefined,
      upperBound: undefined,
      threshType: "NTH",
      windowType: "ALL",
      bucketSize: "1D",
      lookBackDays: 0,
      threshBound: "BT",
      threshFormat: "NUM",
      slicerColumnId: undefined,
      metricTimecolumnId: undefined,
      threshValue: 0,
    },
    classifications: {
      tagsData: {},
      data: [],
    },
    columnsData: [],
    colsInfo: [],
    ruleTypeId: "BLK",
    ruleCatId: "FND",
    ruleExpUrls: "",
    tableFilter: "",
    sawsId: 0,
  },

  pageState: {
    scrollToBottom: false,
    isProfilingInProgress: true,
    activeTab: ruleDetailPageTabsValues?.data,
    activePageView: rulePageViewValues?.overview,
    activeTabView: {
      data: "preview",
      history: "preview",
      definition: "preview",
      instructions: "preview",
      "column-sequence": "preview",
    },
    showAllExceptionsInOnlineRun: false,
    slicerAppliedOn: 0,
    isEditDrawerVisible: false,
    showAllTabs: false,
    isDataTabHidden: false,
    isColumnSeqTabHidden: false,
    isDataTabEnabled: true,
    isTblSeqSet: false,
    isHistoryTabEnabled: true,
    hasUserCancelledScheduleJobNotification: false,
    drawerActiveTab: "overview",
    historyTabState: {
      activeFilter: "SevenDays",
      activeView: "Chart",
    },
  },
};
