import styled from "styled-components";

export const StyledCarasolWrapperStyled = styled.div`
  display: flex;
  column-gap: 3px;
`;

export const StyledCarouselWrapper = styled.div`
  width: 100%;
  margin-bottom: 25px;

  .ant-carousel .slick-dots-bottom {
    bottom: -40px;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: ${({ theme }): string => theme.bgColors.BLUE_116};
    border-radius: 1px;
  }

  .ant-carousel .slick-dots li button {
    background: ${({ theme }): string => theme.bgColors.BLUE_116};
  }
  .grouped-carousel-cards {
    > div {
      flex-wrap: wrap;
      row-gap: 15px;
      padding-bottom: 50px;
    }
  }
`;

export const StyledCarouselContentGroupWrapper = styled.div`
  display: flex !important;
  justify-content: flex-start;
  column-gap: 10px;
  flex: 1;
`;

export const CarouselStyled = styled.div`
  display: flex;
  column-gap: 3px;
  margin-top: 22px;
`;

export const StyledCarouselLeftButtonStyled = styled.div`
  display: flex;
  align-items: center;
  margin-left: -30px;

  height: 263px;

  :hover {
    background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  }
`;

export const StyledCarouselRightButtonStyled = styled.div`
  display: flex;
  align-items: center;

  height: 263px;

  :hover {
    background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  }
`;
