import { ReferenceDictionaryContextType } from "./referencedictionarycontext.types";

export const REFERENCE_DICTIONARY_INITIAL_STATE: ReferenceDictionaryContextType = {
  pageView: "view",
  isEdited:false,

  informationSection: {
    isChanged: false,
    viewMode: "preview",
    data: {
      name: "",
      description: "",
      isKeyValuePair: false,
      isHierarchical: false,
      hierarchicalLevel: 0,
      createdBy: "",
      createdOn: "",
      id: 0,
      mappingConfig: "",
      updatedBy: "",
      updatedOn: "",
    },
  },

  valuesSection: {
    isChanged: false,
    viewMode: "preview",
    gridData: [],
    content: "",
    filePath: "",
    isDataHeaders: false,
  },
};
