import styled from "styled-components";

export const FormContainer = styled.form`
  background: ${(props): string => props?.theme?.bgColors?.WHITE_COLOR};
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 2px 8px
    ${(props): string => props?.theme?.borderColors?.GREY_50}; */
`;

export const Row = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 5px;
`;

export const FieldContainer = styled.div<{ width: string }>`
  width: ${({ width }): string => width};

  .ant-input {
    height: auto !important;
  }
  .ant-select .ant-select-selector {
    border-radius: 0;
  }
  .gritter-notification {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    width: fit-content;
  }
  .search-results-dropdown {
    min-width: unset !important;
    width: 100% !important;
  }
`;

export const SelectedAssets = styled.div`
  padding-right: 14px;
  margin-bottom: 10px;

  .bold-text {
    font-weight: bold;
  }
`;

export const Label = styled.label`
  display: block;
  font-family: OpenSansSemiBold;
  font-size: 14px;
  margin-bottom: 6px;
  color: ${(props): string => props.theme.textColors.GREY_40};
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
`;

export const SectionTitle = styled.div`
  font-family: OpenSans;
  font-size: 13px;
  color: ${(props): string => props.theme.textColors.GREY_26};
  margin-bottom: 10px;
`;

export const AssetRelationshipFormContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 100%;

  .add-criteria-btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }
  }
`;

export const FormWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }): string => (isVisible ? "block" : "none")};
  position: relative;
  top: 10px;
  width: 100%;
  background-color: ${(props): string => props.theme.bgColors.WHITE_COLOR};
  border-radius: 8px;
  box-shadow: 0 2px 8px ${(props): string => props.theme.borderColors.GREY_50};
  z-index: 1;
  margin-bottom: 20px;

  &::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 25px;
    width: 20px;
    height: 20px;
    background-color: ${(props): string => props.theme.bgColors.WHITE_COLOR};
    transform: rotate(45deg);
    box-shadow: -2px -2px 4px ${(props): string => props.theme.borderColors.GREY_50};
  }
`;
