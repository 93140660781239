import { useMemo } from "react";
import { PosterContentWrapperStyled, PosterStyled } from "./poster.styles";
import { PosterProps } from "./poster.types";

import { redirectIcon } from "../../svgs";
import { isJsonString, jsonParse, parseStringToHtml } from "../../utils";
import { useGetZendeskArticles } from "../../api/ruleservice";
import { getCurrentRuleData } from "./poster.utils";
import { removeHtmlTagsFromString } from "../../utils/removeHtmlTagsFromString";

const redirectIconSvg = redirectIcon("8", "8");

const Poster = (props: PosterProps): JSX.Element => {
  const { formId, ruleCategoryId, rulesRef } = props || {};

  const {
    parsedData: zendeskRulesArticle,
    isLoading: rulesListLoading,
    error: rulesListError,
  } = useGetZendeskArticles();

  const zendeskArticleHtmlContent = zendeskRulesArticle?.htmlContent || "";

  const selectedRuleMetaData = useMemo(() => {
    const posterContent = {
      categoryName: getCurrentRuleData(
        zendeskArticleHtmlContent,
        ruleCategoryId,
        "Title"
      ),

      categoryDesc: getCurrentRuleData(
        zendeskArticleHtmlContent,
        ruleCategoryId,
        "Desc"
      ),
      subCategoryName: getCurrentRuleData(
        zendeskArticleHtmlContent,
        formId,
        "Title"
      ),
      subCategoryDesc: getCurrentRuleData(
        zendeskArticleHtmlContent,
        formId,
        "Desc"
      ),
      subCategoryExp: getCurrentRuleData(
        zendeskArticleHtmlContent,
        formId,
        "Example"
      ),
    };

    return posterContent;
  }, [zendeskArticleHtmlContent, ruleCategoryId, formId]);

  const {
    categoryDesc,
    categoryName,
    subCategoryDesc,
    subCategoryExp,
    subCategoryName,
  } = selectedRuleMetaData;

  const useFriendlyRuleName = useMemo(() => {
    const words =
      subCategoryName?.split(" ")?.filter((word) => word?.length) || [];

    return words
      ?.map(
        (word) =>
          `${word?.[0]?.toUpperCase()}${
            word?.length > 1 ? word?.substring(1)?.toLocaleLowerCase() : ""
          }`
      )
      ?.join(" ");
  }, [subCategoryName]);

  const ruleZendeskArticleLink = useMemo(() => {
    const currentRule = rulesRef?.find((item) => item?.id === formId);
    const adhocInfo = jsonParse(currentRule?.adhocInfo || "", true);
    return adhocInfo?.article_link || "";
  }, [rulesRef, formId]);

  return (
    <PosterStyled className="poster-styled">
      <PosterContentWrapperStyled>
        <div className="rule-category-info">
          <div className="name">{categoryName}</div>
          <div className="desc">{categoryDesc}</div>
        </div>
        <div className="rule-info">
          <div className="name">{parseStringToHtml(useFriendlyRuleName)}</div>
          <div className="desc">{subCategoryDesc}</div>
        </div>

        <div className="rule-example">
          <div className="text">Example(s):</div>

          <div
            className="example"
            title={removeHtmlTagsFromString(subCategoryExp)}
          >
            {parseStringToHtml(subCategoryExp)}
          </div>
        </div>
      </PosterContentWrapperStyled>
      <div className="rule-link">
        <a href={ruleZendeskArticleLink} rel="noreferrer" target="_blank">
          Learn more about Rule Type here
        </a>
        {redirectIconSvg}
      </div>
    </PosterStyled>
  );
};
export default Poster;
