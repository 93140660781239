import {
  getAnalysisDetailPageUrl,
  getDataSourceDetailPageUrl,
  getReportDetailPageUrl,
  getSourceDetailPageUrl,
  getTableDetailPageUrl,
  getTermDetailPageUrl,
  getColumnDetailPageUrl,
  getRuleDetailPageUrl,
  getAssetDetailPageUrl,
  getEtlDetailPageUrl,
} from ".";

import { NodeSubType, NodeType } from "../app.types";
import { NodeTypeTabMap } from "../parsers/asssetoverviewparser";
import { getReferenceDictionaryDetailPageUrl } from "./getreferencedictionarydetailpageurl";

export function getDetailpageUrlBasedOnNodeType<T extends NodeType>(
  id: string,
  nodeType: NodeType,
  nodeSubType?: NodeSubType,
  tabName?: NodeTypeTabMap
): string {
  const nodeTypeConfig: {
    [key in NodeType]?: string;
  } = {
    TBL: getTableDetailPageUrl(id, tabName),
    TRM: getTermDetailPageUrl(id),
    SRC: getSourceDetailPageUrl(id, tabName),
    DSR: getDataSourceDetailPageUrl(id),
    ANL: getAnalysisDetailPageUrl(id),
    COL: getColumnDetailPageUrl(id, tabName),
    RLS: getRuleDetailPageUrl(id, undefined, tabName),
    PLO: getEtlDetailPageUrl(id, tabName),
    RFD: getReferenceDictionaryDetailPageUrl(id, tabName),
  };

  const nodeSubTypeConfig: {
    [key in NodeSubType | string]?: string;
  } = {
    RPT: getReportDetailPageUrl(id),
    TOL: getAnalysisDetailPageUrl(id, undefined, undefined, true),
  };

  return (
    nodeSubTypeConfig?.[nodeSubType || ""] ||
    nodeTypeConfig?.[nodeType] ||
    getAssetDetailPageUrl(id) ||
    ""
  );
}
