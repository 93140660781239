import { NodeType } from "../app.types";
import { isDataDomainChanged } from "../components/assetoverview/assetoverview.util";
import { Represents } from "../components/represents";
import { SectionRowProps } from "../components/sectionrow";
import { AssetGridDataType } from "../components/sectionwitheditables";
import { ColumnDataType } from "../parsers";

import {
  EMAIL_KEY_MAMPING,
  ID_KEY_MAPPING,
  NAME_KEY_MAPPING,
  getAssetConfig,
  AssetDocumentResponseType,
  AssetDocumenttParsedType,
  AssetFieldIds,
  ReturnTypeOfAssetRelationshipsData,
} from "../parsers/asssetoverviewparser";

import {
  AssetBuisinessRulesValues,
  ClassificationsType,
  CustomDataSetTag,
  NodePublishedStatusIds,
  RepressentsRespDataItemType,
} from "../parsers/parser.types";

import {
  TableDocResponse,
  UserOrUsersGroupParsedType,
} from "../parsers/tablepage";

import { TermType } from "../parsers/termsparser";

import { getTagsetConfigWithNameKey } from "./gettagsetconfigwithkeyname";
import { getTagsetNameFromTagsetConfig } from "./gettagsetnamesfromtagsetconfig";

export function getAssetFormattedBusinessRules(
  termType: TermType,
  responseBusinessRules: AssetBuisinessRulesValues,
  updatedBusinesRules: AssetBuisinessRulesValues
): AssetDocumentResponseType["business_rules"]["value"] {
  switch (termType) {
    case "CAA":
      return {
        ...responseBusinessRules,
        "Categorical Values": updatedBusinesRules?.["Categorical Values"],
        Patterns: updatedBusinesRules?.Patterns,
        Manual: updatedBusinesRules?.Manual,
      };
    case "BSM":
    case "CMM":
      return {
        ...responseBusinessRules,
        Manual: updatedBusinesRules?.Manual,
        "Value Range": updatedBusinesRules?.["Value Range"],
      };
    case "NCA":
      return {
        ...responseBusinessRules,
        Patterns: updatedBusinesRules?.Patterns,
        Manual: updatedBusinesRules?.Manual,
      };
    default:
      return { ...responseBusinessRules, Manual: updatedBusinesRules?.Manual };
  }
}

export function overviewAssetFormatter(
  data: AssetDocumentResponseType,
  parsedData: AssetDocumenttParsedType,
  score: number,
  userAndGroupsData: UserOrUsersGroupParsedType[],
  isDraftExist: boolean,
  status: NodePublishedStatusIds,
  nodeType: NodeType,
  nodeId: string,
  relationshipsData: AssetGridDataType[]
): AssetDocumentResponseType {
  const isColumnPage = nodeType === "COL";
  const isTermPage = nodeType === "TRM";
  const isRulePage = nodeType === "RLS";

  const changedRows: ReturnTypeOfAssetRelationshipsData =
    relationshipsData?.flatMap((section: AssetGridDataType) =>
      section?.rows
        ?.filter((row) => row?.isChanged)
        ?.map((row) => ({
          ...row,
          srcColName: section?.srcColName || "",
          tgtColName: section?.tgtColName || "",
          nodePath: row?.nodePath || [],
        }))
    ) || [];

  const currentAssetconfig = getAssetConfig(nodeType);

  const managegByDataConfig = currentAssetconfig?.managed_by || {};
  const managegByDataFieldsConfig = managegByDataConfig?.fields || [];

  const getResponseTagset = (
    tagsetId: string
  ): ClassificationsType["tags"][number] => {
    return (data?.classifications?.tags?.find(
      (item) => `${item?.tagset_id}` === tagsetId
    ) || {}) as CustomDataSetTag;
  };

  const getParsedDataClassification = (key: string): SectionRowProps =>
    parsedData?.classification?.data?.find(
      (item) => item?.title === key
    ) as SectionRowProps;

  const managedByFormatedData = (
    responseKeyType: keyof TableDocResponse["managed_by"]
  ): any => {
    const parsedManagedByData = parsedData?.managed_by?.data?.find(
      (item) => item?.fieldId === responseKeyType
    );

    const isLengthSame =
      data?.managed_by?.[responseKeyType]?.value?.length ===
      parsedManagedByData?.selectedValues?.length;

    const isDataChanged = !(
      parsedManagedByData?.selectedValues?.every((selectedItem) =>
        data?.managed_by?.[responseKeyType]?.value
          ?.map(
            (item: any) =>
              // Added email alon witht he name because selected value is in the format of name (email)
              `${item?.[NAME_KEY_MAPPING?.[responseKeyType]]} (${
                item?.[EMAIL_KEY_MAMPING?.[responseKeyType]]
              })`
          )
          ?.includes(selectedItem)
      ) && isLengthSame
    );

    return {
      is_changed: isDataChanged,
      value:
        parsedManagedByData?.selectedValues?.map((item) => {
          const existingUser = (data?.managed_by?.[responseKeyType]
            ?.value as Array<any>)?.find(
            (nestItem) =>
              `${nestItem?.[NAME_KEY_MAPPING[responseKeyType]]} (${
                nestItem?.[EMAIL_KEY_MAMPING[responseKeyType]]
              })` === item
          );

          return (
            existingUser || {
              is_inherited: isDataChanged
                ? false
                : parsedManagedByData?.isInherited,
              user_type:
                userAndGroupsData?.find((nestItem) => nestItem?.value === item)
                  ?.type || "USR",
              [ID_KEY_MAPPING[responseKeyType]]:
                userAndGroupsData?.find((nestItem) => nestItem?.value === item)
                  ?.id || "",
              [NAME_KEY_MAPPING[responseKeyType]]:
                userAndGroupsData?.find((nestItem) => nestItem?.value === item)
                  ?.name || "",
              [EMAIL_KEY_MAMPING[responseKeyType]]:
                userAndGroupsData?.find((nestItem) => nestItem?.value === item)
                  ?.email || "",
            }
          );
        }) || [],
    };
  };

  const tagsetNames = getTagsetNameFromTagsetConfig(
    data?.classifications?.tagset_config
  );

  const tagsetConfigWithNameKey = getTagsetConfigWithNameKey(
    data?.classifications?.tagset_config
  );

  const functionalAreaRow = parsedData?.description?.data?.find(
    (item) => item?.id === AssetFieldIds.dataDomain
  );

  const termCategoryRow = parsedData?.description?.data?.find(
    (item) => item?.id === AssetFieldIds.subDomain
  );

  const entityRow = parsedData?.description?.data?.find(
    (item) => item?.id === AssetFieldIds.entity
  );

  const managedByFieldsUsingConfig = managegByDataFieldsConfig?.map(
    (fieldItem) => {
      const { id = "none" } = fieldItem || {};

      const isAuditTrailField = id === AssetFieldIds.auditTrail;

      return isAuditTrailField
        ? {}
        : {
            [id]: managedByFormatedData(
              id as keyof TableDocResponse["managed_by"]
            ),
          };
    }
  );

  const managedByRst = Object.assign({}, ...managedByFieldsUsingConfig);

  const prevenanceData = parsedData?.represents?.data || [];
  const additionalInfo = parsedData?.additional_info?.data || [];

  const provennaceScan =
    currentAssetconfig?.lineage?.fields?.map((fieldItem) => {
      const { isLineageField = false, id = "none", isColLevel = false } =
        fieldItem || {};
      const currentField = prevenanceData?.find((item) => item?.id === id);
      const tblSrcs = currentField?.sources || [];
      const scanValue = currentField?.value || "";
      const isScanFreqField = id === AssetFieldIds.scanFrequency;

      const mapItem = (
        item: Represents,
        isColLevel: boolean
      ): RepressentsRespDataItemType => {
        const {
          colId = "",
          colName = "",
          tblId = "",
          tblName = "",
          dbId = "",
          dbName = "",
          schemaName = "",
          sourceId,
          schemaId = "",
          sourceName,
          dbSrcTypeId,
        } = item;

        // add db_id and folder_id after confirming from Ramana
        return {
          node_id: isColLevel ? colId : tblId,
          node_name: isColLevel ? colName : tblName,
          parent_id: isColLevel ? tblId : dbId,
          parent_name: isColLevel ? tblName : dbName,
          db_name: dbName,
          folder_name: schemaName,
          db_id: dbId,
          src_type_id: dbSrcTypeId,
          folder_id: schemaId,
          ...(isColLevel ? { root_id: sourceId } : {}),
          ...(isColLevel ? { root_name: sourceName } : {}),
        };
      };

      return isLineageField
        ? {
            provenance: {
              value: tblSrcs?.map((item) => mapItem(item, isColLevel)),
              is_changed: true,
            },
          }
        : isScanFreqField
        ? {
            [id]: scanValue,
          }
        : {};
    }) || [];

  const provScanRst = Object.assign({}, ...provennaceScan);

  const selectedTermType = parsedData?.custom_definition?.data?.find(
    (item) => item?.id === AssetFieldIds?.termType
  )?.selectedValues?.[0] as TermType;

  return {
    ...data,
    title: {
      value:
        parsedData?.description?.data?.find(
          (item) => item?.id === AssetFieldIds?.businessName
        )?.value || "",
      is_changed: true,
    },
    node_id: data?.node_id,
    node_type: data?.node_type || "",
    parent_id: Number(data?.parent_id) || 0,
    managed_by: managedByRst,
    node_name: data?.node_name || "",
    description: {
      audit_trail: data?.description?.audit_trail,
      // for term it is definition, get this consistent or chnage it to definition
      // for terms
      ...(isTermPage && {
        definition: {
          value:
            parsedData?.description?.data?.find(
              (item) => item?.id === AssetFieldIds?.description
            )?.value || "",
          is_changed: true,
        },
      }),

      description: {
        value:
          parsedData?.description?.data?.find(
            (item) => item?.id === AssetFieldIds?.description
          )?.value || "",
        is_changed: true,
        is_inherited: false,
      },

      metadata_score: {
        value: score,
        is_changed: true,
      },
      ...(isTermPage && {
        replaces: {
          value:
            parsedData?.custom_definition?.data
              ?.find((item) => item?.id === AssetFieldIds?.replaces)
              ?.replaces?.map((item) => ({
                term_id: item?.id,
                trm_name: item?.name,
              })) || [],
          is_changed: true,
        },

        term_type: {
          value: {
            term_type_id: selectedTermType,
            term_type_name:
              parsedData?.custom_definition?.data?.find(
                (item) => item?.id === AssetFieldIds?.termType
              )?.value || "",
          },
          is_changed: true,
        },

        replaced_by: data?.description?.replaced_by,

        term_formula: {
          value:
            parsedData?.custom_definition?.data?.find(
              (item) => item?.id === AssetFieldIds?.formula
            )?.value || "",

          is_changed: true,
        },

        term_category: {
          value: termCategoryRow?.selectedValues?.[0]
            ? {
                category_id: Number(termCategoryRow?.selectedValues?.[0]),
                category_name: termCategoryRow?.value || "",
              }
            : {},
          is_changed: true,
        },

        previously_known_as: {
          value:
            parsedData?.custom_definition?.data?.find(
              (item) => item?.id === AssetFieldIds?.previouslyKnownAs
            )?.value || "",
          is_changed: true,
        },

        is_replace_terms_dep: {
          value: !!parsedData?.custom_definition?.data?.find(
            (item) => item?.id === AssetFieldIds?.replacedTermsDeprecated
          )?.value,
          is_changed: true,
        },

        term_name: {
          value:
            parsedData?.description?.data?.find(
              (item) => item?.id === AssetFieldIds?.shortName
            )?.value || "",
          is_changed: true,
        },
      }),

      ...(isColumnPage && {
        data_type: data?.description?.data_type as ColumnDataType,

        data_type_id: parsedData?.custom_definition?.data?.find(
          (item) => item?.id === AssetFieldIds?.dataType
        )?.value as ColumnDataType,

        example:
          parsedData?.custom_definition?.data?.find(
            (item) => item?.id === AssetFieldIds?.example
          )?.value || "",

        col_biz_rules: {
          value:
            parsedData?.custom_definition?.data?.find(
              (item) => item?.id === AssetFieldIds?.businessRules
            )?.value || "",
          is_changed: true,
        },
      }),
    },
    parent_name: data?.parent_name,
    record_count: data?.record_count,
    viewed_count: data?.viewed_count,
    physical_name: data?.physical_name,
    endorsed_count: data?.endorsed_count,

    ...(isRulePage && {
      overview: {
        description: {
          value:
            parsedData?.description?.data?.find(
              (item) => item?.id === AssetFieldIds?.description
            )?.value || "",
          is_changed: true,
        },
      },
    }),

    ...(isTermPage && {
      business_rules: {
        value: getAssetFormattedBusinessRules(
          selectedTermType,
          data?.business_rules?.value,
          parsedData?.business_rules
        ),
        is_changed: true,
      },
    }),
    additional_info: {
      value: [
        ...additionalInfo
          ?.filter((item) => item?.actionId === "edit")
          ?.map((item) => ({
            Text: item?.value,
          })),
        ...additionalInfo
          ?.filter((item) => item?.actionId === "embed_link")
          ?.map((item) => ({
            Link: item?.value,
          })),
      ],
      is_changed: true,
    },
    classifications: {
      ...data?.classifications,
      tags: tagsetNames?.map((tagsetName) => {
        const parsedClassificationItem = getParsedDataClassification(
          tagsetName
        );
        const idOfTagset = tagsetConfigWithNameKey?.[tagsetName]?.tagset_id;
        return {
          value:
            parsedClassificationItem?.selectedValueOfTags?.map(
              (selectedTag) => {
                const orginalTagObj = getResponseTagset(
                  `${idOfTagset}`
                )?.value?.find(
                  (resTag) => `${resTag?.tag_id}` === `${selectedTag?.value}`
                );
                const currProminentTag = parsedClassificationItem?.prominentTags?.tags?.find(
                  (pTag) => pTag?.id === selectedTag?.value
                );
                return {
                  ...orginalTagObj,
                  tag_id: Number.isNaN(Number(selectedTag?.value))
                    ? ""
                    : selectedTag?.value,
                  tag_name: selectedTag?.label,
                  tag_comments: selectedTag?.comment || "",
                  ...(selectedTag?.isProminent && {
                    color_code: selectedTag?.colorCode,
                    is_prominent: true,
                  }),
                  ...(!orginalTagObj && {
                    tag_desc: currProminentTag?.tagDetails?.tagsetDesc || "",
                    tag_created_by: currProminentTag?.tagDetails?.updatedBy,
                    tag_created_on: currProminentTag?.tagDetails?.updatedOn,
                  }),
                };
              }
            ) || [],
          is_changed: true,
          tagset_id: idOfTagset,
          tagset_name: tagsetName,
        };
      }),
      functional_area: {
        value: functionalAreaRow?.selectedValues?.[0]
          ? {
              functional_area_id: functionalAreaRow?.selectedValues?.[0],
              functional_area_name: functionalAreaRow?.value,
            }
          : {},
        is_changed: isDataDomainChanged(
          data?.classifications?.functional_area?.value?.functional_area_id,
          parsedData?.description?.data
        ),
      },
      entity: {
        is_changed: true,
        value: {
          entity_id:
            (isColumnPage ? entityRow?.id : entityRow?.selectedValues?.[0]) ||
            "",
          ...(!isColumnPage && {
            entity_name: entityRow?.value || "",
          }),
          ...(isColumnPage && {
            entity_title: parsedData?.linked_entity?.data?.entity_title,
            entity_match_status:
              parsedData?.linked_entity?.data?.match_status || "",
            entity_match_score:
              parsedData?.linked_entity?.data?.match_score || 0,
            entity_match_source:
              parsedData?.linked_entity?.data?.match_source || "",
            col_entity_json: (
              parsedData?.linked_entity?.data?.matching_entities || []
            )?.map((entity) => ({
              entity_id: entity?.matching_entity_id,
              entity_name: entity?.matching_entity,
              entity_desc: entity?.samples || "",
              entity_match_source: entity?.match_source,
              entity_matching_score: entity?.matching_score,
              entity_selection_status: entity?.selected ? "SEL" : "NSL",
              entity_status: entity?.entity_status || "",
              entity_status_id: entity?.entity_status_id,
            })),
          }),
        },
      },

      ...(isColumnPage && {
        col_type_json: {
          value: {
            col_type_id: parsedData?.custom_definition?.data?.find(
              (item) => item?.id === AssetFieldIds?.columnType
            )?.selectedValues?.[0] as ColumnDataType,

            col_type_name:
              parsedData?.custom_definition?.data?.find(
                (item) => item?.id === AssetFieldIds?.columnType
              )?.value || "",
          },
          is_changed: true,
        },
        is_col_cde: {
          value:
            !!parsedData?.custom_definition?.data?.find(
              (item) => item?.id === AssetFieldIds?.isCDE
            )?.value || false,
          is_changed: true,
        },
      }),
    },
    endorsed_status: {
      value: data?.endorsed_status?.value,
      is_changed: data?.endorsed_status?.is_changed,
    },
    get_access_info: {
      value: data?.get_access_info?.value,
      is_changed: false,
      is_inherited: false,
    },
    is_draft_exists: isDraftExist,
    last_updated_on: data?.last_updated_on,
    provenance_scan: provScanRst,
    published_status: data?.published_status,
    published_status_id: status,
    last_refreshed_on: data?.last_refreshed_on,
    is_wf_enabled: parsedData?.is_wf_enabled,
    // is_editable: data?.is_editable,
    relationships: {
      is_changed: changedRows?.length > 0,
      value: changedRows?.map((row) => {
        return {
          src_node_id: parsedData?.node_id || nodeId,
          src_node_type: nodeType || "",
          tgt_node_id: row?.id || "",
          tgt_node_type: row?.nodeType || "",
          rel_type_id: row?.relationshipTypeId || "",
          rel_type: row?.relationshipType || "",
          node_rel_status: row?.matchingStatus === "APR" ? "PUB" : "",
          comments: row?.comment || "",
          additional_info: {
            source: row?.source || "AAI",
            entity_matching_score: row?.matchingScore || 0,
            entity_selection_status: row?.matchingStatus || "",
            entity_json:
              row?.matchingEntities?.map((entity) => ({
                entity_name: entity?.matching_entity || "",
                entity_id: entity?.matching_entity_id || "",
                entity_desc: entity?.samples || "",
                entity_matching_score: entity?.matching_score || 0,
                entity_match_source: entity?.match_source || "AAI",
                entity_selection_status: entity?.selected ? "SEL" : "UNSEL",
                entity_status: entity?.entity_status || "",
                entity_status_id: entity?.entity_status_id || "",
              })) || [],
          },
          src_col_name: row?.srcColName,
          tgt_col_name: row?.tgtColName,
          action: row?.mode || "",
        };
      }),
    },
  };
}
