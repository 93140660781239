import styled from "styled-components";
import { ellipsis } from "../../../../../../styles/app.styles";
import { hexToRgbA } from "../../../../../../utils";

export const TableDescriptionEditableViewStyled = styled.div`
  .desc-editableView {
    background: ${({ theme }): string => theme.bgColors.BLUE_11};
    padding: 23px 34px 34px 23px;

    margin-bottom: 17px;
    .main-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      position: relative;
      .desc-row-title {
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
        width: 100%;
        font-family: OpenSansSemiBold;
        margin-bottom: 6px;
      }
      .row-value {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        width: 100%;
      }
    }
  }
`;

export const TableEntityRendrerStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .field-value-icon {
    margin-right: 5px;
    display: flex;
    svg {
      width: 12px;
      height: 12px;

      > path {
        fill: ${({ theme }): string => hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
      }

      g {
        path {
          fill: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
        }
        .domain-icon-cls-2 {
          fill: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
        }
      }
    }
  }

  .right-icon {
    margin: 0 8px;
    display: flex;
    align-items: center;
  }

  .field-option-value {
    ${ellipsis}
  }

  .dmn-icon {
    svg {
      g {
        :not(:last-of-type) {
          fill: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
          stroke: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
        }

        :last-of-type {
          path {
            fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
          }
        }
      }
    }
  }

  .core-nodes-circle-icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    font-size: 11px;
    background: ${({ theme }): string =>
      hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
  }
`;
