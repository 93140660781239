import { Edge } from "reactflow";

//       <--Constants Start-->
import {
  LINEAGE_LEVEL_1_NODE_WIDTH,
  LINEAGE_LEVEL_2_NODEE_WIDTH,
  SPACING_BETWEEN_LEVEL_2_NODE,
  LINEAGE_LEVEL_2_NODE_BOX_SPACING,
  COMPONENT_HEIGHTS,
  CHILD_COMPONENT_HEIGHTS,
} from "../lineagepage.constants";
//       <--Constants End-->

//       <--Types Start-->
import { LineageNode } from "../../../parsers/lineageparser/lineageparser.types";
//       <--Types End-->

//       <--Utils start-->
import { getLayoutedElements } from "./layoutedelements";
import { JUNCTION_NODE_TYPE } from "./helper";
import { NodeStateAndTypeProps } from "./lineagepage.utils.types";
import { getPageNode } from "../../../utils";
//       <--Utils end-->

function setWidthAndHeightOfLevel2Nodes(
  level2Nodes: LineageNode[] = [],
  isDQEnabled?: boolean
): LineageNode[] {
  return level2Nodes?.map((level2Node) => {
    const { isFocusedMode, nodeType, nodeId } = level2Node?.data || {};

    let totalHeight = CHILD_COMPONENT_HEIGHTS.nodeHeight;

    const { isColumnPage: isColumnNode } = getPageNode(nodeType);

    if (isFocusedMode) {
      totalHeight += CHILD_COMPONENT_HEIGHTS.nodeHeightExpanded;
    }

    if (isDQEnabled && isColumnNode && isFocusedMode) {
      totalHeight += CHILD_COMPONENT_HEIGHTS.dqLayer;
    }

    return {
      ...level2Node,
      style: {
        ...level2Node?.style,
        width: LINEAGE_LEVEL_2_NODEE_WIDTH,
        height: totalHeight,
      },
    };
  });
}

// Function to compute the node height dynamically based on its state and type
const calculateNodeHeight = ({
  isDQEnabled = false,
  childsOfNode = [],
  nodeInfo,
  isColumnLineage,
}: NodeStateAndTypeProps): number => {
  const { data } = nodeInfo || {};
  const {
    nodeType = "TBL",
    nodeSubType,
    isExpandedMode = false,
    isFocusedMode = false,
    isTablePartOfColumnLineage = false,
    isNodeChildPartOfSearchQuery,
    isPlaceholderNode,
  } = data || {};

  const heightOfChildNodes: number =
    childsOfNode
      ?.map((item) => {
        const columnHeight = Number(item?.style?.height || 0);

        return columnHeight;
      })
      ?.reduce((prev, next) => {
        return prev + next;
      }, 0) || 0;

  const {
    isETLPage: isEtlNode,
    isDataSetPage: isDatasetNode,
    isTablePage: isTableNode,
    isColumnPage: isColumnNode,
  } = getPageNode(nodeType);

  const isReportNode = nodeSubType === "RPT";

  let totalHeight = isPlaceholderNode
    ? COMPONENT_HEIGHTS?.baseHeightForPlaceholderNode
    : COMPONENT_HEIGHTS.baseHeight;

  if (!isExpandedMode && !isPlaceholderNode) {
    // If not expanded, include last refresh and record count (or equivalent)
    totalHeight +=
      isDatasetNode || isReportNode
        ? COMPONENT_HEIGHTS.dsrLastRefresh // For ETL, Dataset nodes add last refresh/last run
        : COMPONENT_HEIGHTS.lastRefresh + COMPONENT_HEIGHTS.recordCount; // For Table nodes add both

    if (isDQEnabled && isTableNode) {
      totalHeight += COMPONENT_HEIGHTS.dqLayer; // Add DQ layer height
    }
  }

  if (
    isTablePartOfColumnLineage ||
    isExpandedMode ||
    isNodeChildPartOfSearchQuery
  ) {
    const childSpacing =
      childsOfNode?.length * COMPONENT_HEIGHTS.childNodeHeight;

    totalHeight += childSpacing + heightOfChildNodes;
  }

  // if (!isEtlNode) {
  totalHeight += COMPONENT_HEIGHTS.columnsCount;
  // }

  // Add footer height in focused mode
  if (isFocusedMode && !isExpandedMode) {
    if (
      (isTableNode || isEtlNode || isDatasetNode || isReportNode) &&
      !isColumnLineage
    ) {
      totalHeight += COMPONENT_HEIGHTS.footer;

      if (isColumnNode && isColumnLineage)
        totalHeight += COMPONENT_HEIGHTS.footer;
    }
  }

  if (!isPlaceholderNode) {
    // Add spacing for drill-down
    totalHeight += COMPONENT_HEIGHTS.drillDownSpacing;
  }

  // Final height calculation
  return totalHeight;
};

function setWidthAndHeightOfLevel1Nodes(
  level1Nodes: LineageNode[] = [],
  level2NodesWithDimensions: LineageNode[] = [],
  isDQEnabled?: boolean,
  isColumnLineage?: boolean
): LineageNode[] {
  return level1Nodes?.map((level1Node) => {
    const childsOfNode =
      level2NodesWithDimensions
        ?.filter((childNode) => childNode?.parentNode === level1Node?.id)
        ?.filter((item) => !item?.hidden) || [];

    const isJunctionNode = level1Node?.type === JUNCTION_NODE_TYPE;

    const nodeWidth = LINEAGE_LEVEL_1_NODE_WIDTH;

    const nodeHeight = calculateNodeHeight({
      isDQEnabled,
      childsOfNode,
      nodeInfo: level1Node,
      isColumnLineage,
    });

    return {
      ...level1Node,
      style: {
        ...level1Node?.style,
        width: isJunctionNode ? 40 : nodeWidth,
        height: isJunctionNode ? 40 : nodeHeight,
      },
    };
  });
}

function setWidthAndHeightOfNodes(
  nodes: LineageNode[] = [],
  isDQEnabled?: boolean,
  isColumnLineage?: boolean
): LineageNode[] {
  const level2Nodes = nodes?.filter((node) => node?.data?.level === 2);
  const level1Nodes = nodes?.filter((node) => node?.data?.level === 1);

  const level2NodesWithDimensions = setWidthAndHeightOfLevel2Nodes(
    level2Nodes,
    isDQEnabled
  );

  const level1NodesWithDimensions = setWidthAndHeightOfLevel1Nodes(
    level1Nodes,
    level2NodesWithDimensions,
    isDQEnabled,
    isColumnLineage
  );

  return [...level1NodesWithDimensions, ...level2NodesWithDimensions];
}

function setPositionOfLevel2Nodes(
  level1Nodes: LineageNode[],
  level2Nodes: LineageNode[],
  isDQEnabled?: boolean
): LineageNode[] {
  const level2NodesTemp: LineageNode[] = [];

  level1Nodes?.map((item) => {
    const childsfNode = level2Nodes?.filter(
      (nestItem) => nestItem?.parentNode === item?.id
    );

    const updatedChildsOfNode: LineageNode[] = [...childsfNode];

    let acc = 0;

    const isExpandedParent = item?.data?.isExpandedMode || false;

    const isParentPlaceholderNode = item?.data?.isPlaceholderNode || false;

    const parentNodeType = item?.data?.nodeType;

    const isParentNodeTbl = parentNodeType === "TBL";
    const isParentNodeEtl = parentNodeType === "PLO";

    const hasLastRefAndRecordCount = isParentNodeTbl || isParentNodeEtl;

    // padding+src+tabl name that will always be visible
    const baseAccumulator = 75;

    let accumalater = baseAccumulator;

    // when node is expanded than evrything will be hidden except src and table name
    if (!isExpandedParent && !isParentPlaceholderNode) {
      // only tbl+col node has DQ layer
      // 40 for tables becoz it has last refresh and record count
      // 20 for other becoz it has only last refresh or record count
      accumalater += hasLastRefAndRecordCount ? (isDQEnabled ? 85 : 45) : 25;
    }

    return updatedChildsOfNode.map((childNode, index) => {
      if (index === 0) {
        acc += accumalater + LINEAGE_LEVEL_2_NODE_BOX_SPACING;
      }

      const node = {
        ...childNode,
        position: { x: 26, y: acc },
      };

      level2NodesTemp.push(node);

      if (!childNode?.hidden) {
        acc +=
          Number(childNode?.style?.height || 0) + SPACING_BETWEEN_LEVEL_2_NODE;
      }

      return node;
    }, 0);
  });
  return level2NodesTemp;
}

export function setXYPositionOfNodesDrillDownMode(
  nodes: LineageNode[],
  edges: Edge[],
  isFirstTime: boolean,
  isDQEnabled?: boolean,
  isColumnLineage?: boolean
): { nodes: LineageNode[]; edges: Edge[] } {
  const nodesWithDimensions = setWidthAndHeightOfNodes(
    nodes,
    isDQEnabled,
    isColumnLineage
  );

  const level1Nodes = nodesWithDimensions?.filter(
    (item) => item?.data?.level === 1
  );

  const level2Nodes = nodesWithDimensions?.filter(
    (item) => item?.data?.level === 2
  );

  const level2NodesWithPos = setPositionOfLevel2Nodes(
    level1Nodes,
    level2Nodes,
    isDQEnabled
  );

  const {
    edges: layoutedEdges = [],
    nodes: layoutedNodes = [],
  } = getLayoutedElements(
    isFirstTime ? level1Nodes : [],
    isFirstTime ? edges : [],
    "LR"
  );

  return {
    nodes: [
      ...(isFirstTime ? layoutedNodes : level1Nodes),
      ...level2NodesWithPos,
    ],
    edges: isFirstTime ? layoutedEdges : edges,
  };
}
