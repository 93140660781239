import {
  AssetConfigType,
  AssetFieldConfigType,
  AssetFieldIds,
  AssetUsabilityScoreType,
} from "../../parsers/asssetoverviewparser";
import { TermType } from "../../parsers/termsparser";

import { LineagePreviewerProps } from "../lineagepreviewer/lineagepreviewer.types";
import { DetailPageSectionRowProps } from "../sectionrow";
import {
  AssetGridDataType,
  AssetGridRowDataType,
} from "../sectionwitheditables";
import { BusinessRulesState } from "../sectionwitheditables/sectionwitheditablebuisinessrules/sectionwitheditablebuisinessrules.types";

import {
  AssetOverviewAdditionalInfoState,
  AssetOverviewProvinenceState,
  AssetOverviewState,
} from "./assetoverview.types";

export const getSortedFieldsBasedOnAccessPage = (
  availableRows: DetailPageSectionRowProps[],
  isRefView: boolean
): DetailPageSectionRowProps[] => {
  const detailPageRows =
    availableRows?.filter((row) => {
      const { isRowVisibleInRefViewOnly = false } = row || {};
      return !isRowVisibleInRefViewOnly;
    }) || [];

  const refPageRows =
    availableRows?.filter((row) => {
      const { isRowHiddenInRefViewOnly = false } = row || {};
      return !isRowHiddenInRefViewOnly;
    }) || [];

  const rowsToRender = isRefView ? refPageRows : detailPageRows;

  const sortedRows = rowsToRender?.sort((a, b) => {
    const sequenceA = a?.sequence || 0;
    const sequenceB = b?.sequence || 0;
    return sequenceA - sequenceB;
  });

  return sortedRows || [];
};

export const getSortedFields = (
  rows: AssetFieldConfigType[]
): AssetFieldConfigType[] => {
  const sortedRows = rows?.sort((a, b) => {
    const sequenceA = a?.sequence || 0;
    const sequenceB = b?.sequence || 0;
    return sequenceA - sequenceB;
  });

  return sortedRows;
};

export const checkIfBusinessRuleExistsOrNot = (
  termType: TermType,
  businessRules: BusinessRulesState
): boolean => {
  switch (termType) {
    case "CAA":
      return (
        !!businessRules?.["Categorical Values"]?.length ||
        !!businessRules?.Patterns?.length ||
        !!businessRules?.Manual
      );
    case "BSM":
    case "CMM":
      return (
        !!businessRules?.["Value Range"]?.min ||
        !!businessRules?.["Value Range"]?.max ||
        !!businessRules?.Manual
      );
    case "NCA":
      return !!businessRules?.Patterns?.length || !!businessRules?.Manual;
    default:
      return !!businessRules?.Manual;
  }
};

export function getAssetUsabilityScore(
  state: AssetOverviewState,
  provinanceState: AssetOverviewProvinenceState,
  lineage: LineagePreviewerProps,
  businessRules: BusinessRulesState,
  updatedTermType: TermType,
  additionalInfoState: AssetOverviewAdditionalInfoState,
  currentAssetConfig: AssetConfigType,
  relationshipsData: AssetGridDataType[]
): AssetUsabilityScoreType {
  const nodeType = currentAssetConfig?.metadata_info?.nodeType;

  const isTerm = nodeType === "TRM";
  const isTbl = nodeType === "TBL";
  const isCol = nodeType === "COL";

  const description = state?.description?.data?.find(
    (item) => item?.id === AssetFieldIds.description
  );

  const descriptionValue = !!description?.value;

  const dataOwner = state?.managedBy?.data?.find(
    (item) => item?.id === AssetFieldIds.dataOwner
  );

  const dataOwnerValue = !!dataOwner?.selectedValues?.length;

  const systemOwner = state?.managedBy?.data?.find(
    (item) => item?.id === AssetFieldIds.systemOwner
  );

  const systemOwnerValue = !!systemOwner?.selectedValues?.length;

  const dataSteward = state?.managedBy?.data?.find(
    (item) => item?.id === AssetFieldIds.dataSteward
  );

  const dataStewardValue = !!dataSteward?.selectedValues?.length;

  const dataDomainRow = state?.description?.data?.find(
    (item) => item.id === AssetFieldIds.dataDomain
  );

  const dataDomainValues = !!dataDomainRow?.selectedValues?.filter(
    (item) => !!item
  )?.length;

  const termCategoryRow = state?.description?.data?.find(
    (item) => item?.id === AssetFieldIds.subDomain
  );

  const categoryValues = !!termCategoryRow?.selectedValues?.filter(
    (item) => !!item
  )?.length;

  const provinance = provinanceState?.data?.find(
    (item) => item?.id === AssetFieldIds.tblAndSrcs
  );

  const isLineageExists = !!lineage?.lineageExists;

  const tags = !!state?.classification?.data?.find(
    (item) =>
      item?.tags?.length || item?.prominentTags?.tags?.length || item?.value
  );

  const ownerValue = dataOwnerValue || systemOwnerValue || dataStewardValue;

  const additionalInfo = !!additionalInfoState?.data?.length;

  const termType = !!state?.description?.data?.find(
    (item) => item?.id === AssetFieldIds.termType
  )?.value;

  const isBusinessRulesExists = checkIfBusinessRuleExistsOrNot(
    updatedTermType,
    businessRules
  );

  const getRelationshipInfo = (
    data: AssetGridDataType[],
    typeId: string
  ): { rows: AssetGridRowDataType[]; exists: boolean } => {
    const relationship = data?.find(
      (item) => item?.relationshipTypeId === typeId
    );
    const rows = relationship?.rows || [];
    const rowsNotDeleted = rows?.filter((item) => !item?.isDeleted)?.length;
    const exists = !!rows?.length && !!rowsNotDeleted;

    return { rows, exists };
  };

  const { exists: domainInRelationShipExists } = getRelationshipInfo(
    relationshipsData,
    "GOV_BY_GOV"
  );

  const { exists: termInRelationShipRowsExists } = getRelationshipInfo(
    relationshipsData,
    "REP_BY_REP"
  );

  const isDomainApplied = dataDomainValues || domainInRelationShipExists;

  const domainOrSubDomainExists = categoryValues || isDomainApplied;

  const commonParams = [
    tags,
    ownerValue,
    additionalInfo,
    descriptionValue,
    domainOrSubDomainExists,
  ];

  const additionalTrmParams = [termType, isBusinessRulesExists];
  const additionalColParams = [isLineageExists, termInRelationShipRowsExists];
  const additionalTblParams = [isLineageExists];

  const isTblOrCol = isTbl || isCol;

  const allParams = isTerm
    ? [...commonParams, ...additionalTrmParams]
    : isTbl
    ? [...commonParams, ...additionalTblParams]
    : isCol
    ? [...commonParams, ...additionalColParams]
    : [...commonParams];

  const weight = 10 / allParams?.length;

  const isAllParamsSelected = allParams?.every(Boolean);

  return {
    totalScore: 10,
    obtainedScore: isAllParamsSelected
      ? 10
      : Number(
          allParams
            ?.reduce((sum, param) => sum + (param ? weight : 0), 0)
            ?.toFixed(1)
        ),
    data: [
      {
        title: "Description",
        applied: descriptionValue,
        isLyinginSec1: true,
      },

      { title: "Ownership", applied: ownerValue, isLyinginSec1: true },
      { title: "Tags", applied: tags, isLyinginSec1: true },

      ...(isTerm
        ? [
            {
              title: termCategoryRow?.title || "",
              applied: categoryValues,
              isLyinginSec1: true,
            },
          ]
        : [
            {
              title: dataDomainRow?.title || "",
              applied: isDomainApplied,
              isLyinginSec1: true,
            },
          ]),

      ...(isTerm
        ? [
            {
              title: "Business Rule(s)",
              applied: isBusinessRulesExists,
              isLyinginSec1: true,
            },
            { title: "Term Type", applied: termType, isLyinginSec1: true },
          ]
        : []),

      ...(isCol
        ? [
            {
              title: "Entity",
              applied: termInRelationShipRowsExists,
              isLyinginSec1: true,
            },
          ]
        : []),

      ...(isTblOrCol
        ? [
            {
              title: "Lineage",
              applied: isLineageExists,
              isLyinginSec1: false,
            },
          ]
        : []),

      {
        title: "Additional Info is specified",
        applied: additionalInfo,
        isLyinginSec1: false,
      },
    ],
  };
}

export const isDataDomainChanged = (
  previousState?: DetailPageSectionRowProps[] | number | string,
  newState?: DetailPageSectionRowProps[]
): boolean => {
  const prevDataDomainId =
    typeof previousState === "number" || typeof previousState === "string"
      ? previousState
      : previousState?.find((item) => {
          return item?.id === AssetFieldIds.dataDomain;
        })?.selectedValues?.[0];

  const newDataDomainId = newState?.find((item) => {
    return item?.id === AssetFieldIds.dataDomain;
  })?.selectedValues?.[0];

  const isDomainChanged = +(prevDataDomainId || 0) !== +(newDataDomainId || 0);

  return isDomainChanged;
};
