import { Tooltip } from "antd";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";

import { numberFormatter } from "../../utils";

import { RepresentsStyled } from "./represents.styles";
import {
  Represents as RepresentsType,
  RepresentsProps,
} from "./represents.types";

import LinkButton from "../linkbutton";
import AdaptiveAssetHierarchy from "../adaptiveassetheirachy/adaptiveassetheirachy";
import { NodeSubType, NodeType } from "../../app.types";

function Represents(props: RepresentsProps): JSX.Element {
  const {
    moreItemsCount,
    moreItemsTitle = "",
    moreItemsJsx,
    isRenderInParent = true,
  } = props;

  return (
    <RepresentsStyled
      className="represents-container"
      wrapItems={!!moreItemsJsx}
    >
      <div className="represents-values">
        {props?.represents?.map((represent, index) => {
          const {
            dbName = "",
            dbId = "",
            schemaName = "",
            schemaId = "",
            tblName = "",
            colName = "",

            colId = "",
            tblId = "",
            sourceName = "",
            dbSrcTypeId,
          } = represent || {};

          const columnNode = colName
            ? [
                {
                  node_id: colId,
                  node_type: "COL" as NodeType,
                  node_name: colName,
                  level: 1,
                },
              ]
            : [];

          const tblNode = tblName
            ? [
                {
                  node_id: tblId,
                  node_type: "TBL" as NodeType,
                  node_name: tblName,
                  level: 2,
                },
              ]
            : [];

          const schemaNode = schemaName
            ? [
                {
                  node_id: schemaId,
                  node_type: "SCM" as NodeType,
                  node_name: schemaName,
                  level: 3,
                },
              ]
            : [];

          const databaseNode = dbName
            ? [
                {
                  node_id: dbId,
                  node_type: "DTB" as NodeType,
                  node_name: dbName,
                  level: 4,
                },
              ]
            : [];

          const sourceNode =
            sourceName && represent?.sourceId
              ? [
                  {
                    node_id: represent?.sourceId,
                    node_type: "SRC" as NodeType,
                    node_name: sourceName,
                    node_sub_type: dbSrcTypeId as NodeSubType,
                    level: 5,
                  },
                ]
              : [];
          return (
            <>
              <AdaptiveAssetHierarchy
                key={`key-${dbName}-${index}`}
                nodes={[
                  ...sourceNode,
                  ...databaseNode,
                  ...schemaNode,
                  ...tblNode,
                  ...columnNode,
                ]}
                mode="primary"
                openRef
                onRemove={props?.onRemove}
              />
            </>
          );
        })}

        {!!moreItemsCount && (
          <Tooltip
            overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} more-items-tooltip`}
            placement="rightTop"
            trigger={["click"]}
            title={moreItemsJsx ?? ""}
            overlayInnerStyle={{
              maxHeight: 225,
              overflowY: "auto",
              minWidth: 625,
              maxWidth: 625,
            }}
            overlayStyle={{ maxWidth: 625 }}
            getPopupContainer={
              isRenderInParent
                ? (trigger): HTMLElement => trigger.parentNode as HTMLElement
                : undefined
            }
          >
            <LinkButton
              className="more-items-count"
              onClick={(e): void => e?.stopPropagation()}
              title={moreItemsTitle}
            >
              +{numberFormatter(`${moreItemsCount}`)}
            </LinkButton>
          </Tooltip>
        )}
      </div>
    </RepresentsStyled>
  );
}

export default Represents;
