import { useState, useCallback, useEffect, useMemo } from "react";
import { AutoComplete } from "antd";

import { useHistory, useParams } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";

import { SearchWithDropdownContainer } from "./searchwithdropdown.styles";
import { searchIcon1, crossIcon } from "../../svgs";

import LinkButton from "../linkbutton";
import { APP_ROUTES, ELEMENT_IDS } from "../../constants";

import {
  useGetHeaderSearchResults,
  useGetRecentSearchKeywords,
} from "../../api/enterprisesearchservice/enterprisesearchservice";

import { useDebounce, useGetAppState } from "../../customhooks";
import {
  getParsedDataForSearch,
  ISearchParseProps,
} from "./searchwithdropdown.parser";

import {
  createDefaultEncodedParamsForGuidedSearch,
  isJsonString,
  jsonParse,
} from "../../utils";
import { getModuleLevelAccessInfo } from "../../utils/getModuleLevelAccessInfo";

const { entsrch_bar: ENTSRCH_BAR } = ELEMENT_IDS;

const SearchWithDropdown = (): JSX.Element => {
  const { hasCatalogAccess, hasGlossaryAccess } = getModuleLevelAccessInfo();

  const { q: encodedFilterParams } = useParams<{
    q: string;
  }>();

  const paramsSearchText = jsonParse(
    window.atob(encodedFilterParams || ""),
    true
  )?.search_text;

  const [dropdownVisibility, setDropDownVisibility] = useState(false);
  const history = useHistory();
  const [searchText, setSearchText] = useState(paramsSearchText);
  const [isKeyDownActive, setIsKyeDownActive] = useState<boolean>(false);

  const searchDebounced = useDebounce(searchText, 500);
  const dropdownVisibilityDebounced = useDebounce(dropdownVisibility, 500);

  const { isOnboardingMode } = useGetAppState();

  const { parsedData, isLoading, refetch } = useGetHeaderSearchResults({
    search_text: searchText,
    parser: ({ data }: { data: ISearchParseProps["data"] }) =>
      getParsedDataForSearch({ searchText, data }),
  });

  const {
    parsedData: recentSearchesData = [],
    isLoading: recentSearchesDataLoading,
  } = useGetRecentSearchKeywords();

  useEffect(() => {
    searchDebounced && refetch();
  }, [searchDebounced]);

  const handleOnSearch = useCallback(
    (value) => {
      setSearchText(value);
    },
    [searchText]
  );

  const searchOptions = useMemo((): any[] => {
    return searchText ? parsedData : recentSearchesData; // Menu Default state should be shown here getMenuInitialState
  }, [parsedData, recentSearchesData, searchText]);

  const searchGoToLink = useCallback(
    (encodedSearchObject: string) => {
      if (hasCatalogAccess || hasGlossaryAccess)
        history.push(
          `${APP_ROUTES.private_routes.enterprise_search}/${
            hasCatalogAccess ? "tables" : "terms"
          }/${encodedSearchObject}`
        );
    },
    [hasCatalogAccess, hasGlossaryAccess]
  );

  const handleOnGoButtonClick = useCallback(() => {
    const encodedSearchObject = createDefaultEncodedParamsForGuidedSearch(
      searchText
    );
    searchGoToLink(encodedSearchObject);
  }, [searchText]);

  const handleOnEnterPressed = (e: { key: string }): void => {
    if (e.key === "Enter" && searchText && !isKeyDownActive) {
      setIsKyeDownActive(false);
      const encodedSearchObject = createDefaultEncodedParamsForGuidedSearch(
        searchText
      );

      setDropDownVisibility(false);
      searchGoToLink(encodedSearchObject);
    } else if (e?.key === "ArrowDown" || e?.key === "ArrowUp") {
      setIsKyeDownActive(true);
    }
  };

  const onClearSearchField = useCallback(() => {
    setSearchText("");
  }, [searchText]);

  useEffect(() => {
    setSearchText(paramsSearchText);
  }, [paramsSearchText]);

  useEffect(() => {
    dropdownVisibilityDebounced && setIsKyeDownActive(false);
  }, [dropdownVisibilityDebounced]);

  return (
    <SearchWithDropdownContainer>
      <div className="autocomplete-wrapper">
        <div className="search-icon">{searchIcon1("16px", "16px")}</div>
        <AutoComplete
          dropdownClassName="certain-category-search-dropdown"
          dropdownMatchSelectWidth
          allowClear
          placeholder="Start new search..."
          value={searchText}
          style={{ width: 616 }}
          options={searchOptions}
          {...(!isOnboardingMode && {
            onSelect: (_, option: any): void => {
              setSearchText(option?.title || "");
              !!option?.route && history.push(option?.route);
            },
          })}
          onSearch={handleOnSearch}
          onClear={(): void => {
            setSearchText("");
          }}
          {...(!isOnboardingMode && {
            onKeyUp: handleOnEnterPressed,
          })}
          onDropdownVisibleChange={(visible): void => {
            setDropDownVisibility(visible);
          }}
          open={dropdownVisibility}
          getPopupContainer={(trigger): HTMLElement =>
            trigger?.parentNode as HTMLElement
          }
          id={ENTSRCH_BAR}
          clearIcon={
            (searchText || recentSearchesDataLoading) && (
              <LinkButton
                className="cross-icon"
                height="16px"
                onClick={(): void => {
                  !isLoading &&
                    !recentSearchesDataLoading &&
                    onClearSearchField();
                }}
              >
                {isLoading || recentSearchesDataLoading ? (
                  <LoadingOutlined style={{ fontSize: 15.5 }} spin />
                ) : (
                  crossIcon("15.5px", "15.5px")
                )}
              </LinkButton>
            )
          }
        />
        {(searchText || recentSearchesDataLoading) && (
          <div className="suffix-info">
            {searchText && (
              <LinkButton
                className="go-btn"
                onClick={handleOnGoButtonClick}
                elementId="es-input-searchbar-go"
              >
                Go
              </LinkButton>
            )}
          </div>
        )}
      </div>

      {/*<LinkButton fontFamily="OpenSansSemibold" marginLeft="16px" id="link">
          Saved Searches
        </LinkButton>*/}
    </SearchWithDropdownContainer>
  );
};

export default SearchWithDropdown;
