// <--- Library Imports Start --->
import styled from "styled-components";
// <--- Library Imports End --->

// <--- Components Start --->
import LinkButton from "../linkbutton";
// <--- Components End --->

// <--- Utils Start --->
import { hexToRgbA } from "../../utils";
// <--- Utils End --->

export const DetailPagesOverviewRightSecStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .btn-text-group,
  .governed-btn-group {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  .btn-text-group {
    .awaiting-approval-text {
      cursor: pointer;
    }
  }

  .governed-btn-group {
    cursor: pointer;

    .reject-text,
    .review-changes {
      display: flex;
      gap: 5px;
      align-items: center;
      .review-changes-icon {
        display: flex;
      }

      &.selected {
        svg {
          > g {
            > path {
              fill: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
              stroke: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
            }
          }
          > g {
            > g {
              path {
                :first-of-type {
                  fill: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
                  stroke: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
                }
                :last-of-type {
                  fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
                  stroke: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
                }
              }
            }
          }
        }
      }
    }

    .review-changes {
      span {
        color: ${({ theme }): string => theme?.textColors?.GREY_40};
      }
      .ant-switch {
        height: 25px;
        .ant-switch-handle {
          width: 19px;
          height: 19px;
        }
        .ant-switch-inner {
          display: none;
        }
        &.ant-switch-checked {
          .ant-switch-handle {
            left: calc(100% - 15px - 6.5px);
          }
        }
      }
    }

    .reject-text {
      font-family: OpenSansSemiBold;
      color: ${({ theme }): string => theme?.textColors?.RED_100};
      cursor: pointer;
      svg {
        path {
          fill: ${({ theme }): string => theme?.textColors?.RED_100};
        }
      }
    }
    .approve-button {
      display: flex;
      gap: 5px;
      svg {
        path {
          fill: ${({ theme }): string => theme.borderColors.WHITE_COLOR};
        }
      }
    }
  }

  .btn-group {
    margin-right: 10px;
    display: flex;

    button {
      :not(:last-of-type) {
        border-right: 1px solid
          ${({ theme }): string => theme.borderColors.WHITE_COLOR};
      }
    }

    button:first-of-type {
      border-right: 1px solid
        ${({ theme }): string => theme.borderColors.WHITE_COLOR};
    }
  }

  .edit-icon {
    svg {
      margin-right: 7px;
      path {
        fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
      }
    }
  }
  .submit-for-approval-button {
    margin-left: 8px;
  }

  .saved-info-hint {
    color: ${({ theme }): string => theme.textColors.GREY_31};
    display: flex;
    align-items: center;
    font-style: italic;
    > svg {
      margin-left: 5px;
    }
  }

  .sep-line {
    width: 1px;
    height: 19px;
    background: ${({ theme }): string =>
      hexToRgbA(theme.bgColors.GREY_31, 0.5)};
    margin: 0px 13px;
  }

  .suggestion-count {
    cursor: pointer;
    font-size: 18px;
    font-family: OpenSansSemiBold;
    display: flex;
    align-items: center;

    > svg {
      margin-right: 5px;
    }
  }

  .disabled-suggestion {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const DeleteButtonStyled = styled(LinkButton)`
  &,
  button:disabled {
    display: flex;
    column-gap: 5px;
    align-items: center;
  }

  svg {
    path {
      fill: ${({ theme }): string => theme.textColors.RED_100};
    }
  }
`;
