import {
  AssetFieldConfigType,
  AssetFieldIds,
  AssetReviewChangesParsedType,
  ManagedByObjKeys,
} from "../../parsers/asssetoverviewparser";

import { getSortedFields } from "../assetoverview/assetoverview.util";
import { ValueType } from "./assetcomparechanges.types";

export const getManagedByFieldData = (
  changes: AssetReviewChangesParsedType,
  id: ManagedByObjKeys,
  type: ValueType
): string => {
  const parsedChanges = changes?.[0] || {};

  const { dataOwners, dataStewards, systemOwners } = parsedChanges;

  const { newValue: dataOwnersNewVal = [], oldValue: dataOwnersOldVal = [] } =
    dataOwners || {};

  const {
    newValue: dataStewardsNewVal = [],
    oldValue: dataStewardsOldVal = [],
  } = dataStewards || {};

  const {
    newValue: systemOwnersNewVal = [],
    oldValue: systemOwnersOldVal = [],
  } = systemOwners || {};

  const roleMapping: Record<ManagedByObjKeys, Record<ValueType, string[]>> = {
    data_stewards: { old: dataStewardsOldVal, new: dataStewardsNewVal },
    data_owners: { old: dataOwnersOldVal, new: dataOwnersNewVal },
    system_owners: { old: systemOwnersOldVal, new: systemOwnersNewVal },
  };

  const mappedValue = roleMapping[id]?.[type];
  return mappedValue?.map((item) => item)?.join(", ");
};

export const getIsChangedManagedBy = (
  changes: AssetReviewChangesParsedType,
  id: ManagedByObjKeys
): boolean => {
  const parsedChanges = changes?.[0] || {};

  const { dataOwners, dataStewards, systemOwners } = parsedChanges;

  const { isChanged: dataOwnersIsChanged = false } = dataOwners || {};

  const { isChanged: dataStewardsIsChanged = false } = dataStewards || {};

  const { isChanged: isSystemOwnersChanged } = systemOwners || {};

  const roleMapping: Record<ManagedByObjKeys, boolean> = {
    data_stewards: dataStewardsIsChanged,
    data_owners: dataOwnersIsChanged,
    system_owners: isSystemOwnersChanged,
  };

  const mappedValue = roleMapping[id];
  return mappedValue;
};

export const getFilteredFields = (
  fields: AssetFieldConfigType[]
): AssetFieldConfigType[] => {
  const filteredFields = fields?.filter((field) => {
    const { isRowVisibleInCompareChangesView = false } = field || {};
    return isRowVisibleInCompareChangesView;
  });

  return getSortedFields(filteredFields);
};

export const getDescriptionFieldData = (
  changes: AssetReviewChangesParsedType,
  id: keyof typeof AssetFieldIds,
  type: ValueType
): string => {
  const parsedChanges = changes?.[0] || {};

  const {
    definition,
    isCde,
    colType,
    name,
    title,
    dataDomain,
    term,
    colBusinessRules,
  } = parsedChanges;

  const { newValue: nameNewValue = "", oldValue: nameOldValue = "" } =
    name || {};

  const { newValue: titleNewValue = "", oldValue: titleOldValue = "" } =
    title || {};

  const { newValue: definitionNewVal = "", oldValue: definitionOldVal = "" } =
    definition || {};

  const { newValue: isCdeNewVal = "", oldValue: isCdeOldVal = "" } =
    isCde || {};

  const { newValue: colTypeNewVal = "", oldValue: colTypeOldVal = "" } =
    colType || {};

  const { newValue: dataDomainNewVal = "", oldValue: dataDomainOldVal = "" } =
    dataDomain || {};

  const { newValue: termNewVal = "", oldValue: termOldVal = "" } = term || {};

  const {
    newValue: businessRulesNewVal = "",
    oldValue: businessRulesOldVal = "",
  } = colBusinessRules || {};

  const roleMapping: Record<string, Record<ValueType, string>> = {
    [AssetFieldIds.shortName]: { old: nameOldValue, new: nameNewValue },
    [AssetFieldIds.businessName]: { old: titleOldValue, new: titleNewValue },
    [AssetFieldIds.description]: {
      old: definitionOldVal,
      new: definitionNewVal,
    },
    [AssetFieldIds.columnType]: { old: colTypeOldVal, new: colTypeNewVal },
    [AssetFieldIds.isCDE]: { old: isCdeOldVal, new: isCdeNewVal },
    [AssetFieldIds.dataDomain]: {
      old: dataDomainOldVal,
      new: dataDomainNewVal,
    },
    [AssetFieldIds.termType]: {
      old: termOldVal,
      new: termNewVal,
    },
    [AssetFieldIds.businessRules]: {
      old: businessRulesOldVal,
      new: businessRulesNewVal,
    },
  };

  const mappedValue = roleMapping[id]?.[type];
  return mappedValue;
};

export const getDescriptionFieldTitle = (
  id: keyof typeof AssetFieldIds,
  title: string
): string => {
  const roleMapping: Record<string, string> = {
    [AssetFieldIds.shortName]: "Name",
    [AssetFieldIds.businessName]: "Business Name",
    [AssetFieldIds.termType]: "Term",
  };

  const mappedValue = roleMapping[id] || title;
  return mappedValue;
};

export const getIsChangedDescriptionField = (
  changes: AssetReviewChangesParsedType,
  id: keyof typeof AssetFieldIds
): boolean => {
  const parsedChanges = changes?.[0] || {};

  const {
    definition,
    isCde,
    colType,
    name,
    title,
    dataDomain,
    term,
    colBusinessRules,
  } = parsedChanges;

  const { isChanged: nameIsChanged = false } = name || {};

  const { isChanged: titleIsChanged = false } = title || {};

  const { isChanged: definitionIsChanged = false } = definition || {};

  const { isChanged: isCdeIsChanged = false } = isCde || {};

  const { isChanged: colTypeIsChanged = false } = colType || {};

  const { isChanged: isDataDomainChanged } = dataDomain || {};

  const { isChanged: termIsChanged = false } = term || {};

  const { isChanged: businessRulesIsChanged = false } = colBusinessRules || {};

  const roleMapping: Record<string, boolean> = {
    [AssetFieldIds.shortName]: nameIsChanged,
    [AssetFieldIds.businessName]: titleIsChanged,
    [AssetFieldIds.description]: definitionIsChanged,
    [AssetFieldIds.columnType]: colTypeIsChanged,
    [AssetFieldIds.isCDE]: isCdeIsChanged,
    [AssetFieldIds.dataDomain]: isDataDomainChanged,
    [AssetFieldIds.termType]: termIsChanged,
    [AssetFieldIds.businessRules]: businessRulesIsChanged,
  };

  const mappedValue = roleMapping[id];
  return mappedValue;
};
