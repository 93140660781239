import styled from "styled-components";

export const LinkedEntitySecStyled = styled.div<{ isColRef: boolean }>`
  .entity-row {
    display: flex;
    align-items: center;
    height: 40px;

    .row-title {
      width: ${({ isColRef }): string => (isColRef ? "217px" : "33%")};
    }
  }

  .entity-title-sec {
    .menu-section {
      .menu-sec {
        column-gap: 8px;
      }
    }
  }
`;
