import styled from "styled-components";

export const SectionWithEditableTagsStyled = styled.div<{
  isEditable?: boolean;
  width: number;
}>`
  background: ${({ theme, isEditable }): string =>
    isEditable && theme.bgColors.BLUE_11};
  padding: ${({ isEditable }): any => isEditable && "15px"};
  width: ${({ width }): string => `${width || 340}px`};
`;

export const TagsListingWrapperStyled = styled.div``;
