import { useCallback } from "react";

import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router";

import Flex from "../../../../../../components/flex";
import LinkButton from "../../../../../../components/linkbutton";
import Matchingstatus from "../../../../../../components/matchingstatus";
import { AddEntityButton } from "../../../../../../components/matchingstatus/matchingstatus.components";

import { useOpenDrawer } from "../../../../../../customhooks";

import { ReviewRecEntityFormOnDoneActionProps } from "../../../../../../forms/reviewrecomendedentitymodal/reviewrecomendedentitymodal.types";

import { EntitySelectionStyled } from "./entityselection.styles";
import { getTermDetailPageUrl } from "../../../../../../utils";
import { BreadcrumbItemType } from "../../../../../styledbreadcrum";

function EntitySelection({
  onFocus,
  isDescInherited,
  breadcrumbs,
  isHideMenu = false,
  isColRef = false,
}: {
  onFocus?: () => void;
  isDescInherited?: boolean;
  breadcrumbs: BreadcrumbItemType[];
  isHideMenu?: boolean;
  isColRef?: boolean;
}): JSX.Element {
  const history = useHistory();
  const openDrawer = useOpenDrawer();

  const { setValue, watch } = useFormContext();

  const {
    entity = "",
    desc,
    entityDesc,
    entityId = "",
    entityMatchingStatus,
    matchingScore: watchedMatchingScore,
    matchSource: watchedMatchSource,
    matchingEntities = [],
  } = watch();

  const onEntityClick = useCallback(() => {
    if (isColRef) {
      const url = getTermDetailPageUrl(entityId);
      history?.push(url);
    } else {
      openDrawer({
        drawerId: "term_ref",
        visible: true,
        drawerProps: { id: entityId },
      });
    }
  }, [entityId, entity, isColRef]);

  const onApprove = useCallback(() => {
    setValue("entityMatchingStatus", "APR");
  }, []);

  const onUnApprove = useCallback(() => {
    setValue("entityMatchingStatus", "REW");
  }, []);

  const onDone = useCallback(
    ({
      isUnlink,
      matchingEntities,
      status,
      selectedEntity,
      matchingScore,
      matchSource,
    }: ReviewRecEntityFormOnDoneActionProps) => {
      if (!isUnlink) {
        setValue("entity", selectedEntity?.matching_entity || "");
        setValue("entityId", selectedEntity?.matching_entity_id || "");
        setValue("entityMatchingStatus", status);
        setValue("matchingEntities", matchingEntities);
        setValue("matchingScore", matchingScore || 0);
        setValue("matchSource", matchSource || "AAI");

        if (isDescInherited || !desc || desc === entityDesc) {
          setValue("entityDesc", selectedEntity?.samples || "");
          setValue("desc", selectedEntity?.samples || "");
        }
      } else {
        setValue("entity", "");
        setValue("entityId", "");
        setValue("entityMatchingStatus", "UNL");
        setValue("matchingEntities", matchingEntities);
        setValue("matchingScore", matchingScore || 0);
        setValue("matchSource", matchSource || "AAI");
        onFocus?.();

        if (isDescInherited || !desc || desc === entityDesc) {
          setValue("entityDesc", "");
          setValue("desc", "");
        }
      }
    },
    [entityDesc, isDescInherited, desc]
  );

  const recEntityModalProps = {
    onDone,
    matchingStatus: entityMatchingStatus,
    matchingEntities,
    breadcrumbs,
    watchedMatchingScore,
    watchedMatchSource,
  };

  return (
    <EntitySelectionStyled>
      <Flex>
        {entity ? (
          <>
            <LinkButton
              marginRight="6px"
              showEllipsis
              title={entity}
              height="26px"
              onClick={onEntityClick}
              isTextUnderLine={isColRef}
            >
              {entity}
            </LinkButton>

            <Matchingstatus
              className={
                entityMatchingStatus !== "" && entityMatchingStatus !== "UNL"
                  ? "menu-section"
                  : "add-button"
              }
              matchingStatus={entityMatchingStatus}
              isSpaceBetween
              onApprove={onApprove}
              onUnApprove={onUnApprove}
              isEnableGetPopUpCont
              recEntityModalProps={recEntityModalProps}
              isHideMenu={isHideMenu}
            />
          </>
        ) : (
          !isHideMenu && (
            <AddEntityButton recEntityModalProps={recEntityModalProps} />
          )
        )}
      </Flex>
    </EntitySelectionStyled>
  );
}

export default EntitySelection;
