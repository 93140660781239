import styled from "styled-components";
import { hexToRgbA } from "../../utils";
import { ellipsis } from "../../styles/app.styles";

export const AssetDescEditableViewWrapperStyled = styled.div`
  .desc-editableView {
    background: ${({ theme }): string => theme.bgColors.BLUE_11};
    padding: 23px 34px 34px 23px;

    margin-bottom: 17px;
    .main-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      position: relative;

      .select-field-container {
        display: flex;
        flex-direction: column;

        .error {
          width: inherit;
        }
      }

      .input-field-container {
        display: flex;
        flex-direction: column;
        width: 380px;

        .error {
          position: unset;
          width: inherit;
        }
      }

      .desc-row-title {
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
        width: 100%;
        font-family: OpenSansSemiBold;
        margin-bottom: 6px;

        .required-field {
          color: ${({ theme }): string => theme.textColors.RED_11};
          font-size: 13px;
        }

        .required-icon {
          color: ${({ theme }): string => theme.textColors.RED_11};
        }
      }
      .row-value {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        width: 100%;

        .deprecated-term-sec {
          margin-top: 11px;

          > span {
            margin-left: 9px;
            color: ${({ theme }): string => theme.textColors.BLACK_16};
          }

          .ant-checkbox-wrapper-disabled .ant-checkbox .ant-checkbox-inner {
            background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
          }
        }

        .added-deprecated-term-sec {
          margin-top: 27px;
          width: 365px;
        }
      }

      :nth-of-type(1) {
        .error {
          right: 30%;
        }
      }
    }

    .is-checkbox-row {
      flex-direction: row;
      .row-value {
        width: unset;
      }
    }
  }

  .edit-ref-form-footer {
    display: flex;
    align-items: center;

    .left {
      flex: 1;

      a {
        font-size: 16px;
        font-family: OpenSansSemiBold;
      }
    }
  }

  .ant-select-item-option-content,
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    gap: 3px;

    .workflow-icon {
      margin-top: 8px;
    }
  }
`;

export const ReplaceItemRendererStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.DARK_BLUE};
  margin-right: 20px;
  display: flex;
  align-items: center;

  .core-nodes-circle-icon {
    margin-right: 6px;
  }

  > span {
    flex: 1;
    > span {
      cursor: pointer;
    }
  }
`;

export const AssetEntityRendrerStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .field-value-icon {
    margin-right: 5px;
    display: flex;
    svg {
      width: 12px;
      height: 12px;

      > path {
        fill: ${({ theme }): string => hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
      }

      g {
        path {
          fill: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
        }
        .domain-icon-cls-2 {
          fill: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
        }
      }
    }
  }

  .right-icon {
    margin: 0 8px;
    display: flex;
    align-items: center;
  }

  .field-option-value {
    ${ellipsis}
  }

  .dmn-icon {
    svg {
      g {
        :not(:last-of-type) {
          fill: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
          stroke: ${({ theme }): string =>
            hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
        }

        :last-of-type {
          path {
            fill: ${({ theme }): string => theme.textColors.WHITE_COLOR};
          }
        }
      }
    }
  }

  .core-nodes-circle-icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    font-size: 11px;
    background: ${({ theme }): string =>
      hexToRgbA(theme.bgColors.BLACK_16, 0.8)};
  }
`;
