import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { InputField, TextAreaField } from "../../components/formfields";
import { OverviewCreateSuggestionStyled } from "./overviewcreatesuggestion.styles";
import { suggestAnEditSchema } from "../../utils/schemas/createsuggestionschema";
import StateHandler from "../../components/statehandler/statehandler";
import { useRequestWithMethod } from "../../api";
import { openNotification } from "../../utils";
import { useCancelModal } from "../../customhooks/useCancelModal";
import { useGetAppState, useGetAppType } from "../../customhooks";
import { OverviewCreateSuggestionProps } from "./overviewcreatesuggestion.types";

function OverviewCreateSuggestion(
  props: OverviewCreateSuggestionProps
): JSX.Element {
  const { isRefPage = false, onCancel: onCancelView } = props;

  const { modal } = useGetAppState();
  const { isExtOrDesktop } = useGetAppType();

  const { modalProps = {} } = isRefPage ? { modalProps: { ...props } } : modal;

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(suggestAnEditSchema),
    defaultValues: {
      title: modalProps?.nodeTitle || "",
      description: modalProps?.nodeDesc || "",
    },
  });

  const onCancelModal = useCancelModal();

  const onCancel = onCancelView || onCancelModal;

  const onSuccess = useCallback(() => {
    openNotification(
      <span>
        <span className="action-text">
          Thank you for contributing to
          {modalProps?.nodeType === "TBL"
            ? " Dataset"
            : modalProps?.nodeType === "DSR"
            ? modalProps?.nodeSubType === "RPT"
              ? " Report"
              : " Data Source"
            : " Term"}
          !
        </span>
        DvSum will email you about the status of your suggestions.
      </span>,
      0,
      {
        ...((isExtOrDesktop || isRefPage) && {
          top: isExtOrDesktop ? 0 : 44,
          className: isExtOrDesktop
            ? "desktop-app-center-notification"
            : "ref-page-notification",
          ...(isRefPage && {
            getContainer: (): any => {
              const elements = document.getElementsByClassName(
                "ant-drawer-content"
              );
              return (elements?.length && elements[0]) || document.body;
            },
          }),
        }),
      }
    );
    onCancel?.();
  }, [onCancel]);

  const { onExecuteRequest, isLoading, error } = useRequestWithMethod(
    "create_suggestion",
    [modalProps?.nodeId, modalProps?.nodeType],
    undefined,
    onSuccess
  );

  const onSubmit = useCallback((values) => {
    onExecuteRequest({
      ...values,
      tags:
        values?.tags?.split(",")?.map((item: string) => ({
          tag_id: "",
          tag_name: item,
        })) || [],
    });
  }, []);

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <>
        <OverviewCreateSuggestionStyled
          isRefPage={isRefPage}
          isExtOrDesktop={isExtOrDesktop}
        >
          {(isRefPage || isExtOrDesktop) && (
            <div className="suggest-header">Suggest an Edit</div>
          )}
          <FormStyled
            isItemColumnLayout
            paddingLeft={isRefPage ? "38px" : "118px"}
            paddingRight={isRefPage ? "38px" : "115px"}
            onFinish={handleSubmit(onSubmit) as any}
          >
            <div className="scroll-sec">
              <FormItemStyled
                label="Business Name"
                marginBottom="22px"
                required
              >
                <InputField control={control} width="100%" name="title" />
              </FormItemStyled>
              <FormItemStyled label="Definition" marginBottom="50px" required>
                <TextAreaField
                  control={control}
                  width="100%"
                  height="175.5px"
                  name="description"
                />
              </FormItemStyled>
              <FormItemStyled label="Tags" marginBottom="50px">
                <InputField control={control} width="100%" name="tags" />
              </FormItemStyled>
            </div>
            <FormItemStyled label="" className="form-actions-sec">
              <Button width="82px" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                width="93px"
                marginLeft="8px"
                htmlType="submit"
                disabled={!isValid}
              >
                Submit
              </Button>
            </FormItemStyled>
          </FormStyled>
        </OverviewCreateSuggestionStyled>
      </>
    </StateHandler>
  );
}

export default OverviewCreateSuggestion;
