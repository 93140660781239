import { NodeSubType, NodeType } from "../app.types";
import { AppState } from "../reducers/appreducer/appreducer.types";

export function getDrawerIdBasedOnNodeType(
  nodeType: NodeType,
  nodeSubType?: NodeSubType
): AppState["drawer"]["drawerId"] {
  const nodeTypeConfig: {
    [key in NodeType]?: AppState["drawer"]["drawerId"];
  } = {
    TBL: "table_ref",
    COL: "column_ref",
    TRM: "term_ref",
    DSR: "data_source_ref",
    DSF: "data_source_field_ref",
    RLS: "rule_ref",
    PLO: "etl_ref",
  };

  const nodeSubTypeConfig: {
    [key in NodeSubType | string]?: AppState["drawer"]["drawerId"];
  } = {
    RPT: "report_ref",
  };

  return (
    nodeSubTypeConfig?.[nodeSubType || ""] ||
    nodeTypeConfig?.[nodeType] ||
    "asset_ref_drawer"
  );
}
