import styled, { createGlobalStyle } from "styled-components";
import { hexToRgbA } from "../../utils";
import { ellipsis } from "../../styles/app.styles";

export const AssetOverviewWrapperStyled = styled.div<{
  isEditable?: boolean;
  isRefView?: boolean;
  isSideBarCollapsed?: boolean;
  isAnyGridExpanded?: boolean;
  isSuggestionScreen?: boolean;
}>`
  padding: ${({ isSuggestionScreen }): string =>
    isSuggestionScreen ? "" : " 22px 25px"};

  .usability-score {
    max-height: max-content;
  }

  .main-container {
    position: ${({ isRefView }): string => (isRefView ? "relative" : "")};
    display: flex;
    width: 100%;

    .state-handler-children-wrapper {
      width: 100%;
    }

    .rows-sec {
      margin-right: ${(props): any => props?.isEditable && "22px"};
      /* width of rows should be all space excpet usability score banner width+margin */
      width: ${(props): string =>
        props?.isEditable ? "calc(100% - 250px)" : "100%"};

      .row {
        margin-bottom: ${({ isAnyGridExpanded }): string =>
          isAnyGridExpanded ? "" : "35px"};
      }

      .sec-row {
        .main-row {
          .row-value {
            .is-cde-flex {
              height: unset;
            }
          }
        }
      }

      .full-row {
        /* Take the full viewport width */

        width: ${({ isRefView, isEditable, isSideBarCollapsed }): string =>
          isRefView || !isEditable
            ? "100%"
            : isSideBarCollapsed
            ? "calc(100vw - 140px)"
            : "calc(100vw - 285px)"};
      }
    }
    .suggestion-usability-score-wrapper {
      max-height: max-content;
    }
  }

  .asset-desc-title {
    min-height: ${({ isRefView }): string => (isRefView ? "35px" : "")};
    margin-bottom: ${({ isRefView }): string => (isRefView ? "15px" : "")};
  }

  .asset-title {
    min-height: ${({ isRefView }): string => (isRefView ? "35px" : "")};
    margin-bottom: ${({ isRefView }): string => (isRefView ? "15px" : "")};
  }

  .lineage-field-title {
    display: ${({ isRefView }): string => (isRefView ? "none" : "")};
  }

  .lineage-field-section {
    width: ${({ isRefView }): string => (isRefView ? "100%" : "")};
  }

  .section-title {
    display: ${({ isRefView }): string => (isRefView ? "none" : "")};
  }

  .asset-data-quality-section {
    .row-value {
      width: ${({ isRefView }): string => (isRefView ? "100%" : "")};

      .col-ref-profiling-renderer {
        width: ${({ isRefView }): string => (isRefView ? "100%" : "65%")};
      }
    }
  }
`;

export const AssetOverviewRowStyled = styled.div<{
  isEditable?: boolean;
  isFullWidth?: boolean;
  isRefView?: boolean;
  isAnyRefViewEditOpen?: boolean;
}>`
  &.row {
    display: flex;
    justify-content: space-between;
    border-top: ${({ isRefView, isAnyRefViewEditOpen }): string =>
      isRefView && isAnyRefViewEditOpen ? "1px solid rgb(230, 230, 230)" : ""};

    .left {
      width: 60%;

      .main-row,
      .section-vertical {
        .row-title,
        .title {
          min-width: ${({ isRefView, isEditable }): string =>
            isRefView ? "207px" : isEditable ? "38%" : "24%"};
          max-width: ${({ isRefView, isEditable }): string =>
            isRefView ? "207px" : isEditable ? "38%" : "24%"};
        }
      }

      .over-viev-get-access-info {
        display: flex;
        align-items: center;
        svg {
          margin-right: 13px;
        }
      }

      .access-info-center-align {
        justify-content: center;
      }
    }
    .right {
      width: ${(props): string => (props?.isEditable ? "33%" : "35%")};
      /* margin-left: 128px; */
      .main-row,
      .section-vertical {
        gap: 10px;

        .row-title,
        .title {
          min-width: ${(props): string => (props?.isEditable ? "38%" : "32%")};
          max-width: ${(props): string => (props?.isEditable ? "38%" : "32%")};
        }
        .value-sec {
          ${({ isEditable }): string =>
            !isEditable
              ? `
            min-width: 66%;
            max-width: 58%;
          `
              : ""};
        }
      }
    }

    .section-row {
      padding: ${({ isRefView }): string =>
        isRefView ? "29px 29px 10px;" : ""};
    }

    .classifications-sec {
      .sec-row {
        margin-bottom: 9px;
      }
    }

    /* :nth-of-type(3) {
      .left {
        .section-main-row-value {
          width: 100%;
        }
      }
    } */

    .left {
      width: ${(props): string => (props?.isFullWidth ? "100%" : "")};
      margin-right: ${(props): string => (props?.isFullWidth ? "0px" : "")};
    }

    .right {
      width: ${(props): string => (props?.isFullWidth ? "0px" : "")};
      margin-left: ${(props): string => (props?.isFullWidth ? "0px" : "")};
    }

    .ref-editableView {
      width: 554px;
      height: max-content;
      margin: 29.5px 0 0 0px;
      padding: 20px;

      .main-row {
        margin-bottom: 20px;
      }

      input {
        width: 100%;
      }
    }
  }

  @media (max-width: 1535px) {
    &.row {
      flex-direction: column;
      .left {
        width: 100%;
        margin-right: 0px;

        .main-row,
        .section-vertical {
          .row-title,
          .title {
            min-width: ${({ isRefView, isEditable }): string =>
              isRefView ? "207px" : isEditable ? "38%" : "24%"};
            max-width: ${({ isRefView, isEditable }): string =>
              isRefView ? "207px" : isEditable ? "38%" : "24%"};
          }
        }
      }
      .right {
        width: 100%;
        margin-left: 0px;

        .main-row,
        .section-vertical {
          .row-title,
          .title {
            min-width: ${({ isRefView, isEditable }): string =>
              isRefView ? "207px" : isEditable ? "38%" : "24%"};
            max-width: ${({ isRefView, isEditable }): string =>
              isRefView ? "207px" : isEditable ? "38%" : "24%"};
          }
        }
      }
    }
  }
`;

export const AssetOverviewPopoverGlobalStyled = createGlobalStyle<{
  theme: any;
}>`
  .usability-score-popover {
    .ant-popover-content {
      .ant-popover-arrow {
        top: 1.514719px;
        width: 12px;
        height: 12px;
        .ant-popover-arrow-content {
          border: solid 1px ${({ theme }): string =>
            theme.borderColors.GREY_29};
          background-color: ${({ theme }): string =>
            theme.bgColors.WHITE_COLOR};
          width: 12px;
          box-shadow: none;
          height: 12px;
        }
      }
      .ant-popover-inner {
        min-width: 280.7px;

        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px ${({ theme }): string => theme.borderColors.GREY_29};
        background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        .ant-popover-inner-content {
          padding: 0px;
        }
      }
    }
  }
`;

export const AssetAuditTrailStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};

  .trail-item {
    display: flex;
    align-items: center;
    svg {
      margin-left: 17px;
    }
  }
`;

export const AssetUsabilityScoreRenderStyled = styled.div`
  .usability-score-value {
    cursor: pointer;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    .obtained-score {
      font-family: OpenSansBold;
      margin-right: 2px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  svg {
    margin-left: 7px;
    path {
      stroke: ${({ theme }): string => theme.bgColors.BLUE_116};
    }
  }
`;

export const AssetLastUpdatedWrapperStyled = styled.div``;

export const AssetReportPreviewStyled = styled.div`
  img {
    max-width: 66%;
    padding: 15px;
    border: 1px solid ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};
    border-radius: 5px;
  }
`;

export const AssetUrlPreviewStyled = styled.div`
  ${ellipsis}
`;

export const AssetReplacesRndrerStyled = styled.div`
  .replace-rendrer {
    color: ${({ theme }): string => theme.textColors.DARK_BLUE};
    margin-right: 20px;

    > svg {
      margin-right: 6px;
      path {
        fill: ${({ theme }): string => theme.bgColors.BLUE_116};
      }
    }

    > span {
      cursor: pointer;
    }
  }
`;

export const NodeRefQuickEditWrapperStyled = styled.div`
  .edit-pencil {
    position: absolute;
    right: 18px;
    top: 8px;
  }
`;

export const AssetVisualDistributionStyled = styled.div`
  .main-row {
    pointer-events: none;
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-content {
          margin-top: 15px;

          .ant-card {
            .ant-card-body {
              pointer-events: all;
              padding: 0;
              padding-top: 20;
              background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_5};
            }
          }
        }
      }
    }
    .row-value {
      display: flex;
      align-items: center;
    }
  }
`;

export const MultipleAssetRendererStyled = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
`;

export const AssetNameStyled = styled.div`
  display: flex;
  column-gap: 5px;
`;

export const SchemaRendererStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  .svg {
    color: ${({ theme }): string => theme.textColors.GREY_18};
    font-size: 12px;
  }
`;

export const WorkflowStatusRendererStyled = styled.div`
  display: flex;
`;

export const RunResultRendererStyled = styled.div`
  display: flex;
`;

export const NodeNameRendererStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .letter-icon-mapping {
    font-size: 10px;
  }
`;

export const SourceNameRendererStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;

  .icon {
    height: 17px;
  }
`;

export const DataDiffCheckReadableWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 5px;
  flex-direction: column;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-form-item-label > label::after {
    content: "";
  }

  .datatype-bullet {
    width: 9px;
    height: 20px;
    border-left: 1px solid rgb(207, 206, 206);
    border-bottom: 1px solid rgb(207, 206, 206);
    margin-left: 7px;

    span {
      display: block;
      width: max-content;
      font-size: 13.6px;
      margin-left: 17px;
      margin-top: 6px;
    }
  }
`;

export const AssetUsabilityScoreContentStyled = styled.div<{
  isPlainView?: boolean;
}>`
  ${({ isPlainView, theme }): any =>
    isPlainView &&
    `
  width: 228px;
  min-height: 300px;
  max-height: max-content;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px   ${theme.borderColors.GREY_29};
  background-color:   ${theme.bgColors.WHITE_COLOR};
`}

  .usabilityscore-popover-header {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;
    width: 100%;
    column-gap: 10px;
    height: 50px;
    padding-left: 14px;
    background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_17};
    font-family: OpenSansSemiBold;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    border-bottom: 1px solid
      ${({ theme }): string => hexToRgbA(theme.borderColors.GREY_29, 0.5)};
    .ant-btn {
      float: right;
    }
  }

  .usabilityscore-popover-body {
    padding: 10px 14px;

    .usability-score-sec {
      span {
        :first-of-type {
          font-family: OpenSansSemiBold;
          font-size: 13px;
          color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        }
      }
      .usability-obtained-score-sec {
        float: right;

        .obtained-score {
          font-family: OpenSansBold;
          margin-right: 2px;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }

    .usability-static-text {
      font-size: 13px;
      margin-bottom: 5px;
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};

      :not(:first-of-type) {
        margin-top: 10px;
      }
    }

    .usability-score-item {
      font-size: 13px;
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      > svg {
        margin-right: 12px;
      }
      .applied {
        font-family: OpenSansSemiBold;
        color: ${({ theme }): string => theme.textColors.BLUE_116};
        margin-top: 2px;
      }
    }
  }
`;

export const RelationshipRendererStyled = styled.div`
  font-family: OpenSans;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  margin-bottom: 6px;

  .relationship-list {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  ul {
    padding-left: 20px;
    margin-top: 6px;
    list-style-type: disc;
  }

  li {
    font-size: 13px;
    line-height: 20px;
  }

  .asset-name {
    color: ${({ theme }): string => theme.textColors.DARK_BLUE};
    cursor: pointer;
  }
`;

export const AssetStatusRendererStyled = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;

  .passed {
    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.GREEN_111};
      }
    }
  }
`;

export const AssetCodeHeaderWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${({ theme }): string => theme.textColors.BLUE_116};
  font-family: OpenSansSemiBold;
  font-size: 19.2px;
`;

export const EtlParentRendererStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .etl-icon {
    svg {
      padding-top: 7px;

      path {
        fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
      }
    }
  }
`;

export const AssetCodeInfoRendererStyled = styled.div`
  .query-wrapper {
    max-height: calc(100vh - 415px);
    min-height: calc(100vh - 415px);
  }
`;

export const SubDomainRendererStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .workfloww-icon {
    height: 18px;
  }

  .right-icon {
    display: flex;
    align-items: center;
    svg {
      path {
        fill: #b8bbc3;
      }
    }
  }
`;

export const ColProfilingRendererStyled = styled.div`
  width: 100%;
`;
