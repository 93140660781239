export const etlBlankSlate = (
  <svg width="100%" height="100%" viewBox="0 0 1797 811" fill="none">
    <rect y="4" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line y1="43.5" x2="1057" y2="43.5" stroke="#E6E6E6" />
    <rect y="72" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="72" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="111" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="111" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="150" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="150" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="189" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="189" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="228" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="228" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1197" y="4" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line x1="1197" y1="43.5" x2="1797" y2="43.5" stroke="#E6E6E6" />
    <rect x="1197" y="72" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1397" y="72" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1197" y="111" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1397" y="111" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1197" y="150" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1397" y="150" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="347" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line y1="386.5" x2="1797" y2="386.5" stroke="#E6E6E6" />
    <rect
      width="1797"
      height="400"
      transform="translate(0 411)"
      fill="#F0F0F0"
    />
    <rect
      width="1797"
      height="400"
      transform="translate(0 411)"
      fill="#F0F0F0"
    />
  </svg>
);

export const etlRefCodeBlankSlate = (
  <svg width="540" height="375" viewBox="0 0 540 375" fill="none">
    <rect width="540" height="375" fill="#F0F0F0" />
  </svg>
);

export const etlDetailedCodeBlankSlate = (
  <svg width="100%" height="300" viewBox="0 0 1797 300" fill="none">
    <rect width="1797" height="400" fill="#F0F0F0" />
  </svg>
);
