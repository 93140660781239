// React imports
import { useCallback, useEffect, useMemo, useState } from "react";

// UI components
import { Col, Row, Tooltip } from "antd";
import {
  GraphContainer,
  InfoBox,
  ProfilingStyled,
  StyledCard,
  SectionHeading,
  TileHeading,
  TileText,
  GraphHeader,
  ButtonGroup,
  GraphHeading,
  InfoFieldRendererStyled,
} from "./profilingtab.styles";

// Charts and Graphs
import {
  BarChart,
  ColumnChart,
  LineChart,
} from "../../../../components/recharts";

// Constants
import {
  DVSUM_TOOLTIP_CLASS_NAME,
  Y_AXIS_DATA_KEY,
} from "../../../../constants";

// Utilities and Helpers
import { checkDataTypeOfColumn, numberFormatter } from "../../../../utils";

// Types
import { ColumnProfilingProps, SampleDataRow } from "./profilingtab.types";

// Components
import { ToggleButton } from "../../../../components/togglebutton/togglebutton";
import InformationReadOnlyFieldRendrer, {
  sampleDataColDefs,
} from "./information.renderer";
import { InformationFieldRendrerProps } from "./informationrenderer.types";
import AgGridTable from "../../../../components/aggridtable";
import ProfilingCountProgressbar from "../../../../components/profilingcountprogressbar";
import ColumnSampleData from "../../../../components/detailpagesdatatab/views/columnview/columnsampledata/columnsampledata";
import { DetailPageHeaderWithTab } from "../../../datasourcedetailpage/datasourcedetailpage.components";

// Parsers
import { COLUMN_VISUAL_DIST_DATA_KEY } from "../../../../parsers/columnparsers";

// State Management
import StateHandler from "../../../../components/statehandler";

// API Services
import { useGetTableDataTabData } from "../../../../api/columnsservice";

// Wrappers
import ConditionalWrapper from "../../../../components/conditionalwrapper";
import { GatewayStatusCheckingWrapper } from "../../../analysisdetailpage/analysisdetailpage.views/analysisdetailpagecontentsec/analysisdetailpagecontentsec.components";

// Profiling Constants
import { informationSections } from "./profilingtab.constants";

// Custom Hooks
import { useGetCurrentGateway } from "../../../../customhooks";
import useNodeProfiling from "../../../../customhooks/useNodeProfilingHook";

// Buttons and Icons
import {
  AssetStyledWrapper,
  ProgressBarItem,
  RunNodeProfilingButton,
} from "../../../../components";
import { filledPlayIcon } from "../../../../svgs";

// Profiling Progress Bar
import OnlineProfilingProgressBar from "../../../../components/onlineprofilingprogressbar";
import {
  columnChartBlankSlate,
  columnInformationBlankSlate,
  columnSampleDataBlankSlate,
  columnTilesBlankSlate,
} from "../../columndetailpage.blankslates";
import { AssetFieldIds } from "../../../../parsers/asssetoverviewparser";

const ProfilingTab = (props: ColumnProfilingProps): JSX.Element => {
  const {
    data: ProfilingData,
    nodeId = "",
    isLoading,
    isFetching,
    error,
    refetch,
  } = props;

  const {
    custom_definition: customDefinition,
    last_profiled_on: profiledOn,
    is_profiled: isProfiled,
    min_max: minMax,
    is_primary: isPrimary,
    is_nullable: isNullable,
    technical_data_type: technicalDataType,
    column_position: columnPosition,
    node_name: columnName,
    size: infoSize,
    fractional_digits: noOfFractionalDigits,
    parent_id: parentId,
  } = ProfilingData || {};

  const {
    parsedData,
    isLoading: sampleDataLoading,
    error: sampleDataError,
    refetch: refetchSampleData,
  } = useGetTableDataTabData(parentId, true, true);

  const { columnSampleData = [], gatewayId = "", isSampleDataLoading } =
    parsedData || {};

  const onSuccessProfiling = useCallback(() => {
    refetchSampleData();
    refetch?.();
  }, [refetch, refetchSampleData]);

  const {
    onlineProfiling,
    onClickRunProfileOnline,
    onCancelProfilingClick,
    isProfilingInProgress,
    isProfilingFailed,
  } = useNodeProfiling({
    nodeType: "COL",
    nodeIds: [nodeId],
    onOnlineProfilingProcessCompleted: onSuccessProfiling,
    gatewayId,
  });

  const { isGatewayRunning } = useGetCurrentGateway(gatewayId || "");

  // Extract sample data for the given column
  const sampleData: SampleDataRow[] = useMemo(
    () =>
      columnSampleData
        ?.filter((row) =>
          Object?.prototype?.hasOwnProperty?.call(row, columnName)
        ) // Correct way to check property existence
        .map((row, index) => ({
          id: index + 1,
          value: row[columnName],
        })),
    [columnSampleData, columnName]
  );

  const [selectedOption, setSelectedOption] = useState("Distribution");

  const informationData: InformationFieldRendrerProps[] = useMemo(
    () => [
      {
        label: informationSections?.last_profiled_on?.label,
        value: profiledOn,
        type: informationSections?.last_profiled_on?.type,
      },
      {
        label: informationSections?.min_max?.label,
        value: minMax,
        type: informationSections?.min_max?.type,
      },
      {
        label: informationSections?.is_primary?.label,
        value: isPrimary,
        type: informationSections?.is_primary?.type,
      },
      {
        label: informationSections?.is_nullable?.label,
        value: isNullable,
        type: informationSections?.is_nullable?.type,
      },
      {
        label: informationSections?.technical_data_type?.label,
        value: technicalDataType,
        type: informationSections?.technical_data_type?.type,
      },
      {
        label: informationSections?.column_position?.label,
        value: columnPosition,
        type: informationSections?.column_position?.type,
      },
      {
        label: informationSections?.size?.label,
        value: infoSize,
        type: informationSections?.size?.type,
      },
      {
        label: informationSections?.no_of_fractional_digits?.label,
        value: noOfFractionalDigits,
        type: informationSections?.no_of_fractional_digits?.type,
      },
    ],
    [ProfilingData]
  );

  const {
    profilingCounts,
    emptyCount,
    uniqueCount,
    completeCount,
    recordCount,
    chartData,
    empty,
    unique,
    complete,
    patternData,
    hasPatternData,
    DistributionChart,
  } = useMemo(() => {
    const profillingInfo = customDefinition?.data?.filter(
      (item) => item?.isProfilingField
    );

    const profiling = profillingInfo || [];

    const profilingCounts =
      profiling?.find((item) => item?.id === AssetFieldIds?.count)
        ?.profillingInfo || [];

    const empty = profilingCounts?.find(
      (item: ProgressBarItem) => item?.type === "error"
    ) || { value: 0, type: "error", valueDetail: "" };

    const emptyCount = empty?.valueDetail || "0";

    const unique = profilingCounts?.find(
      (item: ProgressBarItem) => item?.type === "warning"
    ) || { value: 0, type: "warning", valueDetail: "" };

    const uniqueCount = unique?.valueDetail || "0";

    const complete = profilingCounts?.find(
      (item: ProgressBarItem) => item?.type === "success"
    ) || { value: 0, type: "success", valueDetail: "" };

    const completeCount = complete?.valueDetail || "0";

    const recordCount = String(
      Number(emptyCount) + Number(uniqueCount) + Number(completeCount)
    );

    const visualDistInfo = profiling?.find(
      (item) => item?.id === AssetFieldIds?.visualDistribution
    );

    const chartData = visualDistInfo?.visualDistributionChartData || [];
    const colDataTypeId = visualDistInfo?.colDataTypeId || "BOL";
    const { isNumberCol, isDateColumn } = checkDataTypeOfColumn(colDataTypeId);

    const patternInfo = profiling?.find(
      (item) => item?.id === AssetFieldIds?.patternDistribution
    );

    const patternData = patternInfo?.patternDistributionChartData || [];

    const hasPatternData = patternData?.length > 0;

    const DistributionChart = isDateColumn
      ? LineChart
      : isNumberCol
      ? ColumnChart
      : BarChart;

    return {
      profilingCounts,
      empty,
      unique,
      complete,
      emptyCount,
      uniqueCount,
      completeCount,
      recordCount,
      chartData,
      colDataTypeId,
      isNumberCol,
      isDateColumn,
      patternData,
      hasPatternData,
      DistributionChart,
    };
  }, [ProfilingData]);

  const nodeProfilingButton = (
    <RunNodeProfilingButton
      key="scrolled-run-profiling-btn"
      buttonTitle="Run Profiling"
      buttonIcon={filledPlayIcon("20", "20")}
      buttonType="button"
      onClickRunProfileOnline={onClickRunProfileOnline}
      onClickRunProfileOffline={(): void => {}}
      nodeType="COL"
      isNodeProfiled={isProfiled}
      disabled={!isGatewayRunning}
    />
  );

  const { progressPercenatge = 0, jobId = 0 } = onlineProfiling || {};

  return (
    <>
      {!sampleDataLoading && !isGatewayRunning && (
        <GatewayStatusCheckingWrapper
          gatewayId={gatewayId}
          type="error_banner"
          message="Sorry cannot connect to the Gateway and unable to run rule/profiling"
        >
          <div />
        </GatewayStatusCheckingWrapper>
      )}
      {isProfilingInProgress && isGatewayRunning && (
        <OnlineProfilingProgressBar
          onClickRunProfileOnline={onClickRunProfileOnline}
          onCancelProfilingClick={onCancelProfilingClick}
          progressPercenatge={progressPercenatge}
          textMessage="Profiling in Progress:"
          profilingStatus={isProfilingFailed ? "error" : "info"}
          profilingJobId={jobId}
          addGenericStyling
        />
      )}
      <AssetStyledWrapper>
        <DetailPageHeaderWithTab {...props}>
          {nodeProfilingButton}
        </DetailPageHeaderWithTab>
        <ProfilingStyled>
          <Row gutter={[16, 16]}>
            {/* Left Section - Statistics & Graph */}
            <Col span={16}>
              <SectionHeading>Statistics</SectionHeading>
              <StateHandler
                isFetching={isLoading || isFetching}
                error={error}
                blankSlate={columnTilesBlankSlate()}
              >
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <StyledCard>
                      <TileHeading>{numberFormatter(recordCount)}</TileHeading>
                      <TileText>Record Count</TileText>
                      <ProfilingCountProgressbar
                        data={profilingCounts}
                        minThreshhold={0}
                        // className="profiling-rendrer-progress"
                      />
                    </StyledCard>
                  </Col>
                  <Col span={6}>
                    <StyledCard>
                      <TileHeading>{numberFormatter(emptyCount)}</TileHeading>
                      <TileText>Empty or Null</TileText>
                      <ProfilingCountProgressbar
                        data={[empty]}
                        minThreshhold={0}
                        // className="profiling-rendrer-progress"
                      />
                    </StyledCard>
                  </Col>
                  <Col span={6}>
                    <StyledCard>
                      <TileHeading>{numberFormatter(uniqueCount)}</TileHeading>
                      <TileText>Unique</TileText>
                      <ProfilingCountProgressbar
                        data={[unique]}
                        minThreshhold={0}
                        // className="profiling-rendrer-progress"
                      />
                    </StyledCard>
                  </Col>
                  <Col span={6}>
                    <StyledCard>
                      <TileHeading>
                        {numberFormatter(completeCount)}
                      </TileHeading>
                      <TileText>Complete</TileText>
                      <ProfilingCountProgressbar
                        data={[complete]}
                        minThreshhold={0}
                        // className="profiling-rendrer-progress"
                      />
                    </StyledCard>
                  </Col>
                </Row>
              </StateHandler>

              {/* Graph Section Placeholder */}
              <GraphContainer>
                <GraphHeader>
                  <GraphHeading>Visualization</GraphHeading>
                  {/* {hasPatternData && ( */}
                  {hasPatternData ? (
                    <ButtonGroup>
                      <ToggleButton
                        options={["Distribution", "Patterns"]}
                        selectedOption={selectedOption}
                        onClick={setSelectedOption}
                      />
                    </ButtonGroup>
                  ) : (
                    <Tooltip
                      title="Pattern data is not available"
                      overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                    >
                      <ButtonGroup disabled>
                        <ToggleButton
                          options={["Distribution", "Patterns"]}
                          selectedOption={selectedOption}
                          onClick={(): void => {}}
                        />
                      </ButtonGroup>
                    </Tooltip>
                  )}

                  {/* )} */}
                </GraphHeader>

                <StateHandler
                  isFetching={isLoading || isFetching}
                  error={error}
                  blankSlate={columnChartBlankSlate()}
                >
                  {selectedOption === "Distribution" ? (
                    chartData?.length ? (
                      <DistributionChart
                        width="100%"
                        data={chartData}
                        dataKeys={[
                          {
                            dataKey: COLUMN_VISUAL_DIST_DATA_KEY,
                            fill: "#428BCA",
                          },
                        ]}
                        xAxislabel={{ value: "Count", offset: -25 }}
                        yAxis={[
                          {
                            yAxisId: Y_AXIS_DATA_KEY,
                            label: { value: "" },
                            notApplyFormatting: true,
                          },
                        ]}
                        showLedgends={false}
                      />
                    ) : (
                      <div className="no-data">No Data to Show</div>
                    )
                  ) : patternData?.length ? (
                    <BarChart
                      data={patternData}
                      dataKeys={[
                        {
                          dataKey: COLUMN_VISUAL_DIST_DATA_KEY,
                          fill: "#2c6aa0",
                        },
                      ]}
                      width="100%"
                      xAxislabel={{
                        value: "Count",
                        offset: -25,
                      }}
                      yAxis={[
                        {
                          yAxisId: "name",
                          label: { value: "" },
                          notApplyFormatting: true,
                        },
                      ]}
                    />
                  ) : (
                    <div className="no-data">No Data to Show</div>
                  )}
                </StateHandler>
              </GraphContainer>
            </Col>

            {/* Right Section - Information */}
            <Col span={8}>
              <SectionHeading>Information</SectionHeading>
              <InfoBox>
                <StateHandler
                  isFetching={isLoading || isFetching}
                  error={error}
                  blankSlate={columnInformationBlankSlate()}
                >
                  <>
                    {informationData?.map(
                      (item: InformationFieldRendrerProps, index: number) => (
                        <InfoFieldRendererStyled key={index}>
                          <div className="info-label">{item?.label}:</div>
                          <div className="info-value">
                            <InformationReadOnlyFieldRendrer
                              type={item?.type}
                              value={item?.value}
                            />
                          </div>
                        </InfoFieldRendererStyled>
                      )
                    )}
                  </>
                </StateHandler>
                {/* Sample Data Table */}
                <div className="table-section">
                  <StateHandler
                    isFetching={
                      sampleDataLoading || isFetching || isSampleDataLoading
                    }
                    error={sampleDataError}
                    blankSlate={columnSampleDataBlankSlate()}
                  >
                    <ConditionalWrapper
                      condition={!columnSampleData.length}
                      wrapper={(children): JSX.Element => (
                        <GatewayStatusCheckingWrapper
                          type="error_with_children"
                          gatewayId={gatewayId}
                        >
                          {children ?? <div />}
                        </GatewayStatusCheckingWrapper>
                      )}
                    >
                      {isGatewayRunning ? (
                        <AgGridTable<SampleDataRow>
                          rowData={sampleData}
                          tableColumns={sampleDataColDefs}
                          theme="ag-theme-alpine table-container"
                          domLayout="autoHeight"
                          headerHeight={35}
                          rowHeight={35}
                          pagination={false}
                          isShowPaginationComponent={false}
                        />
                      ) : undefined}
                    </ConditionalWrapper>
                  </StateHandler>
                </div>
              </InfoBox>
            </Col>
          </Row>
        </ProfilingStyled>
      </AssetStyledWrapper>
    </>
  );
};

export default ProfilingTab;
