import { useMemo } from "react";
import { NodeType, SourceTypes } from "../../app.types";
import { useGetAppType } from "../../customhooks";

import { chevronRight } from "../../svgs";

import { getDataSourceLogoSvgByType } from "../../utils/getsourceiconsbytype";
import CoreNodesCircleIcon from "../corenodetypescircleicon";

import StyledLink from "../styledlink/styledlink";
import {
  HeaderItemStyled,
  StyledBreadcrumWrapper,
} from "./styledbreadcrum.styles";

import {
  BreadcrumItemStringType,
  StyledBreadcrumbPropsType,
} from "./styledbreadcrum.types";

import {
  getBreadcrumItemClassName,
  getBreadCrumItemType,
  getBreadcrumItemUrl,
} from "./styledbreadcrum.util";
import { getWeightedWidth } from "../../utils";

const getBreadcrumItemIcon = (
  typeOfBreadcrum: BreadcrumItemStringType,
  nodeTypeId: SourceTypes
): JSX.Element => {
  const {
    isSrcNameItem,
    isTermDmnItem,
    isTermCategoryItem,
  } = getBreadCrumItemType(typeOfBreadcrum);

  if (isSrcNameItem) {
    return getDataSourceLogoSvgByType(nodeTypeId);
  }

  // if (isTermDmnItem) return termDomainIcon;

  // if (isTermCategoryItem) return termCategoryIcon;

  return (
    <CoreNodesCircleIcon
      nodeType={typeOfBreadcrum as NodeType}
      mode="tertiary"
      width="18px"
      height="18px"
      fontSize="10px"
    />
  );
};

const StyledBreadcrum = (props: StyledBreadcrumbPropsType): JSX.Element => {
  const { isExtOrDesktop } = useGetAppType();
  const { items, marginBottom = "20px", isFromHome = false } = props;

  const flexGrowValues = useMemo(() => {
    return getWeightedWidth(items);
  }, [items?.length]);

  return (
    <StyledBreadcrumWrapper
      className="source-config-breadcrum"
      marginBottom={marginBottom}
    >
      {items?.map((item, index) => {
        const {
          title = "",
          srcTypeId = "ORC",
          nodeId = "",
          type = "SRC",
        } = item;

        const isSrcConnectionItem = !!srcTypeId;

        const breadCrumbItemIcon = getBreadcrumItemIcon(type, srcTypeId);

        const breadcrumItemLink = getBreadcrumItemUrl(nodeId, type as NodeType);

        const breadcrumItemClassName = getBreadcrumItemClassName(type);

        const { isTermCategoryItem, isTermDmnItem } = getBreadCrumItemType(
          type
        );

        const isSvg =
          isSrcConnectionItem || isTermCategoryItem || isTermDmnItem;

        return (
          <HeaderItemStyled
            key={`${title}-${index}`}
            className={`${breadcrumItemClassName} header-item`}
            isFromHome={isFromHome}
            flexGrow={flexGrowValues[index]}
          >
            <div className={`item-icon ${isSvg ? "svg-icon" : "icon"}`}>
              {breadCrumbItemIcon}
            </div>

            <StyledLink
              to={breadcrumItemLink}
              target={isExtOrDesktop ? "_blank" : "_self"}
              title={title}
              cutomClassName="item-link"
            >
              <div
                title={title}
                className={`item-title ${breadcrumItemLink ? "link-item" : ""}`}
              >
                {title}
              </div>
            </StyledLink>

            {index + 1 < items?.length && (
              <div className="right-icon"> {chevronRight}</div>
            )}
          </HeaderItemStyled>
        );
      })}
    </StyledBreadcrumWrapper>
  );
};
export default StyledBreadcrum;
