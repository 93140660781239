import { APP_ROUTES, LOCAL_STORAGE_CONSTANTS } from "../constants";
import { refDictPageTabsValues } from "../pages/referencedictionarydetailpage/referencedictionarydetailpage.constants";

import { RefDictPageViewKeys } from "../pages/referencedictionarydetailpage/referencedictionarydetailpage.types";

export const getReferenceDictionaryDetailPageUrl = (
  id: string,
  newTab?: RefDictPageViewKeys
): string => {
  const recentlyVisitedTab =
    localStorage.getItem(
      LOCAL_STORAGE_CONSTANTS.recent_visited_ref_dict_detail_page_view
    ) || refDictPageTabsValues?.overview;

  const redirectTo = newTab || recentlyVisitedTab;

  return `${APP_ROUTES.private_routes.reference_dictionary}/${id}/${redirectTo}`;
};
