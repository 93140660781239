// <--- Import Statements Start --->
import { GridApi, ICellRendererParams } from "ag-grid-community";
import moment from "moment";

import { useParams } from "react-router";

import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { Tooltip, Spin } from "antd";
// <--- Import Statements End --->

// <--- Components Start --->
import LinkButton from "../../components/linkbutton";
import NodeStatusRendrer from "../../components/nodestatusrendrer";

import ConfidenceScore from "../../components/confidencescore";
import MatchingStatusPopover from "../../components/matchingstatus";

import TagList from "../../components/tags/taglist/taglist";
import ProminentTags from "../../components/prominenttagslist";

import DQScore from "../../components/dqscore";
import Flex from "../../components/flex";

import { AddEntityButton } from "../../components/matchingstatus/matchingstatus.components";
import IsCDEField from "../../components/iscdefield";

import PopularityScore from "../../components/popularityscore";
import { GenericProminentTagsList } from "../../components/genericprominenttag";
// <--- Components End --->

// <--- Custom Hooks Start --->
import { useOpenDrawer, useOpenModal } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Constants Start --->
import {
  ELEMENT_IDS,
  LOCAL_STORAGE_CONSTANTS,
  DVSUM_TOOLTIP_CLASS_NAME,
} from "../../constants";
// <--- Constants End --->

// <--- Parsers Start --->
import { MatchingEntityResponseType } from "../../parsers";
// <--- Parsers End --->

// <--- Styles Start --->
import {
  ConfidenceScoreRendererStyled,
  DataTypeRendererStyled,
  DateTimeRendererStyled,
  DescriptionRendererStyled,
  IsCDEFieldRendrerStyled,
  ListingEntityRendrerStyled,
  MatchInfoRendrerStyled,
  NameRendererStyled,
  PopularityScoreRendererStyled,
  ListingProfilingColumnRendererStyled,
  RefreshStatusRendererStyled,
  StatusRendererStyled,
  TagsRendererStyled,
  WorkflowStatusRendererStyled,
  FeedbackRendererStyled,
  QuestionRendererTooltipContent,
  QuestionRendererStyled,
  AnalyticsStatusRendererStyled,
  AlertStatusRendererStyled,
  RuleActionsRendererStyled,
  RulesWorkflowStatusRendererStyled,
  NameRendererWrapperStyled,
  RunStatusLoaderStyled,
  JobExecutionStatusRendererStyled,
  LinkedColumnRendrerStyled,
  RangeValueRendererStyled,
} from "./listingpage.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import {
  alertIcon,
  chatIcon,
  filledCrossIcon,
  filledTickIcon,
  thumbsDownFilledSvg,
  thumbsUpFilledSvg,
  dateIcon,
  hideIcon,
} from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { ProminentTagType } from "../../components/genericprominenttag/genericprominenttag.types";

import { ScoreType } from "../../components/popularityscore/popularityscore.types";

import {
  DQScoreRendererType,
  MatchStatusPayloadType,
  NameRendererPropsType,
  ProminentTagsRendererType,
  RendererWithParamsType,
} from "./listingpage.types";

import { TablePopularityInfoType } from "../../parsers/tablepage/tablepageparser.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  analyticsStatusTagMapping,
  getDataTypeIcon,
  getDetailpageUrlBasedOnNodeType,
  getDrawerIdBasedOnNodeType,
  getProfilingInfo,
  getRuleDetailPageUrl,
  getTableDetailPageUrl,
  getTermDetailPageUrl,
  isJsonString,
  numberFormatter,
  getViewOnlyChatDetailPageUrl,
  jsonParse,
  getAnalysisDetailPageUrl,
  getColumnDetailPageUrl,
} from "../../utils";

import { getPopularityScoreData } from "../../parsers/parserutils/getpopularityscoredata";

import ProfilingCountProgressbar from "../../components/profilingcountprogressbar";
import {
  getRuleRunStatusIcon,
  getRuleRunStatusLabel,
  getRuleTrendIcon,
} from "../../utils/getrulerunstatusicon";

import { LISTING_PAGE_TAGSET_NAME } from "./listingpage.constants";
import { ruleDetailPageTabsValues } from "../ruledetailpage/ruledetailpage.views/ruledetailpagecontent/ruledetailpagecontent.types";
import {
  getJobDetailPageUrl,
  getJobScheduledDetailPageUrl,
} from "../../utils/getjobdetailpageurl";
import { jobDetailPageTabsValues } from "../jobdetailpage/jobdetailpage.types";
import { jobScheduleDetailPageTabsValues } from "../jobscheduledetailpage/jobscheduledetailpage.types";

import { getChatDetailPageUrl } from "../../utils/getchatdetailpageurl";
import StyledLink from "../../components/styledlink/styledlink";

import { SingleTagRendrer } from "../../components/prominenttagslist/prominenttagslist";

import { ReviewCaddiFeedbacksModalProps } from "../../components/modalwrapper/modals/reviewcaddifeedbacksmodal/reviewcaddifeedbacksmodal.types";

import { CommentHistoryDrawerTypes } from "../../drawerviews/activityhistorydrawer";
import WorkflowHOC, {
  WorkflowHOCEmbedProps,
} from "../../components/workflowhoc";

import { BreadcrumbItemType } from "../../components/styledbreadcrum";
import { BreadcrumItemStringType } from "../../components/styledbreadcrum/styledbreadcrum.types";
import { AdaptiveAssetHierarchy } from "../../components/adaptiveassetheirachy";
import { AssetPath } from "../../parsers/asssetoverviewparser";
import ElementWithDefaultTooltip from "../../components/elementwithdefaulttooltip";

// <--- Utils End --->

const {
  allcat_lst_lst_grid_value: ALLCAT_LST_LST_GRID_VALUE,
  cht_bot_anal_tab_fbk_lst_qst_1: CHT_BOT_ANAL_TAB_FBK_LST_QST_1,
} = ELEMENT_IDS;

const chatIconSvg = chatIcon();

export const NameRenderer = (props: NameRendererPropsType): JSX.Element => {
  const {
    id = "",
    isEditable,
    value = "",
    drawerId,
    gridApi,
    elemId = "",
    isHidden = false,
    openRef = true,
    toUrl,
    isScheduled = false,
    isDeactivated = false,
    isOnlineJob = false,
  } = props;

  const openDrawer = useOpenDrawer();

  const onNodeClick = useCallback(
    (e: SyntheticEvent) => {
      e?.stopPropagation();
      if (openRef)
        openDrawer({
          drawerId,
          visible: true,
          drawerProps: { id },
        });
    },
    [id, drawerId, gridApi]
  );

  return (
    <NameRendererStyled isHidden={isHidden} isOnlineJob={isOnlineJob}>
      <LinkButton
        {...(!isHidden && !isOnlineJob && { onClick: onNodeClick })}
        // {...(!openRef && !isHidden && !isOnlineJob && { href: toUrl })}
        isTextUnderLine={!isHidden && !isOnlineJob}
        title={value}
        fontSize="13px"
        className={`name-renderer ${isHidden ? "hidden-node" : ""}`}
        elementId={elemId}
      >
        {openRef || isHidden || isOnlineJob ? (
          value
        ) : (
          <StyledLink to={String(toUrl)} cutomClassName="url-link">
            {value}
          </StyledLink>
        )}
      </LinkButton>

      {!isEditable && <span className="default-item">Default</span>}
      {isScheduled && <div className="scheduled-icon">{dateIcon}</div>}
      {isDeactivated && <div className="deactivated-item">(Deactivated)</div>}
    </NameRendererStyled>
  );
};

export const TableNameRendrer = (props: ICellRendererParams): JSX.Element => {
  const prominentTags: ProminentTagsRendererType[] = jsonParse(
    props?.data?.prominent_tags
  );

  const isSensitiveTag = prominentTags?.filter(
    (tag) =>
      tag?.tagset_name === LISTING_PAGE_TAGSET_NAME.DATA_CLASSIFICATION &&
      tag?.tag_name === LISTING_PAGE_TAGSET_NAME.SENSITIVE_TAG
  );

  const isSensitiveTagExists = isSensitiveTag?.length;

  return (
    <NameRendererWrapperStyled>
      <NameRenderer
        id={props?.data?.id}
        isEditable={props?.data?.is_editable !== false}
        drawerId="table_ref"
        value={props?.value}
        gridApi={props?.api}
        elemId={`${ALLCAT_LST_LST_GRID_VALUE}-${props?.rowIndex + 1}`}
        isHidden={props?.data?.is_hidden}
        openRef={false}
        toUrl={getTableDetailPageUrl(props?.data?.id)}
      />
      {!!isSensitiveTagExists && <div className="sensitive-tag">Sensitive</div>}
    </NameRendererWrapperStyled>
  );
};

export const TermNameRendrer = (props: ICellRendererParams): JSX.Element => {
  return (
    <NameRenderer
      id={props?.data?.id}
      isEditable={props?.data?.is_editable !== false}
      drawerId="term_ref"
      value={props?.value}
      gridApi={props?.api}
      elemId={`${ALLCAT_LST_LST_GRID_VALUE}-${props?.rowIndex + 1}`}
      openRef={false}
      toUrl={getTermDetailPageUrl(props?.data?.id)}
    />
  );
};

export const ColumnNameRendrer = (
  props: ICellRendererParams & { isOutsideListingViews?: boolean }
): JSX.Element => {
  const prominentTags: ProminentTagsRendererType[] = jsonParse(
    props?.data?.prominent_tags || ""
  );

  const isSensitiveTag = prominentTags?.filter(
    (tag) =>
      tag?.tagset_name === LISTING_PAGE_TAGSET_NAME.DATA_CLASSIFICATION &&
      tag?.tag_name === LISTING_PAGE_TAGSET_NAME.SENSITIVE_TAG
  );

  const isSensitiveTagExists = isSensitiveTag?.length;

  const { isOutsideListingViews = false } = props;

  return (
    <NameRendererWrapperStyled>
      <NameRenderer
        id={props?.data?.id}
        isEditable={props?.data?.is_editable !== false}
        drawerId="column_ref"
        openRef={!!isOutsideListingViews}
        toUrl={getColumnDetailPageUrl(props?.data?.id)}
        value={props?.value}
        gridApi={props?.api}
        elemId={`${ALLCAT_LST_LST_GRID_VALUE}-${props?.rowIndex + 1}`}
        isHidden={props?.data?.is_hidden}
      />
      {!!isSensitiveTagExists && <div className="sensitive-tag">Sensitive</div>}
    </NameRendererWrapperStyled>
  );
};

export const RuleIdRendrer = (
  props: ICellRendererParams & { isOutsideListingViews?: boolean }
): JSX.Element => {
  const {
    id: nodeId,
    job_ids: jobIds = "",
    is_editable: isEditable,
    api: gripApi,
  } = props?.data || {};

  const isNodeEditable = isEditable ?? true;

  const ruleDetailPageUrl = getRuleDetailPageUrl(
    nodeId,
    ruleDetailPageTabsValues?.data
  );

  const { isOutsideListingViews = false } = props;

  return (
    <NameRendererWrapperStyled>
      <NameRenderer
        id={nodeId}
        isEditable={isNodeEditable}
        drawerId="rule_ref"
        openRef={!!isOutsideListingViews}
        value={props?.value}
        gridApi={gripApi}
        elemId={`${ALLCAT_LST_LST_GRID_VALUE}-${props?.rowIndex + 1}`}
        toUrl={ruleDetailPageUrl}
        isScheduled={!!jobIds}
      />
    </NameRendererWrapperStyled>
  );
};

export const JobExecutionIdRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const {
    job_def_id: jobDefId,
    id: nodeId,
    job_ids: jobIds = "",
    is_editable: isEditable,
    api: gripApi,
  } = props?.data || {};

  const isNodeEditable = isEditable ?? true;

  const jobExecutionDetailPageUrl = getJobScheduledDetailPageUrl(
    jobDefId,
    nodeId
  );

  return (
    <NameRendererWrapperStyled>
      <NameRenderer
        id={nodeId}
        isEditable={isNodeEditable}
        value={props?.value}
        gridApi={gripApi}
        elemId={`${ALLCAT_LST_LST_GRID_VALUE}-${props?.rowIndex + 1}`}
        openRef={false}
        toUrl={jobExecutionDetailPageUrl}
      />
    </NameRendererWrapperStyled>
  );
};

export const JobIdRenderer = (props: ICellRendererParams): JSX.Element => {
  const {
    job_def_id: jobDefId,
    job_ids: jobIds = "",
    is_editable: isEditable,
    api: gripApi,
    is_active: isActive = true,
    is_job_internal: isOnlineJob,
  } = props?.data || {};

  const isNodeEditable = isEditable ?? true;

  const jobDetailPageUrl = getJobDetailPageUrl(
    jobDefId,
    jobDetailPageTabsValues?.details
  );

  return (
    <NameRendererWrapperStyled>
      <NameRenderer
        id={jobDefId}
        isEditable={isNodeEditable}
        value={props?.value}
        gridApi={gripApi}
        elemId={`${ALLCAT_LST_LST_GRID_VALUE}-${props?.rowIndex + 1}`}
        openRef={false}
        toUrl={jobDetailPageUrl}
        isScheduled={!!jobIds}
        isDeactivated={!isActive}
        isOnlineJob={isOnlineJob}
      />
    </NameRendererWrapperStyled>
  );
};

export const RuleAlertStatusRendrer = (
  props: ICellRendererParams
): JSX.Element => {
  const propsData = props?.data || {};

  const isAlertStatusExists = propsData?.alert_status_id;

  return isAlertStatusExists ? (
    <AlertStatusRendererStyled status={propsData?.alert_status_id}>
      {propsData?.alert_status_id === "ALE" ? "Alerting" : "Healthy"}
    </AlertStatusRendererStyled>
  ) : (
    <div />
  );
};

export const RuleRunStatusRendrer = (
  props: ICellRendererParams
): JSX.Element => {
  const propsData = props?.data || {};

  const isProfilingInProgress = propsData?.is_rule_profiling;

  const runStatusIcon = getRuleRunStatusIcon(propsData?.rule_exec_status_id);
  const runStatusLabel = getRuleRunStatusLabel(propsData?.rule_exec_status_id);

  return isProfilingInProgress ? (
    <RunStatusLoaderStyled>
      <Spin size="small" />
    </RunStatusLoaderStyled>
  ) : (
    <Flex columnGap={3}>
      {runStatusIcon}
      {runStatusLabel}
    </Flex>
  );
};

export const RuleTrendRenderer = (props: ICellRendererParams): JSX.Element => {
  const trendId = props?.data?.rule_trend_id;

  return (
    <RuleActionsRendererStyled title={props?.value}>
      {getRuleTrendIcon(trendId)}
    </RuleActionsRendererStyled>
  );
};

export const AssetNameRendrer = (props: ICellRendererParams): JSX.Element => {
  const toUrl = getDetailpageUrlBasedOnNodeType(
    props?.data?.id,
    props?.data?.node_type,
    props?.data?.node_sub_type
  );

  return (
    <NameRenderer
      id={props?.data?.id}
      isEditable={props?.data?.is_editable !== false}
      drawerId={getDrawerIdBasedOnNodeType(
        "DSR",
        props?.data?.type === "Report" ? "RPT" : undefined
      )}
      value={props?.value}
      gridApi={props?.api}
      elemId={`${ALLCAT_LST_LST_GRID_VALUE}-${props?.rowIndex + 1}`}
      openRef={false}
      toUrl={toUrl}
    />
  );
};

// TODO: Update and use this renderer at time of integration for chat listing
export const ChatNameRenderer = (props: any): JSX.Element => {
  const { value, data, isFeedbackAnalyticsPage = false } = props;

  const onChatNameClick = useCallback(
    (e) => {
      e?.stopPropagation();

      const isAuthor =
        data?.chat_author?.toLocaleLowerCase() ===
        localStorage
          ?.getItem(LOCAL_STORAGE_CONSTANTS?.email)
          ?.toLocaleLowerCase();

      window.open(
        isAuthor
          ? getChatDetailPageUrl(
              data?.[isFeedbackAnalyticsPage ? "cht_id" : "id"],
              isFeedbackAnalyticsPage ? data?.id : undefined
            )
          : getViewOnlyChatDetailPageUrl(
              data?.[isFeedbackAnalyticsPage ? "cht_id" : "id"],
              isFeedbackAnalyticsPage ? data?.id : undefined
            )
      );
    },
    [data]
  );

  return (
    <NameRendererStyled>
      <LinkButton
        onClick={onChatNameClick}
        isTextUnderLine
        title={value}
        fontSize="13px"
        className="name-renderer"
      >
        {value}
      </LinkButton>
    </NameRendererStyled>
  );
};

export const AgentNameRenderer = (props: any): JSX.Element => {
  const { value, data } = props;

  const agentPageUrl = useMemo(() => {
    return getAnalysisDetailPageUrl(data?.da_id ? data?.da_id : data?.id);
  }, [data]);

  return (
    <StyledLink to={agentPageUrl} title={value}>
      <LinkButton isTextUnderLine fontSize="13px">
        {value}
      </LinkButton>
    </StyledLink>
  );
};

export const TableRendrer = (props: ICellRendererParams): JSX.Element => {
  return (
    <NameRenderer
      id={props?.data?.tbl_id}
      isEditable={props?.data?.is_editable !== false}
      drawerId="table_ref"
      value={props?.value}
      gridApi={props?.api}
      isHidden={props?.data?.is_hidden}
      openRef={false}
      toUrl={getTableDetailPageUrl(props?.data?.tbl_id)}
    />
  );
};

export const EntityRendrer = (
  props: RendererWithParamsType & {
    breadcrumbs?: BreadcrumbItemType[];
    isOutsideListingViews?: boolean;
  }
): JSX.Element => {
  const data = props?.data || {};
  const {
    nodeId,
    nodeType,
    onSuccess,
    breadcrumbs = [],
    isOutsideListingViews = false,
  } = props;

  const matchingEntities = jsonParse(
    data?.match_info
  ) as MatchingEntityResponseType[];

  const payload: MatchStatusPayloadType = {
    col_ids: [data?.id || 0],
    col_entity_json: [],
    col_entity_id: 0,
    entity_action: "",
  };

  const updatedBreadcrumbs: BreadcrumbItemType[] = [
    ...breadcrumbs,
    ...(nodeType === "TBL"
      ? [
          {
            title: data?.source,
            nodeId: `${data?.src_id}`,
            type: "SRC" as BreadcrumItemStringType,
            srcTypeId: data?.src_type_id,
          },
          {
            title: data?.db_name || "None",
            type: "DTB" as BreadcrumItemStringType,
          },
          {
            title: data?.tbl_folder || "None",
            type: "SCM" as BreadcrumItemStringType,
          },
          {
            title: data?.table_name_physical,
            nodeId: `${data?.id}`,
            type: "TBL" as BreadcrumItemStringType,
          },
        ]
      : []),
    ...(nodeType === "COL"
      ? [
          ...(!breadcrumbs?.length
            ? [
                {
                  title: data?.source,
                  nodeId: `${data?.src_id}`,
                  type: "SRC" as BreadcrumItemStringType,
                  srcTypeId: data?.src_type_id,
                },
                {
                  title: data?.db_name || "None",
                  type: "DTB" as BreadcrumItemStringType,
                },
                {
                  title: data?.tbl_folder || "None",
                  type: "SCM" as BreadcrumItemStringType,
                },
              ]
            : []),
          {
            title: data?.tbl_display_name,
            nodeId: `${data?.tbl_id}`,
            type: "TBL" as BreadcrumItemStringType,
          },
          {
            title: data?.col_display_name,
            nodeId: `${data?.id}`,
            type: "COL" as BreadcrumItemStringType,
          },
        ]
      : []),
  ];

  return (
    <>
      {props?.value ? (
        <ListingEntityRendrerStyled>
          <NameRenderer
            id={props?.data?.trm_id}
            isEditable={props?.data?.is_editable !== false}
            drawerId="term_ref"
            openRef={!!isOutsideListingViews}
            value={props?.value}
            gridApi={props?.api}
            toUrl={getTermDetailPageUrl(props?.data?.trm_id)}
          />
          <NodeStatusRendrer
            tooltipTitle={props?.data?.trm_status}
            status={props?.data?.trm_status_id}
            className="listing-node-status-rendrer"
            nodeType={nodeType}
          />
        </ListingEntityRendrerStyled>
      ) : (
        <AddEntityButton
          recEntityModalProps={{
            matchingStatus: data?.col_entity_match_status_id,
            matchingEntities: matchingEntities?.map((matchingEntity) => ({
              matching_entity: matchingEntity?.entity_name || "",
              matching_entity_id: `${matchingEntity?.entity_id || 0}`,
              samples: matchingEntity?.entity_desc || "--",
              matching_score:
                Math.round(matchingEntity?.entity_matching_score) || 0,
              match_source: matchingEntity?.entity_match_source || "AAI",
              selected:
                matchingEntity?.entity_selection_status === "SEL" || false,
              entity_status: matchingEntity?.entity_status || "",
              entity_status_id: matchingEntity?.entity_status_id || "",
            })),
            breadcrumbs: updatedBreadcrumbs,
            api: {
              onSuccess,
              urlParams: [nodeType, nodeId],
              payload,
            },
          }}
        />
      )}
    </>
  );
};

export const StatusRenderer = (props: any): JSX.Element => {
  const { nodeType } = props;

  return (
    <StatusRendererStyled className="status-renderer">
      <NodeStatusRendrer
        status={props?.data?.status_id}
        className={props?.data?.status_id === "NEW" ? "new-icon" : ""}
        tooltipTitle={props?.value}
        nodeType={nodeType}
      />
    </StatusRendererStyled>
  );
};

export const WorkflowStatusRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  return props?.value ? (
    <WorkflowStatusRendererStyled
      isFromListing
      className="workflow-status-renderer"
    >
      <div className="workflow-status">{props?.value}</div>
    </WorkflowStatusRendererStyled>
  ) : (
    <div />
  );
};

export const RuleWorkflowStatusRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const rowData = props?.data;

  return props?.value ? (
    <RulesWorkflowStatusRendererStyled
      className="rule-workflow-status-renderer"
      workflowStatusId={rowData?.rule_action_status_id}
      isFromListing
    >
      <div className="workflow-status">{props?.value}</div>
    </RulesWorkflowStatusRendererStyled>
  ) : (
    <div />
  );
};

export const AssignedToAndAttributesRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const dataApprovers = props?.value;
  const dataApproversArray = dataApprovers?.split(",");
  return dataApproversArray?.length ? (
    <TagList tags={dataApproversArray} type="secondory" />
  ) : (
    <div />
  );
};

export const DateTimeRenderer = (props: any): JSX.Element => {
  const dateTime = props?.value;
  const formattedDate = dateTime
    ? moment.utc(dateTime).local().format("MM/DD/YYYY hh:mm A")
    : "";

  return (
    <DateTimeRendererStyled title={formattedDate}>
      {formattedDate}
    </DateTimeRendererStyled>
  );
};

export const TagsRenderer = (props: ICellRendererParams): JSX.Element => {
  const tags = props?.value !== "" ? props?.value?.split(",") : [];
  const prominentTags =
    (jsonParse(props?.data?.prominent_tags) as ProminentTagsRendererType[]) ||
    [];
  const prominentTagsNames = prominentTags?.map((tag) => tag?.tag_name) || [];
  const nonProminentTags =
    tags
      ?.filter(
        (tag: string) =>
          !prominentTagsNames?.includes(tag?.split(".")?.[1] || "")
      )
      ?.map((tag: string) => ({
        tag_name: tag || "",
        tag_color: "GRY",
      })) || [];

  const tagsSummary = useMemo(() => {
    return [...prominentTags, ...nonProminentTags]?.map((tag) => ({
      id: `${tag?.tag_id || 0}`,
      name: tag?.tag_name || "",
      type: tag?.tag_color || "GRN",
    }));
  }, [prominentTags, nonProminentTags]);

  return (
    <TagsRendererStyled>
      {prominentTags?.length ? (
        <ProminentTags isShowOnlyOne tags={tagsSummary} />
      ) : (
        <TagList tags={tags} type="secondory" />
      )}
    </TagsRendererStyled>
  );
};

export const RefreshStatusRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const refreshStatus = props?.data?.scan_status_id || "";

  return (
    <RefreshStatusRendererStyled title={props?.value || ""}>
      {refreshStatus === "PSS"
        ? filledTickIcon("13.6px", "13.6px")
        : refreshStatus === "ERR"
        ? filledCrossIcon("13.6px", "13.6px")
        : refreshStatus === "NAN"
        ? alertIcon()
        : ""}
    </RefreshStatusRendererStyled>
  );
};

export const NumberRenderer = (props: ICellRendererParams): JSX.Element => {
  const formattedNumber = Math.floor(props?.value || 0)?.toString();

  return <>{props?.value === "" ? "-" : numberFormatter(formattedNumber)}</>;
};

export const RunResultRenderer = (props: ICellRendererParams): JSX.Element => {
  const { value = "" } = props;
  const formattedNumber = value?.toString();

  return (
    <>{props?.value === "" ? "" : numberFormatter(formattedNumber, true)}</>
  );
};

export const DataTypeRenderer = (props: ICellRendererParams): JSX.Element => {
  const dataType = props?.value;
  const dataTypeId = props?.data?.data_type_id;

  return (
    <DataTypeRendererStyled title={dataType}>
      {getDataTypeIcon(dataTypeId)}
    </DataTypeRendererStyled>
  );
};

export function ConfidenceScoreRendrer(
  props: ICellRendererParams
): JSX.Element {
  const matchScore: number | "" =
    props?.value !== "" ? Math.round(props?.value) : "";

  return (
    <ConfidenceScoreRendererStyled>
      {matchScore !== "" ? <ConfidenceScore score={matchScore} /> : undefined}
    </ConfidenceScoreRendererStyled>
  );
}

export function MatchInfoRendrer(
  props: RendererWithParamsType & { breadcrumbs?: BreadcrumbItemType[] }
): JSX.Element {
  const data = props?.data || {};
  const { nodeId, nodeType, onExecute, onSuccess, breadcrumbs = [] } = props;

  const matchingEntities = jsonParse(
    data?.match_info
  ) as MatchingEntityResponseType[];

  const payload: MatchStatusPayloadType = {
    col_ids: [data?.id || 0],
    col_entity_json:
      matchingEntities?.map((entity) => ({
        entity_id: entity?.entity_id,
        entity_name: entity?.entity_name || "",
        entity_desc: entity?.entity_desc || "",
        entity_match_source: entity?.entity_match_source || "",
        entity_matching_score: entity?.entity_matching_score,
        entity_selection_status: entity?.entity_selection_status || "NSL",
        entity_status: entity?.entity_status || "",
        entity_status_id: entity?.entity_status_id || "",
      })) || [],
    col_entity_id: data?.trm_id || 0,
    entity_action: data?.col_entity_match_status_id || "",
  };

  // Callbacks
  // Unselect row
  const onUnSelectRow = useCallback((e) => {
    e?.stopPropagation();
  }, []);

  // Approve a record
  const onApprove = useCallback(
    (e) => {
      const node = props?.api?.getRowNode(props?.data?.id);
      onUnSelectRow?.(e);

      if (node) {
        payload.entity_action = "APR";
        onExecute([payload], [nodeType, nodeId]);
      }
    },
    [props, payload]
  );

  // Unapprove a record
  const onUnApprove = useCallback(
    (e) => {
      const node = props?.api?.getRowNode(props?.data?.id);
      onUnSelectRow?.(e);

      if (node) {
        payload.entity_action = "REW";
        onExecute([payload], [nodeType, nodeId]);
      }
    },
    [props, payload]
  );

  const updatedBreadcrumbs: BreadcrumbItemType[] = [
    ...breadcrumbs,
    ...(nodeType === "TBL"
      ? [
          {
            title: data?.source,
            nodeId: `${data?.src_id}`,
            type: "SRC" as BreadcrumItemStringType,
            srcTypeId: data?.src_type_id,
          },
          {
            title: data?.db_name || "None",
            type: "DTB" as BreadcrumItemStringType,
          },
          {
            title: data?.tbl_folder || "None",
            type: "SCM" as BreadcrumItemStringType,
          },
          {
            title: data?.table_name_physical,
            nodeId: `${data?.id}`,
            type: "TBL" as BreadcrumItemStringType,
          },
        ]
      : []),
    ...(nodeType === "COL"
      ? [
          ...(!breadcrumbs?.length
            ? [
                {
                  title: data?.source,
                  nodeId: `${data?.src_id}`,
                  type: "SRC" as BreadcrumItemStringType,
                  srcTypeId: data?.src_type_id,
                },
                {
                  title: data?.db_name || "None",
                  type: "DTB" as BreadcrumItemStringType,
                },
                {
                  title: data?.tbl_folder || "None",
                  type: "SCM" as BreadcrumItemStringType,
                },
              ]
            : []),
          {
            title: data?.tbl_display_name,
            nodeId: `${data?.tbl_id}`,
            type: "TBL" as BreadcrumItemStringType,
          },
          {
            title: data?.col_display_name,
            nodeId: `${data?.id}`,
            type: "COL" as BreadcrumItemStringType,
          },
        ]
      : []),
  ];

  return (
    <MatchInfoRendrerStyled>
      <MatchingStatusPopover
        matchingStatus={data?.col_entity_match_status_id}
        onApprove={onApprove}
        onUnApprove={onUnApprove}
        onUnSelectRow={onUnSelectRow}
        recEntityModalProps={{
          matchingStatus: data?.col_entity_match_status_id,
          matchingEntities: matchingEntities?.map((matchingEntity) => ({
            matching_entity: matchingEntity?.entity_name || "",
            matching_entity_id: `${matchingEntity?.entity_id || 0}`,
            samples: matchingEntity?.entity_desc || "--",
            matching_score:
              Math.round(matchingEntity?.entity_matching_score) || 0,
            match_source: matchingEntity?.entity_match_source || "AAI",
            selected:
              matchingEntity?.entity_selection_status === "SEL" || false,
            entity_status: matchingEntity?.entity_status || "",
            entity_status_id: matchingEntity?.entity_status_id || "",
          })),
          breadcrumbs: updatedBreadcrumbs,
          api: {
            onSuccess,
            urlParams: [nodeType, nodeId],
            payload,
          },
        }}
      />
    </MatchInfoRendrerStyled>
  );
}

export const DescriptionRenderer = (
  params: ICellRendererParams
): JSX.Element => {
  const description: string = params?.value || "";

  return (
    <DescriptionRendererStyled title={description}>
      {description}
    </DescriptionRendererStyled>
  );
};

export const DQScoreRenderer = (props: ICellRendererParams): JSX.Element => {
  const { DQ_STATUS_ID, DQ_STATUS = "", DRIFT_VAR, SCORE } = jsonParse(
    props?.data?.dq_summary || ""
  ) as DQScoreRendererType;

  return (
    <Flex height="40px">
      <DQScore
        score={`${SCORE}`}
        trend={`${DRIFT_VAR}`}
        statusId={DQ_STATUS_ID}
        status={DQ_STATUS}
      />
    </Flex>
  );
};

export const AlertTypeRenderer = (props: ICellRendererParams): JSX.Element => {
  const alerts = (props?.value as string) || "";
  const mappedAlerts = alerts?.split(",")?.map(
    (alert: string): ProminentTagType => ({
      name: alert || "",
      type: "RED",
    })
  );

  return (
    <Flex
      height="40px"
      data-testid={`listing-page-ag-grid-alert-cell-${alerts}`}
    >
      <GenericProminentTagsList
        tags={mappedAlerts}
        type="RED"
        isBoldView
        data-testid={`listing-page-ag-grid-tag-${alerts}`}
      />
    </Flex>
  );
};

export const IsCDEFieldRendrer = (props: ICellRendererParams): JSX.Element => {
  return (
    <IsCDEFieldRendrerStyled>
      <IsCDEField isUseFlex={false} value={props?.value} />
    </IsCDEFieldRendrerStyled>
  );
};

export const PopularityScoreRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const popularityScoreId = props?.data?.tbl_popularity_id as ScoreType;
  const popularityScoreTitle = (props?.value as string) || "";
  const popularityScoreInfo = jsonParse(
    props?.data?.tbl_popularity_info || ""
  ) as TablePopularityInfoType;

  const popularityScoreParsedData = getPopularityScoreData(
    popularityScoreId,
    popularityScoreTitle,
    popularityScoreInfo
  );

  const handleWrapperClicked = useCallback((event) => {
    event?.stopPropagation?.();
  }, []);

  return (
    <PopularityScoreRendererStyled role="button" onClick={handleWrapperClicked}>
      <PopularityScore {...popularityScoreParsedData} />
    </PopularityScoreRendererStyled>
  );
};

export const ProfilingInfoRendrer = (
  props: ICellRendererParams
): JSX.Element => {
  const { value = "" } = props;
  const {
    col_dist_cnt: colDistCnt = 0,
    col_blanks_cnt: colBlanksCnt = 0,
    col_record_cnt: colRecordCnt = 0,
  } = jsonParse(value, true);

  const profilingInfo = getProfilingInfo(
    colRecordCnt,
    colDistCnt,
    colBlanksCnt
  );
  return (
    <ListingProfilingColumnRendererStyled>
      <ProfilingCountProgressbar
        data={profilingInfo}
        minThreshhold={10}
        className="profiling-rendrer-progress"
      />
    </ListingProfilingColumnRendererStyled>
  );
};

export const QuestionRenderer = (props: any): JSX.Element => {
  const { id: botId = "" } = useParams<{
    id: string;
  }>();

  const openModal = useOpenModal();

  const {
    value,
    data,
    updateFeedbackStatusConfig,
    getDataApiCommonProps,
    rowIndex = 0,
  } = props;

  const { id = 0, cht_id: chatId = 0 } = data || {};

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onQuestionClick = useCallback(
    (e) => {
      e?.stopPropagation();
      setIsTooltipVisible(false);

      openModal({
        modalId: "review_caddi_feedbacks_modal",
        modalTitle: "Review Feedback",
        visible: true,
        modalProps: {
          botId,
          chatId,
          queryId: id,
          updateFeedbackStatusConfig,
          getDataApiCommonProps,
        } as ReviewCaddiFeedbacksModalProps,
      });
    },
    [id, botId, chatId, updateFeedbackStatusConfig, getDataApiCommonProps]
  );

  return (
    <Tooltip
      overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} listing-question-renderer-tooltip`}
      title={
        <QuestionRendererTooltipContent>
          <div className="value">{value}</div>
          <div className="desc">Click to review feedback</div>
        </QuestionRendererTooltipContent>
      }
      placement="topLeft"
      visible={isTooltipVisible}
      onVisibleChange={(visible): void => {
        setIsTooltipVisible(visible);
      }}
    >
      <QuestionRendererStyled>
        <LinkButton
          fontSize="12px"
          showEllipsis
          onClick={onQuestionClick}
          elementId={`${CHT_BOT_ANAL_TAB_FBK_LST_QST_1}-${rowIndex + 1}`}
        >
          {value}
        </LinkButton>
      </QuestionRendererStyled>
    </Tooltip>
  );
};

export const FeedbackRenderer = (props: any): JSX.Element => {
  const { value } = props;

  const feedbackConfig = useMemo(() => {
    switch (value) {
      case "Approved":
        return {
          icon: thumbsUpFilledSvg("16.5px", "16.5px"),
          tooltip: "Approved",
          className: "approved-icon",
        };
      case "Rejected":
        return {
          icon: thumbsDownFilledSvg("16.5px", "16.5px"),
          tooltip: "Rejected",
          className: "rejected-icon",
        };
      default:
        return {};
    }
  }, [value]);

  return (
    <Tooltip
      overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
      title={feedbackConfig?.tooltip}
      placement="right"
    >
      <FeedbackRendererStyled
        className={`feedback-renderer ${feedbackConfig?.className}`}
      >
        {feedbackConfig?.icon}
      </FeedbackRendererStyled>
    </Tooltip>
  );
};

const CommentHistoryRendrer = (
  props: WorkflowHOCEmbedProps & { qstId: string }
): JSX.Element => {
  const { qstId = "", onNodeCommentOrRejectRequest } = props;
  const openDrawer = useOpenDrawer();

  const onCommnetClick = useCallback(
    (e) => {
      e?.stopPropagation();
      const drawerProps: CommentHistoryDrawerTypes = {
        nodeType: "QRY",
        nodeId: qstId,
        actionType: "CMT",
        activityRequest: onNodeCommentOrRejectRequest,
      };

      openDrawer({
        drawerId: "comment_history_ref",
        visible: true,
        drawerProps,
        zIndex: 10001,
        isStartFromTop: true,
      });
    },
    [qstId, onNodeCommentOrRejectRequest]
  );
  return (
    <LinkButton
      onClick={onCommnetClick}
      tooltipProps={{
        title: "Activities",
      }}
    >
      {chatIconSvg}
    </LinkButton>
  );
};

export const AnalyticsStatusRenderer = (props: any): JSX.Element => {
  const { value, data } = props;

  const { qry_fdbk_status_id: statusId, id = "" } = data;

  const analyticsStatusTag = analyticsStatusTagMapping(statusId, value);

  return (
    <>
      {analyticsStatusTag?.name && (
        <AnalyticsStatusRendererStyled>
          <SingleTagRendrer tag={analyticsStatusTag} />
          <WorkflowHOC nodeType="QRY" nodeId={id}>
            <CommentHistoryRendrer qstId={id} />
          </WorkflowHOC>
        </AnalyticsStatusRendererStyled>
      )}
    </>
  );
};

export const JobStatusRenderer = (props: ICellRendererParams): JSX.Element => {
  const { data, value = "" } = props;
  const { last_job_status_id } = data || {};

  return (
    <JobExecutionStatusRendererStyled execStatus={last_job_status_id}>
      {value}
    </JobExecutionStatusRendererStyled>
  );
};

export const JobExecutionStatusRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const { data, value = "" } = props;
  const { sch_status_id } = data || {};

  return (
    <JobExecutionStatusRendererStyled execStatus={sch_status_id}>
      {value}
    </JobExecutionStatusRendererStyled>
  );
};

export const AssignedToRenderer = (props: ICellRendererParams): JSX.Element => {
  const dataApprovers = props?.value;
  const dataApproversArray = dataApprovers?.split(",");
  return dataApproversArray?.length ? (
    <TagList tags={dataApproversArray} type="secondory" tagstoShowUpfront={2} />
  ) : (
    <div />
  );
};

export const LinkedColumnRendrer = (
  props: ICellRendererParams
): JSX.Element => {
  const { initialData, remainingData, remainingItemsCount } = useMemo(() => {
    const colDtls: Array<
      AssetPath[number] & { node_path: AssetPath; node_title: string }
    > = jsonParse(props?.data?.linked_cols_dtls);

    const colDtlsWithCurItem = colDtls?.map((item) => [
      ...(item?.node_path || []),
      {
        level: 0,
        node_id: `${item?.node_id}`,
        node_name: item?.node_title || item?.node_name,
        node_type: item?.node_type,
        node_sub_type: item?.node_sub_type,
      },
    ]);

    const initialData = colDtlsWithCurItem?.[0] || [];
    const remainingItemsCount = colDtlsWithCurItem?.length
      ? colDtlsWithCurItem?.length - 1
      : 0;

    const remainingData = colDtlsWithCurItem?.slice(1) || [];

    return {
      remainingData,
      initialData,
      remainingItemsCount,
    };
  }, [props?.data?.linked_cols_dtls]);

  const stopPropagation = useCallback((e) => {
    e?.stopPropagation();
  }, []);

  return (
    <LinkedColumnRendrerStyled>
      {initialData && (
        <AdaptiveAssetHierarchy nodes={initialData} mode="primary" openRef />
      )}
      {!!remainingItemsCount && (
        <Tooltip
          overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} more-items-tooltip`}
          title={remainingData?.map((item, index: number) => (
            <AdaptiveAssetHierarchy
              nodes={item}
              mode="primary"
              key={`linked-col-${props?.data?.id}-${index}`}
              openRef
            />
          ))}
          placement="rightTop"
          trigger={["click"]}
          overlayInnerStyle={{
            maxHeight: 225,
            overflowY: "auto",
            minWidth: 625,
            maxWidth: 625,
          }}
          overlayStyle={{ maxWidth: 625 }}
          getPopupContainer={(triggerNode): HTMLElement =>
            triggerNode?.parentElement as HTMLElement
          }
        >
          <LinkButton className="more-items-count" onClick={stopPropagation}>
            +{numberFormatter(`${remainingItemsCount}`)}
          </LinkButton>
        </Tooltip>
      )}
    </LinkedColumnRendrerStyled>
  );
};

export const RangeValueRenderer = (props: ICellRendererParams): JSX.Element => {
  const rangValue = props?.value;

  return (
    <RangeValueRendererStyled title={rangValue}>
      {rangValue}
    </RangeValueRendererStyled>
  );
};

export const ValueRendererWithTooltip = (
  props: ICellRendererParams
): JSX.Element => {
  const value = props?.value || "";

  return (
    <ElementWithDefaultTooltip
      title={`${value}`}
      value={`${value}`}
      showEllipsis
    />
  );
};
