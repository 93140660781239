import {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useRef,
} from "react";
import { ASSET_RELATIONSHIPS_INITIAL_STATE } from "./assetrelationshipscontext.config";

import {
  AssetRelationshipActionsType,
  AssetRelationshipContextStateType,
  AssetRelationshipContextType,
  updateAssetRelationshipStateType,
} from "./assetrelationshipscontext.types";

const initialState = ASSET_RELATIONSHIPS_INITIAL_STATE;

const reducer = (
  state: AssetRelationshipContextStateType,
  action: AssetRelationshipActionsType
): AssetRelationshipContextStateType => {
  switch (action?.type) {
    case "UPDATE_STATE":
      return {
        ...state,
        ...action?.updatedState,
      };
    case "UPDATE_FILTER_STATE":
      return {
        ...state,
        selectedAssetFilter: action?.updatedState,
      };
    case "UPDATE_SEARCH_STATE":
      return {
        ...state,
        assetSearchText: action?.updatedState,
      };

    default:
      return state;
  }
};

const AssetRelationshipContext = createContext({
  state: initialState,
  updateState: (_: AssetRelationshipContextStateType) => {},
  updateFilterState: (_: string[]) => {},
  updateSearchState: (_: string) => {},
  relationshipsRef: { current: null } as React.RefObject<HTMLDivElement>,
});

const AssetRelationshipProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const relationshipsRef = useRef<HTMLDivElement | null>(null);

  const updateState: updateAssetRelationshipStateType = useCallback(
    (updatedState) => {
      dispatch({ type: "UPDATE_STATE", updatedState });
    },
    [dispatch]
  );

  const updateFilterState = useCallback(
    (selectedAssetFilter: string[]) => {
      dispatch({
        type: "UPDATE_FILTER_STATE",
        updatedState: selectedAssetFilter,
      });
    },
    [dispatch]
  );

  const updateSearchState = useCallback(
    (assetSearchText: string) => {
      dispatch({
        type: "UPDATE_SEARCH_STATE",
        updatedState: assetSearchText,
      });
    },
    [dispatch]
  );

  const contextValue: AssetRelationshipContextType = {
    state,
    updateState,
    updateFilterState,
    updateSearchState,
    relationshipsRef,
  };

  return (
    <AssetRelationshipContext.Provider value={contextValue}>
      {children}
    </AssetRelationshipContext.Provider>
  );
};

const useAssetRelationshipContext = (): AssetRelationshipContextType => {
  const context = useContext(AssetRelationshipContext);

  if (!context) {
    throw new Error(
      "useAssetRelationshipContext must be used within a AssetRelationshipProvider"
    );
  }

  return context;
};

export { AssetRelationshipProvider, useAssetRelationshipContext };
