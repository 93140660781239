import { useState, useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import AdditionalInfo, { AddedItem } from "../../additionalinfo";
import SectionBlankSlateText from "../../sectionblankslatetext";
import SectionTitleWithIcon from "../../sectiontitlewithicon";
import { SectionWithEditableAdditionalinfoStyled } from "./sectionwitheditableadditionalinfo.styles";
import {
  CopyState,
  SectionWithEditableAdditionalinfoProps,
} from "./sectionwitheditableadditionalinfo.types";

function SectionWithEditableAdditionalinfo(
  props: SectionWithEditableAdditionalinfoProps
): JSX.Element {
  const { secTitle, setState, state } = props;
  const [copyState, setCopyState] = useState<CopyState>({
    currentAction: "edit",
    addedItems: state?.data || [],
    id: uuidv4(),
  });

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);

  useEffect(() => {
    setCopyState((st) => ({
      ...st,
      addedItems: state?.data || [],
    }));
  }, [state]);

  const onEdit = useCallback(() => {
    setState((st) => ({ ...st, isEditable: !st?.isEditable }));
  }, [state]);

  const onSave = useCallback(() => {
    setState((st) => ({ ...st, data: copyState.addedItems, isEdited: true }));
    setCopyState((st) => ({
      ...st,
      currentValue: "",
      currentAction: "edit",
    }));
    onEdit();
  }, [state, copyState]);

  const onCancel = useCallback(() => {
    setState((st) => ({ ...st, isEditable: !st?.isEditable }));
    setCopyState((st) => ({
      ...st,
      addedItems: state?.data,
      currentValue: "",
      currentAction: "edit",
    }));
  }, [copyState, state]);

  return (
    <SectionWithEditableAdditionalinfoStyled isEditable={state?.isEditable}>
      <SectionTitleWithIcon
        {...secTitle}
        marginBottom="25px"
        onEdit={onEdit}
        isEditable={state?.isEditable}
        onCancel={onCancel}
        onSave={onSave}
        isDisabledSaveButton={isSaveDisabled}
        disabledSaveBtnTooltipProps={{
          title: "Save the Additional Info below and then proceed.",
        }}
      />
      {!state?.isEditable ? (
        <SectionBlankSlateText isEmpty={!state?.data?.length}>
          <div className="asset-additional-info">
            {state?.data?.map((item) => (
              <AddedItem
                item={item}
                isEditable={false}
                key={`additionalinfo-${item?.id}`}
              />
            ))}
          </div>
        </SectionBlankSlateText>
      ) : (
        state?.isEditable && (
          <AdditionalInfo
            setState={setCopyState}
            isEditable
            state={copyState}
            setIsSaveDisabled={setIsSaveDisabled}
          />
        )
      )}
    </SectionWithEditableAdditionalinfoStyled>
  );
}

export default SectionWithEditableAdditionalinfo;
