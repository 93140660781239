import { useEffect, useRef, useState } from "react";

export const useCalculateContainerWidth = (): {
  ref: any;
  width: number;
} => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  // Set up ResizeObserver to handle screen size changes
  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setContainerWidth(entry.contentRect.width);
      });
    });

    resizeObserver.observe(containerRef.current);

    // Initial measurement
    setContainerWidth((containerRef.current as HTMLDivElement)?.offsetWidth);

    const cleanup = (): void => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
    cleanup();
  }, [containerRef.current]);

  return { ref: containerRef, width: containerWidth ?? 0 };
};
