export const COLUMN_SECTION_TITLES = {
  description: {
    description: "Definition",
    meta_score: "Usability Score",
    data_type: "Data Type",
    example: "Example",
    column_type: "Column Type",
    column_sub_type: "Column Sub-type",
    is_cde: "Is CDE",
    data_domain: "Data Domain",
    business_rules: "Business Rules",
    title: "Business Name",
  },
  classification: {
    entity: "Entity",
    functional_area: "Data Domain",
  },
  profiling_information: {
    count: "Count",
    min_max: "Min-Max",
    visual_distribution: "Visual Distribution",
    pattern_distribution: "Pattern Distribution",
  },
  quality: {
    overall_score: "Overall Score",
    last_updated: "Last Updated",
  },
  data_quality_rules: {
    auto_set: "Auto set",
    validity: "Validity",
  },
  managed_by: {
    data_owner: "Data Owner",
    system_owner: "System Owner",
    data_steward: "Data Steward",
    audit_trail: "Audit Trail",
  },
  audit_trail: {
    created: "Created",
    updated: "Updated",
  },
  provinance_data: {
    table_and_sources_columns: "Sources, Tables & Columns",
  },
  linked_entity: {
    entity: "Entity",
    matching_score: "Matching Score",
    match_source: "Match Source",
  },
};

export const COLUMN_SECTIONS = {
  classification: "Tags",
  profiling_information: "Profiling Information",
  lineage: "Lineage",
  quality: "Quality",
  data_quality_rules: "Data Quality Rules",
  description: "Description",
  managed_by: "Managed By",
  linked_entity: "Linked Entity",
  additional_info: "Additional Info",
  usability_score: "Usability Score",
  ownership: "Ownership", // used in usability score heading
  entity: "Entity", // used in usability score heading
  definition: "Definition", // used in usability score heading
};
