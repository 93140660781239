import { useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { UserOrUsersGroupParsedType } from "../../../parsers/tablepage/tablepageparser.types";
import ManagedBySectionRow from "../../managedbysectionrow";
import { ManagedBySectionRowprops } from "../../managedbysectionrow/managedbysectionrow.types";
import SectionTitleWithIcon from "../../sectiontitlewithicon";
import { SectionWithEditableManagedByStyled } from "./sectionwitheditablemanagedby.styles";

import { SectionWithEditableManagedByProps } from "./sectionwitheditablemanagedby.types";
import { getSortedFieldsBasedOnAccessPage } from "../../assetoverview/assetoverview.util";
import { AssetFieldIds } from "../../../parsers/asssetoverviewparser";

function valueUpdation(
  item: ManagedBySectionRowprops,
  values: any,
  dropdownData?: UserOrUsersGroupParsedType[]
): ManagedBySectionRowprops {
  switch (item?.type) {
    case "multiselect":
      return {
        ...item,
        keyValueTags: values?.[item?.title]
          ? values?.[item?.title]?.map((nestItem: any) => {
              const drpDownData = dropdownData?.find(
                (dataItem: any) => dataItem?.value === nestItem
              );

              const keyValData = item?.keyValueTags?.find(
                (dataItem: any) => dataItem?.name === nestItem
              );

              return {
                name: drpDownData?.value || keyValData?.name,
                email: drpDownData?.email || keyValData?.email,
                type: drpDownData?.type || keyValData?.type,
              };
            })
          : item?.keyValueTags,
        selectedValues: values?.[item?.title]
          ? values?.[item?.title]
          : item?.selectedValues,
      };
    default:
      return item;
  }
}

function ManagedBySectionWithEditable(
  props: SectionWithEditableManagedByProps
): JSX.Element {
  const {
    state,
    setState,
    id,
    secTitle,
    rendrer,
    dropdownData = [],
    nodeType,
    nodeId = "",
    isReportPage = false,
    stewardsDropdownData = [],
    commentActivity,
    isRefView = false,
  } = props;

  const fieldValues: { [key: string]: string } = state[id]?.data
    ?.filter((item) => item?.isEditable)
    ?.reduce(
      (prev, item) => ({
        ...prev,
        [item.title]: item?.selectedValues,
      }),
      {}
    );

  const form = useForm<any>({
    defaultValues: fieldValues,
    mode: "all",
  });

  const onEdit = useCallback(() => {
    Object?.keys(fieldValues)?.map((field) =>
      form.setValue(field, fieldValues?.[field], { shouldValidate: true })
    );

    setState((st) => ({
      ...st,
      [id]: { ...st[id], isEditable: !st[id]?.isEditable },
    }));
  }, [state, fieldValues]);

  const onCancel = useCallback(() => {
    onEdit();
    form.unregister();
  }, [state]);

  const onSave = useCallback(() => {
    const values = form.watch() as any;
    setState((st) => ({
      ...st,
      [id]: {
        ...st[id],
        isEdited: true,
        data: st?.[id]?.data?.map((item) => ({
          ...valueUpdation(
            item,
            values,
            item?.isDataStewardField && nodeType === "TRM"
              ? stewardsDropdownData
              : dropdownData
          ),
        })),
      },
    }));
    onCancel();
  }, [state, form.watch(), id, dropdownData, stewardsDropdownData]);

  const rowsToShow = state[id]?.data || [];

  const pageRows = getSortedFieldsBasedOnAccessPage(rowsToShow, isRefView);

  return (
    <FormProvider {...form}>
      <SectionTitleWithIcon
        {...secTitle}
        marginBottom="25px"
        onEdit={onEdit}
        isEditable={state[id]?.isEditable}
        onCancel={onCancel}
        onSave={onSave}
        isDisabledSaveButton={!form?.formState?.isValid}
      />
      <SectionWithEditableManagedByStyled
        isEditable={state[id]?.isEditable}
        className="asset-managed-by-section"
      >
        {pageRows?.map((row) => {
          const rowId = row?.id || "";

          const isAuditTrailField = row?.id === AssetFieldIds.auditTrail;
          const isSectionEditable = state[id]?.isEditable;
          const isRowEditable = isAuditTrailField ? true : row?.isEditable;

          return (
            <ManagedBySectionRow
              {...row}
              isEditable={isSectionEditable && isRowEditable}
              customJSX={
                rendrer && rendrer?.(row)?.[rowId]
                  ? rendrer?.(row)?.[rowId]?.()
                  : undefined
              }
              key={`mode-sec-row-${row.title}-${id}`}
              nodeType={nodeType}
              nodeId={nodeId}
              isReportPage={isReportPage}
              commentActivity={commentActivity}
              isRefView={isRefView}
            />
          );
        })}
      </SectionWithEditableManagedByStyled>
    </FormProvider>
  );
}

export default ManagedBySectionWithEditable;
