import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DataNode } from "antd/lib/tree";

import { FormProvider, useForm } from "react-hook-form";
import { FormStyled } from "../../components/form";

import { useCancelModal, useGetAppState } from "../../customhooks";

import {
  AddNewJobFormFooterStyled,
  AddNewJobFormStyled,
  AddNewJobTabStyled,
} from "./addnewjobform.styles";

import {
  AddNewJobFormType,
  AddNewJobModalProps,
  JobTabsType,
  SchemaDataNodeType,
  UserAndGroupNodeType,
} from "./addnewjobform.types";

import {
  getJobDetailPageUrl,
  getPostLoginData,
  openNotification,
  sortListOnSpecificKeyValue,
} from "../../utils";

import { AnchorStyled, Button, CoreNodesCircleIcon } from "../../components";
import StateHandler from "../../components/statehandler/statehandler";

import { addNewJobSchema } from "../../utils/schemas/addnewjobschema";

import { useRequestWithMethod } from "../../api";
import SuccessNotificationMessage from "../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";

import { LinkTabs } from "../../components/tabs";
import { AddJobDefinitionForm } from "./addjobdefinitionform/addjobdefinitionform";

import { AddJobNotificationForm } from "./addjobnotificationform/addjobnotificationform";
import { AddJobScheduleForm } from "./addjobscheduleform/addjobscheduleform";

import { useGetNodeTags } from "../../api/ruleservice";
import { TranferStateType } from "../../components/transfer/transfer.types";

import {
  TagsData,
  TagsEditorState,
} from "../../components/tagseditor/tagseditor.types";
import { useGetAllUserGroups } from "../../api/userservice";

import { useGetAllActiveUsers } from "../../api/userservice/usersservice";
import { useGetAllDomains } from "../../api/accountsettingsservice";

import {
  compareArrays,
  getInitialTreeDataForUsersandGroups,
} from "./addnewjobform.utils";
import { getSourceAndDestinationDataWithEnabledObjects } from "../addroleform/addroleform.utils";

import { ParsedEnabledObjectType } from "../../parsers/userroles/userrolesparser.types";
import { databaseIcon } from "../../svgs";

import { TransferCompStyled } from "./addjobdefinitionform/addjobdefinitionform.styles";
import Flex from "../../components/flex";
import { ELEMENT_IDS } from "../../constants";

import { getModuleLevelAccessInfo } from "../../utils/getModuleLevelAccessInfo";

const defaultValuesOfCopyState = {
  data: {},
  selectedCategory: "Custom Tags",
  searchText: "",
  customTagsValue: "",
};

const {
  ad_job_form_def_tab: AD_JOB_FORM_DEF_TAB,
  ad_job_form_noti_tab: AD_JOB_FORM_NOTI_TAB,
  ad_job_form_sch_tab: AD_JOB_FORM_SCH_TAB,
  ad_job_form_sav_btn: AD_JOB_FORM_SAV_BTN,
} = ELEMENT_IDS;

const AddNewJobForm = (): JSX.Element => {
  const handleCancel = useCancelModal();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const postLoginData = getPostLoginData();
  const { isDqEnabled } = getModuleLevelAccessInfo();

  const { user_info } = postLoginData || {};

  const { job_settings } = user_info || {};

  const {
    offline_profiling_limit: offlineProfLinmit = 0,
    offline_rules_limit: offlineRulesLimit = 0,
  } = job_settings || {};

  const {
    // selectedRulesWithSourceInfo,
    propsOnSave,
    jobConfig,
    isEdit = false,
    jobDefId: id = "0",
  } = modalProps as AddNewJobModalProps;

  const { jobMetaDataInfo, jobNotificationInfo, jobScheduleInfo } =
    jobConfig || {};

  const {
    jobDesc: desc = "",
    jobCategory,
    jobCreatedOnNodes = [],
    jobCreatedOnTags = [],
    selectionType: selectionTypeVal,
  } = jobMetaDataInfo || {};

  const {
    internalRecipients = [],
    externalRecipients: external = [],
    sendEmailOnAlerts = false,
    sendScheduleEmailCompletion = true,
  } = jobNotificationInfo || {};

  const {
    frequencyType = "OTM",
    daysOfMonth = "DOM",
    endAfterRepeatingXTimes = "5",
    endsOnDate = "",
    interval = "1",
    isRepeat = false,
    repeatAfterXMinutes = "",
    repeatForXHours = "",
    scheduleType = "SCH",
    ends = "never",
    activationDate = new Date(),
    daysOfWeek = [],
    startOnTimeEdit = new Date(),
  } = jobScheduleInfo || {};

  const isOnlyJobCategory = !jobCategory && !isDqEnabled;
  const selectedJobCategory = isOnlyJobCategory ? "PRF" : jobCategory;

  const addJobForm = useForm<AddNewJobFormType>({
    defaultValues: {
      jobCategory: selectedJobCategory,
      desc,
      source: "",
      sendScheduleEmailCompletion,
      sendEmailOnAlerts,
      frequencyType,
      activationDate,
      daysOfMonth,
      daysOfWeek,
      endAfterRepeatingXTimes,
      endsOnDate,
      interval,
      isRepeat,
      repeatAfterXMinutes,
      repeatForXHours,
      scanType: "FUL",
      scheduleType,
      ends,
      startOnTime: startOnTimeEdit,
      selectionType: selectionTypeVal || "based_on_attributes",
    },
    resolver: yupResolver(addNewJobSchema),
    mode: "onChange",
  });

  const [savedJobId, setSavedJobId] = useState<number>(Number(id));
  const [activeTab, setActiveTab] = useState<JobTabsType>("DEF");
  const [externalRecipients, setExternalRecipients] = useState<string[]>(
    external
  );
  const [destinationData, setDestinationData] = useState<DataNode[]>();

  const [tagsDataState, setTagsDataState] = useState<TagsEditorState>(
    defaultValuesOfCopyState
  );

  const {
    watch,
    formState: { isValid, isDirty },
    handleSubmit,
  } = addJobForm;

  const {
    jobCategory: job,
    errorInExternalRecipients,
    scheduleType: schType,
    selectionType,
  } = watch();

  const isApiJob = schType === "API";
  const isSchedulerJob = schType === "SCH";

  const addToJobLimit = job === "RLS" ? offlineRulesLimit : offlineProfLinmit;

  const initialTransferState = useMemo(
    () => ({
      data:
        jobCreatedOnNodes.map((val) => ({
          key: String(val?.id),
          name: job === "RLS" ? val?.name : `${val?.parent_name} ${val?.name}`,
          icon:
            job === "RLS" ? (
              <CoreNodesCircleIcon
                nodeType={job === "RLS" ? "RLS" : "TBL"}
                width="20px"
                height="20px"
                marginRight="4px"
                fontSize="10px"
              />
            ) : (
              <>
                {databaseIcon}
                <TransferCompStyled>
                  <Flex customClass="source">
                    <span className="text" title={val?.parent_name}>
                      {val?.parent_name}
                    </span>
                  </Flex>
                  <div className="arrow">{">"}</div>
                  <Flex customClass="name">
                    <CoreNodesCircleIcon
                      nodeType="TBL"
                      width="15px"
                      height="15px"
                      marginRight="4px"
                      fontSize="10px"
                    />
                    <span className="text" title={val?.name}>
                      {val?.name}
                    </span>
                  </Flex>
                </TransferCompStyled>
              </>
            ),
        })) || [],
      targetKeys: jobCreatedOnNodes?.map((val) => String(val?.id)),
    }),
    [jobCreatedOnNodes]
  );

  const [stateTransfer, setStateTransfer] = useState<TranferStateType>(
    initialTransferState
  );

  const onSaveSuccess = useCallback(
    (response) => {
      const jobDefId = response?.data?.job_def_id;
      const jobId = response?.data?.job_id;

      openNotification(
        <SuccessNotificationMessage
          message={
            isEdit ? (
              "Job updated successfully."
            ) : isSchedulerJob ? (
              <span>
                <AnchorStyled role="link" href={getJobDetailPageUrl(jobDefId)}>
                  {jobId}
                </AnchorStyled>{" "}
                job created successfully.
              </span>
            ) : (
              "API URL generated successfully."
            )
          }
        />
      );

      setSavedJobId(jobDefId);
      if (isSchedulerJob || isEdit) {
        handleCancel();
        propsOnSave?.(jobDefId);
      }
    },
    [isApiJob, isSchedulerJob, isEdit, schType, propsOnSave]
  );

  const onApiJobSaveSuccess = useCallback(() => {
    handleCancel();
    propsOnSave?.(String(savedJobId));
  }, [savedJobId, propsOnSave]);

  const {
    onExecuteRequest: onAddNewScheduler,
    isLoading: isLoadingAddNewScheduler,
    error: errorInAddNewScheduler,
  } = useRequestWithMethod("run_profiling", undefined, true, onSaveSuccess);

  const {
    onExecuteRequest: onUpdateNewScheduler,
    isLoading: isLoadingUpdateNewScheduler,
    error: errorInUpdateNewScheduler,
  } = useRequestWithMethod(
    "update_job_scheduler",
    undefined,
    true,
    onSaveSuccess
  );

  const {
    parsedData: parsedTagsData,
    isLoading: isLoadingTagsData,
    error: errorTagsData,
  } = useGetNodeTags(job === "RLS" ? "RLS" : "TBL");

  const {
    parsedData: groupsData = [],
    isLoading: isGroupsDataLoading,
    error: errorInGroupsData,
  } = useGetAllUserGroups();

  const {
    parsedData: usersData = [],
    isLoading: isUsersDataLoading,
    error: errorInUsersData,
  } = useGetAllActiveUsers();

  const sortedGroupsData = useMemo(
    () =>
      sortListOnSpecificKeyValue({
        list: groupsData,
        key: "group_name",
      }),
    [groupsData]
  );

  const sortedUsersData = useMemo(() => {
    return sortListOnSpecificKeyValue({
      list: usersData,
      key: "firstName",
    });
  }, [usersData]);

  const {
    parsedData: whitelistedDomains = [],
    isLoading: isDomainListLoading,
    error: errorInDomainList,
  } = useGetAllDomains();

  const isDefinitionTabActive = activeTab === "DEF";
  const isNotificationTabActive = activeTab === "NOT";
  const isScheduleTabActive = activeTab === "SCH";

  const appliedTags: TagsData = useMemo(() => {
    return Object.keys(tagsDataState?.data)?.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: {
          ...tagsDataState?.data[curr],
          tagsData: tagsDataState?.data[curr]?.tagsData?.filter(
            (item) => item?.isAdded
          ),
        },
      }),
      {}
    );
  }, [tagsDataState.data]);

  useEffect(() => {
    if (parsedTagsData) {
      const tagsAppliedId = jobCreatedOnTags?.map((val) => String(val?.id));

      Object.keys(parsedTagsData)?.map((mainTag) =>
        parsedTagsData[mainTag]?.tagsData?.map((tagData) => {
          if (tagsAppliedId?.includes(tagData?.value)) {
            tagData.isAdded = true;
            tagData.selected = true;
          }
          return tagData;
        })
      );

      setTagsDataState({
        ...defaultValuesOfCopyState,
        selectedCategory: Object.keys(parsedTagsData || {})?.[0] || "",
        data: parsedTagsData || [],
      });
    }
  }, [parsedTagsData, jobCreatedOnTags?.length]);

  const onSubmit = useCallback(
    (data: AddNewJobFormType) => {
      const activationDateTime = data?.activationDate;
      if (data?.startOnTime) {
        activationDateTime.setHours(data?.startOnTime?.getHours());
        activationDateTime.setMinutes(data?.startOnTime?.getMinutes());
      }

      const internalReceipients =
        (destinationData as UserAndGroupNodeType[])?.flatMap((item) => {
          return item?.children?.map((child: SchemaDataNodeType) => {
            return {
              node_id: child?.id,
              node_type: child?.nodeType,
            };
          });
        }) || [];

      const jobCategory = data?.jobCategory;
      const selectionType = data?.selectionType;

      const jobConfig = {
        job_description: data?.desc,
        job_category: jobCategory,
        node_ids: {
          include: jobCategory
            ? selectionType === "based_on_attributes"
              ? Object?.values(appliedTags)?.flatMap((val) =>
                  val?.tagsData?.map((tag) => Number(tag?.value))
                )
              : stateTransfer?.targetKeys?.map((val) => Number(val))
            : [Number(data?.source)],
        },
        job_config: {
          job_desc: data?.desc,
          is_job_via_tag: selectionType === "based_on_attributes",
          recur_interval: data?.interval ? data?.interval : null,
          job_type: data?.scheduleType,
          freq_type: data?.frequencyType ? data?.frequencyType : null,
          is_repeat: data?.isRepeat,
          days_of_week: data?.daysOfWeek ? data?.daysOfWeek : null,

          days_of_month: data?.daysOfMonth ? data?.daysOfMonth : null,
          activation_date: activationDateTime || "",
          // (data?.frequencyType &&
          //   data?.activationDate &&
          //   getFormattedUtcDateTime(
          //     data?.activationDate,
          //     data?.startOnTime
          //   )) ||
          // "",

          email_recipients: {
            internal: internalReceipients,
            external: externalRecipients,
          },
          repeat_for_x_hours: data?.repeatForXHours
            ? data?.repeatForXHours
            : null,
          repeat_after_x_minutes: data?.repeatAfterXMinutes
            ? data?.repeatAfterXMinutes
            : null,
          ends_after_repeating_x_times:
            data?.ends === "after" ? data?.endAfterRepeatingXTimes : null,
          ends_on_date: data?.ends === "on" ? data?.endsOnDate : null,
          job_completion_email: data?.sendScheduleEmailCompletion,
          send_email_on_alerts: data?.sendScheduleEmailCompletion
            ? data?.sendEmailOnAlerts
            : false,
          start_on_time: data?.startOnTime,
        },
      };

      isEdit
        ? onUpdateNewScheduler({ ...jobConfig }, [id, jobCategory])
        : onAddNewScheduler({ ...jobConfig }, [jobCategory]);
    },
    [
      destinationData,
      externalRecipients,
      stateTransfer,
      appliedTags,
      onSaveSuccess,
      id,
    ]
  );

  const onUpdateActiveTab = useCallback((newActiveTab: string) => {
    setActiveTab(newActiveTab as JobTabsType);
  }, []);

  const showLoadingWhenScheduled =
    isLoadingAddNewScheduler && schType === "SCH";

  const isExecuteViaApi = schType === "API";

  const isDirtyCheck = isEdit
    ? isDirty ||
      !(selectionTypeVal === "select_individually"
        ? jobCreatedOnNodes?.length &&
          compareArrays(
            jobCreatedOnNodes?.map((val) => Number(val?.id)),
            stateTransfer?.targetKeys.map((val) => Number(val))
          )
        : true) ||
      !(selectionTypeVal === "based_on_attributes"
        ? compareArrays(
            Object.values(appliedTags)?.flatMap((mainTag) =>
              mainTag?.tagsData?.map((tagsData) => Number(tagsData?.value))
            ),
            jobCreatedOnTags?.map((val) => Number(val?.id))
          )
        : true) ||
      !(internalRecipients?.length || destinationData?.length
        ? compareArrays(
            destinationData?.flatMap(
              (group) =>
                group?.children?.map((val: DataNode & { id?: number }) =>
                  Number(val?.id)
                ) || []
            ) || [],
            internalRecipients?.map((val) => val?.id)
          )
        : true) ||
      !compareArrays(external, externalRecipients)
    : true;

  const isAttrOrNodesSelected =
    selectionType === "based_on_attributes"
      ? Boolean(
          Object?.values(appliedTags)?.flatMap((val) =>
            val?.tagsData?.map((tag) => Number(tag?.value))
          )?.length
        )
      : Boolean(stateTransfer?.targetKeys?.length);

  const isDefinitionStepAndValid =
    isDefinitionTabActive && job && isAttrOrNodesSelected;

  const isNotificationTabDisabled = !job || !isAttrOrNodesSelected;

  const isScheduleTabDisabled =
    !job || !isAttrOrNodesSelected || !!errorInExternalRecipients;

  const isNotificationStepAndValid =
    isNotificationTabActive && !errorInExternalRecipients;

  const isContinueButtonDisabled =
    !isDefinitionStepAndValid && !isNotificationStepAndValid;

  const isSaveButtonDisabled =
    !isDirtyCheck ||
    !isValid ||
    !!errorInExternalRecipients ||
    (!!isExecuteViaApi && !isEdit) ||
    !(isValid && isAttrOrNodesSelected);

  const onPrevBtnClick = useCallback(() => {
    setActiveTab(isNotificationTabActive ? "DEF" : "NOT");
  }, [isNotificationTabActive, isScheduleTabActive]);

  const onNextBtnClick = useCallback(() => {
    setActiveTab(isDefinitionTabActive ? "NOT" : "SCH");
  }, [isDefinitionTabActive, isScheduleTabActive]);

  const RULE_PAGE_TABS = [
    {
      key: "DEF",
      value: "Definition",
      id: AD_JOB_FORM_DEF_TAB,
    },

    {
      key: "NOT",
      value: "Notification",
      disabled: isNotificationTabDisabled,
      id: AD_JOB_FORM_NOTI_TAB,
    },
    {
      key: "SCH",
      value: "Schedule",
      disabled: isScheduleTabDisabled,
      id: AD_JOB_FORM_SCH_TAB,
    },
  ];

  const initialTreeData = getInitialTreeDataForUsersandGroups(
    sortedUsersData,
    sortedGroupsData
  );

  const [treeData, setTreeData] = useState<DataNode[]>([...initialTreeData]);

  const [sourceData, setSourceData] = useState<DataNode[]>([
    ...initialTreeData,
  ]);

  const updateTreeData = useCallback((data: DataNode[]) => {
    setTreeData(data);
  }, []);

  const updateSourceData = (data: DataNode[]): void => {
    setSourceData(data);
  };

  const updateDestinationData = (data: DataNode[]): void => {
    setDestinationData(data);
  };

  const enabledObjects: ParsedEnabledObjectType[] = useMemo(() => {
    return internalRecipients?.map((item) => {
      const enabledObj = {
        id: `${item?.id}`,
        key:
          item?.type === "USR"
            ? `user-data-${item?.id}`
            : `group-data-${item?.id}`,
        node_type: item?.type,
      };
      return enabledObj;
    });
  }, [internalRecipients]);

  useEffect(() => {
    if (isNotificationTabActive && !destinationData) {
      const {
        treeData,
        sourceData: usersGrpsSourceData,
        destinationData: usersGrpsDestinationData,
      } = getSourceAndDestinationDataWithEnabledObjects(
        enabledObjects,
        initialTreeData
      );

      updateTreeData([...treeData]);
      updateSourceData([...usersGrpsSourceData]);
      // if (!destinationData) updateDestinationData(usersGrpsDestinationData);
      updateDestinationData([...usersGrpsDestinationData]);
    }
  }, [initialTreeData?.length, isNotificationTabActive]);
  // }, []);

  const showOKButtonForApiJob = isApiJob && !isEdit;

  return (
    <StateHandler
      isFetching={
        showLoadingWhenScheduled ||
        isLoadingTagsData ||
        isGroupsDataLoading ||
        isUsersDataLoading ||
        isDomainListLoading ||
        isLoadingUpdateNewScheduler
      }
      error={
        errorInAddNewScheduler ||
        errorTagsData ||
        errorInGroupsData ||
        errorInUsersData ||
        errorInDomainList ||
        errorInUpdateNewScheduler
      }
      isModal
    >
      <AddNewJobTabStyled>
        <div className="link-tabs">
          <LinkTabs
            tabs={RULE_PAGE_TABS}
            selectedTab={activeTab}
            onChange={onUpdateActiveTab}
          />
        </div>
        <AddNewJobFormStyled>
          <FormStyled layout="vertical">
            <FormProvider {...addJobForm}>
              {isDefinitionTabActive ? (
                <AddJobDefinitionForm
                  initialTransferState={initialTransferState}
                  state={stateTransfer}
                  setState={setStateTransfer}
                  tagsDataState={tagsDataState}
                  setTagsDataState={setTagsDataState}
                  appliedTags={appliedTags}
                  isEdit={isEdit}
                  jobFor={jobCategory}
                  addToJobLimit={addToJobLimit}
                  jobCreatedOnNodes={jobCreatedOnNodes}
                />
              ) : isNotificationTabActive ? (
                <AddJobNotificationForm
                  internalRecipients={internalRecipients}
                  externalRecipients={externalRecipients}
                  destinationData={destinationData}
                  setDestinationData={setDestinationData}
                  groups={sortedGroupsData}
                  users={sortedUsersData}
                  whitelistedDomains={whitelistedDomains}
                  isEdit={isEdit}
                  setExternalRecipients={setExternalRecipients}
                  initialTreeData={initialTreeData}
                  treeData={treeData}
                  setTreeData={setTreeData}
                  sourceData={sourceData}
                  setSourceData={setSourceData}
                  updateTreeData={updateTreeData}
                  updateSourceData={updateSourceData}
                  updateDestinationData={updateDestinationData}
                />
              ) : (
                <AddJobScheduleForm
                  savedJobId={savedJobId}
                  onGenerateApiUrl={handleSubmit(onSubmit)}
                  generatingApiUrl={!!isLoadingAddNewScheduler}
                  isEdit={isEdit}
                />
              )}
            </FormProvider>
          </FormStyled>
        </AddNewJobFormStyled>
        <AddNewJobFormFooterStyled>
          <Button id="cancel" width="74px" onClick={handleCancel}>
            Cancel
          </Button>

          {!isDefinitionTabActive && (
            <Button
              id="cancel"
              width="74px"
              onClick={onPrevBtnClick}
              elementId="prev-btn"
              marginLeft="8px"
            >
              Back
            </Button>
          )}

          {!isScheduleTabActive && (
            <Button
              width="74px"
              marginLeft="8px"
              onClick={onNextBtnClick}
              disabled={isContinueButtonDisabled}
            >
              Next
            </Button>
          )}

          {isScheduleTabActive && (
            <Button
              elementId={AD_JOB_FORM_SAV_BTN}
              width="74px"
              marginLeft="8px"
              htmlType={showOKButtonForApiJob ? "button" : "submit"}
              onClick={
                showOKButtonForApiJob
                  ? onApiJobSaveSuccess
                  : handleSubmit(onSubmit)
              }
              disabled={
                showOKButtonForApiJob ? !savedJobId : isSaveButtonDisabled
              }
            >
              {showOKButtonForApiJob ? "OK" : "Save"}
            </Button>
          )}
        </AddNewJobFormFooterStyled>
      </AddNewJobTabStyled>
    </StateHandler>
  );
};

export default AddNewJobForm;
