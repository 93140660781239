import styled from "styled-components";

export const DataQualityTabStyled = styled.div`
  /* padding-left: 10px;
  padding-right: 10px; */
`;

export const SectionHeading = styled.h2`
  font-size: 20px;
  font-family: OpenSansSemiBold;
  color: ${({ theme }): string => theme.textColors.BLUE_116};
  margin-bottom: 16px;
  border-bottom: 1px solid
    ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
`;

export const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: 170px 170px 170px repeat(auto-fit, minmax(180px, 1fr));
  align-items: stretch;
  gap: 16px;
  margin-bottom: 20px;
`;

export const StyledCard = styled.div<{ filter?: boolean; selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px 12px;
  border: 1px solid ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
  border-radius: 4px;
  background-color: ${({ theme, selected }): string =>
    selected ? theme.textColors.BLUE_116 : theme.textColors.WHITE_COLOR};
  height: 100%;
  min-width: 170px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ filter }): string | false =>
    filter
      ? `
      cursor: pointer;
      transition: box-shadow 0.2s ease-in-out;

      &:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Smooth shadow effect */
      }
    `
      : ""};
`;

export const TileHeading = styled.h3<{ selected?: boolean }>`
  font-size: 16px;
  font-family: OpenSansSemiBold;
  color: ${({ theme, selected }): string =>
    selected ? theme.textColors.WHITE_COLOR : theme.textColors.BLACK_COLOR};
  margin-bottom: -2px;
`;

export const TileText = styled.h4<{ selected?: boolean }>`
  font-size: 14px;
  font-family: OpenSans;
  color: ${({ theme, selected }): string =>
    selected ? theme.textColors.WHITE_COLOR : theme.textColors.GREY_24};
  margin-top: 0;
  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    cursor: pointer;
  }

  ${({ selected, theme }): string =>
    selected
      ? `
        svg path {
          fill: ${theme.textColors.WHITE_COLOR};
        }

        /* Special case: Alerts icon only needs stroke change */
        svg g path {
          fill: none;
          stroke: ${theme.textColors.WHITE_COLOR};
        }
      `
      : ""}
`;

export const GraphContainer = styled.div`
  border-radius: 4px;
  margin-top: 30px;
  overflow: hidden;
  .recharts-legend-wrapper {
    top: -10px !important;

    .legend-wrapper {
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      max-width: 100%;

      .chart-legend {
        min-width: 40px;
      }
    }
  }
  .no-data {
    border: 1px solid ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
    padding: 30px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    color: ${({ theme }): string => theme.textColors.GREY_24};
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: OpenSansSemiBold;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  }
  .header-filters {
    display: flex;
    align-items: center;
    & > div {
      column-gap: 0;
      -webkit-column-gap: 0;
    }

    .toggle-button-option {
      padding: 8px;
      height: 30px;
      min-width: unset;

      span {
        color: grey;
      }

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:not(:first-child):not(:last-child) {
        border-radius: 0;
      }
    }

    button[disabled] {
      color: ${({ theme }): string => theme.textColors.GREY_35};
    }

    .toggle-button-option-selected {
      padding: 8px;
      height: 30px;
      min-width: unset;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:not(:first-child):not(:last-child) {
        border-radius: 0;
      }
    }
  }
`;

export const RulesContainer = styled.div`
  margin-top: 10px;
`;
