import styled from "styled-components";

export const SectionWithEditableWrapperStyled = styled.div<{
  padding?: string;
}>`
  padding: ${({ padding }): string => padding || ""};
`;

export const SectionWithEditableStyled = styled.div<{ isEditable?: boolean }>`
  background: ${({ theme, isEditable }): string =>
    isEditable && theme.bgColors.BLUE_11};
  padding: ${({ isEditable }): any => isEditable && "23px 34px 34px 23px"};

  ${({ isEditable }): any =>
    isEditable &&
    `  .main-row {
    flex-direction: column;
    .row-title,
    .sublist-title {
      margin-bottom: 6px;
      font-family: OpenSansSemiBold;
    }
    .row-value {
      width: 100%;
    }
  }`}

  .main-row {
    .row-value {
      .show-more-text-main-wrapper {
        width: unset;
      }
    }
  }
`;
