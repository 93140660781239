import styled from "styled-components";
import { hexToRgbA } from "../../utils";
import { WizardItemProps } from "./wizard.types";

export const WizardStyled = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const WizardItemStyled = styled.div<WizardItemProps>`
  display: flex;
  flex-direction: column;

  .wizard-item-main-content {
    display: flex;
    align-items: center;
  }

  .wizard-item-meta-data {
    max-width: 180px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 15px;
    font-size: 12px;
    color: ${({ theme }): string => theme?.textColors?.DARK_GREY_120};
  }

  .outer-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    padding: 4px;
    border-radius: 50%;
    background: ${(props): string =>
      props.inProgress
        ? hexToRgbA(props.theme.bgColors.BLUE_21, 0.2)
        : props.theme.bgColors.WHITE_COLOR};
    margin-right: 10px;
    .inner-circle {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(props): string =>
        props.inProgress
          ? props.theme.bgColors.BLUE_116
          : props.done
          ? props.theme.bgColors.GREEN_10
          : props.theme.bgColors.GREY_11};
      color: ${(props): string => props.theme.textColors.WHITE_COLOR};
      font-size: 16px;
      font-family: OpenSansSemiBold;
      font-weight: 600;
    }
  }
  .wizard-item-wrapper {
    display: flex;
    flex-direction: column;
    .title {
      color: ${(props): string =>
        props?.inProgress
          ? props.theme.textColors.BLACK_COLOR
          : props.theme.textColors.DARK_GREY_120};
      font-size: 16px;
      font-family: ${(props): string =>
        props?.inProgress ? "OpenSansSemiBold" : "OpenSans"};
      font-weight: ${(props): string => (props?.inProgress ? "600" : "normal")};
    }
  }

  .seperation-line {
    width: 104px;
    height: 1px;
    margin: 0 15px;
    opacity: 0.7;
    background: ${(props): string => props.theme.borderColors.GREY_35};
  }
`;
