import { SetStateAction, useCallback, useMemo, useState } from "react";

import StateHandler from "../../components/statehandler/statehandler";
import { termRefRendrer as termRefRendrerConfig } from "./termref.rendrer";
import { useGetTermRefDoc } from "../../api/termservice/termservice";
import SectionHeader from "../../components/sectionheader";
import { Section } from "../../components/section";

import { redirectCircledIcon } from "../../svgs";
import { TermRefDrawerStyled } from "./termrefdrawer.styles";
import { termRefBlankSlate } from "../../blankslates/termrefblankslate";

import { useGetAppState, useGetAppType } from "../../customhooks";
import LinkButton from "../../components/linkbutton";
import { ELEMENT_IDS } from "../../constants";
import SuggestAnEditButton from "../../components/suggestaneditbutton/suggestaneditbutton";
import NodeRefPageHOC from "../../components/noderefpagehoc/noderefpagehoc";
import NodeStatusRendrer from "../../components/nodestatusrendrer";
import ProminentTags from "../../components/prominenttagslist";
import CoreNodesCircleIcon from "../../components/corenodetypescircleicon";
import { NodeRefPageHOCEmbededProps } from "../../components/noderefpagehoc/noderefpagehoc.types";
import { TERM_SECTIONS } from "../../constants/labelconstants";
import NodeRefQuickEditComp from "../../components/noderefquickedit";
import { TermRefDrawerProps } from "./termrefdrawer.types";
import StyledLink from "../../components/styledlink/styledlink";
import { getTermDetailPageUrl } from "../../utils";
import StyledBreadcrum, {
  BreadcrumbItemType,
} from "../../components/styledbreadcrum";

const titleSecProps = {
  className: "col-ref-sec-titile",
  showBorderBottom: false,
  isShowIcon: false,
};

const {
  busgls_detpg_ref_ttl: BUSGLS_DETPG_REF_TTL,
  busgls_detpg_ref_goto_btn: BUSGLS_DETPG_REF_GOTO_BTN,
} = ELEMENT_IDS;

function DrawerContent(
  props: NodeRefPageHOCEmbededProps & {
    id?: string;
  }
): JSX.Element {
  const [isEditViewTriggered, setIsEditViewTriggered] = useState(false);
  const { drawer } = useGetAppState();

  const { isExtOrDesktop } = useGetAppType();

  const { id, isDrawer, editViewComp, onClickEdit } = props;

  const { drawerProps = {} } = drawer || {};

  const { id: drawerPropsId, isEdit } = (drawerProps ||
    {}) as TermRefDrawerProps;

  const nodeId = id || drawerPropsId;

  const { parsedData, isLoading, error } = useGetTermRefDoc(
    nodeId,
    "TRM",
    "PUB"
  );

  const { node_desc: nodeDesc = "", node_title: nodeTitle = "" } =
    parsedData || {};

  const toUrl = useMemo(() => {
    const url = getTermDetailPageUrl(nodeId);

    return url;
  }, [drawerProps, nodeId]);

  const termRefRendrer = useMemo(() => {
    return termRefRendrerConfig;
  }, []);

  const onChangeEditView = useCallback(() => {
    setIsEditViewTriggered(true);
  }, [isEditViewTriggered]);

  const sourceItems: BreadcrumbItemType[] = useMemo(
    () => [
      {
        title: parsedData?.domain_name,
        type: "DMN",
      },
      {
        title: parsedData?.category,
        type: "CAT",
      },
    ],
    [parsedData]
  );

  return (
    <StateHandler
      isFetching={isLoading}
      error={error}
      blankSlate={termRefBlankSlate}
    >
      <TermRefDrawerStyled
        hasPromintntTags={!!parsedData?.prominent_tags?.tags?.length}
      >
        <div className="term-ref-header">
          <StyledBreadcrum items={sourceItems} />

          <div className="header_row_2" id={BUSGLS_DETPG_REF_TTL}>
            <SectionHeader
              icon={<CoreNodesCircleIcon nodeType="TRM" marginRight="13px" />}
              title={parsedData?.node_title}
              subTitle=""
              rightIcon={
                <>
                  <NodeStatusRendrer
                    status={parsedData?.publish_status_id}
                    className="deprecate-icon"
                    tooltipTitle={parsedData?.publish_status}
                    nodeType="TRM"
                  />

                  <LinkButton height="33px" disabled={!!editViewComp}>
                    <StyledLink
                      to={editViewComp ? "" : toUrl}
                      target={isExtOrDesktop ? "_blank" : "_self"}
                      cutomClassName="term-redirect-icon"
                      elementId={BUSGLS_DETPG_REF_GOTO_BTN}
                    >
                      {redirectCircledIcon("28px", "28px")}
                    </StyledLink>
                  </LinkButton>
                </>
              }
            />
            {!parsedData?.is_editable && (
              <SuggestAnEditButton
                nodeType="TRM"
                nodeId={nodeId}
                isDrawer={isDrawer}
                onClick={props?.onClickSuggest}
                nodeDesc={parsedData?.node_desc}
                nodeTitle={parsedData?.node_title}
                marginLeft="39px"
              />
            )}
            <div className="node-title" title={parsedData?.term_name || ""}>
              {parsedData?.term_name}
            </div>
            {!!parsedData?.prominent_tags?.tags?.length && (
              <ProminentTags {...parsedData?.prominent_tags} />
            )}
          </div>
        </div>
        {editViewComp || (
          <>
            {parsedData?.is_editable && (
              <NodeRefQuickEditComp
                nodeDesc={nodeDesc}
                nodeId={nodeId}
                nodeTitle={nodeTitle}
                nodeType="TRM"
                onClickEdit={onClickEdit}
                top="40px"
                right="29px"
                isTriggerEditOnMount={isEdit && !isEditViewTriggered}
                onChangeEditView={onChangeEditView}
                nodeName={parsedData?.term_name}
                isDisabled={parsedData?.is_wf_enabled}
              />
            )}
            <div className="term-ref-section, first-type">
              <Section
                secTitle={{
                  title: TERM_SECTIONS?.description,
                  ...titleSecProps,
                }}
                secRowData={parsedData?.overview_info?.map((item) => ({
                  ...item,
                  customJSX: termRefRendrer?.[item?.title]?.(item),
                }))}
              />
            </div>
            <div className="term-ref-section managedby-section">
              <Section
                secTitle={{
                  title: TERM_SECTIONS?.managed_by,
                  ...titleSecProps,
                }}
                secRowData={parsedData?.managed_by?.map((item) => ({
                  ...item,
                  customJSX: termRefRendrer?.[item?.title]?.(item),
                }))}
                mode="section_column"
              />
            </div>
            <div className="term-ref-section managedby-section">
              <Section
                secTitle={{
                  title: TERM_SECTIONS?.business_rules,
                  ...titleSecProps,
                }}
                secRowData={parsedData?.buiseness_rules?.map((item) => ({
                  ...item,
                  customJSX: termRefRendrer?.[item?.title]?.(item),
                }))}
              />
            </div>
            <div className="term-ref-section">
              <Section
                secTitle={{
                  title: TERM_SECTIONS?.linked_columns,
                  ...titleSecProps,
                }}
                secRowData={[]}
                representsData={parsedData?.represents}
                mode="represents"
              />
            </div>
            <div className="term-ref-section">
              <Section
                secTitle={{
                  title: TERM_SECTIONS?.classification,
                  ...titleSecProps,
                }}
                secRowData={parsedData?.clasification}
              />
            </div>
            <div className="term-ref-section">
              <Section
                secTitle={{
                  title: TERM_SECTIONS?.additional_info,
                  ...titleSecProps,
                }}
                additionalInfoData={parsedData?.additionalInfo}
                mode="additional_info"
                secRowData={[]}
              />
            </div>
          </>
        )}
      </TermRefDrawerStyled>
    </StateHandler>
  );
}

const ColRefDrawer = (props: {
  id?: string;
  isDrawer?: boolean;
  setShowParentGoBack?: React.Dispatch<SetStateAction<boolean>>;
  onClickSuggest?: () => void;
}): JSX.Element => {
  return (
    <NodeRefPageHOC
      isDrawer={props?.isDrawer}
      setShowParentGoBack={props?.setShowParentGoBack}
    >
      <DrawerContent {...props} />
    </NodeRefPageHOC>
  );
};

export default ColRefDrawer;
