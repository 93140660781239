import { useMemo } from "react";

import SectionTitleWithIcon from "../sectiontitlewithicon";

import { SimilarNodesAssetsViewType } from "./similarnodeassetsview.types";

import { SimilarNodesAssetsViewStyled } from "./similarnodeassetsview.styles";
import SectionBlankSlateText from "../sectionblankslatetext";

import StateHandler from "../statehandler/statehandler";
import { similarDataAssetsBlankSlate } from "../../blankslates";

import SimilarAssetCard from "../similarassetcard";
import StyledCarasol from "../styledcarasol";

function SimilarNodesAssetsView(
  props: SimilarNodesAssetsViewType
): JSX.Element {
  const { title, parsedData, isLoading, error } = props;

  const simalarAssetCards = useMemo(() => {
    return parsedData?.map((item) => (
      <SimilarAssetCard key={item?.nodeId} data={item} />
    ));
  }, [parsedData]);

  return (
    <SimilarNodesAssetsViewStyled className="similar-nodes-assets-view">
      <SectionTitleWithIcon
        title={title}
        marginBottom="30px"
        marginTop="30px"
        paddingBottom="10px"
        isShowIcon={false}
      />
      <StateHandler
        isFetching={isLoading}
        error={error}
        blankSlate={similarDataAssetsBlankSlate}
      >
        <SectionBlankSlateText
          isEmpty={!parsedData?.length}
          className="sec-blank"
        >
          <StyledCarasol cards={simalarAssetCards} />
        </SectionBlankSlateText>
      </StateHandler>
    </SimilarNodesAssetsViewStyled>
  );
}

export default SimilarNodesAssetsView;
