import { tickIcon } from "../../../../svgs";
import { ListItemStyled } from "./renderuserandstatus.styles";
import { UserProps } from "./renderuserandstatus.types";

const RenderUserAndStatus = (props: UserProps): JSX.Element => {
  const { userName = "", userStatus = "", showStatus = false } = props;
  return (
    <ListItemStyled>
      <span className="name-text"> {userName} </span>
      {showStatus && (
        <span className="status-text">
          (
          {userStatus === "OPN" || userStatus === "REJ" ? (
            <span className="dot" />
          ) : userStatus === "CLS" ? (
            <span className="tick-icon">{tickIcon("10", "10")}</span>
          ) : (
            <></>
          )}
          <i>
            {userStatus === "OPN" || userStatus === "REJ"
              ? "Awaiting Approval "
              : userStatus === "CLS"
              ? "Approved "
              : "In Progress"}
          </i>
          )
        </span>
      )}
    </ListItemStyled>
  );
};
export default RenderUserAndStatus;
