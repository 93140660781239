import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { addIcon, deleteIcon1 } from "../../../../../../../svgs";
import { InputField, TextAreaField } from "../../../../../../formfields";
import { Input } from "../../../../../../inputs";
import LinkButton from "../../../../../../linkbutton";
import SectionBlankSlateText from "../../../../../../sectionblankslatetext";
import {
  BusinessRuleGridRendrerStyled,
  BusinessRuleMinMaxRendrerStyled,
} from "./collapseitem.rendrer.styles";
import { BusinessRuleEditViewCollapseItemProps } from "./collapseitem.types";

export function BusinessRuleGridRendrer(
  props: BusinessRuleEditViewCollapseItemProps
): JSX.Element {
  const { setState, state, currentItem } = props;
  const ruleType = currentItem as "Categorical Values" | "Patterns";

  const onAdd = useCallback(() => {
    setState((st) => ({
      ...st,
      [currentItem]: [{ desc: "", value: "" }, ...st?.[ruleType]],
    }));
  }, [props]);

  const onDelete = useCallback(
    (index) => {
      const currentTypeList = state?.[ruleType];
      const updatedArr = [
        ...currentTypeList.slice(0, index),
        ...currentTypeList.slice(index + 1, currentTypeList?.length),
      ];
      setState((st) => ({ ...st, [ruleType]: updatedArr }));
    },
    [props]
  );

  const onChange = useCallback(
    (index, event, isDes) => {
      const currentTypeList = state?.[ruleType];
      const updatedArr = [
        ...currentTypeList.slice(0, index),
        {
          ...currentTypeList?.[index],
          [isDes ? "desc" : "value"]: event?.target?.value || "",
        },
        ...currentTypeList.slice(index + 1, currentTypeList?.length),
      ];
      setState((st) => ({ ...st, [ruleType]: updatedArr }));
    },
    [props]
  );

  return (
    <BusinessRuleGridRendrerStyled hasScroll={state?.[ruleType]?.length > 6}>
      <div className="grid-header">
        <LinkButton
          fontSize="15px"
          fontFamily="OpenSansSemiBold"
          onClick={onAdd}
        >
          {addIcon()}Add
        </LinkButton>
      </div>
      <div className="grid-view">
        <div className="grid-view-header">
          <div>Values</div>
          <div>Description</div>
          <div>Action</div>
        </div>
        <SectionBlankSlateText isEmpty={!state?.[ruleType]?.length}>
          <div className="grid-view-body">
            {state?.[ruleType]?.map((item, index) => (
              <div
                className="grid-view-body-item"
                key={`grid-view-body-item-${index}`}
              >
                <Input
                  value={item?.value}
                  onChange={(e): void => onChange(index, e, false)}
                  placeholder="Value"
                />
                <Input
                  value={item?.desc}
                  onChange={(e): void => onChange(index, e, true)}
                  placeholder="Description"
                />
                <div>
                  <LinkButton
                    height="22px"
                    onClick={(): void => onDelete(index)}
                  >
                    {deleteIcon1("11", "12")}
                  </LinkButton>
                </div>
              </div>
            ))}
          </div>
        </SectionBlankSlateText>
      </div>
    </BusinessRuleGridRendrerStyled>
  );
}

export function BusinessRuleMinMaxRendrer(): JSX.Element {
  const { control } = useFormContext();
  return (
    <BusinessRuleMinMaxRendrerStyled>
      <div className="min-max-rendrer-header">
        <div>
          Min Value:
          {/* <span className="required-field">*</span> */}
        </div>
        <div>
          Max Value:
          {/* <span className="required-field">*</span> */}
        </div>
      </div>
      <div className="min-max-rendrer-body">
        <InputField
          control={control}
          name="min_value"
          width="221px"
          isMessagePositionAbsolute
          required
        />
        <div className="seperation-line" />
        <InputField
          control={control}
          name="max_value"
          width="221px"
          isMessagePositionAbsolute
          required
        />
      </div>
    </BusinessRuleMinMaxRendrerStyled>
  );
}

export function BusinessRuleDescRendrer(): JSX.Element {
  const { control } = useFormContext();
  return (
    <TextAreaField
      control={control}
      name="manual"
      height="160px"
      isMessagePositionAbsolute
      required
    />
  );
}
