import { NodeType } from "../../../app.types";
import { rightAngleIcon } from "../../../svgs";
import CoreNodesCircleIcon from "../../corenodetypescircleicon";
import {
  TermRenderItemForSelectStyled,
  TermRenderTitleForSelectStyled,
} from "./termrendrer.styles";

export const termTitleRenderForSelect = (title: string): any => (
  <TermRenderTitleForSelectStyled>{title}</TermRenderTitleForSelectStyled>
);

const getNodeIcon = (nodeType: NodeType): JSX.Element => (
  <CoreNodesCircleIcon
    nodeType={nodeType}
    width="15px"
    height="15px"
    mode="secondary"
    fontSize="9px"
  />
);
export const termItemRrenderForSelect = (item: any): any => ({
  value: item?.nodeId,
  label: (
    <TermRenderItemForSelectStyled className="render-item">
      {getNodeIcon("CAT")}
      <div className="parent-name" title={item?.parentName}>
        {item?.parentName}
      </div>
      <div className="right-icon">{rightAngleIcon}</div> {getNodeIcon("TRM")}
      <div className="node-name" title={item?.nodeName}>
        {item.nodeName}
      </div>
    </TermRenderItemForSelectStyled>
  ),
});
