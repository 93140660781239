import {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";

import { WorkflowHOCEmbedProps, WorkflowHOCProps } from "./workflowhoc.types";
import { useRequestWithMethod } from "../../api";

import {
  getPostLoginData,
  localTimeZoneToUtc,
  openNotification,
} from "../../utils";

import SuccessNotificationMessage from "../successnotificationmessagerendrer/successnotificationmessagerendrer";
import { ActivityActionType } from "../detailpagesoverviewrightsec/detailpagesoverviewrightsec.types";

import { API_CONFIG } from "../../constants/apiconfig";
import { useOpenDrawer, useQueryParams, useSetData } from "../../customhooks";

import { useGetWorkflowSteps } from "../../api/termservice/termservice";
import { CommentHistoryDrawerTypes } from "../../drawerviews/activityhistorydrawer/activityhistorydrawer.types";

import { DetailPageHistoryState } from "../noderefquickedit/noderefquickedit.types";

import { WorkflowResponse } from "../../parsers/termsparser";

const WorkflowHOC = ({
  nodeId = "",
  nodeType = "TRM",
  children,
  refetchNodeData,
  refetchAssetRelationshipsData,
  isReportPage = false,
}: WorkflowHOCProps): JSX.Element => {
  const { user_info: userInfo } = getPostLoginData();
  const onSetData = useSetData();
  const isNodeSubmittedForApproval = useRef<boolean>(false);
  const params = useQueryParams();
  const openDrawer = useOpenDrawer();
  const history = useHistory<DetailPageHistoryState>();

  const [isNodeSendForPublish, setIsNodeSendForPublishing] = useState(false);
  const [showReviewChangesPanel, setShowReviewChangesPanel] = useState(false);

  const onToggleReviewChangesPanel = useCallback(() => {
    setShowReviewChangesPanel(!showReviewChangesPanel);
  }, [showReviewChangesPanel]);

  const onHideReviewChangesPanel = useCallback(() => {
    setShowReviewChangesPanel(false);
  }, []);

  const setNodeSubmittedForApproval = useCallback(() => {
    isNodeSubmittedForApproval.current = true;
  }, []);

  const {
    parsedData: workflow,
    isFetching: isWorkflowStepsLoading,
    error: errorInWorkflowSteps,
  } = useGetWorkflowSteps(nodeId, nodeType);

  const resetWorkflowData = useCallback(
    (apiResponse: { data: WorkflowResponse[] }) => {
      onSetData(API_CONFIG.get_workflow_steps, apiResponse?.data, [
        nodeType,
        nodeId,
      ]);
    },
    [nodeType, nodeId]
  );

  const { workflowSteps = [], workflowTemplateId = 0 } = workflow || {};
  const workflowId = workflow?.workflowId || 0;

  const inProgressUsersCountForApproveStep = useMemo(() => {
    const getApproveStep = workflowSteps?.find(
      (item) => item?.stepType === "APR"
    );
    const inProgressUsers =
      getApproveStep?.stepUsers?.filter((item) => item?.userStatus === "OPN") ||
      [];
    return inProgressUsers?.length || 0;
  }, [workflow]);

  const {
    isLoading: isWorkflowActionLoading,
    error: errorInWorkflowAction,
    onExecuteRequest: executeWorkflowActionRequest,
  } = useRequestWithMethod("workflow_action");

  const onNodeSubmittedForApprovalSuccess = useCallback(
    (apiResponse: { data: WorkflowResponse[] }) => {
      resetWorkflowData(apiResponse);
      isNodeSubmittedForApproval.current = false;
      openNotification(
        <SuccessNotificationMessage
          message="Asset is submitted for review, we will notify you with the updates"
          showSuccess
          alignItems="flex-start"
        />
      );
    },
    []
  );

  const onCancelNodeSubmissionSuccess = useCallback(
    (apiResponse: { data: WorkflowResponse[] }) => {
      resetWorkflowData(apiResponse);
      openNotification(
        <SuccessNotificationMessage
          message="Asset submission for approval is cancelled"
          showSuccess
        />
      );
    },
    []
  );

  const onNodeCommentOrRejectRequestSuccess = useCallback(
    (apiResponse: { data: WorkflowResponse[] }) => {
      resetWorkflowData(apiResponse);
      onHideReviewChangesPanel();
      openNotification(
        <SuccessNotificationMessage
          message={
            <div>
              You have rejected the Asset. We will inform you once the <br />
              editor made the changes
            </div>
          }
          showSuccess
          alignItems="flex-start"
        />
      );
    },
    []
  );

  const onNodeApprovedSuccess = useCallback(
    (apiResponse: { data: WorkflowResponse[] }) => {
      const isOnlyApprover =
        workflowTemplateId === 1 || inProgressUsersCountForApproveStep === 1;

      isOnlyApprover && setIsNodeSendForPublishing(true);
      const refetchPromises = [
        refetchAssetRelationshipsData?.(),
        refetchNodeData?.(),
      ];
      Promise.all(refetchPromises).then(() => {
        resetWorkflowData(apiResponse);
        openNotification(
          <SuccessNotificationMessage
            message={
              isOnlyApprover
                ? "Asset has been approved and published in the Asset Dictionary"
                : `Asset is approved`
            }
            showSuccess
          />
        );
        setIsNodeSendForPublishing(false);
        setShowReviewChangesPanel(false);
      });
    },
    [inProgressUsersCountForApproveStep, workflowTemplateId]
  );

  const sendNodeForApproval = useCallback(() => {
    executeWorkflowActionRequest(
      undefined,
      [workflowId || "None", "SMT", nodeId, nodeType],
      onNodeSubmittedForApprovalSuccess
    );
  }, [workflowId, nodeType, nodeId]);

  const onCancelNodeSubmission = useCallback(() => {
    executeWorkflowActionRequest(
      undefined,
      [workflowId, "CSB", nodeId, nodeType],
      onCancelNodeSubmissionSuccess
    );
  }, [workflowId, nodeId, nodeType]);

  const onNodeCommentOrRejectRequest = useCallback(
    (
      comment: string,
      actionType: ActivityActionType,
      numOfRecordsToFetch?: number
    ) => {
      const commentDetails = {
        USER_NAME: userInfo?.user_name || "",
        CREATED_ON: localTimeZoneToUtc(),
        EVENT_TYPE: "CMT",
        NODE_DESC: comment,
      };

      executeWorkflowActionRequest(
        {
          comment,
        },
        [workflowId || "None", actionType, nodeId, nodeType],
        (apiResponse: { data: WorkflowResponse[] }) => {
          if (actionType === "REJ") {
            onNodeCommentOrRejectRequestSuccess(apiResponse);
          }

          onSetData(
            API_CONFIG.user_activities,
            commentDetails,
            [nodeId, nodeType, `${numOfRecordsToFetch}`],
            { action: "CREATE" }
          );
        }
      );
    },
    [workflowId, nodeId, nodeType]
  );

  const onApproveNodeRequest = useCallback(() => {
    executeWorkflowActionRequest(
      undefined,
      [workflowId, "APR", nodeId, nodeType],
      onNodeApprovedSuccess
    );
  }, [workflowId]);

  const onOpenRef = useCallback(() => {
    openDrawer({
      drawerId: "comment_history_ref",
      visible: true,
      drawerProps: {
        nodeType,
        nodeId,
        activityRequest: onNodeCommentOrRejectRequest,
        actionType: "CMT",
      } as CommentHistoryDrawerTypes,
    });
  }, [nodeId, nodeType]);

  const openActivityRefForRejectedNode = useCallback(() => {
    if (params?.has("isFromEmail")) {
      onOpenRef();
      params?.delete("isFromEmail");
      history.replace({
        search: params?.toString(),
      });
    }
  }, []);

  useEffect(() => {
    openActivityRefForRejectedNode();
  }, []);

  return cloneElement(children, {
    onSubmitForApproval: sendNodeForApproval,
    onCancelSubmission: onCancelNodeSubmission,
    onNodeCommentOrRejectRequest,
    setNodeSubmittedForApproval,
    onApproveNodeRequest,
    onToggleReviewChangesPanel,
    workflowLoadingState: {
      isWorkflowStepsLoading,
      isWorkflowActionLoading,
    },
    workflowErrorState: {
      errorInWorkflowSteps,
      errorInWorkflowAction,
    },
    isNodeSubmittedForApproval,
    showReviewChangesPanel,
    isNodeSendForPublish,
    workflow,
  } as WorkflowHOCEmbedProps);
};

export default WorkflowHOC;
