import styled from "styled-components";

export const EntitySelectionStyled = styled.div`
  .menu-section {
    justify-content: flex-end;
  }

  .add-button {
    justify-content: flex-start;
  }
`;
