import { useCallback } from "react";

//        <---Components-->
import Flex from "../flex";
import LinkButton from "../linkbutton";
//        <---Components-->

//        <---SVGS--->
import { redirectCircledIcon } from "../../svgs";
//        <---SVGS--->

//        <---Types--->
import { LineagesectitlewithiconProps } from "./lineagesectitlewithicon.types";
//        <---Types--->

//        <---Utils--->

import { buildLineagePageUrl, getUserPermissions } from "../../utils";

//        <---Utils--->

function LineageSectionTitleWithIcon(
  props: LineagesectitlewithiconProps
): JSX.Element {
  const { title = "Lineage", isRefView, queryParams, lineageExists } = props;

  const onClick = useCallback(() => {
    const { nodeId = "", nodeSubType, nodeName = "", nodeType = "TBL" } =
      queryParams || {};

    const isSubNode = nodeType === "COL" || nodeType === "DSF";

    const lineageUrl = buildLineagePageUrl({
      nodeId,
      nodeName,
      nodeType,
      nodeSubType,
      isSubNode,
    });

    window.open(lineageUrl);
  }, [props]);

  const { has_no_lineage_access: hasNoLineageAccess } = getUserPermissions();

  return (
    <Flex>
      {title}
      <LinkButton
        marginLeft="8px"
        height={isRefView ? "20px" : "33px"}
        onClick={onClick}
        disabled={hasNoLineageAccess || !lineageExists}
        tooltipProps={{
          title: !lineageExists
            ? "Lineage doesn't exist"
            : hasNoLineageAccess
            ? "You do not have access to lineage. Contact your administrator for access."
            : "",
          placement: "right",
          overlayClassName: "lineage-tooltip",
        }}
      >
        {redirectCircledIcon("20", "20")}
      </LinkButton>
    </Flex>
  );
}

export default LineageSectionTitleWithIcon;
