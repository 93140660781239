import { useMemo } from "react";

import { Section } from "../../components/section";
import { ETL_SECTIONS } from "../../constants/labelconstants/etlconfig";

import {
  EtlCodeRefDrawerHeaderWrapperStyled,
  ETLRefDrawerHeaderStyled,
  ETLRefDrawerSectionWrapperStyled,
  ETLRefDrawerStyled,
} from "./etlrefdrawer.styles";

import { ETLRefDrawerPropsType } from "./etlrefdrawer.types";
import { etlRefDrawer } from "./etlrefdrawer.renderer";

import { codeIcon, settingsIcon } from "../../svgs";

import {
  useGetEtlCode,
  useGetEtlDoc,
  useGetPreSignedUrlForEtlCodes,
} from "../../api/etlservice/etlservice";
import { useGetAppState } from "../../customhooks";

import {
  etlBlankSlate,
  etlRefCodeBlankSlate,
} from "../../blankslates/etlblankslate";
import StateHandler from "../../components/statehandler";

import { ContentPreviewer } from "../../components";
import StyledBreadcrum, {
  BreadcrumbItemType,
} from "../../components/styledbreadcrum";

const titleSecProps = {
  className: "col-ref-sec-titile",
  showBorderBottom: false,
  isShowIcon: false,
};

const etlNodeIcon = settingsIcon;

const edgeCodeIcon = codeIcon();

const ETLRefDrawer = (props: ETLRefDrawerPropsType): JSX.Element => {
  const { drawer } = useGetAppState();

  const { drawerProps = {} } = drawer || {};

  const nodeId = props?.id || drawerProps?.id;

  const { parsedData, isLoading, error } = useGetEtlDoc(nodeId, "PLO", "PUB");

  const etlRefDrawerRendrer = useMemo(() => {
    return etlRefDrawer;
  }, []);

  const { etlCodeS3Url = "", etlCode: etlParsedCode = "" } = parsedData || {};

  const {
    parsedData: presignedUrlForEtlCode,
    isFetching: isFetchingPresignedUrlForEtlCode,
    error: errorPresignedUrlForEtlCode,
  } = useGetPreSignedUrlForEtlCodes(etlCodeS3Url, !!etlCodeS3Url);

  const { url = "" } = presignedUrlForEtlCode || {};

  const {
    parsedData: etlS3CodeDetails = [],
    isFetching: isFetchingEtlCode,
    error: errorEtlCode,
  } = useGetEtlCode(url);

  const etlS3Code = etlS3CodeDetails?.plo_code || "";

  const etlCode = etlS3Code || etlParsedCode;

  // confirm from TL
  const replacedStr = etlCode
    ?.replace(/\\n/g, "\n ")
    ?.replace(/\\ n/g, "\n ")
    ?.replace(/\\ r/g, "\r ")
    ?.replace(/\\r/g, "\r ")
    ?.replace(/\\ t/g, "\t ")
    ?.replace(/\\t/g, "\t ");

  const refDrawerHeaderJsx = (): JSX.Element => (
    <EtlCodeRefDrawerHeaderWrapperStyled>
      <div className="icon">{edgeCodeIcon}</div>
      <span className="etl-name">Etl Code</span>
    </EtlCodeRefDrawerHeaderWrapperStyled>
  );

  const {
    parentName = "",
    schemaName = "",
    dbName = "",
    sourceTypeId,
    parentId = "",
    nodeName = "",
  } = parsedData || {};

  const breadcrumItems: BreadcrumbItemType[] = useMemo(
    () => [
      {
        title: parentName,
        srcTypeId: sourceTypeId,
        nodeId: String(parentId),
        type: "SRC",
      },
      {
        title: dbName,
        type: "DTB",
      },
      {
        title: schemaName,
        type: "SCM",
      },
    ],
    [parsedData]
  );

  return (
    <StateHandler
      isFetching={isLoading}
      error={error}
      blankSlate={etlBlankSlate}
    >
      <ETLRefDrawerStyled>
        <ETLRefDrawerHeaderStyled>
          <StyledBreadcrum items={breadcrumItems} marginBottom="0px" />

          <div className="etl-info">
            <span className="etl-icon">{etlNodeIcon}</span>
            <span className="etl-name"> {nodeName}</span>
          </div>
        </ETLRefDrawerHeaderStyled>
        <ETLRefDrawerSectionWrapperStyled>
          <div className="etl-metadata-info">
            <Section
              secTitle={{
                title: ETL_SECTIONS?.metaInfo,
                ...titleSecProps,
              }}
              secRowData={parsedData?.metaInfo?.map((item) => ({
                ...item,
                customJSX: etlRefDrawerRendrer?.[item?.title]?.(item),
              }))}
            />
          </div>
          <div className="etl-exec-details-info">
            <Section
              secTitle={{
                title: ETL_SECTIONS?.executionDetails,
                ...titleSecProps,
              }}
              secRowData={parsedData?.execution?.map((item) => ({
                ...item,
                customJSX: etlRefDrawerRendrer?.[item?.title]?.(item),
              }))}
            />
          </div>
          <div className="etl-managed-by-info">
            <Section
              secTitle={{
                title: ETL_SECTIONS?.managedBy,
                ...titleSecProps,
              }}
              secRowData={parsedData?.managedBy?.map((item) => ({
                ...item,
                customJSX: etlRefDrawerRendrer?.[item?.title]?.(item),
              }))}
            />
          </div>

          <StateHandler
            isFetching={isFetchingPresignedUrlForEtlCode || isFetchingEtlCode}
            error={errorEtlCode || errorPresignedUrlForEtlCode}
            blankSlate={etlRefCodeBlankSlate}
          >
            <div className="etl-code-info">
              <ContentPreviewer
                title="Source Code"
                showCrossIcon={false}
                marginBottom="0"
                sqlQuery={replacedStr}
                addScroll
                showCollapseIcon={false}
                showExpandIcon
                expandedModalTitle={refDrawerHeaderJsx}
              />
            </div>
          </StateHandler>
        </ETLRefDrawerSectionWrapperStyled>
      </ETLRefDrawerStyled>
    </StateHandler>
  );
};

export default ETLRefDrawer;
