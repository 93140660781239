import { Tooltip } from "antd";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { appendQueryParamInUrl, useRequestWithMethod } from "../../api";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { API_CONFIG } from "../../constants/apiconfig";
import { useCopyToClipboard } from "../../customhooks";
import { NodeSuggestionsResponse } from "../../parsers/nodesuggestions/nodesuggestionsparser.types";
import { solidSuccessTickIcon } from "../../svgs";
import { openNotification } from "../../utils";
import LinkButton from "../linkbutton";
import StateHandler from "../statehandler/statehandler";
import { SimpleTag } from "../tags";
import { SuggestionStyled } from "./suggestionrendrer.styles";
import { SuggestionsRendrerProps } from "./suggestionrendrer.types";

export default function SuggestionsRendrer({
  sugestion,
}: SuggestionsRendrerProps): JSX.Element {
  const queryClient = useQueryClient();

  const onCopy = useCopyToClipboard();
  const { onExecuteRequest, isLoading, error } = useRequestWithMethod(
    "resolved_suggestion",
    [sugestion?.id],
    undefined,
    () => {
      const apiUrl = appendQueryParamInUrl(API_CONFIG.get_suggestions.url, [
        sugestion?.suggNodeId,
      ]);
      const data = queryClient?.getQueryData(apiUrl) as any;
      queryClient?.setQueryData(apiUrl, {
        ...data,
        data: (data?.data as NodeSuggestionsResponse[])?.filter(
          (item) => `${item?.SUGG_ID}` !== sugestion?.id
        ),
      });
      openNotification(
        <span>
          <span className="action-text">{solidSuccessTickIcon} Success!</span>
          Your suggestion has been marked as resolved
        </span>
      );
    }
  );

  const onMarkAsResolved = useCallback(() => {
    onExecuteRequest();
  }, [sugestion]);

  return (
    <StateHandler isFetching={false} error={error} isShowBlankSlate={false}>
      <SuggestionStyled>
        <div className="suggestion-header" title={sugestion?.suggestedBy}>
          <div className="user">Suggested by {sugestion?.suggestedBy} </div>
        </div>
        <div className="suggestion-body">
          <div className="days-ago">{sugestion?.duration}</div>
          {!sugestion?.isDeprecatedSuggestion ? (
            <>
              <div className="suggestion-title">Name:</div>
              <Tooltip
                title="Click to copy"
                overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                placement="topLeft"
              >
                <div
                  onClick={(): void => onCopy(sugestion?.name)}
                  role="button"
                  className="suggestion-value"
                >
                  {sugestion?.name}
                </div>
              </Tooltip>
              <div className="suggestion-title">Description:</div>
              <Tooltip
                title="Click to copy"
                overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                placement="topLeft"
              >
                <div
                  className="suggestion-value"
                  title={sugestion?.desc}
                  onClick={(): void => onCopy(sugestion?.desc)}
                  role="button"
                >
                  {sugestion?.desc}
                </div>
              </Tooltip>
              <div className="suggestion-title">Tags:</div>
              <div className="suggestion-value tags">
                {sugestion?.tags?.map((tag, index) => (
                  <Tooltip
                    title="Click to copy"
                    overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                    placement="topLeft"
                    key={`${tag}-overview-rendrer-${index}`}
                  >
                    <span
                      onClick={(): void => onCopy(tag)}
                      role="button"
                      className="single-tag"
                    >
                      <SimpleTag width="150px" marginRight="0px">
                        {tag}
                      </SimpleTag>
                    </span>
                  </Tooltip>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="suggestion-title">Comment:</div>
              <div className="suggestion-value">{sugestion?.comment}</div>
            </>
          )}
          <LinkButton
            fontFamily="OpenSansSemibold"
            onClick={onMarkAsResolved}
            disabled={isLoading}
          >
            Mark as Resolved
          </LinkButton>
        </div>
      </SuggestionStyled>
    </StateHandler>
  );
}
