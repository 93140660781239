import { useHistory } from "react-router";
import { useCallback } from "react";
import Poster from "../../components/poster";

import { useCancelModal, useGetAppState } from "../../customhooks";
import { AddRuleFormModalProps } from "./addruleform.types";

import { AddCountFormStyled } from "./addruleform.styles";

import { openNotification, getRuleDetailPageUrl } from "../../utils";
import SuccessNotificationMessage from "../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";

import RuleWrapper from "../../components/rulewrapper/rulewrapper";
import { useGetRefByType } from "../../api/refservice";
import { ruleDetailPageTabsValues } from "../../pages/ruledetailpage/ruledetailpage.views/ruledetailpagecontent/ruledetailpagecontent.types";
import { rulePageViewValues } from "../../pages/ruledetailpage/ruledetailpage.constants";

const AddRuleForm = (): JSX.Element => {
  const { modal: { modalProps = {} } = {} } = useGetAppState();
  const onCancel = useCancelModal();
  const history = useHistory();

  const {
    ruleCategoryId,
    formId,
    tableId = "",
    sourceId = 0,
    srcTypeId,
    ruleConfig,
    isFromRecommendedRules = false,
    columnId,
    onAddRecommendedRuleSuccess,
  } = modalProps as AddRuleFormModalProps;

  const isRulesetOrCustomquery = formId === "MDR" || formId === "CUQ";

  const { parsedData: dqRulesCategoriesAndTypes } = useGetRefByType(
    "DQ_RULE_TYPES"
  );

  const onAddRuleSuccess = useCallback(
    (res) => {
      if (isFromRecommendedRules) {
        onAddRecommendedRuleSuccess?.(res);
        onCancel();
      } else {
        openNotification(
          <SuccessNotificationMessage message="New rule added successfully." />
        );
        onCancel();
        const newlyCreatedRuleId = Number(res?.data?.FN_CREATE_NODE) || 0;
        const ruleDetailPageUrl = getRuleDetailPageUrl(
          `${newlyCreatedRuleId}`,
          ruleDetailPageTabsValues.definition,
          rulePageViewValues.data_quality
        );
        history.push(ruleDetailPageUrl);
      }
    },
    [isFromRecommendedRules]
  );

  return (
    <AddCountFormStyled
      isFromRuleSet={isFromRecommendedRules}
      isRulesetOrCustomquery={isRulesetOrCustomquery}
    >
      <RuleWrapper
        ruleConfig={ruleConfig}
        formId={formId}
        ruleCategoryId={ruleCategoryId}
        tableId={tableId}
        sourceId={sourceId}
        srcTypeId={srcTypeId}
        onAddRuleSuccess={onAddRuleSuccess}
        propsOnCancel={onCancel}
        isModal
        isFromRecommendedRules={isFromRecommendedRules}
        columnId={columnId}
        rulesRef={dqRulesCategoriesAndTypes}
      />
      <Poster
        formId={formId}
        ruleCategoryId={ruleCategoryId}
        rulesRef={dqRulesCategoriesAndTypes}
      />
    </AddCountFormStyled>
  );
};

export default AddRuleForm;
