import { AxiosResponse } from "axios";
import {
  isJsonString,
  jsonParse,
  sortListOnSpecificKeyValue,
} from "../../utils";
import {
  CategoryResponseType,
  CategoryType,
  DomainsSubdomainResponse,
  DomainSubdomainListReturnType,
  DomainUserResponseType,
  DomainUserType,
  GlossaryDomainsForSelectParserReturnType,
  GlossaryDomainsParserReturnType,
  GlossaryDomainsResponse,
} from "./glossarydomainsparser.types";

export const getParsedGlossaryDomains = ({
  data,
}: AxiosResponse<
  GlossaryDomainsResponse[]
>): GlossaryDomainsParserReturnType[] => {
  return (
    data?.map((item) => {
      const domainCategories: CategoryType[] = jsonParse(item?.CATEGORIES)?.map(
        (category: CategoryResponseType) => {
          return {
            id: category?.cat_id || 0,
            name: category?.cat_name || "",
          };
        }
      );

      const domainDataOwners: DomainUserType[] = jsonParse(
        item?.DATA_OWNERS
      )?.map((owner: DomainUserResponseType) => {
        return {
          id: owner?.user_id || 0,
          name: owner?.user_name || "",
          email: owner?.user_email || "",
          type: owner?.user_type || "",
        };
      });

      const domainDataStewards: DomainUserType[] = jsonParse(
        item?.DATA_STEWARDS
      )?.map((steward: DomainUserResponseType) => {
        return {
          id: steward?.user_id || 0,
          name: steward?.user_name || "",
          email: steward?.user_email || "",
          type: steward?.user_type || "",
        };
      });

      const domainDataApprovers: DomainUserType[] = jsonParse(
        item?.DMN_APPROVERS
      )?.map((approver: DomainUserResponseType) => {
        return {
          id: approver?.user_id || 0,
          name: approver?.user_name || "",
          email: approver?.user_email || "",
          type: approver?.user_type || "",
        };
      });

      const parsedSettings = jsonParse(item?.DMN_SETTINGS || "");

      return {
        id: item?.DMN_ID || 0,
        name: item?.DMN_NAME || "",
        description: item?.DMN_DESC || "",
        is_predefined: item?.IS_PREDEFINED || false,
        categories: domainCategories,
        created_by_id: item?.CREATED_BY_ID || 0,
        created_by: item?.CREATED_BY || "",
        created_on: item?.CREATED_ON || "",
        updated_by_id: item?.UPDATED_BY_ID || 0,
        updated_by: item?.UPDATED_BY || "",
        updated_on: item?.UPDATED_ON || "",
        data_owners: domainDataOwners.map((item) => item?.name),
        data_owners_obj: domainDataOwners,
        data_stewards: domainDataStewards.map((item) => item?.name),
        data_stewards_obj: domainDataStewards,
        action: "",
        is_workflow_enabled: item?.IS_WF_ENABLED || false,
        is_dq_workflow_enabled: item?.IS_DQ_WF_ENBLED || false,
        is_workflow_inherited: item?.IS_WT_INHERITED || false,
        data_approvers: domainDataApprovers?.map((item) => item?.name) || [],
        data_approvers_obj: domainDataApprovers || [],
        workflow_id: item?.WT_ID || 0,
        domainSettings: parsedSettings || {},
      };
    }) || []
  );
};

export const getParsedGlossaryDomainsForSelect = ({
  data = [],
}: AxiosResponse<
  GlossaryDomainsResponse[]
>): GlossaryDomainsForSelectParserReturnType[] => {
  const formatedData = data?.map((item) => {
    const domainDataApprovers: DomainUserType[] = jsonParse(
      item?.DMN_APPROVERS
    ).map((approver: DomainUserResponseType) => {
      return {
        id: approver?.user_id || 0,
        name: approver?.user_name || "",
        email: approver?.user_email || "",
        type: approver?.user_type || "",
      };
    });
    const domainDataStewardsList: DomainUserType[] = jsonParse(
      item?.DATA_STEWARDS
    )?.map((item: DomainUserResponseType) => ({
      id: item?.user_id || 0,
      name: item?.user_name || "",
      email: item?.user_email || "",
      type: item?.user_type || "",
    }));

    const parsedDomainSettings = jsonParse(item?.DMN_SETTINGS || "");

    return {
      value: `${item?.DMN_ID}` || "",
      label: item?.DMN_NAME || "",
      labelText: item?.DMN_NAME || "",
      isWorkflowEnabled: item?.IS_WF_ENABLED || false,
      data_approvers: domainDataApprovers?.map((item) => item?.name) || [],
      data_approvers_obj: domainDataApprovers || [],
      data_stewards_list: domainDataStewardsList || [],
      workflow_id: item?.WT_ID || 0,
      isGovEnabled: !!parsedDomainSettings?.is_gov_enabled,
    };
  });

  return sortListOnSpecificKeyValue({ list: formatedData, key: "labelText" });
};

export const getDomainSubdomainListOfLoggedUser = ({
  data,
}: AxiosResponse<
  DomainsSubdomainResponse[]
>): DomainSubdomainListReturnType[] => {
  return (
    data?.map((item) => {
      return {
        categoryName: item?.CAT_NAME || "",
        categoryId: item?.CAT_ID || "",
        domainName: item?.DMN_NAME || "",
        domainId: `${item?.DMN_ID || ""}`,
        isWorkflowEnabled: item?.IS_WF_ENABLED || false,
        isWorkflowInherited: item?.IS_WF_INHERITED || false,
      };
    }) || []
  );
};
