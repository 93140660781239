import { useCallback, useMemo, useState } from "react";

import { ApplicableToRendererProps } from "./applicabletorenderer.types";

import { ApplicableToStyled } from "./applicabletorenderer.styles";
import CoreNodesCircleIcon from "../../../../../../components/corenodetypescircleicon";

import { getObjectKeys } from "../../../../../../utils";
import { NodeType } from "../../../../../../app.types";
import { LinkButton } from "../../../../../../components";

const nodeIconProps = { width: "15px", height: "15px", fontSize: "12px" };

const ApplicableToRenderer = (
  props: ApplicableToRendererProps
): JSX.Element => {
  const [allShowing, setAllShowing] = useState<boolean>(false);
  const { applicableTo } = props;

  const dataToRender = useMemo(
    () =>
      getObjectKeys(applicableTo)?.map((key, index) =>
        applicableTo?.[key]?.count !== undefined ? (
          <div
            className="applicable-to-row"
            key={`applicable-to-${key}-${index}`}
          >
            <span className="applicable-to-name">
              <span className="datasets">
                <CoreNodesCircleIcon
                  nodeType={key as NodeType}
                  {...nodeIconProps}
                  mode="tertiary"
                  fontSize="9px"
                  width="20px"
                  height="20px"
                />
              </span>
              {applicableTo?.[key]?.name}
            </span>
            <span className="applicable-to-description">
              Used {applicableTo?.[key]?.count} times on{" "}
              {applicableTo?.[key]?.name}
            </span>
          </div>
        ) : (
          <div key={`applicable-to-${key}-${index}`} />
        )
      ),
    [applicableTo]
  );

  const dataToView = useMemo(() => {
    const showCount = allShowing ? dataToRender?.length : 6;
    return dataToRender?.slice(0, showCount);
  }, [allShowing, dataToRender]);

  const showAllBtnHandler = useCallback((): void => {
    setAllShowing(!allShowing);
  }, [allShowing]);

  return (
    <ApplicableToStyled>
      {dataToView}
      {dataToRender?.length > 6 && (
        <LinkButton type="link" onClick={showAllBtnHandler} marginLeft="5px">
          {allShowing ? "Show Less" : `Show More`}
        </LinkButton>
      )}
    </ApplicableToStyled>
  );
};

export default ApplicableToRenderer;
