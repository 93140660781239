import { useCallback, useState } from "react";
import { Button } from "../../../..";

import {
  ActionsWrapper,
  CommentPopoverContentStyled,
} from "./commentpopovercontent.styles";

import { TextAreaFieldStyled } from "../../../../formfields/textareafield/textareafield.styles";
import { CommentPopoverContentProps } from "./commentpopovercontent.types";

const CommentPopoverContent = (
  props: CommentPopoverContentProps
): JSX.Element => {
  const { defaultValue = "", onSave, onCancel } = props;

  const [commentText, setCommentText] = useState(defaultValue);

  const isSaveDisabled = defaultValue === commentText;

  const handleSave = useCallback((): void => {
    onSave(commentText);
  }, [commentText, onSave]);

  const onTextAreaChange = useCallback(
    (e): void => setCommentText(e?.target?.value),
    []
  );

  return (
    <CommentPopoverContentStyled>
      <div className="title">Comment</div>

      <TextAreaFieldStyled
        placeholder="(Optional) Add a comment"
        value={commentText}
        onChange={onTextAreaChange}
        height="88px"
      />

      <ActionsWrapper>
        <Button onClick={onCancel} id="cancel">
          Cancel
        </Button>
        <Button
          id="primary"
          onClick={handleSave}
          disabled={isSaveDisabled}
          tooltipProps={{
            title: isSaveDisabled
              ? "You haven't made any changes in the comment."
              : "",
            placement: "topRight",
          }}
        >
          Save
        </Button>
      </ActionsWrapper>
    </CommentPopoverContentStyled>
  );
};

export default CommentPopoverContent;
