import { NodeType } from "../../app.types";
import { API_CONFIG } from "../../constants/apiconfig";
import { getParsedEtlDocForRefPage } from "../../parsers/etlparser/etlparser";
import { EtlParsedDocType } from "../../parsers/etlparser/etlparser.types";
import { getFiltersfromLocalStorage } from "../../utils";
import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

const { get_node: getNode } = API_CONFIG;

export function useGetEtlDoc(
  nodeID: string,
  nodeType: NodeType,
  nodeStatus: string
): CustomReactQueryHookReturnType<EtlParsedDocType> {
  const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);
  return useGetAll({
    apiConfig: getNode,
    params: [nodeID, nodeType, nodeStatus, "", "", parsedNodeFilter],
    parser: getParsedEtlDocForRefPage,
  });
}

export const useGetPreSignedUrlForEtlCodes = (
  url: string,
  enabled: boolean
): CustomReactQueryHookReturnType<any> => {
  return useGetAll<any>({
    apiConfig: API_CONFIG?.get_presigned_json_url,
    options: {
      enabled,
      refetchOnMount: false,
    },
    params: [url],
    parser: (data): void => data?.data,
  });
};

export const useGetEtlCode = (
  url: string
): CustomReactQueryHookReturnType<any> => {
  return useGetAll<any>({
    apiConfig: { url, method: "GET" },
    options: {
      enabled: !!url,
    },
    isReqWithoutHeader: true,
    parser: (data): void => {
      return data?.data;
    },
  });
};
