import {
  TableNameRendrer,
  TermNameRendrer,
  ColumnNameRendrer,
  TableRendrer,
  EntityRendrer,
  StatusRenderer,
  DateTimeRenderer,
  TagsRenderer,
  RefreshStatusRenderer,
  NumberRenderer,
  DataTypeRenderer,
  MatchInfoRendrer,
  ConfidenceScoreRendrer,
  DescriptionRenderer,
  DQScoreRenderer,
  AlertTypeRenderer,
  IsCDEFieldRendrer,
  PopularityScoreRenderer,
  WorkflowStatusRenderer,
  AssignedToAndAttributesRenderer,
  ProfilingInfoRendrer,
  RuleIdRendrer,
  RuleAlertStatusRendrer,
  RuleRunStatusRendrer,
  RuleTrendRenderer,
  RuleWorkflowStatusRenderer,
  RunResultRenderer,
  JobIdRenderer,
  JobExecutionIdRenderer,
  JobStatusRenderer,
  JobExecutionStatusRenderer,
  AssignedToRenderer,
  ChatNameRenderer,
  QuestionRenderer,
  FeedbackRenderer,
  AnalyticsStatusRenderer,
  LinkedColumnRendrer,
  RangeValueRenderer,
  AssetNameRendrer,
  ValueRendererWithTooltip,
} from "./listingpage.renderer"; // Assuming all renderers are imported from a renderers file
import {
  CellRenderersType,
  FrameworkComponentsType,
  RendererWithParamsType,
} from "./listingpage.types";

export const getFrameworkComponents = (
  entityBreadcrumbs?: any,
  isOutsideListingVews?: boolean
): FrameworkComponentsType => {
  return {
    TableNameRendrer,
    TermNameRendrer,

    ColumnNameRendrer: (props: RendererWithParamsType): JSX.Element =>
      ColumnNameRendrer({
        ...props,
        isOutsideListingViews: isOutsideListingVews,
      }),
    AssetNameRendrer,
    TableRendrer,
    EntityRendrer: (props: RendererWithParamsType): JSX.Element =>
      EntityRendrer({
        ...props,
        breadcrumbs: entityBreadcrumbs,
        isOutsideListingViews: isOutsideListingVews,
      }),
    StatusRenderer,
    DateTimeRenderer,
    TagsRenderer,
    RefreshStatusRenderer,
    NumberRenderer,
    DataTypeRenderer,
    MatchInfoRendrer: (props: RendererWithParamsType): JSX.Element =>
      MatchInfoRendrer({
        ...props,
        breadcrumbs: entityBreadcrumbs,
      }),
    ConfidenceScoreRendrer,
    DescriptionRenderer,
    DQScoreRenderer,
    AlertTypeRenderer,
    IsCDEFieldRendrer,
    PopularityScoreRenderer,
    WorkflowStatusRenderer,
    AssignedToAndAttributesRenderer,
    ProfilingInfoRendrer,

    RuleIdRendrer: (props: RendererWithParamsType): JSX.Element =>
      RuleIdRendrer({
        ...props,
        isOutsideListingViews: isOutsideListingVews,
      }),

    RuleAlertStatusRendrer,
    RuleRunStatusRendrer,
    RuleTrendRenderer,
    RuleWorkflowStatusRenderer,
    RunResultRenderer,
    JobIdRenderer,
    JobExecutionIdRenderer,
    JobStatusRenderer,
    JobExecutionStatusRenderer,
    AssignedToRenderer,
    ChatNameRenderer,
    QuestionRenderer,
    FeedbackRenderer,
    AnalyticsStatusRenderer,
    LinkedColumnRendrer,
    RangeValueRenderer,
    ValueRendererWithTooltip,
  };
};

export const getCellRenderers = (options: {
  isRulePage?: boolean;
  type?: string;
  isFeedbackAnalyticsPage?: boolean;
  isJobExecutionsPage?: boolean;
  isTablePage?: boolean;
  isColumnPage?: boolean;
  parsedCurrentNode?: any;
  onSuccessMatchStatus?: Function;
  onExecuteRequestMatchStatus?: Function;
  updateFeedbackStatusConfig?: any;
  getDataApiCommonProps?: any;
}): CellRenderersType => {
  const {
    isRulePage,
    type,
    isFeedbackAnalyticsPage,
    isJobExecutionsPage,
    parsedCurrentNode,
    onSuccessMatchStatus,
    onExecuteRequestMatchStatus,
    updateFeedbackStatusConfig,
    getDataApiCommonProps,
  } = options;

  return {
    short_name: { render: "TermNameRendrer" },
    table_name: {
      render: isRulePage ? "TableRendrer" : "TableNameRendrer",
    },
    column_name: { render: "ColumnNameRendrer" },
    rule_id: { render: "RuleIdRendrer" },
    job_id: { render: "JobIdRenderer" },
    execution_id: { render: "JobExecutionIdRenderer" },
    alert_status: { render: "RuleAlertStatusRendrer" },
    run_status: { render: "RuleRunStatusRendrer" },
    trend: { render: "RuleTrendRenderer" },
    name: { render: "AssetNameRendrer" },
    is_cde: { render: "IsCDEFieldRendrer" },
    full_name: {
      render: type === "DSR" ? "AssetNameRendrer" : "TableNameRendrer",
    },
    status: {
      render: isFeedbackAnalyticsPage
        ? "AnalyticsStatusRenderer"
        : isJobExecutionsPage
        ? "JobExecutionStatusRenderer"
        : "StatusRenderer",
      params: { nodeType: type },
    },

    tags: { render: "TagsRenderer" },
    refresh_status: { render: "RefreshStatusRenderer" },
    scan_status: { render: "RefreshStatusRenderer" },
    record_count: { render: "NumberRenderer" },
    count_distinct: { render: "NumberRenderer" },
    count_blanks: { render: "NumberRenderer" },
    length: { render: "NumberRenderer" },
    run_result: { render: "RunResultRenderer" },
    data_type: { render: "DataTypeRenderer" },
    workflow_status: {
      render: isRulePage
        ? "RuleWorkflowStatusRenderer"
        : "WorkflowStatusRenderer",
    },

    assigned_to: { render: "AssignedToAndAttributesRenderer" },
    attributes: { render: "AssignedToAndAttributesRenderer" },
    // last_execution: { render: "DateTimeRenderer" },
    // next_execution: { render: "DateTimeRenderer" },
    // start_time: { render: "DateTimeRenderer" },
    // end_time: { render: "DateTimeRenderer" },
    "no._of_recipients": { render: "NumberRenderer" },
    object_count: { render: "NumberRenderer" },
    linked_columns: { render: "LinkedColumnRendrer" },

    entity: {
      render: "EntityRendrer",
      params: {
        onSuccess: onSuccessMatchStatus,
        nodeType: type,
        nodeId: `${parsedCurrentNode?.node_id}`,
      },
    },
    table: { render: "TableRendrer" },
    match_status: {
      render: "MatchInfoRendrer",
      params: {
        onSuccess: onSuccessMatchStatus,
        onExecute: onExecuteRequestMatchStatus,
        nodeType: type,
        nodeId: `${parsedCurrentNode?.node_id}`,
      },
    },
    confidence_score: { render: "ConfidenceScoreRendrer" },
    description: { render: "DescriptionRenderer" },
    rule_desc: { render: "DescriptionRenderer" },
    dq_score: { render: "DQScoreRenderer" },
    alert_type: { render: "AlertTypeRenderer" },
    popularity: { render: "PopularityScoreRenderer" },
    profiling_info: { render: "ProfilingInfoRendrer" },
    chat_name: {
      render: "ChatNameRenderer",
      params: { isFeedbackAnalyticsPage },
    },
    execution_status: { render: "JobStatusRenderer" },
    range_value: {
      render: "RangeValueRenderer",
    },
    total_questions: { render: "NumberRenderer" },
    approved_feedbacks: { render: "NumberRenderer" },
    rejected_feedbacks: { render: "NumberRenderer" },
    feedbacks_count: { render: "NumberRenderer" },
    // latest_chat_time: { render: "DateTimeRenderer" },
    // created_on: { render: "DateTimeRenderer" },
    question: {
      render: "QuestionRenderer",
      params: {
        updateFeedbackStatusConfig,
        getDataApiCommonProps,
      },
    },
    feedback: { render: "FeedbackRenderer" },
  };
};
