import styled from "styled-components";

export const BusinessRuleEditViewStyled = styled.div`
  .collapse-header {
    width: 100%;
    height: 49px;
    padding: 15px 22px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    .collapse-header-title {
      color: ${({ theme }): string => theme.textColors.DARK_BLUE};
      font-family: OpenSansSemiBold;
      font-size: 15px;
      flex: 1;
    }
  }

  .collapse-container {
    margin-bottom: 16px;
  }

  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-content {
        .ant-collapse-content-box {
          .collapse-rendrer-content {
            padding: 0px 22px 25px 22px;
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
`;
