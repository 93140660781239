import styled from "styled-components";
import { ellipsis } from "../../../styles/app.styles";

export const TransferCompStyled = styled.div`
  display: flex;
  width: 100%;

  .arrow {
    margin-right: 5px;
    margin-left: 5px;
    color: ${({ theme }): string => theme.textColors.GREY_34};
  }
`;

export const RightTitleWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;

  .selection-count {
    margin-left: 4px;
    color: ${({ theme }): string => theme.textColors.GREY_18};
  }
`;

export const NodeNameStyled = styled.div<{ maxWidth?: string }>`
  display: flex;
  align-items: center;
  column-gap: 5px;
  max-width: ${({ maxWidth }): string => `${maxWidth}%`};

  .node-icon {
    height: 14px;
  }

  .node-text {
    min-width: 3ch;
    overflow: hidden;
    ${ellipsis}
  }
`;
