import "react-quill/dist/quill.snow.css";
import { AssetGridWrapperProps } from "./assetcomparechanges.types";

import {
  AssetGridWrapperStyled,
  AssetPathAndCommentStyled,
  AssetPathWrapperStyled,
  AssetTabularContentStyled,
  AssetTabularGridStyled,
  AssetTabularHeaderStyled,
  FieldHeadingStyled,
  FieldStyled,
  GridContentRowStyled,
} from "./assetcomparechanges.styles";

import LinkButton from "../linkbutton";
import { jsonParse, parseStringToHtml } from "../../utils";

import {
  AssetPath,
  RelationShipAdditionalInfoType,
  RelationshipItemType,
  getParsedNodePath,
} from "../../parsers/asssetoverviewparser";

import { AdaptiveAssetHierarchy } from "../adaptiveassetheirachy";

import { NodeSubType, NodeType } from "../../app.types";

const SIMPLE_TAG_CLASS_NAME = "review-changes-tag";

export const AssetPathList = ({
  data,
}: {
  data: RelationshipItemType[];
}): JSX.Element => {
  return (
    <AssetPathWrapperStyled>
      {data?.map((item) => {
        const {
          TGT_NODE_PATH: tgtNodePath = "",
          TGT_NODE_TITLE: tgtNodeTitle = "",
          TGT_NODE_TYPE_ID: tgtNodeType = "",
          SRC_NODE_ID: srcNodeId = "",
          SRC_NODE_TYPE_ID: srcNodeType = "",
          TGT_NODE_SUB_TYPE: tgtNodeSubType = "",
          REL_TYPE_ID: relType,
          IS_WF_ENABLED: isWfEnabled,
          COMMENTS: comment,
          TGT_NODE_ID: tgtNodeId = "",
          ADDITIONAL_INFO: additionalInfo,
        } = item || {};

        const parsedAdditionalInfo: RelationShipAdditionalInfoType = jsonParse(
          additionalInfo
        );

        const { entity_selection_status: entityStatus } =
          parsedAdditionalInfo || {};

        const rowId = `${tgtNodeId}-${srcNodeId}-${relType}`;

        const nodePathWithOutCurrentAsset = getParsedNodePath(tgtNodePath);

        const isApproved = entityStatus === "APR";

        const isDmnOrSubDmn =
          tgtNodeSubType === "DMN" || tgtNodeSubType === "CAT";

        const tgtNode = {
          level: 1,
          node_id: String(tgtNodeId),
          node_name: tgtNodeTitle,
          node_type: tgtNodeType as NodeType,
          node_sub_type: tgtNodeSubType as NodeSubType,
          is_approved: isApproved,
          is_workflow_enabled: isDmnOrSubDmn ? isWfEnabled : false,
        } as AssetPath[number];

        const nodePathWithCurrentAsset = [
          ...nodePathWithOutCurrentAsset,
          tgtNode,
        ];

        const parsedNodePath = tgtNodePath
          ? nodePathWithCurrentAsset
          : [tgtNode];

        return (
          <AssetPathAndCommentStyled key={`asset-path-list-${rowId}`}>
            <AdaptiveAssetHierarchy nodes={parsedNodePath} mode="primary" />
            {comment && <div className="comment">{comment}</div>}
          </AssetPathAndCommentStyled>
        );
      })}
    </AssetPathWrapperStyled>
  );
};

export const AssetGridWrapper = (props: AssetGridWrapperProps): JSX.Element => {
  const { title, children, actionIcon, onActionItemClick } = props;

  return (
    <AssetGridWrapperStyled>
      <div className="grid-wrapper-header">
        <p className="tab-heading">{title} </p>
        {actionIcon && (
          <LinkButton
            icon={actionIcon}
            onClick={onActionItemClick}
            className="grid-wrapper-action"
          />
        )}
      </div>

      <AssetTabularGridStyled>
        <AssetTabularHeaderStyled>
          <FieldHeadingStyled>Fields</FieldHeadingStyled>
          <FieldHeadingStyled>Previous Content</FieldHeadingStyled>
          <FieldHeadingStyled>Updated Content</FieldHeadingStyled>
        </AssetTabularHeaderStyled>
        <AssetTabularContentStyled>{children}</AssetTabularContentStyled>
      </AssetTabularGridStyled>
    </AssetGridWrapperStyled>
  );
};

export const RenderRuleOfTypeList = ({
  isChanged = false,
  newVal = [],
  oldVal = [],
  title = "",
}: {
  isChanged: boolean;
  oldVal: { desc: string; value: string }[];
  newVal: { desc: string; value: string }[];
  title: string;
}): JSX.Element => {
  return (
    <GridContentRowStyled className="grid-content-row" isChanged={isChanged}>
      <FieldStyled>{title}</FieldStyled>
      <FieldStyled>
        {oldVal?.map((item, index) => {
          const title = `${item?.value}`;
          const desc = item?.desc && ` (${item?.desc})`;

          return (
            <span
              key={`${title}-${item?.value}-${item?.desc}-${index}`}
              className={SIMPLE_TAG_CLASS_NAME}
              title={title}
            >
              <b>{title}</b>
              {desc && desc}
            </span>
          );
        })}
      </FieldStyled>
      <FieldStyled>
        {newVal?.map((item, index) => {
          const title = `${item?.value}`;
          const desc = item?.desc && ` (${item?.desc})`;

          return (
            <span
              key={`${title}-${item?.value}-${item?.desc}-${index}`}
              className={SIMPLE_TAG_CLASS_NAME}
              title={title}
            >
              <b>{title}</b>
              {desc && desc}
            </span>
          );
        })}
      </FieldStyled>
    </GridContentRowStyled>
  );
};

const RenderLinks = ({ links }: { links: string[] }): JSX.Element => {
  return (
    <FieldStyled>
      <ul>
        {links?.map((val, index) => (
          <li key={`additional-info-links-${val}-${index}`}>
            <a
              className="add-info-list-item-link"
              target="_blank"
              href={val}
              rel="noreferrer"
              title={val}
            >
              {val}
            </a>
          </li>
        ))}
      </ul>
    </FieldStyled>
  );
};

const RenderTexts = ({ texts }: { texts: string[] }): JSX.Element => {
  return (
    <FieldStyled>
      <ul>
        {texts?.map((val, index) => (
          <li key={`${val}-${index}`}>
            <div className="add-info-list-item">
              <div className="ql-editor">{parseStringToHtml(val)}</div>
            </div>
          </li>
        ))}
      </ul>
    </FieldStyled>
  );
};

export const AdditionalInfoRendere = ({
  isChanged,
  newValues = [],
  oldValues = [],
  title = "",
  isLink,
}: {
  title: string;
  oldValues: string[];
  newValues: string[];
  isChanged: boolean;
  isLink?: boolean;
}): JSX.Element => {
  return (
    <GridContentRowStyled className="grid-content-row" isChanged={isChanged}>
      <>
        <FieldStyled>{title}</FieldStyled>

        {isLink ? (
          <RenderLinks
            data-testid="review-changes-additional-info-old-val"
            links={oldValues}
          />
        ) : (
          <RenderTexts
            data-testid="review-changes-additional-info-old-val"
            texts={oldValues}
          />
        )}

        {isLink ? (
          <RenderLinks
            data-testid="review-changes-additional-info-new-val"
            links={newValues}
          />
        ) : (
          <RenderTexts
            data-testid="review-changes-additional-info-new-val"
            texts={newValues}
          />
        )}
      </>
    </GridContentRowStyled>
  );
};

export const RenderRelationShipList = ({
  isChanged = false,
  newVal = [],
  oldVal = [],
  assetName = "",
}: {
  isChanged: boolean;
  oldVal: RelationshipItemType[];
  newVal: RelationshipItemType[];
  assetName: string;
}): JSX.Element => {
  const {
    TGT_REL_TYPE: tgtRelType = "",
    TGT_NODE_TYPE: tgtNodeType = "",
    SRC_REL_TYPE: srcRelType = "",
  } = newVal?.[0] || {};

  const relTypeText = tgtRelType || srcRelType;

  const title = `${assetName} ${relTypeText} ${tgtNodeType}`;

  return (
    <GridContentRowStyled className="grid-content-row" isChanged={isChanged}>
      <FieldStyled title={title}>
        <div className="field-name">{title}</div>
      </FieldStyled>
      <FieldStyled>
        <AssetPathWrapperStyled>
          <AssetPathList data={oldVal} />
        </AssetPathWrapperStyled>
      </FieldStyled>
      <FieldStyled>
        <AssetPathList data={newVal} />
      </FieldStyled>
    </GridContentRowStyled>
  );
};
