import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";

export const TextReadOnlyFieldRendrerStyled = styled.div`
  width: 100%;
  ${ellipsis}
`;

export const PickListReadOnlyFieldRendrerStyled = styled.div`
  .tags-list {
    padding: 0;
  }
`;
