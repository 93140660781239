import { API_CONFIG } from "../../constants/apiconfig";
import { useGetAll } from "../baseservice";

import {
  getParsedGlossaryDomains,
  GlossaryDomainsParserReturnType,
} from "../../parsers";

import { CustomReactQueryHookReturnType } from "../services.types";
import {
  DomainSubdomainListReturnType,
  GlossaryDomainsForSelectParserReturnType,
} from "../../parsers/glossarydomains/glossarydomainsparser.types";
import {
  getDomainSubdomainListOfLoggedUser,
  getParsedGlossaryDomainsForSelect,
} from "../../parsers/glossarydomains/glossarydomainsparser";
import { getFiltersfromLocalStorage } from "../../utils";

const {
  get_all_glossary_domains: getAllGlossaryDomains,
  get_loggedin_user_domain_and_subdomain: getLoggedinUserDomainAndSubdomainList,
} = API_CONFIG;

export const useGetGlossaryDomains = (): CustomReactQueryHookReturnType<
  GlossaryDomainsParserReturnType[]
> => {
  return useGetAll({
    apiConfig: getAllGlossaryDomains,
    parser: getParsedGlossaryDomains,
  });
};

export const useGetGlossaryDomainsForSelect = (
  enabled?: boolean
): CustomReactQueryHookReturnType<
  GlossaryDomainsForSelectParserReturnType[]
> => {
  return useGetAll({
    apiConfig: getAllGlossaryDomains,
    parser: getParsedGlossaryDomainsForSelect,
    options: { enabled: enabled ?? true },
  });
};

export const useGetLoggedInUserDomainAndSubdomain = (
  enabled?: boolean
): CustomReactQueryHookReturnType<DomainSubdomainListReturnType[]> => {
  const parsedNodeFilters = getFiltersfromLocalStorage("TRM");
  return useGetAll({
    apiConfig: getLoggedinUserDomainAndSubdomainList,
    params: [parsedNodeFilters],
    parser: getDomainSubdomainListOfLoggedUser,
    options: { enabled: enabled ?? true },
  });
};
