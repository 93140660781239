import { useCallback, useEffect, useMemo, useState } from "react";

import {
  FilterSelectionPanelStyled,
  OptionItemStyled,
  OptionLabelStyled,
  OptionsContainerStyled,
} from "./filterselectionpanel.styles";

import SearchField from "../searchfield";

import {
  FilterOption,
  FilterSelectionPanelProps,
} from "./filterselectionpanel.types";

import {
  SearchFieldContextProvider,
  useSearchFieldContext,
} from "../../contexts";
import Checkbox from "../checkbox";
import SectionBlankSlateText from "../sectionblankslatetext";
import Flex from "../flex";

const FilterSelectionPanelContent: React.FC<FilterSelectionPanelProps> = ({
  options,
  onChange,
}) => {
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>(options);
  const [selectAll, setSelectAll] = useState(false);

  const { searchText: searchTerm } = useSearchFieldContext();
  // Handle search filtering
  useEffect(() => {
    if (searchTerm) {
      const filtered = options?.map((option) => ({
        ...option,
        visible: option?.label
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase()),
      }));
      setFilterOptions(filtered as FilterOption[]);
    } else {
      setFilterOptions(options);
    }
  }, [searchTerm, options]);

  // Handle "Select All" checkbox state
  useEffect(() => {
    setSelectAll(filterOptions?.every((option) => option?.selected));
  }, [filterOptions]);

  const handleSelectAll = useCallback(() => {
    const newSelectAll = !selectAll;
    const updatedOptions = filterOptions?.map((option) => ({
      ...option,
      selected: newSelectAll,
    }));
    setFilterOptions(updatedOptions);
    onChange(updatedOptions);
  }, [selectAll, filterOptions, onChange]);

  const handleOptionChange = useCallback(
    (optionId: string) => (): void => {
      const updatedOptions = filterOptions?.map((option) =>
        option?.id === optionId
          ? { ...option, selected: !option?.selected }
          : option
      );
      setFilterOptions(updatedOptions);
      onChange(updatedOptions);
    },
    [filterOptions, onChange]
  );

  const isAnyItemSelected = useMemo(
    () => filterOptions?.some((option) => option?.selected),
    [filterOptions]
  );

  const visibleOptions = useMemo(
    () => filterOptions?.filter((option) => option?.visible ?? true),
    [filterOptions]
  );

  return (
    <FilterSelectionPanelStyled>
      <SearchField />
      <OptionsContainerStyled>
        {!!visibleOptions?.length && (
          <OptionItemStyled>
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAll}
              indeterminate={!selectAll && isAnyItemSelected}
            />
            <OptionLabelStyled>Select All</OptionLabelStyled>
          </OptionItemStyled>
        )}

        {visibleOptions?.map((option) => (
          <OptionItemStyled key={`select-option-${option?.id}`}>
            <Checkbox
              id={option?.id}
              checked={option?.selected}
              onChange={handleOptionChange(option?.id)}
            />

            <OptionLabelStyled>
              <Flex columnGap={7}>
                {option?.icon}
                {option?.label}
              </Flex>
            </OptionLabelStyled>
          </OptionItemStyled>
        ))}

        {!visibleOptions?.length && (
          <SectionBlankSlateText
            isEmpty
            text="Your search did not match any option"
          >
            <div />
          </SectionBlankSlateText>
        )}
      </OptionsContainerStyled>
    </FilterSelectionPanelStyled>
  );
};

const FilterSelectionPanel: React.FC<FilterSelectionPanelProps> = (props) => {
  return (
    <SearchFieldContextProvider>
      <FilterSelectionPanelContent {...props} />
    </SearchFieldContextProvider>
  );
};
export default FilterSelectionPanel;
