import { useHistory } from "react-router";
// <---  Library Imports Start --->
import { Tooltip } from "antd";
import { useCallback, useMemo } from "react";
// <---  Library Imports End --->

// <---  Components Start --->
import { Button } from "..";
import ListItemsView from "../listitemsview";

import Flex from "../flex";
// <---  Components End --->

// <---  Constants Start --->
import {
  CERTIFY_ELEM_ID_MAPPPING,
  DVSUM_TOOLTIP_CLASS_NAME,
  LOCAL_STORAGE_CONSTANTS,
  CREATE_SUGGESTION_BTN,
  DONE_ELEM_ID_MAPPPING,
  EDIT_ELEM_ID_MAPPPING,
  SUBMIT_APPROVAL_ELEM_ID_MAPPPING,
  VIEW_SUGGESTIONS_BTN,
  APP_ROUTES,
  ELEMENT_IDS,
} from "../../constants";

// <---  Constants End --->

// <---  Custom Hooks Start --->
import { useOpenDrawer, useOpenModal } from "../../customhooks";
// <---  Custom Hooks End --->

// <---  Styles Start --->
import {
  DeleteButtonStyled,
  DetailPagesOverviewRightSecStyled,
} from "./detailpagesoverviewrightsec.styles";
// <---  Styles End --->

// <---  Svgs Start --->
import {
  deleteIcon,
  editPencilIcon,
  eyeIcon,
  filledInfoIconCircle,
  lightCrossIcon,
  suggestionIcon,
  tickIcon,
} from "../../svgs";
// <--- Svgs End --->

// <---  Types Start --->
import { ButtonCustomProps } from "../button/button.interfaces";
import { DetailPagesOverviewRightSecProps } from "./detailpagesoverviewrightsec.types";
// <---  Types End --->

import LinkButton from "../linkbutton/linkbutton";
import { VerticalDividerStyled } from "../dividers/dividers.styles";

import { CommentHistoryDrawerTypes } from "../../drawerviews/activityhistorydrawer/activityhistorydrawer.types";
import { DeleteRecordsModalProps } from "../../pages/listingpage/listingpage.components/deleterecords/deleterecords.types";

import { NodeType } from "../../app.types";
import { AssetUsabilityScoreRender } from "../assetoverview/assetoverview.renderer";
import { getPageNode } from "../../utils";

const btnProps: ButtonCustomProps = {
  id: "primary",
  fontSize: "15px",
};

const unSavedMessages =
  "You've unsaved changes please saved these changes or discard";
const noChangedMessageForDraft = "You haven't change anything to save as draft";
const noChangedMessageForPublish = "You haven't change anything to publish";
const noChangedMessageForDiscard = "You haven't change anything to discard";

const { asset_overview_edit_btn: ASSET_OVERVIEW_EDIT_BTN } = ELEMENT_IDS;

function DetailPagesOverviewRightSec(
  props: DetailPagesOverviewRightSecProps
): JSX.Element {
  const {
    isEdited,
    isSaved,
    isEditable,
    onEditClick,
    onClickDelete,
    onDiscardChanges,
    suggestions,
    onSuggestionClick,
    isViewer,
    nodeId,
    nodeType = "TBL",
    onApply,
    isLoading,
    isAnyUnsavedChanges,
    onClickDone,
    isClickOnDone,
    nodeDesc,
    nodeTitle,
    onClickCertify,
    usabilityScore,
    nodeSubType,
    onToggleReviewChangesPanel,
    showReviewChangesPanel,
    workflow,
    handlers = {},
    isWorkflowEnabled = false,
    isReportPage = false,
    isDepricated = false,
    hasEditAccess = true,
  } = props;

  const {
    onSubmitForApproval,
    onCancelSubmission,
    onNodeCommentOrRejectRequest,
    onApproveNodeRequest,
    setNodeSubmittedForApproval,
  } = handlers;

  const history = useHistory();

  const workflowSteps = workflow?.workflowSteps || [];

  const onOpenModal = useOpenModal();
  const openDrawer = useOpenDrawer();

  const onDiscardClick = useCallback(
    (isLocalDiscard?: boolean) => {
      onOpenModal({
        modalId: "discard_table_detail_changes",
        modalTitle: "Discard",
        visible: true,
        modalProps: {
          onDiscard: (): void => {
            onDiscardChanges();
          },
          isSaved: isLocalDiscard ? false : isSaved,
          nodeId,
          nodeType,
        },
      });
    },
    [onDiscardChanges, isSaved]
  );

  const oSuggest = useCallback(() => {
    onOpenModal({
      modalId: "suggest_an_edit_form",
      visible: true,
      modalProps: { nodeType, nodeId, nodeDesc, nodeTitle, nodeSubType },
    });
  }, [nodeId, nodeType, nodeSubType, nodeDesc, nodeTitle]);

  const loggedInUserEmail =
    localStorage?.getItem(LOCAL_STORAGE_CONSTANTS?.email) || "";

  const activeStep = useMemo(
    () => workflowSteps?.find((item) => item?.stepStatus === "INP"),
    [workflowSteps]
  );

  const activeStepUsers = useMemo(() => {
    const inProgressStep = workflowSteps?.find(
      (item) => item?.stepStatus === "INP"
    );

    return inProgressStep?.stepUsers || [];
  }, [workflowSteps]);

  const isUserAssignedWithCurrentWorkflowStep = useMemo(() => {
    const isUserExistsInStepUsers = activeStepUsers?.find(
      (item) =>
        item?.userEmail?.toLocaleLowerCase() ===
        loggedInUserEmail?.toLocaleLowerCase()
    );
    return !!isUserExistsInStepUsers;
  }, [activeStepUsers, loggedInUserEmail]);

  const hasUserPerfomedActiveStep = useMemo(() => {
    return activeStepUsers?.find(
      (item) =>
        item?.userEmail?.toLocaleLowerCase() ===
          loggedInUserEmail?.toLocaleLowerCase() &&
        (item?.userStatus === "CLS" || item?.userStatus === "REJ")
    );
  }, [activeStepUsers, loggedInUserEmail]);

  const isCreateStepWaitingForSubmission = useMemo(
    () => activeStep?.stepType === "CED" && activeStep?.stepEvent === "SUB",
    [activeStep]
  );

  const onOpenRef = useCallback(() => {
    openDrawer({
      drawerId: "comment_history_ref",
      visible: true,
      drawerProps: {
        nodeType,
        nodeId,
        isReportPage,
        activityRequest: onNodeCommentOrRejectRequest,
        actionType: "REJ",
        zIndex: 10001,
        isStartFromTop: true,
      } as CommentHistoryDrawerTypes,
    });
  }, [nodeId]);

  const onSuccessDeleteRecord = useCallback(() => {
    const pageUrl =
      nodeType === "TBL"
        ? APP_ROUTES?.private_routes?.table_dictionary
        : nodeType === "COL"
        ? APP_ROUTES?.private_routes?.field_dictionary
        : nodeType === "DSR"
        ? APP_ROUTES?.private_routes?.analytics_dictionary
        : nodeType === "RLS"
        ? APP_ROUTES?.private_routes?.rules_dictionary
        : nodeType === "RFD"
        ? APP_ROUTES?.private_routes?.reference_dictionary
        : APP_ROUTES?.private_routes?.assets;
    history.push(pageUrl);
  }, []);

  const onDeleteAsset = useCallback(() => {
    const ids = [Number(nodeId)];
    onOpenModal({
      modalId: "delete_records",
      visible: true,
      modalTitle: `Delete Asset`,
      modalProps: {
        nodeType: nodeType as NodeType,
        selectedNodesIds: ids,
        selectedRowsCount: 1,
        searchId: "",
        onDeleteRecords: onSuccessDeleteRecord,
      } as DeleteRecordsModalProps,
    });
  }, [nodeId]);

  //Connected assets means that comes through scan like tables, columns, datasets, etc.
  const isConnectedAsset = ([
    "TBL",
    "COL",
    "DSF",
    "DSR",
    "DTB",
    "SCM",
    "WSP",
    "PLO",
    "POF",
  ] as NodeType[]).includes(nodeType);

  const showDeleteButton = isConnectedAsset ? isDepricated : true;

  const { isTablePage } = getPageNode(nodeType);
  const showCertifyBtn = isTablePage;

  return (
    <DetailPagesOverviewRightSecStyled>
      {activeStep?.stepType === "APR" &&
      !isUserAssignedWithCurrentWorkflowStep ? (
        <div className="btn-text-group">
          <span className="awaiting-approval-text">
            Awaiting approval from
            <b>
              <Tooltip
                title={
                  activeStepUsers?.length
                    ? activeStepUsers?.map(
                        (item, index) =>
                          `${item?.userName}${
                            index < activeStepUsers?.length - 1 ? "," : ""
                          } `
                      )
                    : undefined
                }
                overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME}  `}
              >
                {` ${activeStepUsers?.length} ` || 0}
              </Tooltip>
            </b>
            approver{activeStepUsers?.length > 1 ? "s" : ""}
          </span>
          <Button
            {...btnProps}
            className="cancel-submission"
            onClick={onCancelSubmission}
          >
            Cancel Submission
          </Button>
        </div>
      ) : activeStep?.stepType === "APR" &&
        isUserAssignedWithCurrentWorkflowStep ? (
        <div className="governed-btn-group">
          <LinkButton
            onClick={onToggleReviewChangesPanel}
            className={`${
              showReviewChangesPanel ? "selected" : ""
            } review-changes`}
          >
            <span className="review-changes-icon">{eyeIcon()}</span>
            <span>Compare Changes</span>
          </LinkButton>

          {!hasUserPerfomedActiveStep && (
            <>
              <VerticalDividerStyled height="14px" />
              <LinkButton
                className="reject-text"
                onClick={(): void => {
                  onOpenRef();
                }}
              >
                {lightCrossIcon()}Reject
              </LinkButton>

              <Button
                {...btnProps}
                icon={tickIcon()}
                className="approve-button"
                onClick={onApproveNodeRequest}
              >
                Approve
              </Button>
            </>
          )}
        </div>
      ) : isViewer ? (
        <Button
          {...btnProps}
          icon={editPencilIcon("16", "16")}
          className="edit-icon"
          onClick={oSuggest}
          elementId={CREATE_SUGGESTION_BTN?.[nodeType]}
        >
          Suggest an Edit
        </Button>
      ) : (
        <>
          {isClickOnDone && isEditable && (
            <div className="btn-group">
              <Button
                {...btnProps}
                onClick={(): void => onDiscardClick()}
                disabled={isLoading || (isSaved ? false : !isEdited)}
                tooltipProps={{
                  title: (isSaved ? false : !isEdited)
                    ? noChangedMessageForDiscard
                    : "",
                  placement: "topRight",
                }}
              >
                Discard
              </Button>

              <Button
                {...btnProps}
                onClick={(): void => onApply("DFT")}
                disabled={isLoading || isAnyUnsavedChanges || !isEdited}
                tooltipProps={{
                  title: isAnyUnsavedChanges
                    ? unSavedMessages
                    : !isEdited
                    ? noChangedMessageForDraft
                    : "",
                  placement: "topRight",
                }}
              >
                Save Draft
              </Button>

              {isWorkflowEnabled ? (
                <Button
                  {...btnProps}
                  onClick={(): void => {
                    setNodeSubmittedForApproval?.();
                    onApply("DFT");
                  }}
                  disabled={
                    isLoading ||
                    isAnyUnsavedChanges ||
                    (isSaved ? false : !isEdited)
                  }
                  tooltipProps={{
                    title: isAnyUnsavedChanges
                      ? unSavedMessages
                      : (isSaved ? false : !isEdited)
                      ? noChangedMessageForPublish
                      : "",
                    placement: "topRight",
                  }}
                  width="78px"
                  elementId={SUBMIT_APPROVAL_ELEM_ID_MAPPPING?.[nodeType]}
                >
                  Submit For Approval
                </Button>
              ) : (
                <Button
                  {...btnProps}
                  onClick={(): void => onApply("PUB")}
                  disabled={
                    isLoading ||
                    isAnyUnsavedChanges ||
                    (isSaved ? false : !isEdited)
                  }
                  tooltipProps={{
                    title: isAnyUnsavedChanges
                      ? unSavedMessages
                      : (isSaved ? false : !isEdited)
                      ? noChangedMessageForPublish
                      : "",
                    placement: "topRight",
                  }}
                  width="78px"
                >
                  Publish
                </Button>
              )}
            </div>
          )}
          {(isEdited || isSaved) && !isClickOnDone ? (
            <div className="saved-info-hint">
              {isEdited && (
                <>
                  Edited {filledInfoIconCircle()} <div className="sep-line" />
                </>
              )}
              {isSaved && !isEditable && isCreateStepWaitingForSubmission && (
                <>
                  Edited and Saved {filledInfoIconCircle()}{" "}
                  <div className="sep-line" />
                </>
              )}
            </div>
          ) : (
            <div />
          )}
          {usabilityScore && !isEditable && (
            <Flex columnGap={7}>
              <span>Usability Score</span>
              {AssetUsabilityScoreRender({
                usabilityScore,
                nodeSubType,
                nodeType,
                onEditOverview: onEditClick,
                isEditor: !isViewer && hasEditAccess,
              })}
            </Flex>
          )}
          {!!suggestions?.length && !isClickOnDone && (
            <>
              {!isEditable && <div className="sep-line" />}
              <ListItemsView
                data={{ "SUGGESTIONS:": suggestions }}
                sectionTitle=""
                selected="Term 1"
                isShowIcon
                onClick={onSuggestionClick}
              >
                <div
                  className="suggestion-count"
                  id={VIEW_SUGGESTIONS_BTN?.[nodeType]}
                >
                  {suggestionIcon()} {suggestions?.length}
                </div>
              </ListItemsView>
              {isEditable && <div className="sep-line" />}
            </>
          )}
          {isEditable ? (
            <>
              <Button
                {...btnProps}
                onClick={(): void => {
                  isClickOnDone && !isEdited
                    ? onDiscardChanges()
                    : isClickOnDone && isEdited
                    ? onDiscardClick(true)
                    : onClickDone();
                }}
                id={isClickOnDone ? "cancel" : "primary"}
                disabled={isLoading}
                elementId={DONE_ELEM_ID_MAPPPING?.[nodeType]}
              >
                {isClickOnDone ? "Cancel" : "Done"}
              </Button>
              {showCertifyBtn && !isClickOnDone && (
                <Button
                  onClick={(): void => onClickCertify?.(`${Math.random()}`)}
                  marginLeft="5px"
                  {...btnProps}
                  elementId={CERTIFY_ELEM_ID_MAPPPING?.[nodeType]}
                >
                  Certify
                </Button>
              )}
            </>
          ) : (
            <>
              {showDeleteButton && hasEditAccess && (
                <>
                  <div className="sep-line" />
                  <DeleteButtonStyled
                    id="danger"
                    onClick={nodeType === "TRM" ? onClickDelete : onDeleteAsset}
                  >
                    {deleteIcon} Delete
                  </DeleteButtonStyled>
                </>
              )}
              {hasEditAccess && (
                <>
                  <div className="sep-line" />
                  <Button
                    {...btnProps}
                    icon={editPencilIcon("16", "16")}
                    className="edit-icon"
                    onClick={onEditClick}
                    elementId={ASSET_OVERVIEW_EDIT_BTN}
                  >
                    Edit
                  </Button>
                </>
              )}

              {!!workflowSteps?.length &&
                isWorkflowEnabled &&
                isCreateStepWaitingForSubmission &&
                hasEditAccess && (
                  <>
                    <div className="sep-line" />
                    <Button
                      {...btnProps}
                      className="submit-for-approval-button"
                      onClick={onSubmitForApproval}
                      elementId={SUBMIT_APPROVAL_ELEM_ID_MAPPPING?.[nodeType]}
                    >
                      Submit For Approval
                    </Button>
                  </>
                )}
            </>
          )}
        </>
      )}
    </DetailPagesOverviewRightSecStyled>
  );
}

export default DetailPagesOverviewRightSec;
