//  <--Library Imports Start-->
import { useMemo } from "react";
import { Tooltip } from "antd";
//  <--Library Imports End-->

//  <--Components Start-->
import Flex from "../flex";
//  <--Components End-->

//  <--Constatnts Start-->
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
//  <--Constatnts End-->

//  <--SVGS Start-->
import { checkMarkSvg } from "../../svgs";
//  <--SVGS End-->

//  <--Types Start-->
import { IsCDEFieldProps } from "./iscdefield.types";
//  <--Types End-->

function IsCDEField(props: IsCDEFieldProps): JSX.Element {
  const { value = "", isUseFlex = true } = props;

  const childContent = useMemo(() => {
    return value === "Yes" ? (
      <Tooltip
        title="Yes"
        placement="right"
        overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
      >
        {checkMarkSvg()}
      </Tooltip>
    ) : (
      ""
    );
  }, [value]);

  return isUseFlex ? (
    <Flex justifyContent="center" height="40px" customClass="is-cde-flex">
      {childContent}
    </Flex>
  ) : (
    <>{childContent}</>
  );
}

export default IsCDEField;
