export const recommendedRulesGridBlankSlate = (
  <svg width="100%" height="379" viewBox="0 0 1778 379" fill="none">
    <rect width="1778" height="56" fill="#EFF3F8" />
    <rect x="1242" y="19.5" width="250" height="17" rx="8.5" fill="#E8E8E8" />
    <circle cx="1517" cy="28" r="12.5" fill="#EFF3F8" stroke="#E6E6E6" />
    <g clipPath="url(#clip0_1650_48041)">
      <path
        d="M1517.59 22.4302L1512.15 27.8771L1517.59 33.3236"
        stroke="#BCBDC3"
        strokeWidth="1.3"
      />
    </g>
    <g clipPath="url(#clip1_1650_48041)">
      <path
        d="M1522.2 22.4302L1516.75 27.8771L1522.2 33.3236"
        stroke="#BCBDC3"
        strokeWidth="1.3"
      />
    </g>
    <circle cx="1549" cy="28" r="12.5" fill="#EFF3F8" stroke="#E6E6E6" />
    <g clipPath="url(#clip2_1650_48041)">
      <path
        d="M1551.38 22.4656L1545.93 27.9125L1551.38 33.359"
        stroke="#BCBDC3"
        strokeWidth="1.3"
      />
    </g>
    <line
      x1="1575"
      y1="17"
      x2="1575"
      y2="39"
      stroke="#E6E6E6"
      strokeWidth="2"
    />
    <rect x="1586" y="19.5" width="94" height="17" rx="8.5" fill="#E8E8E8" />
    <line
      x1="1693"
      y1="17"
      x2="1693"
      y2="39"
      stroke="#E6E6E6"
      strokeWidth="2"
    />
    <circle
      cx="13"
      cy="13"
      r="12.5"
      transform="matrix(-1 0 0 1 1730 15)"
      fill="#EFF3F8"
      stroke="#E6E6E6"
    />
    <g clipPath="url(#clip3_1650_48041)">
      <path
        d="M1714.62 22.4656L1720.07 27.9125L1714.62 33.359"
        stroke="#BCBDC3"
        strokeWidth="1.3"
      />
    </g>
    <circle
      cx="13"
      cy="13"
      r="12.5"
      transform="matrix(-1 0 0 1 1762 15)"
      fill="#EFF3F8"
      stroke="#E6E6E6"
    />
    <g clipPath="url(#clip4_1650_48041)">
      <path
        d="M1748.41 22.4302L1753.85 27.8771L1748.41 33.3236"
        stroke="#BCBDC3"
        strokeWidth="1.3"
      />
    </g>
    <g clipPath="url(#clip5_1650_48041)">
      <path
        d="M1743.8 22.4302L1749.25 27.8771L1743.8 33.3236"
        stroke="#BCBDC3"
        strokeWidth="1.3"
      />
    </g>
    <rect x="16" y="20" width="159" height="17" rx="8.5" fill="#E8E8E8" />
    <mask id="path-16-inside-1_1650_48041" fill="white">
      <path d="M0 56H40V119H0V56Z" />
    </mask>
    <path d="M0 56H40V119H0V56Z" fill="#F6F6F6" />
    <path
      d="M0 56V55H-1V56H0ZM0 119H-1V120H0V119ZM0 57H40V55H0V57ZM40 118H0V120H40V118ZM1 119V56H-1V119H1Z"
      fill="#E6E6E6"
      mask="url(#path-16-inside-1_1650_48041)"
    />
    <rect
      x="12.5"
      y="80"
      width="15"
      height="15"
      rx="1.5"
      fill="white"
      stroke="#D6D5D5"
    />
    <mask id="path-19-inside-2_1650_48041" fill="white">
      <path d="M40 56H774.5V119H40V56Z" />
    </mask>
    <path d="M40 56H774.5V119H40V56Z" fill="#F6F6F6" />
    <path
      d="M40 57H774.5V55H40V57ZM774.5 118H40V120H774.5V118Z"
      fill="#E6E6E6"
      mask="url(#path-19-inside-2_1650_48041)"
    />
    <path
      d="M757.5 79V96H759.5V79H757.5Z"
      fill="#E6E6E6"
      mask="url(#path-21-inside-3_1650_48041)"
    />
    <rect x="52" y="79" width="159" height="17" rx="8.5" fill="#E8E8E8" />
    <mask id="path-24-inside-4_1650_48041" fill="white">
      <path d="M774.5 56H1509V119H774.5V56Z" />
    </mask>
    <path d="M774.5 56H1509V119H774.5V56Z" fill="#F6F6F6" />
    <path
      d="M774.5 57H1509V55H774.5V57ZM1509 118H774.5V120H1509V118Z"
      fill="#E6E6E6"
      mask="url(#path-24-inside-4_1650_48041)"
    />
    <path
      d="M1492 79V96H1494V79H1492Z"
      fill="#E6E6E6"
      mask="url(#path-26-inside-5_1650_48041)"
    />
    <rect x="786.5" y="79" width="159" height="17" rx="8.5" fill="#E8E8E8" />
    <mask id="path-29-inside-6_1650_48041" fill="white">
      <path d="M1509 56H1778V119H1509V56Z" />
    </mask>
    <path d="M1509 56H1778V119H1509V56Z" fill="#F6F6F6" />
    <path
      d="M1778 56H1779V55H1778V56ZM1778 119V120H1779V119H1778ZM1509 57H1778V55H1509V57ZM1777 56V119H1779V56H1777ZM1778 118H1509V120H1778V118Z"
      fill="#E6E6E6"
      mask="url(#path-29-inside-6_1650_48041)"
    />
    <path
      d="M1761 79V96H1763V79H1761Z"
      fill="#E6E6E6"
      mask="url(#path-31-inside-7_1650_48041)"
    />
    <rect x="1571.5" y="79" width="140" height="17" rx="8.5" fill="#E8E8E8" />
    <mask id="path-34-inside-8_1650_48041" fill="white">
      <path d="M0 118H40V171H0V118Z" />
    </mask>
    <path d="M0 118H40V171H0V118Z" fill="white" />
    <path
      d="M0 118V117H-1V118H0ZM0 171H-1V172H0V171ZM0 119H40V117H0V119ZM40 170H0V172H40V170ZM1 171V118H-1V171H1Z"
      fill="#E6E6E6"
      mask="url(#path-34-inside-8_1650_48041)"
    />
    <rect
      x="12.5"
      y="137"
      width="15"
      height="15"
      rx="1.5"
      fill="white"
      stroke="#D6D5D5"
    />
    <mask id="path-37-inside-9_1650_48041" fill="white">
      <path d="M40 118H774.5V171H40V118Z" />
    </mask>
    <path d="M40 118H774.5V171H40V118Z" fill="white" />
    <path
      d="M40 119H774.5V117H40V119ZM774.5 170H40V172H774.5V170Z"
      fill="#E6E6E6"
      mask="url(#path-37-inside-9_1650_48041)"
    />
    <rect x="52" y="136" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-40-inside-10_1650_48041" fill="white">
      <path d="M774.5 118H1509V171H774.5V118Z" />
    </mask>
    <path d="M774.5 118H1509V171H774.5V118Z" fill="white" />
    <path
      d="M774.5 119H1509V117H774.5V119ZM1509 170H774.5V172H1509V170Z"
      fill="#E6E6E6"
      mask="url(#path-40-inside-10_1650_48041)"
    />
    <rect x="786.5" y="136" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-43-inside-11_1650_48041" fill="white">
      <path d="M1509 118H1778V171H1509V118Z" />
    </mask>
    <path d="M1509 118H1778V171H1509V118Z" fill="white" />
    <path
      d="M1778 118H1779V117H1778V118ZM1778 171V172H1779V171H1778ZM1509 119H1778V117H1509V119ZM1777 118V171H1779V118H1777ZM1778 170H1509V172H1778V170Z"
      fill="#E6E6E6"
      mask="url(#path-43-inside-11_1650_48041)"
    />
    <rect x="1571.5" y="136" width="140" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-46-inside-12_1650_48041" fill="white">
      <path d="M0 170H40V223H0V170Z" />
    </mask>
    <path d="M0 170H40V223H0V170Z" fill="white" />
    <path
      d="M0 170V169H-1V170H0ZM0 223H-1V224H0V223ZM0 171H40V169H0V171ZM40 222H0V224H40V222ZM1 223V170H-1V223H1Z"
      fill="#E6E6E6"
      mask="url(#path-46-inside-12_1650_48041)"
    />
    <rect
      x="12.5"
      y="189"
      width="15"
      height="15"
      rx="1.5"
      fill="white"
      stroke="#D6D5D5"
    />
    <mask id="path-49-inside-13_1650_48041" fill="white">
      <path d="M40 170H774.5V223H40V170Z" />
    </mask>
    <path d="M40 170H774.5V223H40V170Z" fill="white" />
    <path
      d="M40 171H774.5V169H40V171ZM774.5 222H40V224H774.5V222Z"
      fill="#E6E6E6"
      mask="url(#path-49-inside-13_1650_48041)"
    />
    <rect x="52" y="188" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-52-inside-14_1650_48041" fill="white">
      <path d="M774.5 170H1509V223H774.5V170Z" />
    </mask>
    <path d="M774.5 170H1509V223H774.5V170Z" fill="white" />
    <path
      d="M774.5 171H1509V169H774.5V171ZM1509 222H774.5V224H1509V222Z"
      fill="#E6E6E6"
      mask="url(#path-52-inside-14_1650_48041)"
    />
    <rect x="786.5" y="188" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-55-inside-15_1650_48041" fill="white">
      <path d="M1509 170H1778V223H1509V170Z" />
    </mask>
    <path d="M1509 170H1778V223H1509V170Z" fill="white" />
    <path
      d="M1778 170H1779V169H1778V170ZM1778 223V224H1779V223H1778ZM1509 171H1778V169H1509V171ZM1777 170V223H1779V170H1777ZM1778 222H1509V224H1778V222Z"
      fill="#E6E6E6"
      mask="url(#path-55-inside-15_1650_48041)"
    />
    <rect x="1571.5" y="188" width="140" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-58-inside-16_1650_48041" fill="white">
      <path d="M0 222H40V275H0V222Z" />
    </mask>
    <path d="M0 222H40V275H0V222Z" fill="white" />
    <path
      d="M0 222V221H-1V222H0ZM0 275H-1V276H0V275ZM0 223H40V221H0V223ZM40 274H0V276H40V274ZM1 275V222H-1V275H1Z"
      fill="#E6E6E6"
      mask="url(#path-58-inside-16_1650_48041)"
    />
    <rect
      x="12.5"
      y="241"
      width="15"
      height="15"
      rx="1.5"
      fill="white"
      stroke="#D6D5D5"
    />
    <mask id="path-61-inside-17_1650_48041" fill="white">
      <path d="M40 222H774.5V275H40V222Z" />
    </mask>
    <path d="M40 222H774.5V275H40V222Z" fill="white" />
    <path
      d="M40 223H774.5V221H40V223ZM774.5 274H40V276H774.5V274Z"
      fill="#E6E6E6"
      mask="url(#path-61-inside-17_1650_48041)"
    />
    <rect x="52" y="240" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-64-inside-18_1650_48041" fill="white">
      <path d="M774.5 222H1509V275H774.5V222Z" />
    </mask>
    <path d="M774.5 222H1509V275H774.5V222Z" fill="white" />
    <path
      d="M774.5 223H1509V221H774.5V223ZM1509 274H774.5V276H1509V274Z"
      fill="#E6E6E6"
      mask="url(#path-64-inside-18_1650_48041)"
    />
    <rect x="786.5" y="240" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-67-inside-19_1650_48041" fill="white">
      <path d="M1509 222H1778V275H1509V222Z" />
    </mask>
    <path d="M1509 222H1778V275H1509V222Z" fill="white" />
    <path
      d="M1778 222H1779V221H1778V222ZM1778 275V276H1779V275H1778ZM1509 223H1778V221H1509V223ZM1777 222V275H1779V222H1777ZM1778 274H1509V276H1778V274Z"
      fill="#E6E6E6"
      mask="url(#path-67-inside-19_1650_48041)"
    />
    <rect x="1571.5" y="240" width="140" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-70-inside-20_1650_48041" fill="white">
      <path d="M0 274H40V327H0V274Z" />
    </mask>
    <path d="M0 274H40V327H0V274Z" fill="white" />
    <path
      d="M0 274V273H-1V274H0ZM0 327H-1V328H0V327ZM0 275H40V273H0V275ZM40 326H0V328H40V326ZM1 327V274H-1V327H1Z"
      fill="#E6E6E6"
      mask="url(#path-70-inside-20_1650_48041)"
    />
    <rect
      x="12.5"
      y="293"
      width="15"
      height="15"
      rx="1.5"
      fill="white"
      stroke="#D6D5D5"
    />
    <mask id="path-73-inside-21_1650_48041" fill="white">
      <path d="M40 274H774.5V327H40V274Z" />
    </mask>
    <path d="M40 274H774.5V327H40V274Z" fill="white" />
    <path
      d="M40 275H774.5V273H40V275ZM774.5 326H40V328H774.5V326Z"
      fill="#E6E6E6"
      mask="url(#path-73-inside-21_1650_48041)"
    />
    <rect x="52" y="292" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-76-inside-22_1650_48041" fill="white">
      <path d="M774.5 274H1509V327H774.5V274Z" />
    </mask>
    <path d="M774.5 274H1509V327H774.5V274Z" fill="white" />
    <path
      d="M774.5 275H1509V273H774.5V275ZM1509 326H774.5V328H1509V326Z"
      fill="#E6E6E6"
      mask="url(#path-76-inside-22_1650_48041)"
    />
    <rect x="786.5" y="292" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-79-inside-23_1650_48041" fill="white">
      <path d="M1509 274H1778V327H1509V274Z" />
    </mask>
    <path d="M1509 274H1778V327H1509V274Z" fill="white" />
    <path
      d="M1778 274H1779V273H1778V274ZM1778 327V328H1779V327H1778ZM1509 275H1778V273H1509V275ZM1777 274V327H1779V274H1777ZM1778 326H1509V328H1778V326Z"
      fill="#E6E6E6"
      mask="url(#path-79-inside-23_1650_48041)"
    />
    <rect x="1571.5" y="292" width="140" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-82-inside-24_1650_48041" fill="white">
      <path d="M0 326H40V379H0V326Z" />
    </mask>
    <path d="M0 326H40V379H0V326Z" fill="white" />
    <path
      d="M0 326V325H-1V326H0ZM0 379H-1V380H0V379ZM0 327H40V325H0V327ZM40 378H0V380H40V378ZM1 379V326H-1V379H1Z"
      fill="#E6E6E6"
      mask="url(#path-82-inside-24_1650_48041)"
    />
    <rect
      x="12.5"
      y="345"
      width="15"
      height="15"
      rx="1.5"
      fill="white"
      stroke="#D6D5D5"
    />
    <mask id="path-85-inside-25_1650_48041" fill="white">
      <path d="M40 326H774.5V379H40V326Z" />
    </mask>
    <path d="M40 326H774.5V379H40V326Z" fill="white" />
    <path
      d="M40 327H774.5V325H40V327ZM774.5 378H40V380H774.5V378Z"
      fill="#E6E6E6"
      mask="url(#path-85-inside-25_1650_48041)"
    />
    <rect x="52" y="344" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-88-inside-26_1650_48041" fill="white">
      <path d="M774.5 326H1509V379H774.5V326Z" />
    </mask>
    <path d="M774.5 326H1509V379H774.5V326Z" fill="white" />
    <path
      d="M774.5 327H1509V325H774.5V327ZM1509 378H774.5V380H1509V378Z"
      fill="#E6E6E6"
      mask="url(#path-88-inside-26_1650_48041)"
    />
    <rect x="786.5" y="344" width="331" height="17" rx="8.5" fill="#F0F0F0" />
    <mask id="path-91-inside-27_1650_48041" fill="white">
      <path d="M1509 326H1778V379H1509V326Z" />
    </mask>
    <path d="M1509 326H1778V379H1509V326Z" fill="white" />
    <path
      d="M1778 326H1779V325H1778V326ZM1778 379V380H1779V379H1778ZM1509 327H1778V325H1509V327ZM1777 326V379H1779V326H1777ZM1778 378H1509V380H1778V378Z"
      fill="#E6E6E6"
      mask="url(#path-91-inside-27_1650_48041)"
    />
    <rect x="1571.5" y="344" width="140" height="17" rx="8.5" fill="#F0F0F0" />
    <defs>
      <clipPath id="clip0_1650_48041">
        <rect
          width="11.823"
          height="6.84273"
          fill="white"
          transform="translate(1518.06 21.9646) rotate(90)"
        />
      </clipPath>
      <clipPath id="clip1_1650_48041">
        <rect
          width="11.823"
          height="6.84273"
          fill="white"
          transform="translate(1522.66 21.9646) rotate(90)"
        />
      </clipPath>
      <clipPath id="clip2_1650_48041">
        <rect
          width="11.823"
          height="6.84273"
          fill="white"
          transform="translate(1551.84 22) rotate(90)"
        />
      </clipPath>
      <clipPath id="clip3_1650_48041">
        <rect
          width="11.823"
          height="6.84273"
          fill="white"
          transform="matrix(4.37114e-08 1 1 -4.37114e-08 1714.16 22)"
        />
      </clipPath>
      <clipPath id="clip4_1650_48041">
        <rect
          width="11.823"
          height="6.84273"
          fill="white"
          transform="matrix(4.37114e-08 1 1 -4.37114e-08 1747.94 21.9646)"
        />
      </clipPath>
      <clipPath id="clip5_1650_48041">
        <rect
          width="11.823"
          height="6.84273"
          fill="white"
          transform="matrix(4.37114e-08 1 1 -4.37114e-08 1743.34 21.9646)"
        />
      </clipPath>
    </defs>
  </svg>
);
