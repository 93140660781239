import {
  ReturnTypeOfAssetRelationshipsData,
  ReturnTypeOfAssetRelationshipsList,
} from "../../parsers/asssetoverviewparser";
import { AssetGridDataType } from "../sectionwitheditables";

export const transformAssetRelationshipParsedData = (
  assetRelationshipsList: ReturnTypeOfAssetRelationshipsList,
  assetRelationshipsData: ReturnTypeOfAssetRelationshipsData
): AssetGridDataType[] => {
  return assetRelationshipsList.map((relationshipListItem) => {
    const rows = assetRelationshipsData?.filter(
      (relationshipDataItem) =>
        relationshipListItem?.id === relationshipDataItem?.sectionId
    );

    return {
      isInherited: rows?.[0]?.isInherited || false,
      isInheritedBy: rows?.[0]?.isInheritedBy || "",
      assetType: relationshipListItem?.target_asset_type,
      assetTypeId: relationshipListItem?.assetTypeId,
      relationshipType: relationshipListItem?.relationship_type,
      relationshipTypeId: relationshipListItem?.relationship_type_id,
      sectionId: relationshipListItem?.id,
      isEditable: relationshipListItem?.isEditable,
      isMultiValued: relationshipListItem?.isMultiValued,
      isAssetPathRequired: relationshipListItem?.isAssetPathRequired,
      nodeType: relationshipListItem?.nodeType,
      srcColName: relationshipListItem?.srcColName,
      tgtColName: relationshipListItem?.tgtColName,
      rows,
    };
  });
};
