import { TermType } from "../../../parsers/termsparser/termsparser.types";
import { sortListOnSpecificKeyValue } from "../../../utils";
import {
  BusinessRulesState,
  GetBusinessRulesType,
} from "./sectionwitheditablebuisinessrules.types";

export function getBuisenessRulesForTerm(
  termType: TermType,
  state: BusinessRulesState
): GetBusinessRulesType {
  const isMinExist =
    state?.["Value Range"]?.min !== null &&
    state?.["Value Range"]?.min !== undefined;

  const isMaxExist =
    state?.["Value Range"]?.max !== undefined &&
    state?.["Value Range"]?.max !== null;

  const isMinMaxExist = isMaxExist && isMinExist;
  switch (termType) {
    case "CAA":
      return {
        "Categorical Values": {
          title: "Categories",
          firstValue:
            state?.["Categorical Values"]?.find((rule) => rule?.value)?.value ||
            "",
          details: state?.["Categorical Values"] || [],
          remaininItemsCount: state?.["Categorical Values"]?.length
            ? state?.["Categorical Values"]?.length - 1
            : 0,
          type: "tags",
          ruleType: "Categorical Values",
        },
        Patterns: {
          title: "Pattern",
          firstValue: state?.Patterns?.find((rule) => rule?.value)?.value || "",
          details: state?.Patterns || [],
          remaininItemsCount: state?.Patterns?.length
            ? state?.Patterns?.length - 1
            : 0,
          type: "tags",
          ruleType: "Patterns",
        },
        Manual: {
          title: "Manual",
          value: state?.Manual || "",
          type: "text",
          ruleType: "Manual",
        },
      };
    case "BSM":
    case "CMM":
      return {
        "Value Range": {
          title: "Value Range",
          value: isMinMaxExist
            ? `${state?.["Value Range"]?.min} - ${state?.["Value Range"]?.max}`
            : "",
          type: "min-max",
          ruleType: "Value Range",
          max_value: isMaxExist ? state?.["Value Range"]?.max : "",
          min_value: isMinExist ? state?.["Value Range"]?.min : "",
        },
        Manual: {
          title: "Manual",
          value: state?.Manual || "",
          type: "text",
          ruleType: "Manual",
        },
      };
    case "NCA":
      return {
        Patterns: {
          title: "Pattern",
          firstValue: state?.Patterns?.find((rule) => rule?.value)?.value || "",
          details: state?.Patterns || [],
          remaininItemsCount: state?.Patterns?.length
            ? state?.Patterns?.length - 1
            : 0,
          type: "tags",
          ruleType: "Patterns",
        },
        Manual: {
          title: "Manual",
          value: state?.Manual || "",
          type: "text",
          ruleType: "Manual",
        },
      };
    default:
      return {
        Manual: {
          title: "Manual",
          value: state?.Manual || "",
          type: "text",
          ruleType: "Manual",
        },
      };
  }
}

export function sortBusinessRulesValues(
  values: BusinessRulesState
): BusinessRulesState {
  return {
    ...values,
    "Categorical Values": sortListOnSpecificKeyValue({
      list: values?.["Categorical Values"],
      key: "value",
    }),
    Patterns: sortListOnSpecificKeyValue({
      list: values?.Patterns,
      key: "value",
    }),
  };
}
