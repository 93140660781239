// <--- Library Imports Start --->
import { useCallback } from "react";

import { useDispatch } from "react-redux";
// <--- Library Imports Start --->

// <--- Components Start --->
import FindOrAddDataset from "../findoradddataset";

import DvsumHelpDrawer from "../../drawerviews/dvsumhelpdrawer/dvsumhelpdrawer";

import {
  ActivityHistoryDrawer,
  ETLRefDrawer,
  ETLCodeRefDrawer,
  AssetRefDrawer,
} from "../../drawerviews";
// <--- Components End --->

// <--- Custom Hooks Start --->
import { useGetAppState } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Reducers Start --->
import { setDrawer } from "../../reducers/appreducer/appreducer";
// <--- Reducers End --->

// <--- Styles Start --->
import { DrawerStyled } from "./drawerwrapper.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { crossIcon, dvSumLogo } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { AppState } from "../../reducers/appreducer/appreducer.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  enableMasking,
  getBoxShadow,
  getDrawerWidth,
  getMaskBackground,
} from "./drawerwrapper.utils";

import { checkIsChatPage } from "../../utils/ischatpage";
import TableViewDrawer from "../../drawerviews/tableviewdrawer/tableviewdrawer";

import ErdEdgeRefDrawer from "../../drawerviews/erdedgerefdrawer/erdedgerefdrawer";
import RulesListingEditRefDrawer from "../../drawerviews/ruleslistingeditdrawer/ruleslistingeditdrawer";

import JobRefDrawer from "../../drawerviews/jobrefdrawer/jobrefdrawer";
// <--- Utils End --->

function getDrawerContent(id: AppState["drawer"]["drawerId"]): JSX.Element {
  switch (id) {
    case "dummy":
      return <div>Drawer added in the storybook</div>;
    case "discovery_drawer":
      return <FindOrAddDataset />;

    case "dvsum_help":
      return <DvsumHelpDrawer />;
    case "comment_history_ref":
      return <ActivityHistoryDrawer />;

    case "table_view":
      return <TableViewDrawer />;
    case "erd_edge_view":
      return <ErdEdgeRefDrawer />;

    case "rules_listing_edit":
      return <RulesListingEditRefDrawer />;

    case "job_ref":
      return <JobRefDrawer />;

    case "etl_code_ref":
      return <ETLCodeRefDrawer />;

    case "asset_ref_drawer":
      return <AssetRefDrawer />;
    case "column_ref":
      return <AssetRefDrawer nodeType="COL" />;
    case "term_ref":
      return <AssetRefDrawer nodeType="TRM" />;
    case "table_ref":
      return <AssetRefDrawer nodeType="TBL" />;
    // return <AssetRefDrawer nodeType="RFD" />;
    case "rule_ref":
      return <AssetRefDrawer nodeType="RLS" />;
    case "data_source_ref":
      return <AssetRefDrawer nodeType="DSR" />;
    case "report_ref":
      return <AssetRefDrawer nodeType="DSR" nodeSubType="RPT" />;
    case "data_source_field_ref":
      return <AssetRefDrawer nodeType="DSF" />;
    case "etl_ref":
      return <AssetRefDrawer nodeType="PLO" />;

    default:
      return <div />;
  }
}

function Drawer(): JSX.Element {
  const { drawer = {} } = useGetAppState();

  const {
    visible,
    drawerTitle,
    drawerId,
    placement = "right",
    zIndex = 1000,
    isMaximized = false,
    isStartFromTop,
    propsOnClose,
  } = drawer;

  const isChatPage = checkIsChatPage();

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    propsOnClose && propsOnClose();
    dispatch(setDrawer({ ...drawer, visible: false }));
  }, [drawer]);

  return (
    <DrawerStyled
      placement={placement}
      visible={visible}
      title={
        drawerTitle && (
          <div className="custom-header">
            {dvSumLogo()}
            {drawerTitle}
          </div>
        )
      }
      maskClosable
      onClose={onClose}
      width={getDrawerWidth(drawerId, isMaximized)}
      closeIcon={crossIcon()}
      mask={enableMasking(drawerId)}
      zIndex={zIndex}
      destroyOnClose
      isChatPage={isChatPage}
      maskStyle={{
        backgroundColor: getMaskBackground(drawerId),
      }}
      contentWrapperStyle={{
        boxShadow: getBoxShadow(drawerId),
      }}
      isStartFromTop={isStartFromTop}
    >
      {getDrawerContent(drawerId)}
    </DrawerStyled>
  );
}

export default Drawer;
