import moment from "moment";
import { KeyValuePairType, NodeType } from "../../app.types";
import { TileFilterType } from "../../components/tablewithfiltertiles/tablewithfiltertiles.types";

import { ELEMENT_IDS } from "../../constants";
import { FieldType } from "../../parsers/listingpagesparser/listingpagesparser.types";

import {
  alertIconOutlined,
  assignToMe,
  calendarIcon,
  clockIcon,
  chatIcon,
  circleTickSvg,
  crossIconInCircle,
  doubleTickIcon,
  duplicateIcon,
  greyPlayIcon,
  monthlyCalendarIcon,
  removeScheduleIcon,
  weeklyCalendarIcon,
  multiChatIcon,
  plusIconInCircleOutlined,
  refreshIconOutlined1,
  saveIcon,
  thumbsDownSvg,
  thumbsUpSvg,
  toReviewSvg,
  totalResultsIcon,
  userActivityIcon,
  userIconOutlined,
  userWithTickInCircleIcon,
} from "../../svgs";

import { getObjectKeys, getPageNode } from "../../utils";
import { LISTING_PAGE_TAB_FILTER_VALUES } from "./listingpage.constants";

import { PageNodeType } from "./listingpage.types";

const { allcat_lst_list_hlttot: ALLCAT_LST_LIST_HLTTOT } = ELEMENT_IDS;

const {
  ALERTS_AVAILABLE_COUNT,
  ALL,
  ASSIGNED_TO_COUNT,
  DUPLICATE_COUNT,
  INCOMPLETE_COUNT,
  NEWLY_ADDED_COUNT,
  RECENTLY_REFRESHED_COUNT,
  USER_ACTIVITY_COUNT,
  RECENTLY_EXECUTED_COUNT,
  DAILY_JOBS,
  WEEKLY_JOBS,
  RUNNING_JOBS,
  COMPLETED_JOBS,
  ABORTED_JOBS,
  SCHEDULED_JOBS,
  MONTHLY_JOBS,
  TOTAL_FEEDBACK_COUNT,
  TOTAL_QUESTIONS_COUNT,
  USERS_COUNT,
  APPROVED_COUNT,
  REJECTED_COUNT,
  TO_REVIEW_COUNT,
  SAVED_COUNT,
  RECENTLY_MODIFIED_BY,
} = LISTING_PAGE_TAB_FILTER_VALUES;

export const getTileFiltersList = (
  summaryCount: KeyValuePairType<number>,
  type: NodeType
): TileFilterType[] => {
  const filters =
    type === "TRM"
      ? [
          {
            id: ALL,
            value: `${summaryCount?.total_results_count || 0}`,
            title: "Total Results",
            icon: totalResultsIcon("13", "13"),
            iconClass: "total-results",
          },
          {
            id: NEWLY_ADDED_COUNT,
            value: `${summaryCount?.newly_added_count || 0}`,
            title: "Newly Added",
            icon: plusIconInCircleOutlined("13", "13"),
            iconClass: "newly-added",
          },
          {
            id: INCOMPLETE_COUNT,
            value: `${summaryCount?.incomplete_count || 0}`,
            title: "Incomplete",
            icon: crossIconInCircle("13", "13"),
            iconClass: "incomplete",
          },
          {
            id: DUPLICATE_COUNT,
            value: `${summaryCount?.duplicate_count || 0}`,
            title: "Duplicate",
            icon: duplicateIcon("13", "13"),
            iconClass: "duplicate",
          },
          {
            id: ASSIGNED_TO_COUNT,
            value: `${summaryCount?.assigned_to_count || 0}`,
            title: "Assigned to me",
            icon: assignToMe(),
            iconClass: "assigned-to",
          },
        ]
      : type === "CHT"
      ? [
          {
            id: ALL,
            value: `${summaryCount?.total_results_count || 0}`,
            title: "Total Chats",
            icon: chatIcon("13", "13"),
            iconClass: "total-chats",
          },
          {
            id: TOTAL_QUESTIONS_COUNT,
            value: `${summaryCount?.total_questions_count || 0}`,
            title: "Questions",
            icon: multiChatIcon("20", "20"),
            iconClass: "questions",
          },
          {
            id: TOTAL_FEEDBACK_COUNT,
            value: `${summaryCount?.total_feedback_count || 0}`,
            title: "With Feedback",
            icon: toReviewSvg("17", "17"),
            iconClass: "with-feedback",
          },
          {
            id: USERS_COUNT,
            value: `${summaryCount?.users_count || 0}`,
            title: "Users",
            icon: userIconOutlined("13", "13"),
            iconClass: "users",
          },
        ]
      : type === "FBK"
      ? [
          {
            id: ALL,
            value: `${summaryCount?.total_results_count || 0}`,
            title: "All Feedback",
            icon: circleTickSvg(),
            iconClass: "all-feedback",
          },
          {
            id: APPROVED_COUNT,
            value: `${summaryCount?.approved_count || 0}`,
            title: "Approved",
            icon: thumbsUpSvg(),
            iconClass: "approved",
          },
          {
            id: REJECTED_COUNT,
            value: `${summaryCount?.rejected_count || 0}`,
            title: "Rejected",
            icon: thumbsDownSvg(),
            iconClass: "rejected",
          },
          {
            id: TO_REVIEW_COUNT,
            value: `${summaryCount?.to_review_count || 0}`,
            title: "To Review",
            icon: userWithTickInCircleIcon(),
            iconClass: "to-review",
          },
        ]
      : type === "JOB"
      ? [
          {
            id: ALL,
            value: `${summaryCount?.total_results_count || 0}`,
            title: "Total Results",
            icon: totalResultsIcon("13", "13"),
            iconClass: "total-results",
          },
          {
            id: DAILY_JOBS,
            value: `${summaryCount?.daily_jobs || 0}`,
            title: "Daily Jobs",
            icon: clockIcon("13", "13"),
            iconClass: "daily-jobs",
          },
          {
            id: WEEKLY_JOBS,
            value: `${summaryCount?.weekly_jobs || 0}`,
            title: "Weekly Jobs",
            icon: weeklyCalendarIcon("22", "22"),
            iconClass: "weekly-jobs",
          },
          {
            id: MONTHLY_JOBS,
            value: `${summaryCount?.monthly_jobs || 0}`,
            title: "Monthly Jobs",
            icon: monthlyCalendarIcon("22", "22"),
            iconClass: "monthly-jobs",
          },
        ]
      : type === "SCH"
      ? [
          {
            id: ALL,
            value: `${summaryCount?.total_results_count || 0}`,
            title: "Total Results",
            icon: totalResultsIcon("13", "13"),
            iconClass: "total-results",
          },
          {
            id: RUNNING_JOBS,
            value: `${summaryCount?.running_count || 0}`,
            title: "Running",
            icon: clockIcon("13", "13"),
            iconClass: "running-jobs",
          },
          {
            id: SCHEDULED_JOBS,
            value: `${summaryCount?.scheduled_count || 0}`,
            title: "Scheduled",
            icon: calendarIcon("13", "13"),
            iconClass: "scheduled-jobs",
          },
          {
            id: COMPLETED_JOBS,
            value: `${summaryCount?.completed_count || 0}`,
            title: "Completed",
            icon: doubleTickIcon("25", "25"),
            iconClass: "completed-jobs",
          },
        ]
      : [
          {
            id: ALL,
            value: `${summaryCount?.total_results_count || 0}`,
            title: `Total ${type === "RLS" ? "Rules" : "Results"}`,
            icon: totalResultsIcon("13", "13"),
            iconClass: "total-results",
            elemId: ALLCAT_LST_LIST_HLTTOT,
          },

          type === "RLS"
            ? {
                id: RECENTLY_EXECUTED_COUNT,
                value: `${summaryCount?.recently_executed_count || 0}`,
                title: " Recently Executed",
                icon: refreshIconOutlined1("13", "13"),
                iconClass: "recently-executed",
              }
            : type === "ALL"
            ? {
                id: RECENTLY_MODIFIED_BY,
                value: `${summaryCount?.modified_count || 0}`,
                title: "Modified",
                icon: refreshIconOutlined1("13", "13"),
                iconClass: "recently-refreshed",
              }
            : {
                id: RECENTLY_REFRESHED_COUNT,
                value: `${summaryCount?.recently_refreshed_count || 0}`,
                title: "Recently Refreshed",
                icon: refreshIconOutlined1("13", "13"),
                iconClass: "recently-refreshed",
              },

          ...(type !== "RLS"
            ? [
                {
                  id: NEWLY_ADDED_COUNT,
                  value: `${summaryCount?.newly_added_count || 0}`,
                  title: "Newly Added",
                  icon: plusIconInCircleOutlined("13", "13"),
                  iconClass: "newly-added",
                },
              ]
            : []),
          ...(type !== "ALL"
            ? [
                {
                  id: ALERTS_AVAILABLE_COUNT,
                  value: `${summaryCount?.alerts_count || 0}`,
                  title: "With Alerts",
                  icon: alertIconOutlined("13", "13"),
                  iconClass: "with-alerts",
                },
              ]
            : []),
          type === "COL"
            ? {
                id: USER_ACTIVITY_COUNT,
                value: `${summaryCount?.user_activity_count || 0}`,
                title: "With User Activity",
                icon: userActivityIcon("13", "13"),
                iconClass: "with-user-activity",
              }
            : {
                id: ASSIGNED_TO_COUNT,
                value: `${summaryCount?.assigned_to_count || 0}`,
                title: "Assigned to me",
                icon: assignToMe(),
                iconClass: "assigned-to",
              },
        ];

  return filters;
};

const formatDate = (date: string | null): string | null => {
  return date ? moment.utc(date).local().format("YYYY-MM-DD") : null;
};

export const parseFilterModel = (
  filterModel: KeyValuePairType<any>,
  columns: any[],
  isAgGridModel?: boolean
): KeyValuePairType<any> => {
  const newFilterModel = { ...filterModel };
  const filterModelKeys = getObjectKeys(newFilterModel);

  const allColumns: KeyValuePairType<string> = columns?.reduce(
    (previous, column) => ({
      ...previous,
      [isAgGridModel
        ? column?.field || ""
        : column?.headerName || ""]: isAgGridModel
        ? column?.headerName
        : column?.field,
    }),
    {}
  );

  const parsedFilterModel = filterModelKeys?.reduce((previous: any, key) => {
    const filter = { ...newFilterModel[key] };
    return {
      ...previous,
      [allColumns[key]]: {
        ...filter,
        ...(filter?.filterType === "date" &&
          isAgGridModel &&
          (filter?.operator
            ? {
                condition1: {
                  ...filter?.condition1,
                  dateFrom: formatDate(filter?.condition1?.dateFrom),
                  dateTo: formatDate(filter?.condition1?.dateTo),
                },
                condition2: {
                  ...filter?.condition2,
                  dateFrom: formatDate(filter?.condition2?.dateFrom),
                  dateTo: formatDate(filter?.condition2?.dateTo),
                },
              }
            : {
                dateFrom: formatDate(filter?.dateFrom),
                dateTo: formatDate(filter?.dateTo),
              })),
      },
    };
  }, {});

  return parsedFilterModel;
};

export const isDownloadDisabled = (
  tileFilters: TileFilterType[],
  selectedFilter: string
): boolean => {
  const filter = tileFilters?.find(
    (tileFilter) => tileFilter?.id === selectedFilter
  );

  return tileFilters?.length === 0
    ? true
    : filter
    ? filter?.value === "0"
    : true;
};

export const convertConfigObjectToArray = (
  config: KeyValuePairType<FieldType>
): FieldType[] => {
  const objKeys = getObjectKeys(config);

  const configArray = objKeys?.map((key) => config[key]) || [];

  return configArray;
};

export const getPageNodeName = (nodeType: NodeType): string => {
  const {
    isTablePage,
    isColumnPage,
    isJobPage,
    isRulePage,
    isDataSetPage,
    isTermPage,
  } = getPageNode(nodeType);

  const nodeName = isTablePage
    ? "table"
    : isColumnPage
    ? "column"
    : isRulePage
    ? "rule"
    : isJobPage
    ? "job"
    : isTermPage
    ? "term"
    : isDataSetPage
    ? "dataset"
    : "asset";

  return nodeName;
};
