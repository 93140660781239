import { AxiosResponse } from "axios";
import { isJsonString, jsonParse, sortObjectsArrayByKey } from "../../utils";
import { GlossaryCategoriesResponse } from "../termsparser/termsparser.types";
import {
  CategoryUserResponseType,
  CategoryUserType,
  GlossaryCategoriesForDomainsParserReturnType,
  GlossaryCategoriesForSelectParserReturnType,
  GlossaryCategoriesParserReturnType,
  DetailGlossaryCategoriesResponse,
  TermResponseType,
  TermsParserResponseType,
  TermsResponse,
  TermType,
  SubDomainForSelectReturnType,
} from "./glossarycategoriesparser.types";
import { workflowIcon } from "../../svgs";

const workflow = workflowIcon("14", "14");

export const getParsedGlossaryCategories = ({
  data,
}: AxiosResponse<
  DetailGlossaryCategoriesResponse[]
>): GlossaryCategoriesParserReturnType[] => {
  return data?.map((item) => {
    const categoryTerms: TermType[] = jsonParse(item?.TERMS)?.map(
      (term: TermResponseType) => {
        return {
          id: term?.trm_id || 0,
          name: term?.trm_name || "",
        };
      }
    );

    const categoryDataOwners: CategoryUserType[] = jsonParse(
      item?.DATA_OWNERS
    )?.map((owner: CategoryUserResponseType) => {
      return {
        id: owner?.user_id || 0,
        name: owner?.user_name || "",
        email: owner?.user_email || "",
        type: owner?.user_type || "",
      };
    });

    const categoryDataStewards: CategoryUserType[] = jsonParse(
      item?.DATA_STEWARDS
    )?.map((steward: CategoryUserResponseType) => {
      return {
        id: steward?.user_id || 0,
        name: steward?.user_name || "",
        email: steward?.user_email || "",
        type: steward?.user_type || "",
      };
    });

    const domainDataApprovers: CategoryUserType[] = jsonParse(
      item?.CAT_APPROVERS
    )?.map((approver: CategoryUserResponseType) => {
      return {
        id: approver?.user_id || 0,
        name: approver?.user_name || "",
        email: approver?.user_email || "",
        type: approver?.user_type || "",
      };
    });

    const parsedSettings = jsonParse(item?.CAT_SETTINGS || "");

    return {
      id: item?.CAT_ID || 0,
      name: item?.CAT_NAME || "",
      description: item?.CAT_DESC || "",
      is_predefined: item?.IS_PREDEFINED || false,
      priority_id: item?.CAT_PRIORITY_ID || "",
      priority: item?.CAT_PRIORITY || "",
      terms: categoryTerms?.map((term) => term.name),
      terms_obj: categoryTerms,
      created_by_id: item?.CREATED_BY_ID || 0,
      created_by: item?.CREATED_BY || "",
      created_on: item?.CREATED_ON || "",
      updated_by_id: item?.UPDATED_BY_ID || 0,
      updated_by: item?.UPDATED_BY || "",
      updated_on: item?.UPDATED_ON || "",
      data_owners: categoryDataOwners.map((item) => item?.name),
      data_owners_obj: categoryDataOwners,
      data_stewards: categoryDataStewards.map((item) => item?.name),
      data_stewards_obj: categoryDataStewards,
      domain_id: item?.DMN_ID || 0,
      domain_name: item?.DMN_NAME || "",
      action: "",
      is_workflow_enabled: item?.IS_WF_ENABLED || false,
      is_workflow_inherited: item?.IS_WT_INHERITED || false,
      data_approvers: domainDataApprovers?.map((item) => item?.name) || [],
      data_approvers_obj: domainDataApprovers || [],
      workflow_id: item?.WT_ID || 0,
      categorySettings: parsedSettings || {},
    };
  });
};

export const getParsedGlossaryCategoriesForDomains = ({
  data,
}: AxiosResponse<
  DetailGlossaryCategoriesResponse[]
>): GlossaryCategoriesForDomainsParserReturnType[] => {
  return data?.map((item) => {
    return {
      key: `${item?.CAT_ID || 0}`,
      name: item?.CAT_NAME || "",
      description: item?.CAT_DESC || "",
      disabled: !!item?.IS_PREDEFINED,
    };
  });
};

export const getParsedGlossaryCategoriesForSelect = ({
  data,
}: AxiosResponse<
  DetailGlossaryCategoriesResponse[]
>): GlossaryCategoriesForSelectParserReturnType[] => {
  return data?.map((item) => {
    return {
      value: `${item?.CAT_ID || 0}`,
      label: `${item?.CAT_NAME} > ${item?.CAT_DESC}` || "",
    };
  });
};

export const getParsedTermsForCategory = ({
  data,
}: AxiosResponse<TermsResponse[]>): TermsParserResponseType[] => {
  return (
    data?.map((item) => {
      return {
        key: `${item?.TRM_ID || 0}`,
        name: item?.TRM_NAME || "",
        description: item?.TRM_DESC || "",
      };
    }) || []
  );
};

export const getParsedGlossaryCategoriesForTerm = ({
  data,
}: AxiosResponse<
  GlossaryCategoriesResponse[]
>): GlossaryCategoriesForSelectParserReturnType[] => {
  return (
    data?.map((item) => {
      return {
        value: `${item?.CAT_ID || 0}`,
        label: `${item?.CAT_NAME}` || "",
      };
    }) || []
  );
};

export const getDomainSubdomainForTermSelect = ({
  data,
}: AxiosResponse<
  GlossaryCategoriesResponse[]
>): SubDomainForSelectReturnType[] => {
  const sortedData = sortObjectsArrayByKey(data, "CAT_NAME");

  return (
    sortedData?.map((apiData) => {
      const selectOptionObject = {
        label: (
          <>
            {apiData?.DMN_NAME || ""}
            {apiData?.IS_WF_INHERITED && (
              <span className="workflow-icon">{workflow}</span>
            )}
            <span className="icon">{">"}</span>
            {apiData?.CAT_NAME || ""}
            {(apiData?.IS_WF_ENABLED || apiData?.IS_WF_INHERITED) && (
              <span className="workflow-icon">{workflow}</span>
            )}
          </>
        ),
        labelText: `${apiData?.DMN_NAME || ""} ${apiData?.CAT_NAME || ""}`,
        value: `${apiData?.CAT_ID || 0}`,
      };
      return selectOptionObject || {};
    }) || []
  );
};

export const getUniqueDomainOfLoggedInUser = ({
  data,
}: AxiosResponse<GlossaryCategoriesResponse[]>): string[] => {
  const domainsArray =
    data?.map((apiData) => {
      return {
        domainName: `${apiData?.DMN_NAME || ""}`,
        domainId: `${apiData?.DMN_ID || 0}`,
      };
    }) || [];
  // SET
  return [...new Set(domainsArray.map((item) => item?.domainName || ""))];
};

export const getSubdomainOfUser = ({
  data,
}: AxiosResponse<
  GlossaryCategoriesResponse[]
>): GlossaryCategoriesResponse[] => {
  return (
    data?.map((item) => {
      return {
        CAT_ID: item?.CAT_ID || 0,
        CAT_NAME: item?.CAT_NAME || "",
        DMN_ID: item?.DMN_ID || 0,
        DMN_NAME: item?.DMN_NAME || "",
      };
    }) || []
  );
};
