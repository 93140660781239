import { NodeType } from "../../app.types";
import { AssetRelationshipContextStateType } from "./assetrelationshipscontext.types";

export const ASSET_RELATIONSHIPS_INITIAL_STATE: AssetRelationshipContextStateType = {
  data: [],
  assetSearchText: "",
  selectedAssetFilter: ["All"],
  isEditable: false,
  isEdited: false,
  nodeName: "",
  nodeId: "",
  nodeTitle: "",
  nodeType: "" as NodeType,
  isRefPage: false,
};
