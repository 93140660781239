// <--- Library Imports Start --->
import ReactDOM from "react-dom";
import Amplify, { Hub, Auth } from "aws-amplify";

import { StrictMode } from "react";
import { LicenseManager } from "ag-grid-enterprise";
// <--- Library Imports End --->

// <--- Components Start --->
import App from "./App";
// <--- Components End --->

// <--- Constants Start --->
import { awsConfig, awsAuth } from "./constants/awsuserpoolconfig";
// <--- Constants End --->

// <--- Utils Start --->
import reportWebVitals from "./reportWebVitals";
import {
  checkIsFDAMode,
  getEnvVariables,
  setAuthenticationInfoInLocalStorage,
} from "./utils";
// <--- Utils End --->

// import { worker } from "./mocks/browser";

// if (process.env.NODE_ENV === "development") {
//   worker.start();
// }

const { REACT_APP_AG_GRID_LICENCE_KEY } = getEnvVariables();

const isFDAMode = checkIsFDAMode();

LicenseManager.setLicenseKey(REACT_APP_AG_GRID_LICENCE_KEY);

if (!isFDAMode) {
  Amplify.configure(awsConfig);
  Auth.configure({ oauth: awsAuth });

  Hub.listen("auth", ({ payload: { event, data } }) => {
    const { accessToken = {}, refreshToken = {}, idToken = {} } =
      data?.signInUserSession || {};
    switch (event) {
      case "signIn":
        setAuthenticationInfoInLocalStorage({
          accessToken: accessToken?.jwtToken,
          username: accessToken?.payload?.username,
          idToken: idToken?.jwtToken,
          email: idToken?.payload?.email,
          refreshToken: refreshToken?.token,
          expTime: accessToken?.payload?.exp,
        });

        break;
      default:
      // console.log("event", event, data);
    }
  });
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
