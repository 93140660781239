import { assetRelationshipsBlankStateSvg } from "../../../../../svgs";
import { AssetRelationshipsBlankStateStyled } from "./assetrelationshipsblankstate.styles";

const AssetRelationshipsBlankStateIcon = assetRelationshipsBlankStateSvg();

const AssetRelationshipsBlankState = (): JSX.Element => {
  return (
    <AssetRelationshipsBlankStateStyled>
      {AssetRelationshipsBlankStateIcon}

      <div className="blankslate-text">
        You haven’t defined any relationships yet.
      </div>
    </AssetRelationshipsBlankStateStyled>
  );
};

export default AssetRelationshipsBlankState;
