// UI Components
import { Tooltip } from "antd";

// Constants
import {
  DVSUM_TOOLTIP_CLASS_NAME,
  MONTH_DAY_YEAR_HOUR_MINUTE,
} from "../../../../constants";

// Icons & SVGs
import { checkMarkSvg } from "../../../../svgs";

// Utilities
import { utcTOLocalTimeZone } from "../../../../utils";

// Styled Components
import { TextReadOnlyFieldRendrerStyled } from "./informationrenderer.styles";

// Types
import {
  InformationFieldRendrerProps,
  RendererValueType,
} from "./informationrenderer.types";
import { TableProps } from "../../../../components/aggridtable";
import { SampleDataRow } from "./profilingtab.types";

const TextReadOnlyFieldRendrer = ({
  value,
}: RendererValueType): JSX.Element => (
  <Tooltip title={value} overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}>
    <TextReadOnlyFieldRendrerStyled>
      {value !== undefined ? value : "--"}
    </TextReadOnlyFieldRendrerStyled>
  </Tooltip>
);

const DateReadOnlyFieldRendrer = ({
  value,
}: RendererValueType): JSX.Element => {
  const formattedDate = value
    ? utcTOLocalTimeZone(String(value), MONTH_DAY_YEAR_HOUR_MINUTE)
    : "--";
  return (
    <TextReadOnlyFieldRendrerStyled>
      {formattedDate}
    </TextReadOnlyFieldRendrerStyled>
  );
};

const CheckBoxReadOnlyFieldRendrer = ({
  value,
}: RendererValueType): JSX.Element => {
  return value === true ? (
    <Tooltip
      title="Yes"
      placement="right"
      overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
    >
      {checkMarkSvg()}
    </Tooltip>
  ) : (
    <span>--</span>
  );
};

const NumberFieldReadOnlyFieldRendrer = ({
  value,
}: RendererValueType): JSX.Element => (
  <TextReadOnlyFieldRendrerStyled>
    {value !== undefined ? value : "--"}
  </TextReadOnlyFieldRendrerStyled>
);

const InformationReadOnlyFieldRendrer = ({
  type,
  value,
}: InformationFieldRendrerProps): JSX.Element => {
  switch (type) {
    case "TEXT":
      return <TextReadOnlyFieldRendrer value={value ?? "--"} />;
    case "CHECKBOX":
      return <CheckBoxReadOnlyFieldRendrer value={Boolean(value)} />;
    case "DATE":
      return <DateReadOnlyFieldRendrer value={value ?? "--"} />;
    case "NUMBER":
      return <NumberFieldReadOnlyFieldRendrer value={value ?? "--"} />;
    default:
      return <TextReadOnlyFieldRendrer value={value ?? "--"} />;
  }
};

export const sampleDataColDefs: TableProps<SampleDataRow>["tableColumns"] = [
  {
    field: "id",
    headerName: "#",
    width: 40,
  },
  {
    field: "value",
    headerName: "Sample Data",
    flex: 1, // Automatically adjusts width
  },
];

export default InformationReadOnlyFieldRendrer;
