import { NodeType } from "../../../../app.types";
import { CoreNodesCircleIcon } from "../../../../components";
import {
  MassEditableFieldValueTypeResponse,
  MassEditableParsedField,
} from "../../../../parsers/listingpagesparser/listingpagesparser.types";
import { chevronRight, databaseIcon } from "../../../../svgs";
import { MassUpdateOptionRendrerStyled } from "./massupdateformitem.styles";

const getNodeIcon = (nodeType: NodeType): JSX.Element => (
  <CoreNodesCircleIcon
    nodeType={nodeType}
    mode="tertiary"
    width="15px"
    height="15px"
    fontSize="9px"
  />
);

export function massUpdateItemOptionRendrer(
  item: MassEditableParsedField["field_data"][number]
): JSX.Element {
  const parentIcon: {
    [key in MassEditableFieldValueTypeResponse]: JSX.Element;
  } = {
    CAT: getNodeIcon("DMN"),
    COL: getNodeIcon("TBL"),
    TBL: getNodeIcon("DTB"),
    TRM: getNodeIcon("CAT"),
  };

  const rootIcon: {
    [key in MassEditableFieldValueTypeResponse]: JSX.Element;
  } = {
    CAT: <div />,
    COL: getNodeIcon("DTB"),
    TBL: <div />,
    TRM: getNodeIcon("DMN"),
  };

  const valueIcon: {
    [key in MassEditableFieldValueTypeResponse]: JSX.Element;
  } = {
    CAT: getNodeIcon("CAT"),
    COL: getNodeIcon("COL"),
    TBL: getNodeIcon("TBL"),
    TRM: getNodeIcon("TRM"),
  };

  return (
    <MassUpdateOptionRendrerStyled>
      {item?.root_name && (
        <>
          <div className="field-value-icon">{rootIcon?.[item?.value_type]}</div>
          <div className="field-option-value" title={item?.root_name}>
            {item?.root_name}
          </div>
          <div className="right-icon">{chevronRight}</div>
        </>
      )}
      {item?.parent_name && (
        <>
          <div className="field-value-icon">
            {parentIcon?.[item?.value_type]}
          </div>
          <div className="field-option-value" title={item?.parent_name}>
            {item?.parent_name}
          </div>
          <div className="right-icon">{chevronRight}</div>
        </>
      )}
      <>
        {item?.is_multi_level_option && (
          <div className="field-value-icon">
            {valueIcon?.[item?.value_type]}
          </div>
        )}
        <div className="field-option-value" title={item?.value_name}>
          {item?.value_name}
        </div>
      </>
    </MassUpdateOptionRendrerStyled>
  );
}
