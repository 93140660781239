import { useMemo } from "react";

import { getBuisenessRulesForTerm } from "../../sectionwitheditablebusinessrules.utils";
import { BusinessRuleEditViewProps } from "./businessruleeditableview.types";

import { BusinessRuleEditViewStyled } from "./businessruleeditableview.styles";
import { CollapseItem } from "./businessruleeditableview.components";
import { BuisinessRulesValuesKeys } from "../../sectionwitheditablebuisinessrules.types";

function BusinessRuleEditView(props: BusinessRuleEditViewProps): JSX.Element {
  const { state, termType, setState } = props;

  const rulesConfig = useMemo(() => getBuisenessRulesForTerm(termType, state), [
    props,
  ]);

  return (
    <BusinessRuleEditViewStyled>
      {Object.keys(rulesConfig)?.map((item) => (
        <div className="collapse-container" key={item}>
          <CollapseItem
            rulesConfig={rulesConfig}
            currentItem={item as BuisinessRulesValuesKeys}
            state={state}
            termType={termType}
            setState={setState}
          />
        </div>
      ))}
    </BusinessRuleEditViewStyled>
  );
}

export default BusinessRuleEditView;
