import { AssetStyledWrapperStyled } from "./assetstyledwrapper.styles";
import { AssetStyledWrapperProps } from "./assetstyledwrapper.types";

const AssetStyledWrapper = (props: AssetStyledWrapperProps): JSX.Element => {
  const { isGridExpanded = false, children } = props || {};

  return (
    <AssetStyledWrapperStyled
      className="AssetStyledWrapperStyled"
      isGridExpanded={isGridExpanded}
    >
      {children}
    </AssetStyledWrapperStyled>
  );
};

export default AssetStyledWrapper;
