import LinkButton from "../linkbutton";
import {
  crossIconInCircle,
  editPencilIcon,
  tickIconInCircle,
} from "../../svgs";
import {
  EditStyled,
  FilterStyled,
  SectionTitleWithIconStyled,
} from "./sectiontitlewithicon.syles";
import { SecTitleProps } from "./sectiontitlewithicon.types";

function SectionTitleWithIcon(props: SecTitleProps): JSX.Element {
  const {
    title,
    icon,
    paddingBottom,
    marginBottom,
    isShowIcon = true,
    fontFamily,
    onEdit,
    onSave,
    onCancel,
    isEditable = false,
    isShowSaveIcon = true,
    fontSize,
    showBorderBottom,
    className = "",
    marginTop,
    minHeight,
    isDisabledSaveButton,
    isDisabledEditButton,
    rightSec,
    disabledSaveBtnTooltipProps = {
      title: "Some fields are not fulfilling the validation",
      placement: "topRight",
    },
    disabledEditBtnTooltipProps = {
      title: "System created terms cannot be edited",
      placement: "topRight",
    },
    fontWeight = 600,
    id = "",
    elemIdOfEdit = "",
    elemIdOfSave = "",
    showHeader = false,
  } = props;
  return (
    <SectionTitleWithIconStyled
      paddingBottom={paddingBottom}
      marginBottom={marginBottom}
      fontFamily={fontFamily}
      className={className}
      fontSize={fontSize}
      showBorderBottom={showBorderBottom ?? true}
      marginTop={marginTop}
      minHeight={minHeight}
      fontWeight={fontWeight}
    >
      <div className="title" id={id}>
        {title}
        {isShowIcon && !isEditable && showHeader && (
          <EditStyled>
            <LinkButton
              type="link"
              onClick={onEdit}
              height="19px"
              disabled={isDisabledEditButton}
              tooltipProps={
                isDisabledEditButton ? disabledEditBtnTooltipProps : undefined
              }
              elementId={elemIdOfEdit}
            >
              {icon || editPencilIcon()}
            </LinkButton>
          </EditStyled>
        )}
      </div>

      {isShowIcon &&
        (isEditable ? (
          <div className="icons-wrapper">
            <LinkButton type="link" onClick={onCancel} height="30px">
              {crossIconInCircle()}
            </LinkButton>
            {isShowSaveIcon && (
              <LinkButton
                type="link"
                onClick={onSave}
                marginLeft="5px"
                height="30px"
                disabled={isDisabledSaveButton}
                tooltipProps={
                  isDisabledSaveButton ? disabledSaveBtnTooltipProps : undefined
                }
                elementId={elemIdOfSave}
              >
                {tickIconInCircle()}
              </LinkButton>
            )}
          </div>
        ) : (
          <>
            <FilterStyled showHeader>
              {showHeader && rightSec && rightSec}
            </FilterStyled>
            {!showHeader && (
              <LinkButton
                type="link"
                onClick={onEdit}
                height="19px"
                disabled={isDisabledEditButton}
                tooltipProps={
                  isDisabledEditButton ? disabledEditBtnTooltipProps : undefined
                }
                elementId={elemIdOfEdit}
              >
                {icon || editPencilIcon()}
              </LinkButton>
            )}
          </>
        ))}
      {!isShowIcon && rightSec && rightSec}
    </SectionTitleWithIconStyled>
  );
}

export default SectionTitleWithIcon;
