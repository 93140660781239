import styled from "styled-components";

import { hexToRgbA } from "../../../../../utils";

export const ColumnEditViewStyled = styled.div<{ hasScroll: boolean }>`
  padding: 25px 29.5px 29.8px 30px;
  border: solid 1px ${({ theme }): string => theme.borderColors.GREY_110};
  background-color: ${({ theme }): string => theme.borderColors.BLUE_11};

  .column-edit-view-content {
    background-color: ${({ theme }): string => theme.borderColors.WHITE_COLOR};

    .column-edit-header {
      padding: 15px 12px 15px 30px;
      display: grid;
      grid-template-columns: 10% 15% 15% 5% 15% 20% 20%;
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      font-family: OpenSansSemiBold;
      font-size: 15px;
      overflow-y: ${({ hasScroll }): any => hasScroll && "scroll"};
      border-bottom: 1px solid
        ${({ theme }): string =>
          hexToRgbA(theme.borderColors.LIGHT_GREY_11, 0.2)};

      .column-header-is-col-cde,
      .column-header-is-col-attr {
        padding-left: 12px;
      }

      ::-webkit-scrollbar {
        background: white;
      }
    }

    .column-edit-body {
      max-height: 720px;
      overflow: auto;
      height: 720px;
    }

    .column-edit-footer {
      border-bottom: 1px solid
        ${({ theme }): string =>
          hexToRgbA(theme.borderColors.LIGHT_GREY_11, 0.2)};
      padding: 16px 17px 15px 17px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

export const ColumnRowStyled = styled.div<{
  isFocused: boolean;
}>`
  padding: 16px 12px 18px 30px;
  display: grid;
  grid-template-columns: 10% 15% 15% 5% 15% 20% 20%;

  :not(:last-of-type) {
    border-bottom: 1px solid
      ${({ theme }): string => hexToRgbA(theme.borderColors.LIGHT_GREY_11, 0.2)};
  }

  > div {
    padding-right: 10px;
  }

  .col-org-name {
    color: ${({ theme }): string => theme.textColors.GREY_210};
    margin-top: 6px;
    font-size: 13px;
  }

  .entity-selection {
    .ant-btn-link {
      width: 71%;
    }
  }

  .is-cde-col {
    padding-left: 27px;
  }

  .is-attr-col {
    .ant-select {
      width: 85%;
    }
  }

  ${({ isFocused, theme }): any =>
    isFocused &&
    `background-color: ${theme.borderColors.BLUE_23};
  }`}
  :hover {
    background-color: ${({ theme }): string => theme.borderColors.BLUE_23};
  }

  .information-sec {
    .datatype-icon {
      margin-bottom: 3px;
      svg {
        path {
          fill: ${({ theme }): string => theme.bgColors.LIGHT_GREY_5};
        }
      }
    }
    .entity-type,
    .min-max-value {
      margin-bottom: 3px;
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      margin-right: 20px;
      word-break: break-all;
    }
  }

  .column-edit-actions {
    font-size: 13px;
    font-style: italic;
    padding-top: 5px;

    .edited {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      > div {
        margin-bottom: 4px;
      }
    }
  }

  > div {
    position: relative;
    .error {
      left: 0;
      right: unset;
      text-align: left;
    }
  }
`;
