import { NodeSubType, NodeType, SourceTypes } from "../../app.types";
import { ProminentTagsType } from "../../components/genericprominenttag/genericprominenttag.types";

import { LineagePreviewerProps } from "../../components/lineagepreviewer/lineagepreviewer.types";

import { PopularityScoreProps } from "../../components/popularityscore/popularityscore.types";
import { ProminentTagsProps } from "../../components/prominenttagslist";

import {
  SectionWithEditableAdditionalinfoProps,
  SectionWithEditableSourcesProps,
  SectionWithEditableState,
} from "../../components/sectionwitheditables";

import { SectionWithEditableBuisenessRulesProps } from "../../components/sectionwitheditables/sectionwitheditablebuisinessrules/sectionwitheditablebuisinessrules.types";
import { SectionWithEditableLinkedEntityProps } from "../../components/sectionwitheditables/sectionwitheditablelinkedentity/sectionwitheditablelinkedentity.types";

import { SimalrAssetCardProps } from "../../components/similarassetcard/similarassetcard.types";
import { BreadcrumbItemType } from "../../components/styledbreadcrum";

import { BreadcrumItemStringType } from "../../components/styledbreadcrum/styledbreadcrum.types";
import { APIKeys } from "../../constants/constant.types";

import { AssetRelationshipContextStateType } from "../../contexts";
import { ColumnPageParamViewKeys } from "../../pages/columndetailpage";

import { EtlPageViewKeys } from "../../pages/etldetailpage/etldetailpage.types";
import { RulePageViewKeys } from "../../pages/ruledetailpage";

import { SourceDetailPageParamViewKeys } from "../../pages/sourcedetailpage";
import { TablePageParamViewKeys } from "../../pages/tablepage/tablepage.types";

import {
  ColumnChartDataType,
  ColumnDataType,
  MatchingStaus,
  SampleDataHeaderChart,
} from "../columnparsers";

import {
  AssetBuisinessRulesValues,
  ClassificationEntityValueType,
  ClassificationsType,
  CustomDataSetTag,
  lineageDocResponse,
  NodePublishedStatusIds,
  RepressentsRespDataItemType,
} from "../parser.types";

import {
  JobSchedulersParsedType,
  RuleExecInfoType,
  ScheduledJonsResponseType,
} from "../ruleparser/ruleparser.types";

import { TablePopularityInfoType } from "../tablepage";
import { TermType } from "../termsparser";

export type AssetComputedNodeType = Extract<
  NodeType,
  "COL" | "DSR" | "TBL" | "TRM" | "RPT" | "RLS" | "RFD" | "DSF" | "PLO"
>;

export type AssetInputType =
  | "text"
  | "select"
  | "multiselect"
  | "textarea"
  | "input"
  | "checkbox"
  | "entitySelect"
  | "columnTypeSelect"
  | "autocomplete";

export enum AssetSectionName {
  description = "Description",
  managedBy = "Managed By",
  lineage = "Lineage",
}

export enum AssetFieldIds {
  usabilityScore = "usability_score",
  businessName = "title",
  definition = "definition",
  description = "description",
  filePath = "file_path",
  functionalArea = "functional_area",
  entity = "entity",
  tblAndSrcs = "table_and_sources",
  scanFrequency = "scan_frequency",
  overAllScore = "overall_score",
  lastRefreshedOn = "last_refreshed_on",
  lastRun = "last_run",
  lastScannedOn = "last_scanned_on",
  lastUpdatedOn = "last_updated_on",
  imagePreview = "image_preview",
  shortName = "short_name",
  replacedTermsDeprecated = "replaced_terms_deprecated",
  longName = "title",
  termCategory = "term_category",
  termType = "term_type",
  formula = "formula",
  previouslyKnownAs = "previously_known_as",
  replaces = "replaces",
  replacedBy = "replaced_by",
  linkedColumns = "linked_columns",
  dataType = "data_type",
  example = "example",
  columnType = "column_type",
  columnSubType = "column_sub_type",
  isCDE = "is_cde",
  dataDomain = "data_domain",
  subDomain = "sub_domain",
  businessRules = "business_rules",
  colDataDomain = "column_data_domain",
  auditTrail = "audit_trail",
  nodeUrl = "node_url",
  min_max = "min_max",
  count = "count",
  recordCount = "record_count",
  visualDistribution = "visual_distribution",
  patternDistribution = "pattern_distribution",
  ruleType = "rule_type",
  runResult = "run_result",
  dqScore = "dq_score",
  workflowStatus = "workflow_status",
  activity = "activity",
  dataSource = "data_source",
  tableName = "table_name",
  fieldName = "field_name",
  minValue = "min_value",
  maxValue = "max_value",
  dataSteward = "data_stewards",
  dataOwner = "data_owners",
  systemOwner = "system_owners",
  isKeyValuePair = "is_key_value_pair",
  isHierarchial = "is_hierarchial",
  relationships = "relationships",
  ploTarget = "target",
  ploSource = "source",
  jobType = "job_type",
  jobName = "job_name",
  parentName = "parent_name",
  duration = "duration",
  runStatus = "run_status",
  none = "",
}

export type AssetFieldConfigType = {
  title: string;
  id: string;
  path?: string;
  idPath?: string;
  type?: AssetInputType;
  isEditable?: boolean;
  isRowEditableInQuickEdit?: boolean;
  isRowVisibleInEditView?: boolean;
  isRowVisibleInNormalView?: boolean;
  isRowVisibleInRefViewOnly?: boolean;
  isRowHiddenInRefViewOnly?: boolean;
  isShowInfoMsg?: boolean;
  apiKey?: APIKeys;
  urlParam?: string[];
  isDataStewardField?: boolean;
  placeHolder?: string;
  placeHolderForSearch?: string;
  isTimeField?: boolean;
  isInheritedPath?: string;
  isInheritedByPath?: string;
  alternatePath?: string;
  isColLevel?: boolean;
  isLineageField?: boolean;
  isRequired?: boolean;
  sequence: number;
  isRowVisibleInCompareChangesView?: boolean;
  isProfilingField?: boolean;
  hasMaxLimit?: boolean;
};

export type ConfigBreadcrumbItemType = {
  type: BreadcrumItemStringType;
  path: string;
  idPath?: string;
};

export type AssetConfigItemType = {
  sectionName: string;
  sectionId?: string;
  rowId?: number;
  fields?: AssetFieldConfigType[];
  items?: ConfigBreadcrumbItemType[];
  isApplicableOnDetailPage?: boolean;
  isApplicableOnRefPage?: boolean;
  isEdited: boolean;
  isEditable: boolean;
  detailPageSequence: number;
  isFullSpan?: boolean;
  nodeNameKey?: string;
  nodeIdKey?: string;
  parentIdKey?: string;
  parentNameKey?: string;
  rootIdKey?: string;
  rootNameKey?: string;
  nodeType?: NodeType;
  nodeSubType?: NodeSubType;
  refPageSequence?: number;
  descKey?: string;
  descIdKey?: string;
  isSectionHasEditAction?: boolean;
  isPartOfUsabilityScore?: boolean;
  isSingleItemInRow?: boolean;
  isAlwaysOnRightSide?: boolean;
  sectionNameInCompareChangesView?: string;
  sectionTitleInCompareChangesView?: string;
};

export type AssetConfigType = {
  [key: string]: AssetConfigItemType;
};

export type ManagedByObjKeys =
  | "data_owners"
  | "data_stewards"
  | "system_owners";

export type ManagedByKeys =
  | "data_owner_id"
  | "data_steward_id"
  | "system_owner_id"
  | "data_owner_name"
  | "data_steward_name"
  | "system_owner_name"
  | "data_owner_email"
  | "data_steward_email"
  | "system_owner_email";

export type ManagedByUserType = "USR" | "UGP";

export type ManagedByItem =
  | {
      data_owner_id: string;
      data_owner_name: string;
      data_owner_email: string;
      is_inherited?: boolean;
      inherited_from?: string;
      user_type: ManagedByUserType;
    }
  | {
      system_owner_id: string;
      system_owner_name: string;
      system_owner_email: string;
      is_inherited?: boolean;
      inherited_from?: string;
      user_type: ManagedByUserType;
    }
  | {
      data_steward_id: string;
      data_steward_name: string;
      data_steward_email: string;
      is_inherited?: boolean;
      inherited_from?: string;
      user_type: ManagedByUserType;
    };

export type ManagedByItemValueType = {
  value: ManagedByItem[];
  is_changed: boolean;
};

export type ManagedByItemKeyType = {
  [key in ManagedByObjKeys]: ManagedByItemValueType;
};

export type ReplaceResponseType = { trm_name: string; term_id: string };
export type ReplaceType = { name: string; id: string };

export type AssetAuditTrailType = {
  user_id: number;
  user_name: string;
  activity: string;
  activity_on: string;
};

export type DetailsNodePathType = {
  node_id: string;
  node_name: string;
  level: string;
  node_type: NodeType;
  node_sub_type: SourceTypes;
};

export type NodePathType = {
  NODE_ID: string;
  NODE_NAME: string;
  LEVEL: string;
  NODE_TYPE: NodeType;
  NODE_SUB_TYPE: SourceTypes;
};

export type SimilarAssetParsedNodePathType = DetailsNodePathType[];

export type RelationshipPayloadEntry = {
  src_node_id: string;
  src_node_type: string;
  tgt_node_id: string;
  tgt_node_type: string;
  rel_type_id: string;
  rel_type: string;
  node_rel_status: string;
  comments: string;
  additional_info: {
    source: string;
  };
  src_col_name?: string;
  tgt_col_name?: string;
  action: string;
};

export type RelationshipsPayloadType = {
  is_changed: boolean;
  value: RelationshipPayloadEntry[];
};

export type AssetPathNodeSubType = SourceTypes | NodeSubType;

export type AssetPath = {
  level: number;
  node_id: string;
  node_name: string;
  node_sub_type?: AssetPathNodeSubType;
  node_type: NodeType;
  is_approved?: boolean;
  is_workflow_enabled?: boolean;
  node_title?: string;
}[];

export type InheritInfoType = {
  inherited_from_id?: string;
  is_inherited?: boolean;
  inherited_from?: string;
};

export type LineageNodeType = {
  node_id: number;
  node_name: string;
  node_sub_type: NodeSubType;
  node_type: NodeType;
  parent_id: string;
  parent_name: string;
  is_placeholder: boolean;
};

export type AssetDocumentResponseType = {
  account_id: number;
  physical_name: string;
  node_url: string;
  node_image_url: string;
  is_live_connection: boolean;
  last_refreshed_on: string;
  last_updated_on: string;
  business_rules: {
    value: AssetBuisinessRulesValues;
    is_changed: boolean;
  };
  profiling_info: {
    count: {
      blanks_count: number;
      record_count: number;
      distinct_count: number;
    };
    min_max: string;
    distribution: SampleDataHeaderChart;
  };
  overview?: {
    description: {
      value: string;
      is_changed: boolean;
      is_inherited?: boolean;
    };
  };
  description: {
    example?: string;
    is_col_nullable?: boolean;
    is_col_pk?: boolean;
    column_position?: number;
    col_length?: number;
    col_sequence?: number;
    data_type?: string;
    col_data_precision?: number;
    data_type_id?: ColumnDataType;

    is_replace_terms_dep?: {
      value: boolean;
      is_changed: boolean;
    };

    metadata_score?: {
      value: number;
      is_changed: boolean;
    };
    description: {
      value: string;
      is_changed: boolean;
      is_inherited?: boolean;
    };
    audit_trail: AssetAuditTrailType[];

    term_type?: {
      value: {
        term_type_id: TermType;
        term_type_name: string;
      };
      is_changed: boolean;
    };

    replaces?: {
      value: ReplaceResponseType[];
      is_changed: boolean;
    };
    replaced_by?: {
      value: ReplaceType[];
      is_changed: boolean;
    };
    term_formula?: { value: string; is_changed: boolean };
    term_category?: {
      value?: {
        category_id?: number;
        category_name?: string;
      };
      is_changed: boolean;
    };
    previously_known_as?: {
      value: string;
      is_changed: boolean;
    };
    term_name?: {
      value: string;
      is_changed: boolean;
    };

    definition?: {
      value: string;
      is_changed: boolean;
    };
  };
  lineage: lineageDocResponse;
  get_access_info: {
    value: string;
    is_changed: boolean;
    is_inherited: boolean;
  };
  trm_source_id: string;
  is_workflow_enabled?: boolean;
  is_dq_enabled: boolean;
  node_type: NodeType;
  node_sub_type: NodeSubType;
  is_editable?: boolean;
  node_path?: AssetPath;
  is_draft_exists: boolean;
  is_wf_enabled: boolean;
  is_predefined: boolean;
  is_approver?: boolean;
  endorsed_count: number;
  endorsed_status: {
    value: boolean;
    is_changed: boolean;
  };
  viewed_count: number;
  // appearing twice for diff nodes. get it checked
  record_count: number;
  title: {
    value: string;
    is_changed: boolean;
  };
  published_status: string;
  published_status_id: NodePublishedStatusIds;
  additional_info: {
    value: { Link?: string; Image?: any; Text?: string }[];
    is_changed: boolean;
  };
  classifications: ClassificationsType;
  node_name: string;
  node_desc: string;
  node_id: number;
  source_id: number;
  source_name: string;
  src_type_id: SourceTypes;
  db_name: string;
  folder_name: string;
  parent_id: number;
  parent_name: string;
  managed_by: ManagedByItemKeyType;
  provenance_scan: {
    provenance: {
      value: RepressentsRespDataItemType[];
      is_changed: boolean;
    };
    scan_frequency: string;
  };

  tbl_popularity_id: string;
  tbl_popularity: string;
  tbl_popularity_info: TablePopularityInfoType;

  scheduled_jobs: {
    is_changed: boolean;
    value: Array<ScheduledJonsResponseType>;
  };

  rule_exec_info: RuleExecInfoType;

  plo_code_s3_url?: string;
  plo_code?: string;
  plo_sources: LineageNodeType[];
  plo_targets: LineageNodeType[];
  relationships: RelationshipsPayloadType;
};

export type ParsedAssetDocKeys =
  | "description"
  | "managed_by"
  | "additional_info"
  | "classification"
  | "business_rules"
  | "lineage"
  | "represents"
  | "data_quality"
  | "linked_entity"
  | "provinance_data";

export type AssetDocumenttParsedType = {
  root_name: string;
  root_id: string;
  parent_id: string;
  parent_name: string;
  node_name: string;
  node_desc: string;
  node_system_generated_desc?: string;
  node_title: string;
  node_id: string;
  node_type: NodeType;
  node_sub_type: NodeSubType;
  node_url: string;
  is_editable: boolean;
  source_name: string;
  is_draft_exist: boolean;
  is_endorsed: boolean;
  description: SectionWithEditableState["state"];
  custom_definition: SectionWithEditableState["state"];
  managed_by: SectionWithEditableState["state"];
  additional_info: SectionWithEditableAdditionalinfoProps["state"];
  classification: SectionWithEditableState["state"];
  business_rules: SectionWithEditableBuisenessRulesProps["state"];
  lineage: LineagePreviewerProps;
  represents: SectionWithEditableSourcesProps["state"];
  data_quality: SectionWithEditableState["state"];
  linked_entity: SectionWithEditableLinkedEntityProps["state"];
  get_access_info: string;
  is_deprecated: boolean;
  endorsed_count: number;
  publish_status: string;
  publish_status_id: NodePublishedStatusIds;
  is_wf_enabled: boolean;
  is_dq_enabled: boolean;
  last_refreshed_on: string;
  last_updated_on: string;
  is_profiled: boolean;
  last_profiled_on: string;
  is_primary: boolean;
  min_max: string;
  is_nullable: boolean;
  technical_data_type: string;
  column_position: number;
  size: number;
  fractional_digits: number;
  prominent_tags: ProminentTagsProps;
  status_tag: ProminentTagsProps;
  provinance_data: SectionWithEditableSourcesProps["state"];
  src_type_id: SourceTypes;
  db_name: string;
  schema_name: string;
  source_id: string;
  is_predefined: boolean;
  is_approver: boolean;
  popularity_score: PopularityScoreProps;
  entity_id: string;
  is_col_desc_inherited: boolean;

  code_s3_url: string;
  code: string;
  relationships?: AssetRelationshipContextStateType;
  term_type: TermType;
  trm_source_id: string;
  page_breadcrumb: {
    data: BreadcrumbItemType[];
  };

  job_schedulers: JobSchedulersParsedType;
};

export type BusinessRulesValueType =
  | string
  | string[]
  | { min: string; max: string };

export type BusinessRulesMappedType = Record<
  keyof AssetBuisinessRulesValues,
  BusinessRulesValueType
>;

export type AssetProfilingInfoParsedType = {
  profiling_info: SectionWithEditableState["state"];
};

export type AssetKeys = keyof AssetDocumentResponseType;

export type AssetVisDisAdditionalInfo = {
  visualDistributionChartData: ColumnChartDataType;
  colDataTypeId: ColumnDataType;
};

export type ColumnAssetChartDataType = {
  name: string;
  Count: number;
  fill: string;
  isCustomData?: boolean;
}[];

export type RuleOnColsType = {
  colName: string;
  coldId: string;
};

export type SimilarAssetParsedDescriptionType = {
  audit_trail: AssetAuditTrailType[];
  description: {
    value: string;
    is_changed: boolean;
    is_inherited?: boolean;
  };
  metadata_score: {
    value: string;
    is_changed: boolean;
    is_inherited?: boolean;
  };
};

export type SimilarAssetParsedManagedByType = {
  data_owners: {
    value: {
      data_owner_id: string;
      data_owner_name: string;
      data_owner_email: string;
      is_inherited?: boolean;
      inherited_from?: string;
      user_type: ManagedByUserType;
    }[];
  };
};

export type SimilarAssetsResponse = {
  NODE_ID: number;
  NODE_NAME: string;
  NODE_TYPE: NodeType;
  NODE_SUB_TYPE?: NodeSubType;
  DESCRIPTION: string;
  MANAGED_BY: string;
  NODE_PATH: string;
  NODE_TITLE: string;
};

export type SimilarNodesParserReturnType = SimalrAssetCardProps["data"];

export type AssetUsabilityScoreType = {
  totalScore: number;
  obtainedScore: number;
  data: { title: string; applied: boolean; isLyinginSec1: boolean }[];
};

export type AssetConfigAdhocInfoType = {
  is_editable: boolean;
  is_mandatory: boolean;
  src_col_name: string;
  tgt_col_name: string;
  is_asset_path_required: boolean;
  is_multi_value_allowed: boolean;
};

export type AssetsConfigurationsListResponseType = {
  nodes_relations_configs: {
    SRC_NODE_TYPE_ID: NodeType;
    SRC_NODE_TYPE: string;
    TGT_NODE_TYPE_ID: NodeType;
    TGT_NODE_TYPE: string;
    REL_TYPE_ID: string;
    SRC_REL_TYPE: string;
    TGT_REL_TYPE: string;
    REF_ADHOC_CONFIG: string;
  }[];
};

export interface RefAdhocConfigType {
  is_editable?: boolean;
  is_multi_value_allowed?: boolean;
  is_mandatory?: boolean;
  src_col_name?: string;
  tgt_col_name?: string;
  is_asset_path_required?: boolean;
  is_inherited?: boolean;
  is_inherited_by?: string;
}

export type ReturnTypeOfAssetRelationshipsList = Array<{
  id: string;
  nodeType: string;
  assetTypeId: string;
  isInherited?: boolean;
  isInheritedBy?: string;
  target_asset_type: string;
  relationship_type: string;
  relationship_type_id: string;
  isEditable: boolean;
  isMultiValued: boolean;
  isMandatory?: boolean;
  srcColName?: string;
  tgtColName?: string;
  isAssetPathRequired: boolean;
}>;

// TODO: update these on API Integration
export type RelationshipAdditionalInfoType = {
  source?: string;
  entity_matching_score?: number;
  entity_selection_status?: MatchingStaus;
  entity_json?: Array<{
    entity_id?: string;
    entity_desc?: string;
    entity_name?: string;
    entity_status?: string;
    entity_status_id?: string;
    entity_match_source?: string;
    entity_matching_score?: number;
    entity_selection_status?: string;
  }>;
};

export type AssetRelationshipsDataResponseType = {
  SRC_NODE_ID: number;
  SRC_NODE_TYPE_ID: NodeType;
  SRC_NODE_TYPE: string;
  TGT_NODE_ID: string;
  TGT_NODE_TYPE_ID: NodeType;
  TGT_NODE_NAME: string;
  TGT_NODE_TYPE: string;
  TGT_NODE_SUB_TYPE: string;
  TGT_NODE_TITLE: string;
  TGT_NODE_PATH: string;
  REL_TYPE_ID: string;
  SRC_REL_TYPE: string;
  TGT_REL_TYPE: string;
  COMMENTS: string;
  TGT_NODE_DESC: string;
  IS_WF_ENABLED: boolean;
  INHERIT_INFO: string;
  ADDITIONAL_INFO: string;
}[];

export type ReturnTypeOfAssetRelationshipsData = Array<{
  id: string;
  nodeType: NodeType;
  nodeSubType?: string;
  sectionId: string;
  name: string;
  description: string;
  comment: string;
  nodePath?: AssetPath;
  assetType: string;
  source: string;
  relationshipType: string;
  relationshipTypeId: string;
  isInherited?: boolean;
  isInheritedBy?: string;
  isMetadataWorkflowEnabled?: boolean;
  isWorkflowEnabled?: boolean;
  matchingScore?: number;
  matchingStatus?: MatchingStaus;
  srcColName?: string;
  tgtColName?: string;
  mode: string;
  isDeleted: boolean;
  isChanged: boolean;
  matchingEntities?: Array<{
    matching_entity: string;
    matching_entity_id: string;
    samples: string;
    matching_score: number;
    match_source: string;
    selected: boolean;
    entity_status: string;
    entity_status_id: string;
  }>;
}>;

export type NodeParentalInfoResponse = {
  NODE_ID: number;
  NODE_NAME: string;
  PARENT_ID: string;
  PARENT_NAME: string;
  ROOT_PARENT_NAME: string;
  PARENT_TYPE: SourceTypes;
  ROOT_PARENT_ID: string;
  PUBLISHED_STATUS: string;
  PUBLISHED_STATUS_ID: string;
  DESCRIPTION: string;
  NODE_TITLE: string;
  FOLDER_NAME: string;
  FOLDER_ID: string;
  SRC_TYPE_ID: SourceTypes;
  DB_NAME: string;
  DB_ID: string;
  NODE_PATH: string;
  NODE_TYPE: NodeType;
  NODE_SUB_TYPE: NodeSubType;
  IS_WF_ENABLED: boolean;
};

export type AssetTagContextResponse = {
  NODE_ID: number;
  NODE_NAME: string;
  PARENT_ID: string;
  PARENT_NAME: string;
  IS_NEW_VALUE_ALLOWED: boolean;
  IS_MULTI_VALUE_ALLOWED: boolean;
  NODE_DESC: string;
  IS_PROMINENT: boolean;
  COLOR_CODE: ProminentTagsType;
};

export type ProvenanceReturnType = {
  dbName: string;
  dbId?: string;
  tblName: string;
  tblId: string;
  isRefView: boolean;
  isTblSelection: boolean;
  schemaName: string;
  dbSrcTypeId: SourceTypes;
  sourceName: string;
  sourceId: string;
};

export type RelationShipCompareItem = {
  id: string;
  comment: string;
  tgtRelType: string;
  tgtNodeType: string;
  path: AssetPath;
  tgtNodeTitle: string;
  relTypeId: string;
};

export type SourcesReturnType = ProvenanceReturnType & {
  colName: string;
  colId: string;
};

export type ValueType = {
  tag_id: string;
  tag_name: string;
  tag_comments: string;
  tag_created_by?: string;
  tag_created_by_id?: string;
  tag_created_on?: string;
  tag_desc?: string;
};

export type ClassificationResponseType = {
  new_value: CustomDataSetTag[];
  old_value: CustomDataSetTag[];
};

export type ClassificationReturnType = {
  value: Array<ValueType>;
  newValue?: Array<ValueType>;
  oldValue?: Array<ValueType>;
  is_changed: boolean;
  tagset_id: number;
  tagset_name: string;
};

export type CurrentObj = {
  [key: number]: ClassificationReturnType;
};

export type CurrentRelationshipObj = {
  [key: string]: RelationShipCompareItem[];
};

export type WorkflowTemplateResponse = {
  WT_ID: number;
  WT_NAME: string;
};

export type WorkflowResponse = {
  WF_CURR_ASSIGNED_USERS: string;
  WF_STEPS: string;
  WF_STEPS_STATUS: string;
  WF_ID: number;
  WT_ID: number;
};

export type StepEvent = "SUB" | "RSB" | "CSB";
export type StepType = "CED" | "APR" | "ABP";

export type StepUser = {
  user_id: number;
  user_name: string;
  user_status: string;
  user_email: string;
  updated_on: string;
  user_action: "CRT" | "UPD";
};

export type WorkFlowStepResponse = {
  all_users_acklg_required: boolean;
  step_desc: string;
  step_name: string;
  step_num: number;
  step_status: string;
  step_type: StepType;
  updated_on: string;
  step_users: Array<StepUser>;
  step_event: StepEvent;
};

export type DescriptionResponseType = {
  new_value: string;
  old_value: string;
};

export type TermNameResponseType = {
  new_value: string;
  old_value: string;
};

export type IsCdeResponseType = {
  new_value: boolean;
  old_value: boolean;
};

export type EntityResponseType = {
  new_value: ClassificationEntityValueType;
  old_value: ClassificationEntityValueType;
};

export type TermTypeResponse = {
  new_value: {
    value: {
      term_type_name: string;
      term_type_id: TermType;
    };
  };
  old_value: {
    value: {
      term_type_id: TermType;
      term_type_name: string;
    };
  };
};

export type DataStewardsArray = {
  value: Array<{ data_steward_name: string }>;
};
export type DataOwnersArray = {
  value: Array<{ data_owner_name: string }>;
};

export type SystemOwnersArray = {
  value: Array<{ system_owner_name: string }>;
};

export type RelationShipAdditionalInfoType = {
  entity_matching_score: number;
  entity_selection_status: string;
  source: string;
};

export type RelationshipItemType = {
  ADDITIONAL_INFO: string;
  COMMENTS: string;
  NODE_REL_STATUS: string;
  REL_TYPE_ID: string;
  SRC_NODE_ID: number;
  SRC_NODE_TYPE: string;
  SRC_NODE_TYPE_ID: NodeType;
  SRC_REL_TYPE: string;
  TGT_NODE_DESC: string;
  TGT_NODE_ID: number;
  TGT_NODE_NAME: string;
  TGT_NODE_PATH: string;
  TGT_NODE_SUB_TYPE: string;
  TGT_NODE_TITLE: string;
  TGT_NODE_TYPE: string;
  TGT_NODE_TYPE_ID: NodeType;
  TGT_REL_TYPE: string;
  TGT_NODE_STATUS: NodePublishedStatusIds;
  IS_WF_ENABLED: boolean;
};

export type RelationshipResponse = {
  new_value: RelationshipItemType[];
  old_value: RelationshipItemType[];
};

export type ManagedByResponse = {
  new_value: {
    data_stewards: DataStewardsArray;
    data_owners: DataOwnersArray;
    system_owners: SystemOwnersArray;
  };
  old_value: {
    data_stewards: DataStewardsArray;
    data_owners: DataOwnersArray;
    system_owners: SystemOwnersArray;
  };
};

export type LinkedColumn = Array<{
  col_id: string;
  col_name: string;
  src_id: string;
  src_name: string;
  tbl_id: string;
  tbl_name: string;
  db_name: string;
  folder_name: string;
  src_type_id: SourceTypes;
}>;

export type LinkedColumnResponseType = {
  new_value: { value: LinkedColumn };
  old_value: { value: LinkedColumn };
};

export type AdditionalInfoResponseType = {
  new_value: { Link?: string; Image?: any; Text?: string }[];
  old_value: { Link?: string; Image?: any; Text?: string }[];
};

export type BuisenessRulesReviewChangesResponseType = {
  old_value: AssetBuisinessRulesValues;
  new_value: AssetBuisinessRulesValues;
};

export type ProvenanceScanItems = {
  node_id: number;
  node_name: string;
  node_type: NodeType;
  parent_id: string;
  parent_name: string;
  root_id?: string;
  root_name?: string;
  folder_name: string;
  folder_id: string;
  src_type_id: SourceTypes;
  db_name: string;
  db_id: string;
  node_path?: AssetPath;
}[];

export type ProvenanceScan = {
  new_value: ProvenanceScanItems;
  old_value: ProvenanceScanItems;
};

export type AssetReviewChangesResponse = {
  description: DescriptionResponseType;
  term_type: TermTypeResponse;
  managed_by: ManagedByResponse;
  classifications: ClassificationResponseType;
  linked_columns: LinkedColumnResponseType;
  additional_info: AdditionalInfoResponseType;
  business_rules: BuisenessRulesReviewChangesResponseType;
  term_name: TermNameResponseType;
  title: TermNameResponseType;
  provenance_scan: ProvenanceScan;
  data_domain: TermNameResponseType;
  entity: EntityResponseType;
  is_col_cde: IsCdeResponseType;
  col_type: TermNameResponseType;
  col_biz_rules: TermNameResponseType;
  relationships: RelationshipResponse;
};

export type RelationshipResultType = {
  oldValue: RelationshipItemType[];
  newValue: RelationshipItemType[];
  isChanged: boolean;
};

// Define a flexible type to handle dynamic keys
export type RelationshipChanges = {
  [key: string]: RelationshipResultType;
};

export type AssetReviewChangesParsedType = Array<{
  definition: { oldValue: string; newValue: string; isChanged: boolean };
  name: { oldValue: string; newValue: string; isChanged: boolean };
  title: { oldValue: string; newValue: string; isChanged: boolean };
  isCde: { oldValue: string; newValue: string; isChanged: boolean };
  colType: { oldValue: string; newValue: string; isChanged: boolean };
  colBusinessRules: { oldValue: string; newValue: string; isChanged: boolean };
  term: {
    oldValue: string;
    newValue: string;
    isChanged: boolean;
  };
  dataStewards: {
    oldValue: string[];
    newValue: string[];
    isChanged: boolean;
  };
  dataOwners: {
    oldValue: string[];
    newValue: string[];
    isChanged: boolean;
  };
  systemOwners: {
    oldValue: string[];
    newValue: string[];
    isChanged: boolean;
  };

  provenance: {
    oldValue: Array<ProvenanceReturnType>;
    newValue: Array<ProvenanceReturnType>;
    isChanged: boolean;
  };
  classification: ClassificationReturnType[];
  additionalInfoText: {
    oldValue: string[];
    newValue: string[];
    isChanged: boolean;
  };
  additionalInfoLink: {
    oldValue: string[];
    newValue: string[];
    isChanged: boolean;
  };
  businessRules: {
    categories: {
      oldValue: AssetBuisinessRulesValues["Categorical Values"];
      newValue: AssetBuisinessRulesValues["Categorical Values"];
      isChanged: boolean;
      hide: boolean;
    };
    patterns: {
      oldValue: AssetBuisinessRulesValues["Patterns"];
      newValue: AssetBuisinessRulesValues["Patterns"];
      isChanged: boolean;
      hide: boolean;
    };
    valueRange: {
      oldValue: string;
      newValue: string;
      isChanged: boolean;
      hide: boolean;
    };
    manual: {
      oldValue: AssetBuisinessRulesValues["Manual"];
      newValue: AssetBuisinessRulesValues["Manual"];
      isChanged: boolean;
      hide: boolean;
    };
  };
  entity: {
    oldValue: {
      id: string;
      title: string;
      status: string;
    };
    newValue: {
      id: string;
      title: string;
      status: string;
    };
    isChanged: boolean;
  };
  entityScore: {
    oldValue: number;
    newValue: number;
    isChanged: boolean;
  };
  entitySource: {
    oldValue: string;
    newValue: string;
    isChanged: boolean;
  };
  dataDomain: {
    oldValue: string;
    newValue: string;
    isChanged: boolean;
  };

  relationship: RelationshipChanges;
}>;

export type AssetJobExecutionDetailsType = {
  pl_name: string;
  pl_type: string;
  pl_last_exec_status: string;
  pl_last_exec_on: string;
  pl_exec_duration_secs: string;
  pl_status: string;
};

export type AssetJobSourceNodeType = {
  node_id: number;
  node_name: string;
  node_sub_type: NodeSubType;
  node_type: NodeType;
  parent_id: string;
  parent_name: string;
};

export type NodeParsedType = {
  nodeId: number;
  nodeName: string;
  nodeType: NodeType;
  nodeSubType?: NodeSubType;
};

export type AssetJobExecutionDetailsParsedType = {
  jobName: string;
  jobType: string;
  lastRun: string;
  runStatus: string;
  duration: string;
};

export type MappedFieldsType = {
  isAuditTrail: boolean;
  isDataDomainField: boolean;
  isDescField: boolean;
  isMinValueField: boolean;
  isFieldsNameField: boolean;
  isTblNameField: boolean;
  isSrcField: boolean;
  isTgtField: boolean;
};

export type NodeTypeTabMap = TablePageParamViewKeys &
  SourceDetailPageParamViewKeys &
  ColumnPageParamViewKeys &
  RulePageViewKeys &
  EtlPageViewKeys &
  string;
