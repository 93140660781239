import { APP_ROUTES } from "../constants";

export const checkIsColumnPage = (): boolean => {
  try {
    const pathname = window?.location?.pathname || "";
    return pathname.includes(APP_ROUTES.private_routes.column_detail);
  } catch (e) {
    return false;
  }
};
