import { Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  AssetSelectionFieldStyled,
  RenderTitleStyled,
} from "./assetselectionfield.styles";

import { AssetSelectionFieldProps } from "./assetselectionfield.type";
import { useDebounce } from "../../customhooks";

import { LoadingSpinerStyled } from "../../styles/app.styles";
import AutoComplete from "../autocomplete";
import AdaptiveAssetHierarchy from "../adaptiveassetheirachy/adaptiveassetheirachy";
import { useGetParentalNodeInfo } from "../../api/assetservice";

export const renderTitle = (title: string): any => (
  <RenderTitleStyled>{title}</RenderTitleStyled>
);

const AssetSelectionField = ({
  apiKey = "get_node_parental_info_by_node_type",
  urlParam = [],
  placeHolderForSearch = "",
  placeHolder = "",
  onSelect,
  addedIds = [],
  disabled,
  clearSearch,
  singleSelect,
}: AssetSelectionFieldProps): JSX.Element => {
  const [searchText, setText] = useState<string>("");
  const { parsedData, refetch, status } = useGetParentalNodeInfo(
    apiKey,
    urlParam,
    searchText
  );

  const onSearch = useCallback(
    (val) => {
      setText(val);
    },
    [searchText]
  );

  const searchDebounce = useDebounce(searchText, 500);

  useEffect(() => {
    searchDebounce && refetch();
  }, [searchDebounce]);

  useEffect(() => {
    clearSearch?.(setText);
  }, [clearSearch]);

  const updatedData = useMemo(() => {
    const isDisabled = singleSelect && addedIds.length > 0;

    return parsedData?.length
      ? [
          {
            label: renderTitle(placeHolder),
            options:
              parsedData?.map((item) => ({
                value: item?.nodeId || "",
                label: (
                  <AdaptiveAssetHierarchy
                    nodes={item?.nodePath || []}
                    mode="secondary"
                  />
                ),
                disabled: isDisabled || addedIds?.includes(item?.nodeId || ""),
              })) ?? [],
          },
        ]
      : [];
  }, [parsedData, placeHolder, addedIds, singleSelect]);

  const onSelection = useCallback(
    (id) => {
      const findedObj = parsedData?.find((item) => item?.nodeId === id);
      onSelect?.(id, findedObj);
    },
    [onSelect, parsedData]
  );

  return (
    <AssetSelectionFieldStyled>
      <AutoComplete
        placeholder={placeHolderForSearch}
        dropdownClassName="search-results-dropdown"
        options={updatedData}
        listHeight={300}
        onSelect={onSelection}
        onSearch={onSearch}
        dropdownMatchSelectWidth={700}
        notFoundContent={
          status === "loading" ? (
            <LoadingSpinerStyled>
              Data is being fetched <Spin size="small" />
            </LoadingSpinerStyled>
          ) : parsedData?.length === 0 &&
            searchText &&
            (status === "success" || status === "idle") ? (
            <LoadingSpinerStyled>No results found</LoadingSpinerStyled>
          ) : null
        }
        searchValue={searchText}
        clearSearch={(): void => setText("")}
        virtual
        value={searchText}
        singleSelect={singleSelect}
        disabled={disabled}
        marginBottom="16px"
      />
    </AssetSelectionFieldStyled>
  );
};

export default AssetSelectionField;
