import styled from "styled-components";

export const OverviewCreateSuggestionStyled = styled.div<{
  isRefPage: boolean;
  isExtOrDesktop: boolean;
}>`
  ${({ isRefPage }): any => !isRefPage && `padding-top: 44px;`}
  width:100%;

  .ant-form-item-control {
    width: 100%;
  }

  .suggest-header {
    font-family: OpenSansSemiBold;
    height: 70px;
    padding: 0 31px 0 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 26px;
    svg {
      path {
        fill: ${({ theme }): string => theme.bgColors.RED_100};
        opacity: 0.5;
      }

      :hover {
        path {
          opacity: unset;
        }
      }
    }
  }

  .scroll-sec {
    ${({ isRefPage, isExtOrDesktop }): any =>
      isRefPage &&
      `height: calc(100vh - ${
        isExtOrDesktop ? "152px" : "197px"
      }); overflow: auto;`}

    .ant-input {
      max-width: 100%;
    }
  }

  .form-actions-sec {
    ${({ isRefPage }): any => isRefPage && `margin-bottom: 0px`}
  }
`;
