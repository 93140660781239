export const ETL_SECTIONS = {
  metaInfo: "Definition",
  executionDetails: "Execution Details",
  managedBy: "Managed By",
  code: "Source Code",
};

export const ETL_SECTION_TITLES = {
  META_INFO: {
    SOURCE: "Source",
    TARGET: "Target",
    SOURCE_OF_RELATION: "Source of Relation",
    ETL_PARENT_NODE: "Parent Node",
  },

  EXECUTION_DETAILS: {
    LAST_RUN: "Last Run",
    DURATION: "Duration",
    RUN_STATUS: "Run Status",
    LAST_UPDATED_ON: "Last Updated On",
    SOURCE_CODE: "Source Code",
  },

  DESCRIPTION: "Description",

  RULE_DEFINITION: {
    COL_ID: "Column",
    RULE_DESC: "Rule Description",
    REF_SRC_ID: "Reference",
  },
};
