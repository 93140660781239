import styled from "styled-components";
import { ellipsis, multilineEllipsis } from "../../styles/app.styles";
import { hexToRgbA } from "../../utils";

export const AssetCompareChangesPanelStyled = styled.div`
  width: 100%;
  .panel-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 42px;
    .tab-heading {
      margin-bottom: 5px;
      font-family: OpenSansSemiBold;
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      font-size: 17.6px;
    }
  }
`;

export const AssetGridWrapperStyled = styled.div`
  .grid-wrapper-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;

    svg {
      vertical-align: middle;
    }
  }
`;

export const AssetTabularGridStyled = styled.div`
  border: solid 1px ${({ theme }): string => theme.borderColors.GREY_56};
`;

export const AssetTabularHeaderStyled = styled.div`
  background-color: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};
  display: grid;
  grid-template-columns: 200px repeat(2, minmax(0, 1fr));
`;

export const AssetTabularContentStyled = styled.div``;

export const FieldStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 12px 16px 12px 12px;
  border-bottom: 1px solid ${({ theme }): string => theme.bgColors.GREY_56};

  .field-name {
    word-break: break-all;
  }

  .represents-container {
    max-width: 100%;
  }

  .review-changes-tag {
    max-width: 100%;
    height: fit-content;

    .content {
      max-width: 100%;
      ${multilineEllipsis}
      word-break: break-all;
      -webkit-line-clamp: 4;
      white-space: unset;
    }
  }

  ul {
    padding: 0px;
    padding-left: 16px;
    margin: 0;

    li {
      .add-info-list-item,
      .add-info-list-item-link {
        ${multilineEllipsis}
        word-break: break-all;
        -webkit-line-clamp: 4;
        white-space: unset;

        .ql-editor {
          padding: 0px;
        }
      }

      .add-info-list-item-link {
        color: ${({ theme }): string => theme?.textColors?.DARK_BLUE};
      }
    }
  }

  :not(:last-child) {
    border-right: 1px solid ${({ theme }): string => theme.bgColors.GREY_56};
  }
`;

export const FieldHeadingStyled = styled(FieldStyled)`
  font-family: OpenSansSemiBold;
`;

export const GridContentRowStyled = styled.div<{ isChanged?: boolean }>`
  &.grid-content-row {
    display: grid;
    grid-template-columns: 200px repeat(2, minmax(0, 1fr));
    border-bottom: 1px solid
      ${({ theme }): string => theme.borderColors.GREY_56};
    background-color: ${({ theme, isChanged }): string =>
      isChanged ? theme.bgColors.YELLOW_116 : theme.bgColors.WHITE_COLOR};

    &:last-child {
      border-bottom: none;
    }

    .is-cde-flex {
      height: unset;
    }
  }
`;
export const ValAndCount = styled.div`
  padding: 4px;
  height: 22px;
  margin-right: 4px;
  min-width: 33px;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_27};
  border: solid 1px ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
  color: ${({ theme }): string => hexToRgbA(theme.textColors.BLACK_COLOR, 0.8)};
`;

export const EntityRendrerStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 6px;
  }
`;

export const AssetPathWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
`;

export const AssetPathAndCommentStyled = styled.div`
  display: flex;
  flex-direction: column;

  .comment {
    color: ${({ theme }): string => hexToRgbA(theme.textColors.GREY_210)};
    ${ellipsis}
  }
`;

export const NoChangesDetectedStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;

  .text {
    color: ${({ theme }): string =>
      hexToRgbA(theme.textColors.BLACK_COLOR, 0.8)};
  }
`;
