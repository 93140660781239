import { Tooltip } from "antd";
import { useCallback, useMemo } from "react";
import {
  AdaptiveNodeHierarchyItemStyled,
  AdaptiveNodeHierarchyStyled,
} from "./adaptiveassetheirachy.styles";

import CoreNodesCircleIcon from "../corenodetypescircleicon";
import { chevronRight, deleteIcon, workflowIcon } from "../../svgs";

import { getDataSourceLogoSvgByType } from "../../utils/getsourceiconsbytype";

import {
  AdaptiveNodeHierarchyProps,
  BreadcrumbItemProps,
} from "./adaptiveassetheirachy.types";

import { getDetailpageUrlBasedOnNodeType, getWeightedWidth } from "../../utils";

import { NodeSubType, NodeType, SourceTypes } from "../../app.types";
import { useOpenDrawer } from "../../customhooks";

import StyledLink from "../styledlink/styledlink";
import LinkButton from "../linkbutton";

import Flex from "../flex";
import { DVSUM_TOOLTIP_CLASS_NAME, NON_ASSET_ITEMS } from "../../constants";
import { MatchingStatusPreviewer } from "../matchingstatus";

const RenderItem = ({
  node,
  isLast,
  mode,
  openRef,
  onRemove,
  flexGrow,
}: BreadcrumbItemProps): JSX.Element => {
  const isPrimary = mode === "primary";
  const openDrawer = useOpenDrawer();

  const handleOpenRef = useCallback(
    (e) => {
      if (!node?.node_id || !node?.node_type) return;
      e?.stopPropagation();
      openDrawer({
        drawerId: "asset_ref_drawer",
        visible: true,
        drawerProps: {
          id: node?.node_id,
          nodeType: node?.node_type,
          nodeSubType: node?.node_sub_type,
        },
        zIndex: 1071,
      });
    },
    [node, openDrawer]
  );

  // Determine if the asset should be clickable
  const isClickable = Boolean(
    node?.node_id &&
      isPrimary &&
      !NON_ASSET_ITEMS.includes(node?.node_type as NodeType)
  );

  // Generate detail page URL if applicable
  const detailPageUrl = isClickable
    ? getDetailpageUrlBasedOnNodeType(
        node?.node_id,
        node?.node_type,
        node?.node_sub_type as NodeSubType
      )
    : "";

  // Render name component based on mode and clickability
  const renderNodeName = (): JSX.Element => {
    const nameProps = {
      className: "adaptive-node-name",
      title: node?.node_name,
    };

    if (node?.node_type !== "SRC" && openRef) {
      return (
        <div
          {...nameProps}
          {...(isPrimary &&
            isClickable && {
              role: "button",
              onClick: handleOpenRef,
            })}
        >
          {node?.node_name}
        </div>
      );
    }

    return isPrimary ? (
      <StyledLink
        to={detailPageUrl}
        cutomClassName="adaptive-node-name"
        title={node?.node_name}
      >
        {node?.node_name}
      </StyledLink>
    ) : (
      <div {...nameProps}>{node?.node_name}</div>
    );
  };

  return (
    <AdaptiveNodeHierarchyItemStyled
      flexGrow={flexGrow}
      isPrimary={isPrimary}
      showCursorPointer={isClickable}
      className="adaptive-asset-item-rendrer"
    >
      {node?.node_type === "SRC" ? (
        <Flex>
          {getDataSourceLogoSvgByType(node?.node_sub_type as SourceTypes)}
        </Flex>
      ) : (
        <CoreNodesCircleIcon
          nodeType={node?.node_type}
          mode={isPrimary ? "primary" : "secondary"}
          width="16px"
          height="16px"
          fontSize="9px"
        />
      )}

      {renderNodeName()}

      {node?.is_approved && (
        <Flex customClass="adaptive-node-status">
          <MatchingStatusPreviewer isApproved={node?.is_approved} />
        </Flex>
      )}

      {node?.is_workflow_enabled && (
        <Tooltip
          title="Metadata Workflow Enabled"
          overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
          placement="right"
        >
          <Flex customClass="adaptive-node-workflow-status">
            {workflowIcon("15", "15")}
          </Flex>
        </Tooltip>
      )}

      {!isLast && <div className="right-icon">{chevronRight}</div>}

      {onRemove && isLast && (
        <LinkButton
          className="delete-icon"
          onClick={(): void => onRemove?.(node?.node_id)}
        >
          {deleteIcon}
        </LinkButton>
      )}
    </AdaptiveNodeHierarchyItemStyled>
  );
};

const AdaptiveAssetHierarchy = ({
  nodes = [],
  mode = "primary",
  openRef,
  ...restProps
}: AdaptiveNodeHierarchyProps): JSX.Element => {
  // Sort nodes by level in descending order (higher level nodes first)
  const sortedNodes = useMemo(
    () => [...nodes].sort((a, b) => b?.level - a?.level),
    [nodes]
  );

  // Calculate flex growth values for responsive layout
  const flexGrowValues = useMemo(() => {
    return getWeightedWidth(sortedNodes);
  }, [sortedNodes?.length]);

  return (
    <AdaptiveNodeHierarchyStyled className="adaptive-asset-hierarchy">
      {sortedNodes?.map((node, index) => (
        <RenderItem
          nodes={sortedNodes}
          key={`node-${node?.node_id}-${node?.node_name}-${mode}-${index}`}
          node={node}
          isLast={index === sortedNodes.length - 1}
          mode={mode}
          openRef={openRef}
          flexGrow={flexGrowValues?.[index] ?? 0}
          {...restProps}
        />
      ))}
    </AdaptiveNodeHierarchyStyled>
  );
};

export default AdaptiveAssetHierarchy;
