import { DetailPageHeaderWithTab } from "../../../datasourcedetailpage/datasourcedetailpage.components";
import { DataQualityTabStyled } from "./columnqualitytab.styles";

import { ColumnDQProps } from "./columnqualitytab.types";

import {
  TableColumnDataQualityTab,
  AssetStyledWrapper,
} from "../../../../components";

import ConditionalDisplay from "../../../../components/conditionaldisplay";
import { useGetAppState } from "../../../../customhooks";

const ColumnDataQualityTab = (props: ColumnDQProps): JSX.Element => {
  const {
    sourceId = "",
    nodeId = "",
    parentId = "",
    sourceTypeId = "",
  } = props;

  const {
    expandableGridProps: { isGridExpanded = false } = { isGridExpanded: false },
  } = useGetAppState();

  return (
    <AssetStyledWrapper isGridExpanded={isGridExpanded}>
      <ConditionalDisplay condition={!isGridExpanded}>
        <DetailPageHeaderWithTab {...props} />
      </ConditionalDisplay>

      <DataQualityTabStyled>
        <TableColumnDataQualityTab
          nodeId={nodeId}
          parentId={parentId}
          sourceId={sourceId}
          sourceTypeId={sourceTypeId}
        />
      </DataQualityTabStyled>
    </AssetStyledWrapper>
  );
};

export default ColumnDataQualityTab;
