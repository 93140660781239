import { useHistory, useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DataQualityTabStyled,
  FilterHeader,
  GraphContainer,
  RulesContainer,
  SectionHeading,
  StyledCard,
  TileHeading,
  TilesContainer,
  TileText,
} from "./tablecolumndataqualitytab.styles";

import { DataQualityTabProps } from "./tablecolumndataqualitytab.types";
import RulesGridView from "../../pages/tablepage/views/dataqualitytab/dataqualitytab.components/rulesgridview/rulesgridview";
import { RuleViewType } from "../../pages/tablepage/views/dataqualitytab/dataqualitytab.types";
import { onChangeRuleViewType } from "../../pages/tablepage/tablepage.types";
import {
  useGetNodeHistoryForDetailPage,
  useGetNodeStatisticsForDetailPage,
} from "../../api/columnsservice";
import { LineChart } from "../recharts";
import StateHandler from "../statehandler";
import {
  columnHistoryChartBlankSlate,
  ColumnDqTilesBlankSlate,
  exceptionRecordIcon,
  lastRunIcon,
  recordCountIcon,
  showDqScoreIcon,
  totalRuleIcon,
  warningInCircleIcon,
} from "../../pages/columndetailpage/columndetailpage.blankslates";
import { doubleTickIcon } from "../../svgs";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { numberFormatter, utcTOLocalTimeZone } from "../../utils";
import { ToggleButton } from "../togglebutton/togglebutton";
import { QUERY_PARAMS } from "../../pages/listingpage/listingpage.constants";
import { SourceTypes } from "../../app.types";
import { checkIsColumnPage } from "../../utils/iscolumnpage";
import { useGetAppState, useQueryParams } from "../../customhooks";
import ConditionalDisplay from "../conditionaldisplay";

const TableColumnDataQualityTab = (props: DataQualityTabProps): JSX.Element => {
  const history = useHistory();
  const params = useQueryParams();

  const {
    expandableGridProps: { isGridExpanded = false } = { isGridExpanded: false },
  } = useGetAppState();

  const paramNodeId = useMemo(() => {
    const { NODE_ID } = QUERY_PARAMS;
    return Number.parseInt(params?.get(NODE_ID) || "");
  }, [params]);

  const [showDqScore, setShowDqScore] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState("7 Days");
  const [nodeFilter, setNodeFilter] = useState("all");
  const rulesGridRef = useRef<HTMLDivElement | null>(null);
  const isColumnPage = checkIsColumnPage();

  const {
    sourceId = "",
    nodeId = "",
    parentId = "",
    sourceTypeId = "",
    freshnessRuleColumnId = null,
  } = props;

  const {
    parsedData: columnDqData,
    isLoading,
    isFetching: isFetchingStats,
    error,
    refetch: refetchStatistics,
  } = useGetNodeStatisticsForDetailPage(isColumnPage ? nodeId : "", parentId);

  const {
    parsedData: columnHistoryData,
    isLoading: isChartLoading,
    error: chartError,
    isFetching: isChartFetching,
    refetch: refetchChart,
  } = useGetNodeHistoryForDetailPage(
    isColumnPage ? nodeId : "",
    parentId,
    selectedOption
  );

  const [activeRuleView, setActiveRuleView] = useState<RuleViewType>(
    columnDqData?.rule_count > 0 ? "AVB" : "RCM"
  );

  const onChangeRuleView: onChangeRuleViewType = useCallback(() => {
    setActiveRuleView((currentView) => (currentView === "AVB" ? "RCM" : "AVB"));
  }, []);

  const refetchStatsAndCharts = useCallback(() => {
    refetchStatistics();
    refetchChart();
  }, []);

  const handleFilterChange = (option: string): void => {
    setSelectedOption(option);
    refetchChart();
  };

  const onChangeFilter = useCallback(
    (filter: string) => (): void => {
      setNodeFilter(filter);
      //Doing this to replace listing page filter with the selected filter
      const currentPath = history?.location;
      history.replace({
        ...currentPath,
        search: `?selected-filter=${filter}&node-id=${paramNodeId || ""}`,
      });
      if (rulesGridRef?.current) {
        rulesGridRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    [paramNodeId]
  );

  const getYAxisOffset = (data: any[], key: string): number => {
    if (!Array.isArray(data) || data?.length === 0) return 40; // Default offset when data is empty or undefined
    const maxValue = Math?.max(...data?.map((d) => d?.[key] || 0));
    return maxValue < 100 ? 40 : 80;
  };

  const chartConfig = {
    data: columnHistoryData?.historyData || [],
    dataKeys: [
      { dataKey: "DQ Score", fill: "#428BCA", label: "DQ Score" },
      { dataKey: "Exceptions", fill: "#D3212C", label: "Exception Records" },
    ],
    yAxis: [
      {
        yAxisId: "DQ Score",
        label: {
          value: "DQ Score",
          angle: -90,
          dy: 20,
          offset: 5,
        },
        orientation: "left" as "left" | "right",
      },
      {
        yAxisId: "Exceptions",
        label: {
          value: "Exceptions",
          angle: -90,
          dy: 20,
          offset: getYAxisOffset(columnHistoryData?.historyData, "Exceptions"),
        },
        orientation: "right" as "left" | "right",
      },
    ],
    tooltipFormatter: (val: number): string => numberFormatter(val?.toFixed(2)),
    width: "100%",
    height: 300,
    margin: { top: 20, left: 10, bottom: 20 },
    xAxisLabel: { value: "Date", offset: -5 },
    showCartesianGrid: true,
    hideXAxis: false,
    hideYAxis: false,
    enableDataLabels: false,
    customLabel: false,
    title: "DQ Score History",
  };

  useEffect(() => {
    if (columnDqData?.rule_count > 0) {
      setActiveRuleView("AVB");
    } else {
      setActiveRuleView("RCM");
    }
  }, [columnDqData]);

  return (
    <DataQualityTabStyled>
      {/* Only added conditional display wrapper for expanded grid */}
      <ConditionalDisplay condition={!isGridExpanded}>
        <>
          <SectionHeading>Statistics</SectionHeading>
          <StateHandler
            isFetching={isLoading || isFetchingStats}
            error={error}
            blankSlate={ColumnDqTilesBlankSlate()}
          >
            <TilesContainer>
              <StyledCard>
                <TileHeading>
                  {numberFormatter(columnDqData?.avg_dq_score?.toString())}
                </TileHeading>
                <TileText>
                  {doubleTickIcon()} DQ Score
                  <Tooltip
                    title="Show DQ Score History"
                    overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                  >
                    <div
                      onClick={(): void => setShowDqScore((prev) => !prev)}
                      role="button"
                    >
                      {showDqScoreIcon()}
                    </div>
                  </Tooltip>
                </TileText>
              </StyledCard>
              <StyledCard
                filter
                onClick={onChangeFilter("all")}
                selected={nodeFilter === "all"}
              >
                <TileHeading selected={nodeFilter === "all"}>
                  {numberFormatter(columnDqData?.rule_count.toString())}
                </TileHeading>
                <TileText selected={nodeFilter === "all"}>
                  {totalRuleIcon()} Total Rules
                </TileText>
              </StyledCard>
              <StyledCard
                filter
                onClick={onChangeFilter("alerts_available_count")}
                selected={nodeFilter === "alerts_available_count"}
              >
                <TileHeading selected={nodeFilter === "alerts_available_count"}>
                  {numberFormatter(
                    columnDqData?.alerting_rules_count?.toString()
                  )}
                </TileHeading>
                <TileText selected={nodeFilter === "alerts_available_count"}>
                  {warningInCircleIcon()} With Alerts
                </TileText>
              </StyledCard>
              <StyledCard>
                <TileHeading>
                  {numberFormatter(
                    columnDqData?.exception_record_count?.toString()
                  )}
                </TileHeading>
                <TileText>
                  {exceptionRecordIcon()} Total Exception Count
                </TileText>
              </StyledCard>
              <StyledCard>
                <TileHeading>
                  {numberFormatter(columnDqData?.record_count?.toString())}
                </TileHeading>
                <TileText>{recordCountIcon()} Total Records Scanned</TileText>
              </StyledCard>
              <StyledCard>
                <TileHeading>
                  {columnDqData?.last_executed_on
                    ? utcTOLocalTimeZone(
                        String(columnDqData?.last_executed_on),
                        "M/d/yyyy h:mm a"
                      )
                    : "--"}
                </TileHeading>

                <TileText>{lastRunIcon()} Last Rule Ran</TileText>
              </StyledCard>
            </TilesContainer>
          </StateHandler>
        </>
      </ConditionalDisplay>

      {/* Section: Graph */}
      {showDqScore && (
        <GraphContainer>
          {/* <SectionHeading>DQ Score History</SectionHeading> */}

          <>
            <FilterHeader>
              <div className="heading">DQ Score History</div>
              <div className="header-filters">
                <ToggleButton
                  options={["7 Days", "30 Days", "90 Days"]}
                  selectedOption={selectedOption}
                  onClick={handleFilterChange}
                />
              </div>
            </FilterHeader>
            <StateHandler
              isFetching={isChartLoading || isChartFetching}
              error={chartError}
              blankSlate={columnHistoryChartBlankSlate()}
            >
              {columnHistoryData?.historyData &&
              columnHistoryData?.historyData?.length > 0 ? (
                <LineChart {...chartConfig} />
              ) : (
                <div className="no-data">No Data to Show</div>
              )}
            </StateHandler>
          </>
        </GraphContainer>
      )}

      {/* Section: Rules Table */}
      <RulesContainer ref={rulesGridRef}>
        <RulesGridView
          tableId={parentId}
          columnId={nodeId}
          sourceId={parseInt(sourceId)}
          srcTypeId={sourceTypeId as SourceTypes}
          freshnessRuleColumnId={
            freshnessRuleColumnId ??
            (columnDqData?.is_time_series_column ? 1 : 0)
          }
          activeRuleView={activeRuleView}
          onChangeRuleView={onChangeRuleView}
          refetchStatsAndCharts={refetchStatsAndCharts}
          nodeFilter={nodeFilter}
        />
      </RulesContainer>
    </DataQualityTabStyled>
  );
};

export default TableColumnDataQualityTab;
