import { AxiosResponse } from "axios";
import { setAuthenticationInfoInLocalStorage } from "./setauthenticationinfoinlocalstorage";

export const setCoginitoAuthInfoIntoLocalStorage = (
  res: AxiosResponse<{
    id_token: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    token_type: string;
  }>
): void => {
  const {
    access_token: accessToken = "",
    expires_in: expiresIn = 0,
    id_token: idToken = "",
    refresh_token: refreshToken = "",
  } = res?.data || {};

  setAuthenticationInfoInLocalStorage({
    accessToken,
    expTime: expiresIn,
    idToken,
    refreshToken,
  });
};
