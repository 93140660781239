import styled from "styled-components";

export const DescriptionEditableViewStyled = styled.div`
  .desc-editableView {
    background: ${({ theme }): string => theme.bgColors.BLUE_11};
    padding: 23px 34px 34px 23px;

    margin-bottom: 17px;
    .main-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      position: relative;
      .desc-row-title {
        color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
        width: 100%;
        font-family: OpenSansSemiBold;
        margin-bottom: 6px;

        .required-field {
          color: ${({ theme }): string => theme.textColors.RED_11};
          font-size: 13px;
        }

        .required-icon {
          color: ${({ theme }): string => theme.textColors.RED_11};
        }
      }
      .row-value {
        color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
        width: 100%;
      }

      :nth-of-type(1) {
        .error {
          right: 30%;
        }
      }
    }
  }
`;
