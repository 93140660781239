import { useCallback } from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";

import { Button } from "../../../../../components";
import { RuleActionsRendererStyled } from "./recommendedrulesgrid.styles";

import { RecommendedRulesParsedType } from "../../../../../parsers/ruleparser/ruleparser.types";

export const RuleActionsRenderer = (
  props: ICellRendererParams
): JSX.Element => {
  const colDefs = props?.api?.getColumnDefs()?.[2] as ColDef;

  const { rule_id: ruleId } = (props?.data as RecommendedRulesParsedType) || {};

  const { cellRendererParams } = colDefs || {};
  const { onAddRecommendedRuleClick, selectedNodesCount } =
    cellRendererParams || {};

  const onAddButtonClick = useCallback(
    (e) => {
      e?.stopPropagation();
      onAddRecommendedRuleClick?.(e, ruleId);
    },
    [ruleId]
  );

  return (
    <RuleActionsRendererStyled>
      <Button
        disabled={selectedNodesCount > 1}
        onClick={onAddButtonClick}
        className="add-rcm-rule-btn"
      >
        Add
      </Button>
    </RuleActionsRendererStyled>
  );
};
