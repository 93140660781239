import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { Tooltip } from "antd";
import { ICellRendererParams } from "ag-grid-community";

import {
  AssetGridDataType,
  AssetGridRowDataType,
  AssetRelationshipGridsState,
  OnCommentSave,
  OnDeletion,
} from "./assetrelationshipgrids.types";
import {
  DVSUM_TOOLTIP_CLASS_NAME,
  NON_ASSET_ITEMS,
} from "../../../../../constants";

import {
  GridActionRenderer,
  GridNameContainer,
  MatchingStatusPopoverStyled,
  NonAssetItem,
} from "./assetrelationshipgrids.styles";

import { getAssetUrl } from "../../sectionwitheditablerelationships.utils";
import StyledLink from "../../../../styledlink/styledlink";

import { CoreNodesCircleIcon, LinkButton } from "../../../..";
import ConfidenceScore from "../../../../confidencescore";

import {
  approveIcon,
  deleteIcon1,
  editIcon,
  managedByIcon,
  reviewIcon,
  unApproveIcon,
  workflowIcon,
} from "../../../../../svgs";

import {
  domainWorkflowTitle,
  getDetailpageUrlBasedOnNodeType,
} from "../../../../../utils";
import { TableProps } from "../../../../aggridtable";

import DvSumPopover from "../../../../dvsumpopover";
import { CommentPopoverContent } from "../..";
import MatchingStatusPopover, {
  MatchingStatusPreviewer,
} from "../../../../matchingstatus";
import { useOpenModal } from "../../../../../customhooks";
import { ReviewRecEntityFormProps } from "../../../../../forms/reviewrecomendedentitymodal";
import TableActionsSec from "../../../../tableactionssec";
import { useAssetRelationshipContext } from "../../../../../contexts";
import ConfirmationPopover from "../../../../confirmationpopover";
import { AdaptiveAssetHierarchy } from "../../../../adaptiveassetheirachy";
import {
  gridColumnMaxWidth,
  gridColumnMinWidth,
} from "../../../../aggridtable/aggridtable.constants";
import { checkIsColumnPage } from "../../../../../utils/iscolumnpage";

export const NameRenderer: React.FC<ICellRendererParams> = (props) => {
  const { api, node, context } = props;
  const { copyState, setCopyState } = context;
  const { state: contextState } = useAssetRelationshipContext();

  const openModal = useOpenModal();

  const isEditMode = contextState?.isEditable;

  const assetUrl = getDetailpageUrlBasedOnNodeType(
    props?.data?.id,
    props?.data?.nodeType,
    props?.data?.nodeSubType
  );

  const isNonAssetItem = NON_ASSET_ITEMS.includes(props?.data?.nodeType);
  const isColumnPage = checkIsColumnPage();

  const matchingScore = props?.data?.matchingScore;
  const matchingStatus = props?.data?.matchingStatus;
  const isSourceAI = props?.data?.source === "AAI";

  // const isInherited = props?.data?.isInherited;
  // const isInheritedBy = props?.data?.isInheritedBy;

  const isWorkflowEnabled = props?.data?.isWorkflowEnabled;

  const isApproved = matchingStatus === "APR";

  const updateMatchingStatus = useCallback(
    (newStatus: "APR" | "REW") => {
      if (!node) return;

      const newMode = newStatus === "APR" ? "UPDATE" : "DELETE";

      const updatedData = copyState?.map((section: AssetGridDataType) => ({
        ...section,
        rows: section?.rows?.map((row) =>
          row?.id === node?.data?.id
            ? {
                ...row,
                matchingStatus: newStatus,
                isChanged: true,
                mode: newMode,
              }
            : row
        ),
      }));

      setCopyState(updatedData);

      const rowNode = api.getRowNode(node?.data?.id);
      if (rowNode) {
        rowNode.setData({ ...rowNode.data, matchingStatus: newStatus });
      }

      api.refreshCells({ rowNodes: [node], force: true });
    },
    [api, node, copyState, setCopyState]
  );

  const onDone = useCallback(
    (params): void => {
      const { selectedEntity, status, matchingEntities } = params;

      if (!node) return;

      const previousRow = copyState?.flatMap((section: AssetGridDataType) =>
        section?.rows?.filter((row) => row?.id === node?.data?.id)
      )?.[0];

      const isNewEntity =
        selectedEntity &&
        selectedEntity?.matching_entity_id !== previousRow?.id;

      const updatedData = copyState?.map((section: AssetGridDataType) => ({
        ...section,
        rows: section?.rows?.flatMap((row) => {
          if (row?.id === node?.data?.id) {
            const updatedRow = {
              ...row,
              matchingStatus: status,
              matchingScore: selectedEntity
                ? selectedEntity?.matching_score
                : row?.matchingScore,
              matchingEntities: matchingEntities || row?.matchingEntities,
              isChanged: true,
              mode: status === "APR" ? "UPDATE" : "DELETE",
              name: isNewEntity ? selectedEntity?.matching_entity : row?.name,
            };

            if (isNewEntity) {
              return [
                {
                  ...row,
                  isChanged: true,
                  mode: "DELETE",
                  isDeleted: true,
                },
                {
                  ...updatedRow,
                  id: selectedEntity?.matching_entity_id,
                  name: selectedEntity?.matching_entity,
                  mode: "UPSERT",
                },
              ];
            }

            return [updatedRow];
          }
          return [row];
        }),
      }));

      setCopyState(updatedData);

      // Update AG Grid row
      const rowNode = api.getRowNode(node?.data?.id);
      if (rowNode) {
        rowNode.setData({
          ...rowNode?.data,
          name: isNewEntity
            ? selectedEntity?.matching_entity
            : rowNode?.data?.name,
          matchingStatus: status,
          matchingScore: selectedEntity
            ? selectedEntity?.matching_score
            : rowNode?.data?.matchingScore,
          matchingEntities: matchingEntities || rowNode?.data?.matchingEntities,
        });
      }

      setTimeout(() => {
        api.refreshCells({ rowNodes: [node], force: true });
        api.redrawRows({ rowNodes: [node] });
      }, 100);
    },
    [api, node, copyState, setCopyState]
  );

  const onApprove = useCallback(() => updateMatchingStatus("APR"), [
    updateMatchingStatus,
  ]);
  const onUnApprove = useCallback(() => updateMatchingStatus("REW"), [
    updateMatchingStatus,
  ]);

  const recEntityModalProps = {
    matchingStatus,
    onDone,
    matchingEntities: props?.data?.matchingEntities || [],
    isRelationshipSection: true,
    breadcrumbs: [
      {
        title: props?.data?.name || "",
        type: props?.data?.nodeType,
      },
    ],
  };

  const onReview = useCallback(() => {
    openModal({
      modalId: "review_recomended_entity_modal",
      visible: true,
      modalTitle: "Review Recommended Term",
      modalProps: recEntityModalProps,
    });
  }, [recEntityModalProps]);

  const popoverMenu = useMemo(
    () => [
      isApproved
        ? { name: "Unapprove", icon: unApproveIcon(), onClick: onUnApprove }
        : { name: "Approve", icon: approveIcon(), onClick: onApprove },
      ...(isColumnPage
        ? [
            {
              name: "Review",
              icon: reviewIcon(),
              onClick: onReview,
              id: `relationship-matching-status-${props?.value}`,
            },
          ]
        : []),
    ],
    [isApproved, onReview, isColumnPage]
  );

  const menuSec = (
    <span>
      <TableActionsSec
        data={popoverMenu}
        itemClassName="matching-status-popover-item"
        id={`relationship-action-status-${props?.value}`}
      />
    </span>
  );

  return (
    <>
      <GridNameContainer>
        <CoreNodesCircleIcon
          nodeType={props?.data?.nodeType}
          nodeSubType={props?.data?.nodeSubType}
          width="16px"
          height="16px"
          fontSize="8px"
        />
        {isNonAssetItem ? (
          <NonAssetItem>{props.value}</NonAssetItem>
        ) : (
          <StyledLink to={assetUrl}>{props.value}</StyledLink>
        )}

        {isApproved && <MatchingStatusPreviewer isApproved={isApproved} />}

        {isSourceAI && typeof matchingScore === "number" && (
          <ConfidenceScore score={matchingScore} />
        )}

        {/* {isInherited && (
          <Tooltip
            title={`Inherited from linked ${isInheritedBy}`}
            overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
            placement="right"
          >
            {managedByIcon()}
          </Tooltip>
        )} */}

        {isWorkflowEnabled && (
          <Tooltip
            overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
            title={domainWorkflowTitle(isWorkflowEnabled, false)}
            placement="right"
          >
            {workflowIcon("16", "16")}
          </Tooltip>
        )}

        {isSourceAI && isEditMode && (
          <MatchingStatusPopoverStyled
            isSpaceBetween={false}
            isContainingAdditionalInfo={false}
          >
            {matchingStatus !== "UNL" && menuSec}
          </MatchingStatusPopoverStyled>
        )}
      </GridNameContainer>
    </>
  );
};

export const ActionRenderer = (
  props: PropsWithChildren<ICellRendererParams>,
  sectionId: string,
  onCommentSave: OnCommentSave,
  onDeletion: OnDeletion
): JSX.Element => {
  const id = props?.data?.id;
  const comment = props?.data?.comment;

  const [isPopoverVisible, setPopoverVisible] = useState<boolean>(false);

  const onVisibleChange = useCallback(
    (visibility: boolean) => {
      setPopoverVisible(visibility);
    },
    [setPopoverVisible]
  );

  const handleCancel = useCallback(() => {
    setPopoverVisible(false);
  }, [setPopoverVisible]);

  const handleSaveComment = useCallback(
    (newComment: string) => {
      onCommentSave(sectionId, id, newComment);
      setPopoverVisible(false);
    },
    [id, sectionId, onCommentSave, setPopoverVisible]
  );

  const handleDelete = useCallback(() => {
    onDeletion(sectionId, id);
  }, [id, sectionId, onDeletion]);

  return (
    <GridActionRenderer>
      <DvSumPopover
        content={
          <CommentPopoverContent
            defaultValue={comment}
            onCancel={handleCancel}
            onSave={handleSaveComment}
          />
        }
        visible={isPopoverVisible}
        onVisibleChange={onVisibleChange}
        trigger={["click"]}
        showArrow
        arrowPointAtCenter
        placement="topLeft"
        zIndex={1}
        destroyTooltipOnHide
      >
        {editIcon}
      </DvSumPopover>

      <ConfirmationPopover
        heading="Confirm Deletion"
        desc="Are you sure you want to delete this relation? This action cannot be undone."
        okText="Delete"
        cancelText="Cancel"
        onCancel={handleCancel}
        onOk={handleDelete}
        storageKey={`delete-confirmation-${id}`}
      >
        <LinkButton>{deleteIcon1("14px", "14px")}</LinkButton>
      </ConfirmationPopover>
    </GridActionRenderer>
  );
};

export const AssetPathRenderer: React.FC<ICellRendererParams> = (props) => {
  const assetPath = props?.value;
  const asset = props?.data;

  return (
    <div>
      <AdaptiveAssetHierarchy
        nodes={assetPath || []}
        mode="primary"
        key={`${asset?.id}-${asset?.name}`}
      />
    </div>
  );
};

export const SourceRenderer: React.FC<ICellRendererParams> = (props) => {
  const source = props?.value;

  const displaySource =
    source === "AAI" ? "AI" : source === "USR" ? "User" : source;

  return <div>{displaySource}</div>;
};

const customStringComparator = (valueA = "", valueB = ""): number => {
  return valueA?.toLowerCase()?.localeCompare(valueB?.toLowerCase());
};

export const assetsGridColumns = (
  isEditMode: boolean,
  isEditable: boolean,
  isAssetPathRequired: boolean
): TableProps<AssetRelationshipGridsState>["tableColumns"] => {
  const columns: TableProps<AssetRelationshipGridsState>["tableColumns"] = [
    {
      field: "name",
      headerName: "Name",
      cellRenderer: "NameRenderer",
      sort: "asc",
      minWidth: gridColumnMinWidth,
      maxWidth: isAssetPathRequired ? gridColumnMaxWidth : undefined,
      isAutoSize: isAssetPathRequired,
      flex: 1,
      comparator: customStringComparator,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: gridColumnMinWidth,
      maxWidth: isAssetPathRequired ? gridColumnMaxWidth : undefined,
      isAutoSize: isAssetPathRequired,
      flex: 1,
    },
    {
      field: "source",
      headerName: "Source",
      cellRenderer: "SourceRenderer",
      minWidth: 100,
      // flex: !isAssetPathRequired ? 1 : undefined,
      flex: 1,
    },
  ];

  if (isAssetPathRequired) {
    columns.splice(1, 0, {
      field: "nodePath",
      headerName: "Asset Path",
      cellRenderer: "AssetPathRenderer",
      flex: 1,
      minWidth: 300,
      maxWidth: gridColumnMaxWidth,
      isAutoSize: true,
    });
  }

  if (isEditable) {
    columns.splice(isAssetPathRequired ? 3 : 2, 0, {
      field: "comment",
      headerName: "Comment",
      minWidth: gridColumnMinWidth,
      maxWidth: gridColumnMaxWidth,
      isAutoSize: isAssetPathRequired,
      flex: 1,
    });
  }

  if (isEditMode && isEditable) {
    columns.push({
      field: "action",
      headerName: "Action",
      cellRenderer: "ActionRenderer",
      cellClass: "ag-cell-center",
      minWidth: 100,
    });
  }

  return columns;
};
