import { WidgetCreationContextType } from "./widgetcreationcontext.types";

export const All_GOV_CATEGORY = { name: "ALL", id: "ALLGOV", widgets: [] };

export const WIDGET_CREATION_INITIAL_STATE: WidgetCreationContextType = {
  currentViewId: "COR",
  governanceViewCategories: [],
  widgetCreationType: undefined,
  selectedGovernanceCategory: All_GOV_CATEGORY,
  selectedWidget: undefined,
  governanceViewCategoriesLoading: true,
  governanceViewCategoriesError: "",
  isEditMode: false,
  isCloneMode: false,
  dashboardId: "",
  selectedWidgetType: "COR",
};
