import DetailPagesHeader from "../detailpagesheader";
import StateHandler from "../statehandler/statehandler";
import TabsWithRightSection from "../tabswithrightsec";

import { dataSetTopBS } from "../../blankslates";

import { DetailPageHeaderWithTabProps } from "./detailpageheaderwithtab.types";

const DetailPageHeaderWithTab = (
  props: DetailPageHeaderWithTabProps
): JSX.Element => {
  const { tabs, isLoading, error, headerData, children } = props;

  return (
    <>
      <StateHandler
        isFetching={isLoading}
        error={error}
        blankSlate={dataSetTopBS}
      >
        <DetailPagesHeader {...headerData} />
      </StateHandler>
      <TabsWithRightSection tabs={tabs}>{children}</TabsWithRightSection>
    </>
  );
};

export default DetailPageHeaderWithTab;
