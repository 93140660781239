// <--- Import Statements Start --->
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
// <--- Import Statements End --->

// <--- Components Start --->
import {
  CheckboxField,
  InputField,
  SelectField,
  TextAreaField,
} from "../../../../../../components/formfields";

import EntitySelection from "./entityselection";
// <--- Components End --->

// <--- Schemas Start --->
import { columnEditSchema } from "../../../../../../utils/schemas/columneditschema";
// <--- Schemas End --->

// <--- Styles Start --->
import { ColumnRowStyled } from "../columneditview.styles";
// <--- Styles End --->

// <--- Types Start --->
import {
  ColConfigState,
  ColConfigStateValue,
  ColumnRowProps,
} from "../columneditview.types";
import { MatchingStaus } from "../../../../../../parsers/columnparsers/columnparsers.types";

import { MatchingEntity } from "../../../../../../forms/reviewrecomendedentitymodal";
// <--- Types End --->

// <--- Utils Start --->
import { useOutsideClick } from "../../../../../../utils/useoutsideclick";
import { useDebounce } from "../../../../../../customhooks";
import LinkButtonToOpenRefView from "../../../../../linkbuttontoopenrefview/linkbuttontoopenrefview";
// <--- Utils End --->

function ColumnRow(props: ColumnRowProps): JSX.Element {
  const [isFocused, setFocused] = useState<boolean>(
    props?.columnData?.column_id === props?.selectedColumnId
  );

  const {
    columnData,
    columnEditConfig,
    setColumnConfigState,
    colFocusedFields,
    setColFocusedFields,
    columnTypeIdSelect,
  } = props;

  const rowRef = useRef(null);

  const curentColumnConfig = columnEditConfig?.[columnData?.column_id] || {};

  const { business_rule: isBusinessRuleFocused, desc: isDescFocused } =
    colFocusedFields?.[columnData?.column_id] || {};

  const rowForm = useForm<{
    colName: string;
    desc: string;
    entity: string;
    entityId: string;
    entityDesc: string;
    entityMatchingStatus: MatchingStaus;
    matchingEntities: MatchingEntity[];
    buisenessRule: string;
    isCdeField: boolean;
    columnTypeId: string;
  }>({
    defaultValues: {
      colName: curentColumnConfig?.colName,
      entity: curentColumnConfig?.entity,
      desc: curentColumnConfig?.desc,
      entityId: `${curentColumnConfig?.entityId}`,
      entityMatchingStatus: curentColumnConfig?.entity_matching_status,
      buisenessRule: curentColumnConfig?.buiseness_rule,
      isCdeField: !!curentColumnConfig?.is_cde_field,
      columnTypeId: curentColumnConfig?.column_type_id,
      matchingEntities: curentColumnConfig?.matching_entities?.map(
        (matchingEntity) => ({
          matching_entity: matchingEntity?.matching_entity || "",
          matching_entity_id: `${matchingEntity?.matching_entity_id || 0}`,
          samples: matchingEntity?.samples || "",
          matching_score: Math.round(matchingEntity?.matching_score) || 0,
          match_source: matchingEntity?.match_source || "AAI",
          selected: matchingEntity?.selected || false,
          entity_status: matchingEntity?.entity_status || "",
          entity_status_id: matchingEntity?.entity_status_id,
        })
      ),
    },
    resolver: yupResolver(columnEditSchema),
    mode: "all",
  });

  const { control, watch, setValue } = rowForm;

  const formProperties = watch();

  const {
    desc,
    entity,
    colName,
    entityId,
    entityDesc,
    entityMatchingStatus,
    matchingEntities,
    buisenessRule,
    isCdeField,
    columnTypeId,
  } = formProperties;

  const textValsDebounce = useDebounce(
    [colName, desc, buisenessRule].join(","),
    500
  );

  const onFocus = useCallback(
    (callingFrm?: "desc" | "businessRule" | "colName") => {
      setFocused(true);
      setColFocusedFields((st) => ({
        ...st,
        [columnData?.column_id]: {
          desc: callingFrm === "desc",
          business_rule: callingFrm === "businessRule",
        },
      }));
    },
    [isFocused]
  );

  const onBlur = useCallback(() => {
    setFocused(false);
    setColFocusedFields((st) => ({
      ...st,
      [columnData?.column_id]: {
        desc: false,
        business_rule: false,
      },
    }));
  }, [isFocused]);

  useOutsideClick(
    rowRef,
    () => {
      onBlur();
    },
    [isFocused]
  );

  const onSave = useCallback(
    (id) => {
      const {
        buiseness_rule: resBuisenessRule,
        column_display_name: resColumnDisplayName,
        entity: resEntity,
        description: resDescription,
        is_cde_field: resIsCdeField,
        match_info: resMatchInfo,
        column_type_id: resColTypeId,
      } = columnData || {};

      setColumnConfigState((st) => {
        const updatedRow: ColConfigStateValue = {
          ...st?.[id],
          entityDesc,
          colName,
          desc,
          entity,
          isChanged: false,
          entityId,
          entity_matching_status: entityMatchingStatus,
          matching_entities: matchingEntities,
          buiseness_rule: buisenessRule,
          is_cde_field: isCdeField,
          column_type_id: columnTypeId,
        };

        const isEdited = !(
          updatedRow?.colName === resColumnDisplayName &&
          updatedRow?.entity === resEntity &&
          updatedRow?.desc === resDescription &&
          updatedRow?.buiseness_rule === resBuisenessRule &&
          updatedRow?.is_cde_field === !!resIsCdeField &&
          updatedRow?.entity_matching_status ===
            resMatchInfo?.matching_status &&
          updatedRow?.column_type_id === resColTypeId
        );

        const updatedRowWithIsEdited: ColConfigStateValue = {
          ...updatedRow,
          isEdited,
        };

        const updatedState: ColConfigState = {
          ...st,
          [id]: updatedRowWithIsEdited,
        };

        return updatedState;
      });
    },
    [props, formProperties]
  );

  useEffect(() => {
    onSave(columnData?.column_id);
  }, [
    textValsDebounce,
    entity,
    entityMatchingStatus,
    isCdeField,
    columnTypeId,
  ]);

  const CondDescComp = isDescFocused ? TextAreaField : InputField;
  const CondBusinesRuleComp = isBusinessRuleFocused
    ? TextAreaField
    : InputField;

  return (
    <FormProvider {...rowForm}>
      <ColumnRowStyled isFocused={isFocused} ref={rowRef}>
        <div>
          <LinkButtonToOpenRefView
            nodeId={`${columnData?.column_id}`}
            nodeType="COL"
            showEllipsis
            title={columnData?.column_name}
          >
            {columnData?.column_name}
          </LinkButtonToOpenRefView>
        </div>
        <div>
          <InputField
            control={control}
            name="colName"
            width="90%"
            onFocus={(): void => onFocus("colName")}
            autoFocus={props?.columnData?.column_id === props?.selectedColumnId}
          />
        </div>

        <div className="entity-selection">
          <EntitySelection
            onFocus={onFocus}
            isDescInherited={columnData?.is_desc_inherited}
            breadcrumbs={{
              sourceName: columnData?.source_display_name,
              tableName: columnData?.table_display_name,
              columnName: columnData?.column_display_name,
            }}
          />
        </div>
        <div className="is-cde-col">
          <CheckboxField name="isCdeField" control={control} />
        </div>
        <div className="is-attr-col">
          <SelectField
            options={[
              {
                label: "Select",
                value: "",
                options: columnTypeIdSelect || [],
              },
            ]}
            name="columnTypeId"
            control={control}
            setValue={setValue}
            placeholder="Select"
            allowClear={false}
            isAllowClear={false}
          />
        </div>
        <CondDescComp
          control={control}
          name="desc"
          height={isDescFocused ? "139px" : "36px"}
          width="97%"
          onFocus={(): void => onFocus("desc")}
          onBlurCapture={onBlur}
          isMessagePositionAbsolute
          autoFocus={isDescFocused}
        />
        <CondBusinesRuleComp
          control={control}
          name="buisenessRule"
          height={isBusinessRuleFocused ? "139px" : "36px"}
          width="97%"
          onFocus={(): void => onFocus("businessRule")}
          isMessagePositionAbsolute
          autoFocus={isBusinessRuleFocused}
          onBlurCapture={onBlur}
        />
      </ColumnRowStyled>
    </FormProvider>
  );
}

export default ColumnRow;
