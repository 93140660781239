import { APP_ROUTES, LOCAL_STORAGE_CONSTANTS } from "../constants";
import {
  columnDetailPageTabsValues,
  ColumnPageParamViewKeys,
} from "../pages/columndetailpage";

export function getColumnDetailPageUrl(
  id: string,
  tab?: ColumnPageParamViewKeys
): string {
  const recentlyVisitedTab =
    localStorage.getItem(
      LOCAL_STORAGE_CONSTANTS.recent_visited_column_detail_page_tab
    ) || columnDetailPageTabsValues.overview;

  const redirectTo = tab || recentlyVisitedTab;

  return `${APP_ROUTES.private_routes.column_detail}/${id}/${redirectTo}`;
}
