import { Tooltip } from "antd";
// <--- Library Imports Start --->
import { useQueryClient } from "react-query";
import { useCallback, useMemo } from "react";

import { useHistory } from "react-router";

import { AxiosResponse } from "axios";
// <--- Library Imports End --->

// <--- Components Start --->
import LinkButton from "../linkbutton";

import ProminentTags from "../prominenttagslist";
import CoreNodesCircleIcon from "../corenodetypescircleicon";

import PopularityScore from "../popularityscore";
import Flex from "../flex";

import StateHandler from "../statehandler/statehandler";
import SuccessNotificationMessage from "../successnotificationmessagerendrer/successnotificationmessagerendrer";
// <--- Components End --->

// <--- Constants Start --->
import { API_CONFIG } from "../../constants/apiconfig";

import {
  APP_ROUTES,
  DVSUM_TOOLTIP_CLASS_NAME,
  ENDORSE_ELEM_ID_MAPPING,
  FOLLOW_ELEM_ID_MAPPING,
} from "../../constants";
// <--- Constants End --->

// <--- Renderers Start --->
import NodeStatusRendrer from "../nodestatusrendrer";
// <--- Renderers End --->

// <--- Services Start --->
import { useGetSocialNode } from "../../api/socialnodeservice/socialnodeservice";
import { appendQueryParamInUrl, useRequestWithMethod } from "../../api";
// <--- Services End --->

// <--- Styles Start --->
import {
  DetailPagesHeaderStyled,
  RightSectionWrapperStyled,
  SectionHeaderMetaWrapperStyled,
  SectionSubHeaderWrapperStyled,
  SectionTagsWrapperStyled,
  WizardDescriptionStyled,
} from "./detailpagesheader.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import {
  chevronRight,
  dateIcon,
  endorseIcon,
  eyeIcon,
  redirectCircledIcon,
} from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import {
  DetailPagesHeaderProps,
  RightSecData,
  RightSecDataActionId,
} from "./detailpagesheader.types";

import { SocialNodeResponse } from "../../parsers/socialnode/socialnodeparser.types";
import { TableDocResponse } from "../../parsers/tablepage/tablepageparser.types";

import { NodePublishedStatusIds } from "../../parsers/parser.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  getDetailpageUrlBasedOnNodeType,
  getFiltersfromLocalStorage,
  jsonParse,
  openNotification,
} from "../../utils";
import Wizard from "../wizard/wizard";

import { RenderUserAndStatus } from "./detailpagesheader.components/renderuserandstatus";
import { StepUserReturnType } from "../../parsers/termsparser/termsparser.types";

import { VerticalDividerStyled } from "../dividers/dividers.styles";
import StyledBreadcrum from "../styledbreadcrum";

import StyledLink from "../styledlink/styledlink";
import { useGetAppType } from "../../customhooks";

import { useCalculateContainerWidth } from "../../customhooks/usecalculatecontainerwidth";
import ConditionalDisplay from "../conditionaldisplay";
import SuggestAnEditButton from "../suggestaneditbutton";
// <--- Utils End --->

const NUMBER_OF_APPROVERS_TO_SHOW_UPFRONT = 2;

function DetailPagesHeader(props: DetailPagesHeaderProps): JSX.Element {
  const {
    desc = "",
    source = [],
    title,
    nodeId,
    endorsedCount,
    nodeType,
    publishStatusId = "",
    publishStatus = "",
    prominentTags = [],
    nodeSubType,
    nodeUrl,
    popularityScoreData,
    hideWorkFlow = false,
    workflow,
    showRightSecButton = true,
    isRefView = false,
    isNodeScheduled = false,
    statusTags,
    isRedirectIconDisabled,
    onClickSuggest,
    showSuggestionButton,
  } = props;

  const showRightHeaderSection = showRightSecButton && !isRefView;

  const { workflowSteps = [], workflowStatus = "" } = workflow || {};

  const queryClient = useQueryClient();
  const history = useHistory();
  const { isExtOrDesktop } = useGetAppType();

  const {
    parsedData,
    isLoading: socialNodeLoading,
    error: socialNodeError,
  } = useGetSocialNode(nodeId);

  const onSuccess = useCallback(
    (response: AxiosResponse<SocialNodeResponse>) => {
      const action = jsonParse(response?.config?.data, true)?.action;

      const messageMapping: { [key: string]: string } = {
        unendorse: "We have noted that you removed your endorsement",
        endorse: "You have successfully endorsed",
        follow: "You have successfully followed",
        unfollow: "You have successfully unfollowed",
      };

      const socialNodeApi = appendQueryParamInUrl(
        API_CONFIG.get_social_node.url,
        [nodeId]
      );
      const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);
      const nodeAPi = appendQueryParamInUrl(API_CONFIG.get_node.url, [
        nodeId,
        nodeType,
        "PUB",
        "",
        "",
        parsedNodeFilter,
      ]);

      const nodeData = queryClient.getQueryData(
        nodeAPi
      ) as AxiosResponse<TableDocResponse>;

      queryClient.setQueryData(nodeAPi, {
        ...nodeData,
        data: {
          ...nodeData?.data,
          published_status_id:
            action === "unendorse" || action === "endorse"
              ? "PUB"
              : nodeData?.data?.published_status_id,
          endorsed_count:
            action === "unendorse"
              ? nodeData?.data?.endorsed_count - 1
              : action === "endorse"
              ? (nodeData?.data?.endorsed_count || 0) + 1
              : nodeData?.data?.endorsed_count,
        },
      });

      queryClient.setQueryData(socialNodeApi, response);

      openNotification(
        <SuccessNotificationMessage message={messageMapping?.[action]} />
      );
    },
    [parsedData]
  );

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    "add_endorse_action",
    [nodeId, nodeType],
    true,
    onSuccess
  );

  const onActionClick = useCallback(
    (id: RightSecDataActionId) => {
      const actionMapping: { [key in typeof id]?: string } = {
        endorse: parsedData?.is_endorsed ? "unendorse" : "endorse",
        follow: parsedData?.is_followed ? "unfollow" : "follow",
      };

      onExecuteRequest(
        { action: actionMapping[id] },
        undefined,
        undefined,
        id === "follow" ? "add_follow_action" : "add_endorse_action"
      );
    },
    [props, parsedData]
  );

  const rightSecData: RightSecData = useMemo(
    () => [
      {
        name: parsedData?.is_endorsed ? "Endorsed" : "Endorse",
        icon: endorseIcon(),
        count: endorsedCount,
        id: "endorse",
        selected: parsedData?.is_endorsed,
        elemId: ENDORSE_ELEM_ID_MAPPING?.[nodeType] || "",
      },
      {
        name: parsedData?.is_followed ? "Followed" : "Follow",
        icon: eyeIcon(),
        id: "follow",
        selected: parsedData?.is_followed,
        elemId: FOLLOW_ELEM_ID_MAPPING?.[nodeType] || "",
      },
      // { name: "More", icon: actionsIcon(), id: "share" },
    ],
    [props, parsedData]
  );

  const renderCustomJsxForWizardDescription = useCallback(
    (props?: string | StepUserReturnType[]): JSX.Element => {
      const stepDetails = workflowSteps?.find(
        (item) => item?.stepName === props
      );
      const stepDesc = stepDetails?.stepDescription || "";
      const showStepUserStatus = stepDetails?.showStepUserStatus;
      return (
        <>
          {Array.isArray(stepDesc) ? (
            <WizardDescriptionStyled className="description-list">
              {stepDesc
                ?.slice(0, NUMBER_OF_APPROVERS_TO_SHOW_UPFRONT)
                ?.map((item) => (
                  <RenderUserAndStatus
                    {...item}
                    key={`${item?.userId}`}
                    showStatus={showStepUserStatus}
                  />
                ))}
              {stepDesc?.length > NUMBER_OF_APPROVERS_TO_SHOW_UPFRONT && (
                <Tooltip
                  title={stepDesc
                    ?.slice(
                      NUMBER_OF_APPROVERS_TO_SHOW_UPFRONT,
                      stepDesc?.length
                    )
                    ?.map((item) => (
                      <RenderUserAndStatus
                        {...item}
                        key={`${item?.userId}`}
                        showStatus={showStepUserStatus}
                      />
                    ))}
                  overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME}  `}
                >
                  <span className="more">
                    <b>
                      +{stepDesc?.length - NUMBER_OF_APPROVERS_TO_SHOW_UPFRONT}
                    </b>
                  </span>
                </Tooltip>
              )}
            </WizardDescriptionStyled>
          ) : (
            stepDesc
          )}
        </>
      );
    },
    [workflowSteps]
  );

  const activeStep = useMemo(() => {
    const inProgressStep = workflowSteps?.find(
      (item) => item?.stepStatus === "INP"
    );

    return inProgressStep?.stepNum || 1;
  }, [workflowSteps]);

  const workflowTemplateSteps =
    workflowSteps?.map((item) => item?.stepName || "") || [];

  const isWorkflowInProgress = useMemo(() => workflowStatus === "INP", [
    workflowStatus,
  ]);

  const { isOpeningInNewTab, toUrl } = useMemo(() => {
    const isOpeningInNewTab =
      history?.location?.pathname?.includes(
        APP_ROUTES.private_routes.ai_agent
      ) ||
      history?.location?.pathname?.includes(APP_ROUTES.private_routes.chat);

    const url = getDetailpageUrlBasedOnNodeType(nodeId, nodeType, nodeSubType);

    return {
      isOpeningInNewTab: isExtOrDesktop || isOpeningInNewTab,
      toUrl: url,
    };
  }, [isExtOrDesktop, nodeId, nodeSubType, nodeType]);

  const showNodeUrl = nodeUrl && !isRefView;

  const isProminentTagExists = !!prominentTags?.length;
  const isStatusTagExists = !!statusTags?.tags?.length;

  const isPopularityScoreExists =
    nodeType === "TBL" && popularityScoreData?.isShow;

  const prominentTagsJsx = (isProminentTagExists ||
    isPopularityScoreExists) && (
    <Flex direction="row" columnGap={10.4} customClass="prominent-tags-rendrer">
      {isProminentTagExists && (
        <ProminentTags tags={prominentTags} isBoldView />
      )}
      {isPopularityScoreExists && (
        <PopularityScore
          {...popularityScoreData}
          isShowBorderLeft={isProminentTagExists}
        />
      )}
    </Flex>
  );

  const stausTagsJsx = isStatusTagExists && (
    <Flex direction="row" columnGap={10.4} customClass="status-rendrer">
      <ProminentTags tags={statusTags?.tags} isBoldView />
    </Flex>
  );

  const {
    ref: containerRef,
    width: containerWidth,
  } = useCalculateContainerWidth();

  const isWorkFlowVisible = isWorkflowInProgress && !hideWorkFlow;

  return (
    <DetailPagesHeaderStyled
      className="page-header"
      isWorkflowInProgress={isWorkFlowVisible}
      isRefView={isRefView}
      ref={containerRef}
    >
      <div className="left">
        {!!source?.length && (
          <StyledBreadcrum items={source} marginBottom="10px" />
        )}
        <SectionHeaderMetaWrapperStyled>
          <CoreNodesCircleIcon nodeType={nodeType} nodeSubType={nodeSubType} />

          <div className="info-title" title={title}>
            {title}
          </div>

          {publishStatusId && (
            <NodeStatusRendrer
              status={publishStatusId as NodePublishedStatusIds}
              tooltipTitle={publishStatus}
              nodeType={nodeType}
            />
          )}

          {isNodeScheduled && <div className="scheduled-icon">{dateIcon}</div>}

          <SectionTagsWrapperStyled
            className={containerWidth > 620 ? "large-screen-tags-section" : ""}
          >
            {prominentTagsJsx}
            {stausTagsJsx}
          </SectionTagsWrapperStyled>

          {isRefView && (
            <LinkButton
              disabled={isRedirectIconDisabled}
              className="asset-redirect-icon-wrapper"
            >
              <StyledLink
                to={toUrl}
                cutomClassName="asset-redirect-icon"
                target={isOpeningInNewTab ? "_blank" : "_self"}
              >
                {redirectCircledIcon("24", "24")}
              </StyledLink>
            </LinkButton>
          )}
        </SectionHeaderMetaWrapperStyled>

        {showSuggestionButton && (
          <SuggestAnEditButton
            nodeType={nodeType}
            nodeId={nodeId}
            isDrawer={!isRefView}
            onClick={onClickSuggest}
            nodeDesc={desc}
            nodeTitle={title}
            marginLeft="32px"
          />
        )}

        {/* {(desc || showNodeUrl) && ( */}
        <SectionSubHeaderWrapperStyled
          isWorkflowInProgress={isWorkflowInProgress}
          isRefView={isRefView}
        >
          <div className="node-tech-name">
            <span title={desc} className="desc">
              {desc}
            </span>
            {showNodeUrl && <VerticalDividerStyled height="12px" width="1px" />}
          </div>

          {showNodeUrl && (
            <div className={`node-url-sec ${!desc ? "link-only" : ""}`}>
              <span className="node-url-label">
                Link to {nodeSubType === "RPT" ? "Report" : "URL"}
              </span>
              <LinkButton
                href={nodeUrl}
                target="_blank"
                className="node-url"
                showEllipsis
                title={nodeUrl}
                isTextUnderLine
              >
                {nodeUrl}
              </LinkButton>
            </div>
          )}
        </SectionSubHeaderWrapperStyled>
        {/* )} */}

        <SectionTagsWrapperStyled
          className={containerWidth < 620 ? "small-screen-tags-section" : " "}
        >
          {prominentTagsJsx}
          {stausTagsJsx}
        </SectionTagsWrapperStyled>
      </div>

      <div className="right">
        <ConditionalDisplay condition={showRightHeaderSection}>
          <StateHandler
            isFetching={false}
            isShowBlankSlate={false}
            error={error || socialNodeError}
          >
            <RightSectionWrapperStyled>
              {isWorkFlowVisible ? (
                <div className="term-page-wizard-container">
                  <Wizard
                    currentPageNumber={activeStep}
                    listOfPages={workflowTemplateSteps}
                    renderDescription={renderCustomJsxForWizardDescription}
                  />
                </div>
              ) : (
                rightSecData?.map((item: RightSecData[number]) => (
                  <LinkButton
                    key={`pageheader-btn-${item?.name}`}
                    onClick={(): void => {
                      item?.id !== "share" && onActionClick(item?.id);
                    }}
                    disabled={isLoading || socialNodeLoading}
                    elementId={item?.elemId}
                  >
                    <div
                      className={`action-icon-sec ${
                        item?.selected ? "selected" : ""
                      }`}
                    >
                      {item?.icon}
                      {!!item?.count && (
                        <div className="endorsed-count">{item?.count}</div>
                      )}
                    </div>
                    <div>{item.name}</div>
                  </LinkButton>
                ))
              )}
            </RightSectionWrapperStyled>
          </StateHandler>
        </ConditionalDisplay>
      </div>
    </DetailPagesHeaderStyled>
  );
}

export default DetailPagesHeader;
