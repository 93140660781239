import { useCallback } from "react";

import LinkButton from "../linkbutton";
import NodeStatusRendrer from "../nodestatusrendrer";

import {
  AdditionalInfoStyled,
  SimilarAssetCardStyled,
} from "./similarassetcard.styles";

import { redirectCircledIcon } from "../../svgs";

import { SimalrAssetCardProps } from "./similarassetcard.types";

import CoreNodesCircleIcon from "../corenodetypescircleicon";
import StyledBreadcrum from "../styledbreadcrum";

import {
  getDetailpageUrlBasedOnNodeType,
  getDrawerIdBasedOnNodeType,
} from "../../utils";

import { useOpenDrawer } from "../../customhooks";

const SimilarAssetCard = (props: SimalrAssetCardProps): JSX.Element => {
  const { data } = props;

  const openDrawer = useOpenDrawer();

  const {
    nodeType,
    additionalInfo,
    desc = "",
    title = "",
    nodeId = "",
    breadcrumb = [],
    nodeSubType,
    status,
  } = data || {};

  const { id: nodeStatusId, value } = status || {};

  const onMoreDetailsClick = useCallback(() => {
    const url = getDetailpageUrlBasedOnNodeType(nodeId, nodeType);
    window.open(url, "_blank");
  }, [nodeId, nodeType]);

  const onTitleClick = useCallback(() => {
    openDrawer({
      drawerId: getDrawerIdBasedOnNodeType(nodeType, nodeSubType),
      visible: true,
      drawerProps: { id: nodeId, nodeType, nodeSubType },
    });
  }, [nodeId, nodeType, nodeSubType]);

  return (
    <SimilarAssetCardStyled>
      <div className="body">
        <div className="header">
          <StyledBreadcrum items={breadcrumb} marginBottom="16px" />
        </div>
        <div className="related-obg-title">
          <CoreNodesCircleIcon
            nodeType={nodeType}
            nodeSubType={nodeSubType}
            marginRight="10px"
            fontSize="11px"
          />
          <div className="wrapper">
            <div className="info-title-sec">
              <LinkButton
                className="info-title"
                onClick={onTitleClick}
                showEllipsis
                fontFamily="OpenSansSemiBold"
                fontSize="19px"
                title={title}
              >
                {title}
              </LinkButton>
              {nodeStatusId && (
                <NodeStatusRendrer
                  status={nodeStatusId}
                  tooltipTitle={value}
                  className="info-status"
                  nodeType={nodeType}
                />
              )}
            </div>
          </div>
        </div>

        <div className="description" title={desc}>
          {desc || "--"}
        </div>

        <div className="additional-info">
          {additionalInfo?.length > 1 ? (
            additionalInfo.map((info, index) => {
              const { title = "", value = "" } = info || {};

              return (
                <AdditionalInfoStyled key={`${title}-${value}-${index}`}>
                  <div className="title" title={title}>
                    {title}
                  </div>
                  <div className="value" title={value}>
                    {value || "--"}
                  </div>
                </AdditionalInfoStyled>
              );
            })
          ) : (
            <div className="extra-additional-info">
              {additionalInfo?.[0]?.value}
            </div>
          )}
        </div>
      </div>
      <div className="footer">
        <LinkButton
          onClick={onMoreDetailsClick}
          type="link"
          className="more-details"
        >
          More Details
        </LinkButton>
        <LinkButton onClick={onMoreDetailsClick} type="link">
          {redirectCircledIcon("24", "24")}
        </LinkButton>
      </div>
    </SimilarAssetCardStyled>
  );
};

export default SimilarAssetCard;
