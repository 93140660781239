import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  AssetDescriptionEditableViewContentProps,
  GenericFormValues,
} from "./desceditableview.types";

import {
  AssetDescEditableViewWrapperStyled,
  ReplaceItemRendererStyled,
} from "./desceditableview.styles";

import SectionTitleWithIcon from "../sectiontitlewithicon";
import { CheckboxField } from "../formfields";

import { getInputField } from "./desceditableview.utils";
import BusinessNameEditInfoMessage from "../businessnameeditinfo";

import {
  AssetFieldConfigType,
  AssetFieldIds,
  getAssetConfig,
} from "../../parsers/asssetoverviewparser";

import { SelectFieldOption } from "../formfields/selectfield/selectfield.types";
import { ListOfAllTermItemType } from "../../parsers/termsparser";

import { deleteIcon1, infoIcon, termIcon, workflowIcon } from "../../svgs";

import LinkButton from "../linkbutton";
import { useOpenDrawer } from "../../customhooks";

import {
  getDetailpageUrlBasedOnNodeType,
  nodesDescriptionEditableViewValueUpdation,
} from "../../utils";

import StyledLink from "../styledlink/styledlink";
import Button from "../button";

import Flex from "../flex";

import Alert from "../alert/alert";
import CoreNodesCircleIcon from "../corenodetypescircleicon";

import { getSubdomainValue } from "../assetoverview/assetoverview.renderer";

import {
  REQUIRED_MESSAGE,
  TITLE_MAX_CHA_LIMIT,
  TITLE_MAX_RANGE,
} from "../../constants/formconstants";

const termSvg = termIcon("11", "11");
const deleteSvg = deleteIcon1("11", "11");

const AssetDescriptionEditableView = (
  props: AssetDescriptionEditableViewContentProps
): JSX.Element => {
  const {
    setState,
    id,
    state,
    nodeType,
    nodeSubType,
    nodeId = "",
    className,
    isRefViewEdit = false,
    onQuickEditSave,
    onChangeWorkflowStatus,
    listOfColTypes,
    listOfDomains,
    listOfSubDomains,
    listOfTermSubTypes,
  } = props;

  const openDrawer = useOpenDrawer();

  const [searchTextForSelect, setSearchTextForSelect] = useState("");
  const [showWorkflowAlert, setShowWorkflowAlert] = useState(false);

  const [replacesList, setReplacesList] = useState<
    { id: string; name: string }[]
  >(
    state?.[id]?.data?.find((item) => item?.id === AssetFieldIds.replaces)
      ?.replaces || []
  );

  const addedReplacesIds = replacesList?.map((item) => item?.id);

  const currentAssetConfig = getAssetConfig(nodeType);

  const descConfig = currentAssetConfig?.[id] || {};
  const descFormFieldsConfig = descConfig?.fields || [];

  const editableFields = isRefViewEdit
    ? descFormFieldsConfig?.filter((item) => {
        const { isRowEditableInQuickEdit = true, isEditable } = item || {};
        return isEditable && isRowEditableInQuickEdit;
      })
    : descFormFieldsConfig?.filter((item) => {
        const { isRowVisibleInEditView = true, isEditable } = item || {};
        return isEditable && isRowVisibleInEditView;
      });

  const columnTypeSelectOptions = useMemo(() => {
    return (
      listOfColTypes?.map((colType) => ({
        value: colType?.id,
        label: colType?.name,
      })) || []
    );
  }, [listOfColTypes]);

  const termTypesOption = useMemo(() => {
    const filteredTermTypeOpt = listOfTermSubTypes?.filter((item) =>
      item?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchTextForSelect?.toString()?.toLowerCase())
    );

    const finalOptions =
      filteredTermTypeOpt?.map((trmType) => ({
        value: trmType?.id,
        label: trmType?.name,
      })) || [];

    return finalOptions?.length
      ? [
          {
            label: "Select a sub type",
            value: "",
            options: finalOptions,
          },
        ]
      : [];
  }, [listOfTermSubTypes, searchTextForSelect]);

  const domainOptions = useMemo(() => {
    return listOfDomains?.map((item) => ({
      ...item,
      label: (
        <Flex alignItems="center" columnGap={5}>
          {item?.label}
          {item?.isWorkflowEnabled && workflowIcon("14", "14")}
        </Flex>
      ),
    }));
  }, [listOfDomains]);

  const subDomainOptions = useMemo(() => {
    const subDomainOpts = listOfSubDomains?.map((item) => {
      const { categoryName = "", categoryId = "", domainName = "" } =
        item || {};

      return {
        value: String(categoryId),
        label: getSubdomainValue(item, false),
        labelText: `${domainName} ${categoryName}`,
      };
    });

    const filteredTermCategoryOpt = subDomainOpts?.filter((item) =>
      item?.labelText
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchTextForSelect?.toString()?.toLowerCase())
    );

    return filteredTermCategoryOpt?.length
      ? [
          {
            label: "Select the below Domain > Sub-Domain",
            value: "",
            options: filteredTermCategoryOpt,
          },
        ]
      : [];
  }, [listOfSubDomains, searchTextForSelect]);

  const onReplacesSelect = useCallback(
    (selectedObj: ListOfAllTermItemType) => {
      setReplacesList([
        ...replacesList,
        {
          id: selectedObj?.nodeId || "",
          name: selectedObj?.nodeName || "",
        },
      ]);
    },
    [replacesList]
  );

  const onDataDomainSelect = useCallback(
    (option: string) => {
      const selectedSubDomain = listOfSubDomains?.find(
        (category) => parseInt(category?.categoryId) === parseInt(option)
      );

      const selectedDomain = listOfDomains?.find(
        (domain) => domain?.value === option
      );
      const isGovernedDomain = selectedDomain?.isWorkflowEnabled || false;

      const isGovernedSubDomain =
        selectedSubDomain?.isWorkflowEnabled ||
        selectedSubDomain?.isWorkflowInherited ||
        false;

      const isGoverned = isGovernedDomain || isGovernedSubDomain;

      onChangeWorkflowStatus?.(isGoverned);
      setShowWorkflowAlert(isGoverned);
    },
    [listOfDomains, listOfSubDomains]
  );

  const buildValidationSchema = (
    fields: AssetFieldConfigType[]
  ): yup.ObjectSchema<Record<string, yup.StringSchema<string | undefined>>> => {
    const shape = fields?.reduce((schema, field) => {
      let fieldSchema = yup.string();

      const fieldId = field?.id || "";
      const isFieldRequired = field?.isRequired ?? false;
      const addMaxLimit = field?.hasMaxLimit ?? false;

      // Apply required rule

      if (isFieldRequired)
        fieldSchema = fieldSchema?.required(REQUIRED_MESSAGE);
      else fieldSchema = fieldSchema?.notRequired();

      // Apply max limit if specified
      if (addMaxLimit) {
        fieldSchema = fieldSchema.max(TITLE_MAX_CHA_LIMIT, TITLE_MAX_RANGE);
      }

      schema[fieldId] = fieldSchema;

      return schema;
    }, {} as Record<string, yup.StringSchema>);

    return yup.object().shape(shape);
  };

  const schema = buildValidationSchema(editableFields);

  const formDefaultValues = {
    ...state[id]?.data
      ?.filter((item) => item?.isEditable)
      ?.reduce(
        (prev, item) => ({
          ...prev,
          [item?.id as string]:
            item?.type === "select"
              ? (item?.selectedValues || [])?.[0] || undefined
              : item?.type === "checkbox"
              ? !!item?.value
              : item?.value,
        }),
        {}
      ),
  };

  const formConfig = useForm<GenericFormValues>({
    resolver: yupResolver(schema),

    mode: "onChange",
    defaultValues: formDefaultValues,
  });

  const { watch, formState, control, setValue, getValues } = formConfig;

  const watchedValues = watch();

  const onCancel = useCallback(() => {
    setState((st) => ({
      ...st,
      [id]: { ...st?.[id], isEditable: false, isRefViewEditable: false },
    }));
  }, [props]);

  const getSelectFieldOptionsBasedOnId = (id: string): SelectFieldOption[] => {
    switch (id) {
      case AssetFieldIds.columnType:
        return columnTypeSelectOptions || [];
      case AssetFieldIds.termType:
        return termTypesOption || [];
      case AssetFieldIds.dataDomain:
        return domainOptions || [];
      case AssetFieldIds.subDomain:
        return subDomainOptions || [];
      default:
        return [];
    }
  };

  const getSelectFieldPlaceholder = (id: string): string => {
    switch (id) {
      case AssetFieldIds.columnType:
        return "Select Column Type";
      case AssetFieldIds.termType:
        return "Select Term Type";
      case AssetFieldIds.entity:
        return "Select Entity";
      case AssetFieldIds.dataDomain:
        return "Select Data Domain";
      case AssetFieldIds.subDomain:
        return "Select Sub Domain";
      default:
        return "";
    }
  };

  const getAlreadyAddedIds = (id: string): string[] => {
    switch (id) {
      case AssetFieldIds.replaces:
        return addedReplacesIds;

      default:
        return [];
    }
  };

  const onClick = useCallback(
    (id) => (): void => {
      openDrawer({
        drawerId: "term_ref",
        visible: true,
        drawerProps: { id },
      });
    },
    [props]
  );

  const onDelete = useCallback(
    (id) => (): void => {
      setReplacesList(replacesList?.filter((item) => item?.id !== id));
      replacesList?.length === 1 &&
        setValue("replaced_terms_deprecated", false);
    },
    [props, replacesList]
  );

  const isSelctedTypeComputedMeasure = watchedValues?.term_type === "CMM";

  const onSave = useCallback(() => {
    setState((st) => ({
      ...st,
      [id]: {
        ...st?.[id],
        isEditable: false,
        isEdited: true,
        data: st?.[id]?.data?.map((obj) => {
          const { id = "none", isRowVisibleInNormalView = true } = obj || {};

          const selectOptions = getSelectFieldOptionsBasedOnId(id);

          const optionsWithLabel = selectOptions?.[0]?.options;
          const optionsWithLabelExists = !!optionsWithLabel;

          const options = optionsWithLabelExists
            ? optionsWithLabel
            : selectOptions;

          const selectedSubDomain = watchedValues?.sub_domain;

          const selectedCategory = listOfSubDomains?.find(
            (item) => Number(item?.categoryId) === Number(selectedSubDomain)
          );

          return {
            ...nodesDescriptionEditableViewValueUpdation(
              obj,
              watchedValues,
              options
            ),
            selectedCategoryInfo: selectedCategory,

            isRowVisibleInNormalView:
              id === AssetFieldIds.formula
                ? isSelctedTypeComputedMeasure
                : id === AssetFieldIds.replaces
                ? !!replacesList?.length
                : id === AssetFieldIds.previouslyKnownAs
                ? !!watchedValues?.previously_known_as
                : isRowVisibleInNormalView,

            replaces: id === AssetFieldIds?.replaces ? replacesList : [],
            replaced_terms_deprecated:
              id === AssetFieldIds.replacedTermsDeprecated
                ? watchedValues?.replaced_terms_deprecated
                : false,
          };
        }),
      },
    }));
  }, [props, watchedValues, state]);

  const getEditAllUrl = useCallback(() => {
    const url = getDetailpageUrlBasedOnNodeType(
      nodeId,
      nodeType,
      nodeSubType,
      "overview"
    );

    return url;
  }, [nodeId, nodeType, nodeSubType]);

  const onQuickEditSaveChanges = useCallback(() => {
    const formValues = getValues();

    onQuickEditSave?.(formValues);
  }, [onQuickEditSave]);

  return (
    <AssetDescEditableViewWrapperStyled>
      <SectionTitleWithIcon
        title={currentAssetConfig?.[id]?.sectionName}
        isEditable
        marginBottom="25px"
        onSave={onSave}
        onCancel={onCancel}
        isDisabledSaveButton={!formState?.isValid}
        className="section-title"
      />
      <div className={`desc-editableView ${className}`}>
        {/* <FormProvider {...formConfig}> */}
        {editableFields?.map((field) => {
          const { id = "none", isRequired = false, title = "", type } =
            field || {};

          const isCheckBoxField = type === "checkbox";

          const isBusineesNameRow = id === AssetFieldIds.businessName;
          const isReplacesFieldRow = id === AssetFieldIds.replaces;
          const isDataDomainFieldRow = id === AssetFieldIds.dataDomain;
          const isSubDomainFieldRow = id === AssetFieldIds.subDomain;
          const isFormulaFieldRow = id === AssetFieldIds.formula;

          const isDomainOrSubdomainFieldRow =
            isDataDomainFieldRow || isSubDomainFieldRow;

          const isFieldRequired = isRequired;

          const titleJsx = (
            <div className="desc-row-title">
              {title}
              {!isCheckBoxField && ":"}
              {isFieldRequired && <span className="required-field">*</span>}
            </div>
          );

          const selectOptions = getSelectFieldOptionsBasedOnId(id);
          const isOptionsLoading = false;
          const selectPlaceholder = getSelectFieldPlaceholder(id);
          const addedIds = getAlreadyAddedIds(id);

          const isAddedIdsExists = addedIds?.length > 0;

          const onChangeCallback = isDomainOrSubdomainFieldRow
            ? onDataDomainSelect
            : isReplacesFieldRow
            ? onReplacesSelect
            : undefined;

          const inputFieldJsx = getInputField({
            fieldItem: field,
            control,
            setValue,
            selectOptions,
            isOptionsLoading,
            selectPlaceholder,
            alreadySelectedIds: addedReplacesIds,
            propsOnSelect: onChangeCallback,
          });

          const shouldShowFormula = isSelctedTypeComputedMeasure;

          if (isFormulaFieldRow && !shouldShowFormula) {
            return <div key={`formula-row-${id}`} />;
          }

          return (
            <div
              className={`main-row ${isCheckBoxField ? "is-checkbox-row" : ""}`}
              key={id}
            >
              {!isCheckBoxField && titleJsx}

              <div className="row-value">
                {inputFieldJsx}

                {isBusineesNameRow && <BusinessNameEditInfoMessage />}

                {isDomainOrSubdomainFieldRow && showWorkflowAlert && (
                  <Alert
                    margin="10px 0"
                    icon={infoIcon}
                    showIcon
                    type="info"
                    message="After publishing these changes any modification later on the current asset will require approval"
                    useFlexStart
                  />
                )}

                {isReplacesFieldRow && (
                  <>
                    <div className="deprecated-term-sec">
                      <CheckboxField
                        name={AssetFieldIds.replacedTermsDeprecated}
                        control={control}
                        disabled={!replacesList?.length}
                      />
                      <span>
                        Change status of all selected terms as deprecated
                      </span>
                    </div>
                    <div className="added-deprecated-term-sec">
                      {replacesList?.map((item) => {
                        const { id = "", name = "" } = item || {};
                        return (
                          <ReplaceItemRendererStyled
                            className="replace-rendrer"
                            key={`replaces-item-${id}`}
                          >
                            <CoreNodesCircleIcon
                              width="15px"
                              height="15px"
                              nodeType="TRM"
                              fontSize="9px"
                            />
                            <span>
                              <span role="button" onClick={onClick(id)}>
                                {name}
                              </span>
                            </span>
                            <LinkButton onClick={onDelete(id)}>
                              {deleteSvg}
                            </LinkButton>
                          </ReplaceItemRendererStyled>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
              {isCheckBoxField && titleJsx}
            </div>
          );
        })}

        {isRefViewEdit && (
          <div className="edit-ref-form-footer">
            <div className="left">
              <StyledLink
                to={getEditAllUrl()}
                stateData={{ isEdit: true }}
                searchText="isEdit=true"
              >
                Edit All
              </StyledLink>
            </div>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              marginLeft="12px"
              htmlType="submit"
              disabled={!formState?.isValid}
              onClick={onQuickEditSaveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
        {/* </FormProvider> */}
      </div>
    </AssetDescEditableViewWrapperStyled>
  );
};

export default AssetDescriptionEditableView;
