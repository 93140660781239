import { useCallback, useState, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import SectionTitleWithIcon from "../../sectiontitlewithicon";
import { BusinessRuleEditView } from "./sectionwitheditablerules.components";
import BusinessRuleReadOnlyView from "./sectionwitheditablerules.components/businessrulereadonlyview/businessrulereadonlyview";

import { SectionWithEditableBuisenessRulesStyled } from "./sectionwitheditablebuisinessrules.styles";
import { SectionWithEditableBuisenessRulesProps } from "./sectionwitheditablebuisinessrules.types";
import {
  getBuisenessRulesForTerm,
  sortBusinessRulesValues,
} from "./sectionwitheditablebusinessrules.utils";
import { buisinessRuleEditableViewSchema } from "../../../utils/schemas/businessruleeditableviewschema";

function SectionWithEditableBuisenessRules(
  props: SectionWithEditableBuisenessRulesProps
): JSX.Element {
  const {
    secTitle,
    state,
    updatedTermType,
    setState,
    isDefaultTerm,
    isRefView,
  } = props;

  const [copyOfBusinessRuleState, setCopyOfBusinessRuleState] = useState(state);

  const rulesConfig = useMemo(
    () => getBuisenessRulesForTerm(updatedTermType, state),
    [updatedTermType, state]
  );

  const formProvider = useForm<{
    manual: string;
    min_value: string;
    max_value: string;
  }>({
    mode: "onChange",
    resolver: yupResolver(buisinessRuleEditableViewSchema(updatedTermType)),
    defaultValues: { manual: "", max_value: "", min_value: "" },
  });

  const {
    setValue,
    watch,
    trigger,
    formState: { isValid },
  } = formProvider;

  const { max_value, min_value } = watch();

  const onEdit = useCallback(() => {
    setValue("manual", rulesConfig?.Manual?.value || "");
    if (updatedTermType === "BSM" || updatedTermType === "CMM") {
      setValue("max_value", `${rulesConfig?.["Value Range"]?.max_value}`);
      setValue("min_value", `${rulesConfig?.["Value Range"]?.min_value}`);
    }
    setState((st) => ({ ...st, isEditable: !st?.isEditable }));
    trigger();
  }, [state, watch(), rulesConfig]);

  const onCancel = useCallback(() => {
    setState((st) => ({ ...st, isEditable: !st?.isEditable }));
    setCopyOfBusinessRuleState(state);
  }, [state, copyOfBusinessRuleState]);

  const onSave = useCallback(() => {
    const { manual = "", max_value = "", min_value = "" } = watch();
    setState({
      ...sortBusinessRulesValues(copyOfBusinessRuleState),
      isEditable: false,
      ...((updatedTermType === "BSM" || updatedTermType === "CMM") && {
        "Value Range": { max: max_value, min: min_value },
      }),
      Manual: manual,
      isEdited: true,
    });
  }, [state, copyOfBusinessRuleState, watch()]);

  useEffect(() => {
    setCopyOfBusinessRuleState({ ...sortBusinessRulesValues(state) });
  }, [state]);

  useEffect(() => {
    trigger();
  }, [isValid, max_value, min_value]);

  return (
    <FormProvider {...formProvider}>
      <SectionWithEditableBuisenessRulesStyled
        isEditable={!!secTitle?.isShowIcon}
      >
        <SectionTitleWithIcon
          {...secTitle}
          marginBottom="25px"
          onEdit={onEdit}
          isEditable={state?.isEditable}
          onCancel={onCancel}
          onSave={onSave}
          isDisabledSaveButton={!formProvider?.formState?.isValid}
          isDisabledEditButton={isDefaultTerm}
          disabledEditBtnTooltipProps={
            isDefaultTerm
              ? {
                  title:
                    "Business rules cannot be updated for system created terms",
                  placement: "topRight",
                }
              : undefined
          }
        />
        {!state?.isEditable ? (
          <BusinessRuleReadOnlyView
            state={state}
            termType={updatedTermType}
            isRefView={isRefView}
          />
        ) : (
          <BusinessRuleEditView
            state={copyOfBusinessRuleState}
            setState={setCopyOfBusinessRuleState}
            termType={updatedTermType}
          />
        )}
      </SectionWithEditableBuisenessRulesStyled>
    </FormProvider>
  );
}

export default SectionWithEditableBuisenessRules;
