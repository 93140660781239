import { Tooltip } from "antd";
import { useFormContext } from "react-hook-form";

import { LinkedEntitySecStyled } from "./linkedentitysec.styles";
import { LinkedEntitySecProps } from "./linkedentitysec.types";

import { EntitySelection } from "../detailpagesdatatab/views/columnview/columneditview/columneditview.components";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";

import { aiSuggestIcon, userIconOutlined } from "../../svgs";
import ConfidenceScore from "../confidencescore";

const LinkedEntitySec = (props: LinkedEntitySecProps): JSX.Element => {
  const { state, isColRef = false, sourceConfig = [] } = props;

  const { isEditable } = state || {};

  const { watch } = useFormContext();
  const { entityId, matchingScore, matchSource } = watch();

  return (
    <LinkedEntitySecStyled
      isColRef={isColRef}
      className="asset-linked-entity-section"
    >
      <div className="entity-title-sec entity-row">
        <div className="row-title">Entity:</div>
        <div className="entity-title-value-sec">
          <EntitySelection
            isHideMenu={!isEditable}
            isColRef={isColRef}
            breadcrumbs={sourceConfig}
          />
        </div>
      </div>

      <div className="matching-score-sec entity-row">
        <div className="row-title">Matching Score:</div>
        <div className="matching-score-value-sec">
          {!!(entityId && matchingScore) && (
            <ConfidenceScore score={matchingScore} />
          )}
        </div>
      </div>

      <div className="match-source-sec entity-row">
        <div className="row-title">Match Source:</div>
        <div className="match-source-value-sec">
          {entityId && (
            <Tooltip
              overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
              title={
                matchSource === "AAI" ? "Matched by AI" : "Matched by User"
              }
              placement="left"
            >
              {matchSource === "AAI"
                ? aiSuggestIcon("16", "16")
                : userIconOutlined("15", "17")}
            </Tooltip>
          )}
        </div>
      </div>
    </LinkedEntitySecStyled>
  );
};

export default LinkedEntitySec;
