// <--- Library Imports Start --->
import { useEffect, useMemo, useRef, useState } from "react";
// <--- Library Imports End --->

// <--- Components Start --->
import BoldText from "../../components/boldtext";

import Checkbox from "../../components/checkbox";
import LinkButton from "../../components/linkbutton";

import IconWithSelectedState from "../../components/iconselectedstate";
import ProfilingCountProgressbar from "../../components/profilingcountprogressbar";

import DQScore from "../../components/dqscore";
import { MatchingStatusPreviewer } from "../../components/matchingstatus";

import IsCDEField from "../../components/iscdefield";
// <--- Components End --->

// <--- Constants Start --->
import { Y_AXIS_DATA_KEY } from "../../constants";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import { useGetAppType } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Parsers Start --->
import {
  ColRefClassEntityAddInfoType,
  ColumnDataType,
  ColumnRefDataQualityRulesAdditionalInfo,
  ColumnRefDataTypeAdditionalInfo,
  ColumnRefProfillingInfoAdditionalInfo,
  ColumnRefValiditityAdditionalInfo,
  ColumnRefVisDisAdditionalInfo,
} from "../../parsers";
// <--- Parsers End --->

// <--- Types Start --->
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  checkDataTypeOfColumn,
  getDataTypeIcon,
  getTermDetailPageUrl,
} from "../../utils";
import { useOutsideClick } from "../../utils/useoutsideclick";
// <--- Utils End --->

// <--- Styles Start --->
import {
  EntityRendrerStyled,
  VisualDistributionStyled,
} from "./colrefdrawer.styles";

import { LinkCollapseStyled } from "../../components/collapse/linkcollapse/linkcollapse.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { antiClockIcon } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { DQScoreProps } from "../../components/dqscore/dqscore.types";
import { IsCDEFieldProps } from "../../components/iscdefield/iscdefield.types";

import { COLUMN_SECTION_TITLES } from "../../constants/labelconstants";
import { LineChart, BarChart, ColumnChart } from "../../components/recharts";

import { COLUMN_VISUAL_DIST_DATA_KEY } from "../../parsers/columnparsers";
import StyledLink from "../../components/styledlink/styledlink";
// <--- Types End --->

const { Panel } = LinkCollapseStyled;

const {
  profiling_information: {
    visual_distribution: VISUAL_DISTRIBUTION,
    count: COUNT,
  },
  description: { meta_score: META_SCORE, data_type: DATA_TYPE, is_cde: IS_CDE },
  quality: { overall_score: OVERALL_SCORE, last_updated: LAST_UPDATED },
  classification: { entity: ENTITY },
  data_quality_rules: { auto_set: AUTO_SET },
} = COLUMN_SECTION_TITLES;

function VisualDistributuion(item: SectionRowProps): JSX.Element {
  const additionalInfo = item?.additionalInfo as ColumnRefVisDisAdditionalInfo;

  const {
    visual_distribution_chart_data: visualDistributionChartData = [],
    col_data_type_id: colDataTypeId = "BOL",
  } = additionalInfo || {};

  const { isNumberCol, isDateColumn } = checkDataTypeOfColumn(colDataTypeId);

  const isEmpty = !visualDistributionChartData?.length;

  const [selected, setSelected] = useState<boolean>(!isEmpty);
  const recordCoutRef = useRef(null);

  useOutsideClick(
    recordCoutRef,
    () => {
      setSelected(false);
    },
    [selected]
  );

  const chartHeight = visualDistributionChartData?.length * 60;

  const ChartComp = isDateColumn
    ? LineChart
    : isNumberCol
    ? ColumnChart
    : BarChart;

  const isBarChart = !isDateColumn && !isNumberCol;

  useEffect(() => {
    setSelected(!isEmpty);
  }, [isEmpty]);

  return (
    <VisualDistributionStyled ref={recordCoutRef}>
      <div className="main-row">
        <LinkCollapseStyled activeKey={selected ? ["record-count"] : []}>
          <Panel
            key="record-count"
            header={
              <>
                <div className="row-title">{item?.title}:</div>
                <div className="row-value">
                  {item?.value}
                  <IconWithSelectedState
                    selected={selected}
                    onClick={(): void => {
                      !isEmpty && setSelected(!selected);
                    }}
                    tooltipProps={{
                      title: isEmpty
                        ? "No data to show"
                        : `${selected ? "Hide" : "Show"} visual distribution`,
                      placement: "right",
                    }}
                    disabled={isEmpty}
                  >
                    {antiClockIcon()}
                  </IconWithSelectedState>
                </div>
              </>
            }
            showArrow={false}
          >
            <ChartComp
              height={
                isBarChart ? (chartHeight < 300 ? 300 : chartHeight) : 350
              }
              width="100%"
              data={visualDistributionChartData}
              dataKeys={[
                { dataKey: COLUMN_VISUAL_DIST_DATA_KEY, fill: "#4894ca" },
              ]}
              yAxis={[
                {
                  yAxisId: Y_AXIS_DATA_KEY,
                  label: { value: "" },
                  orientation: "left",
                  notApplyFormatting: true,
                },
              ]}
              showLedgends={false}
              margin={{ top: 40, bottom: 0 }}
              keepStaicBarSize={false}
              // customLabel
            />
          </Panel>
        </LinkCollapseStyled>
      </div>
    </VisualDistributionStyled>
  );
}

function EntityRendrer(item: SectionRowProps): JSX.Element {
  const addInfo = (item?.additionalInfo ?? {}) as ColRefClassEntityAddInfoType;

  const { isExtOrDesktop } = useGetAppType();

  const toUrl = useMemo(() => {
    const id = item?.selectedValueOfTags?.[0]?.value || "";
    const url = getTermDetailPageUrl(id);
    return url;
  }, [item]);

  return item?.value ? (
    <EntityRendrerStyled>
      <StyledLink to={toUrl} target={isExtOrDesktop ? "_blank" : "_self"}>
        <LinkButton isTextUnderLine>{item?.value}</LinkButton>
      </StyledLink>
      <MatchingStatusPreviewer
        isApproved={addInfo?.entity_matching_status === "APR"}
      />
    </EntityRendrerStyled>
  ) : (
    <span>--</span>
  );
}

export const colRefCustomRowRendrer: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  [VISUAL_DISTRIBUTION]: (item): JSX.Element => (
    <VisualDistributuion {...item} />
  ),
  [COUNT]: (item) => (
    <div className="col-ref-profiling-renderer">
      <ProfilingCountProgressbar
        data={
          (item?.additionalInfo as ColumnRefProfillingInfoAdditionalInfo)
            ?.profillingInfo
        }
        width="89%"
        minThreshhold={10}
      />
    </div>
  ),
  [META_SCORE]: (item) => <BoldText text={item?.value || ""} />,
  [OVERALL_SCORE]: (item) => (
    <DQScore {...(item?.additionalInfo as DQScoreProps)} showEmptyState />
  ),
  [DATA_TYPE]: (item) => (
    <div
      className="data-type-rendrer"
      title={
        (item?.additionalInfo as ColumnRefDataTypeAdditionalInfo)
          ?.data_type_info
      }
    >
      {getDataTypeIcon(item?.value as ColumnDataType)}
    </div>
  ),
  completeness: (item) => (
    <Checkbox
      checked={
        (item?.additionalInfo as ColumnRefDataQualityRulesAdditionalInfo)
          ?.checked
      }
      disabled
    />
  ),
  uniqueness: (item) => (
    <Checkbox
      checked={
        (item?.additionalInfo as ColumnRefDataQualityRulesAdditionalInfo)
          ?.checked
      }
      disabled
    />
  ),
  data_format: (item) => (
    <Checkbox
      checked={
        (item?.additionalInfo as ColumnRefDataQualityRulesAdditionalInfo)
          ?.checked
      }
      disabled
    />
  ),
  Validity: (item) => (
    <Checkbox
      checked={
        (item?.additionalInfo as ColumnRefValiditityAdditionalInfo)
          ?.completenes_value
      }
      disabled
    />
  ),
  [ENTITY]: (item) => <EntityRendrer {...item} />,
  [IS_CDE]: (item) => (
    <IsCDEField
      value={item?.value as IsCDEFieldProps["value"]}
      isUseFlex={false}
    />
  ),
};

export const colRefCustomCompRendrer: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  [AUTO_SET]: () => (
    <div className="autoset-static-text">Auto-set by DvSum</div>
  ),
  [LAST_UPDATED]: (item) => (
    <div className="last-updated-static-text">{item?.value}</div>
  ),
};
