import styled from "styled-components";

export const RecommendedRulesGridStyled = styled.div`
  .grid-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    min-height: 40px;

    .add-rule-btn {
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-family: OpenSansSemibold;
      font-size: 14px;
    }

    .page-name {
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      font-size: 24px;
      font-family: OpenSansSemiBold;
    }
  }

  .recommended-rules-divider {
    background: ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
    margin: 15px 0;
  }
`;

export const RuleActionsRendererStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
  margin-top: 4px;
`;
