import { useMemo } from "react";

import { AddedItem } from "../additionalinfo";
import Represents from "../represents";

import SectionBlankSlateText from "../sectionblankslatetext";
import SectionRow from "../sectionrow";

import SectionTitleWithIcon from "../sectiontitlewithicon";
import ManagedBySectionRow from "../managedbysectionrow";

import { SectionProps } from "./section.types";
import IconWithText from "../iconwithtext";

import { infoIcon } from "../../svgs";
import { FullWidthRow } from "./section.styles";

import Lineagepreviewer from "../lineagepreviewer";
import DataQualityPreviewer from "../dataqualitypreviewer";

function Section(props: SectionProps): JSX.Element {
  const { mode: sectionMode = "section_row" } = props;

  const content = useMemo(() => {
    switch (sectionMode) {
      case "section_grid":
        return (
          <SectionBlankSlateText isEmpty={!props?.secRowData?.length}>
            <div className="vertical-columns-container">
              {props?.secRowData?.map(({ mode = "section_column", ...item }) =>
                mode === "section_column" ? (
                  <ManagedBySectionRow
                    {...item}
                    key={`section-${item?.value}-${item?.title}`}
                  />
                ) : (
                  <SectionRow
                    {...item}
                    key={`section-${item?.value}-${item?.title}`}
                  />
                )
              )}
            </div>
          </SectionBlankSlateText>
        );
      case "section_column":
        return (
          <SectionBlankSlateText isEmpty={!props?.secRowData?.length}>
            <div>
              {props?.secRowData?.map(({ mode = "section_column", ...item }) =>
                mode === "section_column" ? (
                  <ManagedBySectionRow
                    {...item}
                    key={`section-${item?.value}-${item?.title}`}
                  />
                ) : (
                  <SectionRow
                    {...item}
                    key={`section-${item?.value}-${item?.title}`}
                  />
                )
              )}
            </div>
          </SectionBlankSlateText>
        );
      case "represents":
        return (
          <SectionBlankSlateText isEmpty={!props?.representsData?.length}>
            <Represents represents={props?.representsData} />
          </SectionBlankSlateText>
        );

      case "additional_info":
        return (
          <SectionBlankSlateText isEmpty={!props?.additionalInfoData?.length}>
            <>
              {props?.additionalInfoData?.map((item) => (
                <AddedItem
                  item={item}
                  isEditable={false}
                  key={`additionalinfo-${item?.id}`}
                />
              ))}
            </>
          </SectionBlankSlateText>
        );
      case "access_info":
        return (
          <SectionBlankSlateText isEmpty={!props?.additionalInfoData?.length}>
            <FullWidthRow>
              <div className="row-access-info">
                {props?.additionalInfoData &&
                  props?.additionalInfoData?.map((info) => (
                    <IconWithText
                      key={`section-${info?.id}`}
                      icon={infoIcon}
                      text={info.value}
                    />
                  ))}
              </div>
            </FullWidthRow>
          </SectionBlankSlateText>
        );
      case "lineage":
        return props?.lineageData ? (
          <Lineagepreviewer {...props?.lineageData} />
        ) : (
          <div />
        );
      case "data_quality":
        return props?.dataQualityData ? (
          <DataQualityPreviewer {...props?.dataQualityData} />
        ) : (
          <div />
        );
      default:
        return (
          <SectionBlankSlateText
            isEmpty={!props?.secRowData?.length}
            className={props?.secRowClassName || ""}
          >
            <>
              {props?.secRowData?.map(({ mode = "section_row", ...item }) =>
                mode === "section_row" ? (
                  <SectionRow
                    {...item}
                    key={`section-${item?.value}-${item?.title}`}
                  />
                ) : (
                  <ManagedBySectionRow
                    {...item}
                    key={`section-${item?.value}-${item?.title}`}
                  />
                )
              )}
            </>
          </SectionBlankSlateText>
        );
    }
  }, [props]);

  return (
    <div>
      {props?.secTitle?.title && <SectionTitleWithIcon {...props?.secTitle} />}
      {content}
    </div>
  );
}

export default Section;
