import { useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { useGetListOfTerms } from "../../api/termservice/termservice";

import { ReplacesSelectPropsType } from "./replacesselect.types";

import AutoComplete from "../autocomplete";
import { termItemRrenderForSelect, termTitleRenderForSelect } from "../rendrer";
import { useDebounce } from "../../customhooks";

const ReplacesSelect = (props: ReplacesSelectPropsType): JSX.Element => {
  const { alreadyAddedIds = [], propsOnSelect } = props;

  const { id: paramId } = useParams<{ id: string }>();

  const [searchText, setText] = useState<string>("");
  const { parsedData, refetch, status } = useGetListOfTerms(searchText);

  const onSelect = useCallback(
    (termId) => {
      const selectedObject = parsedData?.find(
        (item) => item?.nodeId === termId
      );

      propsOnSelect?.(selectedObject);
    },
    [propsOnSelect, parsedData]
  );

  const updatedData = parsedData?.length
    ? [
        {
          label: termTitleRenderForSelect("Select an entity"),
          options:
            parsedData?.map((item) => ({
              ...termItemRrenderForSelect(item),
              disabled:
                alreadyAddedIds?.includes(item?.nodeId) ||
                item?.nodeId === paramId,
            })) ?? [],
        },
      ]
    : [];

  const onSearch = useCallback(
    (val) => {
      setText(val);
    },
    [searchText]
  );

  const searchDebounce = useDebounce(searchText, 500);

  useEffect(() => {
    searchDebounce && refetch();
  }, [searchDebounce]);

  return (
    <AutoComplete
      placeholder="Search for terms"
      dropdownClassName="search-results-dropdown"
      options={updatedData}
      listHeight={150}
      width="100%"
      virtual
      status={status}
      onSelect={onSelect}
      onSearch={onSearch}
      searchValue={searchText}
      value={searchText}
      data={parsedData}
    />
  );
};

export default ReplacesSelect;
