import { useCallback, useRef, useState } from "react";
import { Transfer as AntdTransfer } from "antd";

import { TransferProps } from "./transfer.types";
import { TransferStyled } from "./transfer.styles";
import { searchIcon1 } from "../../svgs";

import { TransferUpDownSection } from "./transfer.components";
import { transferBlankSlate } from "../../blankslates/transferblankslate";

function Transfer(props: TransferProps): JSX.Element {
  const {
    setState,
    state,
    transferProps = {},
    leftTile,
    rightTile,
    showError = true,
    errorMessage = "Please select at least one item",
    minItems = 1,
    showUpDownComp = false,
    notFoundMessage,
    onSelectedKeysChange,
    maxItemsInTarget,
    getSearchValue,
    loading = false,
  } = props;

  const [touch, setTouch] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<{
    sourceKeys: string[];
    targetKeys: string[];
  }>({ sourceKeys: [], targetKeys: [] });

  const isCtrlKeyPressed = useRef<any>();

  const taregtKeysLength =
    selectedKeys?.sourceKeys?.length + state?.targetKeys?.length;

  const isRightTransferActionDisable = maxItemsInTarget
    ? taregtKeysLength > maxItemsInTarget
    : false;

  const onChange = useCallback(
    (
      targetKeys: string[],
      direction: "left" | "right",
      selectedKeys: string[]
    ) => {
      setTouch(true);

      if (direction === "right" && isRightTransferActionDisable) return;

      setSelectedKeys((st) => ({
        ...st,
        [direction === "left" ? "targetKeys" : "sourceKeys"]: [],
      }));
      onSelectedKeysChange?.(targetKeys);
      if (maxItemsInTarget) {
        setState(() =>
          direction === "left"
            ? { ...state, targetKeys }
            : {
                ...state,
                targetKeys:
                  state?.targetKeys?.length < maxItemsInTarget
                    ? [...state?.targetKeys, ...selectedKeys]
                    : state?.targetKeys,
              }
        );
      } else {
        setState(() =>
          direction === "left"
            ? { ...state, targetKeys }
            : {
                ...state,
                targetKeys: [...state?.targetKeys, ...selectedKeys],
              }
        );
      }
    },
    [
      state,
      touch,
      selectedKeys,
      isRightTransferActionDisable,
      onSelectedKeysChange,
    ]
  );

  const filterOption = useCallback((inputValue, option) => {
    return (
      option?.name?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1 ||
      option?.description?.toLowerCase().indexOf(inputValue?.toLowerCase()) >
        -1 ||
      option?.parentName?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1
    );
  }, []);

  const onSearch = useCallback((direction, value: string): void => {
    if (direction === "left") getSearchValue?.(value);
  }, []);

  const onChangeSelectedKeys = useCallback(
    (changedSourceKeys, changedTargetKeys) => {
      const isSelectingSourceKeys =
        changedSourceKeys?.length !== selectedKeys?.sourceKeys?.length;
      const isSelectingTargetKeys =
        changedTargetKeys?.length !== selectedKeys?.targetKeys?.length;

      const updateSelectedItem = (
        isChanging: boolean,
        selectedKeys: string[],
        changedKeys: string[]
      ): string[] => {
        const differKeys = selectedKeys?.filter(
          (item) => changedKeys?.indexOf(item) === -1
        );
        return isChanging
          ? isCtrlKeyPressed?.current
            ? changedKeys
            : selectedKeys?.length > 1
            ? differKeys?.length
              ? differKeys
              : changedKeys?.slice(-1)
            : changedKeys?.slice(-1)
          : selectedKeys;
      };

      setSelectedKeys({
        sourceKeys: updateSelectedItem(
          isSelectingSourceKeys,
          selectedKeys?.sourceKeys,
          changedSourceKeys
        ),

        targetKeys: updateSelectedItem(
          isSelectingTargetKeys,
          selectedKeys?.targetKeys,
          changedTargetKeys
        ),
      });
    },
    [state, selectedKeys]
  );

  return (
    <TransferStyled
      className="app-transfer"
      isRightTransferActionDisable={isRightTransferActionDisable}
    >
      <div className="left-title">{leftTile}</div>
      <div className="right-title">{rightTile}</div>
      <div className="left-icon">{searchIcon1("13", "13")}</div>
      <div className="right-icon">{searchIcon1("13", "13")}</div>
      <AntdTransfer
        render={(item): JSX.Element => (
          <div
            title={
              typeof item?.name === "string"
                ? `${item?.name} ${
                    item?.description ? `( ${item?.description} )` : ""
                  }`
                : ""
            }
            onClick={(e): void => {
              isCtrlKeyPressed.current = e?.ctrlKey;
            }}
            role="button"
            className="dvsum-transfer-item-rendrer"
            key={`andt-transfer-item-${item?.key}-${item?.name}`}
          >
            <div className="item">
              {item?.icon}
              <div className="item-name"> {item?.name}</div>
            </div>
            {item?.description && (
              <span className="decs" title={item?.description}>
                ({item?.description}
                &nbsp;
              </span>
            )}
          </div>
        )}
        targetKeys={state.targetKeys}
        showSearch
        onChange={onChange}
        dataSource={state?.data}
        filterOption={filterOption}
        onSearch={onSearch}
        showSelectAll={false}
        locale={{
          // notFoundContent: notFoundMessage || <div />,
          notFoundContent: loading
            ? transferBlankSlate
            : notFoundMessage || <div />,
          searchPlaceholder: "Search",
        }}
        onSelectChange={onChangeSelectedKeys}
        {...transferProps}
        selectedKeys={[
          ...selectedKeys?.sourceKeys,
          ...selectedKeys?.targetKeys,
        ]}
      />
      {showUpDownComp && (
        <TransferUpDownSection
          state={state}
          setState={setState}
          selectedTargetKeys={selectedKeys?.targetKeys}
        />
      )}
      {showError && touch && state.targetKeys.length < minItems && (
        <div className="error-message">
          {errorMessage || `Please select at least ${minItems} item(s)`}
        </div>
      )}
    </TransferStyled>
  );
}

export default Transfer;
