import styled from "styled-components";

import { FontFamilyType } from "../../app.types";

export const SectionTitleWithIconStyled = styled.div<{
  paddingBottom?: string;
  marginBottom?: string;
  fontFamily?: FontFamilyType;
  fontSize?: string;
  showBorderBottom?: boolean;
  marginTop?: string;
  minHeight?: string;
  fontWeight?: number | string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${({ paddingBottom }): string => paddingBottom || "8px"};
  margin-bottom: ${({ marginBottom }): any => marginBottom};
  margin-top: ${({ marginTop }): any => marginTop};
  ${({ showBorderBottom }): any =>
    showBorderBottom && `border-bottom: 1px solid rgba(112, 112, 112, 0.1)`};
  min-height: ${({ minHeight }): string => minHeight || "44px"};

  .title {
    color: ${(props): string => props.theme.textColors.DARK_BLUE};
    font-size: ${({ fontSize }): string => fontSize || "19px"};
    font-weight: ${({ fontWeight }): any => fontWeight || 600};
    font-family: ${({ fontFamily }): string =>
      fontFamily || "OpenSansSemiBold"};
    line-height: 1;
    flex: 1;
  }
  .icons-wrapper {
    display: flex;
    align-items: center;
    min-width: 75px;
    justify-content: flex-end;
  }
`;

export const FilterStyled = styled.div<{ showHeader: boolean }>`
  margin-right: ${({ showHeader }): string => (showHeader ? "20px" : "0px")};
`;

export const EditStyled = styled.span`
  margin-left: 10px;
`;
