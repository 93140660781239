import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { LinkedEntitySec, SectionTitleWithIcon } from "../..";
import { SectionWithEditableLinkedEntityStyled } from "./sectionwitheditablelinkedentity.styles";
import { SectionWithEditableLinkedEntityProps } from "./sectionwitheditablelinkedentity.types";
import { MatchingStaus } from "../../../parsers";
import { MatchingEntity } from "../../../forms/reviewrecomendedentitymodal";

const SectionWithEditableLinkedEntity = (
  props: SectionWithEditableLinkedEntityProps
): JSX.Element => {
  const {
    secTitle,
    state,
    setState,
    isColRef = false,
    sourceConfig = [],
  } = props;

  const { data } = state || {};

  const {
    id = "",
    entity_title: entityTitle = "",
    match_status: matchStatus,
    match_score: matchingScore = 0,
    match_source: matchSource,
    matching_entities: matchingEntities = [],
  } = data || {};

  const formDefaultValues = {
    entity: entityTitle,
    entityId: id,
    entityMatchingStatus: matchStatus,
    matchingScore,
    matchSource,
    matchingEntities: matchingEntities?.map((matchingEntity) => ({
      matching_entity: matchingEntity?.matching_entity || "",
      matching_entity_id: `${matchingEntity?.matching_entity_id || 0}`,
      samples: matchingEntity?.samples || "",
      matching_score: Math.round(matchingEntity?.matching_score) || 0,
      match_source: matchingEntity?.match_source || "AAI",
      selected: matchingEntity?.selected || false,
      entity_status: matchingEntity?.entity_status || "",
      entity_status_id: matchingEntity?.entity_status_id,
    })),
  };

  const form = useForm<{
    entity: string;
    entityId: string;
    entityMatchingStatus: MatchingStaus;
    matchingEntities: MatchingEntity[];
    matchingScore: number;
    matchSource: string;
  }>({
    defaultValues: formDefaultValues,
    mode: "all",
  });

  const { watch } = form;
  const watchedValues = watch();

  useEffect(() => {
    form?.reset(formDefaultValues);
  }, [data]);

  const onEdit = (): void => {
    setState((prev) => ({
      ...prev,
      isEditable: true,
    }));
  };

  const onCancel = (): void => {
    setState((prev) => ({
      ...prev,
      isEditable: false,
    }));
  };

  const onSave = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isEditable: false,
      isEdited: true,
      data: {
        ...prev.data,
        id: watchedValues.entityId,
        entity_title: watchedValues.entity,
        match_status: watchedValues.entityMatchingStatus,
        match_score: watchedValues.matchingScore,
        match_source: watchedValues.matchSource,
        matching_entities: watchedValues.matchingEntities,
      },
    }));
  }, [watchedValues]);

  return (
    <FormProvider {...form}>
      <SectionWithEditableLinkedEntityStyled>
        <SectionTitleWithIcon
          marginBottom="25px"
          {...secTitle}
          onEdit={onEdit}
          isEditable={state?.isEditable}
          onCancel={onCancel}
          onSave={onSave}
        />

        <LinkedEntitySec
          state={state}
          isColRef={isColRef}
          sourceConfig={sourceConfig}
        />
      </SectionWithEditableLinkedEntityStyled>
    </FormProvider>
  );
};

export default SectionWithEditableLinkedEntity;
