import { SelectieldType } from "../components/formfields/selectfield/selectfield.types";
import { SectionRowProps } from "../components/sectionrow";

export function nodesDescriptionEditableViewValueUpdation(
  item: SectionRowProps,
  values: any,
  options?: SelectieldType["options"]
): SectionRowProps {
  const { type, id = "none", value = "", isEditable, title = "" } = item || {};

  switch (type) {
    case "textarea":
    case "input":
      return {
        ...item,
        value: isEditable ? values?.[id] ?? value : value,
      };

    case "select": {
      const findedOpt = options?.find((opt) => opt?.value === values?.[id]);

      const selectedVal = values?.[id];

      return {
        ...item,
        value: findedOpt?.labelText || findedOpt?.label || "",
        selectedValues: selectedVal ? [selectedVal] : [],
      };
    }
    case "checkbox": {
      return {
        ...item,
        value: isEditable ? values?.[id] ?? value : value,
      };
    }
    default:
      return item;
  }
}
