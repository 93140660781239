import { createContext, useCallback, useContext, useReducer } from "react";
import { REFERENCE_DICTIONARY_INITIAL_STATE } from "./referencedictionarycontext.config";

import {
  ReferenceDictionaryPageContextType,
  onChangeReferenceDictionaryPageViewType,
  onChangeReferenceDictionarySectionViewType,
  ReferenceDictionaryActionsType,
  ReferenceDictionaryContextType,
  ReferenceDictionaryPageViewType,
  ReferenceDictionarySectionType,
  ReferenceDictionarySectionViewType,
  updateReferenceDictionaryDetailStateType,
  updateReferenceDictionaryDetailIsChangedType,
} from "./referencedictionarycontext.types";

const initialState = REFERENCE_DICTIONARY_INITIAL_STATE;

const reducer = (
  state: ReferenceDictionaryContextType,
  action: ReferenceDictionaryActionsType
): ReferenceDictionaryContextType => {
  switch (action?.type) {
    case "CHANGE_PAGE_VIEW": {
      const newPageView = action?.pageView as ReferenceDictionaryPageViewType;
      return {
        ...state,
        pageView: newPageView,
      };
    }
    case "CHANGE_SECTION_VIEW": {
      const newSection = action?.section as ReferenceDictionarySectionType;
      const newSectionView = action?.sectionView as ReferenceDictionarySectionViewType;
      return {
        ...state,
        [newSection]: {
          ...state[newSection],
          viewMode: newSectionView,
        },
      };
    }
    case "UPDATE_STATE":
      return {
        ...state,
        ...action?.updatedState,
      };

    case "UPDATE_IS_CHANGED": {
      const newSection = action?.section as ReferenceDictionarySectionType;
      const newSectionView = action?.istNewChanged as boolean;

      return {
        ...state,
        [newSection]: {
          isChanged: newSectionView,
        },
      };
    }

    default:
      return state;
  }
};

const ReferenceDictionaryDetailPageContext = createContext({
  state: initialState,
  dispatch: (_: ReferenceDictionaryActionsType) => {},
});

const ReferenceDictionaryDetailPageProvider: React.FC = ({ children }) => {
  // Use useReducer instead of useState
  const [state, dispatch] = useReducer(reducer, initialState);

  // Define your callback functions using dispatch

  const onChangePageView: onChangeReferenceDictionaryPageViewType = useCallback(
    (pageView) => {
      dispatch({ type: "CHANGE_PAGE_VIEW", pageView });
      dispatch({
        type: "CHANGE_SECTION_VIEW",
        section: "informationSection",
        sectionView: "preview",
      });
      dispatch({
        type: "CHANGE_SECTION_VIEW",
        section: "informationSection",
        sectionView: "preview",
      });
    },
    [dispatch]
  );

  const onChangeSectionView: onChangeReferenceDictionarySectionViewType = useCallback(
    (section, sectionView) => {
      dispatch({ type: "CHANGE_SECTION_VIEW", section, sectionView });
    },
    [dispatch]
  );

  const updateState: updateReferenceDictionaryDetailStateType = useCallback(
    (updatedState) => {
      dispatch({ type: "UPDATE_STATE", updatedState });
    },
    [dispatch]
  );
  //is not needed anymore so remove this
  const updateIsChanged: updateReferenceDictionaryDetailIsChangedType = useCallback(
    (section, istNewChanged) => {
      dispatch({ type: "UPDATE_IS_CHANGED", section, istNewChanged });
    },
    [dispatch]
  );

  // Provide state and dispatch value to the context
  const contextValue: ReferenceDictionaryPageContextType = {
    state,
    onChangePageView,
    onChangeSectionView,
    updateState,
    updateIsChanged,
    dispatch,
  };

  return (
    <ReferenceDictionaryDetailPageContext.Provider value={contextValue}>
      {children}
    </ReferenceDictionaryDetailPageContext.Provider>
  );
};

const useReferenceDictionaryPageContext = (): ReferenceDictionaryPageContextType => {
  const context = useContext(ReferenceDictionaryDetailPageContext);

  if (!context) {
    console.log(
      "ReferenceDictionaryDetailPageContext must be used within a JobDetailPageContextProvider"
    );
  }

  return context as ReferenceDictionaryPageContextType;
};

export {
  ReferenceDictionaryDetailPageProvider,
  ReferenceDictionaryDetailPageContext,
  useReferenceDictionaryPageContext,
};
