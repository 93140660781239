import { useCallback, useMemo } from "react";

import { TableWithSearchAndFiltersHOC } from "../../../../../../components/tablewithsearchandfiltershoc";

import ListingPages from "../../../../../listingpage/listingpage";
import { RulesGridStyled } from "./rulesgridview.styles";

import {
  RulesGridViewContentPropType,
  RulesGridViewPropsType,
} from "./rulesgridview.types";

import RecommendedRulesGrid from "../../recommendedrulesgrid/recommendedrulesgrid";
import { Input } from "../../../../../../components/inputs";

import { crossIcon, searchIconLight } from "../../../../../../svgs";

import Flex from "../../../../../../components/flex";
import { ViewNameStyled } from "../../dataqualitytab.styles";

import { SwitchFieldStyled } from "../../../../../../components/formfields/switchfield/switchfield.styles";
import { VerticalDividerStyled } from "../../../../../../components/formfields/dictionaryfield/dictionaryfield.styles";

import { ELEMENT_IDS } from "../../../../../../constants";
import { useGetAppState } from "../../../../../../customhooks";

const crossIconSvg = crossIcon("12", "12");
const serachIcon = searchIconLight();

const {
  datdict_detpg_dq_switch_avl_rule_view_btn: DATDDICT_DETPG_DQ_SWITCH_AVL_RULE_VIEW_BTN,
} = ELEMENT_IDS;

const RulesGridViewContent = (
  props: RulesGridViewContentPropType
): JSX.Element => {
  const {
    tableId,
    doesExternalFilterPass,
    onGridReady,
    gridApi,
    searchText,
    onChangeSearchText,
    sourceId,
    srcTypeId,
    freshnessRuleColumnId,
    activeRuleView,
    columnId,
    onChangeRuleView,
    nodeFilter = "all",
    refetchStatsAndCharts,
  } = props;

  const {
    expandableGridProps: { isGridExpanded = false } = { isGridExpanded: false },
  } = useGetAppState();

  const onGridReadyWrapper = useCallback(
    (params) => {
      onGridReady && onGridReady(params);
      params?.api?.setQuickFilter?.(searchText);
    },
    [props]
  );

  const isRecommendedView = activeRuleView === "RCM";

  const onSwitchToggle = useCallback(() => {
    onChangeRuleView?.();
  }, []);

  const DQTabListingRightSec = useMemo(() => {
    return (
      <Flex alignItems="center" columnGap={20}>
        <Input
          suffix={
            searchText ? (
              <span
                role="button"
                onClick={onChangeSearchText}
                className="clear-search-text-icon"
              >
                {crossIconSvg}
              </span>
            ) : (
              <span />
            )
          }
          className="search-box"
          placeholder="Search"
          prefix={serachIcon}
          value={searchText}
          onChange={onChangeSearchText}
          width="250px"
          fontSize="14px"
          height="40px"
        />

        <VerticalDividerStyled height="8px" />

        <Flex alignItems="center" justifyContent="center" columnGap={10}>
          <ViewNameStyled isSelected={activeRuleView === "AVB"}>
            Available Rules
          </ViewNameStyled>
          <div className="switch-field">
            <SwitchFieldStyled
              size="small"
              width="40px"
              checked={activeRuleView === "RCM"}
              onChange={onSwitchToggle}
              bgColorActive
              height="20.5px"
              //Adding ts-ignore to ignore the type error of id which requires for tutorial.
              //@ts-ignore
              id={
                activeRuleView === "RCM"
                  ? DATDDICT_DETPG_DQ_SWITCH_AVL_RULE_VIEW_BTN
                  : ""
              }
            />
          </div>
          <ViewNameStyled isSelected={activeRuleView === "RCM"}>
            Recommended Rules
          </ViewNameStyled>
        </Flex>
      </Flex>
    );
  }, [searchText, activeRuleView, onChangeSearchText, onSwitchToggle]);

  return (
    <RulesGridStyled>
      {isRecommendedView ? (
        <RecommendedRulesGrid
          tableId={tableId}
          columnId={columnId}
          sourceId={sourceId}
          onChangeRuleView={onChangeRuleView}
          onGridReadyWrapper={onGridReadyWrapper}
          gridApi={gridApi}
          dqTabListingRightSec={DQTabListingRightSec}
          refetchStatsAndCharts={refetchStatsAndCharts}
        />
      ) : (
        <ListingPages
          type="RLS"
          isOutsideListingVews
          tableId={tableId}
          columnId={columnId}
          sourceId={sourceId}
          onGridReadyWrapper={onGridReadyWrapper}
          doesExternalFilterPass={doesExternalFilterPass}
          gridApi={gridApi}
          customRightSectionAction={DQTabListingRightSec}
          srcTypeId={srcTypeId}
          freshnessRuleColumnId={freshnessRuleColumnId}
          maxHeightOfGrid={isGridExpanded ? "calc(100vh - 300px)" : "65vh"}
          refetchStatsAndCharts={refetchStatsAndCharts}
          nodeFilter={nodeFilter}
        />
      )}
    </RulesGridStyled>
  );
};

const RulesGridView = (props: RulesGridViewPropsType): JSX.Element => {
  return (
    <TableWithSearchAndFiltersHOC appliedFilters={["all"]} filterColumnName="">
      <RulesGridViewContent {...props} />
    </TableWithSearchAndFiltersHOC>
  );
};
export default RulesGridView;
