import { useMemo } from "react";
import { useLocation } from "react-router";
import { useGetAppState } from "../../customhooks";

import Header from "../header";
import NavBar from "../navbar";

import { LayoutStyled } from "./layout.styles";
import BackToTopButtton from "../backtotopbtn";
import { APP_ROUTES } from "../../constants";

function Layout({ children }: { children: React.ReactNode }): JSX.Element {
  const {
    sideBarPosition,
    expandableGridProps: { isGridExpanded = false },
  } = useGetAppState();

  const location = useLocation();

  // Create an array of routes to check
  const routesToCheck = [
    APP_ROUTES.private_routes.table_dictionary,
    APP_ROUTES.private_routes.column_detail,
    APP_ROUTES.private_routes.terms,
    APP_ROUTES.private_routes.data_sources,
    APP_ROUTES.private_routes.reports,
    APP_ROUTES.private_routes.assets_disctionary,
    APP_ROUTES.private_routes.reference_dictionary,
    APP_ROUTES.private_routes.rule_detail,
    APP_ROUTES.private_routes.etl_detail,
    // Add new routes here as they come in
  ];

  // Check if current path is in overview AND matches any of the specified routes
  const dontUseMinWidth = useMemo(
    () =>
      location?.pathname.includes("overview") &&
      routesToCheck.some((route) => location?.pathname?.includes(route)),
    [location?.pathname]
  );

  return (
    <LayoutStyled
      isSideBarCollapsed={sideBarPosition === "collapsed"}
      isOverviewTab={dontUseMinWidth}
    >
      <Header />

      <NavBar />
      <div className="page-content">
        <div className="page-content-layout">{children}</div>
      </div>

      {!isGridExpanded && <BackToTopButtton />}
    </LayoutStyled>
  );
}
export default Layout;
