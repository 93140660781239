import styled from "styled-components";

export const SectionWithEditableAdditionalinfoStyled = styled.div<{
  isEditable: boolean;
}>`
  .added-item {
    margin-bottom: ${({ isEditable }): any => !isEditable && "23px"};
  }
  .additionalinfo-blank-slate {
    font-size: 17px;
    font-family: OpenSansSemiBold;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    text-align: center;
    padding-top: 50px;
  }
`;
