import styled from "styled-components";

export const DataQualityPreviewerStyled = styled.div`
  .score-wrapper {
    display: flex;
    margin-bottom: 9px;

    .overall-score-label {
      min-width: 217px;
      color: ${({ theme }): string => theme?.textColors?.DARK_GREY_10};
    }
  }

  .last-refreshed {
    font-size: 13px;
    color: ${({ theme }): string => theme?.textColors?.DARK_GREY_10};
    opacity: 0.6;
    margin-bottom: 32px;
  }
`;
