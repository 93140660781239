import { Card } from "antd";
import styled from "styled-components";
import { ellipsis } from "../../../../styles/app.styles";

export const ProfilingStyled = styled.div`
  padding-right: 10px;
`;

export const SectionHeading = styled.h2`
  font-size: 20px;
  font-family: OpenSansSemiBold;
  color: ${({ theme }): string => theme.textColors.BLUE_116};
  margin-bottom: 16px;
  border-bottom: 1px solid
    ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
`;

export const GraphHeading = styled.h2`
  font-size: 20px;
  font-family: OpenSansSemiBold;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  margin-bottom: 16px;
`;

export const TileHeading = styled.h3`
  font-size: 16px;
  font-family: OpenSansSemiBold;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  margin-bottom: -4px;
`;

export const TileText = styled.h4`
  font-size: 14px;
  font-family: OpenSans;
  color: ${({ theme }): string => theme.textColors.GREY_24};
`;

export const StyledCard = styled(Card)`
  text-align: center;
  border: 1px solid ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
  border-radius: 4px;

  .progress-bar-wrapper {
    width: 100%;
    height: 8px;
    border-radius: 1px;
    overflow: hidden;
    background-color: ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
  }
`;

export const InfoBox = styled.div`
  height: auto;
  border-radius: 8px;
  margin-top: 10px;

  .table-section {
    margin-top: 20px;
    background: ${({ theme }): string => theme.textColors.LIGHT_GREY_22};
    border: 1px solid ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
    padding: 20px;
    border-radius: 4px;
  }
  .gateway-check-with-child {
    position: relative;
  }
`;

export const GraphContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }): string => theme.textColors.LIGHT_GREY_51};
  margin-top: 30px;
  padding: 16px;

  .no-data {
    text-align: center;
    font-size: 16px;
    color: ${({ theme }): string => theme.textColors.GREY_24};
    margin-top: 20px;
  }
`;

export const GraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonGroup = styled.div<{ disabled?: boolean }>`
  display: flex;
  opacity: ${({ disabled }): string => (disabled ? "0.5" : "1")};

  & > div {
    column-gap: 0;
    -webkit-column-gap: 0;
  }
  & > div > button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > div > button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const InfoFieldRendererStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .info-label {
    min-width: 200px;
    color: ${({ theme }): string => theme.textColors.GREY_24};
    width: 40%;
    font-weight: 500;
  }

  .info-value {
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    width: 60%;
    font-weight: 400;
    ${ellipsis}
    position: relative;
    cursor: pointer;
  }
`;
