import { GridApi, GridReadyEvent } from "ag-grid-community";
import { TableProps } from "../../../../../components/aggridtable/aggridtable.types";

import {
  RuleCategoryTypeId,
  RuleDefConfigType,
  RuleTypeId,
} from "../../../../../parsers/ruleparser/ruleparser.types";
import { agGridStringComparator } from "../../../../../utils";

export type RecommendedRulesGridProps = {
  sourceId: number;
  columnId?: string; //Getting this incase of column detail page
  tableId: string;
  onChangeRuleView: (calledBy?: "USR" | "AUTO") => void;
  onGridReadyWrapper?: (_api: GridReadyEvent) => void;
  gridApi?: GridApi;
  dqTabListingRightSec: JSX.Element;
  refetchStatsAndCharts: () => void;
};

export type RecommendedRulesGridColumnsType = {
  id: number;
  rule_name: string;
  rule_desc: string;
  actions: string;
};

export type ValueRangeModalPropsType = {
  ruleCategoryId: RuleCategoryTypeId;
  formId: RuleTypeId;
  tableId: string;
  ruleConfig: RuleDefConfigType;
  isFromRecommendedRules: boolean;
};

export type onAddRecommendedRuleCallbackType = (
  // SEE IF WE CAN REMOVE THE ANY TYPE
  event?: any,
  ruleId?: number
) => void;

export const RecommendedRulesGridColumns: TableProps<RecommendedRulesGridColumnsType>["tableColumns"] = [
  {
    headerName: "Rule Name",
    minWidth: 220,
    flex: 1,
    field: "rule_name",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    comparator: agGridStringComparator,
  },
  {
    headerName: "Rule Description",
    minWidth: 220,
    flex: 1,
    field: "rule_desc",
    comparator: agGridStringComparator,
  },
  {
    headerName: "Actions",
    width: 220,
    field: "actions",
  },
];
