import styled from "styled-components";

export const BusinessRuleMinMaxRendrerStyled = styled.div`
  .min-max-rendrer-header {
    display: flex;
    align-items: center;
    > div {
      margin-top: 5px;
      width: 263px;
      margin-bottom: 5px;

      .required-field {
        color: ${({ theme }): string => theme.textColors.RED_11};
        font-size: 13px;
        margin-left: 3px;
      }
    }
  }
  .min-max-rendrer-body {
    display: flex;
    align-items: center;
    .seperation-line {
      width: 11px;
      height: 1px;
      background: ${({ theme }): string => theme.bgColors.GREY_101};
      margin: 0 15px;
    }
  }
`;

export const BusinessRuleGridRendrerStyled = styled.div<{ hasScroll: boolean }>`
  padding: 15.6px 22.5px 27.5px 23px;
  background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  .grid-header {
    display: flex;
    align-items: center;
    margin-bottom: 11px;

    > button {
      display: flex;
      align-items: center;
      svg {
        margin-right: 2px;
      }
    }
  }

  .grid-view {
    border: 1px solid ${({ theme }): string => theme.borderColors.GREY_10};

    .grid-view-header {
      background: ${({ theme }): string => theme.bgColors.GREY_95};
      display: grid;
      grid-template-columns: 22% 66% 12%;
      border-bottom: 1px solid
        ${({ theme }): string => theme.borderColors.GREY_10};
      overflow-y: ${({ hasScroll }): any => hasScroll && "scroll"};

      ::-webkit-scrollbar {
        background: #f7f7f7;
      }

      div {
        padding: 9px 11px;
        color: ${({ theme }): string => theme.textColors.GREY_90};
        font-family: OpenSansBold;
        font-size: 13px;

        :not(:last-of-type) {
          border-right: 1px solid
            ${({ theme }): string => theme.borderColors.GREY_10};
        }

        :last-of-type {
          text-align: center;
        }
      }
    }

    .grid-view-body {
      max-height: 230px;
      overflow-y: auto;
      .grid-view-body-item {
        display: grid;
        grid-template-columns: 22% 66% 12%;
        > input {
          border-radius: 0px;

          :hover,
          :focus {
            border-color: ${({ theme }): string => theme.borderColors.BLUE_105};
          }

          :focus {
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
          }

          :not(:focus, :hover) {
            :not(:last-of-type) {
              border-right-width: 0px;
            }
            border-top-width: 0px;

            :first-of-type {
              border-left-width: 0px;
            }
          }
        }

        div {
          :last-of-type {
            text-align: center;
            border-bottom: 1px solid
              ${({ theme }): string => theme.bgColors.GREY_10};
            button {
              text-align: center;
              padding: 9px 11px;
              width: 11px;
            }
          }
        }

        :last-of-type {
          > input {
            :not(:focus, :hover) {
              border-bottom-width: 0px;
            }
          }

          > div {
            border-bottom-width: 0px;
          }
        }
      }
    }
  }
`;
