import { Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormContainer,
  Row,
  FieldContainer,
  Label,
  ActionsContainer,
  SelectedAssets,
  SectionTitle,
  FormWrapper,
  AssetRelationshipFormContainer,
} from "./assetrelationshipform.styles";
import { SelectField, TextAreaField } from "../../components/formfields";
import { Button, GritterNotification, LinkButton } from "../../components";
import {
  AssetRelationshipFormProps,
  AssetRelationshipFormValues,
} from "./assetrelationshipform.types";
import { RepresentsTypes } from "../../components/multiselectassetsfield/multiselectassetsfield.types";

import { plusIconInFilledCircle } from "../../svgs";
import { useAssetRelationshipContext } from "../../contexts/assetrelationshipscontext";
import { AssetSelectionField } from "../../components/assetselectionfield";
import { AdaptiveAssetHierarchy } from "../../components/adaptiveassetheirachy";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { NodeType } from "../../app.types";
import { sortObjectsArrayByKey } from "../../utils";

const AssetRelationshipForm = (
  props: AssetRelationshipFormProps
): JSX.Element => {
  const {
    onAddAsset,
    isFormVisible,
    setIsFormVisible,
    setIsValidState,
  } = props;

  const {
    state: { data: RelationshipData, nodeType },
  } = useAssetRelationshipContext();

  const relationshipOptions = useMemo(
    () =>
      Array.from(
        new Map(
          RelationshipData?.filter((item) => item?.isEditable).map((item) => [
            item?.relationshipTypeId,
            {
              label: item?.relationshipType,
              value: item?.relationshipTypeId,
            },
          ])
        )?.values()
      ),
    [RelationshipData]
  );

  const [assetSections, setAssetSections] = useState<
    Record<string, RepresentsTypes[]>
  >({});

  // const [isFormVisible, setIsFormVisible] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<AssetRelationshipFormValues>({
    defaultValues: {
      relationshipType: "",
      assetType: "",
      assets: [],
      comment: "",
    },
  });

  const getSectionLabels = useCallback(
    (key: string) => {
      const section = RelationshipData?.find((item) => item?.sectionId === key);

      return {
        relationshipTypeLabel: section?.relationshipType || "",
        assetTypeLabel: section?.assetType || "",
        assetTypeValue: section?.assetTypeId || "",
        relationshipTypeValue: section?.relationshipTypeId || "",
      };
    },
    [RelationshipData]
  );

  const relationshipType = watch("relationshipType");
  const assetType = watch("assetType");

  const assetTypeOptions = useMemo(() => {
    if (!relationshipType) return [];

    const options = Array.from(
      new Map(
        RelationshipData?.filter(
          (item) =>
            item?.relationshipTypeId === relationshipType && item?.isEditable
        )?.map((item) => [
          item?.assetTypeId,
          {
            label: item?.assetType,
            value: item?.assetTypeId || "",
          },
        ])
      )?.values()
    );

    return sortObjectsArrayByKey(options, "label");
  }, [RelationshipData, relationshipType]);

  const sectionKey = `${nodeType}-${relationshipType}-${assetType}`;

  const currentAssets = assetSections[sectionKey] || [];

  const isMultiValued = useMemo(() => {
    return (
      RelationshipData?.find((section) => section?.sectionId === sectionKey)
        ?.isMultiValued ?? true
    );
  }, [RelationshipData, relationshipType, sectionKey]);

  const onSubmit = (data: AssetRelationshipFormValues): void => {
    if (!relationshipType || !assetType || !currentAssets?.length) {
      return;
    }

    const assetRelationshipsList = Object.keys(assetSections).map((key) => {
      const {
        relationshipTypeLabel,
        assetTypeLabel,
        assetTypeValue,
        relationshipTypeValue,
      } = getSectionLabels(key);
      return {
        sectionId: key,
        relationshipType: relationshipTypeLabel,
        relationshipTypeId: relationshipTypeValue,
        assetType: assetTypeLabel,
        isEditable: true,
        isMultiValued: true,
        isAssetPathRequired: false,
        rows: assetSections[key].map((asset) => ({
          sectionId: key,
          id: asset?.nodeId || "",
          isChanged: true,
          mode: "UPSERT",
          nodePath: asset?.nodePath,
          nodeType: asset?.nodeType as NodeType,
          name: asset?.nodeName || "",
          description: asset?.description || "",
          comment: data?.comment || "",
          assetType: assetTypeLabel,
          isWorkflowEnabled: asset?.isWorkflowEnabled || false,
          isDeleted: false,
          source: "USR",
          relationshipType: relationshipTypeLabel,
          relationshipTypeId: relationshipTypeValue,
        })),
      };
    });

    onAddAsset(assetRelationshipsList);

    reset();
    setAssetSections({});
    setIsFormVisible(false);
  };

  const addedIds = useMemo(() => {
    return (
      assetSections[sectionKey]
        ?.map((asset) => asset?.nodeId)
        .filter(Boolean) || []
    );
  }, [assetSections, sectionKey]);

  const clearSearchText = useCallback(
    (setText: (value: string) => void) => {
      setText("");
    },
    [relationshipType, assetType]
  );

  const isAddDisabled = useMemo(() => {
    return Object?.values(assetSections)?.every(
      (section) => section?.length === 0
    );
  }, [assetSections]);

  const onRemove = useCallback(
    (id?: string, section?: string): void => {
      setAssetSections((prevSections) => {
        if (!section) return prevSections;

        return {
          ...prevSections,
          [section]:
            prevSections[section]?.filter((source) =>
              source?.isTblSelection
                ? source?.nodeId !== id
                : source?.nodeId !== id
            ) || [],
        };
      });
    },
    [setAssetSections]
  );

  const onSelect = useCallback(
    (id: string, findedObj): void => {
      if (findedObj) {
        setAssetSections((prevSections) => ({
          ...prevSections,
          [sectionKey]: [...(prevSections?.[sectionKey] || []), findedObj],
        }));
      }
    },
    // clearSearchText(""),
    [sectionKey, setAssetSections]
  );

  const onCancel = useCallback(() => {
    reset();
    setAssetSections({});
    setIsFormVisible(false);
  }, [reset, setAssetSections, setIsFormVisible]);

  useEffect(() => {
    setValue("assetType", "");
  }, [relationshipType, setValue]);

  useEffect(() => {
    if (assetTypeOptions.length === 1) {
      setValue("assetType", assetTypeOptions[0]?.value || "");
    }
  }, [assetTypeOptions, setValue]);

  useEffect(() => {
    setIsValidState(!(addedIds?.length > 0));
  }, [addedIds, setIsValidState]);

  return (
    <AssetRelationshipFormContainer>
      <LinkButton
        className="add-criteria-btn"
        onClick={(): void => setIsFormVisible((prev) => !prev)}
      >
        {plusIconInFilledCircle("12px", "12px")} Add
      </LinkButton>

      <FormWrapper isVisible={isFormVisible}>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <FieldContainer width="20%">
              <Label>Relationship Type</Label>
              <SelectField
                name="relationshipType"
                control={control}
                setValue={setValue}
                options={relationshipOptions}
                placeholder="Select"
                width="100%"
              />
            </FieldContainer>

            <FieldContainer width="20%">
              <Label>Asset Type</Label>
              <SelectField
                name="assetType"
                control={control}
                setValue={setValue}
                options={assetTypeOptions}
                placeholder="Select"
                width="100%"
              />
            </FieldContainer>

            <FieldContainer width="60%">
              <Label>Asset</Label>
              <AssetSelectionField
                apiKey="get_node_parental_info_by_node_type"
                urlParam={[assetType, nodeType]}
                onSelect={onSelect}
                addedIds={addedIds}
                disabled={!relationshipType || !assetType}
                clearSearch={clearSearchText}
                singleSelect={!isMultiValued}
                placeHolderForSearch="Search for asset"
              />
            </FieldContainer>
          </Row>

          <FieldContainer width="100%">
            {Object.keys(assetSections).map((key) => {
              const contextSection = RelationshipData?.find(
                (section) => section?.sectionId === key
              );

              const filteredRows =
                contextSection?.rows?.filter((row) => !row?.isDeleted) || [];

              const {
                relationshipTypeLabel,
                assetTypeLabel,
              } = getSectionLabels(key);

              const contextRows = filteredRows?.length ?? 0;

              return (
                <SelectedAssets key={key}>
                  {assetSections?.[key]?.length > 0 && (
                    <SectionTitle>
                      {relationshipTypeLabel} {assetTypeLabel}
                    </SectionTitle>
                  )}
                  <>
                    {assetSections[key]?.map((asset) => (
                      <AdaptiveAssetHierarchy
                        nodes={asset?.nodePath || []}
                        mode="primary"
                        key={`${asset?.nodeId}-${asset?.nodeName}`}
                        onRemove={(id): void => onRemove(id, key)}
                        openRef
                      />
                    ))}
                  </>
                  {contextSection &&
                    assetSections[key]?.length > 0 &&
                    !contextSection?.isMultiValued &&
                    contextRows > 0 && (
                      <GritterNotification
                        type="warning"
                        message={
                          <>
                            Selecting&nbsp;
                            <span className="bold-text">
                              {assetSections[key]?.[0]?.nodeName ||
                                "This asset"}
                            </span>
                            &nbsp;will replace the currently selected&nbsp;
                            <span className="bold-text">
                              {contextSection?.rows?.[0]?.name ||
                                "existing asset"}
                            </span>
                            &nbsp;
                            {contextSection?.assetType}.
                          </>
                        }
                      />
                    )}
                </SelectedAssets>
              );
            })}
          </FieldContainer>

          <FieldContainer width="100%">
            <Label>Comment</Label>
            <TextAreaField
              name="comment"
              rows={3}
              control={control}
              placeholder="(Optional) Add a comment"
            />
          </FieldContainer>

          <ActionsContainer>
            <Button id="cancel" onClick={onCancel} type="default">
              Cancel
            </Button>
            {isAddDisabled ? (
              <Tooltip
                overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                title="Select at least one Asset to enable add."
                placement="topLeft"
              >
                <span>
                  <Button type="primary" htmlType="submit" disabled>
                    Add
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button type="primary" htmlType="submit" disabled={false}>
                Add
              </Button>
            )}
          </ActionsContainer>
        </FormContainer>
      </FormWrapper>
    </AssetRelationshipFormContainer>
  );
};

export default AssetRelationshipForm;
