import { NodeType } from "../app.types";
import { PageNodeType } from "../pages/listingpage/listingpage.types";

export const getPageNode = (nodeType: NodeType): PageNodeType => {
  const isTablePage = nodeType === "TBL";
  const isColumnPage = nodeType === "COL";
  const isRulePage = nodeType === "RLS";
  const isJobPage = nodeType === "JOB";
  const isTermPage = nodeType === "TRM";
  const isJobExecutionsPage = nodeType === "SCH";
  const isFeedbackAnalyticsPage = nodeType === "FBK";
  const isETLPage = nodeType === "PLO";
  const isDataSetPage = nodeType === "DSR";
  const isDashboardPage = nodeType === "DSH";
  const isDatasetReportsPage = nodeType === "DSR";
  const isTransformationPage = nodeType === "POF";
  const isDataSrcFieldPage = nodeType === "DSF";
  const isAllNodesPage = nodeType === "ALL";

  return {
    isTablePage,
    isColumnPage,
    isRulePage,
    isJobPage,
    isTermPage,
    isJobExecutionsPage,
    isFeedbackAnalyticsPage,
    isETLPage,
    isDataSetPage,
    isDashboardPage,
    isDatasetReportsPage,
    // Confirm from TL
    // isAgentAnalyticsChatPage: false,
    // isFeedbackPage: false,
    // Confirm from TL
    isTransformationPage,
    isDataSrcFieldPage,
    isAllNodesPage,
  };
};
