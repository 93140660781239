export const updateUrlHostPort = (
  url: string,
  host: string,
  port: string
): string => {
  try {
    const parsedUrl = new URL(url);

    const isHostChanged = host && parsedUrl?.hostname !== host;
    const isPortChanged = port && parsedUrl?.port && parsedUrl?.port !== port;

    // Check if the host is different
    if (isHostChanged) {
      parsedUrl.hostname = host;
    }

    // Check if the port is different or missing
    if (isPortChanged) {
      parsedUrl.port = port;
    }

    return parsedUrl.toString();
  } catch (error) {
    console.error("Invalid URL", error);
    return url;
  }
};
