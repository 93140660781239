import styled from "styled-components";
import { ellipsis } from "../../../styles/app.styles";
import { hexToRgbA } from "../../../utils";

export const SectionWithEditableBuisenessRulesStyled = styled.div<{
  isEditable: boolean;
}>`
  .read-only-view {
    display: flex;
    justify-content: space-between;
    .left-view {
      width: 50%;
      .businessrule-main-row {
        display: flex;
        margin-bottom: 25px;
        .businessrule-row-title {
          color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
          width: 33%;
        }
        .businessrule-row-value {
          color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
          width: 66%;
          word-break: break-word;
        }
        .businessrule-tags-sec,
        .selected-tag {
          display: flex;
          align-items: center;

          .first-val,
          .remaining-count {
            padding: 4px;
            height: 22px;
            margin-right: 4px;
            min-width: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_27};
            border: solid 1px
              ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
            color: ${({ theme }): string =>
              hexToRgbA(theme.textColors.BLACK_COLOR, 0.8)};
          }

          .remaining-count {
            color: ${({ theme }): string => theme.textColors.DARK_GREY_115};
            font-family: OpenSansBold;
            cursor: pointer;
          }

          .first-val {
            max-width: 200px;
            span {
              ${ellipsis};
            }
          }

          .clickable {
            cursor: pointer;
          }

          :not(.selected-tag):not(.ref-view-tag) {
            :hover {
              .first-val,
              .remaining-count {
                background: ${({ theme }): string => theme.bgColors.BLUE_119};
                color: ${({ theme }): string => theme.textColors.DARK_BLUE};
                border: solid 1px
                  ${({ theme }): string => theme.borderColors.BLUE_22};
              }
            }
          }
        }

        .selected-tag {
          .first-val,
          .remaining-count {
            background: ${({ theme }): string => theme.bgColors.DARK_BLUE};
            color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
            border: solid 1px
              ${({ theme }): string => theme.borderColors.DARK_BLUE};
          }
        }
      }
    }
    .right-view {
      width: ${({ isEditable }): string => (isEditable ? "40%" : "45%")};
      .selected-rule-config-title {
        font-size: 15px;
        font-family: OpenSansSemiBold;
        color: ${({ theme }): string => theme.textColors.DARK_BLUE};
        margin-bottom: 17px;
      }
      .selected-rule-config-container {
        max-height: 200px;
        overflow-y: auto;
        .selected-rule-config {
          display: flex;
          border-bottom: solid 1px
            ${({ theme }): string =>
              hexToRgbA(theme.borderColors.LIGHT_GREY_11, 0.2)};
          min-height: 34px;
          display: flex;
          align-items: center;
          .selected-rule-config-value {
            width: 130px;
            font-family: OpenSansSemiBold;
            font-weight: 600;
            color: ${({ theme }): string => theme.textColors.GREY_25};
            margin-right: 15px;
            ${ellipsis};
          }
          .selected-rule-config-desc {
            color: ${({ theme }): string => theme.textColors.GREY_25};
            width: 76%;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-word;
          }
        }
      }
    }
  }
`;
