import { useMemo, useState } from "react";
import ShowMoreOrLessText from "../../../../showmoreorlesstext";

import { BuisinessRulesValuesKeys } from "../../sectionwitheditablebuisinessrules.types";
import { getBuisenessRulesForTerm } from "../../sectionwitheditablebusinessrules.utils";
import { BusinessRuleReadOnlyViewProps } from "./businessrulereadonlyview.types";

function BusinessRuleReadOnlyView(
  props: BusinessRuleReadOnlyViewProps
): JSX.Element {
  const { state, termType, isRefView } = props;
  const [selectedRule, setSelectedRule] = useState<BuisinessRulesValuesKeys>();

  const rulesConfig = useMemo(() => getBuisenessRulesForTerm(termType, state), [
    props,
  ]);

  return (
    <div className="asset-business-rules-section read-only-view">
      <div className="left-view">
        {Object.keys?.(rulesConfig)?.map((item, index) => {
          const typedItem = item as BuisinessRulesValuesKeys;
          return (
            <div className="businessrule-main-row" key={`${item}-${index}`}>
              <div className="businessrule-row-title">
                {rulesConfig?.[typedItem]?.title}
              </div>
              {rulesConfig?.[typedItem]?.type === "text" ||
              rulesConfig?.[typedItem]?.type === "min-max" ? (
                <div
                  className="businessrule-row-value"
                  title={rulesConfig?.[typedItem]?.value}
                >
                  <ShowMoreOrLessText
                    text={rulesConfig?.[typedItem]?.value || ""}
                  />
                </div>
              ) : (
                <div
                  className={`businessrule-tags-sec ${
                    typedItem === selectedRule ? "selected-tag" : ""
                  } ${isRefView ? "ref-view-tag" : ""}`}
                >
                  {rulesConfig?.[typedItem]?.firstValue ? (
                    <div
                      className={`first-val ${
                        !rulesConfig?.[typedItem]?.remaininItemsCount &&
                        !isRefView
                          ? "clickable"
                          : ""
                      }`}
                      role="button"
                      onClick={(): void => {
                        !rulesConfig?.[typedItem]?.remaininItemsCount &&
                          !isRefView &&
                          setSelectedRule(typedItem);
                      }}
                      title={rulesConfig?.[typedItem]?.firstValue}
                    >
                      <span>{rulesConfig?.[typedItem]?.firstValue}</span>
                    </div>
                  ) : (
                    "-"
                  )}
                  {!!rulesConfig?.[typedItem]?.remaininItemsCount && (
                    <div
                      className="remaining-count"
                      role="button"
                      onClick={(): void => {
                        setSelectedRule(typedItem);
                      }}
                    >
                      +{rulesConfig?.[typedItem]?.remaininItemsCount}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {!!selectedRule && rulesConfig?.[selectedRule] && (
        <div className="right-view">
          <div className="selected-rule-config-title">{selectedRule}:</div>
          <div className="selected-rule-config-container">
            {rulesConfig?.[selectedRule]?.details?.map((item, nestIndex) => (
              <div
                className="selected-rule-config"
                key={`${item?.value}-${item?.desc}-${nestIndex}`}
              >
                <div className="selected-rule-config-value" title={item?.value}>
                  {item?.value}
                </div>
                <div className="selected-rule-config-desc">
                  <span title={item?.desc}>{item?.desc || "-"}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default BusinessRuleReadOnlyView;
