import { Spin } from "antd";
import Tooltip, { TooltipPlacement } from "antd/lib/tooltip";
import { useCallback, useMemo } from "react";

import {
  GatewayStatusContextState,
  useGatewayStatusContext,
} from "../../contexts";

import {
  allGatewayUpIcon,
  allGatewaysDownIcon,
  downGatewayIcon,
  intermidiateGatewaysStateIcon,
  runningGatewayIcon,
} from "../../svgs";

import {
  GatewayItemStyled,
  GatewayListPopoverContainerStyled,
  GatewaysListPopoverContentStyled,
  GatewaysListPopoverStyled,
} from "./gatewayslistpopover.styles";

import DvSumPopover from "../dvsumpopover/dvsumpopover";
import { sortListOnSpecificKeyValue } from "../../utils";

import ConditionalWrapper from "../conditionalwrapper/conditionalwrapper";
import { DVSUM_TOOLTIP_CLASS_NAME, ELEMENT_IDS } from "../../constants";

import { useGetAppState } from "../../customhooks";
import GatewayRetryButton from "../gatewatretrybtn";

const {
  userjrn_per_gateways_lst_sec: USERJRN_PER_GATEWAYS_LST_SEC,
  userjrn_per_top_nav_gateways_sec: USERJRN_PER_TOP_NAV_GATEWAYS_SEC,
} = ELEMENT_IDS;

const GatewayItem = ({
  gateway,
}: {
  gateway: GatewayStatusContextState["listOfGateways"][number];
}): JSX.Element => {
  const {
    isPingcheckInProgress,
    isGatewayRunning,
    host,
    name,
    port,
    id = "",
  } = gateway;

  const onGatewayClick = useCallback(() => {
    window.open(`https://${host}:${port}/`);
  }, [host, port]);

  return (
    <GatewayItemStyled
      isGatewayRunning={isGatewayRunning}
      onClick={isGatewayRunning ? undefined : onGatewayClick}
    >
      <Tooltip
        overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
        title={isGatewayRunning ? "Connected" : ""}
        placement="right"
      >
        <div className="gateway-item-left-sec">
          {isPingcheckInProgress ? (
            <Spin size="small" />
          ) : isGatewayRunning ? (
            runningGatewayIcon()
          ) : (
            downGatewayIcon()
          )}
          <div className="gateway-name">{name}</div>(
          <div className="gateway-host" title={host}>
            {host}
          </div>
          )
        </div>
      </Tooltip>

      <div className="gateway-item-right-sec">
        <GatewayRetryButton id={id} />
      </div>
    </GatewayItemStyled>
  );
};

const GatewaysListPopover = ({
  title = "",
  placement = "rightBottom",
}: {
  title?: string;
  placement?: TooltipPlacement;
}): JSX.Element => {
  const {
    state: { listOfGateways = [], isFetchingGatewaysList },
  } = useGatewayStatusContext();

  const { caddiSideBarPosition } = useGetAppState();

  const filteredList = useMemo(
    () =>
      sortListOnSpecificKeyValue({
        list: listOfGateways?.filter((item) => item?.isOnpremiseGateway),
        type: "agGridStrSorting",
        key: "name",
      }),
    [listOfGateways]
  );

  const { isAllDown, isAllRunning, isSomeRunningOrDown } = useMemo(() => {
    return {
      isAllRunning: filteredList?.every((item) => item?.isGatewayRunning),
      isAllDown: filteredList?.every((item) => !item?.isGatewayRunning),
      isSomeRunningOrDown:
        filteredList?.some((item) => !item?.isGatewayRunning) ||
        filteredList?.some((item) => item?.isGatewayRunning),
    };
  }, [filteredList]);

  const iconToDisplay = useMemo(() => {
    switch (true) {
      case isAllRunning:
        return allGatewayUpIcon();
      case isAllDown:
        return allGatewaysDownIcon();
      case isSomeRunningOrDown:
        return intermidiateGatewaysStateIcon();
      default:
        return allGatewayUpIcon();
    }
  }, [isAllDown, isAllRunning, isSomeRunningOrDown]);

  return (
    <GatewayListPopoverContainerStyled
      className="gateways-list-popover-container"
      isCollapsed={caddiSideBarPosition === "collapsed"}
      id={USERJRN_PER_TOP_NAV_GATEWAYS_SEC}
    >
      <ConditionalWrapper
        condition
        wrapper={(child): JSX.Element =>
          isFetchingGatewaysList ? (
            <Tooltip
              overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
              title="Checking Connection"
              placement={placement}
            >
              {child}
            </Tooltip>
          ) : !filteredList?.length ? (
            <Tooltip
              overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
              title="No Gateway exists."
              placement={placement}
            >
              {child}
            </Tooltip>
          ) : (
            <DvSumPopover
              content={
                <GatewaysListPopoverContentStyled
                  id={USERJRN_PER_GATEWAYS_LST_SEC}
                >
                  <div className="list-head">
                    {filteredList?.length} Gateways
                  </div>
                  {filteredList?.map((gateway) => (
                    <GatewayItem
                      key={`gateway-list-${gateway?.id}`}
                      gateway={gateway}
                    />
                  ))}
                </GatewaysListPopoverContentStyled>
              }
              placement={placement}
              trigger={["click"]}
              getPopupContainer={(trigger): HTMLElement =>
                trigger?.parentNode as HTMLElement
              }
            >
              {child}
            </DvSumPopover>
          )
        }
      >
        <GatewaysListPopoverStyled className="gateways-list-popover">
          {isFetchingGatewaysList ? (
            <Spin size="default" />
          ) : (
            <>
              {iconToDisplay} {title}
            </>
          )}
        </GatewaysListPopoverStyled>
      </ConditionalWrapper>
    </GatewayListPopoverContainerStyled>
  );
};
export default GatewaysListPopover;
