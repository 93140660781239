import { Spin } from "antd";
import { useHistory } from "react-router";

import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "react-query";
import {
  useGetAssetDocument,
  useGetAssetRelationshipsData,
  useGetClassificationDataForAsset,
  useGetParsedCode,
  useGetPreSignedUrlForCodes,
  useGetSimilarNodes,
} from "../../api/assetservice/assetservice";

import {
  useCancelSecondaryModal,
  useGetAppState,
  useGetAppType,
  useGetData,
  useOpenDrawer,
  useOpenModal,
  useQueryParams,
  useSetData,
} from "../../customhooks";
import WorkflowHOC from "../workflowhoc";

import {
  AssetCodeHeaderWrapperStyled,
  AssetCodeInfoRendererStyled,
  AssetOverviewPopoverGlobalStyled,
  AssetOverviewRowStyled,
  AssetOverviewWrapperStyled,
  NodeRefQuickEditWrapperStyled,
} from "./assetoverview.styles";

import {
  AssetOverviewAdditionalInfoState,
  AssetOverviewHOCPropsType,
  AssetOverviewLinkedEntityState,
  AssetOverviewPropsType,
  AssetOverviewProvinenceState,
  AssetOverviewState,
  PageConfigItem,
  RefScreenTypes,
  SectionMap,
} from "./assetoverview.types";

import { DetailPageHistoryState } from "../noderefquickedit/noderefquickedit.types";
import {
  useGetGlossaryDomainsForSelect,
  useGetLoggedInUserDomainAndSubdomain,
} from "../../api/glossarydomainsservice";

import {
  useGetSuggestionsForOverview,
  useGetUserAndGroups,
} from "../../api/tablesservice";

import {
  AssetConfigItemType,
  AssetDocumentResponseType,
  AssetDocumenttParsedType,
  AssetFieldIds,
  ReturnTypeOfAssetRelationshipsData,
  getAssetConfig,
} from "../../parsers/asssetoverviewparser";

import {
  getColumnDetailPageUrl,
  getFiltersfromLocalStorage,
  getObjectEntries,
  getObjectKeys,
  getObjectValues,
  getProminentTags,
  getTableDetailPageUrl,
  getUserPermissions,
  isJsonString,
  openNotification,
} from "../../utils";

import { appendQueryParamInUrl, useRequestWithMethod } from "../../api";
import { API_CONFIG } from "../../constants/apiconfig";

import SuccessNotificationMessage from "../successnotificationmessagerendrer";

import { overviewAssetFormatter } from "../../utils/assetsfomratter";
import { ELEMENT_IDS } from "../../constants";

import StateHandler from "../statehandler";

import DetailPagesOverviewRightSec from "../detailpagesoverviewrightsec";

import SuggestionsRendrer from "../suggestionrendrer";

import TabsWithRightSection from "../tabswithrightsec";
import DetailPagesHeader from "../detailpagesheader";

import {
  SectionWithEditable,
  SectionWithEditableAdditionalinfo,
  SectionWithEditableBuisenessRules,
  SectionWithEditableLinkedEntity,
  SectionWithEditableSources,
  SectionWithEditableTags,
} from "../sectionwitheditables";

import SectionTitleWithIcon from "../sectiontitlewithicon";

import ManagedBySectionWithEditable from "../sectionwitheditables/sectionwitheditablemanagedby";
import LineageSectionTitleWithIcon from "../lineagesectitlewithicon";

import LineagePreviewer from "../lineagepreviewer";
import { codeIcon, editPencilIcon } from "../../svgs";

import RouterPrompt from "../routerprompt";
import { Section } from "../section";

import QualitySectionTitleWithIcon from "../qualitysectitlewithicon";

import {
  assetOverviewCustomCompRenderer,
  AssetUsabilityScoreContent,
  assetOverviewRenderer,
} from "./assetoverview.renderer";

import AssetDescriptionEditableView from "../desceditableview";
import { BusinessRulesState } from "../sectionwitheditables/sectionwitheditablebuisinessrules/sectionwitheditablebuisinessrules.types";

import { TermType } from "../../parsers/termsparser";
import ConditionalWrapper from "../conditionalwrapper";

import ConditionalDisplay from "../conditionaldisplay";
import { Item } from "../form";

import LinkButton from "../linkbutton";

import { TermRefDrawerProps } from "../../drawerviews/termrefdrawer/termrefdrawer.types";

import {
  assetOverviewDetailPageBlankSlate,
  assetOverviewDetailPageHeaderBlankSlate,
  assetOverviewRefPageBlankSlate,
  assetOverviewRefPageHeaderBlankSlate,
} from "../../blankslates/assetoverviewblankslate";

import SimilarNodesAssetsView from "../similarnodeassetsview/similarnodesassetsview";
import {
  getAssetUsabilityScore,
  isDataDomainChanged,
} from "./assetoverview.util";

import { transformAssetRelationshipParsedData } from "./assetoverview.utils";
import SectionWithEditableRelationships from "../sectionwitheditables/sectionwitheditablerelationships";

import {
  AssetRelationshipProvider,
  useAssetRelationshipContext,
} from "../../contexts";
import { getAssetConfigurations } from "../../utils/getassetconfiguration";

import { GenericFormValues } from "../desceditableview/desceditableview.types";
import { useGetRefByType } from "../../api/refservice";

import AssetCompareChangesPanel from "../assetcomparechanges";
import ContentPreviewer from "../contentpreviewer";

import { etlRefCodeBlankSlate } from "../../blankslates/etlblankslate";
import { DeleteTermModalProps } from "../../pages/listingpage/listingpage.components/deleteterm/deleteterm.types";
import {
  MarkAsDeprecateSuggestionForm,
  OverviewCreateSuggestion,
  OverviewSuggestAnEdit,
} from "../../forms";
import ExtBreadCrumb from "../breadcrum/extbreadcrumb";
import { CERTIFY_TAGSET } from "../../pages/tablepage/tablepage.constants";

const {
  anldict_detpg_ove_save_desc_info: ANLDICT_DETPG_OVE_SAVE_DESC_INFO,
  anldict_detpg_ovw_edit_defi: ANLDICT_DETPG_OVW_EDIT_DEFI,
  edit_relationship_icon: EDIT_RELATIONSHIP_ICON,
} = ELEMENT_IDS;

const edgeCodeIcon = codeIcon();

const AssetOverviewContent = (
  props: AssetOverviewHOCPropsType
): JSX.Element => {
  const params = useQueryParams();
  const queryClient = useQueryClient();
  const history = useHistory<DetailPageHistoryState>();
  const openModal = useOpenModal();
  const openDrawer = useOpenDrawer();
  const onCancel = useCancelSecondaryModal();
  const { isExtOrDesktop } = useGetAppType();
  const [screenType, setScreenType] = useState<RefScreenTypes>("REF");

  const {
    sideBarPosition,
    expandableGridProps: { isGridExpanded = false } = { isGridExpanded: false },
  } = useGetAppState();

  const onSetData = useSetData();
  const onGetData = useGetData();

  const {
    nodeId,
    isReportPage = false,
    workflow,
    setNodeSubmittedForApproval,
    onSubmitForApproval,
    onCancelSubmission,
    onNodeCommentOrRejectRequest,
    onApproveNodeRequest,
    onToggleReviewChangesPanel,
    showReviewChangesPanel,
    isNodeSubmittedForApproval,
    workflowErrorState,
    workflowLoadingState,
    isNodeSendForPublish,
    parsedData,
    isFetchingOverviewData,
    isLoading,
    error,
    responseData,
    nodeType = "DSR",

    tableId = "",
    isRefView = false,
    showRightSecButton = true,
    assetRelationshipsDataError,
    assetRelationshipsDataIsLoading,
    assetRelationshipsDataParsedData = [],
    refetchAssetRelationshipsData,
    isFetchingAssetRelationshipsData,
    hasEditAccess = true,
  } = props;

  const onOpenModal = useOpenModal();

  // const nodeType = "RFD";

  const isColumnPage = nodeType === "COL";
  const isTermPage = nodeType === "TRM";
  const isRulePage = nodeType === "RLS";
  const isRefDictPage = nodeType === "RFD";

  const isSideBarCollapsed = sideBarPosition === "collapsed";

  const isEditableInParams = !!params?.get("isEdit");

  const isScrollInParams = !!params?.get("scroll");

  const { isWorkflowStepsLoading, isWorkflowActionLoading } =
    workflowLoadingState || {};

  const { errorInWorkflowAction, errorInWorkflowSteps } =
    workflowErrorState || {};

  const [isWorkflowEnabled, setIsWorkflowEnabled] = useState<boolean>(
    parsedData?.is_wf_enabled
  );

  const [isClickedOnCertify, setIsClickedOnCertify] = useState<string>("");

  const {
    page_breadcrumb: pageBreadcrum,
    job_schedulers: jobSchedulers,
    node_system_generated_desc: sysGeneratedDesc = "",
    node_name: assetTechName = "",
    node_title: assetDisplayTitle = "",
    parent_id: parentId = "",
    code_s3_url: codeS3Url,
    code: parsedCode,
    node_sub_type: nodeSubType,
    is_editable: isEditable,
  } = parsedData || {};

  const assetDisplayName = assetDisplayTitle || assetTechName || "";

  console.log("assetDisplayName", {
    assetDisplayTitle,
    assetTechName,
    assetDisplayName,
  });

  const { data: pageBreadcrumData = [] } = pageBreadcrum || {};

  const isNodeScheduled = useMemo(() => jobSchedulers?.length > 0, [
    jobSchedulers,
  ]);

  const [
    fetchingClassificationData,
    setFetchingClassificationData,
  ] = useState<boolean>(false);

  const [triggerState, setTriggerState] = useState(false);
  const [isShowOverViewEditIcon, setIsShowOverViewEditIcon] = useState<boolean>(
    false
  );

  const [isClickOnDone, setClickOnDone] = useState<boolean>();
  const [selectedSuggestionId, setSuggestionId] = useState<string>();

  const [overViewState, setOverviewState] = useState<AssetOverviewState>({
    description: parsedData?.description,
    managedBy: parsedData?.managed_by,
    classification: parsedData?.classification,
    dataQuality: parsedData?.data_quality,
    custom_definition: parsedData?.custom_definition,
  });

  const [businessRules, setBuisenessRules] = useState<BusinessRulesState>(
    parsedData?.business_rules
  );

  const [
    isClickedOnPublishOrDraft,
    setIsClickedOnPublishOrDraft,
  ] = useState<boolean>(false);

  const [
    additionalState,
    setAdditionalState,
  ] = useState<AssetOverviewAdditionalInfoState>(parsedData?.additional_info);

  const [
    overViewProvinanceState,
    setoverViewProvinanceState,
  ] = useState<AssetOverviewProvinenceState>(parsedData?.represents);

  const [
    linkedEntityState,
    setLinkedEntityState,
  ] = useState<AssetOverviewLinkedEntityState>(parsedData?.linked_entity);

  const {
    updateState: updateAssetRelationShipState,
    state: RelationshipState,
    relationshipsRef,
  } = useAssetRelationshipContext();

  const {
    parsedData: presignedUrlForCode,
    isFetching: isFetchingPresignedUrlForCode,
    error: errorPresignedUrlForCode,
  } = useGetPreSignedUrlForCodes(codeS3Url, !!codeS3Url);

  const { url = "" } = presignedUrlForCode || {};

  const {
    parsedData: S3CodeDetails,
    isFetching: isFetchingCode,
    error: errorEtlCode,
  } = useGetParsedCode(url);

  const S3Code = S3CodeDetails?.plo_code || "";

  const assetCode = S3Code || parsedCode;

  const {
    parsedData: parsedDrafData,
    isLoading: draftDataLoading,
    error: draftDataError,
    data: daftResponseData,
  } = useGetAssetDocument(
    nodeId,
    nodeType,
    "DFT",
    isRefView,
    !isLoading && parsedData?.is_editable && parsedData?.is_draft_exist
  );

  const {
    parsedData: suggestionsData,
    isLoading: sugestionsLoading,
    error: suggestionsError,
  } = useGetSuggestionsForOverview(
    nodeId,
    !isLoading && parsedData?.is_editable
  );

  const {
    parsedData: userAndGroupsData,
    isLoading: userAndGroupsDataIsLoading,
    error: userAndGroupsDataError,
  } = useGetUserAndGroups(!isLoading && parsedData?.is_editable);

  const {
    isLoading: classificationLoading,
    parsedData: classificationTagsData,
    error: classificationTagsError,
    refetch: refetchClassificationData,
    isFetching: isFetchingClassificationData,
  } = useGetClassificationDataForAsset(
    nodeId,
    parsedData?.is_draft_exist,
    !isLoading && parsedData?.is_editable,
    nodeType
  );

  const isFetchColTypes = !isRefView && isColumnPage;

  const {
    parsedData: parsedColTypes = [],
    isLoading: isLoadingColtypes,
  } = useGetRefByType("COL_TYPE", undefined, isFetchColTypes);

  const isFetchTermSubTypes = !isRefView && isTermPage;

  const {
    parsedData: parsedTermSubTypes = [],
    isLoading: isLoadingTermSubTypes,
  } = useGetRefByType("TERM_SUBTYPE", undefined, isFetchTermSubTypes);

  const { parsedData: listOfDomains = [] } = useGetGlossaryDomainsForSelect(
    !isRefView
  );

  const {
    parsedData: listOfSubDomains,
    isLoading: isLoadingSubDomains,
  } = useGetLoggedInUserDomainAndSubdomain(!isRefView);

  const {
    parsedData: parsedSimilarNodes,
    isLoading: isLoadingSimilarNodes,
    error: errorSimilarNodes,
  } = useGetSimilarNodes(nodeId, !isRefView);

  const assetRelationshipsListParsedData = getAssetConfigurations(nodeType);

  const {
    parsedData: assetRelationshipsDraftDataParsedData,
    isLoading: assetRelationshipsDraftDataIsLoading,
    isFetching: isFetchingAssetRelationshipsDraftData,
    error: assetRelationshipsDraftDataError,
    refetch: refetchAssetRelationshipsDraftData,
  } = useGetAssetRelationshipsData(nodeId, !isRefView, "DFT");

  useEffect(() => {
    if (
      isClickedOnPublishOrDraft &&
      !isFetchingAssetRelationshipsData &&
      !isFetchingAssetRelationshipsDraftData
    ) {
      setIsClickedOnPublishOrDraft(false);
      setTriggerState(!triggerState);
    }
  }, [isFetchingAssetRelationshipsData, isFetchingAssetRelationshipsDraftData]);

  useEffect(() => {
    if (isScrollInParams && relationshipsRef?.current) {
      const rect = relationshipsRef?.current?.getBoundingClientRect();
      const offset = 150;
      const scrollPosition = window?.scrollY + rect?.top - offset;

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [relationshipsRef?.current, isScrollInParams]);

  useEffect(() => {
    if (!assetRelationshipsDataIsLoading) {
      const transformedAssetRelationshipsData = transformAssetRelationshipParsedData(
        assetRelationshipsListParsedData,
        assetRelationshipsDataParsedData
      );

      updateAssetRelationShipState({
        nodeName: parsedData?.node_name,
        nodeId: parsedData?.node_id || nodeId,
        nodeTitle: parsedData?.node_title,
        data: transformedAssetRelationshipsData,
        isEditable: false,
        isEdited: false,
        selectedAssetFilter: ["All"],
        assetSearchText: "",
        nodeType,
        isRefPage: isRefView,
      });
    }
  }, [assetRelationshipsDataIsLoading]);

  const onSuccess = useCallback(
    async (response: AxiosResponse<AssetDocumentResponseType>) => {
      const shouldFetchTheRelationshipsData =
        RelationshipState?.isEdited ||
        parsedData?.is_draft_exist ||
        isDataDomainChanged(
          parsedData?.description?.data,
          overViewState?.description?.data
        );

      if (shouldFetchTheRelationshipsData) {
        refetchAssetRelationshipsData();
        refetchAssetRelationshipsDraftData();
      }
      const nodedStatusId = isJsonString(response?.config?.data)
        ? JSON.parse(response?.config?.data)?.published_status_id || ""
        : "";

      const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);

      const api = appendQueryParamInUrl(API_CONFIG.get_node.url, [
        nodeId,
        nodeType,
        nodedStatusId,
        "",
        "",
        parsedNodeFilter,
      ]);

      if (nodedStatusId === "DFT") {
        const pubVer = appendQueryParamInUrl(API_CONFIG.get_node.url, [
          nodeId,
          nodeType,
          "PUB",
          "",
          "",
          parsedNodeFilter,
        ]);
        const pubData = queryClient.getQueryData(pubVer) as any;
        queryClient.setQueriesData(pubVer, {
          ...pubData,
          data: {
            ...pubData?.data,
            is_draft_exists: true,
            updatingData: Math.random(),
          },
        });
      }

      const data = queryClient.getQueryData(api) as any;

      queryClient.setQueriesData(api, {
        ...data,
        data: { ...response?.data, updatingData: Math.random() },
      });

      await refetchClassificationData();

      setFetchingClassificationData(false);
      setIsShowOverViewEditIcon(false);
      setClickOnDone(false);
      if (!shouldFetchTheRelationshipsData) {
        setTriggerState(!triggerState);
        setIsClickedOnPublishOrDraft(false);
      }

      if (isNodeSubmittedForApproval?.current && parsedData?.is_wf_enabled)
        onSubmitForApproval?.();

      if (!isNodeSubmittedForApproval?.current) {
        openNotification(
          <SuccessNotificationMessage
            message={` Your changes have been
        ${nodedStatusId === "DFT" ? " saved as draft." : " published."}`}
          />
        );
      }
    },
    [
      isShowOverViewEditIcon,
      parsedData,
      isClickOnDone,
      triggerState,
      isNodeSubmittedForApproval?.current,
      nodeType,
      RelationshipState,
      overViewState,
    ]
  );

  const onRefViewEditSuccess = useCallback(
    async (
      response: AxiosResponse<AssetDocumentResponseType>,
      formValues?: GenericFormValues,
      hasUserChangedInheritedDesc?: boolean
    ) => {
      const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);

      if (isColumnPage) {
        const { description = "", title } = formValues || {};
        const updatedTitle = title || assetTechName;

        const colRefData = onGetData(API_CONFIG.get_node, [
          nodeId,
          nodeType,
          "PUB",
          "true",
          "",
          parsedNodeFilter,
        ]) as AxiosResponse<AssetDocumentResponseType>;

        const colRefFormattedData: AssetDocumentResponseType = {
          ...colRefData?.data,
          node_name: updatedTitle,
          title: {
            value: updatedTitle,
            is_changed: true,
          },
          description: {
            ...colRefData?.data?.description,
            description: {
              is_changed: true,
              value: description,
              is_inherited: !hasUserChangedInheritedDesc,
            },
          },
        };

        onSetData(API_CONFIG.get_node, colRefFormattedData, [
          nodeId,
          nodeType,
          "PUB",
          "true",
          "",
          parsedNodeFilter,
        ]);

        const tableColumnsDetails = onGetData(API_CONFIG.get_table_columns, [
          parentId,
        ]);

        if (tableColumnsDetails) {
          onSetData(API_CONFIG.get_table_columns, response?.data, [parentId]);
        }
      } else {
        const detailPageData = onGetData(API_CONFIG.get_node, [
          nodeId,
          nodeType,
          "PUB",
          "",
          "",
          parsedNodeFilter,
        ]);

        if (detailPageData) {
          onSetData(API_CONFIG.get_node, response?.data, [
            nodeId,
            nodeType,
            "PUB",
            "",
            "",
            parsedNodeFilter,
          ]);
        }

        onSetData(API_CONFIG.get_node, response?.data, [
          nodeId,
          nodeType,
          "PUB",
          "true",
          "",
          parsedNodeFilter,
        ]);
      }

      setOverviewState((st) => {
        return {
          ...st,
          description: {
            ...st?.description,
            isRefViewEditable: false,
            isEditable: false,
          },
        };
      });

      openNotification(
        <SuccessNotificationMessage message="Your changes have been saved" />,
        0,
        {
          top: isExtOrDesktop ? 0 : 60,
          className: "desktop-app-center-notification",
          getContainer: (): any => {
            const elements = document.getElementsByClassName(
              "ant-drawer-content"
            );
            return (elements?.length && elements[0]) || document.body;
          },
        }
      );
    },
    [parsedData]
  );

  const onSwitchScreen = useCallback(
    (sType?: RefScreenTypes) => (): void => {
      setScreenType(
        sType || (screenType === "SUG" || screenType === "DEP" ? "OPT" : "REF")
      );
    },
    [screenType]
  );

  const onClickSuggest = useCallback(() => {
    setScreenType("OPT");
  }, []);

  const onFailure = useCallback(() => {
    setFetchingClassificationData(false);
  }, [fetchingClassificationData]);

  const {
    onExecuteRequest,
    isLoading: updateNodeLoading,
    error: updateNodeError,
  } = useRequestWithMethod(
    "update_node",
    [nodeId, nodeType, "DFT"],
    true,
    onSuccess,
    onFailure
  );

  const {
    isLoading: updateColIsLoading,
    onExecuteRequest: updateColumn,
    error: updateColumnError,
  } = useRequestWithMethod(
    "edit_column_config",
    [parentId, "true"],
    false,
    onRefViewEditSuccess
  );

  const stateChanger = useCallback(
    (
      stateChangerData: AssetDocumenttParsedType,
      RelationshipsData: ReturnTypeOfAssetRelationshipsData
    ) => {
      setOverviewState({
        description: stateChangerData?.description,
        managedBy: stateChangerData?.managed_by,
        classification: {
          ...stateChangerData?.classification,
          tagsData: classificationTagsData,
        },
        dataQuality: stateChangerData?.data_quality,
        custom_definition: stateChangerData?.custom_definition,
      });
      setAdditionalState(stateChangerData?.additional_info);
      setLinkedEntityState(stateChangerData?.linked_entity);
      setIsWorkflowEnabled(stateChangerData?.is_wf_enabled);
      setoverViewProvinanceState(stateChangerData?.represents);
      setBuisenessRules(stateChangerData?.business_rules);

      const transformedAssetRelationshipsData = transformAssetRelationshipParsedData(
        assetRelationshipsListParsedData,
        RelationshipsData
      );

      updateAssetRelationShipState({
        nodeName: stateChangerData?.node_name,
        nodeTitle: stateChangerData?.node_title,
        nodeId: parsedData?.node_id || nodeId,
        data: transformedAssetRelationshipsData,
        isEditable: false,
        isEdited: false,
        selectedAssetFilter: RelationshipState?.selectedAssetFilter,
        assetSearchText: RelationshipState?.assetSearchText,
        nodeType,
        isRefPage: isRefView,
      });
    },
    [
      parsedData,
      responseData,
      additionalState,
      overViewState,
      triggerState,
      classificationTagsData,
      assetRelationshipsListParsedData,
    ]
  );

  const disabledConditions = useMemo(() => {
    // console.log("getObjectKeys(overViewState)", {
    //   keys: overViewState,
    //   anyEdited: getObjectKeys(overViewState)?.some(
    //     (item) => overViewState[item]?.isEdited
    //   ),
    //   anyInEditCond: getObjectKeys(overViewState)?.some(
    //     (item) => overViewState[item]?.isEditable
    //   ),
    //   businessRulesisEdited: businessRules?.isEdited,
    //   businessRulesisEditable: businessRules?.isEditable,
    //   businessRules,
    // });
    const isAnyChanged =
      getObjectKeys(overViewState)?.some(
        (item) => overViewState[item]?.isEdited
      ) ||
      additionalState?.isEdited ||
      overViewProvinanceState?.isEdited ||
      linkedEntityState?.isEdited ||
      businessRules?.isEdited ||
      RelationshipState?.isEdited;

    const isAnyUnsavedChanges =
      getObjectKeys(overViewState)?.some(
        (item) => overViewState[item]?.isEditable
      ) ||
      additionalState?.isEditable ||
      overViewProvinanceState?.isEditable ||
      linkedEntityState?.isEditable ||
      businessRules?.isEditable ||
      RelationshipState?.isEditable;

    return {
      isAnyChanged: isRefView ? false : isAnyChanged,
      isAnyUnsavedChanges: isRefView ? false : isAnyUnsavedChanges,
    };
  }, [
    isRefView,
    additionalState,
    overViewState,
    overViewProvinanceState,
    linkedEntityState,
    businessRules,
    RelationshipState,
  ]);

  const onEditOverview = useCallback(() => {
    if (parsedData?.is_draft_exist) {
      stateChanger(parsedDrafData, assetRelationshipsDraftDataParsedData);
    } else {
      setOverviewState((st) => ({
        ...st,
        classification: {
          ...st?.classification,
          tagsData: classificationTagsData,
        },
      }));
    }
    setOverviewState((st) => ({
      ...st,
      ...getObjectKeys(overViewState).reduce(
        (prev, item) => ({
          ...prev,
          [item]: {
            ...st[item],
            data: st[item]?.data?.map((nestItem) => ({
              ...nestItem,
              isRowVisibleInNormalView:
                nestItem?.id === AssetFieldIds.usabilityScore
                  ? false
                  : nestItem?.isRowVisibleInNormalView,
            })),
          },
        }),
        {}
      ),
    }));

    setIsShowOverViewEditIcon(true);
  }, [
    overViewState,
    isShowOverViewEditIcon,
    parsedDrafData,
    classificationTagsData,
    assetRelationshipsDraftDataParsedData,
  ]);

  const onDiscardChanges = useCallback(() => {
    stateChanger(parsedData, assetRelationshipsDataParsedData);
    setIsShowOverViewEditIcon(false);
    setClickOnDone(false);
  }, [parsedData, isShowOverViewEditIcon]);

  const onClickDone = useCallback(() => {
    setClickOnDone(!isClickOnDone);
  }, [isClickOnDone]);

  const onSuggestionClick = useCallback(
    (item) => {
      setSuggestionId(item?.id);
    },
    [selectedSuggestionId]
  );

  const selectedSuggestion = useMemo(
    () =>
      suggestionsData?.suggestionDetaislList?.find(
        (item: any) => item?.id === selectedSuggestionId
      ),
    [selectedSuggestionId, suggestionsData]
  );

  const { updatedTermType } = useMemo(() => {
    return {
      updatedTermType: overViewState?.description?.data?.find(
        (item) => item?.id === AssetFieldIds.termType
      )?.selectedValues?.[0] as TermType,
    };
  }, [overViewState?.description?.data]);

  const currentAssetConfig = getAssetConfig(
    nodeType,
    isReportPage ? "RPT" : undefined
  );

  const isColDescInherited = overViewState?.description?.data?.find(
    (item) => item?.id === AssetFieldIds.description
  )?.isInherited;

  const initialDesc = parsedData?.description?.data?.find(
    (item) => item?.id === AssetFieldIds.description
  )?.value;

  const usabilityScore = getAssetUsabilityScore(
    overViewState,
    overViewProvinanceState,
    parsedData?.lineage,
    businessRules,
    updatedTermType,
    additionalState,
    currentAssetConfig,
    RelationshipState?.data
  );

  const nodeTitle = useMemo(
    () =>
      overViewState?.description?.data?.find(
        (item) => item?.id === AssetFieldIds.businessName
      )?.value || "",
    [overViewState]
  );

  const onPublishOrDraft = useCallback(
    (status: "PUB" | "DFT") => {
      setIsClickedOnPublishOrDraft(true);
      const parsedDataAccordingStatus =
        status === "PUB" ? parsedData : parsedDrafData;
      setFetchingClassificationData(true);

      const parsedApiData = overviewAssetFormatter(
        status === "PUB"
          ? responseData?.data
          : parsedData?.is_draft_exist
          ? daftResponseData?.data
          : responseData?.data,
        {
          ...parsedData,
          node_name: parsedDataAccordingStatus?.node_name,
          node_desc: parsedDataAccordingStatus?.node_desc,
          node_type: parsedDataAccordingStatus?.node_type,
          node_sub_type: parsedDataAccordingStatus?.node_sub_type,
          node_url: parsedDataAccordingStatus?.node_url,
          is_editable: parsedDataAccordingStatus?.is_editable,
          source_name: parsedDataAccordingStatus?.source_name,
          is_draft_exist: parsedData?.is_draft_exist,
          is_endorsed: parsedDataAccordingStatus?.is_endorsed,
          description: overViewState?.description,
          custom_definition: overViewState?.custom_definition,
          managed_by: overViewState?.managedBy,
          additional_info: additionalState,
          classification: overViewState?.classification,
          endorsed_count: parsedDataAccordingStatus?.endorsed_count,
          is_deprecated: parsedData?.is_deprecated,
          publish_status_id: parsedData?.publish_status_id,
          publish_status: parsedData?.publish_status,
          lineage: parsedData?.lineage,
          get_access_info: parsedData?.get_access_info,
          is_wf_enabled: parsedData?.is_wf_enabled,
          represents: overViewProvinanceState,
          src_type_id: parsedData?.src_type_id,
          db_name: parsedData?.db_name,
          schema_name: parsedData?.schema_name,
          source_id: parsedData?.source_id,
          linked_entity: linkedEntityState,
          business_rules: businessRules,
          relationships: RelationshipState,
        },
        usabilityScore?.obtainedScore,
        userAndGroupsData,
        status === "DFT",
        status === "DFT"
          ? "DFT"
          : status === "PUB"
          ? "PUB"
          : responseData?.data?.published_status_id,
        nodeType,
        nodeId,
        RelationshipState?.data
      );

      onExecuteRequest(parsedApiData, [
        nodeId,
        nodeType,
        status,
        "",
        "",
        getFiltersfromLocalStorage(nodeType),
      ]);
    },
    [
      parsedData,
      responseData,
      parsedDrafData,
      daftResponseData,
      overViewState,
      additionalState,
      userAndGroupsData,
      overViewProvinanceState,
      linkedEntityState,
      businessRules,
      RelationshipState,
    ]
  );

  const onQuickEditSave = useCallback(
    (formValues: GenericFormValues) => {
      const assetData = responseData?.data;

      const updatedtitle = formValues?.title || nodeTitle;
      const updatedDesc = formValues?.description;
      const updatedTrmName = formValues?.short_name;

      setOverviewState((st) => ({
        ...st,
        description: {
          ...st?.description,
          data: st?.description?.data?.map((obj) => {
            const isTitle = obj?.id === AssetFieldIds?.longName;
            const isDesc = obj?.id === AssetFieldIds?.description;
            const isTermName = obj?.id === AssetFieldIds?.shortName;
            return isTitle || isDesc || isTermName
              ? {
                  ...obj,
                  value: isTitle
                    ? updatedtitle
                    : isDesc
                    ? updatedDesc
                    : isTermName
                    ? updatedTrmName
                    : "",
                }
              : obj;
          }),
        },
      }));

      const parsedApiData = {
        ...assetData,
        title: { value: updatedtitle, is_changed: true },

        ...(isRulePage && {
          overview: {
            description: {
              value: updatedDesc,
              is_changed: true,
            },
          },
        }),

        ...(!isRulePage && {
          description: {
            ...responseData?.data?.description,
            description: {
              ...responseData?.data?.description?.description,
              is_changed: true,
              value: updatedDesc,
            },

            ...(isTermPage && {
              term_name: {
                ...responseData?.data?.description?.term_name,
                value: updatedTrmName,
                is_changed: true,
              },

              definition: {
                ...responseData?.data?.description?.definition,
                is_changed: true,
                value: updatedDesc,
              },
            }),
          },
        }),
      };

      const hasUserChangedInheritedDesc = isColDescInherited
        ? !!(isColumnPage && initialDesc !== updatedDesc)
        : true;

      if (isColumnPage) {
        updateColumn(
          [
            {
              COL_ID: nodeId,
              COL_TITLE: updatedtitle,
              COL_USER_DESC: hasUserChangedInheritedDesc ? updatedDesc : "",
            },
          ],
          [parentId, "true"],
          (res): void => {
            onRefViewEditSuccess?.(
              res,
              formValues,
              hasUserChangedInheritedDesc
            );
          }
        );
      } else {
        onExecuteRequest(
          parsedApiData,
          [
            nodeId,
            nodeType,
            "PUB",
            "true",
            "",
            getFiltersfromLocalStorage(nodeType),
          ],
          onRefViewEditSuccess
        );
      }
    },
    [
      responseData,
      isColDescInherited,
      parsedData,
      nodeTitle,
      onRefViewEditSuccess,
    ]
  );

  const rightSecLoading =
    userAndGroupsDataIsLoading ||
    classificationLoading ||
    sugestionsLoading ||
    draftDataLoading ||
    isLoading ||
    isFetchingOverviewData ||
    isWorkflowStepsLoading ||
    isWorkflowActionLoading ||
    isLoadingSubDomains ||
    isLoadingTermSubTypes ||
    isLoadingColtypes;

  const rightSecError =
    userAndGroupsDataError ||
    classificationTagsError ||
    suggestionsError ||
    draftDataError ||
    error ||
    errorInWorkflowAction ||
    errorInWorkflowSteps;

  const onChangeWorkflowStatus = useCallback((workflowStatus: boolean) => {
    setIsWorkflowEnabled(workflowStatus);
  }, []);

  const { has_no_lineage_access: hasNoLineageAccess } = getUserPermissions();

  const userHasLineageAccess = !hasNoLineageAccess;

  const isAnyRefViewEditOpen = overViewState?.description?.isRefViewEditable;

  const getDefinitionEditableSection = (sectionId: string): JSX.Element => {
    return (
      <AssetDescriptionEditableView
        state={overViewState}
        setState={setOverviewState}
        id={sectionId}
        isReportPage={isReportPage}
        onChangeWorkflowStatus={onChangeWorkflowStatus}
        nodeType={nodeType}
        nodeSubType={nodeSubType}
        nodeId={nodeId}
        secTitle={{ title: "" }}
        className={isAnyRefViewEditOpen ? "ref-editableView" : ""}
        isRefViewEdit={isRefView && isAnyRefViewEditOpen}
        onQuickEditSave={onQuickEditSave}
        listOfColTypes={parsedColTypes}
        listOfDomains={listOfDomains}
        listOfSubDomains={listOfSubDomains}
        listOfTermSubTypes={parsedTermSubTypes}
      />
    );
  };

  const getDefinitionViewableSection = (sectionId: string): JSX.Element => {
    const editableRowsCount =
      overViewState[sectionId]?.data?.filter((item) => item?.isEditable)
        ?.length ?? 0;

    const isShowEditIcon = isShowOverViewEditIcon && !!editableRowsCount;

    return (
      <SectionWithEditable
        {...props}
        secTitle={{
          title: currentAssetConfig?.[sectionId]?.sectionName,
          isShowIcon: isShowEditIcon,
          elemIdOfEdit: ANLDICT_DETPG_OVW_EDIT_DEFI,
          className: "asset-desc-title",
        }}
        id={sectionId}
        state={overViewState}
        setState={setOverviewState}
        rendrer={assetOverviewRenderer}
        customRenderer={assetOverviewCustomCompRenderer}
        isWorkflowEnabled={isWorkflowEnabled}
        additionalPropsForRenderer={{
          onEditOverview: !rightSecLoading && !rightSecError && onEditOverview,
          isEditor: parsedData?.is_editable,
        }}
        className="asset-overview-description-section"
        isRefView={isRefView}
      />
    );
  };

  const descriptionSec = overViewState?.description?.isEditable
    ? getDefinitionEditableSection("description")
    : getDefinitionViewableSection("description");

  const customDefinitionSec = overViewState?.custom_definition?.isEditable
    ? getDefinitionEditableSection("custom_definition")
    : getDefinitionViewableSection("custom_definition");

  const managedBySec = (
    <ManagedBySectionWithEditable
      state={overViewState}
      setState={setOverviewState}
      rendrer={assetOverviewRenderer}
      secTitle={{
        title: currentAssetConfig?.managed_by?.sectionName,
        isShowIcon: isShowOverViewEditIcon,
        className: "asset-title",
      }}
      id="managedBy"
      dropdownData={userAndGroupsData}
      nodeType={nodeType}
      isReportPage={isReportPage}
      nodeId={nodeId}
      commentActivity={onNodeCommentOrRejectRequest}
      isRefView={isRefView}
    />
  );

  const editableLineageSec = (
    <SectionWithEditableSources
      secTitle={{
        title: currentAssetConfig?.lineage?.sectionName,
        marginBottom: "25px",
        isShowIcon: isShowOverViewEditIcon,
        className: "asset-title",
      }}
      state={overViewProvinanceState}
      setState={setoverViewProvinanceState}
    />
  );

  const viewableLineageSec = (
    <>
      <SectionTitleWithIcon
        title={
          <LineageSectionTitleWithIcon
            title={currentAssetConfig?.lineage?.sectionName}
            queryParams={{
              nodeId,
              mode: "none",
              nodeName: nodeTitle,
              nodeType,
              nodeSubType: isReportPage ? "RPT" : undefined,
            }}
            lineageExists={parsedData?.lineage?.lineageExists}
          />
        }
        marginBottom="25px"
        isShowIcon={false}
        className="asset-title"
      />
      <LineagePreviewer
        {...parsedData?.lineage}
        align="start"
        lineageExists={parsedData?.lineage?.lineageExists}
      />
    </>
  );

  const isLineageEditable =
    currentAssetConfig?.lineage?.isSectionHasEditAction ?? true;

  const lineageSec =
    isShowOverViewEditIcon && userHasLineageAccess && isLineageEditable
      ? editableLineageSec
      : viewableLineageSec;

  const representsSec = (
    <SectionWithEditableSources
      secTitle={{
        title: currentAssetConfig?.represents?.sectionName,
        marginBottom: "25px",
        isShowIcon: isShowOverViewEditIcon,
        className: "asset-title",
      }}
      state={overViewProvinanceState}
      setState={setoverViewProvinanceState}
      isRefView={isRefView}
    />
  );

  const tagsSection = (
    <SectionWithEditableTags
      secTitle={{
        title: currentAssetConfig?.classification?.sectionName,
        isShowIcon: isShowOverViewEditIcon,
        className: "asset-title",
      }}
      id="classification"
      state={overViewState}
      setState={setOverviewState}
      className="classifications-sec"
      isClickedOnCertify={isClickedOnCertify}
    />
  );

  const relationshipsSection = (
    <SectionWithEditableRelationships
      secTitle={{
        title: currentAssetConfig?.relationships?.sectionName,
        isShowIcon: isShowOverViewEditIcon,
        elemIdOfEdit: EDIT_RELATIONSHIP_ICON,
      }}
      isLoading={assetRelationshipsDataIsLoading}
      errorPublishData={assetRelationshipsDataError}
      errorDraftData={assetRelationshipsDraftDataError}
      assetRelationshipsDataParsedData={assetRelationshipsDataParsedData}
    />
  );

  const additionalInfoSection = (
    <SectionWithEditableAdditionalinfo
      state={additionalState}
      setState={setAdditionalState}
      secTitle={{
        title: currentAssetConfig?.additional_info?.sectionName,
        isShowIcon: isShowOverViewEditIcon,
        className: "asset-title",
      }}
    />
  );

  const editableLinkedEntitySec = (
    <SectionWithEditableLinkedEntity
      secTitle={{
        title: currentAssetConfig?.linked_entity?.sectionName,
        isShowIcon: isShowOverViewEditIcon,
        className: "asset-title",
      }}
      state={linkedEntityState}
      setState={setLinkedEntityState}
    />
  );

  const viewableDqSection = (
    <Section
      {...props}
      secTitle={{
        title: (
          <QualitySectionTitleWithIcon
            title={currentAssetConfig?.data_quality?.sectionName}
            nodeId={nodeId}
            isColRef={isColumnPage}
          />
        ),
        isShowIcon: false,
        marginBottom: "15px",
        className: "asset-title",
      }}
      secRowClassName="asset-data-quality-section"
      secRowData={parsedData?.data_quality?.data?.map((item) => {
        const typedId = item?.id as keyof typeof Item;
        return {
          ...item,
          customJSX: assetOverviewRenderer?.(item)?.[typedId]?.(),
          customRowComp: assetOverviewCustomCompRenderer?.(item)?.[typedId]?.(),
        };
      })}
      dataQualityData={{
        tableId: parentId,
        nodeId,
      }}
      {...(isColumnPage && {
        mode: "data_quality",
      })}
    />
  );

  const dqSection = parsedData?.is_dq_enabled ? viewableDqSection : <div />;

  const businessRulesSec = (
    <SectionWithEditableBuisenessRules
      secTitle={{
        title: currentAssetConfig?.business_rules?.sectionName,
        isShowIcon: isShowOverViewEditIcon,
        className: "asset-title",
      }}
      state={businessRules}
      setState={setBuisenessRules}
      termType={parsedData?.term_type}
      updatedTermType={updatedTermType}
      isDefaultTerm={parsedData?.trm_source_id === "SYS"}
      isRefView={isRefView}
    />
  );

  const linkedEntitySection = linkedEntityState ? (
    editableLinkedEntitySec
  ) : (
    <div />
  );

  const refDrawerHeaderJsx = (): JSX.Element => (
    <AssetCodeHeaderWrapperStyled>
      <div className="icon">{edgeCodeIcon}</div>
      <span className="etl-name">Etl Code</span>
    </AssetCodeHeaderWrapperStyled>
  );

  const replacedStr = assetCode?.replace(
    /\\([nrt])/g,
    (_: string, char: string): string => {
      const map: Record<string, string> = { n: "\n ", r: "\r ", t: "\t " };
      return map[char] || char;
    }
  );

  const codeSection = (
    <AssetCodeInfoRendererStyled>
      <SectionTitleWithIcon
        title={currentAssetConfig?.asset_code?.sectionName}
        marginBottom="25px"
        isShowIcon={false}
        className="asset-title"
      />
      <StateHandler
        isFetching={isFetchingPresignedUrlForCode || isFetchingCode}
        error={errorEtlCode || errorPresignedUrlForCode}
        blankSlate={etlRefCodeBlankSlate}
      >
        <ContentPreviewer
          title="Source Code"
          showCrossIcon={false}
          marginBottom="0"
          sqlQuery={replacedStr}
          addScroll
          showCollapseIcon={false}
          showExpandIcon
          expandedModalTitle={refDrawerHeaderJsx}
        />
      </StateHandler>
    </AssetCodeInfoRendererStyled>
  );

  const pageConfig = useMemo(() => {
    const assetRendererBaseOnId: Record<string, JSX.Element> = {
      description: descriptionSec,
      managed_by: managedBySec,
      lineage: lineageSec,
      classification: tagsSection,
      additional_info: additionalInfoSection,
      relationships: relationshipsSection,
      data_quality: dqSection,
      linked_entity: linkedEntitySection,
      business_rules: businessRulesSec,
      represents: representsSec,
      asset_code: codeSection,
      custom_definition: customDefinitionSec,
    };

    const currentConfigValues: AssetConfigItemType[] = getObjectValues(
      currentAssetConfig
    );

    const sortedDetailPageConfig: AssetConfigItemType[] = currentConfigValues
      ?.filter((item) => {
        const { isApplicableOnDetailPage = true } = item || {};
        return isApplicableOnDetailPage;
      })
      ?.sort((a, b) => a?.detailPageSequence - b?.detailPageSequence);

    const sortedRefPageConfig: AssetConfigItemType[] = currentConfigValues
      ?.filter((item) => {
        const { isApplicableOnRefPage = true } = item || {};
        return isApplicableOnRefPage;
      })
      ?.sort((a, b) => {
        const refPageSeqA = a?.refPageSequence || 0;
        const refPageSeqB = b?.refPageSequence || 0;

        return refPageSeqA - refPageSeqB;
      });

    const sortedRefPageConfigStates = isAnyRefViewEditOpen
      ? sortedRefPageConfig?.filter((item) => item?.sectionId === "description")
      : sortedRefPageConfig;

    const groupByPairs = sortedDetailPageConfig?.reduce<
      Array<AssetConfigItemType[]>
    >((acc, item) => {
      if (item?.isFullSpan) {
        acc?.push([item]);
      } else {
        const lastRow = acc?.[acc?.length - 1];

        const isLastItemFullSpan = lastRow?.[0]?.isFullSpan ?? false;
        const isLastItemTakesFullRow = lastRow?.[0]?.isSingleItemInRow ?? false;

        const isRowHasTwoItems = lastRow?.length === 2;
        const isLastRowHasCompleted =
          isLastItemTakesFullRow || isRowHasTwoItems;

        if (!lastRow || isLastRowHasCompleted || isLastItemFullSpan) {
          // If no last row exists, or the last row is full, or the last row is a full-span item, start a new row
          acc?.push([item]);
        } else {
          lastRow?.push(item);
        }
      }

      return acc;
    }, []);

    const detailPageConfig = getObjectEntries(groupByPairs)
      ?.map(([rowId, rows]) => {
        const leftSecRow = rows?.[0];
        const rightSecRow = rows?.[1];

        const leftSecId = leftSecRow?.sectionId as keyof AssetConfigItemType["rowId"];
        const rightSecId = rightSecRow?.sectionId as keyof AssetConfigItemType["rowId"];

        const isFullSpan: boolean = rows?.some(
          (row: AssetConfigItemType) => row?.isFullSpan
        );

        const isSectionOnRightSide: boolean = rows?.some(
          (row: AssetConfigItemType) => row?.isAlwaysOnRightSide
        );

        const isSingleItemInRow: boolean = rows?.length === 1;

        const isRelationShipGrid =
          leftSecId === "relationships" || rightSecId === "relationships";

        const getApplicableSection = (sectionId: string): JSX.Element => {
          const sectionJsx = assetRendererBaseOnId[sectionId];

          const isApplicable =
            currentAssetConfig?.[sectionId]?.isApplicableOnDetailPage ?? true;

          return isApplicable ? sectionJsx : <div />;
        };

        const lftSecCnt = getApplicableSection(leftSecId);
        const rgtSecCnt = getApplicableSection(rightSecId);

        const sectionMap: SectionMap = {
          true: {
            true: (content: JSX.Element): JSX.Element => content, // isGridExpanded && isRelationShipGrid
            false: (): JSX.Element => <div />, // isGridExpanded && !isRelationShipGrid
          },
          false: {
            true: (content: JSX.Element): JSX.Element => content, // !isGridExpanded && isRelationShipGrid
            false: (content: JSX.Element): JSX.Element => content, // !isGridExpanded && !isRelationShipGrid
          },
        };

        const lftSec = sectionMap[String(isGridExpanded)][
          String(isRelationShipGrid)
        ](lftSecCnt);

        const rgtSec = sectionMap[String(isGridExpanded)][
          String(isRelationShipGrid)
        ](rgtSecCnt);

        return {
          rowId: Number(rowId),
          leftComponents:
            isSingleItemInRow && isSectionOnRightSide ? <div /> : lftSec,
          rightComponent:
            isSingleItemInRow && isSectionOnRightSide ? lftSec : rgtSec,
          key: `row-key-${rowId}`,
          isFullwidth: isFullSpan || false,
        };
      })
      ?.flat();

    const refViewConfig = sortedRefPageConfigStates?.map((config) => {
      const { sectionId = "" } = config || {};

      const leftSecId = sectionId as keyof AssetConfigItemType["sectionId"];

      const isLeftSectionApplicable =
        currentAssetConfig?.[leftSecId]?.isApplicableOnRefPage ?? true;

      const leftSectionContent = assetRendererBaseOnId[leftSecId];

      return {
        rowId: Number(sectionId),
        leftComponents: isLeftSectionApplicable ? leftSectionContent : <span />,
        rightComponent: <></>,
        key: `row-key-${sectionId}`,
        isFullwidth: true,
      };
    });

    return isRefView ? refViewConfig : detailPageConfig;
  }, [
    props,
    parsedData,
    responseData,
    additionalState,
    overViewState,
    isShowOverViewEditIcon,
    userAndGroupsData,
    classificationLoading,
    rightSecLoading,
    rightSecError,
    setOverviewState,
    usabilityScore,
    setAdditionalState,
    nodeId,
    isWorkflowEnabled,
    overViewProvinanceState,
    hasNoLineageAccess,
    nodeType,
    onChangeWorkflowStatus,
    linkedEntityState,
    setLinkedEntityState,
    isAnyRefViewEditOpen,
    assetRelationshipsDataParsedData,
    isClickedOnCertify,
  ]);

  const prominentTags = useMemo(
    () => getProminentTags(overViewState?.classification?.data),
    [overViewState]
  );

  const statusTags = useMemo(() => parsedData?.status_tag || [], [parsedData]);

  const updatedTermName = useMemo(
    () =>
      overViewState?.description?.data?.find(
        (item) => item?.id === AssetFieldIds?.shortName
      )?.value || "",
    [overViewState]
  );

  const updatedRuleDesc = useMemo(
    () =>
      overViewState?.description?.data?.find(
        (item) => item?.id === AssetFieldIds?.description
      )?.value || "",
    [overViewState]
  );

  const ruleUpdatedDesc = useMemo(() => {
    return updatedRuleDesc
      ? `${updatedRuleDesc}. ${sysGeneratedDesc}`
      : sysGeneratedDesc;
  }, [updatedRuleDesc, sysGeneratedDesc]);

  const nodeDesc = useMemo(
    () =>
      isTermPage
        ? updatedTermName
        : isRulePage && !isRefView
        ? ruleUpdatedDesc
        : isRefDictPage || isRulePage
        ? ""
        : assetTechName,
    [updatedTermName, ruleUpdatedDesc, assetTechName]
  );

  useEffect(() => {
    stateChanger(parsedData, assetRelationshipsDataParsedData);
  }, [isLoading, triggerState, nodeId, isFetchingOverviewData]);

  useEffect(() => {
    !classificationLoading &&
      !isLoading &&
      setOverviewState((st) => ({
        ...st,
        classification: {
          ...overViewState?.classification,
          tagsData: classificationTagsData,
        },
      }));
  }, [classificationLoading]);

  useEffect(() => {
    if (
      parsedData?.is_editable &&
      !rightSecLoading &&
      isEditableInParams &&
      !isRefView
    ) {
      onEditOverview();
      params?.delete("isEdit");
      history?.replace({
        search: params?.toString(),
      });
    }
  }, [rightSecLoading, params]);

  // reset to technical name if node title is empty
  useEffect(() => {
    if (!nodeTitle?.trim()) {
      setOverviewState((st) => ({
        ...st,
        description: {
          ...st?.description,
          data: st?.description?.data?.map((item) => {
            const isTitleField = item?.id === AssetFieldIds.businessName;

            return {
              ...item,
              value: isTitleField ? parsedData?.node_name : item?.value,
            };
          }),
        },
      }));
    }
  }, [nodeTitle]);

  const onQuickEditClick = useCallback(() => {
    if (isColumnPage && isColDescInherited) {
      openModal(
        {
          modalId: "quick_edit_for_col_confirmation",
          modalTitle: "Edit Column Details",
          visible: true,
          modalProps: {
            onEditEntity: (): void => {
              openDrawer({
                drawerId: "term_ref",
                drawerProps: {
                  id: parsedData?.entity_id,
                  isEdit: true,
                } as TermRefDrawerProps,
                visible: true,
                isStartFromTop: false,
                zIndex: 100001,
              });
              onCancel();
            },
            onEditField: (): void => {
              setOverviewState((st) => ({
                ...st,
                description: {
                  ...st?.description,
                  isEditable: !st?.description?.isEditable,
                  isRefViewEditable: !st?.description?.isRefViewEditable,
                },
              }));
              onCancel();
            },
          },
        },
        true
      );
    } else {
      setOverviewState((st) => ({
        ...st,
        description: {
          ...st?.description,
          isEditable: !st?.description?.isEditable,
          isRefViewEditable: !st?.description?.isRefViewEditable,
        },
      }));
    }
  }, [overViewState, isColDescInherited, isColumnPage]);

  const showRightHeaderSection = !isRefView && showRightSecButton;

  const headerBlankSlate = isRefView
    ? assetOverviewRefPageHeaderBlankSlate
    : assetOverviewDetailPageHeaderBlankSlate;

  const contentBlankSlate = isRefView
    ? assetOverviewRefPageBlankSlate
    : assetOverviewDetailPageBlankSlate;

  const isRefViewSaving = !!(
    (updateNodeLoading || updateColIsLoading) &&
    isRefView
  );

  const isCreateSuggestionPage = screenType === "SUG";
  const isRefMarkAsDepSuggestionPage = screenType === "DEP";
  const isChooseSuggestionPage = screenType === "OPT";
  const isNodeMainRefScreen = screenType === "REF";

  const isSuggestionScreen =
    isCreateSuggestionPage ||
    isRefMarkAsDepSuggestionPage ||
    isChooseSuggestionPage;

  const showSuggestionButton = !isEditable && !isSuggestionScreen && isRefView;

  // desc and title are being used in suggest an edit form.
  const assetDescriptionForm = useMemo(() => {
    return (
      parsedData?.description?.data?.find(
        (item) => item?.id === AssetFieldIds.description
      )?.value || ""
    );
  }, [parsedData]);

  const assetTitleForm = useMemo(() => {
    return (
      parsedData?.description?.data?.find(
        (item) => item?.id === AssetFieldIds.businessName
      )?.value || ""
    );
  }, [parsedData]);

  const refPageProps = useMemo(() => {
    return {
      nodeId,
      nodeType,
      nodeTitle: assetTitleForm,
      nodeDesc: assetDescriptionForm,
    };
  }, [nodeId, nodeType, assetTitleForm, assetDescriptionForm]);

  const computedConfig = useMemo(() => {
    return (
      pageConfig?.reduce(
        (acc, row) => {
          if (acc?.hasFullWidthOccurred) {
            acc?.result?.push({ ...row, applyFullRowClass: true });
          } else {
            acc?.result?.push({ ...row, applyFullRowClass: row?.isFullwidth });
            if (row?.isFullwidth) acc.hasFullWidthOccurred = true;
          }
          return acc;
        },
        {
          hasFullWidthOccurred: false,
          result: [] as (PageConfigItem & { applyFullRowClass: boolean })[],
        }
      )?.result || []
    );
  }, [pageConfig]);

  const onClickDelete = useCallback(() => {
    onOpenModal({
      modalId: "delete_terms",
      visible: true,
      modalTitle: "Delete Term",
      modalProps: {
        nodeId,
        nodeType: "TRM",
        isTermOverviewPage: true,
      } as DeleteTermModalProps,
    });
  }, [nodeId]);

  const isTagsetCertified = useMemo(() => {
    return overViewState?.classification?.tagsData?.[
      CERTIFY_TAGSET
    ]?.tagsData?.some((item) => item?.isAdded);
  }, [overViewState]);

  const onChangeClickCertifyBtn = useCallback(
    (val: string) => {
      setIsClickedOnCertify(val);
    },
    [isClickedOnCertify]
  );

  return (
    <StateHandler
      error={assetRelationshipsDataError}
      isShowBlankSlate={false}
      isFetching={
        isClickedOnPublishOrDraft &&
        (isFetchingAssetRelationshipsData ||
          isFetchingAssetRelationshipsDraftData ||
          updateNodeLoading ||
          isFetchingClassificationData)
      }
      isFullScreenLoading
      isModal
    >
      <StateHandler
        showSpinner={isNodeSendForPublish}
        isFetching={isRefViewSaving}
        error={updateNodeError}
        isModal={isRefViewSaving}
        isShowBlankSlate={false}
      >
        <AssetOverviewWrapperStyled
          isEditable={isShowOverViewEditIcon}
          isRefView={isRefView}
          isSideBarCollapsed={isSideBarCollapsed}
          isAnyGridExpanded={isGridExpanded}
          isSuggestionScreen={isSuggestionScreen}
          className="AssetOverviewWrapperStyled"
        >
          <AssetOverviewPopoverGlobalStyled />
          <RouterPrompt
            when={
              disabledConditions?.isAnyChanged ||
              disabledConditions?.isAnyUnsavedChanges
            }
            onLeaveCallback={onDiscardChanges}
          />
          <ConditionalDisplay condition={!isGridExpanded}>
            <>
              <StateHandler
                isFetching={isLoading}
                error={error}
                blankSlate={headerBlankSlate}
              >
                {isNodeMainRefScreen ? (
                  <DetailPagesHeader
                    workflow={workflow}
                    desc={nodeDesc}
                    endorsedCount={parsedData?.endorsed_count}
                    title={nodeTitle}
                    source={pageBreadcrumData}
                    isEndorsed={parsedData?.is_endorsed}
                    isDeprecated={parsedData?.is_deprecated}
                    nodeId={nodeId}
                    nodeType={nodeType}
                    publishStatusId={
                      !isShowOverViewEditIcon
                        ? parsedData?.publish_status_id
                        : parsedData?.is_draft_exist
                        ? parsedDrafData?.publish_status_id
                        : parsedData?.publish_status_id
                    }
                    publishStatus={
                      !isShowOverViewEditIcon
                        ? parsedData?.publish_status
                        : parsedData?.is_draft_exist
                        ? parsedDrafData?.publish_status
                        : parsedData?.publish_status
                    }
                    isNodeScheduled={isNodeScheduled}
                    prominentTags={prominentTags}
                    statusTags={statusTags}
                    nodeSubType={parsedData?.node_sub_type}
                    nodeUrl={parsedData?.node_url}
                    popularityScoreData={parsedData?.popularity_score}
                    showRightSecButton={showRightHeaderSection}
                    isRefView={isRefView}
                    isRedirectIconDisabled={isAnyRefViewEditOpen}
                    onClickSuggest={onClickSuggest}
                    showSuggestionButton={showSuggestionButton}
                  />
                ) : (
                  <ExtBreadCrumb onBackClick={onSwitchScreen()} />
                )}
              </StateHandler>
            </>
          </ConditionalDisplay>

          <ConditionalDisplay condition={!isGridExpanded}>
            <ConditionalWrapper
              condition={!isRefView}
              wrapper={(children): JSX.Element => (
                <TabsWithRightSection tabs={props?.tabs}>
                  <>{children}</>
                </TabsWithRightSection>
              )}
            >
              <ConditionalDisplay condition={!isRefView}>
                <StateHandler
                  isFetching={rightSecLoading}
                  error={rightSecError}
                  blankSlate={
                    rightSecLoading && !isLoading ? <Spin /> : <div />
                  }
                  showChildrenInCaseOfError={false}
                  showAnimation={false}
                >
                  <DetailPagesOverviewRightSec
                    workflow={workflow}
                    handlers={{
                      onSubmitForApproval,
                      onCancelSubmission,
                      onNodeCommentOrRejectRequest,
                      onApproveNodeRequest,
                      setNodeSubmittedForApproval,
                    }}
                    isWorkflowEnabled={parsedData?.is_wf_enabled}
                    onToggleReviewChangesPanel={onToggleReviewChangesPanel}
                    showReviewChangesPanel={showReviewChangesPanel}
                    isEditable={isShowOverViewEditIcon}
                    isEdited={disabledConditions?.isAnyChanged}
                    isSaved={parsedData?.is_draft_exist}
                    onEditClick={onEditOverview}
                    onDiscardChanges={onDiscardChanges}
                    suggestions={suggestionsData?.suggestionsList}
                    onSuggestionClick={onSuggestionClick}
                    nodeId={nodeId}
                    nodeType={nodeType}
                    nodeSubType={parsedData?.node_sub_type}
                    onApply={onPublishOrDraft}
                    isViewer={!parsedData?.is_editable}
                    isLoading={
                      !!updateNodeLoading || fetchingClassificationData
                    }
                    isAnyUnsavedChanges={
                      disabledConditions?.isAnyUnsavedChanges
                    }
                    onClickDone={onClickDone}
                    isClickOnDone={!!isClickOnDone}
                    usabilityScore={usabilityScore}
                    nodeTitle={assetTitleForm}
                    nodeDesc={assetDescriptionForm}
                    isReportPage={isReportPage}
                    isDepricated={parsedData?.is_deprecated}
                    hasEditAccess={hasEditAccess}
                    onClickDelete={onClickDelete}
                    onClickCertify={
                      isTagsetCertified ? undefined : onChangeClickCertifyBtn
                    }
                  />
                </StateHandler>
              </ConditionalDisplay>
            </ConditionalWrapper>
          </ConditionalDisplay>

          {showReviewChangesPanel ? (
            <AssetCompareChangesPanel
              nodeId={nodeId}
              nodeType={nodeType}
              nodeName={nodeTitle}
            />
          ) : (
            <>
              <StateHandler
                isFetching={
                  isLoading || history?.location?.state?.isEdit
                    ? rightSecLoading
                    : false
                }
                error={error}
                blankSlate={contentBlankSlate}
              >
                <div className="main-container">
                  {isRefView && !isAnyRefViewEditOpen && isEditable && (
                    <NodeRefQuickEditWrapperStyled>
                      <LinkButton
                        className="edit-pencil"
                        onClick={onQuickEditClick}
                        tooltipProps={{
                          title: isWorkflowEnabled
                            ? "The workflow is enabled. To make changes, navigate to the detail page and submit your edits for approval."
                            : "",
                          placement: "left",
                          overlayStyle: { maxWidth: "400px" },
                        }}
                        disabled={isWorkflowEnabled}
                      >
                        {editPencilIcon("13", "13")}
                      </LinkButton>
                    </NodeRefQuickEditWrapperStyled>
                  )}

                  {isCreateSuggestionPage ? (
                    <OverviewCreateSuggestion
                      {...refPageProps}
                      isRefPage
                      onCancel={onSwitchScreen("REF")}
                    />
                  ) : isRefMarkAsDepSuggestionPage ? (
                    <MarkAsDeprecateSuggestionForm
                      {...refPageProps}
                      isRefPage
                      onCancel={onSwitchScreen("REF")}
                    />
                  ) : isChooseSuggestionPage ? (
                    <OverviewSuggestAnEdit
                      {...refPageProps}
                      isRefPage
                      onSelectOption={(option): void =>
                        onSwitchScreen(option === "suggest" ? "SUG" : "DEP")()
                      }
                    />
                  ) : (
                    <div className="rows-sec">
                      {computedConfig?.map((row, index) => {
                        return (
                          <AssetOverviewRowStyled
                            className={`row ${
                              row?.applyFullRowClass ? "full-row" : ""
                            }`}
                            key={`${row.key}-${index}`}
                            isEditable={isShowOverViewEditIcon}
                            isFullWidth={row?.isFullwidth}
                            isRefView={isRefView}
                            isAnyRefViewEditOpen={isAnyRefViewEditOpen}
                          >
                            <div className="left">{row.leftComponents}</div>
                            <div className="right">{row?.rightComponent}</div>
                          </AssetOverviewRowStyled>
                        );
                      })}
                    </div>
                  )}

                  <div className="usability-score">
                    <ConditionalDisplay condition={!isGridExpanded}>
                      <div className="suggestion-usability-score-wrapper">
                        {selectedSuggestion && (
                          <SuggestionsRendrer sugestion={selectedSuggestion} />
                        )}
                        {isShowOverViewEditIcon &&
                          AssetUsabilityScoreContent({
                            usabilityScore,
                            isSimpleView: true,
                          })}
                      </div>
                    </ConditionalDisplay>
                  </div>
                </div>
              </StateHandler>

              {!isRefView && !isGridExpanded && (
                <SimilarNodesAssetsView
                  id={nodeId}
                  title="Similar Assets"
                  parsedData={parsedSimilarNodes}
                  isLoading={isLoadingSimilarNodes}
                  error={errorSimilarNodes}
                />
              )}
            </>
          )}
        </AssetOverviewWrapperStyled>
      </StateHandler>
    </StateHandler>
  );
};

const AssetOverview = (props: AssetOverviewPropsType): JSX.Element => {
  const { nodeId, isReportPage, nodeType = "TBL", isRefView } = props;

  const {
    data,
    parsedData,
    isLoading,
    error,
    isFetching: isFetchingOverviewData,
    refetch: refetchNodeData,
  } = useGetAssetDocument(nodeId, nodeType, "PUB", isRefView);

  const {
    parsedData: assetRelationshipsDataParsedData,
    isLoading: assetRelationshipsDataIsLoading,
    isFetching: isFetchingAssetRelationshipsData,
    error: assetRelationshipsDataError,
    refetch: refetchAssetRelationshipsData,
  } = useGetAssetRelationshipsData(nodeId, !isRefView, "PUB");

  return (
    <AssetRelationshipProvider>
      <WorkflowHOC
        nodeId={nodeId}
        nodeType={nodeType}
        isReportPage={isReportPage}
        refetchNodeData={refetchNodeData}
        refetchAssetRelationshipsData={refetchAssetRelationshipsData}
      >
        <AssetOverviewContent
          {...props}
          isFetchingOverviewData={isFetchingOverviewData}
          error={(error || assetRelationshipsDataError) as Error}
          parsedData={parsedData}
          isLoading={isLoading || assetRelationshipsDataIsLoading}
          responseData={data}
          nodeType={nodeType}
          assetRelationshipsDataParsedData={assetRelationshipsDataParsedData}
          assetRelationshipsDataIsLoading={assetRelationshipsDataIsLoading}
          isFetchingAssetRelationshipsData={isFetchingAssetRelationshipsData}
          assetRelationshipsDataError={assetRelationshipsDataError as Error}
          refetchAssetRelationshipsData={refetchAssetRelationshipsData}
        />
      </WorkflowHOC>
    </AssetRelationshipProvider>
  );
};

export default AssetOverview;
