import { NodeType } from "../../app.types";
import { APP_ROUTES } from "../../constants";
import { getDetailpageUrlBasedOnNodeType } from "../../utils";

import { BreadcrumItemStringType, ItemType } from "./styledbreadcrum.types";

export const getBreadCrumItemType = (
  item: BreadcrumItemStringType
): ItemType => {
  return {
    isSrcNameItem: item === "SRC",
    isSchemaNameItem: item === "SCM",
    isTblNameItem: item === "TBL",
    isDbNameItem: item === "DTB",
    isTermDmnItem: item === "DMN",
    isTermCategoryItem: item === "CAT",
    isColItem: item === "COL",
    isDsrItem: item === "DSR",
  };
};

export const getBreadcrumItemUrl = (
  nodeId: string,
  nodeType: NodeType
): string => {
  const { isTermCategoryItem, isTermDmnItem } = getBreadCrumItemType(nodeType);

  const url = getDetailpageUrlBasedOnNodeType(nodeId, nodeType);

  if (isTermCategoryItem || isTermDmnItem) return "";

  if (url) {
    return url;
  }

  return APP_ROUTES.private_routes.home;
};

export const getBreadcrumItemClassName = (
  typeOfBreadcrum: BreadcrumItemStringType
): string => {
  const {
    isSrcNameItem,
    isSchemaNameItem,
    isTblNameItem,
    isDbNameItem,
    isTermDmnItem,
    isTermCategoryItem,
    isDsrItem,
    isColItem,
  } = getBreadCrumItemType(typeOfBreadcrum);

  if (isSrcNameItem) {
    return "src-connection-name";
  }

  if (isTblNameItem) {
    return "tbl-name";
  }

  if (isColItem) {
    return "col-name";
  }

  if (isDbNameItem) {
    return "db-name";
  }

  if (isSchemaNameItem) {
    return "schema-name";
  }

  if (isTermDmnItem) {
    return "term-dmn-name";
  }

  if (isTermCategoryItem) {
    return "term-category-name";
  }

  if (isDsrItem) {
    return "dsr-name";
  }

  return "";
};
