import styled from "styled-components";

import { ellipsis } from "../../styles/app.styles";

export const DetailPagesHeaderStyled = styled.div<{
  isWorkflowInProgress: boolean;
  isRefView?: boolean;
}>`
  &.page-header {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-bottom: ${({ isRefView }): string => (isRefView ? "35px" : "18px")};

    .left {
      max-width: ${({ isWorkflowInProgress, isRefView }): string =>
        isRefView ? "100%" : isWorkflowInProgress ? "50%" : "80%"};
      min-width: ${({ isWorkflowInProgress, isRefView }): string =>
        isRefView ? "100%" : isWorkflowInProgress ? "50%" : "80%"};

      .sec-breadcrum {
        display: flex;
        align-items: center;
        color: ${(props): string => props.theme.textColors.LIGHT_GREY_5};
        font-size: 15px;
        margin-bottom: 17px;

        > div {
          display: flex;
          align-items: center;
          .breadcrum-item {
            display: flex;
            align-items: center;
            svg {
              margin-right: 6px;
            }
          }

          .right-arrow-icon {
            margin: 0px 12px;

            > svg {
              width: 7px;
            }
          }
        }
      }

      .link-only {
        padding: 0px;
        margin: 0px;
        height: auto;
        border-left: none;
      }

      .title-desc,
      .node-url-label {
        opacity: 0.8;
      }

      .node-url-label {
        width: 100%;
      }
    }
  }

  .right {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    max-width: ${({ isWorkflowInProgress }): string =>
      isWorkflowInProgress ? "50%" : "20%"};
    min-width: ${({ isWorkflowInProgress }): string =>
      isWorkflowInProgress ? "50%" : "20%"};

    .term-page-wizard-container {
      margin-top: 8px;
      padding: 5px 15px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);

      .seperation-line {
        width: 80px;
      }

      .outer-circle {
        height: 35px;
        width: 35px;
      }

      .inner-circle {
        height: 25px;
        width: 25px;

        svg {
          width: 12px;
          height: 12px;
        }

        span {
          font-size: 12px;
        }
      }
    }

    .ant-btn {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      font-size: 13px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .action-icon-sec {
        display: flex;
        align-items: center;

        .endorsed-count {
          font-family: OpenSansSemiBold;
          font-size: 15px;
          margin-left: 6px;
        }
      }

      .selected {
        svg {
          > g {
            > path {
              fill: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
              stroke: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
            }
          }
          > g {
            > g {
              path {
                :first-of-type {
                  fill: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
                  stroke: ${({ theme }): string => theme.bgColors.DARK_GREY_10};
                }
                :last-of-type {
                  fill: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
                  stroke: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
                }
              }
            }
          }
        }
      }

      :first-of-type {
        margin-right: 34px;
        min-width: 58px;
      }

      :nth-of-type(2) {
        /* margin-right: 29px; */
        min-width: 55px;
      }
    }
  }
`;
export const WizardDescriptionStyled = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  .more {
    cursor: pointer;
  }
`;

export const SectionHeaderWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
`;

export const SectionHeaderMetaWrapperStyled = styled.div<{
  isClickable?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 6px;

  .asset-meta-info {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .letter-icon-mapping {
    font-size: 12px;
  }

  .info-title {
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-size: 19px;
    font-weight: 600;
    font-family: OpenSansSemiBold;
    cursor: ${({ isClickable }): any => !isClickable && "default"};
    max-width: 80%;
    ${ellipsis}
  }

  .info-subtitle {
    color: ${({ theme }): string => theme.textColors.DARK_GREY};
    font-size: 12px;
    opacity: 0.8;
    line-height: 1;
  }

  .scheduled-icon {
    height: 18px;

    svg {
      path {
        fill: ${({ theme }): string => theme.bgColors.GREY_210};
      }
    }
  }

  .status-rendrer {
    margin-right: 10px;
  }

  .asset-redirect-icon-wrapper {
    height: 23px;
    margin-left: 8px;
  }
`;

export const SectionSubHeaderWrapperStyled = styled.div<{
  isWorkflowInProgress?: boolean;
  isRefView?: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
  font-size: 15px;
  opacity: 0.8;
  line-height: unset;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
  width: 100%;

  padding-left: ${({ isRefView }): string => (isRefView ? "33px" : "")};
  margin-top: ${({ isRefView }): string => (isRefView ? "5px" : "")};
  /* margin-bottom: 12px; */

  .node-tech-name {
    display: flex;
    align-items: center;
    column-gap: 5px;
    width: 100%;

    .desc {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      word-break: break-all;
      max-width: 95%;
      ${ellipsis}
    }
  }

  .node-url-sec {
    display: flex;
    column-gap: 6.4px;
    align-items: center;

    .flex-container {
      span {
        max-width: ${({ isWorkflowInProgress }): string =>
          isWorkflowInProgress ? "calc(100vw - 1000px)" : ""};
      }
    }
  }
`;

export const SectionTagsWrapperStyled = styled.div`
  display: none;
  column-gap: 6px;

  &.small-screen-tags-section {
    display: flex;
    padding-left: 33px;
  }

  &.large-screen-tags-section {
    display: flex;
  }
`;

export const RightSectionWrapperStyled = styled.div`
  display: flex;
  align-items: baseline;
`;
