// <--- Constants Start --->
import { LOCAL_STORAGE_CONSTANTS } from "../constants/localstorageconstants";
// <--- Constants End --->

// <--- Types Start --->
import { NodeType, PostLoginFormType } from "../app.types";
// <--- Types End --->

// <--- Utils Start --->
import { isJsonString, jsonParse, sortObjectsArrayByKey } from ".";
import { ReturnTypeOfAssetRelationshipsList } from "../parsers/asssetoverviewparser";
// <--- Utils End --->

export function getAssetConfigurations(
  nodeType?: NodeType
): ReturnTypeOfAssetRelationshipsList {
  const data =
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.assets_configurations) || "";

  const parsedData: ReturnTypeOfAssetRelationshipsList = jsonParse(data);

  const filteredData = nodeType
    ? parsedData.filter((config) => config?.nodeType === nodeType)
    : parsedData;

  return sortObjectsArrayByKey(filteredData, "relationship_type");
}
