import { QueryObserverResult, UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import { NodeType } from "../../app.types";
import { TagsData } from "../../components/tagseditor/tagseditor.types";

import { API_CONFIG } from "../../constants/apiconfig";
import { APIKeys } from "../../constants/constant.types";

import {
  AssetDocumentResponseType,
  getAssetChangesForReview,
  getAssetTAGContextParser,
  getParsedAssetProfilingDocument,
  getParsedAssetRelationshipsData,
  getParsedAssetsConfiguration,
  getParsedAssetsDocument,
  getParsedSimilarAssetNodes,
  AssetDocumenttParsedType,
  AssetFieldIds,
  AssetProfilingInfoParsedType,
  AssetReviewChangesParsedType,
  AssetReviewChangesResponse,
  AssetsConfigurationsListResponseType,
  ReturnTypeOfAssetRelationshipsData,
  ReturnTypeOfAssetRelationshipsList,
  SimilarNodesParserReturnType,
} from "../../parsers/asssetoverviewparser";

import { getFiltersfromLocalStorage, getObjectKeys } from "../../utils";
import { useGetAll } from "../baseservice";

import { CustomReactQueryHookReturnType } from "../services.types";
import { SectionRowProps } from "../../components/sectionrow";

import { GetClassificationCustomTagsAddInfo } from "../../parsers/parserutils/getclassificationcustomtags";
import { useGetLoggedInUserDomainAndSubdomain } from "../glossarydomainsservice";
import { Represents } from "../../components/represents";

const {
  get_node: getNode,
  get_asset_relationships_list: getAssetRelationshipsList,
  get_asset_relationships_data: getAssetRelationshipsData,
  get_similar_assets: getSimilarAssets,
  get_node_tags: getTags,
  get_basic_profiling_info: getBasicProfilingInfo,
} = API_CONFIG;

export function useGetAssetProfilingInfo(
  nodeId: string,
  resource: NodeType,
  enabled?: boolean
): CustomReactQueryHookReturnType<AssetProfilingInfoParsedType> {
  return useGetAll<AssetProfilingInfoParsedType>({
    apiConfig: getBasicProfilingInfo,
    params: [nodeId, resource],
    parser: (res): AssetProfilingInfoParsedType =>
      getParsedAssetProfilingDocument(res, resource),
    options: {
      enabled: enabled ?? true,
    },
  });
}

export function useGetAssetDocument(
  nodeId: string,
  nodeType: NodeType,
  nodeStatus: "PUB" | "DFT",
  isRefView?: boolean,
  enabled?: boolean,
  doNotUseNodeType?: boolean
): {
  isLoading: boolean;
  parsedData: AssetDocumenttParsedType;
  error: Error;
  data: { data: AssetDocumentResponseType };
  isFetching: boolean;
  refetch?: () => void;
} {
  const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);
  const overviewConfig = useGetAll<AssetDocumenttParsedType>({
    apiConfig: getNode,
    params: [
      nodeId,
      doNotUseNodeType ? "" : nodeType,
      nodeStatus,
      isRefView ? "true" : "",
      "",
      parsedNodeFilter,
    ],
    parser: getParsedAssetsDocument,
    options: {
      enabled: nodeStatus === "PUB" ? true : !!enabled,
    },
  });

  const isTbl = nodeType === "TBL";
  const isDsr = nodeType === "DSR";
  const isTrm = nodeType === "TRM";

  const getProfilingInfo = isDsr || isTbl;

  const tblProfilingConfig = useGetAssetProfilingInfo(
    nodeId,
    nodeType,
    getProfilingInfo
  );

  const listOfSubDmnConfig = useGetLoggedInUserDomainAndSubdomain(isTrm);

  const {
    parsedData: listOfSubDomains,
    isLoading: isLoadingSubDomains,
    error: listOfSubDomainsError,
  } = listOfSubDmnConfig;

  const {
    isLoading: isOverviewLoading,
    error: overviewError,
    parsedData: overviewParsedData,
  } = overviewConfig || {};

  const {
    isLoading: isTblProfilingLoading,
    error: tblProfilingError,
    parsedData: tblProfilingParsedData,
  } = tblProfilingConfig || {};

  const isAnyLoading =
    isOverviewLoading || isTblProfilingLoading || isLoadingSubDomains;

  const isAnyError =
    overviewError || tblProfilingError || listOfSubDomainsError;

  const selectedSubDomain =
    overviewParsedData?.description?.data?.find(
      (item) => item?.id === AssetFieldIds?.subDomain
    )?.selectedValues?.[0] || "";

  const subdomainInfo = listOfSubDomains?.find(
    (item) => Number(item?.categoryId) === Number(selectedSubDomain)
  );

  const tableProfilingInfo = tblProfilingParsedData?.profiling_info?.data || [];

  const customDefFields = overviewParsedData?.custom_definition?.data || [];

  // why i am filtering? because in common parser these fiels are being added with value as ""
  // their real values are being populated using useGetAssetProfilingInfo service
  // so filtering profiling fields in case of tbl here only

  const customDefWithoutProfiling = getProfilingInfo
    ? customDefFields?.filter((item) => !item?.isProfilingField)
    : customDefFields;

  const updatedAssetDocResponse = !isAnyLoading && {
    ...overviewParsedData,
    description: {
      ...overviewParsedData?.description,
      data: overviewParsedData?.description?.data?.map((item) => {
        const isSubDmnfld = item?.id === AssetFieldIds?.subDomain;
        return {
          ...item,
          ...(isSubDmnfld && { selectedCategoryInfo: subdomainInfo }),
        };
      }),
    },

    custom_definition: {
      ...overviewParsedData?.custom_definition,
      data: [
        ...customDefWithoutProfiling,
        ...(getProfilingInfo ? [...tableProfilingInfo] : []),
      ],
    },
  };

  return {
    ...overviewConfig,
    parsedData: updatedAssetDocResponse as AssetDocumenttParsedType,
    isLoading: isAnyLoading,
    error: isAnyError as Error,
  };
}

export const useGetSimilarNodes = (
  nodeId: string,
  enabled?: boolean
): CustomReactQueryHookReturnType<SimilarNodesParserReturnType[]> => {
  return useGetAll({
    apiConfig: getSimilarAssets,
    params: [nodeId],
    parser: getParsedSimilarAssetNodes,
    options: {
      enabled: enabled ?? true,
    },
  });
};

// TODO: update them on API integration
export function useGetAssetsConfiguration(
  onSuccess: (res: AxiosResponse<AssetsConfigurationsListResponseType>) => void
): CustomReactQueryHookReturnType<ReturnTypeOfAssetRelationshipsList> {
  return useGetAll({
    apiConfig: getAssetRelationshipsList,
    params: [],
    parser: getParsedAssetsConfiguration,
    options: {
      enabled: false,
      onSuccess: (data: unknown): void =>
        onSuccess(data as AxiosResponse<AssetsConfigurationsListResponseType>),
    },
  });
}

// TODO: update them on API integration
export function useGetAssetRelationshipsData(
  nodeID: string,
  enabled?: boolean,
  status: "PUB" | "DFT" = "PUB"
): CustomReactQueryHookReturnType<ReturnTypeOfAssetRelationshipsData> {
  return useGetAll({
    apiConfig: getAssetRelationshipsData,
    params: [nodeID, status],
    parser: getParsedAssetRelationshipsData,
    options: { enabled: true },
  });
}

export function useGetParentalNodeInfo(
  key: APIKeys,
  params: string[],
  searchText: string
): CustomReactQueryHookReturnType<Represents[]> {
  return useGetAll({
    apiConfig: API_CONFIG[key],
    params: [...params, searchText],
    options: { enabled: false },
  });
}

export function useGetClassificationDataForAsset(
  id: string,
  isDraftExist: boolean,
  enabled: boolean,
  nodeType: NodeType
): {
  parsedData: TagsData;
  isLoading: boolean;
  error: any;
  refetch: () => Promise<QueryObserverResult<unknown, unknown>>;
  isFetching?: boolean;
} {
  const { parsedData } = useGetAssetDocument(
    id,
    nodeType,
    isDraftExist ? "DFT" : "PUB"
  );

  const getClassificationRow = (tagsetId: string): SectionRowProps =>
    parsedData?.classification?.data?.find(
      (item) =>
        (item?.additionalInfo as GetClassificationCustomTagsAddInfo)
          ?.tagsetId === tagsetId
    ) || { title: "" };

  const {
    parsedData: parsedTags = {},
    isLoading,
    error,
    refetch,
    isFetching,
  } = useGetAll<TagsData>({
    apiConfig: getTags,
    params: [nodeType],
    options: {
      enabled,
    },
    parser: getAssetTAGContextParser,
  });

  const tagsKeys = getObjectKeys(parsedTags) || [];

  return {
    parsedData: {
      ...tagsKeys?.reduce((prev, item) => {
        const row = getClassificationRow(item);
        const { tagsetConfig = {} } = (row?.additionalInfo ||
          {}) as GetClassificationCustomTagsAddInfo;

        const {
          tagset_name: tagsetName,
          is_comment_required: isCommentRequired,
          is_multi_value_allowed: isMultiValueAllowed,
          is_new_value_allowed: isNewValueAllowed,
          tagset_type: tagsetType,
        } = tagsetConfig?.[item] || {};

        const isSelected = (val: string): boolean => {
          const combinedTags = [
            ...(row?.tags || []),
            ...(row?.prominentTags?.tags?.map((promTag) => promTag?.name) ||
              []),
          ];
          return combinedTags?.length
            ? combinedTags?.includes(val)
            : val === row?.value;
        };
        return {
          ...prev,
          [tagsetName]: {
            ...parsedTags?.[item],
            isMultiple: !!isMultiValueAllowed,
            isNewValueAllowed: !!isNewValueAllowed,
            isNoteRequired: !!isCommentRequired,
            tagsetType,
            tagsData: parsedTags?.[item]?.tagsData?.map((nestItem) => ({
              ...nestItem,
              selected: isSelected(nestItem?.label),
              isAdded: isSelected(nestItem?.label),
              comment: row?.prominentTags?.tags?.find(
                (item) => item?.id === nestItem?.value
              )?.tagDetails?.tagNote,
            })),
          },
        };
      }, {}),
    },
    isLoading,
    error,
    refetch,
    isFetching,
  };
}

export function useGetAssetChangesForReview(
  nodeID: string,
  nodeType: NodeType
): CustomReactQueryHookReturnType<AssetReviewChangesParsedType> {
  return useGetAll({
    apiConfig: API_CONFIG?.review_term_changes,
    params: [nodeID, nodeType],
    parser: (data: AxiosResponse<AssetReviewChangesResponse>) =>
      getAssetChangesForReview(data, nodeType),
  });
}

export const useGetPreSignedUrlForCodes = (
  url: string,
  enabled: boolean
): CustomReactQueryHookReturnType<any> => {
  return useGetAll<any>({
    apiConfig: API_CONFIG?.get_presigned_json_url,
    options: {
      enabled,
      refetchOnMount: false,
    },
    params: [url],
    parser: (data): void => data?.data,
  });
};

type ParsedCodeData = {
  data: string;
  plo_code?: string;
};
export const useGetParsedCode = (
  url: string
): CustomReactQueryHookReturnType<ParsedCodeData> => {
  return useGetAll<ParsedCodeData>({
    apiConfig: { url, method: "GET" },
    options: {
      enabled: !!url,
    },
    isReqWithoutHeader: true,
    parser: (data): void => {
      return data?.data;
    },
  });
};
