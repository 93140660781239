import { useCallback, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router";

import BreadCrum from "../../components/breadcrum";

import { APP_ROUTES, LOCAL_STORAGE_CONSTANTS } from "../../constants";

import { ColumnPageStyled } from "./columndetailpage.styles";

import { ColumnPageParamViewKeys } from "./columndetailpage.types";

import { columnDetailPageTabsValues } from "./columndetailpage.constants";

import { DetailPagesHeaderProps } from "../../components/detailpagesheader";
import ProfilingTab from "./views/profilingtab/profilingtab";

import { BreadcrumbItemType } from "../../components/styledbreadcrum";
import StateHandler from "../../components/statehandler";
import { getColumnDetailPageUrl } from "../../utils/getcolumndetailpageurl";

import { useGetAssetDocument } from "../../api/assetservice/assetservice";
import AssetOverview from "../../components/assetoverview";

import ColumnDataQualityTab from "./views/columnqualitytab";
import { DetailContentLayoutStyled } from "../../components/layout/layout.styles";

const ColumnDetailPage = (): JSX.Element => {
  const { tab = "overview", id = "" } = useParams<{
    tab: ColumnPageParamViewKeys;
    id: string;
  }>();

  const history = useHistory();

  const {
    parsedData,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useGetAssetDocument(id, "COL", "PUB");

  const {
    source_name: sourceName = "",
    prominent_tags: prominentTags = { tags: [] },
    node_name: colName = "",
    publish_status: publishStatus = "",
    publish_status_id: publishStatusId,
    node_title: colTitle = "",
    is_deprecated: isDeprecated = false,
    is_endorsed: isEndorsed = false,
    endorsed_count: endorsedCount = 0,
    is_dq_enabled: isDqEnabled = false,
    src_type_id: srcTypeId,
    source_id: sourceId = "",
    parent_id: parentId = "",
    page_breadcrumb: pageBreadcrumns,
  } = parsedData || {};

  const onChangeTab = useCallback(
    (newTab) => {
      const typedTab: ColumnPageParamViewKeys = newTab;

      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS.recent_visited_column_detail_page_tab,
        typedTab
      );

      history.push(
        `${APP_ROUTES.private_routes.column_detail}/${id}/${newTab}`
      );
    },
    [id, history]
  );

  const tabsProps = useMemo(() => {
    return {
      tabs: [
        {
          key: columnDetailPageTabsValues.overview,
          value: "Overview",
          id: columnDetailPageTabsValues.overview,
        },
        {
          key: columnDetailPageTabsValues.profiling,
          value: "Profiling",
          id: columnDetailPageTabsValues.profiling,
        },
        ...((isDqEnabled && [
          {
            key: columnDetailPageTabsValues.data_quality,
            value: "Data Quality",
            id: columnDetailPageTabsValues.data_quality,
          },
        ]) ||
          []),
      ],
      onChange: onChangeTab,
      selectedTab: tab,
    };
  }, [onChangeTab, tab, isDqEnabled]);

  const headerData: DetailPagesHeaderProps = useMemo(
    () => ({
      desc: colName,
      endorsedCount,
      title: colTitle,
      source: pageBreadcrumns?.data,
      isEndorsed,
      isDeprecated,
      nodeId: id,
      nodeType: "COL",
      publishStatusId,
      publishStatus,
      prominentTags: prominentTags?.tags,
    }),
    [parsedData]
  );

  useEffect(() => {
    if (
      tab === columnDetailPageTabsValues.data_quality &&
      !isLoading &&
      !isDqEnabled
    ) {
      history.push(getColumnDetailPageUrl(id, "overview"));
    }
  }, [tab, isDqEnabled, isLoading]);

  const pageContent = useMemo(() => {
    switch (tab) {
      case columnDetailPageTabsValues.overview:
        return (
          <AssetOverview
            tabs={tabsProps}
            nodeId={id}
            isReportPage={false}
            nodeType="COL"
          />
        );

      case columnDetailPageTabsValues.profiling:
        return (
          <ProfilingTab
            tabs={tabsProps}
            nodeId={id}
            headerData={headerData}
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            refetch={refetch}
            data={parsedData}
          />
        );

      case columnDetailPageTabsValues.data_quality:
        return (
          <ColumnDataQualityTab
            nodeId={id}
            headerData={headerData}
            tabs={tabsProps}
            isLoading={isLoading}
            error={error}
            parentId={parentId}
            sourceId={sourceId}
            sourceTypeId={srcTypeId}
          />
        );

      default:
        return <div />;
    }
  }, [
    tabsProps,
    id,
    parentId,
    parsedData,
    isLoading,
    isFetching,
    error,
    headerData,
  ]);

  return (
    <ColumnPageStyled>
      <BreadCrum
        currentPages={[
          {
            label: "Field Dictionary",
            link: APP_ROUTES.private_routes.field_dictionary,
          },
          { label: colTitle },
        ]}
      />

      <DetailContentLayoutStyled>{pageContent}</DetailContentLayoutStyled>
    </ColumnPageStyled>
  );
};

export default ColumnDetailPage;
