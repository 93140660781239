import { APP_ROUTES, LOCAL_STORAGE_CONSTANTS } from "../constants";

import { etlPageTabsValues } from "../pages/etldetailpage/etldetailpage.constants";

import { EtlPageViewKeys } from "../pages/etldetailpage/etldetailpage.types";

export const getEtlDetailPageUrl = (
  id: string,
  view?: EtlPageViewKeys
): string => {
  const recentlyVisitedView =
    localStorage.getItem(
      LOCAL_STORAGE_CONSTANTS.recent_visited_etl_detail_page_view
    ) || etlPageTabsValues?.overview;

  const redirectTo = view || recentlyVisitedView;

  return `${APP_ROUTES.private_routes.etl_detail}/${id}/${redirectTo}`;
};
