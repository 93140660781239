import { LineagePreviewerProps } from "../../components/lineagepreviewer/lineagepreviewer.types";
import {
  AssetDocumentResponseType,
  LineageNodeType,
} from "../asssetoverviewparser";
import { lineageDocResponse } from "../parser.types";

const mapValues = (
  data: LineageNodeType[],
  isCurrent?: boolean
): LineagePreviewerProps["data"]["level2"] => {
  return (
    data?.map((item) => ({
      id: `${item?.node_id}`,
      nodeName: item?.node_name || "",
      nodeType: item?.node_type,
      nodeSubType: item?.node_sub_type,
      isCurrentNode: !!isCurrent,
      isPlaceholderTbl: item?.is_placeholder || false,
    })) || []
  );
};

function getParsedLineageData(
  lineageValues: lineageDocResponse["current"]["value"] = [],
  isCurrent?: boolean
): LineagePreviewerProps["data"]["level2"] {
  return mapValues(lineageValues, isCurrent);
}

export function getLineagePreviewData(
  lineageData: lineageDocResponse
): LineagePreviewerProps {
  const level1 = getParsedLineageData(lineageData?.parent?.value);
  const level2 = getParsedLineageData(lineageData?.parent_or_btwn?.value);
  const level3 = getParsedLineageData(lineageData?.current?.value, true);
  const level4 = getParsedLineageData(lineageData?.end_or_btwn?.value);
  const level5 = getParsedLineageData(lineageData?.end?.value);

  return {
    data: {
      level1,
      level2,
      level3,
      level4,
      level5,
    },
    lineageExists: !!(
      level1.length ||
      level2.length ||
      level4.length ||
      level5.length
    ),
  };
}

export function getLineagePreviewDataForEtl(
  data: AssetDocumentResponseType
): LineagePreviewerProps {
  const ploSrcs = data?.plo_sources || [];
  const ploTgts = data?.plo_targets || [];

  const currentEtlNode = {
    id: `${data?.node_id}`,
    nodeName: data?.node_name || "",
    nodeType: data?.node_type,
    nodeSubType: data?.node_sub_type,
    isCurrentNode: true,
  };

  const sources = mapValues(ploSrcs);
  const targets = mapValues(ploTgts);

  const level2 = sources;
  const level3 = [currentEtlNode];
  const level4 = targets;

  return {
    data: {
      level1: [],
      level2,
      level3,
      level4,
      level5: [],
    },
    lineageExists: !!(level2?.length || level4?.length),
  };
}
