export const assetOverviewRefPageHeaderBlankSlate = (
  <svg
    width="500"
    height="107"
    viewBox="0 0 500 107"
    fill="none"
    style={{ margin: "20px" }}
  >
    <rect y="4" width="198" height="14" rx="7" fill="#F0F0F0" />
    <rect x="42" y="45" width="190" height="19" rx="9.5" fill="#F0F0F0" />
    <rect y="42" width="28" height="28" rx="14" fill="#F0F0F0" />
    <rect y="86" width="145" height="17" rx="8.5" fill="#F0F0F0" />
  </svg>
);

export const assetOverviewRefPageBlankSlate = (
  <svg
    width="500"
    height="853"
    viewBox="0 0 552 853"
    fill="none"
    style={{ margin: "0 20px" }}
  >
    <rect y="4" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line y1="43.5" x2="552" y2="43.5" stroke="#E6E6E6" />
    <rect y="72" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="72" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect x="200" y="96" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect x="200" y="120" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect y="156" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="156" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect x="200" y="180" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect x="200" y="204" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect y="310" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line y1="349.5" x2="552" y2="349.5" stroke="#E6E6E6" />
    <rect y="378" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="378" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="417" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="417" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="456" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="456" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="495" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="495" width="224" height="12" rx="6" fill="#F0F0F0" />
    <rect x="200" y="519" width="224" height="12" rx="6" fill="#F0F0F0" />
    <rect y="625" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line y1="664.5" x2="552" y2="664.5" stroke="#E6E6E6" />
    <rect y="693" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="693" width="260" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="732" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="732" width="260" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="771" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="771" width="260" height="15" rx="7.5" fill="#F0F0F0" />
  </svg>
);

export const assetOverviewDetailPageHeaderBlankSlate = (
  <svg width="100%" height="107" viewBox="0 0 1800 107" fill="none">
    <rect y="4" width="198" height="14" rx="7" fill="#F0F0F0" />
    <rect x="42" y="45" width="190" height="19" rx="9.5" fill="#F0F0F0" />
    <rect y="42" width="28" height="28" rx="14" fill="#F0F0F0" />
    <rect y="86" width="145" height="17" rx="8.5" fill="#F0F0F0" />
  </svg>
);

export const assetOverviewDetailPageBlankSlate = (
  <svg width="100%" height="100%" viewBox="0 0 1774 1943" fill="none">
    <rect y="4" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line y1="43.5" x2="1060" y2="43.5" stroke="#E6E6E6" />
    <rect y="72" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="72" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect x="200" y="96" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect x="200" y="120" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect y="156" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="156" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect x="200" y="180" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect x="200" y="204" width="349" height="12" rx="6" fill="#F0F0F0" />
    <rect x="1200" y="4" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line x1="1200" y1="43.5" x2="1800" y2="43.5" stroke="#E6E6E6" />
    <rect x="1200" y="72" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1400" y="72" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1200" y="111" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1400" y="111" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1200" y="150" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1400" y="150" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1200" y="189" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1400" y="189" width="224" height="12" rx="6" fill="#F0F0F0" />
    <rect x="1400" y="213" width="224" height="12" rx="6" fill="#F0F0F0" />
    <rect y="319" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line y1="358.5" x2="1060" y2="358.5" stroke="#E6E6E6" />
    <rect y="387" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="387" width="260" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="426" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="426" width="260" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="465" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="200" y="465" width="260" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1200" y="319" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line x1="1200" y1="358.5" x2="1800" y2="358.5" stroke="#E6E6E6" />
    <rect x="1200" y="387" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1400" y="387" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1200" y="426" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1400" y="426" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1200" y="465" width="115" height="15" rx="7.5" fill="#F0F0F0" />
    <rect x="1400" y="465" width="145" height="15" rx="7.5" fill="#F0F0F0" />
    <rect y="574" width="173" height="22" rx="11" fill="#F0F0F0" />
    <line y1="613.5" x2="1800" y2="613.5" stroke="#E6E6E6" />
    <rect y="642" width="378" height="16" rx="8" fill="#F0F0F0" />
    <rect y="682" width="378" height="16" rx="8" fill="#F0F0F0" />
  </svg>
);
