import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";

import AgGridTable from "../../../../../components/aggridtable/aggridtable";

import StateHandler from "../../../../../components/statehandler/statehandler";
import { RecommendedRulesGridStyled } from "./recommendedrulesgrid.styles";

import {
  RecommendedRulesGridColumns,
  RecommendedRulesGridColumnsType,
  RecommendedRulesGridProps,
  ValueRangeModalPropsType,
  onAddRecommendedRuleCallbackType,
} from "./recommendedrulesgrid.types";

import { RuleActionsRenderer } from "./renderer";
import { getUserPermissions, openNotification } from "../../../../../utils";

import SuccessNotificationMessage from "../../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import { useRequestWithMethod } from "../../../../../api";

import {
  useGetAppState,
  useOpenModal,
  useSetData,
} from "../../../../../customhooks";
import { API_CONFIG } from "../../../../../constants/apiconfig";

import { AgGridLocalPaginationWithSelect } from "../../../../../components/aggridpaginationwithselect";

import LinkButton from "../../../../../components/linkbutton/linkbutton";
import { plusIconInFilledCircle } from "../../../../../svgs";

import { recommendedRulesGridBlankSlate } from "../../../../../blankslates/recommendedrulesgridblankslate";
import { RecommendedRulesParsedType } from "../../../../../parsers/ruleparser/ruleparser.types";

import { useGetRecommendedRules } from "../../../../../api/ruleservice";
import { HorizontalDividerStyled } from "../../../../../components/dividers/dividers.styles";

import ConditionalDisplay from "../../../../../components/conditionaldisplay";

const addRuleBtn = plusIconInFilledCircle("12", "12");

const RecommendedRulesGrid = (
  props: RecommendedRulesGridProps
): JSX.Element => {
  const {
    sourceId,
    tableId,
    columnId = "",
    onChangeRuleView,
    onGridReadyWrapper,
    gridApi,
    dqTabListingRightSec,
    refetchStatsAndCharts,
  } = props;

  const {
    expandableGridProps: { isGridExpanded = false } = { isGridExpanded: false },
  } = useGetAppState();

  const {
    is_catalog_admin: isCatalogAdmin = false,
    is_catalog_editor: isCatalogEditor = false,
  } = getUserPermissions();

  const hasAccessToCatalog = isCatalogAdmin || isCatalogEditor;

  const onSetData = useSetData();
  const openModal = useOpenModal();

  const gridRef = useRef<GridReadyEvent>(null);

  const [selectedNodes, setSelectedNodes] = useState<
    RecommendedRulesParsedType[]
  >([]);

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      const selectedNodes = event?.api?.getSelectedNodes();

      const nodes = selectedNodes?.map((node) => node?.data);

      setSelectedNodes(nodes);
    },
    [selectedNodes]
  );

  const {
    parsedData: parsedRecommendedRulesData,
    isFetching: isLoadingRecommendedRulesData,
    error: errorRecommendedRules,
  } = useGetRecommendedRules(tableId, columnId);

  const numberOfSelectedRows = useMemo(() => selectedNodes?.length || 1, [
    selectedNodes,
  ]);

  const onAddRecommendedRuleSuccess = useCallback(
    (response) => {
      refetchStatsAndCharts();

      onSetData(API_CONFIG?.get_recommended_rules, response?.data, [tableId]);

      openNotification(
        <SuccessNotificationMessage
          message={`${numberOfSelectedRows} rule(s) has been added. You will find it under`}
          showSuccess
          showSuccessText={false}
          linkText="Available Rules"
          onLinkTextClick={onChangeRuleView}
        />
      );

      setSelectedNodes([]);
    },
    [onChangeRuleView, tableId, numberOfSelectedRows]
  );

  const {
    isLoading: isAddRecommendedRuleLoading,
    onExecuteRequest: addRecommendedRule,
    error: errorInAddingRecommendedRule,
  } = useRequestWithMethod(
    "add_rules",
    undefined,
    true,
    onAddRecommendedRuleSuccess
  );

  const openValueRangeForm = useCallback(
    (ruleConfig) => {
      openModal({
        modalId: "add_rule",
        modalTitle: "Add Rule",
        visible: true,
        modalProps: {
          ruleCategoryId: "FND",
          formId: "VLR",
          tableId,
          sourceId,
          ruleConfig,
          columnId,
          isFromRecommendedRules: true,
          onAddRecommendedRuleSuccess,
        } as ValueRangeModalPropsType,
      });
    },
    [tableId, sourceId]
  );

  const onAddRecommendedRuleClick: onAddRecommendedRuleCallbackType = useCallback(
    (_, ruleId): void => {
      const selectedNodeIds = selectedNodes?.map((item) => item?.rule_id);

      const recommendedRulesId = ruleId ? [ruleId] : selectedNodeIds;

      const selectedRecommendedRulesCount = recommendedRulesId?.length;

      if (selectedRecommendedRulesCount > 1) {
        addRecommendedRule({
          rcm_rule_ids: recommendedRulesId,
          tbl_id: tableId,
        });
      } else {
        const selectedRcmRuleDetails = parsedRecommendedRulesData?.find(
          (item) => item?.rule_id === recommendedRulesId?.[0]
        );

        const ruleConfig = selectedRcmRuleDetails?.value_range_rule_config;

        const parsedRuleConfig = {
          ...ruleConfig,
          rule_desc: selectedRcmRuleDetails?.rule_desc,
          ruleId: selectedRcmRuleDetails?.rule_id,
          min_oper:
            selectedRcmRuleDetails?.value_range_rule_config?.min_oper || "",
          max_oper:
            selectedRcmRuleDetails?.value_range_rule_config?.min_oper || "",
        };

        if (selectedRcmRuleDetails?.rule_type_id === "VLR")
          openValueRangeForm(parsedRuleConfig);
        else
          addRecommendedRule({
            rcm_rule_ids: recommendedRulesId,
            tbl_id: tableId,
          });
      }
    },
    [selectedNodes, tableId, parsedRecommendedRulesData]
  );

  const isAllRecordsSelected = useMemo(
    () =>
      !!(
        parsedRecommendedRulesData?.length &&
        parsedRecommendedRulesData?.length === selectedNodes?.length
      ),
    [parsedRecommendedRulesData, selectedNodes]
  );

  const onClear = useCallback(() => {
    setSelectedNodes([]);
    gridRef?.current?.api?.deselectAll();
  }, []);

  useEffect(() => {
    gridRef?.current?.api?.refreshCells({
      force: true,
      columns: ["actions"],
    });
  }, [selectedNodes?.length]);

  const rcmRulesGridCols = useMemo(() => {
    const filteredCols = RecommendedRulesGridColumns?.filter((col) => {
      const isActionCol = col?.field === "actions";
      if (isActionCol) {
        return hasAccessToCatalog;
      }
      return true;
    });

    return filteredCols?.map((item) => {
      return {
        ...item,
        checkboxSelection: hasAccessToCatalog ? item?.checkboxSelection : false,
        headerCheckboxSelection: hasAccessToCatalog
          ? item?.headerCheckboxSelection
          : false,
      };
    });
  }, [RecommendedRulesGridColumns, hasAccessToCatalog]);

  return (
    <RecommendedRulesGridStyled>
      <StateHandler
        isFetching={
          isLoadingRecommendedRulesData || isAddRecommendedRuleLoading
        }
        error={errorRecommendedRules || errorInAddingRecommendedRule}
        blankSlate={recommendedRulesGridBlankSlate}
      >
        <>
          <div className="grid-header">
            <div className="add-rule-btn-wrapper">
              {selectedNodes?.length ? (
                <LinkButton onClick={onAddRecommendedRuleClick}>
                  <span className="add-rule-btn">
                    {addRuleBtn}
                    Add Rule(s)
                  </span>
                </LinkButton>
              ) : (
                <div className="page-name">Rules</div>
              )}
            </div>

            <div className="grid-search">
              {!selectedNodes?.length && dqTabListingRightSec}
            </div>
          </div>

          <ConditionalDisplay condition={!isGridExpanded}>
            <HorizontalDividerStyled
              marginBottom="20px"
              className="recommended-rules-divider"
            />
          </ConditionalDisplay>

          <div className="data-grid">
            <AgGridTable<RecommendedRulesGridColumnsType>
              theme="ag-theme-alpine table-container"
              isTableWidthfitTowindowSize={false}
              rowData={parsedRecommendedRulesData}
              defaultColDef={{
                resizable: false,
                lockPosition: true,
              }}
              frameworkComponents={{
                RuleActionsRenderer,
              }}
              rowHeight={43}
              headerHeight={43}
              maxHeight={isGridExpanded ? "62vh" : "75vh"}
              domLayout="autoHeight"
              pageSizes={["5", "10", "20", "50"]}
              tableColumns={rcmRulesGridCols}
              rowSelection={hasAccessToCatalog ? "multiple" : ""}
              getRowNodeId={(params: any): string => {
                return params?.id;
              }}
              onGridReady={onGridReadyWrapper}
              suppressCellSelection
              suppressPaginationPanel
              onSelectionChanged={handleSelectionChanged}
              cellRenderers={{
                actions: {
                  render: "RuleActionsRenderer",
                  params: {
                    onAddRecommendedRuleClick,
                    selectedNodesCount: selectedNodes?.length,
                  },
                },
              }}
              gridRef={gridRef}
              showExpandCollapse
              selectionState={{
                isSelectAll: isAllRecordsSelected,
                selectedRecords: selectedNodes?.length,
              }}
              onClear={onClear}
            />
          </div>
        </>
      </StateHandler>
    </RecommendedRulesGridStyled>
  );
};

export default RecommendedRulesGrid;
