import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const AdaptiveNodeHierarchyStyled = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  position: relative;
`;

export const AdaptiveNodeHierarchyItemStyled = styled.div<{
  flexGrow: number;
  isPrimary?: boolean;
  showCursorPointer?: boolean;
}>`
  max-width: ${(props): string => `${props.flexGrow}%`};
  /* flex-basis: 0; */
  min-width: 0;
  display: flex;
  align-items: center;

  .right-icon {
    margin-right: 8px;
    height: 15px;
    display: flex;
    align-items: center;
    svg {
      path {
        fill: #b8bbc3;
      }
    }
  }

  .adaptive-node-name {
    min-width: 3ch;
    margin: 0 8px;
    color: ${({ theme, isPrimary }): string =>
      isPrimary ? theme?.textColors?.BLUE_116 : theme?.textColors?.BLACK_13};
    cursor: ${({ showCursorPointer }): string =>
      showCursorPointer ? "pointer" : "inherit"};
    display: inline-block;
    ${ellipsis}
  }

  .adaptive-node-status {
    svg {
      width: 13px;
      height: 13px;
    }
  }

  .flex-1 {
    flex: 1;
    min-width: 10px;
  }

  .delete-icon {
    position: absolute;
    right: -15px;
    svg {
      path {
        fill: ${({ theme }): string => theme.bgColors.RED_100};
      }
    }
  }
`;
