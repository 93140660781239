import { useCallback } from "react";
import SectionRow from "../../sectionrow";

import SectionTitleWithIcon from "../../sectiontitlewithicon";

import {
  SectionWithEditableStyled,
  SectionWithEditableWrapperStyled,
} from "./sectionwitheditable.styles";

import { SectionWithEditableProps } from "./sectionwitheditable.types";
import { getSortedFieldsBasedOnAccessPage } from "../../assetoverview/assetoverview.util";

import { AssetFieldIds } from "../../../parsers/asssetoverviewparser";

function SectionWithEditable(props: SectionWithEditableProps): JSX.Element {
  const {
    state,
    setState,
    id,
    secTitle,
    rendrer,
    customRenderer,
    isWorkflowEnabled = false,
    className = "",
    isRefView = false,
  } = props;

  const onEdit = useCallback(() => {
    setState((st) => ({
      ...st,
      [id]: { ...st[id], isEditable: !st[id]?.isEditable },
    }));
  }, []);

  const onCancel = useCallback(() => {
    onEdit();
  }, []);

  const rowsToShow = state[id]?.data?.filter((item) => {
    const { isRowVisibleInEditView = true, isRowVisibleInNormalView = true } =
      item || {};

    return state[id]?.isEditable
      ? isRowVisibleInEditView
      : isRowVisibleInNormalView;
  });

  const pageRows = getSortedFieldsBasedOnAccessPage(rowsToShow, isRefView);

  return (
    <SectionWithEditableWrapperStyled>
      <SectionTitleWithIcon
        {...secTitle}
        marginBottom="25px"
        onEdit={onEdit}
        isEditable={state[id]?.isEditable}
        onCancel={onCancel}
      />
      <SectionWithEditableStyled
        isEditable={state[id]?.isEditable}
        className={className}
      >
        {pageRows?.map((row) => {
          const rowId = row?.id || "";

          const isVisualDistField = rowId === AssetFieldIds.visualDistribution;

          const updatedRow = {
            ...row,
            isFromDetailPage: !isRefView,
          };

          return (
            <SectionRow
              {...row}
              isWorkflowEnabled={isWorkflowEnabled}
              customJSX={rendrer?.(updatedRow)?.[rowId]?.()}
              customRowComp={
                isVisualDistField
                  ? customRenderer?.(updatedRow)?.[rowId]?.()
                  : undefined
              }
              key={`mode-sec-row-${row?.title}-${id}`}
            />
          );
        })}
      </SectionWithEditableStyled>
    </SectionWithEditableWrapperStyled>
  );
}

export default SectionWithEditable;
