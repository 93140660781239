import { AssetRefDrawerPropsType } from "./assetrefdrawer.types";
import { useGetAppState } from "../../customhooks";
import { AssetOverview } from "../../components";
import { LinkTabsProps } from "../../components/tabs/linktabs/linktabs.types";

const AssetRefDrawer = (props: AssetRefDrawerPropsType): JSX.Element => {
  const { drawer } = useGetAppState();

  // nodeType get from props
  const { nodeType, nodeSubType } = props || {};

  const { drawerProps } = drawer || {};
  const { id: nodeId = "", nodeType: propNodeType } = drawerProps || {};

  const tabsProps = {
    tabs: [] as LinkTabsProps["tabs"],
    selectedTab: "",
    onChange: (): void => {},
  };

  const isReportPage = nodeSubType === "RPT";

  return (
    <AssetOverview
      nodeId={nodeId}
      nodeType={propNodeType || nodeType}
      isRefView
      tabs={tabsProps}
      isReportPage={isReportPage}
    />
  );
};

export default AssetRefDrawer;
