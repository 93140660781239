import { useHistory, useLocation, useParams } from "react-router";
import { createContext, useCallback, useContext, useReducer } from "react";

import {
  DrawerTabsType,
  RuleDetailPageActionsType,
  RuleDetailPageContextType,
  RuleDetailPageTabType,
  RuleDetailPageType,
  onApplySlicerType,
  onCancelJobSchedulerNotificationClickType,
  onChangeEditDrawerVisibilityType,
  onChangeHistoryActiveViewType,
  onChangeScrollToBottomType,
  onChangeViewType,
  onShowAllTabsType,
  updateStateType,
} from "./ruledetailpagecontext.types";

import { getRuleDetailPageUrl } from "../../utils";
import {
  RuleDetailPageParams,
  RulePageViewKeys,
} from "../../pages/ruledetailpage";
import { RULE_DOCUMENT_INITIAL_STATE } from "./ruledetailpagecontext.config";
import { useQueryParams } from "../../customhooks";
import { LocationStateType } from "../../pages/ruledetailpage/ruledetailpage.views/ruledetailpagecontent/ruledetailpagecontent.tabs/overviewtab/overviewtab.types";
import { LOCAL_STORAGE_CONSTANTS } from "../../constants";

const initialState = RULE_DOCUMENT_INITIAL_STATE;

// Define your reducer function
const reducer = (
  state: RuleDetailPageType,
  action: RuleDetailPageActionsType
): RuleDetailPageType => {
  switch (action?.type) {
    case "CHANGE_EDIT_DRAWER_VISIBILITY": {
      const activeTab = action?.tab as DrawerTabsType;
      return {
        ...state,
        pageState: {
          ...state?.pageState,
          isEditDrawerVisible: !state?.pageState?.isEditDrawerVisible,
          ...(activeTab && { drawerActiveTab: activeTab }),
        },
      };
    }
    case "CHANGE_VIEW": {
      const newActiveTab = action?.tab as RuleDetailPageTabType;
      const newActivePageView = action?.pageView as RulePageViewKeys;

      return {
        ...state,
        data: {
          ...state?.data,
          jobSchedulers: state?.data?.jobSchedulers,
        },
        pageState: {
          ...state?.pageState,
          activeTab: newActiveTab,
          activePageView: newActivePageView,
          ...(action?.view && {
            activeTabView: {
              ...state?.pageState?.activeTabView,
              [newActiveTab]: action?.view,
            },
          }),
        },
      };
    }
    case "SHOW_ALL_EXCEPTIONS_TOGGLED":
      return {
        ...state,
        pageState: {
          ...state?.pageState,
          showAllExceptionsInOnlineRun: !state?.pageState
            ?.showAllExceptionsInOnlineRun,
        },
      };
    case "APPLY_SLICER":
      return {
        ...state,
        pageState: {
          ...state?.pageState,
          slicerAppliedOn: action?.slicerColumnId,
        },
      };
    case "CHANGE_HISTORY_ACTIVE_VIEW": {
      const activeFilter = action?.filterView;
      const activeDataView = action?.dataView;

      return {
        ...state,
        pageState: {
          ...state?.pageState,
          historyTabState: {
            ...state?.pageState?.historyTabState,

            ...(activeFilter && { activeFilter }),
            ...(activeDataView && { activeView: activeDataView }),
          },
        },
      };
    }
    case "UPDATE_STATE":
      return {
        ...state,
        ...action?.updatedState,
      };

    case "SHOW_ALL_TABS":
      return {
        ...state,
        data: {
          ...state?.data,
          jobSchedulers: state?.data?.jobSchedulers,
        },
        pageState: {
          ...state?.pageState,
          showAllTabs: !state?.pageState?.showAllTabs,
        },
      };

    case "CHANGE_SCROLL_TO_BOTTOM":
      return {
        ...state,
        data: {
          ...state?.data,
        },
        pageState: {
          ...state?.pageState,
          scrollToBottom: !state?.pageState?.scrollToBottom,
        },
      };

    case "HIDE_SCHEDULER_JOB_NOTIFICATION":
      return {
        ...state,
        pageState: {
          ...state?.pageState,
          hasUserCancelledScheduleJobNotification: true,
        },
      };
    default:
      return state;
  }
};

// Define your context
const RuleDetailPageContext = createContext({
  state: initialState,
  dispatch: (_: RuleDetailPageActionsType) => {},
});

// Define your provider component
const RuleDetailPageProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const params = useQueryParams();

  const { id = "", tab } = useParams<RuleDetailPageParams>();

  // Use useReducer instead of useState
  const [state, dispatch] = useReducer(reducer, initialState);

  // Define your callback functions using dispatch
  const onChangeEditDrawerVisibility: onChangeEditDrawerVisibilityType = useCallback(
    (tab = "overview") => (): void => {
      dispatch({ type: "CHANGE_EDIT_DRAWER_VISIBILITY", tab });
    },
    [dispatch]
  );

  const onCancelJobSchedulerNotificationClick: onCancelJobSchedulerNotificationClickType = useCallback(() => {
    dispatch({ type: "HIDE_SCHEDULER_JOB_NOTIFICATION" });
  }, [dispatch]);

  const onChangeView: onChangeViewType = useCallback(
    (tab, pageView, view) => {
      dispatch({ type: "CHANGE_VIEW", tab, pageView, view });

      const newUrl = getRuleDetailPageUrl(`${id}`, tab, pageView);

      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS.recent_visited_rule_detail_page_tab,
        tab
      );

      if (params?.has("isDownloadReport"))
        history.push(`${newUrl}?isDownloadReport=true`);
      else
        history.push({
          pathname: `${newUrl}`,
          state: {
            from: (history?.location?.state as LocationStateType)?.from,
          },
        });
    },
    [dispatch, history, id]
  );

  const onShowAllExceptionsInOnlineRunToggled = useCallback(() => {
    dispatch({ type: "SHOW_ALL_EXCEPTIONS_TOGGLED" });
  }, [dispatch]);

  const onChangeScrollToBottom: onChangeScrollToBottomType = useCallback(() => {
    dispatch({ type: "CHANGE_SCROLL_TO_BOTTOM" });
  }, [dispatch]);

  const onApplySlicer: onApplySlicerType = useCallback(
    (slicerColumnId) => {
      dispatch({ type: "APPLY_SLICER", slicerColumnId });
    },
    [dispatch]
  );

  const onChangeHistoryActiveView: onChangeHistoryActiveViewType = useCallback(
    (dataView, filterView) => {
      dispatch({ type: "CHANGE_HISTORY_ACTIVE_VIEW", dataView, filterView });
    },
    [dispatch]
  );

  const updateState: updateStateType = useCallback(
    (updatedState) => {
      dispatch({ type: "UPDATE_STATE", updatedState });
    },
    [dispatch]
  );

  const onShowAllTabs: onShowAllTabsType = useCallback(() => {
    dispatch({ type: "SHOW_ALL_TABS" });
  }, [dispatch]);

  // Provide state and dispatch value to the context
  const contextValue: RuleDetailPageContextType = {
    state,
    dispatch,
    onChangeHistoryActiveView,
    onChangeView,
    onShowAllExceptionsInOnlineRunToggled,
    onApplySlicer,
    onChangeEditDrawerVisibility,
    onShowAllTabs,
    updateState,
    onCancelJobSchedulerNotificationClick,
    onChangeScrollToBottom,
  };

  return (
    <RuleDetailPageContext.Provider value={contextValue}>
      {children}
    </RuleDetailPageContext.Provider>
  );
};

// Export your context and provider

const useRuleDetailPageContext = (): RuleDetailPageContextType => {
  const context = useContext(RuleDetailPageContext);

  if (!context) {
    console.log(
      "RuleDetailPageContext must be used within a RuleDetailPageContextProvider"
    );
  }

  return context as RuleDetailPageContextType;
};

export {
  RuleDetailPageProvider,
  RuleDetailPageContext,
  useRuleDetailPageContext,
};
