import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { GridApi } from "ag-grid-community";

import SectionTitleWithIcon from "../../sectiontitlewithicon";
import {
  AssetGridDataType,
  AssetRelationshipGrids,
  AssetRelationshipsBlankState,
  OnCommentSave,
  OnDeletion,
} from "./sectionwitheditablerelationships.components";

import {
  RightSection,
  SectionWithEditableAdditionalinfoStyled,
} from "./sectionwitheditablerelationships.styles";

import { SectionWithEditableRelationshipsProps } from "./sectionwitheditablerelationships.types";
import { useAssetRelationshipContext } from "../../../contexts";

import { Input } from "../../inputs";
import { searchIconLight } from "../../../svgs";

import AssetRelationshipForm from "../../../forms/assetrelationshipform/assetrelationshipform";
import { VerticalDividerStyled } from "../../dividers/dividers.styles";

import MultiSelectionCheckboxesPopup from "../../multiselectioncheckboxespopup";
import { MultiSelectionCheckboxesPopupProps } from "../../multiselectioncheckboxespopup/multiselectioncheckboxespopup.types";
import StateHandler from "../../statehandler";
import { relationshipBlankSlate } from "../../../blankslates/assetblankslate";
import ConditionalDisplay from "../../conditionaldisplay";
import { useGetAppState } from "../../../customhooks";
import Flex from "../../flex";
import { ELEMENT_IDS } from "../../../constants";

function SectionWithEditableRelationships(
  props: SectionWithEditableRelationshipsProps
): JSX.Element {
  const {
    secTitle,
    isLoading,
    errorPublishData,
    errorDraftData,
    assetRelationshipsDataParsedData,
  } = props;

  const assetDataRef = useRef(assetRelationshipsDataParsedData);

  // Update the ref whenever assetRelationshipsDataParsedData changes
  useEffect(() => {
    assetDataRef.current = assetRelationshipsDataParsedData;
  }, [assetRelationshipsDataParsedData]);

  const { asset_relationship_sec: ASSET_RELATIONSHIP_SEC } = ELEMENT_IDS;

  const {
    expandableGridProps: { isGridExpanded = false, expandedGridId = "" } = {
      isGridExpanded: false,
    },
  } = useGetAppState();

  const {
    state: contextState,
    updateState: updateContextState,
    updateFilterState: updateSelectedAssetTypes,
    updateSearchState: updateSearchText,
    relationshipsRef,
  } = useAssetRelationshipContext();

  const isEditMode = contextState?.isEditable;

  const searchText = contextState?.assetSearchText;
  const selectedAssetTypes = contextState?.selectedAssetFilter;

  const [copyState, setCopyState] = useState<AssetGridDataType[]>(
    contextState?.data || []
  );

  const [isFormVisible, setIsFormVisible] = useState(false);

  const [gridApis, setGridApis] = useState<{ [key: string]: GridApi | null }>(
    {}
  );

  const [isValidState, setIsValidState] = useState<boolean>(false);

  useEffect(() => {
    setCopyState(() => [...contextState?.data]);
  }, [contextState]);

  const assetTypeCounts = useMemo(() => {
    return copyState?.reduce<Record<string, number>>((acc, section) => {
      const filteredRows = section?.rows?.filter(
        (row) =>
          !row?.isDeleted &&
          (row?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
            row?.description
              ?.toLowerCase()
              ?.includes(searchText?.toLowerCase()) ||
            row?.comment?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
            row?.assetType
              ?.toLowerCase()
              ?.includes(searchText?.toLowerCase()) ||
            row?.source?.toLowerCase()?.includes(searchText?.toLowerCase()))
      );

      acc[section?.assetType] = filteredRows?.length || 0;
      return acc;
    }, {});
  }, [copyState, searchText]);

  const filteredAssets = useMemo(() => {
    return copyState
      ?.filter((asset) => !isEditMode || asset?.isEditable)
      ?.map((section) => ({
        ...section,
        rows: section?.rows?.filter(
          (row) =>
            !row?.isDeleted &&
            (row?.name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
              row?.description
                ?.toLowerCase()
                ?.includes(searchText.toLowerCase()) ||
              row?.comment?.toLowerCase()?.includes(searchText.toLowerCase()) ||
              row?.assetType
                ?.toLowerCase()
                ?.includes(searchText.toLowerCase()) ||
              row?.source?.toLowerCase()?.includes(searchText.toLowerCase()))
        ),
      }))
      .filter(
        (section) =>
          (selectedAssetTypes.includes("All") ||
            selectedAssetTypes.includes(section?.assetType)) &&
          section?.rows?.length > 0
      );
  }, [copyState, selectedAssetTypes, searchText]);

  const assetTypesFilterOptions: MultiSelectionCheckboxesPopupProps["options"] = useMemo(() => {
    const allCount = filteredAssets?.reduce(
      (sum, section) => sum + (section?.rows?.length || 0),
      0
    );

    const uniqueAssetTypes = new Set<string>();
    const filteredSections =
      copyState?.filter((section) => {
        const assetType = section?.assetType;
        if (
          !uniqueAssetTypes?.has(assetType) &&
          (assetTypeCounts[assetType] || 0) > 0
        ) {
          uniqueAssetTypes?.add(assetType);
          return true;
        }
        return false;
      }) || [];

    return [
      {
        label: "All",
        value: "All",
        checked: selectedAssetTypes?.includes("All"),
        count: allCount,
        isAllOption: true,
      },
      ...filteredSections?.map((section) => ({
        label: section?.assetType,
        value: section?.assetType,
        checked:
          selectedAssetTypes?.includes("All") ||
          selectedAssetTypes?.includes(section?.assetType),
        count: assetTypeCounts[section.assetType] || 0,
      })),
    ];
  }, [filteredAssets, selectedAssetTypes, assetTypeCounts, copyState]);

  const onChangeAssetTypesFilter = useCallback(
    (selectedTypes: string[]) => {
      updateSelectedAssetTypes(selectedTypes);
    },
    [updateSelectedAssetTypes]
  );

  const onEdit = useCallback(() => {
    const newEditMode = !contextState?.isEditable;

    updateContextState({
      ...contextState,
      isEditable: newEditMode,
    });

    if (newEditMode) {
      updateSelectedAssetTypes(["All"]);
      updateSearchText("");
    }
  }, [
    contextState,
    updateContextState,
    updateSearchText,
    updateSelectedAssetTypes,
  ]);

  const onSave = useCallback(() => {
    updateContextState({
      ...contextState,
      data: [...copyState],
      isEdited: true,
      isEditable: false,
    });
  }, [contextState, copyState]);

  const onCancel = useCallback(() => {
    updateContextState({
      ...contextState,
      isEditable: !contextState?.isEditable,
    });

    setCopyState(() => [...contextState?.data]);
  }, [copyState, contextState]);

  const onChangeSearchText = useCallback(
    (event) => {
      const searchValue = event?.target?.value;
      updateSearchText(searchValue);

      // Apply search filter to all grid APIs
      Object.values(gridApis).forEach((api) => {
        if (api) api.setQuickFilter(searchValue);
      });
    },
    [gridApis]
  );

  // Store API per grid
  const onGridReady = useCallback((assetName: string, params: any) => {
    setGridApis((prev) => ({
      ...prev,
      [assetName]: params.api,
    }));
  }, []);

  const onAddAsset = useCallback(
    (assetRelationshipsList: AssetGridDataType[]) => {
      setCopyState((prevState) => {
        const updatedState = [...(prevState || [])];

        assetRelationshipsList?.forEach((newAsset) => {
          const existingSectionIndex = updatedState?.findIndex(
            (section) => section?.sectionId === newAsset?.sectionId
          );

          if (existingSectionIndex !== -1) {
            const existingRows = new Set(
              updatedState[existingSectionIndex]?.rows?.map((row) => row?.id)
            );

            const isMultiValued =
              updatedState[existingSectionIndex]?.isMultiValued;

            const filteredNewRows = newAsset?.rows?.filter(
              (row) => !existingRows?.has(row?.id)
            );

            if (filteredNewRows?.length > 0) {
              if (
                !isMultiValued &&
                updatedState[existingSectionIndex]?.rows?.length > 0 &&
                (!updatedState[existingSectionIndex]?.rows[0]?.isDeleted ||
                  updatedState[existingSectionIndex]?.rows?.length > 1)
              ) {
                updatedState[existingSectionIndex] = {
                  ...updatedState[existingSectionIndex],
                  // rows: [...filteredNewRows],
                  rows: [
                    {
                      ...updatedState[existingSectionIndex]?.rows[0],
                      isDeleted: true,
                      isChanged: true,
                      mode: "DELETE",
                    },
                    ...filteredNewRows,
                  ],
                };
              } else {
                updatedState[existingSectionIndex] = {
                  ...updatedState[existingSectionIndex],
                  rows: [
                    ...updatedState[existingSectionIndex]?.rows,
                    ...filteredNewRows,
                  ],
                };
              }
            }
          } else {
            updatedState?.push(newAsset);
          }
        });

        return updatedState;
      });
      setIsValidState(true);
    },
    [setCopyState]
  );

  const onCommentSave: OnCommentSave = useCallback(
    (sectionId, assetId, comment) => {
      setCopyState((prevState) =>
        prevState?.map((asset) =>
          asset?.sectionId === sectionId
            ? {
                ...asset,
                rows: asset?.rows?.map((row) =>
                  row?.id === assetId && row?.comment !== comment
                    ? { ...row, comment, isChanged: true }
                    : row
                ),
              }
            : asset
        )
      );
    },
    [setCopyState]
  );

  const onDeletion: OnDeletion = useCallback(
    (sectionId, assetId) => {
      const rowExistsInContext = assetDataRef?.current?.some(
        (section) => section?.id === assetId
      );

      if (rowExistsInContext) {
        setCopyState((prevState) =>
          prevState?.map((section) => {
            if (section?.sectionId === sectionId) {
              return {
                ...section,
                rows: section?.rows?.map((row) =>
                  row?.id === assetId
                    ? {
                        ...row,
                        isChanged: true,
                        mode: "DELETE",
                        isDeleted: true,
                      }
                    : row
                ),
              };
            }
            return section;
          })
        );
      } else {
        setCopyState((prevState) =>
          prevState?.map((section) => {
            if (section?.sectionId === sectionId) {
              return {
                ...section,
                rows: section?.rows?.filter((row) => row?.id !== assetId),
              };
            }
            return section;
          })
        );
      }
    },
    [setCopyState]
  );

  return (
    <SectionWithEditableAdditionalinfoStyled
      id={ASSET_RELATIONSHIP_SEC}
      isEditable={isEditMode}
      ref={relationshipsRef}
    >
      <SectionTitleWithIcon
        {...secTitle}
        marginBottom="25px"
        onEdit={onEdit}
        isEditable={isEditMode}
        onCancel={onCancel}
        onSave={onSave}
        isDisabledSaveButton={isFormVisible && !isValidState}
        disabledSaveBtnTooltipProps={{
          title: "Save pending relationships before proceeding.",
          placement: "topLeft",
        }}
        showHeader
        rightSec={
          <RightSection>
            <ConditionalDisplay condition={!isGridExpanded}>
              <Flex columnGap={10} alignItems="center">
                <MultiSelectionCheckboxesPopup
                  title="Asset Type"
                  options={assetTypesFilterOptions}
                  onChange={onChangeAssetTypesFilter}
                />
                <VerticalDividerStyled />
              </Flex>
            </ConditionalDisplay>

            <Input
              className="search-box"
              placeholder="Search"
              prefix={searchIconLight()}
              value={searchText}
              onChange={onChangeSearchText}
              width="310px"
              fontSize="14px"
            />
          </RightSection>
        }
      />

      <StateHandler
        isFetching={isLoading}
        error={errorPublishData || errorDraftData}
        blankSlate={relationshipBlankSlate}
      >
        <div className={isEditMode ? "relationships-edit-mode-wrapper" : ""}>
          {contextState?.isEditable && (
            <AssetRelationshipForm
              onAddAsset={onAddAsset}
              isFormVisible={isFormVisible}
              setIsFormVisible={setIsFormVisible}
              setIsValidState={setIsValidState}
            />
          )}

          {!!copyState?.length && (
            <AssetRelationshipGrids
              assetRelationshipsData={filteredAssets}
              onGridReady={onGridReady}
              onCommentSave={onCommentSave}
              onDeletion={onDeletion}
              copyState={copyState}
              setCopyState={setCopyState}
            />
          )}

          {!isFormVisible &&
            contextState?.isEditable &&
            copyState?.filter(
              (asset) =>
                asset?.isEditable &&
                asset?.rows?.length > 0 &&
                asset?.rows?.some((row) => !row?.isDeleted)
            )?.length === 0 && <AssetRelationshipsBlankState />}
        </div>
      </StateHandler>
    </SectionWithEditableAdditionalinfoStyled>
  );
}

export default SectionWithEditableRelationships;
