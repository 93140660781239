import { format } from "date-fns";
// <--- Library Imports Start --->
import { AxiosResponse } from "axios";
// <--- Library Imports End --->

// <--- Constants Start --->
import {
  COLUMN_SECTION_TITLES,
  TABLE_DATA_TAB_SECTION_TITLES,
} from "../../constants/labelconstants";
// <--- Constants End --->

// <--- Types Start --->
import { DQScoreProps } from "../../components/dqscore/dqscore.types";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";

import {
  ColTypeIds,
  ColRefClassEntityAddInfoType,
  ColumnDataType,
  ColumnDocResponse,
  ColumnRefDataQualityRulesAdditionalInfo,
  ColumnRefDataTypeAdditionalInfo,
  ColumnRefProfillingInfoAdditionalInfo,
  ColumnRefValiditityAdditionalInfo,
  ColumnRefVisDisAdditionalInfo,
  ColumnResponse,
  DataConnectionFields,
  DQSummaryType,
  MatchingEntityResponseType,
  MatchingEntityType,
  MatchingStaus,
  ReturnTypeOfColumnDoc,
  ReturnTypeOfColumnsParser,
  ReturnTypeOfDataSourceFieldDetailsParser,
  SampleDataHeaderChart,
  ReturnTypeOfColumnDetailParser,
  ColumnDocStatisticsResponse,
  ReturnTypeOfColumDetailStatisticParser,
  ColumnHistoryResponse,
  ReturnTypeOfColumDetailHistoryParser,
  RelatedColumnsResponse,
  ReturnTypeOfGetRelatedColumns,
} from "./columnparsers.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  checkDataTypeOfColumn,
  convertNoHyphenDateToValidDate,
  formatDate,
  getObjectKeys,
  getProminentTags,
  getTimeDifference,
  jsonParse,
  maskString,
  removeEscapeCharForNewLine,
  sortObjectsArrayByKey,
  sumTupleElement,
} from "../../utils";

import { getProfilingInfo } from "../../utils/getProfilingInfo";
import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";

import {
  getClassificationCustomTags,
  getLineagePreviewData,
} from "../parserutils";

import { computeColTypeID } from "./columnparser.utils";
import { X_AXIS_DATA_KEY } from "../../constants";

import { COLUMN_VISUAL_DIST_DATA_KEY } from "./columnparser.constants";
import { momentFormatDateNoTimezone } from "../../utils/momentFormatDateNoTimezone";
import { Actions } from "../../components/additionalinfo/additionalinfo.types";
import { ReturnTypeOfGetRelatedTables } from "../tablepage/tablepageparser.types";

// <--- Utils End --->

function getColumnChartData(
  data: SampleDataHeaderChart,
  colDataType: ColumnDataType,
  isPattern = false
): { name: string; Count: number; fill: string }[] {
  const { isNumberCol, isDateColumn } = checkDataTypeOfColumn(colDataType);

  const distCount = getObjectKeys(data?.DIST)?.length || 0;
  const histCount = getObjectKeys(data?.HIST)?.length || 0;

  const distData = isPattern
    ? data?.PATR // Use PATR for pattern data
    : isNumberCol
    ? distCount > 20 || !distCount
      ? data?.HIST
      : data?.DIST
    : isDateColumn
    ? histCount
      ? data?.HIST
      : data?.DIST
    : data?.DIST;

  if (!distData) return [];

  const chartData = getObjectKeys(distData)?.map((item) => ({
    [X_AXIS_DATA_KEY]: isDateColumn
      ? momentFormatDateNoTimezone(
          convertNoHyphenDateToValidDate(item),
          "MM/DD/YY"
        )
      : removeEscapeCharForNewLine(item),

    [COLUMN_VISUAL_DIST_DATA_KEY]: Number(distData?.[item]),
    fill: "#4894ca",
  }));

  const sortedChartData = isNumberCol
    ? chartData?.sort(
        (a, b) =>
          sumTupleElement(a?.[X_AXIS_DATA_KEY]) -
          sumTupleElement(b?.[X_AXIS_DATA_KEY])
      )
    : isDateColumn
    ? chartData
    : chartData?.sort(
        (a, b) =>
          b?.[COLUMN_VISUAL_DIST_DATA_KEY] - a?.[COLUMN_VISUAL_DIST_DATA_KEY]
      );

  return sortedChartData;
}

function getMatchingEntities(entitiesJson: string): MatchingEntityType[] {
  const matchingEntities: MatchingEntityResponseType[] = jsonParse(
    entitiesJson
  );

  return (
    matchingEntities?.map((entity) => ({
      entity_id: Number.parseInt(entity?.entity_id) || 0,
      entity_name: entity?.entity_name || "",
      description: entity?.entity_desc || "",
      entity_status: entity?.entity_status || "",
      entity_status_id: entity?.entity_status_id || "",
      matching_score: entity?.entity_matching_score || 0,
      matching_source: entity?.entity_match_source || "AAI",
      selected: entity?.entity_selection_status === "SEL",
    })) || []
  );
}

function getDQScore(dqScoreJSON: string): DQScoreProps {
  const { DQ_STATUS_ID, DQ_STATUS = "", SCORE, DRIFT_VAR } = jsonParse(
    dqScoreJSON,
    true
  ) as DQSummaryType;

  return {
    score: `${SCORE}`,
    trend: `${DRIFT_VAR}`,
    status: DQ_STATUS,
    statusId: DQ_STATUS_ID,
  };
}

function getColumnSummary(
  data: ColumnResponse[],
  isFieldView = false
): SectionRowProps[] {
  const filterSameGroupOfColumnType = (
    colType: ColumnResponse["COL_TYPE"]
  ): string[] =>
    data
      ?.filter((item) => item?.COL_TYPE === colType)
      ?.map((nestItem) => nestItem?.COL_TITLE || nestItem?.COL_NAME)
      ?.sort((a, b) => a?.toLowerCase()?.localeCompare(b?.toLowerCase()));
  return [
    {
      title: isFieldView ? "Total fields" : "Total Columns",
      value: `${data?.length || 0}`,
    },
    {
      title: "Granularity",
      stringValues: filterSameGroupOfColumnType("Identifier"),
    },
    {
      title: "Attributes",
      stringValues: filterSameGroupOfColumnType("Attribute"),
    },
    {
      title: "Measures",
      stringValues: filterSameGroupOfColumnType("Measure"),
    },
    {
      title: "Time Series",
      stringValues: filterSameGroupOfColumnType("Timeseries"),
    },
  ];
}

export function getTableColumsDetails({
  data,
}: AxiosResponse<ColumnResponse[]>): ReturnTypeOfColumnsParser {
  const maskDefinition = (str: string): string => {
    const strToBeMasked = str?.includes("e.g.")
      ? str?.split("e.g.")?.slice(-1)?.[0]
      : "";

    const maskedStr = maskString(strToBeMasked);
    return str?.replace(strToBeMasked, maskedStr);
  };

  return {
    column_details:
      data?.map((column) => {
        const minMax = `${column?.COL_MIN_VALUE || ""} - ${
          column?.COL_MAX_VALUE || ""
        }`;
        return {
          column_name: column?.COL_NAME || "",
          description: column?.IS_COL_MASKED
            ? maskDefinition(column?.COL_USER_DESC || "")
            : column?.COL_USER_DESC || "",
          data_type: column?.COL_DATA_TYPE_ID,
          data_type_info: column?.COL_DATA_TYPE || "",
          column_type: column?.COL_TYPE || "",
          column_type_id: column?.COL_TYPE_ID || "",
          entity: column?.COL_ENTITY_NAME || "",
          col_status: column?.COL_STATUS || "",
          col_status_id: column?.COL_STATUS_ID,
          profiling: getProfilingInfo(
            column?.COL_RECORD_CNT,
            column?.COL_DIST_CNT,
            column?.COL_BLANKS_CNT
          ),
          min_max: column?.IS_COL_MASKED ? maskString(minMax) : minMax,
          is_endorsed: column?.COL_ENDORSED_STATUS,
          endorsed_by: column?.COL_ENDORSED_BY || "",
          updated_by: column?.COL_UPDATED_BY || "",
          endorsed_on: getTimeDifference(
            utcTOLocalTimeZone(column?.COL_ENDORSED_ON) || ""
          ),
          updated_on: getTimeDifference(
            utcTOLocalTimeZone(column?.COL_UPDATED_ON) || ""
          ),
          is_recomended_by_ai: column?.IS_ENTITY_AUTO_ASSIGNED,
          column_id: column?.COL_ID,
          entity_id: column?.COL_ENTITY_ID,
          is_desc_inherited: column?.IS_DESC_INHERITED,
          entity_status: column?.TRM_STATUS || "",
          entity_status_id: column?.TRM_STATUS_ID,
          match_info: {
            matching_status: (column?.COL_ENTITY_MATCH_STATUS_ID ||
              "") as MatchingStaus,
            confidence_score: Math.round(
              Number.parseFloat(column?.COL_ENTITY_MATCH_SCORE)
            ),
          },
          matching_entities: getMatchingEntities(column?.COL_ENTITY_JSON),
          column_display_name: column?.COL_TITLE || column?.COL_NAME || "",
          dq_score: getDQScore(column?.COL_DQ_SUMMARY),
          alert: column?.IS_COL_HAS_ALERTS || false,
          table_display_name: column?.TBL_NAME || "",
          source_display_name: column?.SRC_NAME || "",
          is_cde_field: column?.IS_COL_CDE ? "Yes" : "",
          buiseness_rule: column?.COL_BIZ_RULES || "",
          col_type_id: computeColTypeID(column),
          col_dist_count: getObjectKeys(
            (jsonParse(column?.COL_DISTRIBUTION, true) as SampleDataHeaderChart)
              ?.DIST
          )?.length,
          is_col_masked: !!column?.IS_COL_MASKED,
        };
      }) || [],
    column_summary: getColumnSummary(data),
    sample_data_header_rendrer:
      data?.map((column) => ({
        column_id: `${column?.COL_ID || ""}`,
        column_name: column?.COL_NAME || "",
        column_display_name: column?.COL_TITLE || column?.COL_NAME || "",
        description: column?.IS_COL_MASKED
          ? maskDefinition(column?.COL_USER_DESC || "")
          : column?.COL_USER_DESC || "",
        data_type: column?.COL_DATA_TYPE_ID,
        data_type_info: column?.COL_DATA_TYPE || "",
        profiling: getProfilingInfo(
          column?.COL_RECORD_CNT,
          column?.COL_DIST_CNT,
          column?.COL_BLANKS_CNT
        ),
        is_endorsed: column?.COL_ENDORSED_STATUS,
        endorsed_by: column?.COL_ENDORSED_BY || "",
        col_status: column?.COL_STATUS || "",
        col_status_id: column?.COL_STATUS_ID,
        chart_data: column?.IS_COL_MASKED
          ? []
          : getColumnChartData(
              jsonParse(
                column?.COL_DISTRIBUTION,
                true
              ) as SampleDataHeaderChart,
              column?.COL_DATA_TYPE_ID
            ),
        is_col_masked: !!column?.IS_COL_MASKED,
      })) || [],
  };
}

export function getColumnParsedDoc({
  data,
}: AxiosResponse<ColumnDocResponse>): ReturnTypeOfColumnDoc {
  const classificationData =
    getClassificationCustomTags(data?.classifications || {}) || [];

  const {
    dq_status_id: dqStatusId,
    dq_status: dqStatus = "",
    dq_score: dqScore,
    dq_variance: dqVariance,
  } = data?.quality || {};

  const { is_autoset: isAutoSet, value } = data?.data_quality_rules || {};
  const { CMP, SHP, UNQ, VLD } = value || {};

  const columnType = data?.classifications?.col_type_json
    ? data?.classifications?.col_type_json?.value?.col_type_name ?? ""
    : data?.classifications?.col_type ?? "";

  return {
    publish_status: data?.published_status,
    publish_status_id: data?.published_status_id,
    node_title: data?.title?.value || "",
    node_name: data?.physical_name || "",
    source_name: data?.source_name || "",
    source_id: `${data?.source_id || ""}`,
    srcTypeId: data?.src_type_id,
    schemaName: data?.folder_name || "None",
    dbName: data?.db_name || "None",
    parent_id: `${data?.parent_id || ""}`,
    parent_name: data?.parent_name || "",
    lineage: getLineagePreviewData(data?.lineage),
    node_id: `${data?.node_id || ""}`,
    is_rule_auto_set: isAutoSet,
    entity_id: data?.classifications?.entity?.value?.entity_id || "",
    is_col_desc_inherited: !!data?.description?.description?.is_inherited,
    is_dq_enabled: data?.is_dq_enabled || false,
    is_wf_enabled: data?.is_wf_enabled || false,
    information: [
      {
        title: COLUMN_SECTION_TITLES?.description?.meta_score,
        value: data?.description?.metadata_score?.value || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.description?.description,
        value: data?.description?.description?.value || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.description?.data_type,
        value: data?.description?.data_type_id || "",
        additionalInfo: {
          data_type_info: data?.description?.data_type || "",
        } as ColumnRefDataTypeAdditionalInfo,
      },
      {
        title: COLUMN_SECTION_TITLES?.description?.column_type,
        value: columnType,
      },
      {
        title: COLUMN_SECTION_TITLES?.description?.example,
        value: data?.description?.example || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.description?.column_sub_type,
        value: data?.classifications?.col_subtype || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.description?.is_cde,
        value: data?.classifications?.is_col_cde?.value ? "Yes" : "",
      },
      {
        title: COLUMN_SECTION_TITLES?.description?.business_rules,
        value: data?.description?.col_biz_rules?.value || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.description?.data_domain,
        value:
          data?.classifications?.functional_area?.value?.functional_area_name ||
          "",
      },
    ],
    managed_by: [
      {
        title: COLUMN_SECTION_TITLES?.managed_by?.data_owner,
        value: COLUMN_SECTION_TITLES?.managed_by?.data_steward,
        keyValueTags: data?.managed_by?.data_owners?.value
          ?.map((item) => ({
            name:
              item?.user_type === "USR"
                ? `${item?.data_owner_name || ""} (${
                    item?.data_owner_email || ""
                  })`
                : item?.data_owner_name,
            type: item?.user_type,
            email: item?.data_owner_email || "",
          }))
          ?.filter((item) => item?.name),
        selectedValues: data?.managed_by?.data_owners?.value
          ?.map((item) => item?.data_owner_name || "")
          ?.filter((item) => item),
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isInherited: data?.managed_by?.data_owners?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.data_owners?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.managed_by?.system_owner,
        value: "",
        keyValueTags: data?.managed_by?.system_owners?.value
          ?.map((item) => ({
            name:
              item?.user_type === "USR"
                ? `${item?.system_owner_name || ""} (${
                    item?.system_owner_email || ""
                  })`
                : item?.system_owner_name,
            type: item?.user_type,
            email: item?.system_owner_email || "",
          }))
          ?.filter((item) => item?.name),
        selectedValues: data?.managed_by?.system_owners?.value
          ?.map((item) => item?.system_owner_name || "")
          ?.filter((item) => item),
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isInherited: data?.managed_by?.system_owners?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.system_owners?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
      {
        title: COLUMN_SECTION_TITLES?.managed_by?.data_steward,
        value: "",
        keyValueTags: data?.managed_by?.data_stewards?.value
          ?.map((item) => ({
            name:
              item?.user_type === "USR"
                ? `${item?.data_steward_name || ""} (${
                    item?.data_steward_email || ""
                  })`
                : item?.data_steward_name,
            type: item?.user_type,
            email: item?.data_steward_email || "",
          }))
          ?.filter((item) => item?.name),
        selectedValues: data?.managed_by?.data_stewards?.value
          ?.map((item) => item?.data_steward_name || "")
          ?.filter((item) => item),
        apiKey: "get_user_users_groups",
        urlParam: ["", "USR,UGP"],
        isInherited: data?.managed_by?.data_stewards?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.data_stewards?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
    ],
    linked_entity: {
      id: data?.classifications?.entity?.value?.entity_id || "",
      entity_title: data?.classifications?.entity?.value?.entity_title || "",
      entity_name: data?.classifications?.entity?.value?.entity_name || "",
      match_status:
        data?.classifications?.entity?.value?.entity_match_status || "",
      match_score:
        data?.classifications?.entity?.value?.entity_match_score || 0,
      match_source:
        data?.classifications?.entity?.value?.entity_match_source || "",
      matching_entities:
        data?.classifications?.entity?.value?.col_entity_json || [],
    },
    classification: [
      // {
      //   title: COLUMN_SECTION_TITLES?.classification?.entity,
      //   value: data?.classifications?.entity?.value?.entity_name || "",
      //   additionalInfo: {
      //     entity_matching_status:
      //       data?.classifications?.entity?.value?.entity_match_status,
      //   } as ColRefClassEntityAddInfoType,
      //   selectedValueOfTags: data?.classifications?.entity?.value?.entity_name
      //     ? [
      //         {
      //           label: data?.classifications?.entity?.value?.entity_name || "",
      //           value: data?.classifications?.entity?.value?.entity_id || "",
      //         },
      //       ]
      //     : undefined,
      // },

      {
        title: COLUMN_SECTION_TITLES?.classification?.functional_area,
        value: data?.functional_area_name || "",
      },
      ...classificationData,
    ],
    data_quality_rules: [
      {
        title: SHP?.rule_cat_name || "",
        id: "data_format",
        additionalInfo: {
          checked: SHP?.rules?.CDF?.dq_rule_status_id !== "INA",
        } as ColumnRefDataQualityRulesAdditionalInfo,
      },
      {
        title: CMP?.rule_cat_name || "",
        id: "completeness",
        additionalInfo: {
          checked: CMP?.rules?.CCP?.dq_rule_status_id !== "INA",
        } as ColumnRefDataQualityRulesAdditionalInfo,
      },
      {
        title: COLUMN_SECTION_TITLES?.data_quality_rules?.validity,
        additionalInfo: {
          completenes_value:
            VLD?.rules?.CCV?.dq_rule_status_id !== "INA" ||
            VLD?.rules?.CPV?.dq_rule_status_id !== "INA" ||
            VLD?.rules?.CVR?.dq_rule_status_id !== "INA",
        } as ColumnRefValiditityAdditionalInfo,
        subList: [
          {
            title: VLD?.rules?.CCV?.dq_rule_name || "",
            stringValues: getObjectKeys(
              VLD?.rules?.CCV?.Values || {
                None: "",
              }
            ),
          },
          {
            title: VLD?.rules?.CVR?.dq_rule_name,
            value: `${VLD?.rules?.CVR?.Values?.MIN || ""} - ${
              VLD?.rules?.CVR?.Values?.MAX || ""
            }`,
          },
          {
            title: VLD?.rules?.CPV?.dq_rule_name || "",
            stringValues: getObjectKeys(
              VLD?.rules?.CPV?.Values || {
                None: "",
              }
            ),
          },
        ],
      },
      {
        title: UNQ?.rule_cat_name || "",
        id: "uniqueness",
        additionalInfo: {
          checked: UNQ?.rules?.CUQ?.dq_rule_status_id !== "INA",
        } as ColumnRefDataQualityRulesAdditionalInfo,
      },
    ],
    profillingInfo: [
      {
        title: COLUMN_SECTION_TITLES?.profiling_information?.count,
        additionalInfo: {
          profillingInfo: getProfilingInfo(
            data?.profiling_info?.count?.record_count,
            data?.profiling_info?.count?.distinct_count,
            data?.profiling_info?.count?.blanks_count
          ),
        } as ColumnRefProfillingInfoAdditionalInfo,
      },
      {
        title: COLUMN_SECTION_TITLES?.profiling_information?.min_max,
        value: data?.profiling_info?.min_max || "",
      },
      {
        title:
          COLUMN_SECTION_TITLES?.profiling_information?.visual_distribution,
        additionalInfo: {
          visual_distribution_chart_data:
            getColumnChartData(
              data?.profiling_info?.distribution,
              data?.description?.data_type_id
            ) || [],
          col_data_type_id: data?.description?.data_type_id,
        } as ColumnRefVisDisAdditionalInfo,
      },
    ],
    quality: [
      {
        title: COLUMN_SECTION_TITLES?.quality?.overall_score,
        value: dqScore || "",
        additionalInfo: {
          status: dqStatus,
          statusId: dqStatusId,
          score: dqScore,
          trend: dqVariance,
        },
      },
      {
        title: COLUMN_SECTION_TITLES?.quality?.last_updated,
        value: `Last updated ${getTimeDifference(
          utcTOLocalTimeZone(data?.last_refreshed_on || "")
        )}`,
      },
    ],
    additionalInfo: [
      ...(data?.additional_info?.value || [])
        ?.filter((item) => item?.Link)
        ?.map((item, index) => ({
          actionId: "embed_link" as Actions,
          id: `embed_link-${index}`,
          value: item?.Link || "",
        })),
      ...(data?.additional_info?.value || [])
        ?.filter((item) => item?.Text)
        ?.map((item, index) => ({
          actionId: "edit" as Actions,
          id: `edit-${index}`,
          value: item?.Text || "",
        })),
    ],
    is_editable: !!data?.is_editable,

    prominent_tags: {
      tags: getProminentTags(classificationData),
      isBoldView: true,
    },
  };
}

export function getDataSourceFieldsDetails({
  data = [],
}: AxiosResponse<ColumnResponse[]>): ReturnTypeOfDataSourceFieldDetailsParser {
  return {
    column_summary: getColumnSummary(data, true),
    field_details: data?.map((item) => ({
      id: `${item?.COL_ID || ""}`,
      data_connection_fields: (jsonParse(
        item?.DATA_CONNECTION_FIELDS,
        false
      ) as DataConnectionFields)?.map((conectionField) => ({
        name: conectionField?.col_name || "",
        id: `${conectionField?.col_id || ""}`,
      })),
      data_source_field: item?.COL_TITLE || item?.COL_NAME || "",
      data_type: item?.COL_DATA_TYPE_ID,
      entity: item?.COL_ENTITY_NAME || "",
      entity_id: `${item?.COL_ENTITY_ID || ""}`,
      field_desc: item?.COL_USER_DESC || "",
      field_type: item?.COL_TYPE,
      field_status_id: item?.COL_STATUS_ID,
      field_status: item?.COL_STATUS || "",
      data_type_title: item?.COL_DATA_TYPE || "",
    })),
  };
}

export function getColumnParsedDetail({
  data,
}: AxiosResponse<ColumnDocResponse>): ReturnTypeOfColumnDetailParser {
  const createdObj = data?.description?.audit_trail?.find(
    (item) => item?.activity === COLUMN_SECTION_TITLES?.audit_trail?.created
  );
  const updatedObj = data?.description?.audit_trail?.find(
    (item) => item?.activity === COLUMN_SECTION_TITLES?.audit_trail?.updated
  );

  const classificationData =
    getClassificationCustomTags(data?.classifications || {}) || [];

  const isColTypeJson = data?.classifications?.col_type_json;

  const columnTypeName = isColTypeJson
    ? data?.classifications?.col_type_json?.value?.col_type_name ?? ""
    : data?.classifications?.col_type ?? "";

  const columnTypeId = isColTypeJson
    ? data?.classifications?.col_type_json?.value?.col_type_id ?? ""
    : data?.classifications?.col_type ?? "";

  return {
    additional_info: {
      data: [
        ...(data?.additional_info?.value || [])
          ?.filter((item) => item?.Link)
          ?.map((item, index) => ({
            actionId: "embed_link" as Actions,
            id: `embed_link-${index}`,
            value: item?.Link || "",
          })),
        ...(data?.additional_info?.value || [])
          ?.filter((item) => item?.Text)
          ?.map((item, index) => ({
            actionId: "edit" as Actions,
            id: `edit-${index}`,
            value: item?.Text || "",
          })),
      ],
      isEditable: false,
      isEdited: false,
    },
    classification: {
      data: classificationData,
      editable: false,
      isEdited: false,
    },
    description: {
      data: [
        {
          title: COLUMN_SECTION_TITLES?.description?.title,
          value: data?.title?.value || "",
          isRowVisibleInNormalView: false,
          isEditable: true,
          type: "input",
        },
        {
          title: COLUMN_SECTION_TITLES?.description?.description,
          value: data?.description?.description.value || "",
          isEditable: true,
          type: "textarea",
        },
        {
          title: COLUMN_SECTION_TITLES?.description?.data_type,
          value: data?.description?.data_type_id || "",
          isEditable: false,
          isRowVisibleInEditView: false,
        },
        {
          title: COLUMN_SECTION_TITLES?.description?.column_type,
          value: columnTypeName || "",
          isEditable: true,
          isRowVisibleInEditView: false,
          type: "select",
          selectedValues: columnTypeId ? [columnTypeId] : [],
        },
        {
          title: COLUMN_SECTION_TITLES?.description?.example,
          value: data?.description?.example || "",
          isEditable: false,
          isRowVisibleInEditView: false,
        },
        {
          title: COLUMN_SECTION_TITLES?.description?.is_cde,
          value: data?.classifications?.is_col_cde?.value ? "Yes" : "",
          isEditable: true,
          type: "checkbox",
        },
        {
          title: COLUMN_SECTION_TITLES?.description?.business_rules,
          value: data?.description?.col_biz_rules?.value || "",
          isEditable: true,
          type: "textarea",
        },
        {
          title: COLUMN_SECTION_TITLES?.description?.data_domain,
          value:
            data?.classifications?.functional_area?.value
              ?.functional_area_name || "",
          type: "select",
          isEditable: true,
          selectedValues: data?.classifications?.functional_area?.value
            ?.functional_area_id
            ? [
                `${
                  data?.classifications?.functional_area?.value
                    ?.functional_area_id || ""
                }`,
              ]
            : [],
          isInherited: !!data?.classifications?.functional_area?.value
            ?.is_inherited,
          inHeritedBy:
            data?.classifications?.functional_area?.value?.inherited_from || "",
        },
      ],
      editable: false,
      isEdited: false,
    },
    managed_by: {
      data: [
        {
          title: COLUMN_SECTION_TITLES?.managed_by?.data_owner,
          keyValueTags: data?.managed_by?.data_owners?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.data_owner_name || ""} (${
                      item?.data_owner_email || ""
                    })`
                  : item?.data_owner_name,
              type: item?.user_type,
              email: item?.data_owner_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.data_owners?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.data_owner_name || ""} (${
                    item?.data_owner_email || ""
                  })`
                : item?.data_owner_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["", "USR,UGP"],
          isInherited: data?.managed_by?.data_owners?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.data_owners?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
        },
        {
          title: COLUMN_SECTION_TITLES?.managed_by?.system_owner,
          keyValueTags: data?.managed_by?.system_owners?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.system_owner_name || ""} (${
                      item?.system_owner_email || ""
                    })`
                  : item?.system_owner_name,
              type: item?.user_type,
              email: item?.system_owner_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.system_owners?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.system_owner_name || ""} (${
                    item?.system_owner_email || ""
                  })`
                : item?.system_owner_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["", "USR,UGP"],
          isInherited: data?.managed_by?.system_owners?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.system_owners?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
        },
        {
          title: COLUMN_SECTION_TITLES?.managed_by?.data_steward,
          keyValueTags: data?.managed_by?.data_stewards?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.data_steward_name || ""} (${
                      item?.data_steward_email || ""
                    })`
                  : item?.data_steward_name,
              type: item?.user_type,
              email: item?.data_steward_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.data_stewards?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.data_steward_name || ""} (${
                    item?.data_steward_email || ""
                  })`
                : item?.data_steward_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["", "USR,UGP"],
          isInherited: data?.managed_by?.data_stewards?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.data_stewards?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
          isDataStewardField: true,
        },
        {
          title: COLUMN_SECTION_TITLES?.managed_by?.audit_trail,
          value: `${createdObj?.activity || ""} by ${
            createdObj?.user_name || ""
          } ${getTimeDifference(
            utcTOLocalTimeZone(createdObj?.activity_on || "")
          )}:${updatedObj?.activity || ""} by ${
            updatedObj?.user_name || ""
          } ${getTimeDifference(
            utcTOLocalTimeZone(updatedObj?.activity_on || "")
          )}`,
          isEditable: true,
        },
      ],
      editable: false,
      isEdited: false,
    },
    provinance_data: {
      isEditable: false,
      data: [
        {
          title:
            COLUMN_SECTION_TITLES?.provinance_data?.table_and_sources_columns,
          isEditable: true,
          sources: (data?.provenance_scan?.provenance?.value || [])?.map(
            (item) => ({
              sourceName: item?.root_name || "",
              sourceId: `${item?.root_id || ""}`,
              dbSrcTypeId: item?.source_type_id || "ORC",
              dbName: item?.db_name || "None",
              schemaName: item?.folder_name || "None",
              tblName: item?.parent_name || "",
              tblId: `${item?.parent_id || ""}`,
              isRefView: false,
              isTblSelection: false,
              colId: `${item?.node_id || ""}`,
              colName: item?.node_name || "",
            })
          ),
          apiKey: "get_node_parental_info_by_node_type",
          urlParam: ["COL", "COL"],
          placeHolder: "Select one or more columns",
          placeHolderForSearch: "Search for columns",
        },
      ],
      isEdited: false,
    },
    linked_entity: {
      data: {
        id: data?.classifications?.entity?.value?.entity_id || "",
        entity_title: data?.classifications?.entity?.value?.entity_title || "",
        entity_name: data?.classifications?.entity?.value?.entity_name || "",
        match_status:
          data?.classifications?.entity?.value?.entity_match_status || "",
        match_score:
          data?.classifications?.entity?.value?.entity_match_score || 0,
        match_source:
          data?.classifications?.entity?.value?.entity_match_source || "",
        matching_entities: (
          data?.classifications?.entity?.value?.col_entity_json || []
        )?.map((entity) => ({
          matching_entity_id: entity?.entity_id || "",
          matching_entity: entity?.entity_name || "",
          samples: entity?.entity_desc || "",
          match_source: entity?.entity_match_source || "",
          matching_score: entity?.entity_matching_score,
          selected: entity?.entity_selection_status === "SEL",
          entity_status: entity?.entity_status || "",
          entity_status_id: entity?.entity_status_id,
        })),
      },
      isEditable: false,
      isEdited: false,
    },

    publish_status: data?.published_status,
    publish_status_id: data?.published_status_id,
    node_title: data?.title?.value || "",
    node_name: data?.physical_name || "",
    node_type: data?.node_type || "",
    col_description: data?.description?.description?.value || "",
    source_name: data?.source_name || "",
    source_id: `${data?.source_id || ""}`,
    parent_id: `${data?.parent_id || ""}`,
    parent_name: data?.parent_name || "",
    source_type_id: data?.source_type_id || "",
    lineage: getLineagePreviewData(data?.lineage),
    node_id: `${data?.node_id || ""}`,
    entity_id: data?.classifications?.entity?.value?.entity_id || "",
    is_col_desc_inherited: !!data?.description?.description?.is_inherited,
    is_dq_enabled: data?.is_dq_enabled,
    is_editable: !!data?.is_editable,
    is_profiled: !!data?.profiling_info?.count?.record_count,
    is_endorsed: !!data?.endorsed_status?.value,
    endorsed_count: data?.endorsed_count || 0,
    is_deprecated: data?.published_status_id === "DEP",
    last_profiled_on: data?.last_refreshed_on || "",
    is_primary: data?.description?.is_col_pk || true,
    min_max: data?.profiling_info?.min_max || "",
    is_nullable: data?.description?.is_col_nullable || false,
    technical_data_type: data?.description?.data_type || "",
    column_position: data?.description?.col_sequence || 0,
    size: data?.description?.col_length || 0,
    fractional_digits: data?.description?.col_data_precision || 0,
    prominent_tags: {
      tags: getProminentTags(classificationData),
      isBoldView: true,
    },
    profillingInfo: [
      {
        title: COLUMN_SECTION_TITLES?.profiling_information?.count,
        additionalInfo: {
          profillingInfo: getProfilingInfo(
            data?.profiling_info?.count?.record_count,
            data?.profiling_info?.count?.distinct_count,
            data?.profiling_info?.count?.blanks_count
          ),
        } as ColumnRefProfillingInfoAdditionalInfo,
      },

      {
        title: COLUMN_SECTION_TITLES?.profiling_information?.min_max,
        value: data?.profiling_info?.min_max || "",
      },
      {
        title:
          COLUMN_SECTION_TITLES?.profiling_information?.visual_distribution,
        additionalInfo: {
          visual_distribution_chart_data:
            getColumnChartData(
              data?.profiling_info?.distribution,
              data?.description?.data_type_id
            ) || [],
          col_data_type_id: data?.description?.data_type_id,
        } as ColumnRefVisDisAdditionalInfo,
      },
      {
        title:
          COLUMN_SECTION_TITLES?.profiling_information?.pattern_distribution, // Add this new entry
        additionalInfo: {
          pattern_distribution_chart_data: sortObjectsArrayByKey(
            getColumnChartData(
              {
                DIST: {}, // Empty object for missing properties
                HIST: {}, // Empty object for missing properties
                PATR: data?.profiling_info?.distribution?.PATR || {},
              },
              data?.description?.data_type_id,
              true
            ) || [],
            "Count"
          )?.reverse(),
          col_data_type_id: data?.description?.data_type_id,
        },
      },
    ],
    is_wf_enabled: !!data?.is_wf_enabled,
    is_draft_exist: !!data?.is_draft_exists,
    node_sub_type: data?.node_sub_type,
    get_access_info: "",
    node_desc: "",
    node_url: "",
    src_type_id: data?.src_type_id,
    db_name: data?.db_name || "None",
    schema_name: data?.folder_name || "",
  };
}

export function getColumnStatisticsParsedDetail({
  data,
}: AxiosResponse<
  ColumnDocStatisticsResponse[]
>): ReturnTypeOfColumDetailStatisticParser {
  const parsedData = data?.[0] || {}; // Extract the first object

  return {
    record_count: parsedData?.COL_RECORD_CNT || 0,
    last_scanned_on: parsedData?.COL_LAST_SCANNED_ON || "",
    sample_data_path: parsedData?.TBL_SAMPLE_DATA || "",
    is_profiled: parsedData?.IS_COL_PROFILED || false,
    is_on_prem_saws: parsedData?.IS_ON_PREM_SAWS || false,
    saws_communication_key: parsedData?.SAWS_COMMUNICATION_KEY || "",
    saws_id: parsedData?.SAWS_ID || 0,
    saws_hostname: parsedData?.SAWS_HOSTNAME || "",
    saws_port: parsedData?.SAWS_PORT || 0,
    rule_count: parsedData?.RULE_CNT || 0,
    alerting_rules_count: parsedData?.ALERTING_RULES_CNT || 0,
    avg_dq_score:
      parseFloat(parseFloat(parsedData?.AVG_DQ_SCORE).toFixed(2)) || 0,
    exception_record_count: parseInt(parsedData?.RULE_EXP_RECORD_CNT) || 0,
    last_executed_on: parsedData?.RULES_LAST_EXEC_ON || "",
    recommended_rule_count: parsedData?.RCMD_RULE_CNT || 0,
    is_time_series_column: parsedData?.IS_TS_COL_EXISTS || false,
    dq_status: parsedData?.DQ_STATUS || "",
    dq_status_id: parsedData?.DQ_STATUS_ID || "",
  };
}

export function getColumnHistoryParsedDetail({
  data,
}: AxiosResponse<ColumnHistoryResponse>): ReturnTypeOfColumDetailHistoryParser {
  return {
    historyData:
      data?.map((entry) => ({
        name: format(new Date(entry?.RULE_EXEC_DAY), "MMM dd"), // Formatting Date
        "DQ Score":
          parseFloat(parseFloat(entry?.AVERAGE_DQ_SCORE).toFixed(2)) || 0,
        Exceptions:
          parseFloat(parseFloat(entry?.RULE_EXP_RECORD_CNT).toFixed(2)) || 0,
      })) || [],
  };
}

export function getRelatedColumns({
  data,
}: AxiosResponse<RelatedColumnsResponse[]>): ReturnTypeOfGetRelatedColumns[] {
  return data?.map((item) => ({
    entityId: `${item?.COL_ENTITY_ID || ""}`,
    entityTitle: item?.COL_ENTITY_NAME || "--",
    nodeId: `${item?.COL_ID || ""}`,
    colDataType: (item?.COL_DATA_TYPE as ColumnDataType) || "",
    colRecordCount: item?.COL_RECORD_CNT || 0,
    colBlanksCount: item?.COL_BLANKS_CNT || 0,
    colDistinctCount: item?.COL_DIST_CNT || 0,

    header: [
      { nodeType: "SRC", title: item?.SRC_NAME || "" },
      { nodeType: "TBL", title: item?.TBL_DISPLAY_NAME || "" },
    ],
    breadcrumb: [
      {
        title: item?.SRC_NAME || "",
        type: "SRC",
        srcTypeId: `${item?.SRC_TYPE_ID || "ORC"}`,
        nodeId: `${item?.SRC_ID || ""}`,
      },
      {
        title: item?.DB_NAME || "None",
        type: "DTB",
      },
      {
        title: item?.FOLDER_NAME || "None",
        type: "SCM",
      },
      {
        title: item?.TBL_DISPLAY_NAME || "",
        type: "TBL",
        nodeId: `${item?.TBL_ID || ""}`,
      },
    ],
    title: {
      nodeType: "COL",
      mainTitle: item?.COL_TITLE || item?.COL_NAME || "",
      subTitle: `${
        item?.COL_ENDORSE_CNT ? `${item?.COL_ENDORSE_CNT} people liked` : "--"
      }`,
    },
    desc: item?.COL_USER_DESC || "--",
    additionalInfo: [
      {
        title: "Last Scanned On",
        value: utcTOLocalTimeZone(item?.COL_LAST_SCANNED_ON),
      },
      {
        title: "Entity",
        value: item?.COL_ENTITY_NAME || "--",
      },
    ],
  }));
}
