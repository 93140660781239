import styled from "styled-components";

export const FilterSelectionPanelStyled = styled.div`
  padding: 10px;
  border: 1px solid ${(props): string => props?.theme?.bgColors?.GREY_18};
  border-radius: 4px;
  background: ${(props): string => props?.theme?.bgColors?.WHITE_COLOR};
`;

export const OptionsContainerStyled = styled.div`
  padding: 0px 5px 10px 5px;
  margin-top: 18px;
  min-height: 370px;
  max-height: 370px;
  overflow-y: auto;
`;

export const OptionItemStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 11px;
  margin-bottom: 8px;
`;

export const OptionLabelStyled = styled.div`
  color: ${(props): string => props?.theme?.textColors?.GREY_40};
`;
