import { AssetCompareChangesProps } from "./assetcomparechanges.types";
import { useOpenDrawer } from "../../customhooks";

import { useGetAssetChangesForReview } from "../../api/assetservice";

import { IsCDEFieldProps } from "../iscdefield/iscdefield.types";
import IsCDEField from "../iscdefield";

import {
  getDescriptionFieldData,
  getDescriptionFieldTitle,
  getFilteredFields,
  getIsChangedDescriptionField,
  getIsChangedManagedBy,
  getManagedByFieldData,
} from "./assetcomparechanges.util";

import {
  AssetFieldIds,
  ManagedByObjKeys,
  getAssetConfig,
} from "../../parsers/asssetoverviewparser";

import {
  AssetCompareChangesPanelStyled,
  FieldStyled,
  GridContentRowStyled,
  NoChangesDetectedStyled,
} from "./assetcomparechanges.styles";

import {
  AdditionalInfoRendere,
  AssetGridWrapper,
  RenderRelationShipList,
  RenderRuleOfTypeList,
} from "./assetcomparechanges.renderer";

import { SimpleTag } from "../tags";
import Represents from "../represents";

import { reviewChangesBlankSlate } from "../../blankslates/reviewchangesblankslate";
import StateHandler from "../statehandler";
import ConditionalDisplay from "../conditionaldisplay";

const SIMPLE_TAG_CLASS_NAME = "review-changes-tag";

const RenderFieldValue = ({
  isCdeField,
  value,
}: {
  isCdeField: boolean;
  value: string | IsCDEFieldProps["value"];
}): JSX.Element =>
  isCdeField ? (
    <IsCDEField value={value as IsCDEFieldProps["value"]} />
  ) : (
    <>{value}</>
  );

const NoChangesDetected = (): JSX.Element => {
  return (
    <NoChangesDetectedStyled>
      <div className="text">No updates were made.</div>
    </NoChangesDetectedStyled>
  );
};

const AssetCompareChangesPanel = (
  props: AssetCompareChangesProps
): JSX.Element => {
  const openDrawer = useOpenDrawer();
  const { nodeId = "", nodeType = "TRM", isReportPage, nodeName = "" } = props;

  const {
    parsedData: changes,
    isLoading: isWorkflowLoading,
    error: errorInWorkflow,
  } = useGetAssetChangesForReview(nodeId, nodeType);

  const nodeSubType = isReportPage ? "RPT" : undefined;

  const currentAssetconfig = getAssetConfig(nodeType, nodeSubType);

  const isBusinessRulesApplicable =
    currentAssetconfig?.business_rules?.isApplicableOnDetailPage ?? false;

  const isRepresentsApplicable = currentAssetconfig?.represents ?? false;

  const represenstSectionName =
    currentAssetconfig?.represents?.sectionNameInCompareChangesView ?? "";

  const represenstFieldName =
    currentAssetconfig?.represents?.sectionTitleInCompareChangesView ?? "";

  const managegByConfig = currentAssetconfig?.managed_by || {};
  const manageByFields = managegByConfig?.fields || [];

  const descConfig = currentAssetconfig?.description || {};
  const descFields = descConfig?.fields || [];

  const customDefConfig = currentAssetconfig?.custom_definition || [];
  const customDefFields = customDefConfig?.fields || [];

  const filterSortedDescFlds = getFilteredFields(descFields);

  const filterSortedCustomDescFlds = getFilteredFields(customDefFields);

  const filterSortedMngByFlds = getFilteredFields(manageByFields);

  const getTermName = (tagAlongWithTagsetName: string): string => {
    const splitedVal = tagAlongWithTagsetName?.split?.(".");
    return splitedVal?.slice(-1)?.[0] || "";
  };

  const isTerm = nodeType === "TRM";
  const isColumn = nodeType === "COL";

  const parsedChanges = changes?.[0] || {};

  const {
    additionalInfoText,
    classification = [],
    businessRules,
    provenance,
    relationship,
  } = parsedChanges;

  const {
    newValue: additionalInfoTextNewValues = [],
    oldValue: additionalInfoTextOldValues = [],
    isChanged: additionalInfoTextIsChanged = false,
  } = additionalInfoText || {};

  const { categories, manual, patterns, valueRange } = businessRules || {};

  const {
    isChanged: isProvenanceChanged,
    newValue: provenenceNewVal = [],
    oldValue: provenanceOldVal = [],
  } = provenance || {};

  const {
    goverenedByDomain,
    goverenedByPolicy,
    representedByGlossary,
    referencedByDict,
  } = relationship || {};

  const {
    isChanged: goverenedByDomainIsChanged,
    oldValue: goverenedByDomainOldValue,
    newValue: goverenedByDomainNewValue,
  } = goverenedByDomain || {};

  const {
    isChanged: goverenedByPolicyIsChanged,
    oldValue: goverenedByPolicyOldValue,
    newValue: goverenedByPolicyNewValue,
  } = goverenedByPolicy || {};

  const {
    isChanged: representedByGlossaryIsChanged,
    oldValue: representedByGlossaryOldValue,
    newValue: representedByGlossaryNewValue,
  } = representedByGlossary || {};

  const {
    isChanged: referencedByDictIsChanged,
    oldValue: referencedByDictOldValue,
    newValue: referencedByDictNewValue,
  } = referencedByDict || {};

  const isAnyChangeInRelationship =
    goverenedByDomainIsChanged ||
    goverenedByPolicyIsChanged ||
    representedByGlossaryIsChanged ||
    referencedByDictIsChanged;

  return (
    <AssetCompareChangesPanelStyled>
      <StateHandler
        isFetching={isWorkflowLoading}
        error={errorInWorkflow}
        blankSlate={reviewChangesBlankSlate}
      >
        <div className="panel-tabs">
          <AssetGridWrapper title="Description">
            <>
              {filterSortedDescFlds?.map((item) => {
                const { id = "none", title = "" } = item || {};
                const typedId = id as keyof typeof AssetFieldIds;

                const isCdeField = id === AssetFieldIds.isCDE;

                const isChanged = getIsChangedDescriptionField(
                  changes,
                  typedId
                );

                const fieldTitle = getDescriptionFieldTitle(typedId, title);

                const oldVlaue = getDescriptionFieldData(
                  changes,
                  typedId,
                  "old"
                );

                const newValue = getDescriptionFieldData(
                  changes,
                  typedId,
                  "new"
                );

                return (
                  <GridContentRowStyled
                    className="grid-content-row"
                    isChanged={isChanged}
                    key={typedId}
                  >
                    <FieldStyled>{fieldTitle}</FieldStyled>

                    <FieldStyled data-testid={`review-changes-${id}-old-val`}>
                      <RenderFieldValue
                        value={oldVlaue}
                        isCdeField={isCdeField}
                      />
                    </FieldStyled>
                    <FieldStyled data-testid={`review-changes-${id}-new-val`}>
                      <RenderFieldValue
                        value={newValue}
                        isCdeField={isCdeField}
                      />
                    </FieldStyled>
                  </GridContentRowStyled>
                );
              })}
            </>
          </AssetGridWrapper>

          <AssetGridWrapper title="Managed By">
            <>
              {filterSortedMngByFlds?.map((item) => {
                const { id = "none", title } = item || {};
                const typedId = id as ManagedByObjKeys;

                const isChanged = getIsChangedManagedBy(changes, typedId);

                const oldValue = getManagedByFieldData(changes, typedId, "old");

                const newValue = getManagedByFieldData(changes, typedId, "new");

                return (
                  <GridContentRowStyled
                    key={id}
                    className="grid-content-row"
                    isChanged={isChanged}
                  >
                    <FieldStyled>{title}</FieldStyled>
                    <FieldStyled data-testid={`review-changes-${id}-old-val`}>
                      {oldValue}
                    </FieldStyled>
                    <FieldStyled data-testid={`review-changes-${id}-new-val`}>
                      {newValue}
                    </FieldStyled>
                  </GridContentRowStyled>
                );
              })}
            </>
          </AssetGridWrapper>

          <ConditionalDisplay condition={!!filterSortedCustomDescFlds?.length}>
            <AssetGridWrapper title="Asset-Specific Details">
              <>
                {filterSortedCustomDescFlds?.map((item) => {
                  const { id = "none", title = "" } = item || {};
                  const typedId = id as keyof typeof AssetFieldIds;

                  const isCdeField = id === AssetFieldIds.isCDE;

                  const isChanged = getIsChangedDescriptionField(
                    changes,
                    typedId
                  );

                  const fieldTitle = getDescriptionFieldTitle(typedId, title);

                  const oldVlaue = getDescriptionFieldData(
                    changes,
                    typedId,
                    "old"
                  );

                  const newValue = getDescriptionFieldData(
                    changes,
                    typedId,
                    "new"
                  );

                  return (
                    <GridContentRowStyled
                      className="grid-content-row"
                      isChanged={isChanged}
                      key={typedId}
                    >
                      <FieldStyled>{fieldTitle}</FieldStyled>

                      <FieldStyled data-testid={`review-changes-${id}-old-val`}>
                        <RenderFieldValue
                          value={oldVlaue}
                          isCdeField={isCdeField}
                        />
                      </FieldStyled>
                      <FieldStyled data-testid={`review-changes-${id}-new-val`}>
                        <RenderFieldValue
                          value={newValue}
                          isCdeField={isCdeField}
                        />
                      </FieldStyled>
                    </GridContentRowStyled>
                  );
                })}
              </>
            </AssetGridWrapper>
          </ConditionalDisplay>

          <AssetGridWrapper title="Tags">
            <>
              {classification?.length ? (
                classification?.map((tagItem, index) => {
                  return (
                    <GridContentRowStyled
                      className="grid-content-row"
                      isChanged={tagItem?.is_changed}
                      key={`classification-tags-review-changes${tagItem?.tagset_id}-${index}`}
                      data-testid={`classification-tags-review-changes${tagItem?.tagset_id}-${index}`}
                    >
                      <FieldStyled>{tagItem?.tagset_name}</FieldStyled>
                      <FieldStyled>
                        {tagItem?.oldValue?.map((tag, nestIndex) => (
                          <SimpleTag
                            key={`review-changes-tags-${tag?.tag_id}-${index}-${nestIndex}`}
                            className={SIMPLE_TAG_CLASS_NAME}
                          >
                            {getTermName(tag?.tag_name)}
                          </SimpleTag>
                        ))}
                      </FieldStyled>
                      <FieldStyled>
                        {tagItem?.newValue?.map((tag, nestIndex) => (
                          <SimpleTag
                            key={`review-changes-tags-${tag?.tag_id}-${nestIndex}`}
                            className={SIMPLE_TAG_CLASS_NAME}
                          >
                            {getTermName(tag?.tag_name)}
                          </SimpleTag>
                        ))}
                      </FieldStyled>
                    </GridContentRowStyled>
                  );
                })
              ) : (
                <NoChangesDetected />
              )}
            </>
          </AssetGridWrapper>

          {isBusinessRulesApplicable && (
            <AssetGridWrapper title="Business Rules">
              <>
                {!categories?.hide && (
                  <RenderRuleOfTypeList
                    title="Categories"
                    isChanged={categories?.isChanged}
                    oldVal={categories?.oldValue}
                    newVal={categories?.newValue}
                  />
                )}

                {!patterns?.hide && (
                  <RenderRuleOfTypeList
                    title="Patterns"
                    isChanged={patterns?.isChanged}
                    oldVal={patterns?.oldValue}
                    newVal={patterns?.newValue}
                  />
                )}

                {!valueRange?.hide && (
                  <GridContentRowStyled
                    className="grid-content-row"
                    isChanged={valueRange?.isChanged}
                  >
                    <FieldStyled>Value Range</FieldStyled>
                    <FieldStyled>{valueRange?.oldValue}</FieldStyled>
                    <FieldStyled>{valueRange?.newValue}</FieldStyled>
                  </GridContentRowStyled>
                )}

                {!manual?.hide && (
                  <GridContentRowStyled
                    className="grid-content-row"
                    isChanged={manual?.isChanged}
                  >
                    <FieldStyled>Manual</FieldStyled>
                    <FieldStyled>{manual?.oldValue}</FieldStyled>
                    <FieldStyled>{manual?.newValue}</FieldStyled>
                  </GridContentRowStyled>
                )}
              </>
            </AssetGridWrapper>
          )}

          {isRepresentsApplicable && (
            <AssetGridWrapper title={represenstSectionName}>
              <GridContentRowStyled
                className="grid-content-row"
                isChanged={isProvenanceChanged}
              >
                <>
                  <FieldStyled>{represenstFieldName}</FieldStyled>

                  <FieldStyled data-testid="review-changes-represents-old-val">
                    <Represents represents={provenanceOldVal} />
                  </FieldStyled>

                  <FieldStyled data-testid="review-changes-represents-new-val">
                    <Represents represents={provenenceNewVal} />
                  </FieldStyled>
                </>
              </GridContentRowStyled>
            </AssetGridWrapper>
          )}

          <AssetGridWrapper title="Relationships">
            {isAnyChangeInRelationship ? (
              <>
                {goverenedByDomainIsChanged && (
                  <RenderRelationShipList
                    assetName={nodeName}
                    isChanged={goverenedByDomainIsChanged}
                    oldVal={goverenedByDomainOldValue}
                    newVal={goverenedByDomainNewValue}
                  />
                )}
                {goverenedByPolicyIsChanged && (
                  <RenderRelationShipList
                    assetName={nodeName}
                    isChanged={goverenedByPolicyIsChanged}
                    oldVal={goverenedByPolicyOldValue}
                    newVal={goverenedByPolicyNewValue}
                  />
                )}
                {representedByGlossaryIsChanged && (
                  <RenderRelationShipList
                    assetName={nodeName}
                    isChanged={representedByGlossaryIsChanged}
                    oldVal={representedByGlossaryOldValue}
                    newVal={representedByGlossaryNewValue}
                  />
                )}
                {referencedByDictIsChanged && (
                  <RenderRelationShipList
                    assetName={nodeName}
                    isChanged={referencedByDictIsChanged}
                    oldVal={referencedByDictOldValue}
                    newVal={referencedByDictNewValue}
                  />
                )}
              </>
            ) : (
              <NoChangesDetected />
            )}
          </AssetGridWrapper>

          <AssetGridWrapper title="Additional Info">
            <>
              <AdditionalInfoRendere
                oldValues={additionalInfoTextOldValues}
                newValues={additionalInfoTextNewValues}
                title="Text"
                isChanged={additionalInfoTextIsChanged}
              />
            </>
          </AssetGridWrapper>
        </div>
      </StateHandler>
    </AssetCompareChangesPanelStyled>
  );
};
export default AssetCompareChangesPanel;
