import { Spin, AutoComplete as AutoCompleteAntd } from "antd";
import { useCallback, useRef, useState } from "react";

import { crossIcon, searchIcon1 } from "../../svgs";

import { AutoCompleteProps } from "./autocomplete.types";

import { useOutsideClick } from "../../utils/useoutsideclick";

import { LoadingSpinerStyled } from "../../styles/app.styles";
import { AutoCompleteStyled } from "./autocomplete.styles";

function AutoComplete(props: AutoCompleteProps): JSX.Element {
  const {
    isMultiple = true,
    isUseGlobalStyle = false,
    isShowIcon = false,
    icon,
    autoRef,
    singleSelect,
    clearSearch,
  } = props;

  const [open, setOpen] = useState<boolean>(true);
  const autoCompleteRef = useRef(null);

  useOutsideClick(
    autoCompleteRef,
    () => {
      setOpen(false);
      clearSearch?.();
    },
    [open]
  );

  const onClick = useCallback(() => {
    setOpen(true);
  }, [open]);

  return (
    <AutoCompleteStyled
      width={props?.width}
      marginBottom={props?.marginBottom}
      isShowIcon={isShowIcon}
      ref={autoCompleteRef}
      role="button"
      onClick={onClick}
    >
      {isShowIcon && (
        <div className="search-icon">{icon || searchIcon1("13", "13")}</div>
      )}
      <AutoCompleteAntd
        clearIcon={crossIcon("12", "12")}
        allowClear
        virtual
        notFoundContent={
          props?.status === "loading" ? (
            <LoadingSpinerStyled>
              Data is being fetched <Spin size="small" />
            </LoadingSpinerStyled>
          ) : props?.data?.length === 0 &&
            props?.searchValue &&
            (props?.status === "success" || props?.status === "idle") ? (
            <LoadingSpinerStyled>No results found</LoadingSpinerStyled>
          ) : null
        }
        dropdownClassName="search-results-dropdown"
        {...props}
        listHeight={props?.listHeight}
        options={props?.options || []}
        getPopupContainer={
          !isUseGlobalStyle
            ? (trigger): HTMLElement => trigger.parentNode as HTMLElement
            : undefined
        }
        open={isMultiple ? open : undefined}
        ref={autoRef}
        dropdownRender={(menu): JSX.Element => (
          <div>
            {singleSelect && (
              <div className="single-select-heading">
                Only 1 item can be selected
              </div>
            )}
            {menu}
          </div>
        )}
      />
    </AutoCompleteStyled>
  );
}

export default AutoComplete;
