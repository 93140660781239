import { getEnvVariables } from "../../utils";
import { ContactSupportButtonStyled } from "./contactsupportbutton.styles";

const ContactSupportButton = ({
  title = "",
}: {
  title?: string;
}): JSX.Element => {
  const { REACT_APP_CONTACT_US_EMAIL } = getEnvVariables();
  return (
    <ContactSupportButtonStyled>
      <a
        className="steward-email"
        href={`mailto:${REACT_APP_CONTACT_US_EMAIL}`}
      >
        {title || "DvSum support."}
      </a>
    </ContactSupportButtonStyled>
  );
};

export default ContactSupportButton;
