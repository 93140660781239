export const LOCAL_STORAGE_CONSTANTS = {
  access_token: "accessToken",
  username: "username",
  id_token: "idToken",
  email: "email",
  refresh_token: "refreshToken",
  exp_time_in_milli_seconds: "expTimeInMilliSeconds",
  sidebar_position: "sideBarPosition",
  caddi_sidebar_position: "caddiSideBarPosition",
  code: "code",
  post_login_response: "postLoginResponse",
  product_tour_token: "productTourToken",
  session_timeout_time: "sessionTimeoutTime",
  recent_visited_table_detail_page_tab: "recentVisitedTableDetailPageTab",
  theme: "theme",
  recent_visited_datasource_detail_page_tab:
    "recentVisitedDatasourceDetailPageTab",
  recent_visited_report_detail_page_tab: "recentVisitedReportDetailPageTab",
  global_filters: "globalFilters",
  chat_prefrences: "chatPrefrences",
  user_qrys_prefr_of_ans_output: "userQrysPrefrOfAnsOutput",
  user_clicked_on_skip_onb: "userClickedOnSkipOnb",
  list_of_gateways: "listOfGateways",
  next_gateway_check_time: "nextGatewayCheckTime",
  user_expanded_grids: "userExpandedGrids",
  assets_configurations: "assetsConfigurations",

  show_confirmation_popover: "showConfirmationPopover",
  recent_visited_column_detail_page_tab: "recentVisitedColumnDetailPageTab",
  recent_visited_rule_detail_page_view: "recentVisitedRuleDetailPageView",
  recent_visited_rule_detail_page_tab: "recentVisitedRuleDetailPageTab",
  recent_visited_ref_dict_detail_page_view:
    "recentVisitedRefDictDetailPageView",

  recent_visited_etl_detail_page_view: "recentVisitedEtlDetailPageView",
};
