import { LOCAL_STORAGE_CONSTANTS } from "../constants";
import { checkIsFDAMode } from "./isfdamode";

export const setAuthenticationInfoInLocalStorage = ({
  accessToken = "",
  email = "",
  expTime = 0,
  idToken = "",
  refreshToken = "",
  username = "",
}: {
  accessToken?: string;
  username?: string;
  idToken?: string;
  refreshToken?: string;
  email?: string;
  expTime?: number;
}): void => {
  const isFDAMode = checkIsFDAMode();

  accessToken &&
    localStorage.setItem(LOCAL_STORAGE_CONSTANTS?.access_token, accessToken);

  username && localStorage.setItem(LOCAL_STORAGE_CONSTANTS?.username, username);

  idToken && localStorage.setItem(LOCAL_STORAGE_CONSTANTS?.id_token, idToken);

  email && localStorage.setItem(LOCAL_STORAGE_CONSTANTS?.email, email);

  refreshToken &&
    localStorage.setItem(LOCAL_STORAGE_CONSTANTS?.refresh_token, refreshToken);

  expTime &&
    localStorage.setItem(
      LOCAL_STORAGE_CONSTANTS?.exp_time_in_milli_seconds,
      // In case of direct integration with Cognito Api return time in seconds
      isFDAMode
        ? `${new Date().setSeconds(expTime) - 60000}`
        : `${new Date(0).setSeconds(expTime) - 60000}`
    );
};
