import { useCallback, useState, useEffect } from "react";

import Represents from "../../represents";
import SectionBlankSlateText from "../../sectionblankslatetext";
import SectionTitleWithIcon from "../../sectiontitlewithicon";

import {
  DataItemStyled,
  SectionWithEditableSourcesStyled,
} from "./sectionwitheditablesources.styles";
import {
  ItemProps,
  SectionWithEditableSourcesProps,
} from "./sectionwitheditablesources.types";

import { AssetFieldIds } from "../../../parsers/asssetoverviewparser";
import { AssetSelectionField } from "../../assetselectionfield";

function EditableViewOfItem(props: ItemProps): JSX.Element {
  const {
    state,
    setState,
    apiKey = "get_node_parental_info_by_node_type",
    urlParam = [],
    placeHolder = "Select one or more tables",
    placeHolderForSearch = "Search for tables",
  } = props;

  const onRemove = useCallback(
    (id) => {
      setState((st) => ({
        ...st,
        data: st.data.map((item) => ({
          ...item,
          sources: item?.sources
            ? item.sources.filter((source) =>
                source?.isTblSelection
                  ? source.tblId !== id
                  : source?.colId !== id
              )
            : undefined,
        })),
      }));
    },
    [state]
  );

  const addedIds = props?.sources?.map((item) =>
    item?.isTblSelection ? item?.tblId : item?.colId
  );

  const onSelect = useCallback((id, selObj) => {
    setState((st) => ({
      ...st,
      data: st.data.map((item) => {
        const isScanFreqField = item?.id === AssetFieldIds.scanFrequency;
        return {
          ...item,
          sources: !isScanFreqField ? [selObj, ...(item?.sources ?? [])] : [],
        };
      }),
    }));
  }, []);

  return (
    <div>
      <AssetSelectionField
        addedIds={addedIds}
        apiKey={apiKey}
        urlParam={urlParam}
        onSelect={onSelect}
        placeHolder={placeHolder}
        placeHolderForSearch={placeHolderForSearch}
      />
      <SectionBlankSlateText isEmpty={!props?.sources?.length}>
        <Represents represents={props?.sources} onRemove={onRemove} />
      </SectionBlankSlateText>
    </div>
  );
}

function Item(props: ItemProps): JSX.Element {
  const isValueExists = Boolean(props?.value);

  const isSrcExists = props?.sources;

  const isScanFreqField = props?.id === AssetFieldIds.scanFrequency;

  return (
    <DataItemStyled hasTitle={!!props?.title} isRefView={props?.isRefView}>
      <div className="section-main-row">
        {!props?.isEditable ? (
          <>
            {props.title && (
              <div
                className={`section-main-row-title ${
                  props?.isLineageField ? "lineage-field-title" : ""
                }`}
              >
                {props.title}:
              </div>
            )}
            <div
              className={`section-main-row-value ${
                props?.isLineageField ? "lineage-field-section" : ""
              }`}
            >
              {isSrcExists && !isScanFreqField ? (
                <SectionBlankSlateText
                  isEmpty={!props?.sources?.length}
                  position={props?.title ? "flex-start" : "center"}
                  containMinHeight={!props?.title}
                >
                  <Represents represents={props?.sources} hasTitle />
                </SectionBlankSlateText>
              ) : isValueExists ? (
                <div>{props.value}</div>
              ) : (
                <div>--</div>
              )}
            </div>
          </>
        ) : (
          <>
            {props.title && (
              <div className="section-main-row-title">{props.title}:</div>
            )}
            <div className="section-main-row-value">
              <EditableViewOfItem {...props} />
            </div>
          </>
        )}
      </div>
    </DataItemStyled>
  );
}

function SectionWithEditableSources(
  props: SectionWithEditableSourcesProps
): JSX.Element {
  const { secTitle, state, setState, isRefView = false } = props;
  const [localState, setLocalState] = useState<
    SectionWithEditableSourcesProps["state"]
  >(state);

  const onEdit = useCallback(() => {
    setState({ ...state, isEditable: true });
    setLocalState((st) => ({ ...st, isEditable: !st?.isEditable }));
  }, [localState, state]);

  const onSave = useCallback(() => {
    setState({ ...localState, isEditable: false, isEdited: true });
    setLocalState((st) => ({ ...st, isEditable: false, isEdited: true }));
  }, [localState, state]);

  const onCancel = useCallback(() => {
    setState({ ...state, isEditable: false });
    setLocalState((st) => ({ ...st, isEditable: false }));
  }, [localState, state]);

  useEffect(() => {
    setLocalState(state);
  }, [state]);

  return (
    <div className="asset-edidatble-sources-section">
      <SectionTitleWithIcon
        {...secTitle}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={onSave}
        isEditable={localState?.isEditable}
      />
      <SectionWithEditableSourcesStyled
        isEditable={localState?.isEditable}
        className="asset-lineage-section"
      >
        {localState?.data?.map((item) => (
          <Item
            {...item}
            isEditable={localState?.isEditable && item?.isEditable}
            state={localState}
            setState={setLocalState}
            key={`sectionwitheditablesource-${item?.title}`}
            isRefView={isRefView}
          />
        ))}
      </SectionWithEditableSourcesStyled>
    </div>
  );
}
export default SectionWithEditableSources;
