import {
  CheckboxField,
  InputField,
  SelectField,
  TextAreaField,
} from "../formfields";

import { selectFilterOption } from "../../utils";

import ReplacesSelect from "../replacesselect";
import { GetInputfieldPropsType } from "./desceditableview.types";

export const getInputField = (props: GetInputfieldPropsType): JSX.Element => {
  const {
    fieldItem,
    control,
    setValue,
    selectOptions,
    isOptionsLoading,
    propsOnSelect,
    alreadySelectedIds,
    selectPlaceholder = "Select Option",
  } = props;
  // const { control } = useFormContext() || {};
  // console.log("getInputField formContext:", useFormContext());

  const { type, id } = fieldItem || {};

  switch (type) {
    case "input":
      return (
        <InputField
          control={control}
          name={id}
          width="100%"
          isMessagePositionAbsolute
        />
      );

    case "textarea":
      return (
        <TextAreaField
          control={control}
          name={id}
          width="545px"
          height="134px"
          isMessagePositionAbsolute
        />
      );

    case "select":
      return (
        <SelectField
          control={control}
          name={id}
          width="380px"
          options={selectOptions || []}
          setValue={setValue}
          showSearch
          filterOption={selectFilterOption}
          placeholder={selectPlaceholder}
          allowClear
          isAllowClear={false}
          loading={isOptionsLoading}
          disabled={isOptionsLoading}
          propOnChange={propsOnSelect}
        />
      );

    case "checkbox":
      return <CheckboxField control={control} name={id} />;

    case "autocomplete":
      return (
        <ReplacesSelect
          propsOnSelect={propsOnSelect}
          alreadyAddedIds={alreadySelectedIds}
        />
      );

    default:
      return <div />;
  }
};
