import { CheckOutlined } from "@ant-design/icons";
import { WizardItemStyled, WizardStyled } from "./wizard.styles";
import { WizardItemProps, WizardProps } from "./wizard.types";

function WizardItem(props: WizardItemProps): JSX.Element {
  const { currentPage, title, done, isLastItem, renderDescription } = props;

  return (
    <>
      <WizardItemStyled {...props}>
        <div className="wizard-item-main-content">
          <div
            data-testid={`wizard-item-outer-circle-${currentPage}`}
            className="outer-circle"
          >
            <div
              data-testid={`wizard-item-inner-circle-${currentPage}`}
              className="inner-circle"
            >
              {done ? (
                <span data-testid={`wizard-item-check-mark-${currentPage}`}>
                  <CheckOutlined />
                </span>
              ) : (
                <span data-testid={`wizard-item-current-page-${currentPage}`}>
                  {currentPage}
                </span>
              )}
            </div>
          </div>
          <div className="wizard-item-wrapper">
            <div
              data-testid={`wizard-item-title-${currentPage}`}
              className="title"
            >
              {title}
            </div>
          </div>
          {!isLastItem && <div className="seperation-line" />}
        </div>

        {typeof renderDescription === "function" && (
          <div
            className={`${title?.toLocaleLowerCase()} wizard-item-meta-data`}
          >
            {renderDescription?.(title)}
          </div>
        )}
      </WizardItemStyled>
    </>
  );
}

function Wizard({
  listOfPages,
  currentPageNumber,
  isLastDone = false,
  renderDescription,
}: WizardProps): JSX.Element {
  return (
    <WizardStyled data-testid="wizard-wrapper">
      {listOfPages.map((item, index) => {
        const isLastStepAndComplete =
          index + 1 === listOfPages.length && !!isLastDone;
        return (
          <WizardItem
            data-testid={`wizard-item-${item + index}`}
            title={item}
            currentPage={index + 1}
            inProgress={
              isLastStepAndComplete ? false : currentPageNumber === index + 1
            }
            renderDescription={renderDescription}
            todo={currentPageNumber < listOfPages.length}
            done={index + 1 < currentPageNumber || isLastStepAndComplete}
            isLastItem={listOfPages.length === index + 1}
            key={`${item}${index}`}
          />
        );
      })}
    </WizardStyled>
  );
}

export default Wizard;
