import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { Popover, Tooltip } from "antd";

import { useHistory } from "react-router";

import { UsabilityScoreType } from "../../pages/tablepage/views/overview/overview.types";
import {
  antiClockIcon,
  chevronRight,
  eyeIcon,
  roundIcon,
  settingsIcon,
  termIcon,
  tickIconGreen,
  workflowIcon,
} from "../../svgs";

import DQScore from "../dqscore";
import { DQScoreProps } from "../dqscore/dqscore.types";

import LinkButton from "../linkbutton";
import {
  DetailPageRowItem,
  DetailPageSectionRowProps,
  SectionRowProps,
} from "../sectionrow";

import {
  AssetAuditTrailStyled,
  AssetLastUpdatedWrapperStyled,
  AssetReplacesRndrerStyled,
  AssetReportPreviewStyled,
  AssetUrlPreviewStyled,
  AssetUsabilityScoreContentStyled,
  AssetUsabilityScoreRenderStyled,
  AssetVisualDistributionStyled,
  MultipleAssetRendererStyled,
  NodeNameRendererStyled,
  RunResultRendererStyled,
  SourceNameRendererStyled,
  WorkflowStatusRendererStyled,
  RelationshipRendererStyled,
  EtlParentRendererStyled,
  AssetNameStyled,
  SubDomainRendererStyled,
  ColProfilingRendererStyled,
} from "./assetoverview.styles";

import {
  checkDataTypeOfColumn,
  convertToHoursMinutesAndSeconds,
  getDataTypeIcon,
  getDetailpageUrlBasedOnNodeType,
  getDrawerIdBasedOnNodeType,
  getTimeDifference,
  numberFormatter,
  sortObjectsArrayByKey,
  utcTOLocalTimeZone,
} from "../../utils";

import LinkButtonToOpenRefView from "../linkbuttontoopenrefview";
import {
  AssetFieldIds,
  NodeParsedType,
} from "../../parsers/asssetoverviewparser";

import IsCDEField from "../iscdefield";

import { useOpenDrawer } from "../../customhooks";

import { ColumnDataType } from "../../parsers";
import { useOutsideClick } from "../../utils/useoutsideclick";

import { BarChart, ColumnChart, LineChart } from "../recharts";
import { LinkCollapseStyled } from "../collapse/linkcollapse/linkcollapse.styles";

import IconWithSelectedState from "../iconselectedstate";
import { COLUMN_VISUAL_DIST_DATA_KEY } from "../../parsers/columnparsers";

import {
  NON_ASSET_ITEMS,
  Y_AXIS_DATA_KEY,
  MONTH_DAY_YEAR_HOUR_MINUTE,
  MONTH_NAME_DAY_YEAR_HOUR_MIN_SEC,
  DVSUM_TOOLTIP_CLASS_NAME,
  WORKFLOW_TOOLTIP_LABEL,
  ELEMENT_IDS,
} from "../../constants";
import ProfilingCountProgressbar from "../profilingcountprogressbar";

import CoreNodesCircleIcon from "../corenodetypescircleicon";
import StyledLink from "../styledlink/styledlink";

import { NodeSubType, NodeType } from "../../app.types";
import { SingleTagRendrer } from "../prominenttagslist/prominenttagslist";
import Flex from "../flex";

import {
  getRuleRunStatusIcon,
  getRuleRunStatusLabel,
} from "../../utils/getrulerunstatusicon";

import { RunStatusId } from "../../parsers/ruleparser/ruleparser.types";
import { getDataSourceLogoSvgByType } from "../../utils/getsourceiconsbytype";

import { useAssetRelationshipContext } from "../../contexts/assetrelationshipscontext";

import { NonAssetItem } from "../sectionwitheditables/sectionwitheditablerelationships/sectionwitheditablerelationships.components/assetrelationshipgrid/assetrelationshipgrids.styles";
import { DomainSubdomainListReturnType } from "../../parsers/glossarydomains/glossarydomainsparser.types";
import Checkbox from "../checkbox";

const { Panel } = LinkCollapseStyled;
const workflow = workflowIcon("15", "15");

export const NoDataExistsRenderer = (): JSX.Element => <div>--</div>;

export const OverAllScoreRendrer = ({
  item,
}: {
  item?: DetailPageSectionRowProps;
}): JSX.Element => {
  const additionalInfo = {
    score: item?.score,
    statusId: item?.statusId,
    status: item?.status,
    trend: item?.trend,
  } as DQScoreProps;

  return <DQScore {...additionalInfo} showEmptyState />;
};

export const AuditTrailRendrer = ({
  item,
}: {
  item?: SectionRowProps;
}): JSX.Element => {
  const splittedValue = item?.value?.split(":") || [];
  return (
    <AssetAuditTrailStyled>
      {splittedValue?.map((item, index) => (
        <div
          className="trail-item"
          key={`tablepage-usability-audit-trail-item-${index}`}
        >
          {item}
        </div>
      ))}
    </AssetAuditTrailStyled>
  );
};

export function UsabilityScoreContent(
  title: string,
  usabilityScore?: UsabilityScoreType,
  isSimpleView?: boolean,
  onClick?: () => void,
  isEditor?: boolean
): JSX.Element {
  const { data = [], obtainedScore, totalScore } = usabilityScore || {};

  const sec1 = data?.filter((item) => item?.isLyinginSec1);

  const sec2 = data?.filter((item) => !item?.isLyinginSec1);

  return (
    <AssetUsabilityScoreContentStyled isPlainView={isSimpleView}>
      <div className="usabilityscore-popover-header">
        {title || "Improve the usability of dataset"}
        {!isSimpleView && isEditor && onClick && (
          <LinkButton
            fontFamily="OpenSansSemibold"
            id="secondary"
            onClick={(): void => {
              onClick && onClick();
            }}
          >
            Enrich
          </LinkButton>
        )}
      </div>
      <div className="usabilityscore-popover-body">
        {isSimpleView && (
          <div className="usability-score-sec">
            <span>Usability Score</span>
            <span className="usability-obtained-score-sec">
              <span className="obtained-score">{obtainedScore}</span>/
              {totalScore}
            </span>
          </div>
        )}
        <div className="usability-static-text">Includes essential metadata</div>
        {sec1.map((item, index) => (
          <div
            className="usability-score-item"
            key={`tablepage-usability-sec1-score-item-${index}`}
          >
            {item.applied ? tickIconGreen() : roundIcon()}
            <span className={item.applied ? "" : "applied"}>{item.title}</span>
          </div>
        ))}
        <div className="usability-static-text">
          Assurances the dataset is maintained
        </div>
        {sec2.map((item, index) => (
          <div
            className="usability-score-item"
            key={`tablepage-usability-sec2-score-item-${index}`}
          >
            {item.applied ? tickIconGreen() : roundIcon()}
            <span className={item.applied ? "" : "applied"}>{item.title}</span>
          </div>
        ))}
      </div>
    </AssetUsabilityScoreContentStyled>
  );
}

export function UsabilityScoreRender(additionalInfo?: {
  usabilityScore?: UsabilityScoreType;
  onEditOverview?: () => void;
  isEditor?: boolean;
}): JSX.Element {
  const { obtainedScore, totalScore } = additionalInfo?.usabilityScore || {};
  return (
    <AssetUsabilityScoreRenderStyled>
      <Popover
        content={UsabilityScoreContent(
          "Improve the usability of dataset",
          additionalInfo?.usabilityScore,
          undefined,
          additionalInfo?.onEditOverview,
          additionalInfo?.isEditor
        )}
        trigger={["click"]}
        overlayClassName="usability-score-popover"
        placement="bottomLeft"
        getPopupContainer={(trigger): HTMLElement =>
          trigger?.parentNode as HTMLElement
        }
      >
        <span className="usability-score-value">
          <span className="obtained-score">{obtainedScore}</span>/{totalScore}
          {eyeIcon("16", "11.2")}
        </span>
      </Popover>
    </AssetUsabilityScoreRenderStyled>
  );
}

export const DateWithMonthDayYearHrMinRenderer = (
  item: SectionRowProps
): JSX.Element => {
  return (
    <AssetLastUpdatedWrapperStyled>
      {item?.value ? (
        utcTOLocalTimeZone(item?.value, MONTH_DAY_YEAR_HOUR_MINUTE)
      ) : (
        <NoDataExistsRenderer />
      )}
    </AssetLastUpdatedWrapperStyled>
  );
};

export const DateWithMonthNameDayYearHrMinSecRenderer = (
  item: SectionRowProps
): JSX.Element => {
  return (
    <AssetLastUpdatedWrapperStyled>
      {item?.value ? (
        utcTOLocalTimeZone(item?.value, MONTH_NAME_DAY_YEAR_HOUR_MIN_SEC)
      ) : (
        <NoDataExistsRenderer />
      )}
    </AssetLastUpdatedWrapperStyled>
  );
};

export const LastUpdatedRendrer = ({
  item,
}: {
  item?: SectionRowProps;
}): JSX.Element => {
  return (
    <AssetLastUpdatedWrapperStyled>
      {item?.value
        ? `Last updated ${getTimeDifference(
            utcTOLocalTimeZone(item?.value || "")
          )}`
        : ""}
    </AssetLastUpdatedWrapperStyled>
  );
};

export const OverviewEntityRendrer = (item: SectionRowProps): JSX.Element => {
  return item?.value ? (
    <LinkButtonToOpenRefView
      nodeId={item?.selectedValues?.[0] || ""}
      nodeType="TRM"
    >
      {item?.value}
    </LinkButtonToOpenRefView>
  ) : (
    <NoDataExistsRenderer />
  );
};

const IsCdeFieldRenderer = (item: SectionRowProps): JSX.Element => {
  return <IsCDEField value={item?.value ? "Yes" : ""} />;
};

const CheckBoxValueRenderer = (item: SectionRowProps): JSX.Element => {
  const isChecked = !!item?.value;

  return <Checkbox value="abc" checked={isChecked} disabled />;
};

const NodeUrlRenderer = (item: SectionRowProps): JSX.Element => {
  return (
    <AssetUrlPreviewStyled>
      <LinkButton
        href={item?.value}
        target="_blank"
        showEllipsis
        title={item?.value}
        isTextUnderLine
        paddingRight="25px"
      >
        {item?.value}
      </LinkButton>
    </AssetUrlPreviewStyled>
  );
};

const ImagePreviewRendrer = (item: SectionRowProps): JSX.Element => {
  return (
    <AssetReportPreviewStyled>
      {item?.value ? <img src={item?.value} /> : <NoDataExistsRenderer />}
    </AssetReportPreviewStyled>
  );
};

const DataTypeRenderer = (item: DetailPageSectionRowProps): JSX.Element => {
  return getDataTypeIcon(item?.idValue as ColumnDataType);
};

const NodeIcon = ({ nodeType }: { nodeType: NodeType }): JSX.Element => (
  <CoreNodesCircleIcon
    nodeType={nodeType}
    width="15px"
    height="15px"
    fontSize="8px"
  />
);

export const getSubdomainValue = (
  categoryInfo?: DomainSubdomainListReturnType,
  addTooltip?: boolean
): JSX.Element => {
  const {
    isWorkflowEnabled,
    categoryName = "",
    domainName = "",
    isWorkflowInherited,
  } = categoryInfo || {};

  const dmnNodeType = "DMN" as NodeType;
  const catNodeType = "CAT" as NodeType;

  const isSubDomainHasWorkflow = isWorkflowEnabled || isWorkflowInherited;

  return (
    <SubDomainRendererStyled>
      <NodeIcon nodeType={dmnNodeType} />
      {domainName}
      {isWorkflowEnabled && (
        <Tooltip
          overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
          title={addTooltip ? WORKFLOW_TOOLTIP_LABEL : ""}
          placement="right"
        >
          <span className="workfloww-icon">{workflow}</span>
        </Tooltip>
      )}
      <div className="right-icon">{chevronRight}</div>
      <NodeIcon nodeType={catNodeType} />
      {categoryName}
      {isSubDomainHasWorkflow && (
        <Tooltip
          overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
          title={addTooltip ? WORKFLOW_TOOLTIP_LABEL : ""}
          placement="right"
        >
          <span className="workfloww-icon">{workflow}</span>
        </Tooltip>
      )}
    </SubDomainRendererStyled>
  );
};

const SubDomainRenderer = (item: DetailPageRowItem): JSX.Element => {
  const { selectedCategoryInfo } = item || {};

  return getSubdomainValue(selectedCategoryInfo);
};

const ReplaceRendrer = (item: DetailPageSectionRowProps): JSX.Element => {
  const openDrawer = useOpenDrawer();

  const onClick = useCallback(
    (id) => {
      openDrawer({
        drawerId: "term_ref",
        visible: true,
        drawerProps: { id },
      });
    },
    [item]
  );
  return (
    <AssetReplacesRndrerStyled>
      {item?.replaces?.map((item) => (
        <div
          className="replace-rendrer"
          key={`${item?.id}-term-replace-rendrer`}
        >
          {termIcon("11", "11")}
          <span role="button" onClick={(): void => onClick(item?.id)}>
            {item?.name}
          </span>
        </div>
      ))}
    </AssetReplacesRndrerStyled>
  );
};

function VisualDistributuion(item: DetailPageSectionRowProps): JSX.Element {
  const { visualDistributionChartData = [], colDataTypeId = "BOL" } =
    item || {};

  const { isNumberCol, isDateColumn } = checkDataTypeOfColumn(colDataTypeId);

  const isEmpty = !visualDistributionChartData?.length;

  const [selected, setSelected] = useState<boolean>(!isEmpty);
  const recordCoutRef = useRef(null);

  useOutsideClick(
    recordCoutRef,
    () => {
      setSelected(false);
    },
    [selected]
  );

  const chartHeight = visualDistributionChartData?.length * 60;

  const ChartComp = isDateColumn
    ? LineChart
    : isNumberCol
    ? ColumnChart
    : BarChart;

  const isBarChart = !isDateColumn && !isNumberCol;

  useEffect(() => {
    setSelected(!isEmpty);
  }, [isEmpty]);

  return (
    <AssetVisualDistributionStyled ref={recordCoutRef}>
      <div className="main-row">
        <LinkCollapseStyled activeKey={selected ? ["record-count"] : []}>
          <Panel
            key="record-count"
            header={
              <>
                <div className="row-title">{item?.title}:</div>
                <div className="row-value">
                  {item?.value}
                  <IconWithSelectedState
                    selected={selected}
                    onClick={(): void => {
                      !isEmpty && setSelected(!selected);
                    }}
                    tooltipProps={{
                      title: isEmpty
                        ? "No data to show"
                        : `${selected ? "Hide" : "Show"} visual distribution`,
                      placement: "right",
                    }}
                    disabled={isEmpty}
                  >
                    {antiClockIcon()}
                  </IconWithSelectedState>
                </div>
              </>
            }
            showArrow={false}
          >
            <ChartComp
              height={
                isBarChart ? (chartHeight < 300 ? 300 : chartHeight) : 350
              }
              width="100%"
              data={visualDistributionChartData}
              dataKeys={[
                { dataKey: COLUMN_VISUAL_DIST_DATA_KEY, fill: "#4894ca" },
              ]}
              yAxis={[
                {
                  yAxisId: Y_AXIS_DATA_KEY,
                  label: { value: "" },
                  orientation: "left",
                  notApplyFormatting: true,
                },
              ]}
              showLedgends={false}
              margin={{ top: 40, bottom: 0 }}
              keepStaicBarSize={false}
              // customLabel
            />
          </Panel>
        </LinkCollapseStyled>
      </div>
    </AssetVisualDistributionStyled>
  );
}

const CountRenderer = (item: DetailPageSectionRowProps): JSX.Element => {
  return (
    <ColProfilingRendererStyled className="col-ref-profiling-renderer">
      <ProfilingCountProgressbar
        data={item?.profillingInfo || []}
        width="75%"
        minThreshhold={10}
      />
    </ColProfilingRendererStyled>
  );
};

const RecordCountRenderer = (item: DetailPageSectionRowProps): JSX.Element => {
  const { value = "" } = item || {};

  return (
    <div className="tbl-ref-profiling-renderer">
      {value ? numberFormatter(value) : <NoDataExistsRenderer />}
    </div>
  );
};

const ValueRenderer = (item: DetailPageSectionRowProps): JSX.Element => {
  const { value = "" } = item || {};
  return value ? <div>{value}</div> : <NoDataExistsRenderer />;
};

const SourceNameRenderer = (item: DetailPageSectionRowProps): JSX.Element => {
  const { srcTypeId = "ORC", value, idValue = "" } = item || {};
  const srcIcon = getDataSourceLogoSvgByType(srcTypeId);

  const toUrl = getDetailpageUrlBasedOnNodeType(idValue, "SRC");

  return (
    <SourceNameRendererStyled>
      <div className="icon">{srcIcon}</div>
      <StyledLink to={toUrl}>{value}</StyledLink>
    </SourceNameRendererStyled>
  );
};

const NodeRenderer = (item: DetailPageSectionRowProps): JSX.Element => {
  const { nodeType, value = "", idValue = "", isFromDetailPage = false } =
    item || {};

  const openDrawer = useOpenDrawer();

  const onOpenDrawer = useCallback(() => {
    openDrawer({
      drawerId: nodeType === "TBL" ? "table_ref" : "column_ref",
      visible: true,
      drawerProps: {
        id: idValue,
        nodeType,
      },
    });
  }, [nodeType, idValue]);

  const toUrl = nodeType
    ? getDetailpageUrlBasedOnNodeType(idValue, nodeType)
    : "";

  return value ? (
    <NodeNameRendererStyled>
      {nodeType && (
        <CoreNodesCircleIcon width="20px" height="20px" nodeType={nodeType} />
      )}
      {idValue ? (
        <StyledLink
          to={isFromDetailPage ? "" : toUrl}
          {...(isFromDetailPage && {
            propsOnClick: onOpenDrawer,
          })}
        >
          {value}
        </StyledLink>
      ) : (
        <div>{value}</div>
      )}
    </NodeNameRendererStyled>
  ) : (
    <NoDataExistsRenderer />
  );
};

const MultipleNodeRenderer = ({
  data,
  isFromDetailPage,
}: {
  data: NodeParsedType[];
  isFromDetailPage: boolean;
}): JSX.Element => {
  const openDrawer = useOpenDrawer();

  const onOpenDrawer = useCallback((nodeId, nodeType) => {
    const drawerId = getDrawerIdBasedOnNodeType(nodeId, nodeType);
    openDrawer({
      drawerId,
      visible: true,
      drawerProps: {
        id: nodeId,
        nodeType,
      },
    });
  }, []);

  const getNodeUrl = useCallback((nodeId, nodeType) => {
    const url = getDetailpageUrlBasedOnNodeType(nodeId, nodeType);
    return url || "";
  }, []);

  return (
    <MultipleAssetRendererStyled>
      {data?.length ? (
        data?.map((field) => {
          const { nodeId = "", nodeName = "", nodeType, nodeSubType } =
            field || {};

          const url = getNodeUrl(nodeId, nodeType);

          return (
            <AssetNameStyled key={`field-${nodeId}`}>
              <CoreNodesCircleIcon
                width="20px"
                height="20px"
                nodeType={nodeType}
                nodeSubType={nodeSubType}
                fontSize="12px"
              />
              <LinkButton>
                <StyledLink
                  to={isFromDetailPage ? "" : url}
                  {...(isFromDetailPage && {
                    propsOnClick: (): void => onOpenDrawer(nodeId, nodeType),
                  })}
                >
                  {nodeName}
                </StyledLink>
              </LinkButton>
            </AssetNameStyled>
          );
        })
      ) : (
        <NoDataExistsRenderer />
      )}
    </MultipleAssetRendererStyled>
  );
};

const ParsedNodesRenderer = (item: DetailPageSectionRowProps): JSX.Element => {
  const { parsedNodes = [], isFromDetailPage = false } = item || {};

  return (
    <MultipleNodeRenderer
      data={parsedNodes}
      isFromDetailPage={isFromDetailPage}
    />
  );
};

const DQScoreRenderer = (item: SectionRowProps): JSX.Element => {
  const { value = "" } = item || {};

  return value ? <div>{value}%</div> : <NoDataExistsRenderer />;
};

const EtlParentRenderer = (item: SectionRowProps): JSX.Element => {
  const { value } = item || {};

  return value ? (
    <EtlParentRendererStyled>
      <span className="etl-icon">
        <CoreNodesCircleIcon
          nodeType="PLO"
          width="20px"
          height="20px"
          fontSize="10px"
        />
      </span>
      <div className="etl-parent-name">{value}</div>
    </EtlParentRendererStyled>
  ) : (
    <NoDataExistsRenderer />
  );
};

const WorkflowStatusRenderer = (
  item: DetailPageSectionRowProps
): JSX.Element => {
  return (
    <WorkflowStatusRendererStyled>
      {item?.value ? (
        <Flex columnGap={5}>
          <SingleTagRendrer
            tag={{
              name: item?.value === "ACK" ? "Acknowledged" : "Resolved",
              type: item?.value === "ACK" ? "LBL" : "LGR",
            }}
            isBordered
          />

          <div className="assigned-to">By {item?.actionPerformedBy}</div>
        </Flex>
      ) : (
        <span>Assigned to {item?.actionPerformedBy} </span>
      )}
    </WorkflowStatusRendererStyled>
  );
};

const RunResultRenderer = (item: DetailPageSectionRowProps): JSX.Element => {
  const statusIcon = getRuleRunStatusIcon(item?.value as RunStatusId);
  const statusLabel = getRuleRunStatusLabel(item?.value as RunStatusId);
  const exceptionCount = item?.exceptionCount || 0;

  return (
    <RunResultRendererStyled>
      <Flex columnGap={5}>
        {statusIcon}
        {item?.value === "EXP" && !!exceptionCount && (
          <span className="exceptions-count">
            {numberFormatter(`${exceptionCount}`)}
          </span>
        )}
        <span className="run-status-label">
          {statusLabel}
          {item?.value === "EXP" ? "(s)" : ""}
        </span>
      </Flex>
    </RunResultRendererStyled>
  );
};
export const RelationshipSummaryRenderer = (): JSX.Element => {
  const {
    asset_relationship_summary: ASSET_RELATIONSHIP_SUMMARY,
  } = ELEMENT_IDS;

  const {
    state,
    updateFilterState,
    relationshipsRef,
  } = useAssetRelationshipContext();
  const {
    data: assetRelationshipsData,
    nodeTitle,
    nodeType,
    nodeId,
    isRefPage,
  } = state;
  const history = useHistory();

  const relationshipsList = useMemo(() => {
    return assetRelationshipsData?.map((relationshipItem) => {
      const { relationshipType, assetType, rows = [] } = relationshipItem;
      const sortedRows = sortObjectsArrayByKey(rows, "name", true);
      const firstAssetName = sortedRows?.[0]?.name || "";
      const extraCount =
        sortedRows?.length > 1 ? `, +${sortedRows.length - 1}` : "";
      const nodeType = sortedRows[0]?.nodeType;
      const nodeSubType = sortedRows[0]?.nodeSubType;
      const asseId = sortedRows[0]?.id;

      return {
        relationshipType,
        assetType,
        displayText: `${nodeTitle} ${relationshipType} ${assetType}${
          extraCount && "s"
        }`,
        assetName: firstAssetName,
        extraCount,
        asseId,
        nodeType,
        nodeSubType,
        rows: sortedRows,
      };
    });
  }, [assetRelationshipsData]);

  const onExtraCountClick = useCallback(
    (assetType: string) => {
      const assetUrl = getDetailpageUrlBasedOnNodeType(nodeId, nodeType);

      if (isRefPage) {
        history.push({
          pathname: assetUrl,
          search: `scroll=${true}`,
        });
      }

      if (relationshipsRef?.current) {
        const rect = relationshipsRef?.current?.getBoundingClientRect();
        const offset = 150;
        const scrollPosition = window?.scrollY + rect?.top - offset;

        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    },
    [history, isRefPage]
  );

  if (!relationshipsList?.length) return <></>;

  return (
    <RelationshipRendererStyled>
      <div className="relationship-list" id={ASSET_RELATIONSHIP_SUMMARY}>
        This section provides a summary of all relationships:
        <ul>
          {relationshipsList?.map((relation, index) => {
            const assetUrl = getDetailpageUrlBasedOnNodeType(
              relation?.asseId,
              relation?.nodeType,
              relation?.nodeSubType as NodeSubType
            );
            const isNonAssetItem = NON_ASSET_ITEMS.includes(relation?.nodeType);
            return (
              relation?.rows?.length > 0 && (
                <li key={index}>
                  {relation?.displayText}{" "}
                  <span className="asset-name">
                    (
                    {isNonAssetItem ? (
                      <NonAssetItem>{relation?.assetName}</NonAssetItem>
                    ) : (
                      <StyledLink to={assetUrl}>
                        {relation?.assetName}
                      </StyledLink>
                    )}
                    {relation?.extraCount && (
                      <span
                        className="extra-count"
                        role="button"
                        onClick={(): void =>
                          onExtraCountClick(relation?.assetType)
                        }
                      >
                        {relation?.extraCount}
                      </span>
                    )}
                    )
                  </span>
                </li>
              )
            );
          })}
        </ul>
      </div>
    </RelationshipRendererStyled>
  );
};

const DurationRenderer = (item: SectionRowProps): JSX.Element => {
  const { value = "" } = item || {};

  return (
    <div>
      {value ? (
        convertToHoursMinutesAndSeconds(Number(value))
      ) : (
        <NoDataExistsRenderer />
      )}
    </div>
  );
};

export const assetOverviewRenderer = (
  item: SectionRowProps
): { [key: string]: () => JSX.Element } => {
  return {
    [AssetFieldIds.auditTrail]: (): JSX.Element => (
      <AuditTrailRendrer item={item} />
    ),
    [AssetFieldIds.overAllScore]: (): JSX.Element => (
      <OverAllScoreRendrer item={item} />
    ),
    [AssetFieldIds.usabilityScore]: (): JSX.Element => UsabilityScoreRender({}),

    [AssetFieldIds.entity]: (): JSX.Element => (
      <OverviewEntityRendrer {...item} />
    ),

    [AssetFieldIds.isCDE]: (): JSX.Element => <IsCdeFieldRenderer {...item} />,

    [AssetFieldIds.isKeyValuePair]: (): JSX.Element => (
      <CheckBoxValueRenderer {...item} />
    ),

    [AssetFieldIds.isHierarchial]: (): JSX.Element => (
      <CheckBoxValueRenderer {...item} />
    ),
    [AssetFieldIds.dataType]: (): JSX.Element => <DataTypeRenderer {...item} />,

    [AssetFieldIds.subDomain]: (): JSX.Element => (
      <SubDomainRenderer {...item} />
    ),

    [AssetFieldIds.imagePreview]: (): JSX.Element => (
      <ImagePreviewRendrer {...item} />
    ),

    [AssetFieldIds.replaces]: (): JSX.Element => <ReplaceRendrer {...item} />,

    [AssetFieldIds.replacedBy]: (): JSX.Element => <ReplaceRendrer {...item} />,

    [AssetFieldIds.nodeUrl]: (): JSX.Element => <NodeUrlRenderer {...item} />,

    [AssetFieldIds.lastRefreshedOn]: (): JSX.Element => (
      <DateWithMonthDayYearHrMinRenderer {...item} />
    ),

    [AssetFieldIds.lastRun]: (): JSX.Element => (
      <DateWithMonthDayYearHrMinRenderer {...item} />
    ),

    [AssetFieldIds.lastScannedOn]: (): JSX.Element => (
      <DateWithMonthNameDayYearHrMinSecRenderer {...item} />
    ),

    [AssetFieldIds.lastUpdatedOn]: (): JSX.Element => (
      <DateWithMonthNameDayYearHrMinSecRenderer {...item} />
    ),

    [AssetFieldIds.count]: (): JSX.Element => <CountRenderer {...item} />,
    [AssetFieldIds.recordCount]: (): JSX.Element => (
      <RecordCountRenderer {...item} />
    ),
    [AssetFieldIds.minValue]: (): JSX.Element => <ValueRenderer {...item} />,
    [AssetFieldIds.maxValue]: (): JSX.Element => <ValueRenderer {...item} />,

    [AssetFieldIds.dqScore]: (): JSX.Element => <DQScoreRenderer {...item} />,

    [AssetFieldIds.duration]: (): JSX.Element => <DurationRenderer {...item} />,

    [AssetFieldIds.parentName]: (): JSX.Element => (
      <EtlParentRenderer {...item} />
    ),

    [AssetFieldIds.relationships]: (): JSX.Element => (
      <RelationshipSummaryRenderer />
    ),

    [AssetFieldIds.dataSource]: (): JSX.Element => (
      <SourceNameRenderer {...item} />
    ),

    [AssetFieldIds.tableName]: (): JSX.Element => (
      <NodeRenderer {...item} nodeType="TBL" />
    ),

    [AssetFieldIds.fieldName]: (): JSX.Element => (
      <ParsedNodesRenderer {...item} />
    ),

    [AssetFieldIds.runResult]: (): JSX.Element => (
      <RunResultRenderer {...item} />
    ),

    [AssetFieldIds.workflowStatus]: (): JSX.Element => (
      <WorkflowStatusRenderer {...item} />
    ),

    [AssetFieldIds.ploSource]: (): JSX.Element => (
      <ParsedNodesRenderer {...item} />
    ),
    [AssetFieldIds.ploTarget]: (): JSX.Element => (
      <ParsedNodesRenderer {...item} />
    ),
  };
};

export const assetOverviewCustomCompRenderer = (
  item: SectionRowProps
): { [key: string]: () => JSX.Element } => {
  return {
    [AssetFieldIds.lastRefreshedOn]: (): JSX.Element => (
      <LastUpdatedRendrer item={item} />
    ),

    [AssetFieldIds.visualDistribution]: (): JSX.Element => (
      <VisualDistributuion {...item} />
    ),
  };
};

export function AssetUsabilityScoreContent(props: {
  usabilityScore?: UsabilityScoreType;
  isSimpleView?: boolean;
  onClick?: () => void;
  isEditor?: boolean;
}): JSX.Element {
  const { isEditor, isSimpleView, onClick, usabilityScore } = props;

  const { data = [], obtainedScore, totalScore } = usabilityScore || {};

  const sec1 = data?.filter((item) => item?.isLyinginSec1);

  const sec2 = data?.filter((item) => !item?.isLyinginSec1);

  return (
    <AssetUsabilityScoreContentStyled isPlainView={isSimpleView}>
      <div className="usabilityscore-popover-header">
        Improve the usability of asset
        {!isSimpleView && isEditor && onClick && (
          <LinkButton
            fontFamily="OpenSansSemibold"
            id="secondary"
            onClick={onClick}
          >
            Enrich
          </LinkButton>
        )}
      </div>
      <div className="usabilityscore-popover-body">
        {isSimpleView && (
          <div className="usability-score-sec">
            <span>Usability Score</span>
            <span className="usability-obtained-score-sec">
              <span className="obtained-score">{obtainedScore}</span>/
              {totalScore}
            </span>
          </div>
        )}
        <div className="usability-static-text">Includes essential metadata</div>
        {sec1?.map((item, index) => {
          const { applied = false, title = "" } = item || {};
          return (
            <div
              className="usability-score-item"
              key={`tablepage-usability-sec1-score-item-${index}`}
            >
              {applied ? tickIconGreen() : roundIcon()}
              <span className={applied ? "" : "applied"}>{title}</span>
            </div>
          );
        })}
        {!!sec2?.length && (
          <div className="usability-static-text">
            Assurances the asset is maintained
          </div>
        )}

        {sec2?.map((item, index) => {
          const { applied = false, title = "" } = item || {};

          return (
            <div
              className="usability-score-item"
              key={`tablepage-usability-sec2-score-item-${index}`}
            >
              {applied ? tickIconGreen() : roundIcon()}
              <span className={applied ? "" : "applied"}>{title}</span>
            </div>
          );
        })}
      </div>
    </AssetUsabilityScoreContentStyled>
  );
}

export function AssetUsabilityScoreRender(additionalInfo?: {
  usabilityScore?: UsabilityScoreType;
  onEditOverview?: () => void;
  isEditor?: boolean;
  nodeSubType?: NodeSubType;
  nodeType?: NodeType;
}): JSX.Element {
  const { obtainedScore, totalScore } = additionalInfo?.usabilityScore || {};
  return (
    <AssetUsabilityScoreRenderStyled>
      <Popover
        content={AssetUsabilityScoreContent({
          usabilityScore: additionalInfo?.usabilityScore,
          onClick: additionalInfo?.onEditOverview,
          isEditor: additionalInfo?.isEditor,
        })}
        trigger={["click"]}
        overlayClassName="usability-score-popover"
        placement="bottomRight"
        getPopupContainer={(trigger): HTMLElement =>
          trigger?.parentNode as HTMLElement
        }
      >
        <span className="usability-score-value">
          <span className="obtained-score">{obtainedScore}</span>/{totalScore}
          {eyeIcon("16", "11.2")}
        </span>
      </Popover>
    </AssetUsabilityScoreRenderStyled>
  );
}
