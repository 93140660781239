import styled from "styled-components";

export const CommentPopoverContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 9.6px;
  width: 400px;
  background-color: ${(props): string => props.theme.bgColors.WHITE_COLOR};
  border-radius: 8px;
  box-shadow: 0 2px 8px ${(props): string => props.theme.borderColors.GREY_50};
  padding: 12px;

  .title {
    font-family: OpenSansSemiBold;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 10px;
`;
