import styled from "styled-components";

export const DataQualityTabHeaderWrapperStyled = styled.div`
  padding: 22px 30px 12px 20px;
`;

export const DataQualityTabStyled = styled.div``;

export const DataQualityHeaderWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderRightSecStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
`;

export const ViewNameStyled = styled.div<{ isSelected?: boolean }>`
  color: ${({ isSelected, theme }): string =>
    isSelected ? theme.textColors.BLUE_116 : theme.textColors.GREY_24};
  font-size: 13.6px;
`;
