import styled from "styled-components";

export const ListItemStyled = styled.li`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 80px;
  .name-text {
    color: ${({ theme }): string => theme?.textColors?.DARK_GREY_120};
    margin: 0 2px 0;
    font-size: 12px;
    word-break: break-all;
  }

  .status-text {
    word-break: break-word;
    color: ${({ theme }): string => theme?.textColors?.DARK_GREY_120};

    .tick-icon {
      margin: 0 2px;

      svg {
        fill: ${({ theme }): string => theme?.textColors?.GREEN_111};
      }
    }

    .dot {
      display: inline-block;
      margin: 0 2px;
      border-radius: 50px;
      background: ${({ theme }): string => theme?.bgColors?.GREY_56};
      border: ${({ theme }): string => theme?.bgColors?.GREY_56};
      width: 6px;
      height: 6px;
    }
    font-size: 12px;
  }
`;
