export const getWeightedWidth = <T>(nodes: T[]): number[] => {
  if (nodes?.length === 0) return [];

  // Create an array of ascending importance factors
  const factors = Array.from({ length: nodes?.length }, (_, i) => 1 + i * 0.25);
  const totalFactor = factors?.reduce((sum, factor) => sum + factor, 0);

  // Convert factors to percentages
  return factors?.map((factor) => (factor / totalFactor) * 100);
};
