export type InformationSectionType = {
  label: string;
  type: "TEXT" | "NUMBER" | "DATE" | "CHECKBOX";
};

export const informationSections: Record<string, InformationSectionType> = {
  last_profiled_on: { label: "Last Profiled On", type: "DATE" },
  min_max: { label: "Min-Max", type: "TEXT" },
  is_primary: { label: "Is Primary", type: "CHECKBOX" },
  is_nullable: { label: "Is Nullable", type: "CHECKBOX" },
  technical_data_type: { label: "Technical Data Type", type: "TEXT" },
  column_position: { label: "Column Position", type: "NUMBER" },
  size: { label: "Size", type: "NUMBER" },
  no_of_fractional_digits: {
    label: "No. of Fractional Digits",
    type: "NUMBER",
  },
};
