import { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip } from "antd";

import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { GridReadyEvent, ICellRendererParams } from "ag-grid-community";

import {
  AssetRelationshipGridContainer,
  AssetHeader,
  CollapseIcon,
  GridContainer,
} from "./assetrelationshipgrids.styles";

import {
  ActionRenderer,
  AssetPathRenderer,
  assetsGridColumns,
  NameRenderer,
  SourceRenderer,
} from "./assetrelationshipgrids.renderer";

import {
  AssetGridDataType,
  AssetRelationshipGridsProps,
  AssetRelationshipGridsState,
} from "./assetrelationshipgrids.types";

import { useAssetRelationshipContext } from "../../../../../contexts";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../constants";

import { managedByIcon } from "../../../../../svgs";
import AgGridTable from "../../../../aggridtable";
import { useGetAppState } from "../../../../../customhooks";

const AssetRelationshipGrids = (
  props: AssetRelationshipGridsProps
): JSX.Element => {
  const {
    assetRelationshipsData = [],
    onGridReady,
    onCommentSave,
    onDeletion,
    copyState,
    setCopyState,
  } = props;

  const {
    state: { nodeTitle = "", isEditable: isEditMode, isEdited },
  } = useAssetRelationshipContext();

  const {
    expandableGridProps: { isGridExpanded = false, expandedGridId = "" } = {
      isGridExpanded: false,
    },
  } = useGetAppState();

  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (!assetRelationshipsData || assetRelationshipsData.length === 0) return;

    setExpandedSections(
      Object.fromEntries(
        assetRelationshipsData.map(({ assetType }) => [assetType, true])
      )
    );
  }, [assetRelationshipsData]);

  const toggleSection = (assetName: string): void => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [assetName]: !prevState[assetName],
    }));
  };

  const actionRenderers = useCallback(
    (asset: AssetGridDataType) => (props: ICellRendererParams): JSX.Element =>
      ActionRenderer(props, asset?.sectionId, onCommentSave, onDeletion),
    [onCommentSave, onDeletion]
  );

  const onGridReadyHandlers = useCallback(
    (asset) => (params: GridReadyEvent): void =>
      onGridReady(asset?.assetType, params),
    [onGridReady]
  );

  const expandedAssetRelationshipGrids = useMemo(
    () =>
      isGridExpanded
        ? assetRelationshipsData?.filter(
            (asset) => asset?.sectionId === expandedGridId
          )
        : assetRelationshipsData,
    [assetRelationshipsData, isGridExpanded, expandedGridId]
  );

  return (
    <AssetRelationshipGridContainer
      isEditMode={isEditMode}
      hasData={expandedAssetRelationshipGrids?.length > 0}
    >
      {expandedAssetRelationshipGrids?.map((asset) => {
        const renderedColumns = assetsGridColumns(
          isEditMode,
          asset?.isEditable,
          asset?.isAssetPathRequired
        );
        const assetSize = asset?.rows?.length;
        return (
          <div key={asset?.assetType}>
            <AssetHeader onClick={(): void => toggleSection(asset?.assetType)}>
              <CollapseIcon>
                {expandedSections[asset?.assetType] ? (
                  <CaretDownOutlined />
                ) : (
                  <CaretRightOutlined />
                )}
              </CollapseIcon>
              {`${nodeTitle} ${asset?.relationshipType} ${asset?.assetType}${
                assetSize > 1 ? "s" : ""
              }`}

              {asset?.isInherited && (
                <Tooltip
                  title={`Inherited from linked ${asset?.isInheritedBy}`}
                  overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                >
                  {managedByIcon()}
                </Tooltip>
              )}
            </AssetHeader>

            {expandedSections[asset?.assetType] && (
              <GridContainer>
                <AgGridTable<AssetRelationshipGridsState>
                  onGridReady={onGridReadyHandlers(asset)}
                  theme="ag-theme-alpine table-container"
                  rowData={asset?.rows}
                  frameworkComponents={{
                    NameRenderer,
                    AssetPathRenderer,
                    SourceRenderer,
                    ActionRenderer: actionRenderers(asset),
                  }}
                  context={{ copyState, setCopyState }}
                  rowHeight={43}
                  headerHeight={43}
                  domLayout="autoHeight"
                  defaultColDef={{
                    resizable: false,
                    lockPosition: true,
                  }}
                  isShowPaginationComponent={assetSize > 10}
                  tableColumns={assetsGridColumns(
                    isEditMode,
                    asset?.isEditable,
                    asset?.isAssetPathRequired
                  )}
                  suppressCellSelection
                  cellRenderers={{
                    name: { render: "NameRenderer" },
                    action: { render: "ActionRenderer" },
                    nodePath: { render: "AssetPathRenderer" },
                    source: { render: "SourceRenderer" },
                  }}
                  isTableWidthfitTowindowSize={
                    renderedColumns?.length <= 5 && !asset?.isAssetPathRequired
                  }
                  showExpandCollapse
                  gridId={asset?.sectionId}
                  maxHeight={isGridExpanded ? "55vh" : "500px"}
                />
              </GridContainer>
            )}
          </div>
        );
      })}
      {!isEditMode && expandedAssetRelationshipGrids?.length === 0 && (
        <div className="no-data">No Data to Show</div>
      )}
    </AssetRelationshipGridContainer>
  );
};

export default AssetRelationshipGrids;
