import moment from "moment";
import { Margin } from "recharts/types/util/types";
import { APP_ROUTES, X_AXIS_DATA_KEY, YEAR_MONTH_DATE } from "../../constants";

import { ColumnDataType, ReturnTypeOfRefParser } from "../../parsers";
import {
  ChartDataType,
  ColorCodesParsedType,
  WidgetParsedType,
} from "../../parsers/dashboardparser/dashboardparser.types";

import {
  agGridSortDateComparator,
  agGridStringComparator,
  checkDataTypeOfColumn,
  checkIsTableDetailPageDataTab,
  getObjectKeys,
  isJsonString,
  jsonParse,
  maskString,
  StringToKey,
  utcTOLocalTimeZone,
} from "../../utils";

import {
  SelectedWidgetParsedType,
  SelectedWidgetTemplateType,
  WidgetFieldType,
} from "../modalwrapper/modals/widgetcreationwizard";

import { ChartYAxisType } from "../recharts/columnchart/columnchart.types";
import {
  DASHBOARD_LINE_CHART_COLOR_PALLETE,
  DASHBOARD_MULTIPLE_SLICER_COLOR,
  DASHBOARD_SINGLE_SLICER_COLOR,
  DVSUM_CHART_COLOR_PALLETE,
  DVSUM_COLOR_PALLETE,
} from "../recharts/dvsumcolorpallete";

import { DataKeysType } from "../recharts/piechart/piechart.types";
import {
  AggregatedData,
  ChartColorConfigType,
  DataItem,
  MappedObject,
  TranformedChartDataType,
} from "./widgetcard.types";
import { THEME_COLORS } from "../themeprovider/themeprovider.constant";
import { ChartType } from "../../utils/getcharticon";
import {
  FieldType,
  LayoutFieldType,
} from "../../parsers/listingpagesparser/listingpagesparser.types";
import { KeyValuePairType } from "../../app.types";
import { checkIsJobDetailPageeDetailsTab } from "../../utils/checkisjobdetailpageexecutionslisting";
import { getFilterByDataType } from "../../parsers/listingpagesparser/listingpagesparser.utils";
import { apiToFormCriteria } from "../../forms/addgovernanceviewform/addgovernanceviewform.utils";
import { FilterCriteriaResponseType } from "../../parsers/tablepage";

export const FILL_ITEM_KEY = "fill";

export const STACKED_COLUMN_CHART_MARGINS = {
  top: 10,
  bottom: 50,
};
export const COLUMN_CHART_MARGINS = {
  top: 10,
  bottom: 20,
  left: 15,
};

export const BAR_CHART_MARGINS = {
  top: 10,
  bottom: 0,
};

export const LINE_CHART_MARGINS = {
  top: 15,
  bottom: -15,
  left: 10,
};

export const DUMMY_Y_AXIS: ChartYAxisType = [
  {
    label: {
      value: "",
      offset: -35,
    },
    orientation: "left",
    yAxisId: "name",
    prefix: "",
    suffix: "",
    hide: false,
    minYDomain: 1,
    maxYDomain: 9,
  },
];

export const getChartWidth = (): string => {
  return "100%";
};

export const getColumnChartMargins = (dataLabelsEnabled: boolean): Margin => {
  return {
    top: 15,
    bottom: dataLabelsEnabled ? -20 : 20,
    left: dataLabelsEnabled ? 0 : 15,
    right: 15,
  };
};

export const getChartHeight = ({
  isExpandedOnFullScreen,
  isFromHomePage,
}: {
  isExpandedOnFullScreen?: boolean;
  isExpandedInRow?: boolean;
  isFromHomePage?: boolean;
}): number => {
  return isExpandedOnFullScreen ? 640 : isFromHomePage ? 240 : 330;
};

export const getDonutChartInnerRadius = ({
  isExpandedOnFullScreen,
  isFromHomePage,
}: {
  isExpandedOnFullScreen?: boolean;
  isExpandedInRow?: boolean;
  isFromHomePage?: boolean;
}): number => {
  return isExpandedOnFullScreen ? 190 : isFromHomePage ? 50 : 70;
};

export const selectedWidgetTransformer = (
  widget: WidgetParsedType
): SelectedWidgetParsedType => {
  const { widgetName = "", adhocInfo, widgetOrder = 1 } = widget || {};

  const parsedSelectedWidgetAdhocInfo = jsonParse(adhocInfo || "", true);
  const {
    chart_type: widgetChartType,
    gov_view_category: govViewCategory,
    widget_config: selectedWidgetConfig,
  } = (parsedSelectedWidgetAdhocInfo as SelectedWidgetTemplateType) || {};

  const {
    enable_datalabels: enableDataLabels,
    sort_type: sortType,
    metric_fields: metricFields,
    slicer_fields: slicerFields,
  } = selectedWidgetConfig || {};

  return {
    widgetName,
    widgetOrder,
    widgetChartType,
    govViewCategory,
    enableDataLabels,
    sortType,
    metricFields,
    slicerFields,
    widgetId: 0,
    isWidgetFullScreen: false,
  };
};

export const mapColorsWithCode = (
  colorsRef: ReturnTypeOfRefParser[],
  widgetColors: ColorCodesParsedType[]
): MappedObject[] => {
  const colorMap: { [key: string]: string } =
    colorsRef?.reduce((acc: Record<string, string>, color) => {
      acc[color.id] = color?.name; // Mapping the color id to its hex value
      return acc;
    }, {}) || [];

  const colorCodes =
    widgetColors?.map((item) => ({
      name: item?.name,
      code: item?.code,
      colorHex: colorMap?.[item?.code] || "", // Mapping code to hex value or null if not found
    })) || [];

  return colorCodes;
};

const getColorOfChart = (
  colors: MappedObject[],
  slicerValue: string,
  index: number,
  config: ChartColorConfigType
): string => {
  const colorOfField = colors?.find((item) => item?.name === slicerValue);

  const { chartType, is2SlicersAreBeingUsed } = config || {};

  const colorPallete =
    chartType === "LIN"
      ? DASHBOARD_LINE_CHART_COLOR_PALLETE
      : chartType === "DNT"
      ? DASHBOARD_MULTIPLE_SLICER_COLOR
      : !is2SlicersAreBeingUsed
      ? DASHBOARD_SINGLE_SLICER_COLOR
      : DASHBOARD_MULTIPLE_SLICER_COLOR;

  const palleteLength = colorPallete?.length || 0;
  const itemIndex = index % palleteLength;

  const fillColor = colorOfField?.colorHex || colorPallete[itemIndex];
  // const fillColor = colorPallete[itemIndex];

  return fillColor;
};

export const aggregrate1SlicerMultipleMetric = (
  data: ChartDataType,
  metricUsed: WidgetFieldType[],
  slicerFields: WidgetFieldType[],
  config?: ChartColorConfigType
): DataItem[] => {
  const { colors, chartType } = config || {};

  const colorsArray = colors || [];

  return data?.map((item, index: number) => {
    const metricOne = metricUsed?.[0]?.field_name || "";
    const metricTwo = metricUsed?.[1]?.field_name || "";
    const metricThree = metricUsed?.[2]?.field_name || "";

    const isFirstMetricIsCountMetric = metricUsed?.[0]?.field_agg_by === "CNT";
    const isSecondMetricIsCountMetric = metricUsed?.[1]?.field_agg_by === "CNT";
    const isThirdMetricIsCountMetric = metricUsed?.[2]?.field_agg_by === "CNT";

    const upperCaseMetricName = isFirstMetricIsCountMetric
      ? "Count"
      : metricOne?.toLocaleUpperCase() || "";
    const upperCase2ndMetricName = isSecondMetricIsCountMetric
      ? "Count"
      : metricTwo?.toLocaleUpperCase() || "";
    const upperCase3ndMetricName = isThirdMetricIsCountMetric
      ? "Count"
      : metricThree?.toLocaleUpperCase() || "";

    const slicerName = slicerFields?.[0]?.field_name || "";
    const slicerDataType = slicerFields?.[0]?.field_data_type;

    const upperCaseSlicerName = slicerName?.toLocaleUpperCase() || "";

    const firstMetricValue = isFirstMetricIsCountMetric
      ? item?.COUNT
      : item?.[upperCaseMetricName] || 0;
    const secondMetricValue = isSecondMetricIsCountMetric
      ? item?.COUNT
      : item?.[upperCase2ndMetricName] || 0;
    const thirdMetricValue = isThirdMetricIsCountMetric
      ? item?.COUNT
      : item?.[upperCase3ndMetricName] || 0;

    const nameValue = item?.[upperCaseSlicerName] || "";

    const { isDateColumn } = checkDataTypeOfColumn(slicerDataType);

    const parsedNameValue = isDateColumn
      ? utcTOLocalTimeZone(nameValue, YEAR_MONTH_DATE)
      : nameValue;

    const fillColor = getColorOfChart(colorsArray, nameValue, index, {
      chartType,
    });
    // Okay so value will be metric name
    //  name will be slicer name

    const isDonutChart = chartType === "DNT";

    return {
      name: parsedNameValue,
      [metricOne]: Number(firstMetricValue),
      ...(metricTwo && {
        [metricTwo]: Number(secondMetricValue),
      }),
      ...(metricThree && {
        [metricThree]: Number(thirdMetricValue),
      }),
      // ...(isDonutChart && {
      fill: fillColor || "",
      // }),
    };
  });
};

export const aggregate2Slicer1MetricData = (
  data: Array<{ [key: string]: string }>,
  remSlicer: string,
  valToBeXAxis: string,
  metric: string
): AggregatedData => {
  const reducedData =
    data?.reduce((prev: AggregatedData, next) => {
      const key = `${next?.[valToBeXAxis]}-${next?.[remSlicer]}`;

      if (!prev[key]) {
        return { ...prev, [key]: next };
      }
      const prevCount = prev?.[key]?.[metric];
      return {
        ...prev,
        [key]: { ...next, COUNT: prevCount + next?.[metric] },
      };
    }, {}) || [];

  return reducedData;
};

export const transformColumnChartData = (
  data: Array<{ [key: string]: string }>,
  metricFields: WidgetFieldType[],
  slicerFields: WidgetFieldType[]
): TranformedChartDataType[] => {
  const remSlicer = slicerFields?.[0]?.field_name?.toLocaleUpperCase() || "";
  const remSlicerDataType = slicerFields?.[0]?.field_data_type;

  const valToBeXAxis = slicerFields?.[1]?.field_name?.toLocaleUpperCase() || "";
  const valToBeXAxisDataType = slicerFields?.[1]?.field_data_type;

  const firstMetricName = metricFields?.[0]?.field_name || "";
  const metricName = firstMetricName?.toLocaleUpperCase();

  const isCountMetric = metricFields?.[0]?.field_agg_by === "CNT";

  const parsedData = aggregate2Slicer1MetricData(
    data,
    remSlicer,
    valToBeXAxis,
    metricName
  );

  const values = Object.values(parsedData) || [];

  const { isDateColumn } = checkDataTypeOfColumn(remSlicerDataType);
  const { isDateColumn: isSecondSlicerDateType } = checkDataTypeOfColumn(
    valToBeXAxisDataType
  );

  const chartData =
    values?.reduce(
      (
        acc: { [key: string]: TranformedChartDataType },
        item: TranformedChartDataType
      ) => {
        const categoryValue = (item?.[remSlicer] as string) || "";

        const parsedCategoryValue = isDateColumn
          ? utcTOLocalTimeZone(categoryValue, YEAR_MONTH_DATE)
          : categoryValue;

        const statusValue = (item?.[valToBeXAxis] as string) || "";

        const parsedStatusValue = isSecondSlicerDateType
          ? utcTOLocalTimeZone(statusValue, YEAR_MONTH_DATE)
          : statusValue;

        const metricValue =
          (isCountMetric ? item?.COUNT : item?.[metricName]) || 0;

        if (!acc?.[parsedCategoryValue]) {
          acc[parsedCategoryValue] = { name: parsedCategoryValue };
        }

        const metric = acc?.[parsedCategoryValue]?.[parsedStatusValue]
          ? Number(acc?.[parsedCategoryValue]?.[parsedStatusValue])
          : 0;

        acc[parsedCategoryValue][parsedStatusValue] =
          Number(metric) + Number(metricValue);

        return acc;
      },
      {}
    ) || [];

  const transformedChartData = Object.values(chartData);

  return transformedChartData || [];
};

export const getUniqueAndMaxKeysObject = (data: DataItem[]): string[] => {
  if (!data?.length) return []; // Handle empty or undefined array

  const uniqueKeys = new Set<string>();

  data?.forEach((obj) => {
    // Iterate through each key in the current object
    Object.keys(obj)?.forEach((key) => {
      uniqueKeys?.add(key); // Add the key to the Set
    });
  });

  // Convert the Set to an array and return it
  return Array.from(uniqueKeys) || [];
};

export const getDataKeysOfChart = (
  chartKeys: string[],
  isExpandedOnFullScreen: boolean,
  config?: ChartColorConfigType
): DataKeysType => {
  const { colors, is2SlicersAreBeingUsed, chartType } = config || {};

  const colorsArray = colors || [];

  const numberOfKeysToShow = isExpandedOnFullScreen ? chartKeys?.length : 6;

  const filteredNameAndFillKeys = chartKeys
    ?.filter((item) => item !== X_AXIS_DATA_KEY)
    ?.filter((item) => item !== FILL_ITEM_KEY);

  const transformedChartKeys = filteredNameAndFillKeys
    ?.slice(0, numberOfKeysToShow)
    ?.map((nestItem, index) => {
      const fillColor = getColorOfChart(colorsArray, nestItem, index, {
        chartType,
        is2SlicersAreBeingUsed,
      });

      return {
        dataKey: nestItem || "",
        fill: fillColor || "",
      };
    });

  return transformedChartKeys || [];
};

export const onBarClickRouteMap: { [key: string]: string } = {
  TBL: APP_ROUTES.private_routes.table_dictionary,
  COL: APP_ROUTES.private_routes.field_dictionary,
  RLS: APP_ROUTES.private_routes.rules_dictionary,
  DSR: APP_ROUTES.private_routes.analytics_dictionary,
  TRM: APP_ROUTES.private_routes.terms,
  JOB: APP_ROUTES.private_routes.jobs_dictionary,
  SCH: APP_ROUTES.private_routes.jobexecutions_dictionary,
};

export const onBarClickRouteNames: { [key: string]: string } = {
  TBL: "Data Dictionary",
  COL: "Field Dictionary",
  RLS: "DQ Dictionary",
  DSR: "Analytics Dictionary",
  TRM: "Business Glossary",
  JOB: "Jobs",
  SCH: "Job Executions",
};

export const getDataKeysOfPieChart = (
  data: DataItem[],
  isExpandedOnFullScreen: boolean,
  isFromHomePage: boolean
): DataKeysType => {
  return data
    ?.slice(0, isExpandedOnFullScreen ? data?.length : isFromHomePage ? 1 : 5)
    ?.map((item) => {
      return {
        dataKey: item?.name,
        fill: String(item?.fill),
      };
    });
};

export const getDictionaryLink = (govViewCategory: string): string => {
  const selectedRoute =
    onBarClickRouteMap[govViewCategory] ||
    APP_ROUTES.private_routes.table_dictionary;

  return selectedRoute;
};

export const getMaximumLengthChar = (data: DataItem[]): string => {
  let longestName: string = "";

  data?.forEach((item) => {
    const currentItemName = item?.name as string;
    if (currentItemName && currentItemName?.length > longestName?.length) {
      longestName = currentItemName;
    }
  });

  return longestName;
};
