import { AxiosResponse } from "axios";

import {
  AssetConfigType,
  AssetDocumentResponseType,
  AssetDocumenttParsedType,
  AssetFieldIds,
  SimilarAssetParsedDescriptionType,
  SimilarAssetParsedManagedByType,
  SimilarAssetsResponse,
  SimilarNodesParserReturnType,
  AssetRelationshipsDataResponseType,
  ReturnTypeOfAssetRelationshipsData,
  ReturnTypeOfAssetRelationshipsList,
  NodeParentalInfoResponse,
  AssetPath,
  AssetTagContextResponse,
  AssetsConfigurationsListResponseType,
  RefAdhocConfigType,
  RelationshipAdditionalInfoType,
  AssetReviewChangesResponse,
  AssetReviewChangesParsedType,
  ClassificationReturnType,
  CurrentObj,
  RelationshipChanges,
  AssetProfilingInfoParsedType,
  ProvenanceScanItems,
  ProvenanceReturnType,
  InheritInfoType,
} from "./asssetoverviewparser.types";

import {
  getClassificationCustomTags,
  getLineagePreviewData,
} from "../parserutils";

import {
  COLUMN_ASSET_CONFIG,
  DATASET_ASSET_CONFIG,
  DEFAULT_ASSET_CONFIG,
  DSF_ASSET_CONFIG,
  PLO_ASSET_CONFIG,
  REFERENCE_DICT_ASSET_CONFIG,
  REPORT_ASSET_CONFIG,
  RULE_ASSET_CONFIG,
  TABLE_ASSET_CONFIG,
  TERM_ASSET_CONFIG,
} from "../../constants/assetsconfig";

import { NodeSubType, NodeType, SourceTypes } from "../../app.types";

import {
  EMAIL_KEY_MAMPING,
  extractNameEmail,
  getAssetAdditionalInfo,
  getAssetAuditTrailInfo,
  getAssetKey,
  getFieldMappedParsedNodesValue,
  getFieldMappedValue,
  getFieldValueUsingPath,
  getFormattedFieldValues,
  getIsRowInherited,
  getIsRowInheritedBy,
  getIsRowVisibleInNormalView,
  getMappedField,
  getParsedNodePath,
  getReplacesInfo,
  listOfBuisenessRulesBasedOnType,
  mapBusinessRulesValues,
  NAME_KEY_MAPPING,
  populateBreadCrums,
  processRelationship,
} from "./assetoverviewparser.util";

import {
  CustomDataSetTag,
  DataQualityType,
  RepressentsRespDataItemType,
} from "../parser.types";
import { getPopularityScoreData } from "../parserutils/getpopularityscoredata";

import { ScoreType } from "../../components/popularityscore/popularityscore.types";
import {
  checkDataTypeOfColumn,
  convertNoHyphenDateToValidDate,
  getObjectKeys,
  getProfilingInfo,
  getProminentTags,
  getTimeDifference,
  isEqual,
  jsonParse,
  multiLevelSortArrayByKeys,
  removeEscapeCharForNewLine,
  sortObjectsArrayByKey,
  sortObjectsArrayByKeyDescOrder,
  sumTupleElement,
  uniqByKey,
  utcTOLocalTimeZone,
} from "../../utils";

import { momentFormatDateNoTimezone } from "../../utils/momentFormatDateNoTimezone";

import {
  COLUMN_VISUAL_DIST_DATA_KEY,
  ColumnDataType,
  SampleDataHeaderChart,
} from "../columnparsers";

import { X_AXIS_DATA_KEY } from "../../constants";
import { getWorkflowStats } from "../ruleparser/ruleparser.util";

import {
  RuleDefinitionValueType,
  RuleWorkflowType,
} from "../ruleparser/ruleparser.types";

import { SectionWithEditableSourcesProps } from "../../components/sectionwitheditables";

import {
  TagItem,
  TagsData,
} from "../../components/tagseditor/tagseditor.types";
import { ProfillingInfoResponse } from "../tablepage";
import { getLineagePreviewDataForEtl } from "../parserutils/getlineagepreviewerdata";

// place config on FE for time being

export type ComputedNodeType = NodeType | NodeSubType;

function getColumnChartData(
  data: SampleDataHeaderChart,
  colDataType: ColumnDataType,
  isPattern = false
): { name: string; Count: number; fill: string }[] {
  const { isNumberCol, isDateColumn } = checkDataTypeOfColumn(colDataType);

  const distCount = getObjectKeys(data?.DIST)?.length || 0;
  const histCount = getObjectKeys(data?.HIST)?.length || 0;

  const distData = isPattern
    ? data?.PATR // Use PATR for pattern data
    : isNumberCol
    ? distCount > 20 || !distCount
      ? data?.HIST
      : data?.DIST
    : isDateColumn
    ? histCount
      ? data?.HIST
      : data?.DIST
    : data?.DIST;

  if (!distData) return [];

  const chartData = getObjectKeys(distData)?.map((item) => ({
    [X_AXIS_DATA_KEY]: isDateColumn
      ? momentFormatDateNoTimezone(
          convertNoHyphenDateToValidDate(item),
          "MM/DD/YY"
        )
      : removeEscapeCharForNewLine(item),

    [COLUMN_VISUAL_DIST_DATA_KEY]: Number(distData?.[item]),
    fill: "#4894ca",
  }));

  const sortedChartData = isNumberCol
    ? chartData?.sort(
        (a, b) =>
          sumTupleElement(a?.[X_AXIS_DATA_KEY]) -
          sumTupleElement(b?.[X_AXIS_DATA_KEY])
      )
    : isDateColumn
    ? chartData
    : chartData?.sort(
        (a, b) =>
          b?.[COLUMN_VISUAL_DIST_DATA_KEY] - a?.[COLUMN_VISUAL_DIST_DATA_KEY]
      );

  return sortedChartData;
}

export const getAssetConfig = (
  nodeType: ComputedNodeType,
  nodeSubType?: NodeSubType
): AssetConfigType => {
  const nodeSubTypeAssetConfig: {
    [key in NodeSubType | string]?: AssetConfigType;
  } = {
    RPT: REPORT_ASSET_CONFIG,
  };

  const nodeTypeAssetConfig: {
    [key in NodeType | string]?: AssetConfigType;
  } = {
    DSR: DATASET_ASSET_CONFIG,
    TBL: TABLE_ASSET_CONFIG,
    COL: COLUMN_ASSET_CONFIG,
    TRM: TERM_ASSET_CONFIG,
    RLS: RULE_ASSET_CONFIG,
    RFD: REFERENCE_DICT_ASSET_CONFIG,
    DSF: DSF_ASSET_CONFIG,
    PLO: PLO_ASSET_CONFIG,
  };

  const mappedConfig =
    nodeSubTypeAssetConfig?.[nodeSubType || ""] ||
    nodeTypeAssetConfig[nodeType];

  return mappedConfig ?? DEFAULT_ASSET_CONFIG;
};

export function getParsedAssetsDocument({
  data,
}: AxiosResponse<AssetDocumentResponseType>): AssetDocumenttParsedType {
  const nodeType = data?.node_type;
  // const nodeType = "RFD";

  const isEtl = nodeType === "PLO";
  const nodeSubType = data?.node_sub_type;
  const currentAssetconfig = getAssetConfig(nodeType, nodeSubType);

  const managegByDataConfig = currentAssetconfig?.managed_by || {};
  const managegByDataFieldsConfig = managegByDataConfig?.fields || [];

  const descConfig = currentAssetconfig?.description || {};
  const descriptionFieldsConfig = descConfig?.fields || [];

  const businessRulesConfig = currentAssetconfig?.business_rules || [];

  const lineageConfig = currentAssetconfig?.lineage || {};
  const lineageFieldsConfig = lineageConfig?.fields || [];

  const customDefConfig = currentAssetconfig?.custom_definition || [];
  const customDefFields = customDefConfig?.fields || [];

  const dqConfig = currentAssetconfig?.data_quality || {};
  const dqFieldsConfig = dqConfig?.fields || [];

  const linkedEntConfig = currentAssetconfig?.linked_entity || {};

  const tagsConfig = currentAssetconfig?.clasification || {};

  const additionalInfoConfig = currentAssetconfig?.additional_info || {};

  const metaDataConfig = currentAssetconfig?.metadata_info;

  const crumsConfig = currentAssetconfig?.breadcrums_config;

  const {
    parentIdKey = "",
    parentNameKey = "",
    rootIdKey = "",
    rootNameKey = "",
    nodeIdKey = "",
    nodeNameKey = "",
    descKey = "",
    descIdKey = "",
  } = metaDataConfig || {};

  const classificationData =
    getClassificationCustomTags(data?.classifications || {}) || [];

  const rootId = data?.[getAssetKey(rootIdKey)]?.toString() || "";
  const rootName = data?.[getAssetKey(rootNameKey)]?.toString() || "";

  const parentName = data?.[getAssetKey(parentNameKey)]?.toString() || "";
  const parentId = data?.[getAssetKey(parentIdKey)]?.toString() || "";

  const currentNodeName = data?.[getAssetKey(nodeNameKey)]?.toString() || "";
  const currentNodeId = data?.[getAssetKey(nodeIdKey)]?.toString() || "";

  const currentNodeDesc = getFieldValueUsingPath<
    AssetDocumentResponseType,
    string
  >(data, descKey);

  const currentNodeSystemDesc = getFieldValueUsingPath<
    AssetDocumentResponseType,
    string
  >(data, descIdKey);

  const desc = currentNodeDesc
    ? `${currentNodeDesc}. ${currentNodeSystemDesc}`
    : currentNodeSystemDesc;

  const schemaName = data?.folder_name || "None";
  const dbName = data?.db_name || "None";

  const getAllappliedSchedulers =
    data?.scheduled_jobs?.value?.map((item) => ({
      jobId: item?.job_id,
      jodDefId: item?.job_def_id,
      jobDesc: item?.job_desc,
    })) || [];

  const nodePath = data?.node_path || [];

  const etlLineage = getLineagePreviewDataForEtl(data);

  const assetLineage = getLineagePreviewData(data?.lineage);

  return {
    root_id: rootId,
    root_name: rootName,

    parent_id: parentId,
    parent_name: parentName,

    node_id: currentNodeId,
    node_name: currentNodeName,
    node_desc: desc || "",
    node_system_generated_desc: currentNodeSystemDesc || "",
    node_title: data?.title?.value || "",

    node_type: data?.node_type || "DSR",
    node_sub_type: data?.node_sub_type || "DSR",

    term_type: data?.description?.term_type?.value?.term_type_id || "BSM",
    trm_source_id: data?.trm_source_id || "",

    is_editable: !!data?.is_editable,
    // is_editable: true,

    node_url: data?.node_url || "",
    is_deprecated: data?.published_status_id === "DEP",
    is_draft_exist: data?.is_draft_exists,

    is_endorsed: data?.endorsed_status?.value,
    source_name: data?.parent_name || "",
    endorsed_count: data?.endorsed_count || 0,

    publish_status_id: data?.published_status_id,
    publish_status: data?.published_status,
    is_wf_enabled: data?.is_wf_enabled || false,

    provinance_data: { data: [], isEditable: false, isEdited: false },
    source_id: `${data?.source_id || ""}`,
    src_type_id: data?.src_type_id,
    db_name: dbName,
    schema_name: schemaName,

    is_predefined: data?.is_predefined,
    is_approver: data?.is_approver || false,

    entity_id: data?.classifications?.entity?.value?.entity_id || "",
    is_col_desc_inherited: !!data?.description?.description?.is_inherited,

    code_s3_url: data?.plo_code_s3_url || "",
    code: data?.plo_code || "",

    popularity_score: getPopularityScoreData(
      data?.tbl_popularity_id as ScoreType,
      data?.tbl_popularity,
      data?.tbl_popularity_info
    ),

    is_dq_enabled: data?.is_dq_enabled || false,
    last_refreshed_on: data?.last_refreshed_on || "",
    last_updated_on: data?.last_updated_on || "",

    is_profiled: !!data?.profiling_info?.count?.record_count,

    last_profiled_on: data?.last_refreshed_on || "",
    is_primary: data?.description?.is_col_pk || true,
    min_max: data?.profiling_info?.min_max || "",
    is_nullable: data?.description?.is_col_nullable || false,
    technical_data_type: data?.description?.data_type || "",
    column_position: data?.description?.col_sequence || 0,
    size: data?.description?.col_length || 0,
    fractional_digits: data?.description?.col_data_precision || 0,
    prominent_tags: {
      tags: getProminentTags(classificationData),
      isBoldView: true,
    },

    status_tag: {
      tags: data?.rule_exec_info?.alert_status
        ? [
            {
              name: data?.rule_exec_info?.alert_status || "",
              type:
                data?.rule_exec_info?.alert_status_id === "ALE" ? "LRD" : "LGR",
            },
          ]
        : [],
      isBoldView: true,
    },

    get_access_info: data?.get_access_info?.value || "",
    classification: {
      ...tagsConfig,
      data: [...getClassificationCustomTags(data?.classifications || {})],
    },
    additional_info: {
      ...additionalInfoConfig,
      data: getAssetAdditionalInfo(data),
    },

    lineage: isEtl ? etlLineage : assetLineage,

    managed_by: {
      data:
        managegByDataFieldsConfig?.map((fieldItem) => {
          const currentKey = fieldItem?.id as keyof typeof data.managed_by;

          const nameKey = NAME_KEY_MAPPING[currentKey];

          const emailKey = EMAIL_KEY_MAMPING[currentKey];

          const isAuditTrail = fieldItem?.id === AssetFieldIds.auditTrail;

          const auditTrail = getAssetAuditTrailInfo(data);

          return isAuditTrail
            ? { ...fieldItem, title: fieldItem?.title || "", value: auditTrail }
            : {
                ...fieldItem,
                title: fieldItem?.title || "",
                value: "",
                type: fieldItem?.type || "multiselect",
                isEditable: fieldItem?.isEditable || false,
                apiKey: fieldItem?.apiKey || "get_user_users_groups",
                urlParam: fieldItem?.urlParam || [],
                fieldId: fieldItem?.id || "",
                keyValueTags: data?.managed_by?.[currentKey]?.value
                  ?.map((item) => {
                    const { name, email, type } = extractNameEmail(
                      item,
                      nameKey,
                      emailKey
                    );

                    return {
                      name,
                      type,
                      email,
                    };
                  })
                  ?.filter((item) => item?.name && item?.name?.trim() !== "()"),

                selectedValues: data?.managed_by?.[currentKey]?.value
                  ?.map((item) => {
                    const { name } = extractNameEmail(item, nameKey, emailKey);

                    return name;
                  })
                  ?.filter((item) => item && item?.trim() !== "()"),

                isInherited: data?.managed_by?.[currentKey]?.value?.some(
                  (item) => item?.is_inherited
                ),
                inHeritedBy:
                  data?.managed_by?.[currentKey]?.value?.find(
                    (item) => item?.is_inherited
                  )?.inherited_from || "",
              };
        }) || [],
      ...managegByDataConfig,
    },
    description: {
      data: descriptionFieldsConfig?.map((fieldItem) => {
        const { id = "none", idPath = "", path = "" } = fieldItem || {};

        const {
          isDataDomainField,
          isDescField,
          isMinValueField,
        } = getMappedField(fieldItem);

        const isInheritedFields = isDataDomainField || isDescField;

        const isIdPathExists = !!idPath;

        const idPathValue =
          getFieldValueUsingPath<AssetDocumentResponseType, string>(
            data,
            idPath
          ) || "";

        const selectedVals = getFieldValueUsingPath<
          AssetDocumentResponseType,
          string[]
        >(data, idPath);

        const isRowVisibleInNormalView = getIsRowVisibleInNormalView(
          data,
          fieldItem
        );

        const isInherited = getIsRowInherited(data, fieldItem);

        const inHeritedBy = getIsRowInheritedBy(data, fieldItem);

        const replacesInfo = getReplacesInfo(data, fieldItem);

        const ruleDef = getFieldValueUsingPath<
          AssetDocumentResponseType,
          RuleDefinitionValueType
        >(data, path);

        const { is_single_value: isSingleValue } = ruleDef || {};

        const selectedValuesLength = !!selectedVals;
        const addSelectedVals = isIdPathExists && selectedValuesLength;

        const fieldValue = getFieldMappedValue(data, fieldItem);
        const fieldNodesValue = getFieldMappedParsedNodesValue(data, fieldItem);

        return {
          ...fieldItem,
          title:
            isSingleValue && isMinValueField
              ? "Absolute Value"
              : fieldItem?.title || "",
          value: fieldValue,
          idValue: idPathValue,
          isRowVisibleInNormalView,
          // we might need to remove all below rows
          selectedValues: addSelectedVals ? [`${selectedVals}`] : undefined,
          ...(isInheritedFields && {
            isInherited,
            inHeritedBy,
          }),
          parsedNodes: fieldNodesValue,
          replaces: replacesInfo,
          srcTypeId: data?.src_type_id,
        };
      }),
      ...descConfig,
    },

    business_rules: {
      data: [],
      "Categorical Values":
        data?.business_rules?.value?.["Categorical Values"] || [],
      "Column Names": data?.business_rules?.value?.["Column Names"] || [],
      "Column Sub-Type": data?.business_rules?.value?.["Column Sub-Type"] || [],
      Functions: data?.business_rules?.value?.Functions || "",
      Manual: data?.business_rules?.value?.Manual || "",
      Patterns: data?.business_rules?.value?.Patterns || [],
      "Primitive Data Type":
        data?.business_rules?.value?.["Primitive Data Type"] || [],
      "Regex Patterns": data?.business_rules?.value?.["Regex Patterns"] || [],
      "Value Range": data?.business_rules?.value?.["Value Range"] || {
        min: "",
        max: "",
      },
      ...businessRulesConfig,
    },
    represents: {
      data: lineageFieldsConfig?.map((fieldItem) => {
        const { path = "", isLineageField = false } = fieldItem || {};

        const formattedValue = getFormattedFieldValues(data, fieldItem);
        const representsValue =
          getFieldValueUsingPath<
            AssetDocumentResponseType,
            RepressentsRespDataItemType[]
          >(data, path) || [];

        const isColLevelRepresents = fieldItem?.isColLevel || false;

        const represents = multiLevelSortArrayByKeys(representsValue, [
          "parent_name",
        ])?.map((item) => {
          const nodePath = item?.node_path;

          const schemaNode = nodePath?.find(
            (item) => item?.node_type === "SCM"
          );
          const dbNode = nodePath?.find((item) => item?.node_type === "DTB");
          const tblNode = nodePath?.find((item) => item?.node_type === "TBL");
          const srcNode = nodePath?.find((item) => item?.node_type === "SRC");

          const commonProperties = {
            dbName: dbNode?.node_name || "None",
            dbId: dbNode?.node_id || "",
            schemaName: schemaNode?.node_name || "None",
            schemaId: schemaNode?.node_id || "",
            isRefView: false,
            isTblSelection: true,
            tblName: tblNode?.node_name || "",
            tblId: `${tblNode?.node_id || ""}`,
            dbSrcTypeId: srcNode?.node_sub_type as SourceTypes,
            sourceName: srcNode?.node_name || "None",
            sourceId: srcNode?.node_id || "",
          };

          return isColLevelRepresents
            ? {
                colName: item?.node_name || "",
                colId: `${item?.node_id || ""}`,
                ...commonProperties,
              }
            : {
                ...commonProperties,
              };
        });

        return {
          ...fieldItem,
          sources: isLineageField ? represents : [],
          value: formattedValue,
          id: fieldItem?.id || "",
          title: fieldItem?.title || "",
        };
      }),
      ...lineageConfig,
    },

    data_quality: {
      data: dqFieldsConfig?.map((fieldItem) => {
        const { path = "" } = fieldItem || {};

        const dqStoreValue = getFieldValueUsingPath<
          AssetDocumentResponseType,
          DataQualityType
        >(data, path);

        const value =
          getFieldValueUsingPath<AssetDocumentResponseType, string>(
            data,
            path
          ) || "";

        const isOverAllScoreField =
          fieldItem?.id === AssetFieldIds.overAllScore;

        const {
          dq_status_id: dqStatusId,
          dq_status: dqStatus,
          dq_score: dqScore,
          dq_variance: dqVariance,
        } = dqStoreValue || {};

        return {
          ...fieldItem,
          title: fieldItem?.title || "",
          value: isOverAllScoreField ? dqScore : value,

          ...(isOverAllScoreField && {
            score: dqScore,
            statusId: dqStatusId,
            status: dqStatus,
            trend: dqVariance,
          }),
        };
      }),
      ...dqConfig,
    },

    linked_entity: {
      data: {
        id: data?.classifications?.entity?.value?.entity_id || "",
        entity_title: data?.classifications?.entity?.value?.entity_title || "",
        entity_name: data?.classifications?.entity?.value?.entity_name || "",
        match_status:
          data?.classifications?.entity?.value?.entity_match_status || "",
        match_score:
          data?.classifications?.entity?.value?.entity_match_score || 0,
        match_source:
          data?.classifications?.entity?.value?.entity_match_source || "",
        matching_entities: (
          data?.classifications?.entity?.value?.col_entity_json || []
        )?.map((entity) => ({
          matching_entity_id: entity?.entity_id || "",
          matching_entity: entity?.entity_name || "",
          samples: entity?.entity_desc || "",
          match_source: entity?.entity_match_source || "",
          matching_score: entity?.entity_matching_score,
          selected: entity?.entity_selection_status === "SEL",
          entity_status: entity?.entity_status || "",
          entity_status_id: entity?.entity_status_id,
        })),
      },
      ...linkedEntConfig,
    },

    page_breadcrumb: {
      data: populateBreadCrums(nodePath),
    },

    custom_definition: {
      data: customDefFields?.map((fieldItem) => {
        const { id = "none", idPath = "", path = "" } = fieldItem || {};

        const isCountField = id === AssetFieldIds.count;
        const isVisualDistField = id === AssetFieldIds.visualDistribution;
        const isPatternDistField = id === AssetFieldIds.patternDistribution;

        const profilingCount = getFieldValueUsingPath<
          AssetDocumentResponseType,
          {
            record_count: number;
            distinct_count: number;
            blanks_count: number;
          }
        >(data, path) || {
          record_count: 0,
          distinct_count: 0,
          blanks_count: 0,
        };

        const profilingInfo = getProfilingInfo(
          profilingCount?.record_count || 0,
          profilingCount?.distinct_count || 0,
          profilingCount?.blanks_count || 0
        );

        const distribution = getFieldValueUsingPath<
          AssetDocumentResponseType,
          SampleDataHeaderChart
        >(data, path) || {
          DIST: {},
          HIST: {},
          PATR: {},
        };

        const patternDistribution = getFieldValueUsingPath<
          AssetDocumentResponseType,
          SampleDataHeaderChart
        >(data, path) || {
          DIST: {},
          HIST: {},
          PATR: data?.profiling_info?.distribution?.PATR,
        };

        const dataTypeId =
          getFieldValueUsingPath<AssetDocumentResponseType, ColumnDataType>(
            data,
            idPath
          ) || "DTE";

        const { isDataDomainField, isDescField } = getMappedField(fieldItem);

        const isInheritedFields = isDataDomainField || isDescField;

        const idPathValue =
          getFieldValueUsingPath<AssetDocumentResponseType, string>(
            data,
            idPath
          ) || "";

        const isRowVisibleInNormalView = getIsRowVisibleInNormalView(
          data,
          fieldItem
        );

        const isIdPathExists = !!idPath;

        const selectedVals = getFieldValueUsingPath<
          AssetDocumentResponseType,
          string[]
        >(data, idPath);

        const isInherited = getIsRowInherited(data, fieldItem);

        const inHeritedBy = getIsRowInheritedBy(data, fieldItem);

        const replacesInfo = getReplacesInfo(data, fieldItem);

        const selectedValuesLength = !!selectedVals;
        const addSelectedVals = isIdPathExists && selectedValuesLength;

        const workflowValue = getFieldValueUsingPath<
          AssetDocumentResponseType,
          RuleWorkflowType
        >(data, path);

        const { actionPerformedBy } = getWorkflowStats(
          workflowValue as RuleWorkflowType
        );

        const isWorkflowField = id === AssetFieldIds?.workflowStatus;
        const isRunResultField = id === AssetFieldIds?.runResult;

        const fieldValue = getFieldMappedValue(data, fieldItem);
        const fieldNodesValue = getFieldMappedParsedNodesValue(data, fieldItem);

        // this profiling is of col as this info is available in details api
        // for table we are adding profiling info using assetsevice(useGetAssetProfilingInfo)
        // in useGetAssetDocument

        return {
          ...fieldItem,
          title: fieldItem?.title || "",
          value: fieldValue,
          isRowVisibleInNormalView,
          selectedValues: addSelectedVals ? [`${selectedVals}`] : undefined,
          ...(isInheritedFields && {
            isInherited,
            inHeritedBy,
          }),
          parsedNodes: fieldNodesValue,
          replaces: replacesInfo,
          srcTypeId: data?.src_type_id,
          ...(isCountField && {
            profillingInfo: profilingInfo || [],
          }),
          idValue: idPathValue,
          ...(isVisualDistField && {
            visualDistributionChartData:
              getColumnChartData(distribution, dataTypeId) || [],
            colDataTypeId: dataTypeId,
          }),
          ...(isPatternDistField && {
            patternDistributionChartData: sortObjectsArrayByKey(
              getColumnChartData(patternDistribution, dataTypeId, true) || [],
              "Count"
            )?.reverse(),
            colDataTypeId: dataTypeId,
          }),
          ...(isWorkflowField && { actionPerformedBy }),
          ...(isRunResultField && { exceptionCount: idPathValue }),
        };
      }),
      ...customDefConfig,
    },

    job_schedulers: getAllappliedSchedulers,
  };
}

export function getParsedAssetProfilingDocument(
  { data }: AxiosResponse<ProfillingInfoResponse>,
  nodeType: NodeType
): AssetProfilingInfoParsedType {
  const currentAssetconfig = getAssetConfig(nodeType);
  const profilingInfoConfig = currentAssetconfig?.custom_definition || {};
  const profilingInfoFields =
    profilingInfoConfig?.fields?.filter((item) => item?.isProfilingField) || [];

  return {
    profiling_info: {
      data: profilingInfoFields?.map((fieldItem) => {
        const { id = "none" } = fieldItem || {};

        const isCountFld = id === AssetFieldIds?.recordCount;
        const isScanFld = id === AssetFieldIds?.lastScannedOn;

        const countValue = `${data?.[0]?.TBL_RECORD_CNT || ""}`;
        const lastScanned = data?.[0]?.TBL_LAST_REFRESHED_ON;
        const lastUpdated = data?.[0]?.TBL_SRC_LAST_UPDATED_ON;

        return {
          ...fieldItem,
          title: fieldItem?.title || "",
          value: isCountFld
            ? countValue
            : isScanFld
            ? lastScanned
            : lastUpdated,
        };
      }),

      isEditable: false,
      isEdited: false,
    },
  };
}

export const getParsedSimilarAssetNodes = ({
  data,
}: AxiosResponse<SimilarAssetsResponse[]>): SimilarNodesParserReturnType[] => {
  return (
    data?.map((item) => {
      const parsedDescription: SimilarAssetParsedDescriptionType = jsonParse(
        item?.DESCRIPTION,
        true
      );

      const parsedManagedBy: SimilarAssetParsedManagedByType = jsonParse(
        item?.MANAGED_BY,
        true
      );

      const { audit_trail: auditTrail } = parsedDescription || {};

      const updatedObj = auditTrail?.find(
        (item) => item?.activity === "Updated"
      );

      const {
        activity: updatedActivity = "",
        user_name: updatedByUser = "",
        activity_on: updatedActivityOn = "",
      } = updatedObj || {};

      const updatedTime = getTimeDifference(
        utcTOLocalTimeZone(updatedActivityOn)
      );

      const updatedAction = `${updatedActivity} by ${updatedByUser} ${updatedTime}`;

      const nodePath = item?.NODE_PATH || "";
      const parsedNodePath = getParsedNodePath(nodePath);

      const breadCrumb = populateBreadCrums(parsedNodePath);

      const isAuditTrailExists =
        updatedActivity && updatedByUser && updatedActivityOn;

      return {
        breadcrumb: breadCrumb,
        desc: parsedDescription?.description?.value || "",

        nodeType: item?.NODE_TYPE,
        nodeSubType: item?.NODE_SUB_TYPE as NodeSubType,
        title: item?.NODE_TITLE || item?.NODE_NAME || "",

        additionalInfo: [
          {
            title: "Data Owner",
            value:
              parsedManagedBy?.data_owners?.value?.[0]?.data_owner_name || "",
          },
          {
            title: "Audit Trail",
            value: isAuditTrailExists ? updatedAction : "",
          },
        ],
        isHideFooter: false,
        nodeId: `${item?.NODE_ID || 0}`,
      };
    }) || []
  );
};

export const getParsedAssetsConfiguration = ({
  data,
}: AxiosResponse<AssetsConfigurationsListResponseType>): ReturnTypeOfAssetRelationshipsList => {
  return (
    data?.nodes_relations_configs?.map((item) => {
      let adhocConfig: RefAdhocConfigType = {};

      adhocConfig = item?.REF_ADHOC_CONFIG
        ? jsonParse(item?.REF_ADHOC_CONFIG)
        : {};

      return {
        id: `${item?.SRC_NODE_TYPE_ID}-${item?.REL_TYPE_ID}-${item?.TGT_NODE_TYPE_ID}`,
        nodeType: item?.SRC_NODE_TYPE_ID,
        assetTypeId: item?.TGT_NODE_TYPE_ID,
        target_asset_type: item?.TGT_NODE_TYPE,
        relationship_type: item?.SRC_REL_TYPE,
        relationship_type_id: item?.REL_TYPE_ID,

        isEditable: adhocConfig?.is_editable ?? false,
        isMultiValued: adhocConfig?.is_multi_value_allowed ?? false,
        isMandatory: adhocConfig?.is_mandatory ?? false,
        srcColName: adhocConfig?.src_col_name ?? "",
        tgtColName: adhocConfig?.tgt_col_name ?? "",
        isAssetPathRequired: adhocConfig?.is_asset_path_required ?? false,
        isInherited: adhocConfig?.is_inherited ?? false,
        isInheritedBy: adhocConfig?.is_inherited_by ?? "",
      };
    }) ?? []
  );
};

export const getParsedAssetRelationshipsData = ({
  data = [],
}: AxiosResponse<AssetRelationshipsDataResponseType>): ReturnTypeOfAssetRelationshipsData => {
  return data?.map((rel) => {
    const additionalInfo: RelationshipAdditionalInfoType = jsonParse(
      rel?.ADDITIONAL_INFO,
      true
    );
    const nodePath: AssetPath = jsonParse(rel?.TGT_NODE_PATH);
    const inheritInfo: InheritInfoType = jsonParse(rel?.INHERIT_INFO, true);

    return {
      id: `${rel?.TGT_NODE_ID}`,
      mode: "UPDATE",
      isChanged: false,
      sectionId: `${rel?.SRC_NODE_TYPE_ID}-${rel?.REL_TYPE_ID}-${rel?.TGT_NODE_TYPE_ID}`,
      assetType: rel?.TGT_NODE_TYPE || "",
      comment: rel?.COMMENTS || "",
      description: rel?.TGT_NODE_DESC || "",
      name: rel?.TGT_NODE_TITLE || rel?.TGT_NODE_NAME || "",
      nodeType: rel?.TGT_NODE_TYPE_ID || "",
      nodeSubType: rel?.TGT_NODE_SUB_TYPE || "",
      nodePath,
      isDeleted: false,
      relationshipType: rel?.SRC_REL_TYPE,
      relationshipTypeId: rel?.REL_TYPE_ID,
      isWorkflowEnabled: rel?.IS_WF_ENABLED || false,
      isInherited: inheritInfo?.is_inherited || false,
      isInheritedBy: inheritInfo?.inherited_from || "",
      source: additionalInfo?.source ?? "System",
      matchingScore: parseFloat(
        (additionalInfo?.entity_matching_score ?? 0).toFixed(2)
      ),
      matchingStatus: additionalInfo?.entity_selection_status || "",
      matchingEntities:
        additionalInfo?.entity_json?.map((matchingEntity) => ({
          matching_entity: matchingEntity?.entity_name || "",
          matching_entity_id: `${matchingEntity?.entity_id || 0}`,
          samples: matchingEntity?.entity_desc || "",
          matching_score: Number(matchingEntity?.entity_matching_score) || 0,
          match_source: matchingEntity?.entity_match_source || "AAI",
          selected: matchingEntity?.entity_selection_status === "SEL",
          entity_status: matchingEntity?.entity_status || "",
          entity_status_id: matchingEntity?.entity_status_id || "",
        })) || [],
    };
  });
};

export function getNodeParentalInfoByNodeType({
  data,
}: AxiosResponse<
  NodeParentalInfoResponse[]
>): SectionWithEditableSourcesProps["state"]["data"][number]["sources"] {
  const firstObj = data?.length ? data?.[0] : {};
  const isColumn =
    "ROOT_PARENT_NAME" in firstObj && "ROOT_PARENT_ID" in firstObj;

  return multiLevelSortArrayByKeys(data, [
    "ROOT_PARENT_NAME",
    "PARENT_NAME",
    "NODE_TITLE",
  ])?.map((item) => {
    const nodePath: AssetPath =
      item?.NODE_TITLE || item?.NODE_NAME
        ? [
            ...jsonParse(item?.NODE_PATH),
            {
              level: 0,
              node_id: `${item?.NODE_ID}`,
              node_name: item?.NODE_TITLE || item?.NODE_NAME,
              node_type: item?.NODE_TYPE,
              node_sub_type: item?.NODE_SUB_TYPE,
              is_workflow_enabled: !!item?.IS_WF_ENABLED,
            },
          ]
        : [...jsonParse(item?.NODE_PATH)];

    const parsedNodePath: AssetPath = jsonParse(item?.NODE_PATH);

    const schemaNode = parsedNodePath?.find(
      (item) => item?.node_type === "SCM"
    );

    const dbNode = parsedNodePath?.find((item) => item?.node_type === "DTB");

    const commonKeys = {
      nodePath,
      nodeId: `${item?.NODE_ID || ""}`,
      nodeName: item?.NODE_TITLE || item?.NODE_NAME || "",
      nodeType: item?.NODE_TYPE,
      nodesubType: item?.NODE_SUB_TYPE,
      isRefView: false,
      isTblSelection: false,
      schemaName: schemaNode?.node_name || "None",
      schemaId: schemaNode?.node_id || "",
      dbName: dbNode?.node_name || "None",
      dbId: dbNode?.node_id || "",
      dbSrcTypeId: item?.SRC_TYPE_ID,
      isWorkflowEnabled: item?.IS_WF_ENABLED || false,
    };

    return isColumn
      ? {
          tblName: item?.PARENT_NAME || "",
          tblId: `${item?.PARENT_ID || ""}`,
          colName: item?.NODE_NAME || "",
          colId: `${item?.NODE_ID || ""}`,
          sourceName: item?.ROOT_PARENT_NAME || "None",
          sourceId: item?.ROOT_PARENT_ID || "",
          description: item?.DESCRIPTION || "",
          ...commonKeys,
        }
      : {
          tblName: item?.NODE_TITLE || item?.NODE_NAME || "",
          tblId: `${item?.NODE_ID || ""}`,
          sourceName: item?.PARENT_NAME || "None",
          sourceId: item?.PARENT_ID || "",
          description: item?.DESCRIPTION || "",
          ...commonKeys,
        };
  });
}

export const commonItemParser = (
  items: AssetTagContextResponse[]
): TagItem[] => {
  return (
    items?.map((item) => ({
      label: item?.NODE_NAME || "",
      value: `${item?.NODE_ID || ""}`,
      desc: item?.NODE_DESC || "",
      isProminent: !!item?.IS_PROMINENT,
      colorCode: item?.COLOR_CODE,
    })) || []
  ).filter((item) => item?.label && item?.value);
};

export function getAssetTAGContextParser({
  data,
}: AxiosResponse<AssetTagContextResponse[]>): TagsData {
  const parentIds = data?.map((item) => item?.PARENT_ID || "") || [];
  const uniqueNames = [...new Set(parentIds)];
  return uniqueNames?.reduce((prev, parentId) => {
    const currItem = data?.find((item) => item?.PARENT_ID === parentId);
    return {
      ...prev,
      [parentId]: {
        tagsData: commonItemParser(
          data?.filter((item) => item?.PARENT_ID === parentId) || []
        ),
        isMultiple: currItem?.IS_MULTI_VALUE_ALLOWED,
        isNewValueAllowed: currItem?.IS_NEW_VALUE_ALLOWED,
        isNoteRequired: false,
      },
    };
  }, {});
}

export function getAssetChangesForReview(
  { data }: AxiosResponse<AssetReviewChangesResponse>,
  nodeType: NodeType
): AssetReviewChangesParsedType {
  const isColumn = nodeType === "COL";

  const definitionOldVal = data?.description?.old_value || "";
  const definitionNewVal = data?.description?.new_value || "";

  const nameOldVal = data?.term_name?.old_value || "";
  const nameNewVal = data?.term_name?.new_value || "";

  const termTitleOldValue = data?.title?.old_value || "";
  const termTileNewValue = data?.title?.new_value || "";

  const termTypeOldValue =
    data?.term_type?.old_value?.value?.term_type_name || "";

  const termTypeNewValue =
    data?.term_type?.new_value?.value?.term_type_name || "";

  const isCdeOldValue = data?.is_col_cde?.old_value || false ? "Yes" : "";
  const isCdeNewValue = data?.is_col_cde?.new_value || false ? "Yes" : "";

  const colTypeOldValue = data?.col_type?.old_value || "";
  const colTypeNewValue = data?.col_type?.new_value || "";

  const colBusinessRulesOldValue = data?.col_biz_rules?.old_value || "";
  const colBusinessRulesNewValue = data?.col_biz_rules?.new_value || "";

  const entityOldVal = {
    id: `${data?.entity?.old_value?.entity_id || ""}`,
    title: data?.entity?.old_value?.entity_title || "",
    status: data?.entity?.old_value?.entity_match_status || "",
  };

  const entityNewVal = {
    id: `${data?.entity?.new_value?.entity_id || ""}`,
    title: data?.entity?.new_value?.entity_title || "",
    status: data?.entity?.new_value?.entity_match_status || "",
  };

  const entityScoreOldVal = data?.entity?.old_value?.entity_match_score || 0;
  const entityScoreNewVal = data?.entity?.new_value?.entity_match_score || 0;

  const entitySourceOldVal = data?.entity?.old_value?.entity_match_source || "";
  const entitySourceNewVal = data?.entity?.new_value?.entity_match_source || "";

  const dataDomainOldVal = data?.data_domain?.old_value || "";
  const dataDomainNewVal = data?.data_domain?.new_value || "";

  const dataStewardsOldValue =
    data?.managed_by?.old_value?.data_stewards?.value?.map(
      (item) => item?.data_steward_name || ""
    ) || [];

  const dataStewardsNewValue =
    data?.managed_by?.new_value?.data_stewards?.value?.map(
      (item) => item?.data_steward_name || ""
    ) || [];

  const dataOwnersOldValue =
    data?.managed_by?.old_value?.data_owners?.value?.map(
      (item) => item?.data_owner_name || ""
    ) || [];

  const dataOwnersNewValue =
    data?.managed_by?.new_value?.data_owners?.value?.map(
      (item) => item?.data_owner_name || ""
    ) || [];

  const systemOwnersOldValue =
    data?.managed_by?.old_value?.system_owners?.value?.map(
      (item) => item?.system_owner_name || ""
    ) || [];

  const systemOwnersNewValue =
    data?.managed_by?.new_value?.system_owners?.value?.map(
      (item) => item?.system_owner_name || ""
    ) || [];

  const linkedColOldVal =
    sortObjectsArrayByKeyDescOrder(
      data?.linked_columns?.old_value?.value,
      "col_id"
    )?.map((item) => {
      return {
        sourceName: item?.src_name || "",
        sourceId: item?.src_id || "",
        tblName: item?.tbl_name || "",
        tblId: item?.tbl_id || "",
        colName: item?.col_name || "",
        colId: item?.col_id || "",
        isRefView: false,
        isTblSelection: false,
        schemaName: item?.folder_name || "None",
        dbName: item?.db_name || "None",
        dbSrcTypeId: item?.src_type_id,
      };
    }) || [];

  const linkedColNewVal =
    sortObjectsArrayByKeyDescOrder(
      data?.linked_columns?.new_value?.value,
      "col_id"
    )?.map((item) => {
      return {
        sourceName: item?.src_name || "",
        sourceId: item?.src_id || "",
        tblName: item?.tbl_name || "",
        tblId: item?.tbl_id || "",
        colName: item?.col_name || "",
        colId: item?.col_id || "",
        isRefView: false,
        isTblSelection: false,
        schemaName: item?.folder_name || "None",
        dbName: item?.db_name || "None",
        dbSrcTypeId: item?.src_type_id,
      };
    }) || [];

  const parsedProvScan = (
    data: ProvenanceScanItems
  ): ProvenanceReturnType[] => {
    return (
      data?.map((item) => {
        const nodePath = item?.node_path;
        const schemaNode = nodePath?.find((item) => item?.node_type === "SCM");
        const dbNode = nodePath?.find((item) => item?.node_type === "DTB");
        const tblNode = nodePath?.find((item) => item?.node_type === "TBL");
        const srcNode = nodePath?.find((item) => item?.node_type === "SRC");

        return {
          ...(isColumn
            ? {
                colName: item?.node_name || "",
                colId: `${item?.node_id || ""}`,
                tblName: tblNode?.node_name || "",
                tblId: `${tblNode?.node_id || ""}`,
              }
            : {
                tblName: item?.node_name || "",
                tblId: `${item?.node_id || ""}`,
              }),

          isTblSelection: false,
          isRefView: false,
          schemaName: schemaNode?.node_name || "None",
          schemaId: schemaNode?.node_id || "",
          dbName: dbNode?.node_name || "None",
          dbId: dbNode?.node_id || "",
          sourceName: srcNode?.node_name || "",
          sourceId: srcNode?.node_id || "",
          dbSrcTypeId: srcNode?.node_sub_type as SourceTypes,
        };
      }) || []
    );
  };

  const provenanceNewVal = parsedProvScan(data?.provenance_scan?.new_value);

  const provenanceOldVal = parsedProvScan(data?.provenance_scan?.old_value);

  const relationOldValue = data?.relationships?.old_value || [];
  const relationNewValue = data?.relationships?.new_value || [];

  const relationships = [
    { key: "goverenedByDomain", relTypeId: "GOV_BY_GOV" },
    { key: "representedByGlossary", relTypeId: "REP_BY_REP" },
    { key: "goverenedByPolicy", relTypeId: "MODEL_ERD" },
    { key: "referencedByDict", relTypeId: "REF_VL_REF" },
  ];

  const relationshipChanges = relationships?.reduce(
    (acc, { key, relTypeId }) => {
      acc[key] = processRelationship(
        relationOldValue,
        relationNewValue,
        relTypeId
      );
      return acc;
    },
    {} as RelationshipChanges
  );

  const additionalInfoTextOldVal =
    data?.additional_info?.old_value
      ?.filter((item) => item?.Text)
      ?.map((nestItem) => nestItem?.Text || "") || [];

  const additionalInfoTextNewVal =
    data?.additional_info?.new_value
      ?.filter((item) => item?.Text)
      ?.map((nestItem) => nestItem?.Text || "") || [];

  const additionalInfoLinkOldVal =
    data?.additional_info?.old_value
      ?.filter((item) => item?.Link)
      ?.map((nestItem) => nestItem?.Link || "") || [];

  const additionalInfoLinkNewVal =
    data?.additional_info?.new_value
      ?.filter((item) => item?.Link)
      ?.map((nestItem) => nestItem?.Link || "") || [];

  const oldCategoriesValues = mapBusinessRulesValues(
    data?.business_rules?.old_value?.["Categorical Values"] || []
  );

  const newCategoriesValues = mapBusinessRulesValues(
    data?.business_rules?.new_value?.["Categorical Values"] || []
  );

  const oldPatternsValues = mapBusinessRulesValues(
    data?.business_rules?.old_value?.Patterns || []
  );

  const newPatternsValues = mapBusinessRulesValues(
    data?.business_rules?.new_value?.Patterns || []
  );

  const oldManualValue = data?.business_rules?.old_value?.Manual || "";
  const newManualValue = data?.business_rules?.new_value?.Manual || "";

  const oldValueRangeValue =
    data?.business_rules?.old_value?.["Value Range"] || {};
  const newValueRangeValue =
    data?.business_rules?.new_value?.["Value Range"] || {};

  const oldCombineValRangeVal = `${oldValueRangeValue?.min || ""}-${
    oldValueRangeValue?.max || ""
  }`;

  const newCombineValRangeVal = `${newValueRangeValue?.min || ""}-${
    newValueRangeValue?.max || ""
  }`;

  const rulesBasedOnOldTermType = listOfBuisenessRulesBasedOnType(
    data?.term_type?.old_value?.value?.term_type_id
  );
  const rulesBasedOnNewTermType = listOfBuisenessRulesBasedOnType(
    data?.term_type?.new_value?.value?.term_type_id
  );

  const uniqueRules = new Set([
    ...rulesBasedOnOldTermType,
    ...rulesBasedOnNewTermType,
  ]);

  const definitionChanges = {
    oldValue: definitionOldVal,
    newValue: definitionNewVal,
    isChanged: !isEqual(definitionOldVal, definitionNewVal),
  };

  const nameChanges = {
    oldValue: nameOldVal,
    newValue: nameNewVal,
    isChanged: !isEqual(nameOldVal, nameNewVal),
  };
  const titleChanges = {
    oldValue: termTitleOldValue,
    newValue: termTileNewValue,
    isChanged: !isEqual(termTitleOldValue, termTileNewValue),
  };

  const termChanges = {
    oldValue: termTypeOldValue,
    newValue: termTypeNewValue,
    isChanged: !isEqual(termTypeOldValue, termTypeNewValue),
  };

  const isCdeChanges = {
    oldValue: isCdeOldValue,
    newValue: isCdeNewValue,
    isChanged: !isEqual(isCdeOldValue, isCdeNewValue),
  };

  const colTypeChanges = {
    oldValue: colTypeOldValue,
    newValue: colTypeNewValue,
    isChanged: !isEqual(colTypeOldValue, colTypeNewValue),
  };

  const colBusinessRulesChanges = {
    oldValue: colBusinessRulesOldValue,
    newValue: colBusinessRulesNewValue,
    isChanged: !isEqual(colBusinessRulesOldValue, colBusinessRulesNewValue),
  };

  const managedByChanges = {
    dataStewards: {
      oldValue: dataStewardsOldValue,
      newValue: dataStewardsNewValue,
      isChanged: !isEqual(dataStewardsOldValue, dataStewardsNewValue),
    },
    dataOwners: {
      oldValue: dataOwnersOldValue,
      newValue: dataOwnersNewValue,
      isChanged: !isEqual(dataOwnersOldValue, dataOwnersNewValue),
    },
    systemOwners: {
      oldValue: systemOwnersOldValue,
      newValue: systemOwnersNewValue,
      isChanged: !isEqual(systemOwnersOldValue, systemOwnersNewValue),
    },
  };

  const oldValue = data?.classifications?.old_value || [];
  const newValue = data?.classifications?.new_value || [];

  const classificationChanges: ClassificationReturnType[] = Object.values(
    [...oldValue, ...newValue].reduce(
      (current: CurrentObj, obj: CustomDataSetTag) => {
        if (!current?.[obj?.tagset_id]) {
          current[obj?.tagset_id] = {
            ...obj,
            newValue: obj?.value,
            oldValue: current?.[obj?.tagset_id]?.value,
          };
        } else {
          current[obj?.tagset_id] = {
            ...current[obj?.tagset_id],
            newValue: obj?.value,
            ...obj,
            oldValue: current?.[obj?.tagset_id]?.value,
          };
        }
        return current;
      },
      {}
    ) || []
  )?.map((item) => ({
    ...item,
    is_changed: !isEqual(
      item?.oldValue?.map((oldTag) => ({
        tag_desc: oldTag?.tag_desc || "",
        tag_id: oldTag?.tag_id || "",
      })),
      item?.newValue?.map((newTag) => ({
        tag_desc: newTag?.tag_desc || "",
        tag_id: newTag?.tag_id || "",
      }))
    ),
  }));

  const provenanceChanges = {
    isChanged: !isEqual(
      provenanceNewVal?.map((oldVal) => oldVal?.tblName),
      provenanceOldVal?.map((newVal) => newVal?.tblName)
    ),
    oldValue: provenanceOldVal,
    newValue: provenanceNewVal,
  };

  const additionalInfoTextChanges = {
    oldValue: additionalInfoTextOldVal,
    newValue: additionalInfoTextNewVal,
    isChanged: !isEqual(additionalInfoTextOldVal, additionalInfoTextNewVal),
  };

  const additionalInfoLinkChanges = {
    oldValue: additionalInfoLinkOldVal,
    newValue: additionalInfoLinkNewVal,
    isChanged: !isEqual(additionalInfoLinkOldVal, additionalInfoLinkNewVal),
  };

  const entityChanges = {
    oldValue: entityOldVal,
    newValue: entityNewVal,
    isChanged: !isEqual(entityNewVal, entityOldVal),
  };

  const entityScoreChanges = {
    oldValue: entityScoreOldVal,
    newValue: entityScoreNewVal,
    isChanged: !isEqual(entityScoreOldVal, entityScoreNewVal),
  };

  const entitySourceChanges = {
    oldValue: entitySourceOldVal,
    newValue: entitySourceNewVal,
    isChanged: !isEqual(entitySourceOldVal, entitySourceNewVal),
  };

  const dataDomainChanges = {
    oldValue: dataDomainOldVal,
    newValue: dataDomainNewVal,
    isChanged: !isEqual(dataDomainNewVal, dataDomainOldVal),
  };

  return [
    {
      definition: definitionChanges,
      name: nameChanges,
      term: termChanges,
      title: titleChanges,
      isCde: isCdeChanges,
      colType: colTypeChanges,
      colBusinessRules: colBusinessRulesChanges,
      dataStewards: managedByChanges?.dataStewards,
      dataOwners: managedByChanges?.dataOwners,
      systemOwners: managedByChanges?.systemOwners,

      provenance: provenanceChanges,
      classification: classificationChanges,
      additionalInfoLink: additionalInfoLinkChanges,
      additionalInfoText: additionalInfoTextChanges,
      businessRules: {
        categories: {
          oldValue: oldCategoriesValues,
          newValue: newCategoriesValues,
          isChanged: !isEqual(oldCategoriesValues, newCategoriesValues),
          hide: !uniqueRules?.has("categories"),
        },
        patterns: {
          oldValue: oldPatternsValues,
          newValue: newPatternsValues,
          isChanged: !isEqual(oldPatternsValues, newPatternsValues),
          hide: !uniqueRules?.has("patterns"),
        },
        manual: {
          oldValue: oldManualValue,
          newValue: newManualValue,
          isChanged: !isEqual(oldManualValue, newManualValue),
          hide: !uniqueRules?.has("manual"),
        },
        valueRange: {
          oldValue: oldCombineValRangeVal,
          newValue: newCombineValRangeVal,
          isChanged: !isEqual(oldCombineValRangeVal, newCombineValRangeVal),
          hide: !uniqueRules?.has("valueRange"),
        },
      },
      entity: entityChanges,
      entityScore: entityScoreChanges,
      entitySource: entitySourceChanges,
      dataDomain: dataDomainChanges,
      relationship: relationshipChanges,
    },
  ];
}
