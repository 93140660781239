import { APP_ROUTES, LOCAL_STORAGE_CONSTANTS } from "../constants";
import { RuleDetailPageTabType } from "../contexts";
import { RulePageViewKeys } from "../pages/ruledetailpage";
import { rulePageViewValues } from "../pages/ruledetailpage/ruledetailpage.constants";
import { ruleDetailPageTabsValues } from "../pages/ruledetailpage/ruledetailpage.views/ruledetailpagecontent/ruledetailpagecontent.types";

export const getRuleDetailPageUrl = (
  id: string,
  tab?: RuleDetailPageTabType,
  view?: RulePageViewKeys
): string => {
  const newTab = tab || ruleDetailPageTabsValues?.definition;

  const recentlyVisitedView =
    localStorage.getItem(
      LOCAL_STORAGE_CONSTANTS.recent_visited_rule_detail_page_view
    ) || rulePageViewValues?.overview;

  const redirectTo = view || recentlyVisitedView;

  return `${APP_ROUTES.private_routes.rule_detail}/${id}/${redirectTo}/${newTab}`;
};
