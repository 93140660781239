import styled from "styled-components";

export const AssetRelationshipGridContainer = styled.div<{
  isEditMode: boolean;
  hasData: boolean;
}>`
  display: flex;
  flex-direction: column;
  row-gap: 25.6px;
  background-color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
  padding: ${({ isEditMode, hasData }): string =>
    isEditMode && hasData ? "19.2px" : ""};

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 75px;
    font-size: 13px;
    font-family: OpenSans;
    color: ${(props): string => props.theme.textColors.GREY_210};
  }
`;

export const GridHeading = styled.h2`
  font-size: 18px;
  font-family: OpenSansSemiBold;
  margin: 0;
  color: ${(props): string => props.theme.textColors.BLUE_116};
`;

export const AssetHeader = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 14px;
  font-family: OpenSansSemiBold;
  gap: 9.6px;
  cursor: pointer;
  background-color: ${(props): string => props.theme.textColors.WHITE};
  margin-bottom: 12.8px;

  &:hover {
    background-color: ${(props): string => props.theme.textColors.GREY_100};
  }
`;

export const CollapseIcon = styled.div`
  display: flex;
`;

export const GridContainer = styled.div`
  background-color: ${(props): string => props.theme.textColors.WHITE};

  .ag-cell-center {
    display: flex;
    justify-content: center;
  }
`;

export const GridActionRenderer = styled.div`
  /* position: relative; */
  cursor: pointer;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 16px;

  &:hover {
    color: darkred;
  }
`;

export const GridNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .status-icon {
    font-size: 18px;
    margin-right: unset;
  }
`;

export const MatchingStatusPopoverStyled = styled.div<{
  isSpaceBetween?: boolean;
  isContainingAdditionalInfo?: boolean;
  paddingRight?: string;
}>`
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${({ paddingRight }): string => paddingRight || ""};

  .plus-icon {
    font-size: 18px;
    margin-right: 4px;
  }

  .actions-container {
    width: unset;
  }
`;

export const NonAssetItem = styled.div`
  color: ${(props): string => props.theme.textColors.BLUE_116};
  cursor: default;
  display: inline;
`;
