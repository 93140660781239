import { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import {
  Options,
  WebSocketHook,
  JsonValue,
} from "react-use-websocket/dist/lib/types";

import { LOCAL_STORAGE_CONSTANTS } from "../constants";
import { refreshTToken } from "../api";
import { getEnvVariables } from "../utils";

const checkTokenExpiry = async (): Promise<boolean> => {
  const expiryTime = localStorage.getItem(
    LOCAL_STORAGE_CONSTANTS?.exp_time_in_milli_seconds
  );
  const currentTime = new Date().getTime();

  if (
    Number(expiryTime) <= currentTime &&
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.access_token)
  ) {
    try {
      await refreshTToken();
      return true; // Refresh successful
    } catch (error) {
      return false;
    }
  }
  return true; // Token is still valid
};

export const useDvsumWebSocket = (
  options: Options
): WebSocketHook<JsonValue | null, MessageEvent<any> | null> => {
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string | null>(null);

  const { REACT_APP_WEB_SOCKET_URL } = getEnvVariables();

  // Handle token validation and refresh
  useEffect(() => {
    const validateToken = async (): Promise<void> => {
      const isValid = await checkTokenExpiry();
      setIsTokenValid(isValid);
    };
    validateToken();
  }, []);

  useEffect(() => {
    if (isTokenValid) {
      setAuthToken(localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.access_token));
    }
  }, [isTokenValid]);

  // Only create WebSocket connection if token is valid
  const socketUrl =
    isTokenValid && authToken
      ? `${REACT_APP_WEB_SOCKET_URL}?Authorization=${authToken}`
      : null;

  const webSocket = useWebSocket(socketUrl, options);

  return webSocket;
};
