// <--- Library Imports Start --->
import { useForm, FormProvider } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useElementSize } from "usehooks-ts";
// <--- Library Imports End --->

// <--- Components Start --->
import TagsEditor from "../../tagseditor";

import SectionRow from "../../sectionrow";
import SectionTitleWithIcon from "../../sectiontitlewithicon";
// <--- Components End --->

// <--- Constants Start --->
import { CERTIFY_TAGSET } from "../../../pages/tablepage/tablepage.constants";
// <--- Constants End --->

// <--- Styles Start --->
import {
  SectionWithEditableTagsStyled,
  TagsListingWrapperStyled,
} from "./sectionwitheditabletags.styles";
// <--- Styles End --->

// <--- Types Start --->
import { TagsData, TagsEditorState } from "../../tagseditor/tagseditor.types";
import { SectionWithEditableTagsProps } from "./sectionwitheditabletags.types";

import { GetClassificationCustomTagsAddInfo } from "../../../parsers/parserutils/getclassificationcustomtags";
import {
  ProminentTagsType,
  ProminentTagType,
} from "../../genericprominenttag/genericprominenttag.types";
// <--- Types End --->

// <--- Utils Start --->
import { localTimeZoneToUtc } from "../../../utils";
import { getPostLoginData } from "../../../utils/getpostlogindata";
import SectionBlankSlateText from "../../sectionblankslatetext";
// <--- Utils End --->

const defaultValuesOfCopyState = {
  data: {},
  selectedCategory: "",
  searchText: "",
  customTagsValue: "",
  customTagsInputText: "",
};

function SectionWithEditableTags(
  props: SectionWithEditableTagsProps
): JSX.Element {
  const {
    state,
    setState,
    id,
    secTitle,
    rendrer,
    className = "",
    isClickedOnCertify,
    onChangeClickCertifyBtn,
  } = props;

  const [elemSizeRef, size] = useElementSize();

  const form = useForm();

  const [
    copyStateOfTagsData,
    setCopyStateOfTagsData,
  ] = useState<TagsEditorState>(defaultValuesOfCopyState);

  const [showSecondScreen, setShowSecondScreen] = useState(false);

  const onEdit = useCallback(() => {
    setState((st) => ({
      ...st,
      [id]: { ...st[id], isEditable: true },
    }));

    const filteredTagSets = state?.[id]?.tagsData;

    setCopyStateOfTagsData((st) => ({
      ...st,
      selectedCategory: Object.keys(state?.[id]?.tagsData || {})?.[0] || "",
      data: filteredTagSets || {},
    }));
    setShowSecondScreen(false);
    onChangeClickCertifyBtn?.("");
  }, [state, copyStateOfTagsData, showSecondScreen]);

  const onCancel = useCallback(() => {
    setState((st) => ({
      ...st,
      [id]: { ...st[id], isEditable: false },
    }));
    setShowSecondScreen(false);
    setCopyStateOfTagsData(defaultValuesOfCopyState);
    onChangeClickCertifyBtn?.("");
  }, [state, copyStateOfTagsData, showSecondScreen]);

  const appliedTags: TagsData = useMemo(
    () =>
      Object.keys(copyStateOfTagsData?.data)?.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: {
            ...copyStateOfTagsData?.data[curr],
            tagsData: copyStateOfTagsData?.data[curr]?.tagsData?.filter(
              (item) => item?.isAdded
            ),
          },
        }),
        {}
      ),
    [copyStateOfTagsData]
  );

  const onSave = useCallback(() => {
    const { user_info: { user_name: userName = "" } = {} } = getPostLoginData();
    onChangeClickCertifyBtn?.("");
    setState((st) => ({
      ...st,
      [id]: {
        ...st[id],
        data: st[id]?.data?.map((item) => {
          const findProminentTag = (id: string): ProminentTagType =>
            item?.prominentTags?.tags?.find(
              (prompTag) => `${prompTag?.id}` === id
            ) || { name: "", type: "RED" };

          const simpleTags = appliedTags?.[item?.title]?.tagsData?.filter(
            (tag) => !tag?.isProminent
          );
          const prominentTags = appliedTags?.[item?.title]?.tagsData?.filter(
            (tag) => tag?.isProminent
          );

          return {
            ...item,
            value: appliedTags?.[item?.title]
              ? appliedTags?.[item?.title]?.tagsData?.[0]?.label
              : item?.value,
            selectedValueOfTags: appliedTags?.[item?.title]?.isMultiple
              ? appliedTags?.[item?.title]?.tagsData
              : [appliedTags[item?.title]?.tagsData?.[0]],
            tags: simpleTags?.length
              ? simpleTags?.map((item: any) => item?.label)
              : undefined,
            prominentTags: prominentTags?.length
              ? {
                  ...item?.prominentTags,
                  tags: prominentTags?.map((applTag) => {
                    const currPrompTag = findProminentTag(applTag?.value);

                    return currPrompTag?.name
                      ? {
                          ...currPrompTag,
                          tagDetails: currPrompTag?.tagDetails
                            ? {
                                ...currPrompTag?.tagDetails,
                                tagNote: applTag?.comment,
                              }
                            : undefined,
                        }
                      : {
                          name: applTag?.label,
                          id: applTag?.value,
                          type: applTag?.colorCode as ProminentTagsType,
                          tagDetails: {
                            tagName: applTag?.label,
                            tagsetName: item?.title,
                            tagNote: applTag?.comment,
                            tagsetId: (item?.additionalInfo as GetClassificationCustomTagsAddInfo)
                              ?.tagsetId,
                            tagsetDesc: applTag?.desc,
                            updatedBy: userName,
                            updatedOn: localTimeZoneToUtc(),
                          },
                        };
                  }),
                }
              : undefined,
          };
        }),
        tagsData: copyStateOfTagsData?.data,
        isEdited: true,
      },
    }));

    onCancel();
  }, [state, id, copyStateOfTagsData, appliedTags]);

  useEffect(() => {
    if (isClickedOnCertify) {
      setState((st) => ({
        ...st,
        [id]: { ...st[id], isEditable: true },
      }));

      const localTagsData = state?.[id]?.isEditable
        ? copyStateOfTagsData?.data
        : state?.[id]?.tagsData || {};

      setCopyStateOfTagsData((st) => ({
        ...st,
        selectedCategory: CERTIFY_TAGSET,
        data: {
          ...localTagsData,
          [CERTIFY_TAGSET]: {
            ...localTagsData?.[CERTIFY_TAGSET],
            tagsData: localTagsData?.[CERTIFY_TAGSET]?.tagsData?.map(
              (item, index) => ({
                ...item,
                selected: index === 0 ? true : item?.selected,
              })
            ),
          },
        },
      }));
    }
  }, [isClickedOnCertify]);

  return (
    <FormProvider {...form}>
      <div className={className} ref={elemSizeRef}>
        <SectionTitleWithIcon
          {...secTitle}
          marginBottom="25px"
          onEdit={onEdit}
          isEditable={state[id]?.isEditable}
          onCancel={onCancel}
          onSave={onSave}
          isDisabledSaveButton={showSecondScreen}
          disabledSaveBtnTooltipProps={{
            title: "Without providing comment you can't save changes",
            placement: "topRight",
          }}
        />
        {state[id]?.isEditable && (
          <SectionWithEditableTagsStyled
            isEditable={state[id]?.isEditable}
            width={size?.width}
          >
            <TagsEditor
              tagsDataState={copyStateOfTagsData}
              setTagsDataState={setCopyStateOfTagsData}
              appliedTags={appliedTags}
              showSecondScreen={showSecondScreen}
              setShowSecondScreen={setShowSecondScreen}
            />
          </SectionWithEditableTagsStyled>
        )}

        <SectionBlankSlateText isEmpty={!state[id]?.data?.length}>
          <TagsListingWrapperStyled className="asset-tags-viewable-section">
            {state[id]?.data?.map((row) => (
              <SectionRow
                {...row}
                customJSX={
                  rendrer && rendrer(row)[row?.title]
                    ? rendrer(row)[row.title]()
                    : undefined
                }
                key={`${row.title}-${id}`}
              />
            ))}
          </TagsListingWrapperStyled>
        </SectionBlankSlateText>
      </div>
    </FormProvider>
  );
}

export default SectionWithEditableTags;
