import { Edge, Node } from "reactflow";

import {
  getObjectEntries,
  getObjectKeys,
  getObjectValues,
  getPageNode,
  getUserPermissions,
  jsonParse,
} from "../../../utils";

import {
  AlertingNodeRelationToBase,
  AlertingNodesStatus,
  LineageNodeFooterStreamActionType,
  LineagePageQueryNodeParamsType,
  LineagePageUrlParamsType,
  LineageUrlNodesType,
  NodeDefaultValuesType,
  ObjectExistsAndValueType,
} from "../lineagepage.types";

import {
  LineageNode,
  LineageNodeDataType,
} from "../../../parsers/lineageparser/lineageparser.types";
import { traverseLineageBackward } from "./traverselineagebackward";

import { traverseLineageForward } from "./traverselineageforward";

import {
  edgeCommonProperties,
  edgeStyles,
} from "../../../parsers/lineageparser/lineageparser.util";

import {
  addEdgeFromBaseColToFocusColOfTgtNode,
  addEdgeFromBaseNodeFocusColToPartOfLineageCol,
  addEdgeFromPartOfLineageColToBaseNodeFocusCol,
  addEdgeFromSrcFocusColToBaseNodeCol,
} from "./addcolumnedges";

import {
  getLineagePageStateForDqFlowFromUrl,
  getVisibleNodeIdsForLineage,
} from "./getvisiblenodes";

import { splitIds } from "./checkexpandednodeid";
import { NodeType } from "../../../app.types";

import {
  DEFAULT_BASE_NODE_VALUES,
  DEFAULT_LEVEL2_NODE_VALUES,
  DEFAULT_NODE_VALUES,
} from "../lineagepage.constants";

export const isAnyOperationPerformedOnNode = (
  nodes: LineageUrlNodesType,
  nodeId: string
): boolean => {
  return nodeId in nodes;
};

export const JUNCTION_NODE_ONE_ID = "junction-node-1";
export const JUNCTION_NODE_TWO_ID = "junction-node-2";

export const JUNCTION_NODE_IN_SRC = "junction-node-src";
export const JUNCTION_NODE_IN_TRGT = "junction-node-trgt";

export const JUNCTION_NODE_TYPE = "junctionNode";

const SRC_JUNTION_NODE = {
  id: JUNCTION_NODE_IN_SRC,
  type: JUNCTION_NODE_TYPE,
  position: { x: 0, y: 0 },
  draggable: false,
  hidden: false,
  data: {
    nodeId: JUNCTION_NODE_IN_SRC,
    nodeName: "Junction Node",
    level: 1,
  },
  style: {
    width: 50,
    height: 50,
  },
};

const TARGET_JUNTION_NODE = {
  id: JUNCTION_NODE_IN_TRGT,
  type: JUNCTION_NODE_TYPE,
  position: { x: 0, y: 0 },
  draggable: false,
  hidden: false,
  data: {
    nodeId: JUNCTION_NODE_IN_TRGT,
    nodeName: "Junction Node",
    level: 1,
  },
  style: {
    width: 50,
    height: 50,
  },
};

export const filterLevel2Nodes = (nodes: LineageNode[]): LineageNode[] => {
  const updatedNodes = nodes?.filter((node) => node?.data?.level === 2) || [];

  return updatedNodes || [];
};

export const filterLevel1Nodes = (nodes: LineageNode[]): LineageNode[] => {
  const updatedNodes = nodes?.filter((node) => node?.data?.level === 1) || [];

  return updatedNodes || [];
};

export const isLevel1Node = (node: LineageNode): boolean => {
  return node?.data?.level === 1;
};

export const isLevel2Node = (node: LineageNode): boolean => {
  return node?.data?.level === 2;
};

export const convertNodeNameToSafeName = (nodeName: string): string => {
  return nodeName?.replace(
    /[^a-zA-Z0-9 ]/g,
    (char) => `_hex${char?.charCodeAt(0)?.toString(16)?.padStart(4, "0")}`
  );
};

export const convertNodeSafeNameToOrgName = (nodeName: string): string => {
  return nodeName?.replace(/_hex([0-9a-fA-F]{4})/g, (_, hexCode) =>
    String.fromCharCode(parseInt(hexCode, 16))
  );
};

export const getEncodedParsedUrlNodes = (
  urlNodes: string
): LineageUrlNodesType => {
  try {
    const safeUrlNodes = convertNodeSafeNameToOrgName(urlNodes);
    const urlDecodedNodes = decodeURIComponent(safeUrlNodes);
    const parsedUrlNodes: LineageUrlNodesType = jsonParse(urlDecodedNodes);

    return parsedUrlNodes;
  } catch (e) {
    console.error("Error in parsing URL nodes", e);
    const urlDecodedNodes = decodeURIComponent(urlNodes);
    const parsedUrlNodes: LineageUrlNodesType = jsonParse(urlDecodedNodes);
    return parsedUrlNodes;
  }
};

export const getFocusedNodes = (
  nodes: LineageUrlNodesType
): LineagePageQueryNodeParamsType[] => {
  const urlNodeValues: LineagePageQueryNodeParamsType[] =
    getObjectValues(nodes) || [];
  const focusedNodes = urlNodeValues?.filter((node) => node?.isFocused) || [];

  return focusedNodes;
};

export const getExpandedNodes = (
  nodes: LineageUrlNodesType
): LineagePageQueryNodeParamsType[] => {
  const urlNodeValues: LineagePageQueryNodeParamsType[] =
    getObjectValues(nodes) || [];

  const expandedNodes = urlNodeValues?.filter((node) => node?.isExpanded) || [];

  return expandedNodes;
};

export const onUpdateNodeFocusState = (
  nodes: LineageUrlNodesType,
  currentNodeId: string,
  newNodeState: LineageUrlNodesType
): LineageUrlNodesType => {
  const nodeEntries = getObjectEntries(nodes);

  const updatedNodesArray = nodeEntries?.map(([nodeId, nodeData]) => {
    const { nodeType } = nodeData || {};

    return [
      nodeId,
      {
        ...nodeData,
        isFocused: nodeId === currentNodeId,
        isExpanded: nodeData?.isExpanded,
      },
    ];
  });

  const additionalNode = nodes?.[currentNodeId] ? {} : newNodeState;

  const updatedNodesInfo = Object.fromEntries(updatedNodesArray) || [];

  const finalNodes = {
    ...updatedNodesInfo,
    ...additionalNode,
  };

  return finalNodes;
};

export const onUpdateLevel2NodesFocusState = (
  nodes: LineageUrlNodesType,
  currentNodeId: string,
  newNodeState: LineageUrlNodesType
): LineageUrlNodesType[] => {
  const nodeEntries = getObjectEntries(nodes);

  const updatedNodesArray = nodeEntries?.map(([nodeId, nodeData]) => {
    return [
      nodeId,
      {
        ...nodeData,
        // nodeName: nodeData?.nodeName,
        isFocused: nodeId === currentNodeId ? !nodeData?.isFocused : false,
      },
    ];
  });

  const additionalNode = nodes?.[currentNodeId] ? {} : newNodeState;

  const updatedNodesInfo = Object.fromEntries(updatedNodesArray) || [];

  const finalNodes = {
    ...updatedNodesInfo,
    ...additionalNode,
  };

  // console.log("finalNodes", finalNodes);

  return finalNodes;
};

export const setAllChildNodesToInActiveState = (
  nodes: LineageUrlNodesType
): LineageUrlNodesType => {
  const nodeEntries = getObjectEntries(nodes);

  const updatedNodesArray = nodeEntries?.map(([nodeId, nodeData]) => {
    return [
      nodeId,
      {
        ...nodeData,
        isFocused: false,
        isExpanded: false,
      },
    ];
  });

  const updatedNodesInfo = Object.fromEntries(updatedNodesArray);

  return updatedNodesInfo;
};

export const onSetParentNodeFocusOnColumnClick = (
  nodes: LineageUrlNodesType,
  columnParentId: string,
  columnParentName: string,
  columnParentNodeType: NodeType,
  isAnyNodeExpanded: boolean
): LineageUrlNodesType => {
  // Spread the existing nodes to create a new object

  const updatedNodes = getObjectEntries(nodes)?.reduce<LineageUrlNodesType>(
    (acc, [nodeId, nodeData]) => ({
      ...acc,
      [nodeId]: {
        ...nodeData,
        isFocused: nodeId === columnParentId, // Set true if matches columnParentId, else false
      },
    }),
    {}
  );

  // Check if the columnParentId not exists in nodes
  if (!updatedNodes?.[columnParentId]) {
    updatedNodes[columnParentId] = {
      nodeId: columnParentId,
      isFocused: true,
      nodeLevel: 1,
      nodeType: columnParentNodeType,
      isExpanded: isAnyNodeExpanded,
      isBaseNode: false,
      nodeName: columnParentName,
    };
  }

  return updatedNodes;
};

export const getRequiredLevelNodes = (
  nodes: LineageUrlNodesType,
  reqLevel: number
): LineageUrlNodesType => {
  const nodeEntries = getObjectEntries(nodes);
  const filteredNodes =
    nodeEntries
      ?.filter(([, nodeData]) => nodeData?.nodeLevel === reqLevel) // Filter based on node level
      ?.map(([nodeId, nodeData]) => [nodeId, nodeData]) || []; // Create entries in the format [key, value]

  return Object.fromEntries(filteredNodes) || []; // Convert back to an object
};

export const checkIsCurrentNodeAlreadyFocused = (
  nodes: LineageUrlNodesType,
  nodeId: string
): boolean => {
  const node = nodes?.[nodeId]; // Get the node with the provided nodeId
  return node?.isFocused === true;
};

export const checkIsCurrentNodeAlreadyExpanded = (
  nodes: LineageUrlNodesType,
  nodeId: string
): boolean => {
  const node = nodes?.[nodeId]; // Get the node with the provided nodeId
  return node?.isExpanded === true;
};

export const checkIsAnyNodeExpanded = (nodes: LineageUrlNodesType): boolean => {
  const objectValues = getObjectValues(nodes);
  return objectValues?.some(
    (node) => (node as LineagePageQueryNodeParamsType)?.isExpanded === true
  );
};

export const onUpdateNodeExpandedState = (
  nodes: LineageUrlNodesType,
  currentNodeId: string,
  newNodeState: LineageUrlNodesType
  // focusedChildNode: LineageNode
): LineageUrlNodesType => {
  const updatedNodes = { ...nodes };

  const nodeEntries = getObjectEntries(updatedNodes);

  const updatedNodesArray = nodeEntries?.map(([nodeId, nodeData]) => {
    const isLevel1Node = nodeData?.nodeLevel === 1;

    // const isCurrentChildNodeFocused = focusedChildNode?.id === nodeId;

    return [
      nodeId,
      {
        ...nodeData,
        isExpanded:
          nodeId === currentNodeId
            ? !nodeData?.isExpanded
            : nodeData?.isExpanded,

        isFocused: nodeId === currentNodeId,
        // isFocused: isLevel1Node
        //   ? nodeId === currentNodeId
        //   : isCurrentChildNodeFocused
        //   ? !nodeData?.isFocused
        //   : nodeData?.isFocused,
      },
    ];
  });

  const additionalNode = nodes?.[currentNodeId] ? {} : newNodeState;

  const updatedNodesInfo = Object.fromEntries(updatedNodesArray);

  const finalNodes = {
    ...updatedNodesInfo,
    ...additionalNode,
  };

  return finalNodes;
};

export const getNodeDataIfExists = (
  nodes: LineageUrlNodesType,
  currentNodeId: string
): LineagePageQueryNodeParamsType | null => {
  if (nodes?.[currentNodeId]) {
    // Get the keys of the object (like oneDownstream, allDownstream, etc.)
    const currentObjInUrl = nodes?.[currentNodeId];
    return currentObjInUrl;
  }

  // If the node doesn't exist, return null
  return null;
};

export const getObjectKeyIfExists = (
  obj: LineagePageQueryNodeParamsType,
  key: keyof LineagePageQueryNodeParamsType
): ObjectExistsAndValueType => {
  // Check if the key exists in the object
  if (key in obj) {
    return {
      exists: true,
      value: obj?.[key] as LineageNodeFooterStreamActionType,
    };
  }
  return { exists: false, value: null };
};

export const adjustEdgesVisibilityByLineage = (
  nodes: Node<LineageNode["data"]>[],
  edges: Edge[],
  isColumnLineage: boolean,
  currentFocusedColumnEdges: string[]
): Edge[] => {
  const columnNodeIds = filterLevel2Nodes(nodes)?.map((node) => node?.id);

  const updatedEdges = edges?.map((edge) => {
    const isColumnEdge =
      columnNodeIds?.includes(edge?.source) ||
      columnNodeIds?.includes(edge?.target);

    const currentEdgeId = edge?.id;
    const [source, target] = currentEdgeId?.split(":");

    const isFocusedEdge =
      currentFocusedColumnEdges?.includes(source) &&
      currentFocusedColumnEdges?.includes(target);

    const isTblLineage = !isColumnLineage;

    // ORIGINAL COND
    // if (isColumnEdge && isTblLineage && !isFocusedEdge) {
    //   return { ...edge, hidden: true };
    // }

    if (isColumnEdge && isColumnLineage && !isFocusedEdge) {
      return { ...edge, hidden: true };
    }

    return { ...edge, hidden: false };
  });

  return updatedEdges;
};

export const getUpstreamDownstreamNodes = (
  nodes: LineageUrlNodesType
): {
  enabledAllUpstreamIds: string[];
  enabledOneUpstreamIds: string[];
  enabledAllDownstreamIds: string[];
  enabledOneDownstreamIds: string[];
} => {
  return getObjectKeys(nodes)?.reduce(
    (acc, nodeId) => {
      const node = nodes?.[nodeId];
      const currentnodeId = node?.nodeId || "";

      const isAllUpStreamExists = node?.allUpstream;
      const isAllDownStreamExists = node?.allDownstream;

      // Check for upstream
      if (node?.allUpstream === "show") {
        acc.enabledAllUpstreamIds = [
          ...acc?.enabledAllUpstreamIds,
          currentnodeId,
        ];
      } else if (node?.oneUpstream === "show" && !isAllUpStreamExists) {
        acc.enabledOneUpstreamIds = [
          ...acc?.enabledOneUpstreamIds,
          currentnodeId,
        ];
      }

      // Check for downstream
      if (node?.allDownstream === "show") {
        acc.enabledAllDownstreamIds = [
          ...acc?.enabledAllDownstreamIds,
          currentnodeId,
        ];
      } else if (node?.oneDownstream === "show" && !isAllDownStreamExists) {
        acc.enabledOneDownstreamIds = [
          ...acc?.enabledOneDownstreamIds,
          currentnodeId,
        ];
      }

      return acc;
    },
    {
      enabledAllUpstreamIds: [] as string[],
      enabledOneUpstreamIds: [] as string[],
      enabledAllDownstreamIds: [] as string[],
      enabledOneDownstreamIds: [] as string[],
    }
  );
};

export const getDisabledUpstreamDownstreamNodes = (
  nodes: LineageUrlNodesType
): {
  disabledAllUpstreamIds: string[];
  disabledOneUpstreamIds: string[];
  disabledAllDownstreamIds: string[];
  disabledOneDownstreamIds: string[];
} => {
  return getObjectKeys(nodes).reduce(
    (acc, nodeId) => {
      const node = nodes[nodeId];
      const currentnodeId = node.nodeId || "";

      // Check for upstream
      const isAllUpstreamHidden = node?.allUpstream === "hide";
      const isAllUpstreamVisible = node?.allUpstream === "show";
      const isOneUpStreamHidden = node?.oneUpstream === "hide";

      if (isAllUpstreamHidden) {
        acc.disabledAllUpstreamIds = [
          ...acc?.disabledAllUpstreamIds,
          currentnodeId,
        ];

        // if user hide the oneUpstream, but allupstream is visible
        // then ignore the oneUpstream for that node
      } else if (isOneUpStreamHidden && !isAllUpstreamVisible) {
        acc.disabledOneUpstreamIds = [
          ...acc?.disabledOneUpstreamIds,
          currentnodeId,
        ];
      }

      // Check for downstream
      const isAllDownstreamHidden = node?.allDownstream === "hide";
      const isAllDownstreamVisible = node?.allDownstream === "show";
      const isOneDownstreamHidden = node?.oneDownstream === "hide";

      if (isAllDownstreamHidden) {
        acc.disabledAllDownstreamIds = [
          ...acc?.disabledAllDownstreamIds,
          currentnodeId,
        ];

        // if user hide the oneDownstream, but allDownstream is visible
        // then ignore the oneDownstream for that node
      } else if (isOneDownstreamHidden && !isAllDownstreamVisible) {
        acc.disabledOneDownstreamIds = [
          ...acc?.disabledOneDownstreamIds,
          currentnodeId,
        ];
      }

      return acc;
    },
    {
      disabledAllUpstreamIds: [] as string[],
      disabledOneUpstreamIds: [] as string[],
      disabledAllDownstreamIds: [] as string[],
      disabledOneDownstreamIds: [] as string[],
    }
  );
};

const toggleHiddenProperty = <T extends { hidden?: boolean }>(
  item: T,
  isShowAction?: boolean
): T => {
  return {
    ...item,
    hidden: !isShowAction,
  };
};

export const setAllNodesVisiblityAsDefault = (
  allNodes: Node<LineageNode["data"]>[]
): Node<LineageNode["data"]>[] => {
  return (
    allNodes?.map((node) => {
      return {
        ...node,
        hidden: false,
      };
    }) || []
  );
};

export const localizeSearchQueryNodes = (
  allNodes: Node<LineageNode["data"]>[],
  searchText: string
): Node<LineageNode["data"]>[] => {
  const {
    is_funct_attr_level: isFunctAttrLevel = false,
  } = getUserPermissions();

  return (
    allNodes?.map((node) => {
      const nodeName = node?.data?.nodeName?.toLocaleLowerCase();
      const nodeParentName = node?.data?.parentNodeName?.toLocaleLowerCase();
      const lowerSearchText = searchText?.toLocaleLowerCase();

      const isTableNode = isLevel1Node(node);
      const isColumnNode = isLevel2Node(node);

      const isSearchTextExists = !!lowerSearchText;

      const searchCondForTbl =
        isSearchTextExists &&
        (nodeName?.includes(lowerSearchText) ||
          nodeParentName?.includes(lowerSearchText));

      const searchCondForCol =
        isSearchTextExists &&
        nodeName?.includes(lowerSearchText) &&
        isFunctAttrLevel;

      const isNodePartOfSearchedQuery = isTableNode
        ? searchCondForTbl
        : isColumnNode
        ? searchCondForCol
        : false;

      const isAnyChildNodePartOfSearchQry = allNodes?.some((colNode) => {
        const isColNode = isLevel2Node(colNode);
        const isPartOfCurrentTbl = colNode?.parentNode === node?.id;

        const colNodeName = colNode?.data?.nodeName?.toLocaleLowerCase();

        const isColPartOfSrcQry =
          isSearchTextExists && colNodeName?.includes(lowerSearchText);

        return (
          isColNode &&
          isPartOfCurrentTbl &&
          isColPartOfSrcQry &&
          isFunctAttrLevel
        );
      });

      return {
        ...node,
        data: {
          ...node?.data,
          isNodePartOfSearchedQuery,
          isNodeChildPartOfSearchQuery: isTableNode
            ? isAnyChildNodePartOfSearchQry
            : false,
        },
      };
    }) || []
  );
};

export const adjustLevel1NodeVisibilityByLineage = (
  allNodes: Node<LineageNode["data"]>[],
  lineageIds: string[],
  isColLineage: boolean
): Node<LineageNode["data"]>[] => {
  return (
    allNodes
      ?.filter((node) => {
        // Exclude junction nodes entirely
        const isJunctionNode = node?.type === JUNCTION_NODE_TYPE;

        return !isJunctionNode;
      })
      ?.map((node) => {
        const isTableNode = isLevel1Node(node);

        const isTableLineage = !isColLineage;

        const childNodes = allNodes?.filter(
          (item) => item?.parentNode === node?.id
        );

        const isNodePartOfTblLineage = lineageIds?.includes(node?.id);

        // Check if the node is part of the column lineage by checking if any child node ID is in lineageIds
        const isNodePartOfColLineage = childNodes?.some((child) =>
          lineageIds?.includes(child?.id)
        );

        if (
          isTableNode &&
          isColLineage &&
          !node?.data?.isTablePartOfColumnLineage &&
          !node?.data?.isTblChildLoading
        ) {
          return toggleHiddenProperty(node, false);
        }

        // If node is part of lineageIds, show it by setting hidden to false
        if (
          isTableNode &&
          ((isTableLineage && isNodePartOfTblLineage) ||
            (isColLineage && isNodePartOfColLineage))
        ) {
          return toggleHiddenProperty(node, true);
        }

        if (isTableNode && !lineageIds?.includes(node?.id)) {
          return toggleHiddenProperty(node, false); // Updating hidden property
        }

        return node;
      }) || []
  );
};

export const adjustLevel2NodesVisibilityBasedOnParent = (
  allNodes: Node<LineageNode["data"]>[]
): Node<LineageNode["data"]>[] => {
  return (
    allNodes?.map((node) => {
      const isColumnNode = isLevel2Node(node);

      const currentNodeParentId = node?.parentNode || "";
      const isParentNodeVisible = allNodes?.some(
        (parentNode) =>
          parentNode?.id === currentNodeParentId && !parentNode?.hidden
      );

      if (isColumnNode && !isParentNodeVisible) {
        return toggleHiddenProperty(node, false); // Updating hidden property
      }

      return node;
    }) || []
  );
};

export const hideEdgesOfColumnNodesIfTableOrTechLineage = (
  nodes: Node[],
  edges: Edge[],
  isTableLineageActive: boolean
): Edge[] => {
  const edgesWithoutJunNodes = edges?.filter((edge) => {
    // Filter out junction nodes
    const isJunctionNodeEdge = edge?.id?.includes("junction");
    return !isJunctionNodeEdge;
  });

  // if (!isTableLineageActive) {
  //   const updatedEdges = edgesWithoutJunNodes?.map((edge) => {
  //     const sourceNode = nodes?.find((node) => node?.id === edge?.source);
  //     const targetNode = nodes?.find((node) => node?.id === edge?.target);

  //     const isJunctionNodeEdge =
  //       sourceNode?.type === JUNCTION_NODE_TYPE ||
  //       targetNode?.type === JUNCTION_NODE_TYPE;

  //     return {
  //       ...edge,
  //       hidden: isJunctionNodeEdge ? true : edge?.hidden,
  //     };
  //   });

  //   return updatedEdges; // Return edges as-is if table lineage is not active
  // }

  const updatedEdges = edgesWithoutJunNodes?.map((edge) => {
    const sourceNode = nodes?.find((node) => node?.id === edge?.source);
    const targetNode = nodes?.find((node) => node?.id === edge?.target);

    const isSourceOfEdgeIsColumn = sourceNode
      ? isLevel2Node(sourceNode)
      : false;

    const isTargetOfEdgeIsColumn = targetNode
      ? isLevel2Node(targetNode)
      : false;

    const isSourceOfEdgeIsTbl = sourceNode ? isLevel1Node(sourceNode) : false;

    const isTargetOfEdgeIsTbl = targetNode ? isLevel1Node(targetNode) : false;

    const isColEdge = isSourceOfEdgeIsColumn || isTargetOfEdgeIsColumn;
    const isTableNodeEdge = isSourceOfEdgeIsTbl || isTargetOfEdgeIsTbl;

    // Hide edges of column nodes unless the node matches the drillDownNodeFocusId
    return {
      ...edge,
      hidden: isTableNodeEdge
        ? edge?.hidden
        : isTableLineageActive
        ? isColEdge
        : edge?.hidden,
    };
  });

  return updatedEdges;
};

export const getChildNodeAndParentIds = (
  nodes: Node[],
  exclusiveOrInclusiveIds: string[]
): string[] => {
  // Filter nodes that are in the hiddenNodeIds list
  const exclusiveOrInclusiveNodes = nodes?.filter((node) =>
    exclusiveOrInclusiveIds?.includes(node?.id)
  );

  // Extract the parent IDs of the hidden nodes, if they exist
  const parentNodeIds = exclusiveOrInclusiveNodes
    ?.map((node) => node?.parentNode || "")
    ?.filter((parentId) => parentId); // Remove any undefined or null parent IDs

  // Combine hidden node IDs and parent IDs
  return [...exclusiveOrInclusiveIds, ...parentNodeIds];
};

export const getColumnLevelLineageNodes = (
  nodes: Node<LineageNodeDataType>[],
  edges: Edge[],
  expandedIds: string[],
  urlFocusedNodeId: string,
  colFocusId: string,
  isColumnLevelLineage: boolean
): Node<LineageNodeDataType>[] => {
  const backwardIds = traverseLineageBackward(edges, [colFocusId], []);
  const forwardIds = traverseLineageForward(edges, [colFocusId], []);

  const traverseIds = [...backwardIds, ...forwardIds];

  const columnLinNodes = nodes?.map((node) => {
    const isTableNode = isLevel1Node(node); // table

    const isColumnNode = isLevel2Node(node); //column

    const isColumnPartOfLineage = traverseIds?.length
      ? traverseIds?.includes(node?.id)
      : false;

    // Check if this table has any child columns in traverseIds
    const hasLineageColumns = nodes?.some(
      (colNode) =>
        isLevel2Node(colNode) &&
        colNode?.parentNode === node?.id &&
        traverseIds?.includes(colNode?.id)
    );

    const isTablePartOfColumnLineage = isTableNode && hasLineageColumns;

    const currentNodeParentId = node?.parentNode || "";

    const isCurrentNodeParentLoading =
      isLevel1Node(node) && node?.data?.isTblChildLoading;

    const isParentFocused = node?.parentNode === urlFocusedNodeId;

    const isParentExpanded = expandedIds?.includes(currentNodeParentId); // Column's parent is expanded

    const isColumnFocused = colFocusId === node?.id;

    const isColPartOfSearchQry =
      isColumnNode && node?.data?.isNodePartOfSearchedQuery;

    const isColumnVisible = (): boolean => {
      if (isCurrentNodeParentLoading) return false;
      if (isParentExpanded && !colFocusId) return true; // All columns visible if parent table is expanded and no column is focused
      if (isColumnFocused && isColumnLevelLineage) return true; // Focused column should always be visible
      if (isColumnPartOfLineage && isColumnLevelLineage) return true;
      if (isParentExpanded && isParentFocused) return true;
      if (isColPartOfSearchQry) return true;

      return false; // Hide all other columns
    };

    return {
      ...node,
      // Hide column nodes that are not part of the lineage
      hidden: isColumnNode
        ? !isColumnVisible()
        : isTablePartOfColumnLineage
        ? false
        : node?.hidden,

      data: {
        ...node?.data,
        // Highlight table nodes that have lineage columns
        isTablePartOfColumnLineage: !isColumnLevelLineage
          ? false
          : isColumnLevelLineage
          ? isTablePartOfColumnLineage
          : false,
      },

      style: {
        ...node?.style,
        ...(isColumnLevelLineage && { zIndex: isColumnFocused ? 0 : -1 }),
      },
    };
  });

  return columnLinNodes;
};

export const getTableLevelLineageNodes = (
  nodes: Node<LineageNodeDataType>[],
  expandedIds: string[]
): Node<LineageNodeDataType>[] => {
  const columnLinNodes = nodes?.map((node) => {
    const isColumnNode = isLevel2Node(node); //column

    const currentNodeParentId = node?.parentNode || "";

    const isParentExpanded = expandedIds?.includes(currentNodeParentId); // Column's parent is expanded
    const isTableExpanded = expandedIds?.includes(node?.id); // Column's parent is expanded

    const isColPartOfSearchQry =
      isColumnNode && node?.data?.isNodePartOfSearchedQuery;

    const isColVisibleBecauseOfSearchQry = isColPartOfSearchQry;

    return {
      ...node,
      hidden: isColumnNode
        ? !isParentExpanded && !isColVisibleBecauseOfSearchQry
        : node?.hidden,
      data: {
        ...node?.data,
        isExpandedMode: isTableExpanded,
        isTablePartOfColumnLineage: false,
      },
    };
  });

  return columnLinNodes;
};

export const getAllChildsFwd = (
  edges: Edge[],
  ids: string[],
  includeSrcIds = false
): string[] => {
  const fwdLineage = traverseLineageForward(
    edges,
    ids,
    [],
    true,
    includeSrcIds
  );
  return fwdLineage || [];
};

export const getAllChildsBwd = (
  edges: Edge[],
  ids: string[],
  includeSrcIds = false
): string[] => {
  const bwdLineage = traverseLineageBackward(
    edges,
    ids,
    [],
    true,
    includeSrcIds
  );
  return bwdLineage || [];
};

export const getDirectedChildsFwd = (
  edges: Edge[],
  ids: string[],
  includeSrcIds = false
): string[] => {
  const fwdLineage = traverseLineageForward(
    edges,
    ids,
    [],
    false,
    includeSrcIds
  );
  return fwdLineage || [];
};

export const getDirectedChildsBwd = (
  edges: Edge[],
  ids: string[],
  includeSrcIds = false
): string[] => {
  const bwdLineage = traverseLineageBackward(
    edges,
    ids,
    [],
    false,
    includeSrcIds
  );
  return bwdLineage || [];
};

export const getAlertingNodes = (
  nodes: LineageNode[],
  baseNodeId?: string
): LineageNode[] => {
  const tableNodes = filterLevel1Nodes(nodes);

  const removeBaseNode = tableNodes?.filter(
    (node) => node?.data?.nodeId !== baseNodeId
  );

  const baseAndAlertingNodes = removeBaseNode
    ?.filter((node) => {
      const { nodeId, rules } = node?.data;

      const isAnyRuleOfColumnIsAlerting = rules?.some(
        (rule) => rule?.is_alerting
      );

      return isAnyRuleOfColumnIsAlerting;
    })
    ?.reduce((acc, node) => {
      const { nodeId } = node?.data;
      if (!acc?.some((n) => n?.data?.nodeId === nodeId)) {
        return [...acc, node]; // Spread operator to add unique nodes to accumulator
      }
      return acc; // Skip duplicates
    }, [] as LineageNode[]);

  return baseAndAlertingNodes || [];
};

export const addEdgesBetweenAlertingNodesToBaseNode = (
  alertingNodes: string[],
  baseNodeId: string
): Edge[] => {
  // Map to generate edges based on the alerting node types
  const newEdges = alertingNodes?.map((id) => {
    const junctionNodeId = JUNCTION_NODE_IN_SRC; // Create a unique ID for the junction node

    const firstEdgId = `${id}:${junctionNodeId}`;
    const secondEdgeId = `${junctionNodeId}:${baseNodeId}`;

    const firstEdge = {
      id: firstEdgId,
      source: id,
      target: junctionNodeId,
      ...edgeCommonProperties,
      style: { ...edgeStyles },
      data: {
        isTableLevelJunctionEdge: true,
      },
    };

    const secondEdge = {
      id: secondEdgeId,
      source: junctionNodeId,
      target: baseNodeId,
      ...edgeCommonProperties,
      style: { ...edgeStyles },
      data: {
        isTableLevelJunctionEdge: true,
      },
    };

    return [firstEdge, secondEdge];
  });

  // Flatten the edges array and return unique edges
  const finalEdges: Edge[] = newEdges?.flat() || [];

  return finalEdges || [];
};

export const addEdgesBetweenBaseNodeToAlertingNodes = (
  alertingNodes: string[],
  baseNodeId: string
): Edge[] => {
  // Map to generate edges based on the alerting node types
  const newEdges = alertingNodes?.map((id) => {
    const junctionNodeId = JUNCTION_NODE_IN_TRGT; // Create a unique ID for the junction node

    const firstEdgId = `${baseNodeId}:${junctionNodeId}`;
    const secondEdgeId = `${junctionNodeId}:${id}`;

    const firstEdge = {
      id: firstEdgId,
      source: baseNodeId,
      target: junctionNodeId,
      ...edgeCommonProperties,
      style: { ...edgeStyles },
      data: {
        isTableLevelJunctionEdge: true,
      },
    };

    const secondEdge = {
      id: secondEdgeId,
      source: junctionNodeId,
      target: id,
      ...edgeCommonProperties,
      style: { ...edgeStyles },
      data: {
        isTableLevelJunctionEdge: true,
      },
    };

    return [firstEdge, secondEdge];
  });

  // Flatten the edges array and return unique edges
  const finalEdges = newEdges?.flat();

  return finalEdges;
};

export function findAlertingNodeRelationToBase(
  edges: Edge[],
  alertingNodes: string[],
  baseNodeId: string
): AlertingNodeRelationToBase[] {
  return alertingNodes?.map((alertingNodeId) => {
    const bwdTraverseResult = traverseLineageBackward(edges, [baseNodeId], []);

    const fwdTraverseResult = traverseLineageForward(edges, [baseNodeId], []);

    const isSource = bwdTraverseResult?.includes(alertingNodeId);

    const isTarget = fwdTraverseResult?.includes(alertingNodeId);

    return {
      nodeId: alertingNodeId,
      isSource,
      isTarget,
    };
  });
}

export const getAllAlertingNodesStatus = (
  alertingNodeRelations: AlertingNodeRelationToBase[]
): AlertingNodesStatus => {
  const isAllInSource =
    alertingNodeRelations?.every((relation) => relation?.isSource) || false;

  const isAllInTarget =
    alertingNodeRelations?.every((relation) => relation?.isTarget) || false;

  const isSomeInSrc =
    alertingNodeRelations?.some((relation) => relation?.isSource) || false;

  const isSomeInTgt =
    alertingNodeRelations?.some((relation) => relation?.isTarget) || false;

  const isSomeInSrcAndSomeInTgt = isSomeInSrc && isSomeInTgt;

  return {
    isAllInSource,
    isAllInTarget,
    isSomeInSrcAndSomeInTgt,
  };
};

export const getExpAndVisbleNodesChildren = (
  nodes: LineageNode[],
  expandedNodeIds: string[],
  visibleParentIds: string[]
): LineageNode[] => {
  return (
    nodes?.filter((node) => {
      const parentId = node?.parentNode || "";
      const chexkInExpanded = expandedNodeIds?.includes(parentId);
      const chexkInVisible = visibleParentIds?.includes(parentId);

      return chexkInExpanded && chexkInVisible;
    }) || []
  );
};

export const getNodeChildren = (
  nodes: LineageNode[],
  parentNodeIds: string[]
): LineageNode[] => {
  return (
    nodes?.filter((node) => {
      const parentId = node?.parentNode || "";
      return parentNodeIds?.includes(parentId);
    }) || []
  );
};

export const getInfoAboutFocusedChildLineage = (
  edges: Edge[],
  idOfFocusedCol: string,
  availableColIds: string[]
): {
  visibleFwdLinIds: string[];
  visibleBwdLinIds: string[];
  isBwdLinExists: boolean;
  isFwdLinExists: boolean;
  fwdLinOfFocusedCol: string[];
  bwdLinOfFocusedCol: string[];
} => {
  const bwdLinOfFocusedCol = getAllChildsBwd(edges, [idOfFocusedCol]);
  const fwdLinOfFocusedCol = getAllChildsFwd(edges, [idOfFocusedCol]);

  const visibleFwdLinIds =
    fwdLinOfFocusedCol?.filter((node) => availableColIds?.includes(node)) || [];

  const visibleBwdLinIds =
    bwdLinOfFocusedCol?.filter((node) => availableColIds?.includes(node)) || [];

  const isBwdLinExists = bwdLinOfFocusedCol?.length > 0;
  const isFwdLinExists = fwdLinOfFocusedCol?.length > 0;

  return {
    visibleFwdLinIds,
    visibleBwdLinIds,
    isBwdLinExists,
    isFwdLinExists,
    fwdLinOfFocusedCol,
    bwdLinOfFocusedCol,
  };
};

const getInfoAboutFocusedChildParent = (
  nodes: LineageNode[],
  idOfFocusedCol: string,
  alertingNodes: AlertingNodeRelationToBase[]
): {
  isFocusedColParentInSrc: boolean;
  isFocusedColParentInTgt: boolean;
} => {
  const allInfoOfFocusedCol = nodes?.find(
    (node) => node?.id === idOfFocusedCol
  );

  const parentOfFocusedCol = allInfoOfFocusedCol?.parentNode || "";

  const statusOfFocusedColParent = alertingNodes?.find(
    (node) => node?.nodeId === parentOfFocusedCol
  );

  const isFocusedColParentInSrc = statusOfFocusedColParent?.isSource || false;

  const isFocusedColParentInTgt = statusOfFocusedColParent?.isTarget || false;

  return {
    isFocusedColParentInSrc,
    isFocusedColParentInTgt,
  };
};

export const addEdgesForAlertingNodes = (
  nodes: LineageNode[],
  edges: Edge[],
  alertingNodes: string[],
  baseNodeId: string,
  expandedNodeIds: string[],
  focusedNodeIds: string[],
  isColumnLevelLineage: boolean,
  expandedJunctionNodes: string
): Edge[] => {
  const visibleParentIds = [baseNodeId, ...alertingNodes];

  const idOfFocusedCol = isColumnLevelLineage ? focusedNodeIds?.[0] : "";

  // Determine the relationship of each alerting node with respect to the base node
  const nodeRelations = findAlertingNodeRelationToBase(
    edges,
    alertingNodes,
    baseNodeId
  );

  const filteredJxnEdges =
    edges?.filter((item) => {
      const isJunctionEdge = item?.id?.includes("junction");
      return !isJunctionEdge;
    }) || [];

  const {
    allVisibleNodeIds,

    allLevel2FwdLinIds,
    allLevel2BwdLinIds,

    visibleLevel2BwdLinIds,
    visibleLevel2FwdLinIds,
  } = getVisibleNodeIdsForLineage(
    nodes,
    filteredJxnEdges,
    expandedNodeIds,
    visibleParentIds,
    idOfFocusedCol,
    baseNodeId,
    isColumnLevelLineage,
    nodeRelations,
    expandedJunctionNodes
  );

  const isFwdLinExists = allLevel2FwdLinIds?.length > 0;
  const isBwdLinExists = allLevel2BwdLinIds?.length > 0;

  const {
    isCollpasedInSrc,
    isCollpasedInTgt,
    showCollapsedFrwColLin,
    showCollapsedBwdColLin,
    showNodesFromAlertingToBase,
    showNodesFromBaseToAlerting,
  } = getLineagePageStateForDqFlowFromUrl(expandedJunctionNodes, nodeRelations);

  const baseNodeChilds = getNodeChildren(nodes, [baseNodeId]);

  const baseNodeChildsIds = baseNodeChilds?.map((item) => item?.id) || [];

  const isFocusedColPartOfBaseNodeChilds = baseNodeChilds?.some(
    (item) => item?.id === idOfFocusedCol
  );

  const {
    isFocusedColParentInSrc,
    isFocusedColParentInTgt,
  } = getInfoAboutFocusedChildParent(nodes, idOfFocusedCol, nodeRelations);

  const origEdgesWithBaseNodeChildAsTgt = edges?.filter((edge) => {
    return baseNodeChildsIds?.includes(edge?.target);
  });

  const origEdgesWithBaseNodeChildAsSrc = edges?.filter((edge) => {
    return baseNodeChildsIds?.includes(edge?.source);
  });

  const origEdgesWithBaseNodeAsTgt = edges?.filter((edge) => {
    return edge?.target === baseNodeId;
  });

  const origEdgesWithBaseNodeAsSrc = edges?.filter((edge) => {
    return edge?.source === baseNodeId;
  });

  const updatedExistingEdges =
    filteredJxnEdges?.map((edge) => {
      const { source, target } = edge;

      const isEdgePartOfVisibleNode =
        allVisibleNodeIds?.includes(source) &&
        allVisibleNodeIds?.includes(target);

      const isDQEdgesVisible =
        !showNodesFromAlertingToBase && !showNodesFromBaseToAlerting;

      const allEdgesSrcToBaseNode = [
        ...origEdgesWithBaseNodeAsTgt,
        ...origEdgesWithBaseNodeChildAsTgt,
      ];

      const allEdgesTgtToBaseNode = [
        ...origEdgesWithBaseNodeAsSrc,
        ...origEdgesWithBaseNodeChildAsSrc,
      ];

      const isCurrentEdgeInSrc = allEdgesSrcToBaseNode?.find(
        (item) => item?.id === edge?.id
      );
      const isCurrentEdgeInTgt = allEdgesTgtToBaseNode?.find(
        (item) => item?.id === edge?.id
      );

      const isEdgeHidden = (): boolean => {
        if (isDQEdgesVisible) return true;

        if (isCurrentEdgeInSrc) return !showNodesFromAlertingToBase;
        if (isCurrentEdgeInTgt) return !showNodesFromBaseToAlerting;

        return !isEdgePartOfVisibleNode;
      };

      return {
        ...edge,
        hidden: isEdgeHidden(),
      };
    }) || [];

  const alertingNodesEdges =
    nodeRelations?.map(({ nodeId, isSource, isTarget }) => {
      if (isSource && isCollpasedInSrc) {
        return addEdgesBetweenAlertingNodesToBaseNode([nodeId], baseNodeId);
      }

      // if (isTarget && isCollpasedInTgt) {
      //   return addEdgesBetweenBaseNodeToAlertingNodes([nodeId], baseNodeId);
      // }

      return [];
    }) || [];

  const newEdges: Edge[] = alertingNodesEdges?.flat() || [];

  const uniqueEdges = Array.from(
    new Map(newEdges?.map((item) => [item?.id, item]))?.values()
  );

  const focusedColInSrcEdges = addEdgeFromSrcFocusColToBaseNodeCol(
    visibleLevel2FwdLinIds,
    idOfFocusedCol,
    isFwdLinExists,
    baseNodeChildsIds,
    showNodesFromBaseToAlerting,
    showNodesFromAlertingToBase,
    allVisibleNodeIds,
    edges,
    nodes
  );

  const focusedColInTgtEdges = addEdgeFromBaseColToFocusColOfTgtNode(
    visibleLevel2BwdLinIds,
    idOfFocusedCol,
    isBwdLinExists,
    baseNodeChildsIds,
    showNodesFromBaseToAlerting,
    showNodesFromAlertingToBase,
    allVisibleNodeIds,
    edges,
    nodes
  );

  const bwdLineageColToFocusCol = addEdgeFromPartOfLineageColToBaseNodeFocusCol(
    visibleLevel2BwdLinIds,
    idOfFocusedCol,
    isBwdLinExists
  );

  const focusColToFwdLinageCol = addEdgeFromBaseNodeFocusColToPartOfLineageCol(
    visibleLevel2FwdLinIds,
    idOfFocusedCol,
    isFwdLinExists
  );

  const allEdgesForFocusColOfBaseNodeInCollapsedMode = [
    ...(showCollapsedBwdColLin ? bwdLineageColToFocusCol : []),
    ...(showCollapsedFrwColLin ? focusColToFwdLinageCol : []),
  ];

  // these child edges are fake edges becoz original lineage is hidden
  const childNodeEdges = isFocusedColPartOfBaseNodeChilds
    ? allEdgesForFocusColOfBaseNodeInCollapsedMode
    : isFocusedColParentInSrc
    ? focusedColInSrcEdges
    : isFocusedColParentInTgt
    ? focusedColInTgtEdges
    : [];

  const uniqueChildEdges = [
    ...new Map(childNodeEdges?.map((item) => [item?.id, item]))?.values(),
  ];

  const newEdgesWithFocusedColEdge = [
    ...uniqueEdges,
    ...(idOfFocusedCol ? uniqueChildEdges : []),
  ];

  const allEdges = [...newEdgesWithFocusedColEdge, ...updatedExistingEdges];

  return allEdges;
};

export const hideNodesBetweenBaseAndAlerting = (
  nodes: LineageNode[],
  edges: Edge[],
  baseNodeId: string,
  alertingNodesIds: string[],
  expandedNodeIds: string[],
  focusedNodeIds: string[],
  isColumnLevelLineage: boolean,
  expandedJunctionNodes: string
): LineageNode[] => {
  const visibleParentIds = [baseNodeId, ...alertingNodesIds];

  const idOfFocusedCol = isColumnLevelLineage ? focusedNodeIds?.[0] : "";

  const nodeRelations = findAlertingNodeRelationToBase(
    edges,
    alertingNodesIds,
    baseNodeId
  );

  const { isAllInSource, isAllInTarget } = getAllAlertingNodesStatus(
    nodeRelations
  );

  const filteredJxnEdges =
    edges?.filter((item) => {
      const isJunctionEdge = item?.id?.includes("junction");
      return !isJunctionEdge;
    }) || [];

  const { visibleLevel1Nodes, allVisibleNodeIds } = getVisibleNodeIdsForLineage(
    nodes,
    filteredJxnEdges,
    expandedNodeIds,
    visibleParentIds,
    idOfFocusedCol,
    baseNodeId,
    isColumnLevelLineage,
    nodeRelations,
    expandedJunctionNodes
  );

  const updatedNodes = nodes?.map((node) => {
    const isTableNode = isLevel1Node(node); // table

    const isColumnFocused = idOfFocusedCol === node?.id;

    return {
      ...node,
      hidden: !allVisibleNodeIds?.includes(node?.id),
      data: {
        ...node?.data,

        isTablePartOfColumnLineage:
          isTableNode &&
          isColumnLevelLineage &&
          visibleLevel1Nodes?.includes(node?.id),
      },
      style: {
        ...node?.style,
        ...(isColumnLevelLineage && { zIndex: isColumnFocused ? 0 : -1 }),
      },
    };
  });

  const {
    showNodesFromAlertingToBase,
    showNodesFromBaseToAlerting,
  } = getLineagePageStateForDqFlowFromUrl(expandedJunctionNodes, nodeRelations);

  const isSrcJunctionNodeHidden = showNodesFromAlertingToBase || isAllInTarget;

  const isTgtJunctionNodeHidden = showNodesFromBaseToAlerting || isAllInSource;

  const srcJunctionNode = {
    ...SRC_JUNTION_NODE,
    hidden: isSrcJunctionNodeHidden,
  };

  const additionalNodes = [...[srcJunctionNode as LineageNode]];

  const finalNodes = [...updatedNodes, ...additionalNodes];

  return finalNodes || [];
};

export const hideEdgesOFHiddenNodes = (
  nodes: Node[],
  edges: Edge[]
): Edge[] => {
  const updatedEdges = edges
    ?.filter((item) => {
      const isJunctionEdge = item?.id?.includes("junction");

      return !isJunctionEdge;
    })
    ?.map((edge) => {
      const sourceNode = nodes?.find((node) => node?.id === edge?.source);
      const targetNode = nodes?.find((node) => node?.id === edge?.target);

      const isSourceHidden = sourceNode?.hidden;
      const isTargetHidden = targetNode?.hidden;

      const isEdgeHiddenBczOfHiddenNodes = isSourceHidden || isTargetHidden;
      const isEdgeHiddenBczOfLinType = edge?.hidden;

      return {
        ...edge,
        hidden: isEdgeHiddenBczOfHiddenNodes || isEdgeHiddenBczOfLinType,
      };
    });

  return updatedEdges;
};

export const extractBaseNodeDetails = (
  data: LineageUrlNodesType
): LineagePageQueryNodeParamsType => {
  const urlNodesObjVals: LineagePageQueryNodeParamsType[] = getObjectValues(
    data
  );

  const baseNodeDetails = urlNodesObjVals?.filter((node) => node?.isBaseNode);
  return baseNodeDetails?.[0];
};

export const checkDqModeStatusFromUrl = (
  nodes: Node[],
  edges: Edge[],
  queryParams: LineagePageUrlParamsType
): boolean => {
  const {
    mode = "",
    enabledLayer = "",
    expandedJunctionNodes = "",
    nodes: urlNodes = "",
  } = queryParams || {};

  const parsedUrlNodes = getEncodedParsedUrlNodes(urlNodes);

  const baseNodeObj = extractBaseNodeDetails(parsedUrlNodes);

  const { nodeId: baseNodeId = "" } = baseNodeObj || {};

  const alertingNodes = getAlertingNodes(nodes, baseNodeId);
  const alertingNodesIds = alertingNodes?.map((node) => node?.id) || [];

  const isAnyAlertingNodeExixts = !!alertingNodesIds?.length;
  const isDqAlertMode = mode === "dq_alert";

  const urlLayers = splitIds(enabledLayer) || [];
  const isDQEnabled = urlLayers?.includes("DQ");

  const isDqAlertLineageEnabled = isDqAlertMode && isDQEnabled;

  const alertingNodeRelations = findAlertingNodeRelationToBase(
    edges,
    alertingNodesIds,
    baseNodeId
  );

  const {
    isAnySrcOrTgtCollapsed,
    isCollpasedInSrc,
  } = getLineagePageStateForDqFlowFromUrl(
    expandedJunctionNodes,
    alertingNodeRelations
  );

  const isDqflowMode =
    // if both, then just replace isCollpasedInSrc with isAnySrcOrTgtCollapsed
    isAnyAlertingNodeExixts && isDqAlertLineageEnabled && isCollpasedInSrc;

  return isDqflowMode;
};

export const getUniqueElements = <T, K extends keyof T>(
  arr: T[],
  key: K
): T[] => {
  return Array.from(new Map(arr?.map((item) => [item?.[key], item]))?.values());
};

export const extractNodesWithOneUpOrAllUp = (
  data: LineageUrlNodesType,
  baseNodeId: string
): LineagePageQueryNodeParamsType[] => {
  const urlNodesObjEntries = getObjectEntries(data);

  const nodesWithOneUpOrAllUp = urlNodesObjEntries
    ?.filter(
      ([_, node]) =>
        (node?.oneUpstream === "show" || node?.allUpstream === "show") &&
        node?.nodeId !== baseNodeId
    )
    ?.map(([key, node]) => ({ id: key, ...node }));

  return nodesWithOneUpOrAllUp || [];
};

export const extractNodesWithOneDownOrAllDown = (
  data: LineageUrlNodesType,
  baseNodeId: string
): LineagePageQueryNodeParamsType[] => {
  const urlNodesObjEntries = getObjectEntries(data);

  const nodesWithOneDownOrAllDown = urlNodesObjEntries
    ?.filter(
      ([_, node]) =>
        (node?.oneDownstream === "show" || node?.allDownstream === "show") &&
        node?.nodeId !== baseNodeId
    )
    ?.map(([key, node]) => ({ id: key, ...node }));

  return nodesWithOneDownOrAllDown || [];
};

export const flipChildLoadingPropertyOfParentNodes = (
  data: LineageNode[],
  updatedValue: boolean,
  id?: string // Optional id parameter
): LineageNode[] => {
  const updatedNodes =
    data?.map((node) => {
      // If id is provided, update only the matching node with level === 1
      if (id) {
        if (isLevel1Node(node) && node?.id === id) {
          return {
            ...node,
            data: {
              ...node?.data,
              isTblChildLoading: updatedValue,
            },
          };
        }
      }
      // If no id is provided, update all nodes with level === 1
      else if (isLevel1Node(node)) {
        return {
          ...node,
          data: {
            ...node?.data,
            isTblChildLoading: updatedValue,
          },
        };
      }
      return node;
    }) || [];

  return updatedNodes;
};

export const isDrillDownNode = (typeOfNode: NodeType): boolean => {
  return typeOfNode === "COL" || typeOfNode === "DSF" || typeOfNode === "POF";
};

export const isMainNode = (typeOfNode: NodeType): boolean => {
  return typeOfNode === "TBL" || typeOfNode === "DSR" || typeOfNode === "PLO";
};

export const extractNodeForFetchingMoreData = (
  data: LineageUrlNodesType
): LineagePageQueryNodeParamsType => {
  const urlNodesObjVals: LineagePageQueryNodeParamsType[] = getObjectValues(
    data
  );

  const nodeToFetchMoreDataFor = urlNodesObjVals?.filter(
    (node) => node?.isFetchingMoreData
  );

  return nodeToFetchMoreDataFor?.[0];
};

export const removeIsFetchingMoreDataFromUrlNodes = (
  nodes: LineageUrlNodesType
): LineageUrlNodesType => {
  const nodeEntries = getObjectEntries(nodes);

  const updatedNodesArray = nodeEntries?.map(([nodeId, nodeData]) => {
    return [
      nodeId,
      {
        ...nodeData,
        isFetchingMoreData: false,
        direction: "",
      },
    ];
  });

  const updatedNodesInfo = Object.fromEntries(updatedNodesArray);

  return updatedNodesInfo;
};

export const sortNodesArray = (data: LineageNode[]): LineageNode[] => {
  const sortedNodes = data?.sort((a, b) => {
    const nameA = a?.data?.nodeName?.toLowerCase() || "";
    const nameB = b?.data?.nodeName?.toLowerCase() || "";
    return nameA?.localeCompare(nameB);
  });

  return sortedNodes || [];
};

const hasNodeChanged = (node: LineagePageQueryNodeParamsType): boolean => {
  const {
    isExpanded = false,
    oneUpstream = "",
    oneDownstream = "",
    isBaseNode = false,
    isFocused,
    nodeLevel,
    allDownstream,
    allUpstream,
  } = node;

  const isLevel2Node = nodeLevel === 2;

  const defaultValues: NodeDefaultValuesType = isBaseNode
    ? isLevel2Node
      ? DEFAULT_LEVEL2_NODE_VALUES
      : DEFAULT_BASE_NODE_VALUES
    : DEFAULT_NODE_VALUES;

  return (
    isExpanded !== defaultValues?.isExpanded ||
    oneUpstream !== defaultValues?.oneUpstream ||
    oneDownstream !== defaultValues?.oneDownstream ||
    (allUpstream ?? "") !== (defaultValues?.allUpstream ?? "") || // Handle undefined
    (allDownstream ?? "") !== (defaultValues?.allDownstream ?? "") || // Handle undefined
    (nodeLevel === 2 && isFocused !== defaultValues?.isFocused)
  );
};

export const nodesHaveChanges = (
  currentNodes: LineageUrlNodesType
): boolean => {
  const currentNodesArray = Object.values(currentNodes);

  return currentNodesArray?.some((node) => hasNodeChanged(node));
};

export const addParentEdgeInfoInColEdges = (
  newNodes: LineageNode[],
  existingEdges: Edge[],
  newColEdges: Edge[]
): Edge[] => {
  const updatedColEdges = newColEdges?.map((colEdge) => {
    const colEdgeSrc = colEdge?.source;
    const colEdgeTarget = colEdge?.target;

    const colEdgeSrcParent =
      newNodes?.find((node) => node?.id === colEdgeSrc)?.data?.parentNodeId ||
      "";

    const colEdgeTargetParent =
      newNodes?.find((node) => node?.id === colEdgeTarget)?.data
        ?.parentNodeId || "";

    const parentEdgeId = `${colEdgeSrcParent}:${colEdgeTargetParent}`;

    const parentEdge = existingEdges?.find((prevEdge) => {
      return prevEdge?.id === parentEdgeId;
    });

    return {
      ...colEdge,
      data: {
        nodeCodeInfo: parentEdge?.data?.nodeCodeInfo,
      },
    };
  });

  return updatedColEdges || [];
};

export const setBaseNodeAsFocused = (
  nodes: LineageUrlNodesType,
  baseNodeId: string
): LineageUrlNodesType => {
  const nodeEntries = getObjectEntries(nodes);

  const updatedNodesArray = nodeEntries?.map(([nodeId, nodeData]) => {
    const isMainNode = nodeData?.nodeLevel === 1;

    return [
      nodeId,
      {
        ...nodeData,
        isFocused: isMainNode ? nodeId === baseNodeId : false,
      },
    ];
  });

  const updatedNodesInfo = Object.fromEntries(updatedNodesArray) || [];

  return updatedNodesInfo;
};

export const isLineageLayerChanged = (
  currentLayer: string,
  initialLayer: string
): boolean => {
  // Split the string by commas and filter out empty strings
  const layers = currentLayer?.split(",");

  return layers?.length > 1 || layers?.[0] !== initialLayer;
};
