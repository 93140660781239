import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const SuggestionStyled = styled.div`
  width: 220px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px ${({ theme }): string => theme.borderColors.GREY_29};
  background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  margin-bottom: 27px;
  .suggestion-header {
    height: 50px;
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    align-items: center;
    background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_17};
    font-family: OpenSansSemiBold;
    font-size: 13px;
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  }

  .user {
    ${ellipsis}
  }

  .suggestion-body {
    padding: 14px;

    .days-ago {
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      opacity: 0.6;
      margin-bottom: 14px;
    }

    .suggestion-title,
    .suggestion-value {
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    }

    .suggestion-title {
      font-family: OpenSansSemiBold;
    }

    .suggestion-value {
      margin-bottom: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      cursor: auto;
      .single-tag {
        margin-bottom: 5px;
        margin-right: 6px;
        cursor: pointer;
      }
    }
  }
`;
