import { useCallback, useState } from "react";

import {
  solidBlueArrowDownIcon,
  solidBlueArrowUpIcon,
} from "../../../../../../../svgs";
import { LinkCollapseStyled } from "../../../../../../collapse/linkcollapse/linkcollapse.styles";
import { ParsedBusinessRuleType } from "../../../../sectionwitheditablebuisinessrules.types";
import {
  BusinessRuleDescRendrer,
  BusinessRuleGridRendrer,
  BusinessRuleMinMaxRendrer,
} from "./collapseitem.rendrer";

import { BusinessRuleEditViewCollapseItemProps } from "./collapseitem.types";

const { Panel } = LinkCollapseStyled;

function CollapseItem(
  props: BusinessRuleEditViewCollapseItemProps
): JSX.Element {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const { currentItem, rulesConfig } = props;

  const onChangeCollapse = useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded]);

  const currentConfig = rulesConfig?.[currentItem];

  const collapseRendrer: {
    [key in ParsedBusinessRuleType["type"]]: JSX.Element;
  } = {
    "min-max": <BusinessRuleMinMaxRendrer />,
    tags: <BusinessRuleGridRendrer {...props} />,
    text: <BusinessRuleDescRendrer />,
  };

  return (
    <LinkCollapseStyled onChange={onChangeCollapse}>
      <Panel
        header={
          <div className="collapse-header">
            <div className="collapse-header-title">{currentConfig?.title}</div>
            {isExpanded ? solidBlueArrowUpIcon() : solidBlueArrowDownIcon()}
          </div>
        }
        key={currentConfig?.title || ""}
        showArrow={false}
      >
        <div className="collapse-rendrer-content">
          {collapseRendrer?.[currentConfig?.type || "text"]}
        </div>
      </Panel>
    </LinkCollapseStyled>
  );
}

export default CollapseItem;
