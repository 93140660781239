import styled from "styled-components";

export const SectionWithEditableAdditionalinfoStyled = styled.div<{
  isEditable: boolean;
}>`
  .relationships-edit-mode-wrapper {
    padding: 25.6px;
    background-color: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
  }
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
