import { WORKFLOW_TOOLTIP_LABEL } from "../constants";
import {
  DQ_METADATA_WORKFLOW_TOOLTIP_LABEL,
  DQ_WORKFLOW_TOOLTIP_LABEL,
} from "../constants/classnames";

export const domainWorkflowTitle = (
  isMetaDataWorkflowEnabled: boolean,
  isDqWorkFlowEnabled: boolean
): string => {
  const isBothWorkflowEnabled =
    isMetaDataWorkflowEnabled && isDqWorkFlowEnabled;

  return isBothWorkflowEnabled
    ? DQ_METADATA_WORKFLOW_TOOLTIP_LABEL
    : isMetaDataWorkflowEnabled
    ? WORKFLOW_TOOLTIP_LABEL
    : isDqWorkFlowEnabled
    ? DQ_WORKFLOW_TOOLTIP_LABEL
    : "";
};
