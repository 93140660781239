import styled from "styled-components";

export const AssetRelationshipsBlankStateStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 12.8px;
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  padding: 51.2px 0;

  .blankslate-text {
    color: ${({ theme }): string => theme?.bgColors?.GREY_24};
  }
`;
