import { path } from "ramda";
import {
  Actions,
  AdditionalInfoState,
} from "../../components/additionalinfo/additionalinfo.types";

import {
  getTimeDifference,
  isEqual,
  jsonParse,
  utcTOLocalTimeZone,
} from "../../utils";

import {
  AssetConfigItemType,
  AssetDocumentResponseType,
  AssetFieldConfigType,
  AssetFieldIds,
  AssetJobExecutionDetailsParsedType,
  AssetJobExecutionDetailsType,
  AssetJobSourceNodeType,
  AssetKeys,
  AssetPath,
  AssetReviewChangesParsedType,
  ManagedByItem,
  ManagedByKeys,
  MappedFieldsType,
  NodeParsedType,
  RelationShipAdditionalInfoType,
  RelationshipItemType,
  RelationshipResultType,
  ReplaceType,
  SimilarAssetsResponse,
} from "./asssetoverviewparser.types";

import { getBreadCrumItemType } from "../../components/styledbreadcrum/styledbreadcrum.util";

import { NodeType, SourceTypes } from "../../app.types";
import { BreadcrumbItemType } from "../../components/styledbreadcrum";

import { BreadcrumItemStringType } from "../../components/styledbreadcrum/styledbreadcrum.types";
import { AssetBuisinessRulesValues } from "../parser.types";

import { TermType } from "../termsparser";

import {
  RuleDefinitionValueType,
  RuleWorkflowType,
} from "../ruleparser/ruleparser.types";
import { getWorkflowStats } from "../ruleparser/ruleparser.util";

export const ID_KEY_MAPPING: {
  [key in keyof AssetDocumentResponseType["managed_by"]]:
    | "data_owner_id"
    | "data_steward_id"
    | "system_owner_id";
} = {
  data_owners: "data_owner_id",
  data_stewards: "data_steward_id",
  system_owners: "system_owner_id",
};

export const NAME_KEY_MAPPING: {
  [key in keyof AssetDocumentResponseType["managed_by"]]:
    | "data_owner_name"
    | "data_steward_name"
    | "system_owner_name";
} = {
  data_owners: "data_owner_name",
  data_stewards: "data_steward_name",
  system_owners: "system_owner_name",
};

export const EMAIL_KEY_MAMPING: {
  [key in keyof AssetDocumentResponseType["managed_by"]]:
    | "data_owner_email"
    | "data_steward_email"
    | "system_owner_email";
} = {
  data_owners: "data_owner_email",
  data_stewards: "data_steward_email",
  system_owners: "system_owner_email",
};

export const UPDATED = "Updated";
export const CREATED = "Created";

export const extractNameEmail = <T extends ManagedByItem>(
  item: T,
  nameKey: ManagedByKeys,
  emailKey: ManagedByKeys
): { name: string; email: string; type: "USR" | "UGP" } => {
  const name = item?.[nameKey as keyof typeof item] as string | undefined;
  const email = item?.[emailKey as keyof typeof item] as string | undefined;
  const userType = item?.user_type as "USR" | "UGP";

  const userName = name || "";
  const userEmail = email || "";

  const isUser = userType === "USR";

  return {
    name: isUser ? `${userName} (${userEmail})` : userName,
    email: email || "",
    type: item?.user_type,
  };
};

export const getAssetAdditionalInfo = (
  data: AssetDocumentResponseType
): AdditionalInfoState["addedItems"] => {
  const additionalinfo = data?.additional_info?.value || [];

  return [
    ...additionalinfo
      ?.filter((item) => item?.Link)
      ?.map((item, index) => ({
        actionId: "embed_link" as Actions,
        id: `embed_link-${index}`,
        value: item?.Link || "",
      })),
    ...(data?.additional_info?.value || [])
      ?.filter((item) => item?.Text)
      ?.map((item, index) => ({
        actionId: "edit" as Actions,
        id: `edit-${index}`,
        value: item?.Text || "",
      })),
  ];
};

export const getAssetAuditTrailInfo = (
  data: AssetDocumentResponseType
): string => {
  const { description } = data || {};
  const { audit_trail: auditTrail } = description || {};

  const createdObj = auditTrail?.find((item) => item?.activity === CREATED);
  const updatedObj = auditTrail?.find((item) => item?.activity === UPDATED);

  const {
    activity: createdByActivity = "",
    user_name: createdByUser = "",
    activity_on: createdByActivityOn = "",
  } = createdObj || {};

  const {
    activity: updatedActivity = "",
    user_name: updatedByUser = "",
    activity_on: updatedActivityOn = "",
  } = updatedObj || {};

  const createdTime = getTimeDifference(
    utcTOLocalTimeZone(createdByActivityOn)
  );

  const updatedTime = getTimeDifference(utcTOLocalTimeZone(updatedActivityOn));

  const createdAction = `${createdByActivity} by ${createdByUser} ${createdTime}`;
  const updatedAction = `${updatedActivity} by ${updatedByUser} ${updatedTime}`;

  return `${createdAction} : ${updatedAction}`;
};

export const getFieldValueUsingPath = <D, T>(
  data: D,
  valuePath: string
): T | undefined => {
  const splittedPath = valuePath?.split(".") || [];

  const pathValue = path<T>(splittedPath, data);

  return pathValue;
};

export const getValueUsingPath = <T>(
  data: SimilarAssetsResponse[],
  valuePath: string
): T | undefined => {
  const splittedPath = valuePath?.split(".") || [];

  const pathValue = path<T>(splittedPath, data);

  return pathValue;
};

export const getFormattedFieldValues = (
  data: AssetDocumentResponseType,
  fieldConfig: AssetFieldConfigType
): string => {
  const { id: fieldId, path = "", alternatePath = "" } = fieldConfig || {};

  const value =
    getFieldValueUsingPath<AssetDocumentResponseType, string>(data, path) || "";

  const alternatePathValue =
    getFieldValueUsingPath<AssetDocumentResponseType, string>(
      data,
      alternatePath
    ) || "";

  const finalValue = value || alternatePathValue || "";

  switch (fieldId) {
    case AssetFieldIds.isCDE:
      return value ? "Yes" : "";

    case AssetFieldIds.workflowStatus:
      return value ? "Yes" : "";

    default:
      return finalValue;
  }
};

export const getIsRowVisibleInNormalView = (
  data: AssetDocumentResponseType,
  fieldConfig: AssetFieldConfigType
): boolean => {
  const { src_type_id: srcTypeId, node_sub_type: nodeSubType } = data || {};

  const {
    id: fieldId,
    path = "",
    idPath = "",
    isRowVisibleInNormalView: isRowVisible = true,
  } = fieldConfig || {};

  const fieldValue =
    getFieldValueUsingPath<AssetDocumentResponseType, string>(data, path) || "";
  const fieldIdValue =
    getFieldValueUsingPath<AssetDocumentResponseType, string>(data, idPath) ||
    "";

  const isAdlsSrc = srcTypeId === "ADL";
  const isReportType = nodeSubType === "RPT";
  const isComputedMeasureTerm = fieldIdValue === "CMM";

  const fieldValueLength = fieldValue?.length || 0;
  const isFieldValueExists = !!fieldValueLength;

  switch (fieldId) {
    case AssetFieldIds.filePath:
      return isAdlsSrc;

    case AssetFieldIds.imagePreview:
      return !!(isReportType && fieldValue);

    case AssetFieldIds.formula:
      return isComputedMeasureTerm;

    case AssetFieldIds.previouslyKnownAs:
      return !!fieldValue;

    case AssetFieldIds.replaces:
    case AssetFieldIds.replacedBy:
      return isFieldValueExists;

    default:
      return isRowVisible ?? true;
  }
};

export const getIsRowInherited = (
  data: AssetDocumentResponseType,
  fieldConfig: AssetFieldConfigType
): boolean => {
  const { isInheritedPath = "", id: fieldId } = fieldConfig || {};

  const inheritedPathValue = getFieldValueUsingPath(data, isInheritedPath);

  return !!inheritedPathValue;
};

export const getIsRowInheritedBy = (
  data: AssetDocumentResponseType,
  fieldConfig: AssetFieldConfigType
): string => {
  const { isInheritedByPath = "", id: fieldId } = fieldConfig || {};

  const inheritedByPathValue =
    getFieldValueUsingPath<AssetDocumentResponseType, string>(
      data,
      isInheritedByPath
    ) || "";

  return inheritedByPathValue;
};

export const getReplacesInfo = (
  data: AssetDocumentResponseType,
  fieldConfig: AssetFieldConfigType
): ReplaceType[] => {
  const { path = "", id: fieldId } = fieldConfig || {};

  switch (fieldId) {
    case AssetFieldIds.replaces:
    case AssetFieldIds.replacedBy: {
      const value =
        getFieldValueUsingPath<
          AssetDocumentResponseType,
          Array<{
            term_id: string;
            trm_name: string;
          }>
        >(data, path) || [];

      const replacedValues =
        value?.map((item) => {
          const { trm_name = "", term_id = "" } = item || {};
          return {
            name: trm_name,
            id: `${term_id}`,
          };
        }) || [];

      return replacedValues;
    }

    default:
      return [];
  }
};

export const getPipelineDetails = (
  data: AssetDocumentResponseType,
  fieldConfig: AssetFieldConfigType
): AssetJobExecutionDetailsParsedType => {
  const { path = "", id: fieldId } = fieldConfig || {};

  const value =
    getFieldValueUsingPath<
      AssetDocumentResponseType,
      AssetJobExecutionDetailsType[]
    >(data, path) || [];

  const pipleLineDetails = value?.[0];

  const durationResp = pipleLineDetails?.pl_exec_duration_secs;
  const durationSecs = durationResp ? Number(durationResp) : 0;
  const duration = durationSecs ? String(durationSecs) : "";

  const jobName = pipleLineDetails?.pl_name || "";
  const jobType = pipleLineDetails?.pl_type || "";
  const lastRun = pipleLineDetails?.pl_last_exec_on || "";
  const runStatus = pipleLineDetails?.pl_last_exec_status || "";

  return {
    duration,
    jobName,
    jobType,
    lastRun,
    runStatus,
  };
};

export const getColumnDetails = (
  data: AssetDocumentResponseType,
  fieldConfig: AssetFieldConfigType
): NodeParsedType[] => {
  const { path = "", id: fieldId = "none" } = fieldConfig || {};

  switch (fieldId) {
    case AssetFieldIds.fieldName: {
      const colsInfo =
        getFieldValueUsingPath<
          AssetDocumentResponseType,
          { col_name?: string; col_id?: string }[]
        >(data, path) || [];

      const final = colsInfo?.map((item) => {
        return {
          nodeName: item?.col_name || "",
          nodeId: Number(item?.col_id) || 0,
          nodeType: "COL" as NodeType,
        };
      });

      return final || [];
    }

    default:
      return [];
  }
};

export const getSrcAndTgtDetails = (
  data: AssetDocumentResponseType,
  fieldConfig: AssetFieldConfigType
): NodeParsedType[] => {
  const { path = "", id: fieldId = "none" } = fieldConfig || {};

  switch (fieldId) {
    case AssetFieldIds.ploSource:
    case AssetFieldIds.ploTarget: {
      const value =
        getFieldValueUsingPath<
          AssetDocumentResponseType,
          AssetJobSourceNodeType[]
        >(data, path) || [];

      const final = value?.map((item) => {
        return {
          nodeName: item?.node_name || "",
          nodeId: item?.node_id || 0,
          nodeType: item?.node_type,
          nodeSubType: item?.node_sub_type,
        };
      });

      return final || [];
    }

    default:
      return [];
  }
};

export const getAssetKey = (key: string): AssetKeys => {
  return key as AssetKeys;
};

export const extractCrumsConfig = <D>(
  crumsConfig: AssetConfigItemType,
  data: D
): BreadcrumbItemType[] => {
  return (
    crumsConfig?.items?.map((item) => {
      const { idPath = "", path = "", type } = item || {};

      const { isSrcNameItem } = getBreadCrumItemType(type);

      const breadCrumTitle =
        getFieldValueUsingPath<typeof data, string>(data, path) || "";

      const breadCrumId =
        getFieldValueUsingPath<typeof data, string>(data, idPath) || "";

      const breadCrumSrcTypeId = getFieldValueUsingPath<
        typeof data,
        SourceTypes
      >(data, "src_type_id");

      return {
        ...item,
        title: breadCrumTitle || "None",
        ...(breadCrumId ? { nodeId: breadCrumId } : {}),
        ...(isSrcNameItem ? { srcTypeId: breadCrumSrcTypeId } : {}),
      };
    }) || []
  );
};

export const getParsedNodePath = (nodePath: string): AssetPath => {
  const parsedNodePath: AssetPath = jsonParse(nodePath || "");
  return parsedNodePath;
};

export const getSortedNodePath = (parsedNodePath: AssetPath): AssetPath => {
  const sortedPath = parsedNodePath
    ? parsedNodePath?.sort((a, b) => {
        const levelA = Number(a?.level) || 0;
        const levelB = Number(b?.level) || 0;

        return levelB - levelA;
      })
    : [];

  return sortedPath;
};

export const populateBreadCrums = (
  parsedNodePath: AssetPath
): BreadcrumbItemType[] => {
  const isArray = Array.isArray(parsedNodePath);

  const sortedPath = isArray
    ? parsedNodePath?.sort((a, b) => {
        const levelA = Number(a?.level) || 0;
        const levelB = Number(b?.level) || 0;

        return levelB - levelA;
      })
    : [];

  const breadCrumb =
    sortedPath?.map((item) => {
      const itemType = item?.node_type as BreadcrumItemStringType;

      return {
        title: item?.node_title || item?.node_name || "None",
        type: itemType,
        nodeId: `${item?.node_id || ""}`,
        srcTypeId: item?.node_sub_type as SourceTypes,
      };
    }) || [];

  return breadCrumb;
};

export const mapBusinessRulesValues = (
  values: AssetBuisinessRulesValues["Categorical Values"] = []
): AssetBuisinessRulesValues["Categorical Values"] =>
  values?.map((item) => ({
    desc: item?.desc || "",
    value: item?.value || "",
  }));

export const listOfBuisenessRulesBasedOnType = (
  termType: TermType
): Array<keyof AssetReviewChangesParsedType[number]["businessRules"]> => {
  switch (termType) {
    case "CAA":
      return ["categories", "patterns", "manual"];
    case "BSM":
    case "CMM":
      return ["valueRange", "manual"];
    case "NCA":
      return ["patterns", "manual"];
    default:
      return ["manual"];
  }
};

export const getMappedField = (
  fieldItem: AssetFieldConfigType
): MappedFieldsType => {
  const { id } = fieldItem || {};

  const isAuditTrail = id === AssetFieldIds.auditTrail;
  const isDataDomainField = id === AssetFieldIds.dataDomain;
  const isDescField = id === AssetFieldIds.description;
  const isMinValueField = id === AssetFieldIds?.minValue;
  const isFieldsNameField = id === AssetFieldIds?.fieldName;
  const isTblNameField = id === AssetFieldIds?.tableName;
  const isSrcField = id === AssetFieldIds?.ploSource;
  const isTgtField = id === AssetFieldIds?.ploTarget;

  return {
    isAuditTrail,
    isDataDomainField,
    isDescField,
    isFieldsNameField,
    isMinValueField,
    isSrcField,
    isTblNameField,
    isTgtField,
  };
};

export const getFieldMappedValue = (
  data: AssetDocumentResponseType,
  fieldItem: AssetFieldConfigType
): string => {
  const { path = "", id = "none" } = fieldItem || {};

  const formattedValue = getFormattedFieldValues(data, fieldItem);

  const auditTrail = getAssetAuditTrailInfo(data);

  const pipelineInfo = getPipelineDetails(data, fieldItem);

  const ruleDef = getFieldValueUsingPath<
    AssetDocumentResponseType,
    RuleDefinitionValueType
  >(data, path);

  const workflowValue = getFieldValueUsingPath<
    AssetDocumentResponseType,
    RuleWorkflowType
  >(data, path);

  const { currentWorkflowStatus } = getWorkflowStats(
    workflowValue as RuleWorkflowType
  );

  const { min_value: minValue = "", max_value: maxValue = "" } = ruleDef || {};

  const { duration, lastRun, runStatus, jobName = "", jobType } =
    pipelineInfo || {};

  const valueMapping: Record<string, string> = {
    [AssetFieldIds.auditTrail]: auditTrail,
    [AssetFieldIds.jobName]: jobName,
    [AssetFieldIds.jobType]: jobType,
    [AssetFieldIds.minValue]: String(minValue),
    [AssetFieldIds.maxValue]: String(maxValue),
    [AssetFieldIds.duration]: duration,
    [AssetFieldIds.lastRun]: lastRun,
    [AssetFieldIds.runStatus]: runStatus,
    [AssetFieldIds.workflowStatus]: currentWorkflowStatus,
    // Giving them empty because it has array of objects as value
    // or chart as data
    [AssetFieldIds.fieldName]: "",
    [AssetFieldIds.ploSource]: "",
    [AssetFieldIds.ploTarget]: "",
    [AssetFieldIds.count]: "",
    [AssetFieldIds.visualDistribution]: "",
    [AssetFieldIds.patternDistribution]: "",
  };

  const fieldParsedValue =
    id in valueMapping ? valueMapping[id] : formattedValue;

  return fieldParsedValue;
};

export const getFieldMappedParsedNodesValue = (
  data: AssetDocumentResponseType,
  fieldItem: AssetFieldConfigType
): NodeParsedType[] => {
  const { id = "none" } = fieldItem || {};

  const colsInfo = getColumnDetails(data, fieldItem);

  const sourcesInfo = getSrcAndTgtDetails(data, fieldItem);

  const targetsInfo = getSrcAndTgtDetails(data, fieldItem);

  const valueMapping: Record<string, NodeParsedType[]> = {
    [AssetFieldIds.fieldName]: colsInfo,
    [AssetFieldIds.ploSource]: sourcesInfo,
    [AssetFieldIds.ploTarget]: targetsInfo,
  };

  const fieldParsedValue = id in valueMapping ? valueMapping[id] : [];

  return fieldParsedValue;
};

export const processRelationship = (
  relationOldValue: RelationshipItemType[],
  relationNewValue: RelationshipItemType[],
  relTypeId: string
): RelationshipResultType => {
  const extractValues = (
    values: RelationshipItemType[]
  ): RelationshipItemType[] =>
    values?.filter((item) => item?.REL_TYPE_ID === relTypeId);

  const mapValues = (
    values: RelationshipItemType[]
  ): Array<{ comment: string; tgtNodeId: number }> =>
    values?.map(
      ({
        COMMENTS: comment,
        TGT_NODE_ID: tgtNodeId,
        ADDITIONAL_INFO: additionalInfo,
      }) => {
        const parsedAdditionalInfo: RelationShipAdditionalInfoType = jsonParse(
          additionalInfo
        );

        const { entity_selection_status: entityStatus } =
          parsedAdditionalInfo || {};

        return {
          comment,
          tgtNodeId,
          entityStatus,
        };
      }
    );

  const oldObjVals = extractValues(relationOldValue);
  const newObjVals = extractValues(relationNewValue);

  const oldObj = mapValues(oldObjVals);
  const newObj = mapValues(newObjVals);

  return {
    oldValue: oldObjVals,
    newValue: newObjVals,
    isChanged: !isEqual(oldObj, newObj),
  };
};
