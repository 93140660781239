import styled from "styled-components";
import { ellipsis } from "../../../styles/app.styles";

export const SectionWithEditableSourcesStyled = styled.div<{
  isEditable: boolean;
}>`
  background: ${({ theme, isEditable }): string =>
    isEditable && theme.bgColors.BLUE_11};
  padding: ${({ isEditable }): any => isEditable && "23px 34px 34px 23px"};

  ${({ isEditable }): any =>
    isEditable &&
    `
.section-main-row{
  flex-direction:column;

  .section-main-row-title{
    width:100%;
    margin-bottom: 6px;
    font-family: OpenSansSemiBold;
}
  }

  .section-main-row-value{
    width:100% !important;

    
      .represents-container {
        max-width: 97%;
        
      }

   
  }
}
`}
`;

export const DataItemStyled = styled.div<{
  hasTitle: boolean;
  isRefView?: boolean;
}>`
  .section-main-row {
    display: flex;
    margin-bottom: 30px;
    .section-main-row-title {
      color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
      width: 33%;
    }
    .section-main-row-value {
      color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
      width: ${({ hasTitle, isRefView }): string =>
        isRefView ? "100%" : hasTitle ? "66%" : "100%"};
    }
  }
`;

export const RenderTitleStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.DARK_GREY_10};
  padding: 13px 13px 0 13px;
`;

export const RenderItemStyled = styled.div<{ isBold?: boolean }>`
  &.render-item {
    width: 100%;
    display: flex;
    align-items: center;
    color: ${({ theme }): string => theme.textColors.BLACK_13};

    .src-icon,
    .db-icon {
      height: 16px;
    }

    .src-name,
    .tbl-name,
    .db-name {
      min-width: 10%;
      margin: 0 8px 0 5px;
      ${ellipsis}
    }

    .src-name {
      max-width: 10%;
    }

    .db-name {
      max-width: 20%;
    }

    .right-icon {
      margin-right: 8px;
      height: 15px;
      svg {
        path {
          fill: #b8bbc3;
        }
      }
    }

    .core-nodes-circle-icon {
      background: rgba(29, 29, 29, 0.8);
    }

    .db-icon,
    .schema-icon {
      svg {
        path {
          fill: rgba(29, 29, 29, 0.8);
        }
      }
    }
  }
`;
