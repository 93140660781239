import { createRef, useEffect, useState } from "react";
import { CarouselRef } from "antd/lib/carousel";

import { Carousel as CarouselAntd } from "antd";

import {
  StyledCarasolWrapperStyled,
  StyledCarouselContentGroupWrapper,
  StyledCarouselLeftButtonStyled,
  StyledCarouselRightButtonStyled,
  StyledCarouselWrapper,
} from "./styledcarasol.styles";

import { CaroselCards, StyledCarasolPropsType } from "./styledcarasol.types";
import { chevronLeftFilled, chevronRightFilled } from "../../svgs";

const StyledCarasol = (props: StyledCarasolPropsType): JSX.Element => {
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const carouselRef = createRef<CarouselRef>();

  const { cards } = props;

  const groupCardsIntoRows = (cardsList: CaroselCards): CaroselCards[] => {
    return cardsList?.reduce<CaroselCards[]>((acc, card, index) => {
      if (index % 3 === 0) {
        acc.push([card]);
      } else {
        acc[acc?.length - 1]?.push(card);
      }
      return acc;
    }, []);
  };

  const groupedCarsolCards = groupCardsIntoRows(cards);

  useEffect(() => {
    if (groupedCarsolCards?.[currentActiveTab] === undefined) {
      setCurrentActiveTab((prev) => prev - 1);
    }
  }, [currentActiveTab]);

  const handleNext = () => (): void => {
    setCurrentActiveTab((prev) => prev + 1);
    carouselRef.current?.next();
  };

  const handlePrevious = () => (): void => {
    setCurrentActiveTab((prev) => prev - 1);
    carouselRef.current?.prev();
  };

  return (
    <StyledCarasolWrapperStyled className="grouped-carasol">
      {cards?.length >= 3 && !(currentActiveTab === 0) && (
        <StyledCarouselLeftButtonStyled onClick={handlePrevious()}>
          {chevronLeftFilled()}
        </StyledCarouselLeftButtonStyled>
      )}

      <StyledCarouselWrapper>
        <CarouselAntd
          dotPosition="bottom"
          ref={carouselRef}
          afterChange={setCurrentActiveTab}
          infinite={false}
        >
          <div className="grouped-carousel-cards">
            {groupedCarsolCards?.map((group, index) => {
              return (
                <StyledCarouselContentGroupWrapper key={index}>
                  {currentActiveTab === index ? (
                    group?.map((card) => card)
                  ) : (
                    <div />
                  )}
                </StyledCarouselContentGroupWrapper>
              );
            })}
          </div>
        </CarouselAntd>
      </StyledCarouselWrapper>

      {cards?.length >= 3 &&
        !(currentActiveTab === groupedCarsolCards?.length - 1) && (
          <StyledCarouselRightButtonStyled onClick={handleNext()}>
            {chevronRightFilled()}
          </StyledCarouselRightButtonStyled>
        )}
    </StyledCarasolWrapperStyled>
  );
};

export default StyledCarasol;
