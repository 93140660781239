import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const StyledBreadcrumWrapper = styled.div<{ marginBottom?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ marginBottom }): string => marginBottom || "20px"};
  width: 100%;

  .header-item {
    display: flex;
    align-items: center;
  }
`;

export const HeaderItemStyled = styled.div<{
  isFromHome?: boolean;
  flexGrow: number;
}>`
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: ${(props): string => `${props.flexGrow}%`};

  &.src-connection-name {
    .svg-icon {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.header-item {
    line-height: 20px;
    ${ellipsis}

    .item-link {
      ${ellipsis}
    }
  }

  .item-icon {
    margin-right: 6px;
    display: flex;

    &.icon {
      svg {
        path {
          fill: ${({ theme }): string => theme.bgColors.GREY_210};
        }
      }
    }

    svg {
      vertical-align: middle;
    }
  }

  .item-title {
    cursor: default;
    font-size: 13px;
    min-width: 3ch;
    color: ${({ theme, isFromHome }): string =>
      isFromHome ? "rgb(176, 176, 176)" : theme.textColors.GREY_210};
    ${ellipsis};
  }

  .link-item {
    cursor: pointer;

    :hover {
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      text-decoration: underline;
    }
  }

  .right-icon {
    margin: 0px 11px;
    display: flex;
  }
`;
