import styled from "styled-components";

export const RulesGridStyled = styled.div`
  .page-content {
    padding: 0;

    .grid-action-btns-wrapper {
      padding: 0;
    }

    .page-header {
      height: unset;
      .left {
        .ant-btn-link {
          .static-text {
            padding-left: 0;
          }
        }
      }
    }

    .listing-grid {
      margin-top: 15px;

      .ag-header {
        //applying !important due to inline styling
        min-height: 48px !important;
        height: 48px !important;
        .ag-header-row-floating-filter {
          display: none;
        }
      }
    }

    .listing-filters-wrapper {
      margin-top: 15px;
    }

    .listing-page-header-divider {
      margin: 14px 0px;
    }
  }

  .table-container {
    .ag-root-wrapper {
      border: 1px solid ${({ theme }): string => theme.borderColors.GREY_35};
      .ag-header-viewport {
        background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};
        .ag-header-row {
          background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};
          .ag-header-cell {
            color: #777777;

            font-weight: 600;
            font-family: OpenSansSemiBold;
          }
        }
      }
      .ag-body-viewport {
        .ag-row {
          .ag-cell {
            color: ${({ theme }): string => theme.textColors.GREY_25};
            :not(:last-of-type) {
              border-right: none;
            }
            border-bottom: 1px solid
              ${({ theme }): string => theme.borderColors.GREY_35};
          }
        }
      }
    }
  }
`;
