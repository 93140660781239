import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";

export const GatewayListPopoverContainerStyled = styled.div<{
  isCollapsed: boolean;
}>`
  width: 100%;
  .ant-popover-placement-rightBottom {
    padding-left: 0px;

    left: ${({ isCollapsed }): string =>
      isCollapsed ? "44px !important" : "190px !important"};
  }

  .ant-popover-placement-bottomRight {
    padding-top: 0px;
  }
`;

export const GatewaysListPopoverStyled = styled.div`
  width: 100%;
  min-width: 62.8px;
  cursor: pointer;
  border-left: 1px solid
    ${(props): string => props.theme.borderColors.WHITE_COLOR};
  padding: 0px 15px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GatewaysListPopoverContentStyled = styled.div`
  width: 365px;
  background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  padding: 8px 0px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #e8e8e8;

  .list-head {
    padding: 8px 18px;
    color: ${({ theme }): string => theme?.textColors?.GREY_210};
    font-size: 12px;
    font-weight: 400;
  }
`;

export const GatewayItemStyled = styled.div<{ isGatewayRunning: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 18px;
  color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
  cursor: ${({ isGatewayRunning }): string =>
    isGatewayRunning ? "default" : "pointer"};

  .gateway-item-left-sec {
    display: flex;
    align-items: center;

    .ant-spin {
      margin-right: 6px;
    }

    .gateway-name {
      margin: 0 5px;
    }

    .gateway-host {
      margin: 0 2px;
      ${ellipsis}
      flex: 1;
      max-width: 20ch;
    }
  }

  .gateway-item-right-sec {
    margin-left: 5px;
  }

  :hover {
    background: ${({ theme }): string => theme?.bgColors?.LIGHT_BLUE_25};

    .gateway-retry-btn {
      .ant-btn {
        display: flex;
      }
    }
  }
`;
