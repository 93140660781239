import * as yup from "yup";
import { isNumber } from "..";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";
import { TermType } from "../../parsers/termsparser/termsparser.types";

export const buisinessRuleEditableViewSchema = (
  termType: TermType
): yup.AnyObjectSchema =>
  yup.object().shape({
    max_value: yup.lazy((_, props1) => {
      const isValidationApplied = termType === "BSM" || termType === "CMM";
      const propMinValue = props1?.parent?.min_value;
      const propMaxValue = props1?.parent?.max_value;
      if (
        isNumber(propMinValue) &&
        propMinValue &&
        isNumber(propMaxValue) &&
        propMaxValue &&
        isValidationApplied
      ) {
        const minValue = Number(propMinValue);
        const maxValue = Number(propMaxValue);

        return yup.string().test("maxResolver", "Max > Min", () => {
          return maxValue >= minValue;
        });
      }
      if (isValidationApplied && propMinValue) {
        return yup.string().required(REQUIRED_MESSAGE);
      }
      return yup.string();
    }),
    min_value: yup.lazy((_, props2) => {
      const isValidationApplied = termType === "BSM" || termType === "CMM";
      const propMinValue = props2?.parent?.min_value;
      const propMaxValue = props2?.parent?.max_value;
      if (
        isNumber(propMinValue) &&
        propMinValue &&
        isNumber(propMaxValue) &&
        propMaxValue &&
        isValidationApplied
      ) {
        const minValue = Number(propMinValue);
        const maxValue = Number(propMaxValue);

        return yup.string().test("minResolver", "Min < Max", () => {
          return minValue <= maxValue;
        });
      }
      if (isValidationApplied && propMaxValue) {
        return yup.string().required(REQUIRED_MESSAGE);
      }
      return yup.string();
    }),
  });
