import { useGetNodeStatisticsForDetailPage } from "../../api/columnsservice";
import { getTimeDifference, utcTOLocalTimeZone } from "../../utils";
import DQScore from "../dqscore";
import StateHandler from "../statehandler";

import { DataQualityPreviewerStyled } from "./dataqualitypreviewer.styles";
import { DataQualityPreviewerProps } from "./dataqualitypreviewer.types";

const DataQualityPreviewer = (
  props: DataQualityPreviewerProps
): JSX.Element => {
  const { nodeId, tableId } = props;

  const {
    parsedData: columnDqData,
    isLoading,
    error,
  } = useGetNodeStatisticsForDetailPage(nodeId, tableId);

  const {
    avg_dq_score: dqScore,
    dq_status: dqStatus = "",
    dq_status_id: dqStatusId = "",
    last_executed_on: lastExecutedOn = "",
  } = columnDqData || {};

  return (
    <DataQualityPreviewerStyled className="asset-data-quality-preview">
      <div className="score-wrapper">
        <div className="overall-score-label">Overall Score:</div>
        {!!dqScore && !!dqStatusId ? (
          <DQScore
            score={`${dqScore}`}
            status={dqStatus}
            statusId={dqStatusId}
            showEmptyState
          />
        ) : (
          "--"
        )}
      </div>

      <div className="last-refreshed">
        {`Last updated ${getTimeDifference(
          utcTOLocalTimeZone(lastExecutedOn || "")
        )}`}
      </div>
    </DataQualityPreviewerStyled>
  );
};

export default DataQualityPreviewer;
