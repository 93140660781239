import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import SectionTitleWithIcon from "../../../../components/sectiontitlewithicon";
import {
  InputField,
  SelectField,
  TextAreaField,
} from "../../../../components/formfields";

import { DescriptionEditableViewProps } from "./descriptioneditableview.types";

import { dataSourceDescriptionEditableViewSchema } from "../../../../utils/schemas/datasourcedescriptioneditableviewschema";

import { DescriptionEditableViewStyled } from "./descriptioneditableview.styles";
import { DATA_SOURCE_SECTION_TITLES, ELEMENT_IDS } from "../../../../constants";

import BusinessNameEditInfoMessage from "../../../../components/businessnameeditinfo/businessnameeditinfo";
import {
  nodesDescriptionEditableViewValueUpdation,
  selectFilterOption,
} from "../../../../utils";

import Flex from "../../../../components/flex";
import {
  categoryIcon,
  chevronRight,
  doomianIcon,
  infoIcon,
  workflowIcon,
} from "../../../../svgs";
import Alert from "../../../../components/alert/alert";
import { TableEntityRendrerStyled } from "../../../tablepage/views/overview/overview.comp/tabledesceditableview/tabledesceditableview.styles";
import { CoreNodesCircleIcon } from "../../../../components";

const {
  overview: OVERVIEW,
  classification: CLASSIFICATION,
} = DATA_SOURCE_SECTION_TITLES;

const { description: DESCRIPTION, title: TITLE } = OVERVIEW;
const { functional_area: FUNCTIONAL_AREA, entity: ENTITY } = CLASSIFICATION;

function DescriptionEditableView(
  props: DescriptionEditableViewProps
): JSX.Element {
  const {
    setState,
    id,
    state,
    listOfDomains = [],
    listOfTerms = [],
    isReportPage,
    onChangeWorkflowStatus,
  } = props;

  const [showWorkflowAlert, setShowWorkflowAlert] = useState(false);

  const { control, watch, formState, setValue } = useForm<{}>({
    resolver: yupResolver(dataSourceDescriptionEditableViewSchema),
    mode: "onChange",
    defaultValues: {
      ...state[id]?.data
        ?.filter((item) => item?.isEditable)
        ?.reduce(
          (prev, item) => ({
            ...prev,
            [item?.id as string]:
              item?.type === "select"
                ? (item?.selectedValues || [])?.[0] || undefined
                : item?.value,
          }),
          {}
        ),
    },
  });

  const values = watch();

  const optionsForTerm = useMemo(() => {
    return listOfTerms?.map((item) => {
      return {
        value: item?.nodeId,
        label: (
          <TableEntityRendrerStyled>
            <>
              <div className="field-value-icon dmn-icon">{doomianIcon()}</div>
              <div className="field-option-value" title={item?.rootParentName}>
                {item?.rootParentName}
              </div>
              <div className="right-icon">{chevronRight}</div>
            </>
            <>
              <div className="field-value-icon">{categoryIcon()}</div>
              <div className="field-option-value" title={item?.parentName}>
                {item?.parentName}
              </div>
              <div className="right-icon">{chevronRight}</div>
            </>
            <>
              <div className="field-value-icon">
                <CoreNodesCircleIcon nodeType="TRM" />
              </div>
              <div className="field-option-value" title={item?.nodeName}>
                {item?.nodeName}
              </div>
            </>
          </TableEntityRendrerStyled>
        ),
        textForsearch: `${item?.rootParentName || ""}${item?.parentName || ""}${
          item?.nodeName || ""
        }`,
        labelText: item?.nodeName || "",
      };
    });
  }, [listOfTerms]);

  const onSave = useCallback(() => {
    setState((st) => ({
      ...st,
      [id]: {
        ...st?.[id],
        editable: false,
        isEdited: true,
        data: st?.[id]?.data?.map((obj) => ({
          ...nodesDescriptionEditableViewValueUpdation(
            obj,
            values,
            obj?.title === ENTITY ? optionsForTerm : listOfDomains
          ),
          isRowVisibleInNormalView: obj?.isRowVisibleInNormalView,
        })),
      },
    }));
  }, [props, values, state, listOfDomains]);

  const onCancel = useCallback(() => {
    setState((st) => ({ ...st, [id]: { ...st?.[id], editable: false } }));
  }, [props]);

  const domainOptWithWorkflowIcon = useMemo(() => {
    return listOfDomains?.map((item) => ({
      ...item,
      label: (
        <Flex alignItems="center" columnGap={5}>
          {item?.label}
          {item?.isWorkflowEnabled && workflowIcon("14", "14")}
        </Flex>
      ),
    }));
  }, [listOfDomains]);

  return (
    <DescriptionEditableViewStyled>
      <SectionTitleWithIcon
        {...props?.secTitle}
        isEditable
        marginBottom="25px"
        onSave={onSave}
        onCancel={onCancel}
        isDisabledSaveButton={!formState?.isValid}
      />
      <div className="desc-editableView">
        <div className="main-row">
          <div className="desc-row-title">{TITLE}</div>
          <div className="row-value">
            <InputField
              control={control}
              name={TITLE}
              width="70%"
              isMessagePositionAbsolute
            />
            <BusinessNameEditInfoMessage />
          </div>
        </div>

        <div className="main-row">
          <div className="desc-row-title">{DESCRIPTION}</div>
          <div className="row-value">
            <TextAreaField
              control={control}
              name={DESCRIPTION}
              width="100%"
              height="134px"
              isMessagePositionAbsolute
              id={ELEMENT_IDS.anldict_detpg_ovw_edit_desc_info}
            />
          </div>
        </div>

        <div className="main-row">
          <div className="desc-row-title">{FUNCTIONAL_AREA}</div>
          <div className="row-value">
            <SelectField
              control={control}
              name={FUNCTIONAL_AREA}
              width="70%"
              options={domainOptWithWorkflowIcon}
              setValue={setValue}
              showSearch
              allowClear
              isAllowClear={false}
              filterOption={selectFilterOption}
              placeholder="Select Data Domain"
              propOnChange={(option: string): void => {
                const selectedDomain = listOfDomains?.find(
                  (domain) =>
                    parseInt(domain?.value?.toString()) ===
                    parseInt(option?.toString())
                );
                const isGovernedDomain =
                  selectedDomain?.isWorkflowEnabled || false;

                onChangeWorkflowStatus?.(isGovernedDomain);
                setShowWorkflowAlert(isGovernedDomain);
              }}
            />
          </div>
          {showWorkflowAlert && (
            <Alert
              margin="10px 0"
              icon={infoIcon}
              showIcon
              type="info"
              message={`After publishing these changes any modification later on the current ${
                isReportPage ? "report" : "dataset"
              } will require approval`}
              useFlexStart
            />
          )}
        </div>

        <div className="main-row">
          <div className="desc-row-title">{ENTITY}</div>
          <div className="row-value">
            <SelectField
              control={control}
              name={ENTITY}
              width="100%"
              options={optionsForTerm}
              setValue={setValue}
              showSearch
              filterOption={selectFilterOption}
              placeholder="Select Entity"
              allowClear
              isAllowClear={false}
            />
          </div>
        </div>
      </div>
    </DescriptionEditableViewStyled>
  );
}

export default DescriptionEditableView;
